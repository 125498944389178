export const ordinals = (num: number): string => {
  if (typeof num !== 'number') {
    throw new TypeError('Expected Number, got ' + typeof num + ' ' + num)
  } else if (!Number.isFinite(num)) {
    return `${num}`
  } else {
    num = Math.abs(num)
    var cent = num % 100
    if (cent >= 10 && cent <= 20) return 'th'
    var dec = num % 10
    if (dec === 1) return 'st'
    if (dec === 2) return 'nd'
    if (dec === 3) return 'rd'
    return 'th'
  }
}
