import { Flex, IRenderControl, Label, RenderControl, styled } from '@fareye/ui'
import React from 'react'
interface IScoreCard {
  label?: string
  value?: string
  containerStyle?: React.CSSProperties
  labelStyle?: React.CSSProperties
  valueStyle?: React.CSSProperties
}
const ScoreCardContainer = styled(Flex)`
  scroll-snap-align: center;
  padding: 10px;
  background: #fff;
  border-radius: 2px;
  border-left: 2px solid #eab54e;
`
const ScoreCardLabel = styled.label`
  font-size: 14px;
  color: #727272;
`
const ScoreCardValue = styled.label`
  font-size: 20px;
  font-weight: bold;
`
const initialState: IRenderControl = {
  data: null,
  message: '',
  isError: false,
  isLoading: false,
  isSuccess: false
}
export default function ScoreCard({
  label,
  value,
  containerStyle,
  labelStyle,
  valueStyle,
  dataConfig,
  graphConfig,

  globalFilter
}: any) {
  const { getDataForChart } = dataConfig
  const [state, setState] = React.useState(initialState)
  React.useEffect(() => {
    fetchData()
  }, [globalFilter])

  function generateDataForScoreCard(apiData = []) {
    let value = apiData.reduce((a: any, b: any) => {
      a += b.value
      return a
    }, 0)
    return { label: graphConfig.chartTitle, value }
  }

  async function fetchData() {
    try {
      setState({ ...state, isLoading: true })
      const apiData = await getDataForChart(graphConfig, globalFilter)
      console.log(apiData)
      setState({
        ...state,
        data: generateDataForScoreCard(apiData),
        isLoading: false,
        isSuccess: true,
        isError: false
      })
    } catch (ex) {
      setState({
        ...state,
        isLoading: false,
        message: 'No data...',
        isError: true,
        isSuccess: false
      })
    }
  }
  return (
    <ScoreCardContainer column alignItemsFlexStart justifyContentSpaceBetween>
      <RenderControl state={state}>
        <ScoreCardValue style={valueStyle}>{state.data?.value}%</ScoreCardValue>
        <ScoreCardLabel style={labelStyle}>{state.data?.label}</ScoreCardLabel>
      </RenderControl>
    </ScoreCardContainer>
  )
}
