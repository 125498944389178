import axios, {
  CancelTokenStatic,
  CancelToken,
  AxiosRequestConfig
} from 'axios'
import { HEADERS } from './storage'
//FIXME:Naming incorrect
function getHeaders(isAuth?: boolean) {
  const headers: any = {}
  headers['Accept'] = 'application/json'
  headers['Content-Type'] = 'application/json'
  isAuth ? (headers['Authorization'] = HEADERS()['AUTHORIZATION']) : ''
  return headers
}

export const axiosInstance = axios.create()

//NOTE: API Endpoints
export async function get(
  url: string,
  cancelToken: CancelToken,
  config?: AxiosRequestConfig
) {
  return axiosInstance.get(url, { cancelToken, ...config })
}

export async function post(
  url: string,
  data: any,
  cancelToken: CancelToken,
  config?: AxiosRequestConfig
) {
  return axiosInstance.post(url, data, { cancelToken, ...config })
}
export async function del(
  url: string,
  cancelToken: CancelToken,
  config?: AxiosRequestConfig
) {
  return axiosInstance.delete(url, { cancelToken, ...config })
}

export async function put(
  url: string,
  data: any,
  cancelToken: CancelToken,
  config?: AxiosRequestConfig
) {
  return axiosInstance.put(url, data, { cancelToken, ...config })
}

//NOTE: Cancel Token for cancelling API Calls
export function cancelToken(): CancelTokenStatic {
  return axios.CancelToken
}

//FIXME: We have to remove this code from
export async function unAuthGet(url: string, cancelToken: any) {
  return axiosInstance.get(url, { cancelToken })
}
export async function getXlsx(
  url: string,
  cancelToken: any,
  config?: AxiosRequestConfig
) {
  const headers = getHeaders(true)
  headers['Content-Type'] =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  return axiosInstance.get(url, {
    headers,
    cancelToken,
    responseType: 'blob',
    ...config
  })
}
export async function postXlsx(
  url: string,
  data: any,
  cancelToken: any,
  config?: AxiosRequestConfig
) {
  const headers = getHeaders(true)
  headers['Content-Type'] =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  return axiosInstance.post(url, data, {
    headers,
    cancelToken,
    responseType: 'blob',
    ...config
  })
}

export async function signIn(url: string, data: any, cancelToken: any) {
  const headers = getHeaders(false)
  return axiosInstance.post(url, data, { headers, cancelToken })
}
