import { Flex, Label, Link, Span, styled } from '@fareye/ui'
import { cancelToken, get } from '@fareye/utils'
import { TOKEN_KEY } from 'config/app'
import React from 'react'
import { FaUserCircle } from 'react-icons/fa'
import { withRouter } from 'react-router'
import { RoutesPath } from '../../config/constants'
import { AppIcon, HeaderDiv, LogoutIcon, MenuIcon } from '../../style/styled'
import { IHeaderProps } from '../../utility/types'
import { AppStore } from './../../store'
import TimeZone from './TimeZone'

const source = cancelToken().source()

export const UserProfileMenu: any = styled.div`
  right: 0px;
  padding: 5px 0;
  min-width: 110px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  flex-direction: column;
  box-shadow: 0 1px 5px rgba(204, 204, 204);
  a,
  span {
    padding: 7px 15px;
    border-radius: 0;
    transition: 0.6s ease;
    justify-content: flex-start;
    color: ${(props: any) => props.bg || props.theme.colors.primary};
    > svg {
      transition: 0.6s ease;
      margin-right: 10px;
      color: ${(props: any) => props.bg || props.theme.colors.primary};
    }
    &:hover {
      background: ${(props: any) => props.bg || props.theme.colors.primary};
      color: #fff;
      transition: 0.8s ease;
      > svg {
        transition: 0.8s ease;
        color: #fff;
      }
      > div {
        opacity: 1;
        transition: 0.6s ease;
      }
    }
  }
`

export const UserMenuLink = styled.div`
  position: relative;
  $:hover {
  }
  > span {
    cursor: pointer;
    padding: 7px;
    display: flex;
    align-items: center;
    label {
      cursor: pointer;
      text-transform: capitalize;
      font-weight: 600;
      color: #fff;
    }
  }
`
function Header({ setMenuOpen }: IHeaderProps) {
  const { store, updateStore } = React.useContext(AppStore)
  const isAuthenticated = localStorage.getItem(TOKEN_KEY)
  const [menuToggle, setMenuToggle] = React.useState(false)
  const hideTopNavigation = new URL(document.location.href).searchParams.get(
    'disableNav'
  )

  async function getUserProfile(newStore?: any) {
    try {
      const { data, status } = await get('/api/v1/profile', source.token, {
        headers: { 'Key-Inflection': 'camel', 'X-Change-Case': true }
      })
      updateStore({
        ...newStore,
        header: {
          ...newStore.header,
          img: newStore?.header?.img,
          userProfile: status === 200 ? data.profile : {}
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  React.useEffect(() => {
    if (isAuthenticated) {
      getUserProfile({
        ...store,
        header: {
          ...store.header,
          getUserProfile
        }
      })
    }
  }, [])

  if (hideTopNavigation) {
    return null
  }
  const { userProfile, backgroundColor, img } = store?.header || {}
  const { lastName, firstName } = userProfile || {}
  const name = `${firstName || ''} ${lastName || ''}`.trim() || 'Guest'
  const bg = backgroundColor || '#006490'
  console.log(img, '********')
  return (
    <HeaderDiv justifyContentSpaceBetween data-testid="app-header" bg={bg}>
      <Flex>
        <MenuIcon onClick={setMenuOpen} />
        <AppIcon src={img} alt="fareye-logo" />
      </Flex>
      <UserMenuLink data-testid="user-menu">
        <Span
          data-testid="user-name"
          onClick={(e: any) => {
            e.preventDefault()
            setMenuToggle(!menuToggle)
          }}
        >
          <Label>Hi! {name}</Label>
        </Span>
        {menuToggle ? (
          <UserProfileMenu bg={bg}>
            <Link to={RoutesPath.PROFILE}>
              <FaUserCircle size={20} />
              Profile
            </Link>
            <TimeZone bg={bg} />
            <Link data-testid="logout" to={RoutesPath.LOGOUT}>
              <LogoutIcon size={20} />
              Logout
            </Link>
          </UserProfileMenu>
        ) : null}
      </UserMenuLink>
    </HeaderDiv>
  )
}
export default withRouter(Header)
