import colors from './antColors.json'
import { MASTER } from '../styledSystem'

const theme = {
  default: {
    background: '#fff',
    border: '',
    boxShadow: 'rgba(225, 225, 225, 0.5)',
    color: colors.gray9,
    default: colors.gray6,
    disabled: '',
    hover: '',
    link: colors.blue6,
    linkDisabled: colors.blue4
  },
  primary: {
    background: colors.blue6,
    border: '',
    boxShadow: 'rgba(58, 67, 86, 1)',
    color: colors.blue6,
    default: colors.white1,
    disabled: colors.blue1,
    hover: colors.blue5
  },
  secondary: {
    background: colors.gray7,
    border: '',
    boxShadow: 'rgba(58, 67, 86, 1)',
    color: colors.gray7,
    default: colors.gray1,
    disabled: colors.gray5,
    hover: colors.gray6
  },
  success: {
    background: colors.green5,
    border: colors.green5,
    boxShadow: 'rgba(58, 67, 86, 1)',
    color: colors.green5,
    default: colors.white1,
    disabled: colors.green3,
    hover: colors.green4
  },
  warning: {
    background: colors.gray7,
    color: colors.gold6
  },
  danger: {
    background: colors.red6,
    border: colors.red6,
    color: colors.red7,
    default: colors.white1,
    disabled: colors.red4,
    hover: colors.red5
  }
}

export function antTheme(themeOptions: any, boxShadow: any) {
  const cardHeaderFooter = [3, 4, 6, 12, 18, 20, 22]
  const cardBody = [6, 8, 12, 24, 36, 40, 44]
  const fontSize = [38, 30, 24, 20, 16, 14, 12, 10]

  const roundButtonSizes: any = {}
  MASTER.SIZE.map((size: string, n: number) => {
    roundButtonSizes[`round-${size}`] = {
      display: 'flex',
      height: (n + 1) * 10,
      width: (n + 1) * 10,
      'border-radius': '50%',
      'justify-content': 'center',
      'align-items': 'center',
      padding: 0,
      'font-size': `${(n + 4) * 2}px`
    }
    return null
  })

  const general = {
    h1FontSizes: [],
    margin: [4, 6, 8, 12, 16, 20],
    padding: [4, 6, 8, 12, 16, 20],
    colors: { ...themeOptions.colors, ...colors },
    boxShadow,
    inputPadding: [6, 11],
    inputLineHeight: [40, 32, 24],
    alertDefault: {
      'box-sizing': 'border-box',
      margin: '0',
      color: colors.gray8,
      'font-size': `${fontSize[5]}px`,
      'font-variant': 'tabular-nums',
      'line-height': '1.5',
      'list-style': 'none',
      position: 'relative',
      'border-radius': '4px',
      'margin-bottom': '16px'
    },
    buttonDefault: {
      display: 'flex',
      alignItems: 'center',
      'justify-content': 'space-between',
      outline: 'none',
      padding: '10px 15px',
      'font-size': `${fontSize[4]}px`,
      'line-height': '1.1',
      'letter-spacing': 'normal',
      'font-stretch': '100%',
      transition: '.4s ease',
      'border-radius': 4,
      border: `1px solid ${colors.gray5}`,
      margin: 0,
      color: theme.default.color,
      'background-color': theme.default.background,
      ...roundButtonSizes,
      '&:hover': {
        cursor: 'pointer',
        transition: '.6s ease',
        'border-color': theme.default.hover
      },
      '&:disabled': {
        cursor: 'not-allowed',
        color: colors.gray6
      }
    },
    cardDefault: {
      frame: {
        display: 'flex',
        'flex-direction': 'column',
        border: `1px solid ${colors.gray5}`,
        margin: '0',
        'border-radius': '2px',
        padding: '0'
      },
      divider: {
        width: '100%',
        height: '1px',
        background: colors.gray5
      },
      header: {
        'font-weight': '500',
        'font-size': `${fontSize[4]}px`,
        background: 'transparent',
        margin: '0'
      },
      body: {
        'font-size': `${fontSize[5]}px`,
        background: 'transparent',
        margin: '0'
      },
      footer: {
        'font-size': `${fontSize[4]}px`,
        background: 'transparent',
        margin: '0'
      }
    },
    datePickerDefault: {
      default: {
        frame: {
          'font-size': '14px',
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'relative'
        },
        dateRangeWrapper: {
          position: 'absolute',
          zIndex: 99,
          transform: 'scale(0.8, 0.8)',
          boxShadow: '0 0 4px 0 rgba(120, 120, 120, 0.5)',
          right: 0,
          top: '120%',
          width: 'max-content',
          borderRadius: '4px'
        }
      },
      fareyeV2: {
        frame: {
          'font-size': '14px',
          position: 'relative'
        },
        dateRangeWrapper: {
          position: 'absolute',
          zIndex: 99,
          transform: 'scale(0.8, 0.8)',
          boxShadow: '0 0 4px 0 rgba(120, 120, 120, 0.5)',
          right: 0,
          top: '120%',
          width: 'max-content',
          borderRadius: '4px'
        }
      }
    },
    listDefault: {
      frame: {
        display: 'flex',
        'flex-direction': 'column',
        border: `1px solid ${colors.gray5}`,
        margin: '0',
        'border-radius': '2px'
      },
      divider: {
        width: '100%',
        height: '1px',
        background: colors.gray5
      },
      item: {
        'font-size': `${fontSize[5]}px`,
        background: 'transparent',
        margin: '0'
      }
    },
    inputDefault: {
      color: 'rgba(0, 0, 0, 0.65)',
      'background-color': colors.gray1,
      height: 'auto',
      border: `1px solid rgba(179,179,179)`,
      'border-radius': '4px',
      '&:hover': {
        border: `1px solid ${colors.blue5}`
      },
      '&:focus': {
        border: `1px solid ${colors.blue5}`
      }
    },
    renderControlDefault: {
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
      width: '100%',
      'font-size': 16,
      'min-height': 16
    },
    space: [0, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 36, 40],
    switchDefault: {
      container: {
        display: 'flex',
        'justify-content': 'flex-start',
        width: '44px',
        'border-radius': '25px',
        padding: '0px',
        border: '1px solid transparent',
        background: '#ccc',
        transition: '.4s ease'
      },
      slider: {
        'border-radius': '50%',
        border: '1px solid transparent',
        background: colors.white1
      }
    },
    tabs: {
      tabsFrame: {},
      tabsPane: {
        display: 'flex'
      },
      tabsPaneItem: {}
    },
    timelineDefault: {
      frame: {
        display: 'flex',
        'flex-direction': 'column'
      },
      timeInstance: {
        position: 'relative'
      },
      dot: {
        position: 'absolute',
        'box-sizing': 'border-box',
        background: 'white'
      },
      line: {
        position: 'absolute'
      }
    },
    typographyDefault: {
      h1: {
        'font-weight': 600,
        'font-size': '38px',
        'line-height': '1.23'
      },
      h2: {
        'font-weight': '600',
        'font-size': '30px',
        'line-height': '1.35'
      },
      h3: {
        'font-weight': '600',
        'font-size': '24px',
        'line-height': '1.35'
      },
      h4: {
        'font-weight': '500',
        'font-size': '20px',
        'line-height': '1.4'
      },
      h5: {
        'font-weight': '500',
        'font-size': `${fontSize[4]}px`
      },
      h6: {
        'font-weight': '500',
        'font-size': '14px'
      },
      primary: {
        color: colors.blue6
      },
      secondary: {
        color: colors.gray7
      },
      warning: {
        color: colors.gold6
      },
      danger: {
        color: colors.red7
      },
      success: {
        color: '#0b8235'
      },
      disabled: {
        color: 'rgba(0,0,0,0.25)',
        cursor: 'not-allowed',
        'user-select': 'none'
      },
      mark: {
        color: colors.gray8,
        padding: '0',
        'background-color': colors.gold3
      },
      code: {
        color: colors.gray8,
        margin: '0 .2em',
        padding: '.2em .4em .1em',
        background: 'rgba(0,0,0,0.06)',
        border: '1px solid rgba(0,0,0,0.06)',
        'border-radius': '3px'
      },
      underline: {
        color: colors.gray8,
        'text-decoration': 'underline',
        'text-decoration-skip-ink': 'auto'
      },
      delete: {
        color: colors.gray8,
        'text-decoration': 'line-through'
      },
      strong: {
        'font-weight': '600',
        color: colors.gray8
      },
      ellipsis: {
        width: '100%',
        'white-space': 'nowrap',
        'text-overflow': 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block'
      }
    }
  }

  const cards = () => {
    const tempCard: any = {}
    MASTER.SIZE.forEach((cardSize: string, i: number) => {
      tempCard[cardSize] = {}
      Object.keys(general.cardDefault).forEach((option: string, j: number) => {
        tempCard[cardSize][option] = {}
        const padding = ['frame', 'divider'].includes(option)
          ? '0px'
          : option === 'body'
          ? `${cardBody[i]}px ${cardBody[i]}px`
          : `${cardHeaderFooter[i]}px ${cardHeaderFooter[i] * 2}px`
        tempCard[cardSize][option] = {
          padding,
          ...(general.cardDefault as any)[option]
        }
      })
    })
    return tempCard
  }
  const listItems = () => {
    const tempList: any = {}
    MASTER.SIZE.forEach((size: string, i: number) => {
      tempList[size] = {}
      Object.keys(general.listDefault).forEach((option: string, j: number) => {
        tempList[size][option] = {}
        const padding = ['frame', 'divider'].includes(option)
          ? '0px'
          : `${cardBody[i]}px ${cardBody[i]}px`
        tempList[size][option] = {
          padding,
          ...(general.listDefault as any)[option]
        }
      })
    })
    return tempList
  }

  const renderControlSizes: any = {}
  const modalSizes: any = {}
  MASTER.SIZE.map((size: string, n: number) => {
    renderControlSizes[size] = {
      ...general.renderControlDefault,
      'font-size': (n + 5) * 2,
      'min-height': `${n * 15 + 5}vh`
    }
    modalSizes[size] = {
      'max-height': `${(n + 3) * 10}vh`,
      width: `${(n + 3) * 10}vw`
    }
    return null
  })
  // const switchsizes = // work on switch iteration for sizes and coloring loop
  return {
    ...general,
    alert: {
      success: {
        ...general.alertDefault,
        'background-color': colors.green1,
        border: '1px solid #b7eb8f'
      },
      info: {
        ...general.alertDefault,
        'background-color': colors.blue1,
        border: `1px solid ${colors.blue3}`
      },
      warning: {
        ...general.alertDefault,
        'background-color': colors.gold1,
        border: `1px solid ${colors.gold3}`
      },
      error: {
        ...general.alertDefault,
        'background-color': colors.red1,
        border: `1px solid ${colors.red3}`
      }
    },
    breakpoints: ['768px', '992px', '1200px', '1440px'],
    card: cards(),
    buttons: {
      danger: {
        ...general.buttonDefault,
        color: colors.red6,
        'background-color': colors.gray3,
        '&:hover': {
          cursor: 'pointer',
          color: colors.gray1,
          'background-color': colors.red5,
          'border-color': colors.red5
        },
        '&:disabled': {
          ...general.buttonDefault['&:disabled'],
          color: 'rgba(0, 0, 0, 0.25)',
          'background-color': general.colors.gray3,
          'border-color': general.colors.gray5
        },
        ghost: {
          color: colors.red6,
          'background-color': general.colors.transparent,
          'border-color': colors.red6
        }
      },
      dashed: {
        ...general.buttonDefault,
        border: `1px dashed ${colors.gray5}`,
        color: general.colors.grey,
        'background-color': general.colors.white1,
        '&:disabled': {
          ...general.buttonDefault['&:disabled'],
          color: 'rgba(0, 0, 0, 0.25)',
          'background-color': general.colors.gray3,
          'border-color': general.colors.gray5
        },
        ghost: {
          color: general.colors.white1,
          'background-color': general.colors.transparent,
          border: '1px dashed white'
        }
      },
      default: {
        ...general.buttonDefault,
        color: general.colors.grey,
        'background-color': general.colors.white1,
        '&:disabled': {
          ...general.buttonDefault['&:disabled'],
          color: 'rgba(0, 0, 0, 0.25)',
          'background-color': general.colors.gray3,
          'border-color': general.colors.gray5
        },
        ghost: {
          color: general.colors.white1,
          'background-color': general.colors.transparent,
          border: '1px solid white'
        }
      },
      link: {
        ...general.buttonDefault,
        border: '0px solid transparent',
        color: general.colors.blueLink,
        'background-color': general.colors.transparent,
        '&:hover': {
          cursor: 'pointer',
          color: colors.blue6,
          'background-color': general.colors.transparent
        },
        '&:disabled': {
          ...general.buttonDefault['&:disabled'],
          color: 'rgba(0, 0, 0, 0.25)',
          'background-color': general.colors.transparent,
          'border-color': general.colors.transparent
        },
        ghost: {
          color: colors.gray3,
          'border-color': colors.gray3,
          'background-color': general.colors.transparent
        }
      },
      primary: {
        ...general.buttonDefault,
        border: '0px solid transparent',
        color: general.colors.white1,
        'background-color': general.colors.blue6,
        '&:disabled': {
          ...general.buttonDefault['&:disabled'],
          color: 'rgba(0, 0, 0, 0.25)',
          'background-color': general.colors.gray3,
          'border-color': general.colors.gray5
        },
        '&:hover': {
          cursor: 'pointer',
          color: colors.gray1,
          'background-color': colors.blue5,
          'border-color': colors.blue5
        },
        ghost: {
          color: general.colors.blue,
          'background-color': general.colors.transparent,
          border: `1px solid ${general.colors.blue}`
        }
      },
      secondary: {
        ...general.buttonDefault,
        border: '0px solid transparent',
        color: general.colors.gray10,
        'background-color': general.colors.gray4,
        '&:disabled': {
          ...general.buttonDefault['&:disabled'],
          color: 'rgba(0, 0, 0, 0.25)',
          'background-color': general.colors.gray3,
          'border-color': general.colors.gray5
        },
        '&:hover': {
          cursor: 'pointer',
          color: colors.gray9,
          'background-color': colors.gray5,
          'border-color': colors.gray5
        },
        ghost: {
          color: general.colors.gray4,
          'background-color': general.colors.transparent,
          border: `1px solid ${general.colors.gray4}`
        }
      }
    },
    colors: {
      ...general.colors,
      ...theme,
      primary: colors.blue6,
      secondary: '#000'
    },
    datePicker: general.datePickerDefault,
    dropdown: {
      dropdownFrame: {
        position: 'relative',
        display: 'flex',
        minWidth: '100px'
      },
      'Bottom-Center': {
        justifyContent: 'center'
      },
      'Bottom-Right': {
        justifyContent: 'flex-end',
        top: '90%',
        right: 0
      },
      'Top-Center': {
        justifyContent: 'center'
      },
      'Top-Right': {
        justifyContent: 'flex-end',
        bottom: '90%',
        right: 0
      },
      dropdownLabel: {
        color: colors.blue6,
        'text-decoration': 'none',
        'background-color': 'transparent',
        outline: 'none',
        cursor: 'pointer',
        width: '100%'
      },
      dropdownOptionsFrame: {
        display: 'none',
        position: 'absolute',
        margin: '0',
        padding: '4px 0',
        'text-align': 'left',
        'list-style-type': 'none',
        'background-color': colors.gray1,
        'background-clip': 'padding-box',
        'border-radius': '4px',
        outline: 'none',
        width: 'max-content',
        'box-shadow': '0 2px 8px rgba(0,0,0,0.15)',
        zIndex: 99
      },
      dropdownItem: {
        margin: '0',
        padding: '5px 12px',
        color: colors.gray8,
        fontWeight: 'normal',
        'font-size': `${fontSize[5]}px`,
        lineHeight: '22px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        transition: 'all .3s',
        '&:hover': {
          'background-color': colors.blue1
        }
      }
    },
    global: {
      displayType: MASTER.DISPLAY,
      typographySizing: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'label'],
      padding: MASTER.SIZE,
      fontSizes: MASTER.SIZE,
      sizing: MASTER.SIZE,
      pattern: MASTER.PATTERN,
      position: MASTER.POSITION,
      zIndex: MASTER.ZINDEX,
      color: '#000',
      background: '#fff'
    },
    groupedButtons: {
      ...general.buttonDefault,
      margin: 0
    },
    input: {
      checkbox: {
        opacity: 1,
        position: 'relative',
        transform: 'scale(1)',
        '&:before': {
          position: 'absolute',
          content: '',
          background: colors.white1,
          'box-shadow': '0 0 2px 0 rgba(128, 128, 128, 0.4)',
          height: '16px',
          width: '16px',
          '&:disabled': {
            background: 'rgba(239, 239, 239, 1)'
          }
        },
        '&:after': {
          position: 'absolute',
          content: '',
          top: '42%',
          left: '42%',
          background: 'rgba(45, 22, 128, 0.9)',
          height: '40%',
          width: '40%'
        }
      },
      default: {
        'box-shadow': 'none',
        border: '1px solid transparent',
        color: general.colors.black,
        'background-color': colors.gray1
      },
      number: {
        ...general.inputDefault,
        'text-align': 'left',
        'background-color': 'transparent',
        'border-radius': '4px',
        outline: ' 0',
        transition: 'all .3s linear',
        '&:hover': {
          border: `1px solid ${colors.blue5}`
        },
        '&:disabled': {
          color: 'rgb(0,0,0,0.25)',
          cursor: 'not-allowed',
          border: '1px solid rgb(0,0,0,0.25)',
          placeholder: '5',
          'background-color': colors.gray3
        }
      },
      switch: {
        primary: {
          on: {
            container: {
              xxs: {
                ...general.switchDefault.container,
                width: '20px',
                'border-radius': '10px',
                'justify-content': 'flex-end',
                'background-color': colors.blue6
              },
              xs: {
                ...general.switchDefault.container,
                width: '28px',
                'border-radius': '14px',
                'justify-content': 'flex-end',
                'background-color': colors.blue6
              },
              s: {
                ...general.switchDefault.container,
                width: '36px',
                'border-radius': '18px',
                'justify-content': 'flex-end',
                'background-color': colors.blue6
              },
              m: {
                ...general.switchDefault.container,
                width: '44px',
                'border-radius': '22px',
                'justify-content': 'flex-end',
                'background-color': colors.blue6
              },
              l: {
                ...general.switchDefault.container,
                width: '52px',
                'border-radius': '26px',
                'justify-content': 'flex-end',
                'background-color': colors.blue6
              },
              xl: {
                ...general.switchDefault.container,
                width: '60px',
                'border-radius': '30px',
                'justify-content': 'flex-end',
                'background-color': colors.blue6
              },
              xxl: {
                ...general.switchDefault.container,
                width: '68px',
                'border-radius': '34px',
                'justify-content': 'flex-end',
                'background-color': colors.blue6
              }
            },
            slider: {
              xxs: {
                ...general.switchDefault.slider,
                height: '10px',
                width: '10px'
              },
              xs: {
                ...general.switchDefault.slider,
                height: '14px',
                width: '14px'
              },
              s: {
                ...general.switchDefault.slider,
                height: '18px',
                width: '18px'
              },
              m: {
                ...general.switchDefault.slider,
                height: '22px',
                width: '22px'
              },
              l: {
                ...general.switchDefault.slider,
                height: '26px',
                width: '26px'
              },
              xl: {
                ...general.switchDefault.slider,
                height: '30px',
                width: '30px'
              },
              xxl: {
                ...general.switchDefault.slider,
                height: '34px',
                width: '34px'
              }
            }
          },
          off: {
            container: {
              xxs: {
                ...general.switchDefault.container,
                width: '20px',
                'border-radius': '10px'
              },
              xs: {
                ...general.switchDefault.container,
                width: '28px',
                'border-radius': '14px'
              },
              s: {
                ...general.switchDefault.container,
                width: '36px',
                'border-radius': '18px'
              },
              m: {
                ...general.switchDefault.container,
                width: '44px',
                'border-radius': '22px'
              },
              l: {
                ...general.switchDefault.container,
                width: '52px',
                'border-radius': '26px'
              },
              xl: {
                ...general.switchDefault.container,
                width: '60px',
                'border-radius': '30px'
              },
              xxl: {
                ...general.switchDefault.container,
                width: '68px',
                'border-radius': '34px'
              }
            },
            slider: {
              xxs: {
                ...general.switchDefault.slider,
                height: '10px',
                width: '10px'
              },
              xs: {
                ...general.switchDefault.slider,
                height: '14px',
                width: '14px'
              },
              s: {
                ...general.switchDefault.slider,
                height: '18px',
                width: '18px'
              },
              m: {
                ...general.switchDefault.slider,
                height: '22px',
                width: '22px'
              },
              l: {
                ...general.switchDefault.slider,
                height: '26px',
                width: '26px'
              },
              xl: {
                ...general.switchDefault.slider,
                height: '30px',
                width: '30px'
              },
              xxl: {
                ...general.switchDefault.slider,
                height: '34px',
                width: '34px'
              }
            }
          }
        },
        dashed: {
          on: {
            container: {
              xxs: {
                ...general.switchDefault.container,
                width: '20px',
                'border-radius': '10px',
                'justify-content': 'flex-end',
                border: '1px dashed rgb(24,144,245)',
                'background-color': colors.white1
              },
              xs: {
                ...general.switchDefault.container,
                width: '28px',
                'border-radius': '14px',
                'justify-content': 'flex-end',
                border: '1px dashed rgb(24,144,245)',
                'background-color': colors.white1
              },
              s: {
                ...general.switchDefault.container,
                width: '36px',
                'border-radius': '18px',
                'justify-content': 'flex-end',
                border: '1px dashed rgb(24,144,245)',
                'background-color': colors.white1
              },
              m: {
                ...general.switchDefault.container,
                width: '44px',
                'border-radius': '22px',
                'justify-content': 'flex-end',
                border: '1px dashed rgb(24,144,245)',
                'background-color': colors.white1
              },
              l: {
                ...general.switchDefault.container,
                width: '52px',
                'border-radius': '26px',
                'justify-content': 'flex-end',
                border: '1px dashed rgb(24,144,245)',
                'background-color': colors.white1
              },
              xl: {
                ...general.switchDefault.container,
                width: '60px',
                'border-radius': '30px',
                'justify-content': 'flex-end',
                border: '1px dashed rgb(24,144,245)',
                'background-color': colors.white1
              },
              xxl: {
                ...general.switchDefault.container,
                width: '68px',
                'border-radius': '34px',
                'justify-content': 'flex-end',
                border: '1px dashed rgb(24,144,245)',
                'background-color': colors.white1
              }
            },
            slider: {
              xxs: {
                ...general.switchDefault.slider,
                height: '10px',
                width: '10px',
                'background-color': colors.blue6
              },
              xs: {
                ...general.switchDefault.slider,
                height: '14px',
                width: '14px',
                'background-color': colors.blue6
              },
              s: {
                ...general.switchDefault.slider,
                height: '18px',
                width: '18px',
                'background-color': colors.blue6
              },
              m: {
                ...general.switchDefault.slider,
                height: '22px',
                width: '22px',
                'background-color': colors.blue6
              },
              l: {
                ...general.switchDefault.slider,
                height: '26px',
                width: '26px',
                'background-color': colors.blue6
              },
              xl: {
                ...general.switchDefault.slider,
                height: '30px',
                width: '30px',
                'background-color': colors.blue6
              },
              xxl: {
                ...general.switchDefault.slider,
                height: '34px',
                width: '34px',
                'background-color': colors.blue6
              }
            }
          },
          off: {
            container: {
              xxs: {
                ...general.switchDefault.container,
                width: '20px',
                'border-radius': '10px',
                'background-color': colors.white1,
                border: '1px dashed rgb(24,144,245)'
              },
              xs: {
                ...general.switchDefault.container,
                width: '28px',
                'border-radius': '14px',
                'background-color': colors.white1,
                border: '1px dashed rgb(24,144,245)'
              },
              s: {
                ...general.switchDefault.container,
                width: '36px',
                'border-radius': '18px',
                'background-color': colors.white1,
                border: '1px dashed rgb(24,144,245)'
              },
              m: {
                ...general.switchDefault.container,
                width: '44px',
                'border-radius': '22px',
                'background-color': colors.white1,
                border: '1px dashed rgb(24,144,245)'
              },
              l: {
                ...general.switchDefault.container,
                width: '52px',
                'border-radius': '26px',
                'background-color': colors.white1,
                border: '1px dashed rgb(24,144,245)'
              },
              xl: {
                ...general.switchDefault.container,
                width: '60px',
                'border-radius': '30px',
                'background-color': colors.white1,
                border: '1px dashed rgb(24,144,245)'
              },
              xxl: {
                ...general.switchDefault.container,
                width: '68px',
                'border-radius': '34px',
                'background-color': colors.white1,
                border: '1px dashed rgb(24,144,245)'
              }
            },
            slider: {
              xxs: {
                ...general.switchDefault.slider,
                height: '10px',
                width: '10px',
                'background-color': '#ccc'
              },
              xs: {
                ...general.switchDefault.slider,
                height: '14px',
                width: '14px',
                'background-color': '#ccc'
              },
              s: {
                ...general.switchDefault.slider,
                height: '18px',
                width: '18px',
                'background-color': '#ccc'
              },
              m: {
                ...general.switchDefault.slider,
                height: '22px',
                width: '22px',
                'background-color': '#ccc'
              },
              l: {
                ...general.switchDefault.slider,
                height: '26px',
                width: '26px',
                'background-color': '#ccc'
              },
              xl: {
                ...general.switchDefault.slider,
                height: '30px',
                width: '30px',
                'background-color': '#ccc'
              },
              xxl: {
                ...general.switchDefault.slider,
                height: '34px',
                width: '34px',
                'background-color': '#ccc'
              }
            }
          }
        },
        danger: {
          on: {
            container: {
              xxs: {
                ...general.switchDefault.container,
                width: '20px',
                'border-radius': '10px',
                'justify-content': 'flex-end',
                'background-color': colors.red5
              },
              xs: {
                ...general.switchDefault.container,
                width: '28px',
                'border-radius': '14px',
                'justify-content': 'flex-end',
                'background-color': colors.red5
              },
              s: {
                ...general.switchDefault.container,
                width: '36px',
                'border-radius': '18px',
                'justify-content': 'flex-end',
                'background-color': colors.red5
              },
              m: {
                ...general.switchDefault.container,
                width: '44px',
                'border-radius': '22px',
                'justify-content': 'flex-end',
                'background-color': colors.red5
              },
              l: {
                ...general.switchDefault.container,
                width: '52px',
                'border-radius': '26px',
                'justify-content': 'flex-end',
                'background-color': colors.red5
              },
              xl: {
                ...general.switchDefault.container,
                width: '60px',
                'border-radius': '30px',
                'justify-content': 'flex-end',
                'background-color': colors.red5
              },
              xxl: {
                ...general.switchDefault.container,
                width: '68px',
                'border-radius': '34px',
                'justify-content': 'flex-end',
                'background-color': colors.red5
              }
            },
            slider: {
              xxs: {
                ...general.switchDefault.slider,
                height: '10px',
                width: '10px'
              },
              xs: {
                ...general.switchDefault.slider,
                height: '14px',
                width: '14px'
              },
              s: {
                ...general.switchDefault.slider,
                height: '18px',
                width: '18px'
              },
              m: {
                ...general.switchDefault.slider,
                height: '22px',
                width: '22px'
              },
              l: {
                ...general.switchDefault.slider,
                height: '26px',
                width: '26px'
              },
              xl: {
                ...general.switchDefault.slider,
                height: '30px',
                width: '30px'
              },
              xxl: {
                ...general.switchDefault.slider,
                height: '34px',
                width: '34px'
              }
            }
          },
          off: {
            container: {
              xxs: {
                ...general.switchDefault.container,
                width: '20px',
                'border-radius': '10px'
              },
              xs: {
                ...general.switchDefault.container,
                width: '28px',
                'border-radius': '14px'
              },
              s: {
                ...general.switchDefault.container,
                width: '36px',
                'border-radius': '18px'
              },
              m: {
                ...general.switchDefault.container,
                width: '44px',
                'border-radius': '22px'
              },
              l: {
                ...general.switchDefault.container,
                width: '52px',
                'border-radius': '26px'
              },
              xl: {
                ...general.switchDefault.container,
                width: '60px',
                'border-radius': '30px'
              },
              xxl: {
                ...general.switchDefault.container,
                width: '68px',
                'border-radius': '34px'
              }
            },
            slider: {
              xxs: {
                ...general.switchDefault.slider,
                height: '10px',
                width: '10px'
              },
              xs: {
                ...general.switchDefault.slider,
                height: '14px',
                width: '14px'
              },
              s: {
                ...general.switchDefault.slider,
                height: '18px',
                width: '18px'
              },
              m: {
                ...general.switchDefault.slider,
                height: '22px',
                width: '22px'
              },
              l: {
                ...general.switchDefault.slider,
                height: '26px',
                width: '26px'
              },
              xl: {
                ...general.switchDefault.slider,
                height: '30px',
                width: '30px'
              },
              xxl: {
                ...general.switchDefault.slider,
                height: '34px',
                width: '34px'
              }
            }
          }
        }
      },
      text: {
        ...general.inputDefault
      },
      textarea: {
        ...general.inputDefault,
        'min-height': '32px'
      }
    },
    l: {
      'letter-spacing': 'normal',
      'font-stretch': '100%',
      padding: '11.7px 16px',
      'font-size': `${fontSize[4]}px`,
      'border-radius': '4px'
    },
    label: {
      'font-weight': '600',
      default: {
        'font-weight': '600'
      },
      ...general.typographyDefault
    },
    list: {
      listFrame: {
        position: 'relative',
        boxSizing: 'border-box',
        fontSize: `${fontSize[5]}px`,
        fontVariant: 'tabular-nums',
        border: `1px solid ${colors.gray5}`
      },
      listItem: {
        alignItems: 'center'
      },
      ...listItems()
    },
    m: {
      'letter-spacing': 'normal',
      'font-stretch': '100%',
      padding: '9px 16px',
      'font-size': `${fontSize[5]}px`,
      'border-radius': '4px'
    },
    modal: {
      modalFrame: {
        position: 'fixed',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        height: '100vh',
        width: '100vw',
        background: 'rgba(0,0,0,.8)',
        zIndex: MASTER.ZINDEX.modalBackdrop,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      content: {
        overflow: 'auto',
        position: 'relative',
        transform: 'translate(0%, 0%)',
        height: 'auto',
        background: colors.white1
      },
      cross: {
        position: 'absolute',
        right: '25px',
        top: '10px',
        'z-index': '100',
        cursor: 'pointer'
      },
      ...modalSizes,
      drawer: {
        right: 0
      },
      'Bottom-Left': {
        drawerFrame: {
          justifyContent: 'flex-start',
          alignItems: 'flex-end'
        },
        drawerContent: {
          transform: 'translate(0%, 0%)'
        }
      },
      'Bottom-Center': {
        drawerFrame: {
          justifyContent: 'center',
          alignItems: 'flex-end'
        },
        drawerContent: {
          transform: 'translate(0%, 0%)'
        }
      },
      'Bottom-Right': {
        drawerFrame: {
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        },
        drawerContent: {
          transform: 'translate(0%, 0%)'
        }
      },
      'Top-Left': {
        drawerFrame: {
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        },
        drawerContent: {
          transform: 'translate(0%, 0%)'
        }
      },
      'Top-Center': {
        drawerFrame: {
          justifyContent: 'center',
          alignItems: 'flex-start'
        },
        drawerContent: {
          transform: 'translate(0%, 0%)'
        }
      },
      'Top-Right': {
        drawerFrame: {
          justifyContent: 'flex-end',
          alignItems: 'flex-start'
        },
        drawerContent: {
          transform: 'translate(0%, 0%)'
        }
      },
      'Right-Center': {
        drawerFrame: {
          justifyContent: 'flex-end',
          alignItems: 'center'
        },
        drawerContent: {
          transform: 'translate(0%, 0%)'
        }
      },
      'Left-Center': {
        drawerFrame: {
          justifyContent: 'flex-start',
          alignItems: 'center'
        },
        drawerContent: {
          transform: 'translate(0%, 0%)'
        }
      }
    },
    paragraph: {
      default: {
        'margin-top': '7px',
        'margin-bottom': '7px'
      },
      ...general.typographyDefault,
      'margin-top': '7px',
      'margin-bottom': '7px'
    },
    popover: {
      arrow: {
        'Bottom-Left': {
          top: 'calc(100% + 0.5px)',
          left: '20%',
          transform: 'rotate(45deg)',
          clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
        },
        'Bottom-Center': {
          top: 'calc(100% + 0.5px)',
          left: '50%',
          transform: 'translateX(-50%) rotate(45deg)',
          clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
        },
        'Bottom-Right': {
          top: 'calc(100% + 0.5px)',
          right: '20%',
          transform: 'rotate(45deg)',
          clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
        },
        'Left-Top': {
          right: 'calc(100% + 0.5px)',
          top: '20%',
          transform: 'rotate(135deg)',
          clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
        },
        'Left-Center': {
          right: 'calc(100% + 0.5px)',
          top: '50%',
          transform: 'translateY(-50%) rotate(135deg)',
          clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
        },
        'Left-Bottom': {
          right: 'calc(100% + 0.5px)',
          bottom: '20%',
          transform: 'rotate(135deg)',
          clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
        },
        'Right-Top': {
          left: 'calc(100% + 0.5px)',
          top: '20%',
          transform: 'rotate(-45deg)',
          clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
        },
        'Right-Center': {
          left: 'calc(100% + 0.5px)',
          top: '50%',
          transform: 'translateY(-50%) rotate(-45deg)',
          clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
        },
        'Right-Bottom': {
          left: 'calc(100% + 0.5px)',
          bottom: '20%',
          transform: 'rotate(-45deg)',
          clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
        },
        'Top-Left': {
          bottom: 'calc(100% + 0.5px)',
          left: '20%',
          transform: 'rotate(-135deg)',
          clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
        },
        'Top-Center': {
          bottom: 'calc(100% + 0.5px)',
          left: '50%',
          transform: 'translateX(-50%) rotate(-135deg)',
          clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
        },
        'Top-Right': {
          bottom: 'calc(100% + 0.5px)',
          right: '20%',
          transform: 'rotate(-135deg)',
          clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
        }
      },
      body: {
        'Bottom-Left': {
          top: 'calc(100% + 5px)',
          left: 0
        },
        'Bottom-Center': {
          top: 'calc(100% + 5px)',
          left: '50%',
          transform: 'translateX(-50%)'
        },
        'Bottom-Right': {
          top: 'calc(100% + 5px)',
          right: 0
        },
        'Left-Top': {
          right: 'calc(100% + 5px)',
          top: 0
        },
        'Left-Center': {
          right: 'calc(100% + 5px)',
          top: '50%',
          transform: 'translateY(-50%)'
        },
        'Left-Bottom': {
          right: 'calc(100% + 5px)',
          bottom: 0
        },
        'Right-Top': {
          left: 'calc(100% + 5px)',
          top: 0
        },
        'Right-Center': {
          left: 'calc(100% + 5px)',
          top: '50%',
          transform: 'translateY(-50%)'
        },
        'Right-Bottom': {
          left: 'calc(100% + 5px)',
          bottom: 0
        },
        'Top-Left': {
          bottom: 'calc(100% + 5px)',
          left: 0
        },
        'Top-Center': {
          bottom: 'calc(100% + 5px)',
          left: '50%',
          transform: 'translateX(-50%)'
        },
        'Top-Right': {
          bottom: 'calc(100% + 5px)',
          right: 0
        }
      },
      wrapper: {}
    },
    primary: {
      color: general.colors.white1,
      'background-color': general.colors.blue6
    },
    rate: {
      size: '50px',
      'stroke-width': '20px ',
      stroke: '#ffff00'
    },
    renderControl: {
      default: general.renderControlDefault,
      ...renderControlSizes
    },
    span: {
      default: {
        color: 'rgba(0,0,0,0.85)',
        'font-weight': 'normal',
        'font-size': `${fontSize[6]}px`
      },
      ...general.typographyDefault
    },
    s: {
      'letter-spacing': 'normal',
      'font-stretch': '100%',
      padding: '4.8px 8px',
      'font-size': `${fontSize[5]}px`,
      'border-radius': '4px'
    },
    select: {},
    tabs: {
      'Bottom-Left': {
        tabsFrame: {
          ...general.tabs.tabsFrame,
          flexDirection: 'column-reverse'
        },
        tabsPane: {
          ...general.tabs.tabsPane
        },
        tabsPaneItem: {
          ...general.tabs.tabsPaneItem
        }
      },
      'Bottom-Center': {
        tabsFrame: {
          ...general.tabs.tabsFrame,
          flexDirection: 'column-reverse'
        },
        tabsPane: {
          ...general.tabs.tabsPane,
          transform: 'translate(0%, 0%)'
        },
        tabsPaneItem: {
          ...general.tabs.tabsPaneItem
        }
      },
      'Bottom-Right': {
        tabsFrame: {
          ...general.tabs.tabsFrame,
          flexDirection: 'column-reverse'
        },
        tabsPane: {
          ...general.tabs.tabsPane,
          transform: 'translate(0%, 0%)'
        },
        tabsPaneItem: {
          ...general.tabs.tabsPaneItem
        }
      },
      'Top-Left': {
        tabsFrame: {
          ...general.tabs.tabsFrame,
          flexDirection: 'column'
        },
        tabsPane: {
          ...general.tabs.tabsPane,
          transform: 'translate(0%, 0%)'
        },
        tabsPaneItem: {
          ...general.tabs.tabsPaneItem
        }
      },
      'Top-Center': {
        tabsFrame: {
          ...general.tabs.tabsFrame,
          flexDirection: 'column'
        },
        tabsPane: {
          ...general.tabs.tabsPane,
          transform: 'translate(0%, 0%)'
        },
        tabsPaneItem: {
          ...general.tabs.tabsPaneItem
        }
      },
      'Top-Right': {
        tabsFrame: {
          ...general.tabs.tabsFrame,
          flexDirection: 'column'
        },
        tabsPane: {
          ...general.tabs.tabsPane,
          transform: 'translate(0%, 0%)'
        },
        tabsPaneItem: {
          ...general.tabs.tabsPaneItem
        }
      },
      'Right-Center': {
        tabsFrame: {
          ...general.tabs.tabsFrame,
          flexDirection: 'row-reverse'
        },
        tabsPane: {
          ...general.tabs.tabsPane,
          transform: 'translate(0%, 0%)'
        },
        tabsPaneItem: {
          ...general.tabs.tabsPaneItem
        }
      },
      'Left-Center': {
        tabsFrame: {
          ...general.tabs.tabsFrame,
          justifyContent: 'flex-start',
          alignItems: 'center'
        },
        tabsPane: {
          ...general.tabs.tabsPane,
          transform: 'translate(0%, 0%)'
        },
        tabsPaneItem: {
          ...general.tabs.tabsPaneItem
        }
      }
    },
    timeline: {
      xxs: {
        frame: {
          ...general.timelineDefault.frame
        },
        timeInstance: {
          ...general.timelineDefault.timeInstance,
          'margin-left': '10px'
        },
        timeInstanceH: {
          ...general.timelineDefault.timeInstance,
          'margin-top': '10px'
        },
        dot: {
          ...general.timelineDefault.dot,
          left: '-7px',
          top: '4px',
          height: '4px',
          width: '4px',
          'border-radius': '2px',
          border: `1px solid ${colors.gray6}`
        },
        dotH: {
          ...general.timelineDefault.dot,
          left: '0px',
          top: '-7px',
          height: '4px',
          width: '4px',
          'border-radius': '2px',
          border: `1px solid ${colors.gray6}`,
          'margin-left': '50%'
        },
        line: {
          ...general.timelineDefault.line,
          left: '-5.5px',
          top: '8px',
          'border-left': `1px solid ${colors.gray5}`,
          height: 'calc(100% - 3px)'
        },
        lineH: {
          ...general.timelineDefault.line,
          top: '-5px',
          'border-top': `1px solid ${colors.gray5}`,
          width: 'calc(100% + 4px)',
          'margin-left': 'calc(50% + 4px)'
        },
        lineDotted: {
          border: `0.5px dotted ${colors.gray5}`
        }
      },
      xs: {
        frame: {
          ...general.timelineDefault.frame
        },
        timeInstance: {
          ...general.timelineDefault.timeInstance,
          'margin-left': '12px'
        },
        timeInstanceH: {
          ...general.timelineDefault.timeInstance,
          'margin-top': '12px'
        },
        dot: {
          ...general.timelineDefault.dot,
          left: '-8px',
          top: '5px',
          height: '5px',
          width: '5px',
          'border-radius': '3px',
          border: `1px solid ${colors.gray6}`
        },
        dotH: {
          ...general.timelineDefault.dot,
          left: '0px',
          top: '-8px',
          height: '5px',
          width: '5px',
          'border-radius': '3px',
          border: `1px solid ${colors.gray6}`,
          'margin-left': '50%'
        },
        line: {
          ...general.timelineDefault.line,
          left: '-6px',
          top: '10px',
          'border-left': `1px solid ${colors.gray5}`,
          height: 'calc(100% - 5px)'
        },
        lineH: {
          ...general.timelineDefault.line,
          top: '-6px',
          'border-top': `1px solid ${colors.gray5}`,
          width: 'calc(100% + 5px)',
          'margin-left': 'calc(50% + 5px)'
        },
        lineDotted: {
          border: `0.5px dotted ${colors.gray5}`
        }
      },
      s: {
        frame: {
          ...general.timelineDefault.frame
        },
        timeInstance: {
          ...general.timelineDefault.timeInstance,
          'margin-left': '14px'
        },
        timeInstanceH: {
          ...general.timelineDefault.timeInstance,
          'margin-top': '14px'
        },
        dot: {
          ...general.timelineDefault.dot,
          left: '-9px',
          top: '6px',
          height: '6px',
          width: '6px',
          'border-radius': '3px',
          border: `1.5px solid ${colors.gray7}`
        },
        dotH: {
          ...general.timelineDefault.dot,
          left: '0px',
          top: '-9px',
          height: '6px',
          width: '6px',
          'border-radius': '3px',
          border: `1px solid ${colors.gray6}`,
          'margin-left': '50%'
        },
        line: {
          ...general.timelineDefault.line,
          left: '-7px',
          top: '12px',
          'border-left': `1.5px solid ${colors.gray6}`,
          height: 'calc(100% - 5px)'
        },
        lineH: {
          ...general.timelineDefault.line,
          left: '0px',
          top: '-7px',
          'border-top': `1px solid ${colors.gray5}`,
          width: 'calc(100% + 6px)',
          'margin-left': 'calc(50% + 6px)'
        },
        lineDotted: {
          border: `0.75px dotted ${colors.gray6}`
        }
      },
      m: {
        frame: {
          ...general.timelineDefault.frame
        },
        timeInstance: {
          ...general.timelineDefault.timeInstance,
          'margin-left': '16px'
        },
        timeInstanceH: {
          ...general.timelineDefault.timeInstance,
          'margin-top': '16px'
        },
        dot: {
          ...general.timelineDefault.dot,
          left: '-10px',
          top: '7px',
          height: '7px',
          width: '7px',
          'border-radius': '4px',
          border: `1.5px solid ${colors.gray7}`
        },
        dotH: {
          ...general.timelineDefault.dot,
          left: '0px',
          top: '-10px',
          height: '7px',
          width: '7px',
          'border-radius': '4px',
          border: `1px solid ${colors.gray6}`,
          'margin-left': '50%'
        },
        line: {
          ...general.timelineDefault.line,
          left: '-7.5px',
          top: '14px',
          'border-left': `1.5px solid ${colors.gray6}`,
          height: 'calc(100% - 5px)'
        },
        lineH: {
          ...general.timelineDefault.line,
          left: '0px',
          top: '-8px',
          'border-top': `1px solid ${colors.gray5}`,
          width: 'calc(100% + 7px)',
          'margin-left': 'calc(50% + 7px)'
        },
        lineDotted: {
          border: `0.75px dotted ${colors.gray6}`
        }
      },
      l: {
        frame: {
          ...general.timelineDefault.frame
        },
        timeInstance: {
          ...general.timelineDefault.timeInstance,
          'margin-left': '18px'
        },
        timeInstanceH: {
          ...general.timelineDefault.timeInstance,
          'margin-top': '18px'
        },
        dot: {
          ...general.timelineDefault.dot,
          left: '-11px',
          top: '8px',
          height: '8px',
          width: '8px',
          'border-radius': '4px',
          border: `2px solid ${colors.gray8}`
        },
        dotH: {
          ...general.timelineDefault.dot,
          left: '0px',
          top: '-11px',
          height: '8px',
          width: '8px',
          'border-radius': '4px',
          border: `1px solid ${colors.gray6}`,
          'margin-left': '50%'
        },
        line: {
          ...general.timelineDefault.line,
          left: '-8px',
          top: '15px',
          'border-left': `2px solid ${colors.gray7}`,
          height: 'calc(100% - 6px)'
        },
        lineH: {
          ...general.timelineDefault.line,
          left: '0px',
          top: '-9px',
          'border-top': `1px solid ${colors.gray5}`,
          width: 'calc(100% + 8px)',
          'margin-left': 'calc(50% + 8px)'
        },
        lineDotted: {
          border: `1px dotted ${colors.gray7}`
        }
      },
      xl: {
        frame: {
          ...general.timelineDefault.frame
        },
        timeInstance: {
          ...general.timelineDefault.timeInstance,
          'margin-left': '20px'
        },
        timeInstanceH: {
          ...general.timelineDefault.timeInstance,
          'margin-top': '20px'
        },
        dot: {
          ...general.timelineDefault.dot,
          left: '-12px',
          top: '9px',
          height: '9px',
          width: '9px',
          'border-radius': '5px',
          border: `2px solid ${colors.gray8}`
        },
        dotH: {
          ...general.timelineDefault.dot,
          left: '0px',
          top: '-12px',
          height: '9px',
          width: '9px',
          'border-radius': '5px',
          border: `1px solid ${colors.gray6}`,
          'margin-left': '50%'
        },
        line: {
          ...general.timelineDefault.line,
          left: '-8px',
          top: '18px',
          'border-left': `2px solid ${colors.gray7}`,
          height: 'calc(100% - 7px)'
        },
        lineH: {
          ...general.timelineDefault.line,
          left: '0px',
          top: '-10px',
          'border-top': `1px solid ${colors.gray5}`,
          width: 'calc(100% + 9px)',
          'margin-left': 'calc(50% + 9px)'
        },
        lineDotted: {
          border: `1px dotted ${colors.gray7}`
        }
      },
      xxl: {
        frame: {
          ...general.timelineDefault.frame
        },
        timeInstance: {
          ...general.timelineDefault.timeInstance,
          'margin-left': '22px'
        },
        timeInstanceH: {
          ...general.timelineDefault.timeInstance,
          'margin-top': '22px'
        },
        dot: {
          ...general.timelineDefault.dot,
          left: '-13px',
          top: '10px',
          height: '10px',
          width: '10px',
          'border-radius': '5px',
          border: `2px solid ${colors.gray8}`
        },
        dotH: {
          ...general.timelineDefault.dot,
          left: '0px',
          top: '-13px',
          height: '10px',
          width: '10px',
          'border-radius': '5px',
          border: `1px solid ${colors.gray6}`,
          'margin-left': '50%'
        },
        line: {
          ...general.timelineDefault.line,
          left: '-9px',
          top: '20px',
          'border-left': `2px solid ${colors.gray7}`,
          height: 'calc(100% - 10px)'
        },
        lineH: {
          ...general.timelineDefault.line,
          left: '0px',
          top: '-10px',
          'border-top': `1px solid ${colors.gray5}`,
          width: 'calc(100% + 10px)',
          'margin-left': 'calc(50% + 10px)'
        },
        lineDotted: {
          border: `1px dotted ${colors.gray7}`
        }
      }
    },
    xs: {
      'letter-spacing': 'normal',
      'font-stretch': '100%',
      padding: '2.5 4px',
      'font-size': '12px',
      'border-radius': '4px'
    },
    xl: {
      'letter-spacing': 'normal',
      'font-stretch': '100%',
      padding: '15px 24px',
      'font-size': '18px',
      'border-radius': '4px'
    },
    xxs: {
      'letter-spacing': 'normal',
      'font-stretch': '100%',
      padding: '1px 2px',
      'font-size': '10px',
      'border-radius': '4px'
    },
    xxl: {
      'letter-spacing': 'normal',
      'font-stretch': '100%',
      padding: '20px 35px',
      'font-size': '20px',
      'border-radius': '4px'
    },
    'Bottom-Left': {
      top: '95%',
      left: 0
    },
    'Bottom-Center': {
      top: '95%'
    },
    'Bottom-Right': {
      top: '95%',
      right: 0
    },
    'Top-Left': {
      bottom: '95%',
      left: 0
    },
    'Top-Center': {
      bottom: '95%'
    },
    'Top-Right': {
      bottom: '95%',
      right: 0
    }
  }
}
