import {
  Flex,
  Input,
  IRenderControl,
  Modal,
  P,
  RenderControl,
  useDebounce
} from '@fareye/ui'
import { zonedToUTC, zonedToUTCAdd1Day } from '../../../utility/func'
import { cancelToken, FiInfo, get, getQueryParams } from '@fareye/utils'
import React from 'react'
import { IssueRaisedConfig } from '../../../config/constant'
import { ListingContainer } from '../../../style/styled'
import { IIssueListing } from '../../../utility/types'
import IssueList from '../components/IssueListing/IssueListing'

export const AllIssueListing = (props: IIssueListing) => {
  const {
    showDrawer,
    setShowDrawer,
    //NOTE: Default Config for this Module if config is not passed.
    config = IssueRaisedConfig,
    resourceType,
    baseUrl = '',
    startDate,
    endDate,
    showTimezone
  } = props
  const {
    children: { searchIssue: searchIssueConfig }
  } = config
  const source1 = cancelToken().source()
  const [apiData, setApiData] = React.useState<IRenderControl>({
    data: {},
    isLoading: false,
    isSuccess: true,
    isError: false,
    message: ''
  })
  const [searchIssue, setSearchIssue] = React.useState('')
  const debouncedSearchIssue = useDebounce(searchIssue, 500)

  React.useEffect(() => {
    fetchData()
  }, [debouncedSearchIssue])

  async function fetchData() {
    try {
      const query = getQueryParams(
        {
          search: searchIssue,
          resourceType,
          startDate: showTimezone ? zonedToUTC(startDate) : startDate,
          endDate: showTimezone ? zonedToUTCAdd1Day(endDate) : endDate
        },
        showTimezone
      )
      const { apiURL } = searchIssueConfig.config.apiConfig
      setApiData({ ...apiData, isLoading: true })
      const url = `${baseUrl}${apiURL}?${query}`
      const { data } = await get(url, source1.token)

      setApiData({
        ...apiData,
        data,
        isLoading: false,
        isSuccess: true,
        isError: false
      })
    } catch (ex) {
      setApiData({
        ...apiData,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: 'Something went Wrong...'
      })
    }
  }

  const { data } = apiData
  return (
    <ListingContainer
      setToggleModal={setShowDrawer}
      toggleModal={showDrawer}
      variant="xs drawer Bottom-Right"
      style={{
        content: {
          background: '#f9fafb',
          maxHeight: 'calc(100vh - 50px)',
          height: 'calc(100vh - 50px)'
        }
      }}
    >
      <P variant="h6" m={0} p={3} bg="#fff">
        Issues Raised
      </P>
      <Flex m={4}>
        <Input
          width={1}
          type="text"
          placeholder="Search"
          value={searchIssue}
          onChange={e => setSearchIssue(e.target.value)}
        />
      </Flex>
      <RenderControl state={apiData} variant={'l'}>
        <Flex
          p={2}
          style={{ height: 'calc(100vh - 250px)', overflow: 'auto' }}
          column
        >
          <IssueList
            issues={data}
            baseUrl={baseUrl}
            config={config}
            resourceType={resourceType}
          />
        </Flex>
      </RenderControl>
      <Flex
        alignItemsCenter
        style={{ margin: '10px', padding: '4px' }}
        bg="#F0F2F5"
      >
        <FiInfo
          style={{ color: 'red', fontSize: '20px', marginRight: '8px' }}
        />
        <p style={{ fontSize: '12px' }}>
          Go to the trip details page to raise an issue. Click on reference
          number/trip id on the listing page to view trip details page
        </p>
      </Flex>
    </ListingContainer>
  )
}
