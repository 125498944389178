import { formatMs, yyyyMmDd } from './dateTime'
import numberAbbreviate from 'number-abbreviate'
interface AxisFormatter {
  value: string | number
}
export const AxisFormatter = (value: string | number, unit?: string) => {
  function validateDataType(value: string | number): string | number {
    switch (typeof value) {
      case 'string':
        return formatStringType(value)
      case 'number':
        return formatNumberType(value, unit)
      default:
        break
    }
    return ''
  }
  function formatStringType(value: string) {
    let validDate = Date.parse(value)
    if (validDate) {
      return DateFormatter(value)
      //return yyyyMmDd(new Date(value))
    }
    return value
  }
  function formatNumberType(value: number, unit?: string) {
    if (unit !== 'time') {
      return numberAbbreviate(value, 3)
    }
    return formatMs(value)
  }
  function DateFormatter(dateString: string) {
    const date = new Date(dateString)
    let formattedDate = ''
    if (date.getDate() < 10) {
      formattedDate += '0' + date.getDate()
    } else {
      formattedDate += date.getDate().toString()
    }
    formattedDate += '/'
    if (date.getMonth() + 1 < 10) {
      formattedDate += '0' + (date.getMonth() + 1)
    } else {
      formattedDate += date.getMonth() + 1 + ''
    }
    return formattedDate
  }
  return validateDataType(value)
}
