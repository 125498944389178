import { IToggleContent } from '@fareye/types'
import { Button, Flex, Input } from '@fareye/ui'
import {
  FaClock,
  FaTimes,
  FaFilter,
  getQueryParams,
  isEmpty,
  MdFeaturedPlayList,
  MdList,
  MdMap
} from '@fareye/utils'
import queryString from 'query-string'
import React from 'react'
import {
  DateWrapper,
  GroupedButton,
  HeaderFrame,
  LastRefreshed,
  SearchLocalWrapper,
  MobileMenu,
  HeaderRow,
  HeaderBody,
  HeaderRowItem1,
  HeaderRowItem2,
  HeaderRowItem3
} from '../../style/styled'
import { IHeader } from '../../utility/types'
import { Breadcrumb } from '../BreadCrumb'
import Timezone from '../Timezone'

const icons: any = {
  MdMap,
  MdList,
  MdFeaturedPlayList
}

export function Header({
  match,
  toggle,
  history,
  endDate,
  children,
  location,
  startDate,
  lastUpdate,
  selectedKey,
  routesConfig,
  setSearchData,
  rightTopCorner,
  setSelectedKey,
  showMobileMenu,
  setShowMobileMenu,
  showTimezone = false
}: IHeader) {
  const searchParams = queryString.parse(location.search)
  const query = getQueryParams({ ...searchParams, startDate, endDate })
  // let headerRowItem3count = 2
  // if (setSearchData) {
  //   headerRowItem3count += 1
  // }
  // if (rightTopCorner) {
  //   headerRowItem3count += 1
  // }
  // item of the headers must have fixed positions, irrespective of the content present or absent

  return (
    <HeaderFrame data-testid="module-header">
      <HeaderRow>
        <HeaderRowItem1>
          {!isEmpty(routesConfig) ? (
            <Breadcrumb
              match={match}
              history={history}
              location={location}
              routesConfig={routesConfig}
            />
          ) : null}
          {typeof showMobileMenu === 'boolean' && setShowMobileMenu ? (
            <MobileMenu
              hideOnMobile={!showMobileMenu}
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              {showMobileMenu ? <FaFilter size={18} /> : <FaTimes size={20} />}
            </MobileMenu>
          ) : null}
        </HeaderRowItem1>
        <HeaderRowItem2>
          {toggle && (
            <GroupedButton>
              {toggle.map((item: IToggleContent, index: number) => {
                const ICON: any = icons[item.icon as string]
                let selected = index === 0
                if (
                  selectedKey &&
                  toggle.map(item => item.selectedKey).includes(selectedKey)
                ) {
                  selected = selectedKey === item.selectedKey
                }

                return (
                  <Button
                    variant={selected ? 's primary' : 's'}
                    title={item.title}
                    key={index}
                    onClick={() => {
                      setSelectedKey && setSelectedKey(item.selectedKey)
                      if (!['normal', 'tabular'].includes(item.selectedKey)) {
                        history.push({
                          pathname: item.link,
                          search: query
                        })
                      }
                    }}
                  >
                    {ICON ? (
                      <ICON size={18} style={{ margin: '-2px 0 -4px' }} />
                    ) : (
                      item.label
                    )}
                  </Button>
                )
              })}
            </GroupedButton>
          )}
        </HeaderRowItem2>
        <HeaderRowItem3 showRefreshed={!!lastUpdate}>
          {lastUpdate ? (
            <Flex justifyContentFlexEnd>
              <LastRefreshed
                variant="secondary"
                isLast={!setSearchData && !rightTopCorner}
              >
                <FaClock
                  size={10}
                  color={'#fff'}
                  style={{
                    margin: '0 5px -1px 1vw',
                    background: '#727272',
                    borderRadius: '50%',
                    border: '1px solid #aaa'
                  }}
                />
                Refreshed {lastUpdate || '--:--'}
              </LastRefreshed>
            </Flex>
          ) : null}
          {setSearchData ? (
            <SearchLocalWrapper>
              <Input
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchData(e.target.value)
                }
                placeholder={'Filter List'}
                style={{
                  fontSize: 12,
                  lineHeight: 1,
                  background: '#fff',
                  width: '100%',
                  padding: '9px 0 9px 13px',
                  height: '3em', // 34px
                  boxSizing: 'border-box'
                }}
              />
            </SearchLocalWrapper>
          ) : null}
          {showTimezone && <Timezone />}
          {rightTopCorner && <DateWrapper>{rightTopCorner}</DateWrapper>}
        </HeaderRowItem3>
      </HeaderRow>
      {children ? <HeaderBody>{children}</HeaderBody> : null}
    </HeaderFrame>
  )
}
