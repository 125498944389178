import {
  Button,
  Card,
  Flex,
  Input,
  Modal,
  Span,
  Label,
  CheckBox,
  styled
} from '@fareye/ui'
import React from 'react'
import { CONFIGURE_DASHBOARD_OPTIONS } from '../../config/constants'
export default function ConfigureKpiDashboard({
  modal,
  setModal,
  onSelectGraph,
  selectedValue
}: any) {
  const [selectedGraph, setSelectedGraph] = React.useState(selectedValue)

  function changeHandling() {
    onSelectGraph(selectedGraph)
    setModal(!modal)
  }

  const buttonStyle = {
    border: '1px solid #1276D3',
    borderRadius: '2px',
    height: '32px'
  }

  const footer = (
    <Flex justifyContentFlexEnd>
      <Button
        variant="secondary s"
        onClick={() => setModal(!modal)}
        m={2}
        style={{ ...buttonStyle, backgroundColor: '#F9FAFB' }}
      >
        Cancel
      </Button>
      <Button
        m={2}
        variant="primary s"
        onClick={changeHandling}
        style={buttonStyle}
      >
        Apply Filters
      </Button>
    </Flex>
  )

  const isChecked = (item: any, child?: any) => {
    if (item.children && child) {
      switch (item.id) {
        case 'shipstatus':
          if (child === 'Untracked') return false
          return true
        case 'tptmode':
          return false
        case 'yardvis':
          if (child === 'Both') return true
        case 'others':
          if (child === 'Tracking Complaince') return false
          return true
        case 'exc':
          return false
      }
    } else if (item) {
      if (item.id !== 'exc') return true
      return false
    }
  }

  const getRadioOrCheckbox = (item: any, child: any) => {
    if (item.children.type === 'radio')
      return (
        <Radio
          name={item.id}
          type={item.children.type}
          onChange={
            item.id === 'graph' ? () => setSelectedGraph(child) : () => {}
          }
          defaultChecked={
            item.id === 'graph'
              ? selectedGraph === child
              : isChecked(item, child)
          }
        />
      )
    else
      return (
        <CheckBox
          name={item.id}
          type={item.children.type}
          className="primary"
          defaultChecked={
            item.id === 'graph'
              ? selectedGraph === child
              : isChecked(item, child)
          }
        ></CheckBox>
      )
  }

  return (
    <Modal
      toggleModal={modal}
      setToggleModal={() => setModal(!modal)}
      variant="xxl drawer Top-Right"
      style={{
        content: {
          minheight: '50%',
          maxWidth: 400,
          boxShadow: '0 0 20px 1px rgba(0,0,0,.2)',
          top: '42px',
          width: '28vw'
        }
      }}
    >
      <Card
        header="Configure Dashboard"
        bodyStyle={{
          fontSize: '16px',
          fontWeight: 500,
          padding: '15px 10px',
          border: 'none',
          overflowY: 'scroll',
          maxHeight: '80vh'
        }}
        footer={footer}
        headerStyle={{ fontSize: '18px', fontWeight: 400 }}
        style={{
          maxHeight: '80vh'
        }}
      >
        <Flex column style={{ gap: '10px', paddingLeft: '5px' }}>
          {CONFIGURE_DASHBOARD_OPTIONS.map((item: any, idx: any) => {
            return (
              <Flex
                column
                key={idx}
                style={{ padding: '5px 10px', alignItems: 'stretch' }}
              >
                <Flex
                  justifyContentSpaceBetween
                  alignItemsCenter
                  style={{ padding: '10px 0' }}
                >
                  <Label style={{ fontSize: '16px', fontWeight: '500' }}>
                    {item.label}
                  </Label>
                  {idx !== 7 ? (
                    // <Input
                    //   defaultChecked={isChecked(item)}
                    //   type="checkbox"
                    //   style={{ height: '15px', width: '15px' }}
                    // />
                    <CheckBox
                      type="checkbox"
                      className="primary"
                      style={{ height: '15px', width: '15px' }}
                      defaultChecked={isChecked(item)}
                    ></CheckBox>
                  ) : null}
                </Flex>
                {item.subLabel ? (
                  <Label
                    style={{
                      fontSize: '12px',
                      color: '#727272',
                      paddingBottom: '5px'
                    }}
                  >
                    {item.subLabel}
                  </Label>
                ) : null}
                <Flex
                  style={{
                    flexDirection:
                      item.children &&
                      item.children.type === 'radio' &&
                      idx !== 7
                        ? 'row'
                        : 'column'
                  }}
                >
                  {item.children
                    ? item.children.values.map((child: string, idx: any) => {
                        return (
                          <Flex
                            alignItemsCenter
                            key={idx}
                            style={{ padding: '5px 0' }}
                          >
                            {getRadioOrCheckbox(item, child)}
                            <Span
                              style={{
                                fontSize: '14px',
                                fontWeight: '400',
                                padding: '0 5px'
                              }}
                            >
                              {child}
                            </Span>
                          </Flex>
                        )
                      })
                    : null}
                </Flex>
              </Flex>
            )
          })}
        </Flex>
      </Card>
    </Modal>
  )
}

const Radio = styled(Input)`
  :checked:after {
    background: ${props => (props.type === 'radio' ? '#006491' : '#FFFFFF')};
  }
`
