import React from 'react'
import { SearchSelect } from '../Forms'
import { IAutoRefreshProps } from '../utility/types'
import { APP_CONSTANTS } from '../config/constants'
import { styled } from '../main'

export const AutoRefresh = ({
  children,
  intervalOptions = [2, 3, 5, 10]
}: IAutoRefreshProps) => {
  function formatOptions(item: Array<number>) {
    return item.map((val: number) => ({
      label: `${val} Min`,
      value: `${val * 60000}`
    }))
  }
  let options: any = formatOptions(intervalOptions)

  const [timer, setTimer] = React.useState(0)
  const [counter, setCounter] = React.useState<number>(
    parseInt(options[0].value)
  )
  const [refresh, setRefresh] = React.useState<boolean>(false)
  const [intervalId, setIntervalId] = React.useState(0)
  const [expand, setExpand] = React.useState(false)

  React.useEffect(() => {
    const localStorageCounter = localStorage.getItem(
      APP_CONSTANTS.LS_AUTOREFRESH
    )
    if (localStorageCounter) {
      const counterValue = JSON.parse(localStorageCounter)
      counterValue && setCounter(counterValue.counter)
      counterValue && setRefresh(true)
    }
  }, [])

  React.useEffect(() => {
    let x = timer
    if (refresh && !intervalId && counter > 0) {
      const tempId: any = setInterval(() => {
        setTimer(x++)
      }, counter)
      localStorage.setItem(
        APP_CONSTANTS.LS_AUTOREFRESH,
        `{"counter":${counter}}`
      )
      setIntervalId(tempId)
    } else if (refresh && intervalId) {
      clearInterval(intervalId)
      const tempId: any = setInterval(() => {
        setTimer(x++)
      }, counter)
      localStorage.setItem(
        APP_CONSTANTS.LS_AUTOREFRESH,
        `{"counter":${counter}}`
      )
      setIntervalId(tempId)
    } else if (!refresh) {
      clearInterval(intervalId)
      localStorage.removeItem(APP_CONSTANTS.LS_AUTOREFRESH)
      setIntervalId(0)
    }
  }, [counter, refresh])
  const childrenWithProps = React.Children.map(children, (child: any) =>
    React.cloneElement(child, { timer })
  )

  return (
    <AutoRefreshWrapper>
      <AutoRefreshControls expand={expand.toString()}>
        <AutoRefreshContent>
          <label className="label">Auto Refresh</label>
          <input
            type="checkbox"
            onChange={e => setRefresh(e.target.checked)}
            checked={refresh}
          />
          <span id={`status ${refresh ? 'enable' : 'disable'}`}>
            {refresh ? 'Enabled' : 'Disabled'}
          </span>
          {refresh && (
            <SearchSelect
              label={'Interval'}
              style={{
                wrapper: { width: 150 },
                label: { color: '#fff', paddingRight: 10 },
                singleValue: { color: '#fff' },
                control: { minHeight: 36 }
              }}
              value={options.find((o: any) => parseInt(o.value) === counter)}
              options={options}
              onChange={(item: any) => setCounter(parseInt(item.value))}
            />
          )}
        </AutoRefreshContent>
        <BottomHanger onClick={() => setExpand(!expand)}>
          <span>
            <b>&#8964;</b>
          </span>
        </BottomHanger>
      </AutoRefreshControls>
      {childrenWithProps}
    </AutoRefreshWrapper>
  )
}

const AutoRefreshWrapper = styled.div.attrs(() => ({
  className: 'auto-refresh-wrapper'
}))`
  position: sticky;
  width: 100%;
  height: inherit;
`

const AutoRefreshControls = styled.div.attrs(() => ({
  className: 'auto-refresh-controls'
}))<{ expand: string }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1em 0em;
  height: auto;
  width: 100%;
  z-index: 9999;
  background: #273759;
  transform: ${({ expand }) => {
    return expand === 'true' ? 'translateY(0%)' : 'translateY(-100%)'
  }};
  transition: 1s ease;
  box-shadow: ${({ expand }) =>
    expand === 'true'
      ? '2px 0px 12px 0px rgba(33, 45, 68, 0.8)'
      : '1px 0px 1px 0px rgba(33, 45, 68, 0.8)'};

  label.label {
    color: #fff;
    font-weight: 700;
    margin-right: 0.5em;
  }

  span.status {
    font-size: 12px;
    font-weight: 700;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  span.status.enable {
    color: #64dd17;
  }

  span.status.disable {
    color: #ff1744;
  }
`

const AutoRefreshContent = styled.div.attrs(() => ({
  className: 'auto-refresh-content'
}))`
  display: flex;
  align-items: center;
  margin: 0 1em;
  align-items: center;
`

const BottomHanger = styled.div.attrs(() => ({
  className: 'bottom-hanger'
}))`
  position: absolute;
  bottom: 0;
  transform: translate(-50%, calc(100% - 1px));
  padding: 7px 15px 10px;
  border-radius: 0px 0px 8px 8px;
  background: #273759;
  color: #fff;
  box-shadow: 0px 0px 1px 0px rgba(33, 45, 68, 0.5);
  transition: 0.3s linear;

  @media only screen and (min-width: 426px) and (max-width: 768px) {
    right: 20%;
  }

  @media only screen and (max-width: 425px) {
    left: 50%;
  }

  &:hover {
    box-shadow: 0px 2px 3px 2px rgba(33, 45, 68, 0.5);
    transition: 0.2s ease-in;
  }

  &:before {
    content: '';
    display: block;
    height: 1em;
    width: 1em;
    border: 8px solid transparent;
    border-radius: 9px;
    border-left: 4px solid #273759;
    border-top: 4px solid #273759;
    position: absolute;
    transform: rotate(90deg);
    top: -4px;
    left: -12px;
  }

  &:after {
    content: '';
    display: block;
    height: 1em;
    width: 1em;
    border: 8px solid transparent;
    border-radius: 9px;
    border-left: 4px solid #273759;
    border-top: 4px solid #273759;
    position: absolute;
    transform: rotate(0deg);
    top: -4px;
    right: -12px;
  }
  span {
    display: flex;
  }

  span b {
    font-size: 20px;
    transform: translateY(-7px);
    padding: 0 7px;
    height: 8px;
  }
`
