import { Label, Span, styled, Flex, layout } from '@fareye/ui'

// export const ErrorSpan = styled(Span)`
//   font-size: '12px';
//   display: 'block';
//   ${(props: any) => `
//     color: ${props.theme.colors.red9};
//   `}
// `
export const InputLabel = styled(Label)`
  margin-bottom: 4px;
  ${(props: any) => `
    color: ${props.theme.colors.gray9};
  `}
  ${(props: any) => props.style}
`

export const SubDescription = styled(Span)`
  ${(props: any) => `
    margin-bottom:21px;
    color: ${props.theme.colors.gray7};
  `}
  ${(props: any) => props.style}
`
export const Description = styled(Label)`
  font-weight: 600;
  padding-bottom: 8px;
  ${(props: any) => `
    color: ${props.theme.colors.gray9};
  `}
  ${(props: any) => props.style};
`

export const Row: any = styled(Flex)`
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 15px 0;
  flex-wrap: wrap;
`
export const Column: any = styled(Flex)`
  box-sizing: border-box;
  padding: 0 15px;
  ${layout}
`

export const ErrorSpan = styled(Span)`
  min-height: 16px;
  padding: 2px;
  line-height: 1;
`
