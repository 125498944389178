import colors from './antColors.json'
import { MASTER } from '../styledSystem'

export function antDarkTheme(themeOptions: any, boxShadow: any) {
  const options = ['frame', 'header', 'body', 'footer', 'divider']
  const cardHeaderFooter = [3, 4, 6, 12, 18, 20, 22]
  const cardBody = [6, 8, 12, 24, 36, 40, 44]
  const fontSize = [38, 30, 24, 20, 16, 14, 12, 10]

  const roundButtonSizes: any = {}
  MASTER.SIZE.map((size: string, n: number) => {
    roundButtonSizes[`round-${size}`] = {
      display: 'block',
      height: (n + 1) * 10,
      width: (n + 1) * 10,
      'border-radius': '50%',
      'justify-content': 'center',
      'align-items': 'center',
      padding: 0,
      'font-size': `${(n + 4) * 2}px`
    }
    return null
  })

  const general = {
    h1FontSizes: [],
    margin: [4, 6, 8, 12, 16, 20],
    padding: [4, 6, 8, 12, 16, 20],
    colors: { ...themeOptions.colors, ...colors },
    boxShadow,
    inputPadding: [6, 11],
    inputLineHeight: [40, 32, 24],
    alertDefault: {
      'box-sizing': 'border-box',
      margin: '0',
      color: colors.gray8,
      'font-size': `${fontSize[5]}px`,
      'font-variant': 'tabular-nums',
      'line-height': '1.5',
      'list-style': 'none',
      position: 'relative',
      'border-radius': '4px',
      'margin-bottom': '16px'
    },
    buttonDefault: {
      display: 'flex',
      'justify-content': 'space-between',
      outline: 'none',
      padding: '10px 15px',
      'text-transform': 'capitalize',
      'font-size': `${fontSize[4]}px`,
      'line-height': '1.1',
      'border-radius': 4,
      border: `1px solid ${colors.gray5}`,
      margin: 0,
      ...roundButtonSizes,
      '&:hover': {
        cursor: 'pointer',
        color: colors.blue5,
        'border-color': colors.blue5
      },
      '&:disabled': {
        cursor: 'not-allowed',
        color: 'rgba(0, 0, 0, 0.25)',
        'background-color': colors.gray3,
        'border-color': colors.gray5
      }
    },
    cardDefault: {
      default: {
        frame: {
          'font-size': '14px',
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'relative'
        },
        dateRangeWrapper: {
          position: 'absolute',
          right: 0,
          marginTop: '3.5em',
          zIndex: 1000,
          borderRadius: '4px'
        }
      },
      fareyeV2: {
        frame: {
          'font-size': '14px',
          position: 'relative'
        },
        dateRangeWrapper: {
          position: 'absolute',
          right: 0,
          marginTop: '3.5em',
          zIndex: 1000,
          borderRadius: '4px'
        }
      }
    },
    datePickerDefault: {
      frame: {
        'font-size': '14px',
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative'
      },
      dateRangeWrapper: {
        position: 'absolute',
        right: 0,
        marginTop: '3.5em',
        zIndex: 1000,
        borderRadius: '4px'
      }
    },
    inputDefault: {
      color: 'rgba(0, 0, 0, 0.65)',
      'background-color': colors.gray1,
      height: '32px',
      'line-height': '32px',
      'font-size': `${fontSize[5]}px`,
      border: `1px solid ${colors.gray5}`,
      'border-radius': '4px',
      padding: '4px 11px',
      '&:hover': {
        border: `1px solid ${colors.blue5}`
      },
      '&:focus': {
        border: `1px solid ${colors.blue5}`
      }
    },
    renderControlDefault: {
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
      width: '100%',
      'font-size': 16,
      'min-height': 16
    },
    // space: [0, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 36, 40],
    switchDefault: {
      container: {
        display: 'flex',
        'justify-content': 'flex-start',
        width: '44px',
        padding: '0px',
        'border-radius': '25px',
        border: '1px solid transparent',
        background: '#ccc',
        transition: '.4s ease'
      },
      slider: {
        height: '22px',
        'border-radius': '50%',
        width: '22px',
        border: '1px solid transparent',
        background: colors.white1
      }
    },
    tabs: {
      tabsFrame: {},
      tabsPane: {
        display: 'flex'
      },
      tabsPaneItem: {}
    },
    timelineDefault: {
      frame: {
        display: 'flex',
        'flex-direction': 'column'
      },
      timeInstance: {
        position: 'relative'
      },
      dot: {
        position: 'absolute',
        'box-sizing': 'border-box',
        background: 'white'
      },
      line: {
        position: 'absolute'
      }
    },
    typographyDefault: {
      h1: {
        'margin-bottom': '.5em',
        'font-weight': '600',
        'font-size': '38px',
        'line-height': '1.23'
      },
      h2: {
        'margin-bottom': '.5em',
        'font-weight': '600',
        'font-size': '30px',
        'line-height': '1.35'
      },
      h3: {
        'margin-bottom': '.5em',
        'font-weight': '600',
        'font-size': '24px',
        'line-height': '1.35'
      },
      h4: {
        'margin-bottom': '.5em',
        'font-weight': '600',
        'font-size': '20px',
        'line-height': '1.4'
      },
      h5: {
        'margin-bottom': '.5em',
        'font-weight': '600',
        'font-size': `${fontSize[4]}px`
      },
      h6: {
        'margin-bottom': '.5em',
        'font-weight': '600',
        'font-size': '14px'
      },
      primary: {
        color: 'rgba(0,0,0,0.65)'
      },
      secondary: {
        color: 'rgba(0,0,0,0.45)'
      },
      warning: {
        color: colors.gold6
      },
      danger: {
        color: colors.red6
      },
      success: {
        color: '#0b8235'
      },
      disabled: {
        color: 'rgba(0,0,0,0.25)',
        cursor: 'not-allowed',
        'user-select': 'none'
      },
      mark: {
        color: 'rgba(0,0,0,0.65)',
        padding: '0',
        'background-color': colors.gold3
      },
      code: {
        color: 'rgba(0,0,0,0.65)',
        margin: '0 .2em',
        padding: '.2em .4em .1em',
        background: 'rgba(0,0,0,0.06)',
        border: '1px solid rgba(0,0,0,0.06)',
        'border-radius': '3px'
      },
      underline: {
        color: 'rgba(0,0,0,0.65)',
        'text-decoration': 'underline',
        'text-decoration-skip-ink': 'auto'
      },
      delete: {
        color: 'rgba(0,0,0,0.65)',
        'text-decoration': 'line-through'
      },
      strong: {
        'font-weight': '600',
        color: 'rgba(0,0,0,0.65)'
      },
      ellipsis: {
        width: '100%',
        'white-space': 'nowrap',
        'text-overflow': 'ellipsis',
        overflow: 'hidden',
        display: 'inline-block'
      }
    }
  }
  const cards = () => {
    const tempHeader: any = {}
    MASTER.SIZE.forEach((cardSize: string, i: number) => {
      tempHeader[cardSize] = {}
      options.forEach((option: string, j: number) => {
        tempHeader[cardSize][option] = {}
        const padding =
          option === 'frame' || option === 'divider'
            ? '0px'
            : option === 'body'
            ? `${cardBody[i]}px ${cardBody[i]}px`
            : `${cardHeaderFooter[i]}px ${cardHeaderFooter[i] * 2}px`
        tempHeader[cardSize][option] = {
          padding,
          ...(general.cardDefault as any)[option]
        }
      })
    })
    return tempHeader
  }
  const card = cards()

  return {
    general,
    ant: {
      ...general,
      alert: {
        success: {
          ...general.alertDefault,
          'background-color': colors.green1,
          border: '1px solid #b7eb8f'
        },
        info: {
          ...general.alertDefault,
          'background-color': colors.blue1,
          border: `1px solid ${colors.blue3}`
        },
        warning: {
          ...general.alertDefault,
          'background-color': colors.gold1,
          border: `1px solid ${colors.gold3}`
        },
        error: {
          ...general.alertDefault,
          'background-color': colors.red1,
          border: `1px solid ${colors.red3}`
        }
      },
      card,
      buttons: {
        danger: {
          ...general.buttonDefault,
          color: colors.red6,
          'background-color': colors.gray3,
          '&:hover': {
            cursor: 'pointer',
            color: colors.gray1,
            'background-color': colors.red5,
            'border-color': colors.red5
          },
          '&:disabled': {
            ...general.buttonDefault['&:disabled'],
            color: 'rgba(0, 0, 0, 0.25)',
            'background-color': general.colors.gray3,
            'border-color': general.colors.gray5
          },
          ghost: {
            color: colors.red6,
            'background-color': general.colors.transparent,
            'border-color': colors.red6
          }
        },
        dashed: {
          ...general.buttonDefault,
          border: `1px dashed ${colors.gray5}`,
          color: general.colors.grey,
          'background-color': general.colors.white1,
          '&:disabled': {
            ...general.buttonDefault['&:disabled'],
            color: 'rgba(0, 0, 0, 0.25)',
            'background-color': general.colors.gray3,
            'border-color': general.colors.gray5
          },
          ghost: {
            color: general.colors.white1,
            'background-color': general.colors.transparent,
            border: '1px dashed white'
          }
        },
        default: {
          ...general.buttonDefault,
          color: general.colors.grey,
          'background-color': general.colors.white1,
          '&:disabled': {
            ...general.buttonDefault['&:disabled'],
            color: 'rgba(0, 0, 0, 0.25)',
            'background-color': general.colors.gray3,
            'border-color': general.colors.gray5
          },
          ghost: {
            color: general.colors.white1,
            'background-color': general.colors.transparent,
            border: '1px solid white'
          }
        },
        link: {
          ...general.buttonDefault,
          border: '0px solid transparent',
          color: general.colors.blueLink,
          'background-color': general.colors.transparent,
          '&:hover': {
            cursor: 'pointer',
            color: colors.blue5,
            'background-color': general.colors.transparent
          },
          '&:disabled': {
            ...general.buttonDefault['&:disabled'],
            color: 'rgba(0, 0, 0, 0.25)',
            'background-color': general.colors.gray3,
            'border-color': general.colors.transparent
          },
          ghost: {
            color: colors.gray3,
            'border-color': colors.gray3,
            'background-color': general.colors.transparent
          }
        },
        primary: {
          ...general.buttonDefault,
          border: '0px solid transparent',
          color: general.colors.white1,
          'background-color': general.colors.blue6,
          '&:disabled': {
            ...general.buttonDefault['&:disabled'],
            color: 'rgba(0, 0, 0, 0.25)',
            'background-color': general.colors.gray3,
            'border-color': general.colors.gray5
          },
          '&:hover': {
            cursor: 'pointer',
            color: colors.gray1,
            'background-color': colors.blue5,
            'border-color': colors.blue5
          },
          ghost: {
            color: general.colors.blue,
            'background-color': general.colors.transparent,
            border: `1px solid ${general.colors.blue}`
          }
        }
      },
      colors: {
        ...general.colors,
        primary: colors.gray1,
        secondary: '#000'
      },
      datePicker: general.datePickerDefault,
      dropdown: {
        dropdownFrame: {
          position: 'relative',
          display: 'flex',
          minWidth: '100px'
        },
        'Bottom-Center': {
          justifyContent: 'center'
        },
        'Bottom-Right': {
          justifyContent: 'flex-end',
          top: '90%',
          right: 0
        },
        'Top-Center': {
          justifyContent: 'center'
        },
        'Top-Right': {
          justifyContent: 'flex-end',
          bottom: '90%',
          right: 0
        },
        dropdownLabel: {
          color: colors.blue6,
          'text-decoration': 'none',
          'background-color': 'transparent',
          outline: 'none',
          cursor: 'pointer',
          width: '100%'
        },
        dropdownOptionsFrame: {
          display: 'none',
          position: 'absolute',
          margin: '0',
          padding: '4px 0',
          'text-align': 'left',
          'list-style-type': 'none',
          'background-color': colors.gray1,
          'background-clip': 'padding-box',
          'border-radius': '4px',
          outline: 'none',
          width: 'max-content',
          'box-shadow': '0 2px 8px rgba(0,0,0,0.15)',
          zIndex: 99
        },
        dropdownItem: {
          margin: '0',
          padding: '5px 12px',
          color: 'rgba(0,0,0,0.65)',
          fontWeight: 'normal',
          'font-size': `${fontSize[5]}px`,
          lineHeight: '22px',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
          transition: 'all .3s',
          '&:hover': {
            'background-color': colors.blue1
          }
        }
      },
      global: {
        displayType: MASTER.DISPLAY,
        typographySizing: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'label'],
        padding: ['xl', 'l', 'm', 's', 'xs'],
        fontSizes: ['xl', 'l', 'm', 's', 'xs'],
        sizing: MASTER.SIZE,
        pattern: MASTER.PATTERN,
        position: MASTER.POSITION,
        body: {
          background: '#000'
        }
      },
      groupedButtons: {
        ...general.buttonDefault,
        margin: 0
      },
      input: {
        checkbox: {
          opacity: 1,
          position: 'relative',
          transform: 'scale(1)',
          '&:before': {
            position: 'absolute',
            content: '',
            background: colors.white1,
            'box-shadow': '0 0 2px 0 rgba(128, 128, 128, 0.4)',
            height: '16px',
            width: '16px',
            '&:disabled': {
              background: 'rgba(239, 239, 239, 1)'
            }
          },
          '&:after': {
            position: 'absolute',
            content: '',
            top: '42%',
            left: '42%',
            background: 'rgba(45, 22, 128, 0.9)',
            height: '40%',
            width: '40%'
          }
        },
        default: {
          'box-shadow': 'none',
          border: '0px solid transparent',
          color: general.colors.black,
          'background-color': colors.gray1
        },
        number: {
          ...general.inputDefault,
          height: '30px',
          padding: '0 11px',
          'text-align': 'left',
          'background-color': 'transparent',
          'border-radius': '4px',
          outline: ' 0',
          transition: 'all .3s linear',
          '&:hover': {
            border: `1px solid ${colors.blue5}`
          },
          '&:disabled': {
            color: 'rgb(0,0,0,0.25)',
            cursor: 'not-allowed',
            border: '1px solid rgb(0,0,0,0.25)',
            placeholder: '5',
            'background-color': colors.gray3
          }
        },
        switch: {
          primary: {
            on: {
              container: {
                ...general.switchDefault.container,
                'justify-content': 'flex-end',
                'background-color': colors.blue6
              },
              slider: general.switchDefault.slider
            },
            off: {
              container: general.switchDefault.container,
              slider: general.switchDefault.slider
            }
          },
          dashed: {
            on: {
              container: {
                ...general.switchDefault.container,
                'justify-content': 'flex-end',
                border: '1px dashed rgb(24,144,245)',
                'background-color': colors.white1
              },
              slider: {
                ...general.switchDefault.slider,
                'background-color': colors.blue6
              }
            },
            off: {
              container: {
                ...general.switchDefault.container,
                'justify-content': 'flex-end',
                'background-color': colors.white1,
                border: '1px dashed rgb(24,144,245)'
              },
              slider: {
                ...general.switchDefault.slider,
                'background-color': '#ccc'
              }
            }
          },
          danger: {
            on: {
              container: {
                ...general.switchDefault.container,
                'justify-content': 'flex-end',
                'background-color': colors.red5
              },
              slider: general.switchDefault.slider
            },
            off: {
              container: general.switchDefault.container,
              slider: general.switchDefault.slider
            }
          }
        },
        text: {
          ...general.inputDefault
        },
        textarea: {
          ...general.inputDefault,
          height: 'auto',
          'min-height': '32px'
        }
      },
      l: {
        'letter-spacing': 'normal',
        'font-stretch': '100%',
        padding: '11.7px 16px',
        'font-size': `${fontSize[4]}px`,
        'border-radius': '4px'
      },
      label: {
        'font-weight': '600',
        default: {
          'font-weight': '600'
        },
        ...general.typographyDefault
      },
      list: {
        listFrame: {
          boxSizing: 'border-box',
          color: 'rgba(0,0,0,0.65)',
          'font-size': `${fontSize[5]}px`,
          fontVariant: 'tabular-nums',
          lineHeight: '1.5',
          listStyle: 'none',
          fontFeatureSettings: "'tnum'",
          position: 'relative',
          border: `1px solid ${colors.gray5}`
        },
        listItem: {
          alignItems: 'center'
        },
        xs: {
          padding: '3px 6px'
        },
        s: {
          padding: '6px 12px'
        },
        m: {
          padding: '12px 24px'
        },
        l: {
          padding: '18px 36px'
        },
        xl: {
          padding: '22px 40px'
        }
      },
      m: {
        'letter-spacing': 'normal',
        'font-stretch': '100%',
        padding: '9px 16px',
        'font-size': `${fontSize[5]}px`,
        'border-radius': '4px'
      },
      modal: {
        modalFrame: {
          position: 'fixed',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
          height: '100vh',
          width: '100vw',
          background: 'rgba(0,0,0,.8)',
          zIndex: 999999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        },
        content: {
          overflow: 'auto',
          position: 'relative',
          transform: 'translate(0%, 0%)',
          height: 'auto',
          background: colors.white1
        },
        cross: {
          position: 'absolute',
          right: '25px',
          top: '10px',
          'z-index': '100',
          cursor: 'pointer'
        },
        xxl: {
          'max-height': '90vh',
          width: '90vw'
        },
        xl: {
          'max-height': '80vh',
          width: '80vw'
        },
        l: {
          'max-height': '70vh',
          width: '70vw'
        },
        m: {
          'max-height': '60vh',
          width: '60vw'
        },
        s: {
          'max-height': '50vh',
          width: '50vw'
        },
        xs: {
          'max-height': '40vh',
          width: '40vw'
        },
        xxs: {
          'max-height': '30vh',
          width: '30vw'
        },
        drawer: {
          right: 0
        },
        'Bottom-Left': {
          drawerFrame: {
            justifyContent: 'flex-start',
            alignItems: 'flex-end'
          },
          drawerContent: {
            transform: 'translate(0%, 0%)'
          }
        },
        'Bottom-Center': {
          drawerFrame: {
            justifyContent: 'center',
            alignItems: 'flex-end'
          },
          drawerContent: {
            transform: 'translate(0%, 0%)'
          }
        },
        'Bottom-Right': {
          drawerFrame: {
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          },
          drawerContent: {
            transform: 'translate(0%, 0%)'
          }
        },
        'Top-Left': {
          drawerFrame: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          },
          drawerContent: {
            transform: 'translate(0%, 0%)'
          }
        },
        'Top-Center': {
          drawerFrame: {
            justifyContent: 'center',
            alignItems: 'flex-start'
          },
          drawerContent: {
            transform: 'translate(0%, 0%)'
          }
        },
        'Top-Right': {
          drawerFrame: {
            justifyContent: 'flex-end',
            alignItems: 'flex-start'
          },
          drawerContent: {
            transform: 'translate(0%, 0%)'
          }
        },
        'Right-Center': {
          drawerFrame: {
            justifyContent: 'flex-end',
            alignItems: 'center'
          },
          drawerContent: {
            transform: 'translate(0%, 0%)'
          }
        },
        'Left-Center': {
          drawerFrame: {
            justifyContent: 'flex-start',
            alignItems: 'center'
          },
          drawerContent: {
            transform: 'translate(0%, 0%)'
          }
        }
      },
      paragraph: {
        default: {
          'margin-top': '7px',
          'margin-bottom': '7px'
        },
        ...general.typographyDefault,
        'margin-top': '7px',
        'margin-bottom': '7px'
      },
      primary: {
        color: general.colors.white1,
        'background-color': general.colors.red
      },
      rate: {
        size: '50px',
        'stroke-width': '20px ',
        stroke: '#ffff00'
      },
      renderControl: {
        default: general.renderControlDefault,
        xl: {
          ...general.renderControlDefault,
          'font-size': 24,
          'min-height': 600
        },
        l: {
          ...general.renderControlDefault,
          'font-size': 20,
          'min-height': 300
        },
        m: {
          ...general.renderControlDefault,
          'font-size': 18,
          'min-height': 150
        },
        s: {
          ...general.renderControlDefault,
          'font-size': 14,
          'min-height': 75
        },
        xs: {
          ...general.renderControlDefault,
          'font-size': 12,
          'min-height': 30
        }
      },
      span: {
        default: {
          color: 'rgba(0,0,0,0.85)',
          'font-weight': 'normal',
          'font-size': `${fontSize[6]}px`
        },
        ...general.typographyDefault
      },
      s: {
        'letter-spacing': 'normal',
        'font-stretch': '100%',
        padding: '4.8px 8px',
        'font-size': `${fontSize[5]}px`,
        'border-radius': '4px'
      },
      select: {},
      tabs: {
        'Bottom-Left': {
          tabsFrame: {
            ...general.tabs.tabsFrame,
            flexDirection: 'column-reverse'
          },
          tabsPane: {
            ...general.tabs.tabsPane
          },
          tabsPaneItem: {
            ...general.tabs.tabsPaneItem
          }
        },
        'Bottom-Center': {
          tabsFrame: {
            ...general.tabs.tabsFrame,
            flexDirection: 'column-reverse'
          },
          tabsPane: {
            ...general.tabs.tabsPane,
            transform: 'translate(0%, 0%)'
          },
          tabsPaneItem: {
            ...general.tabs.tabsPaneItem
          }
        },
        'Bottom-Right': {
          tabsFrame: {
            ...general.tabs.tabsFrame,
            flexDirection: 'column-reverse'
          },
          tabsPane: {
            ...general.tabs.tabsPane,
            transform: 'translate(0%, 0%)'
          },
          tabsPaneItem: {
            ...general.tabs.tabsPaneItem
          }
        },
        'Top-Left': {
          tabsFrame: {
            ...general.tabs.tabsFrame,
            flexDirection: 'column'
          },
          tabsPane: {
            ...general.tabs.tabsPane,
            transform: 'translate(0%, 0%)'
          },
          tabsPaneItem: {
            ...general.tabs.tabsPaneItem
          }
        },
        'Top-Center': {
          tabsFrame: {
            ...general.tabs.tabsFrame,
            flexDirection: 'column'
          },
          tabsPane: {
            ...general.tabs.tabsPane,
            transform: 'translate(0%, 0%)'
          },
          tabsPaneItem: {
            ...general.tabs.tabsPaneItem
          }
        },
        'Top-Right': {
          tabsFrame: {
            ...general.tabs.tabsFrame,
            flexDirection: 'column'
          },
          tabsPane: {
            ...general.tabs.tabsPane,
            transform: 'translate(0%, 0%)'
          },
          tabsPaneItem: {
            ...general.tabs.tabsPaneItem
          }
        },
        'Right-Center': {
          tabsFrame: {
            ...general.tabs.tabsFrame,
            flexDirection: 'row-reverse'
          },
          tabsPane: {
            ...general.tabs.tabsPane,
            transform: 'translate(0%, 0%)'
          },
          tabsPaneItem: {
            ...general.tabs.tabsPaneItem
          }
        },
        'Left-Center': {
          tabsFrame: {
            ...general.tabs.tabsFrame,
            justifyContent: 'flex-start',
            alignItems: 'center'
          },
          tabsPane: {
            ...general.tabs.tabsPane,
            transform: 'translate(0%, 0%)'
          },
          tabsPaneItem: {
            ...general.tabs.tabsPaneItem
          }
        }
      },
      timeline: {
        xxs: {
          frame: {
            ...general.timelineDefault.frame
          },
          timeInstance: {
            ...general.timelineDefault.timeInstance,
            'margin-left': '10px'
          },
          timeInstanceH: {
            ...general.timelineDefault.timeInstance,
            'margin-top': '10px'
          },
          dot: {
            ...general.timelineDefault.dot,
            left: '-7px',
            top: '4px',
            height: '4px',
            width: '4px',
            'border-radius': '2px',
            border: `1px solid ${colors.gray6}`
          },
          dotH: {
            ...general.timelineDefault.dot,
            left: '0px',
            top: '-7px',
            height: '4px',
            width: '4px',
            'border-radius': '2px',
            border: `1px solid ${colors.gray6}`,
            'margin-left': '50%'
          },
          line: {
            ...general.timelineDefault.line,
            left: '-5.5px',
            top: '8px',
            'border-left': `1px solid ${colors.gray5}`,
            height: 'calc(100% - 3px)'
          },
          lineH: {
            ...general.timelineDefault.line,
            top: '-5px',
            'border-top': `1px solid ${colors.gray5}`,
            width: 'calc(100% + 4px)',
            'margin-left': 'calc(50% + 4px)'
          },
          lineDotted: {
            border: `0.5px dotted ${colors.gray5}`
          }
        },
        xs: {
          frame: {
            ...general.timelineDefault.frame
          },
          timeInstance: {
            ...general.timelineDefault.timeInstance,
            'margin-left': '12px'
          },
          timeInstanceH: {
            ...general.timelineDefault.timeInstance,
            'margin-top': '12px'
          },
          dot: {
            ...general.timelineDefault.dot,
            left: '-8px',
            top: '5px',
            height: '5px',
            width: '5px',
            'border-radius': '3px',
            border: `1px solid ${colors.gray6}`
          },
          dotH: {
            ...general.timelineDefault.dot,
            left: '0px',
            top: '-8px',
            height: '5px',
            width: '5px',
            'border-radius': '3px',
            border: `1px solid ${colors.gray6}`,
            'margin-left': '50%'
          },
          line: {
            ...general.timelineDefault.line,
            left: '-6px',
            top: '10px',
            'border-left': `1px solid ${colors.gray5}`,
            height: 'calc(100% - 5px)'
          },
          lineH: {
            ...general.timelineDefault.line,
            top: '-6px',
            'border-top': `1px solid ${colors.gray5}`,
            width: 'calc(100% + 5px)',
            'margin-left': 'calc(50% + 5px)'
          },
          lineDotted: {
            border: `0.5px dotted ${colors.gray5}`
          }
        },
        s: {
          frame: {
            ...general.timelineDefault.frame
          },
          timeInstance: {
            ...general.timelineDefault.timeInstance,
            'margin-left': '14px'
          },
          timeInstanceH: {
            ...general.timelineDefault.timeInstance,
            'margin-top': '14px'
          },
          dot: {
            ...general.timelineDefault.dot,
            left: '-9px',
            top: '6px',
            height: '6px',
            width: '6px',
            'border-radius': '3px',
            border: `1.5px solid ${colors.gray7}`
          },
          dotH: {
            ...general.timelineDefault.dot,
            left: '0px',
            top: '-9px',
            height: '6px',
            width: '6px',
            'border-radius': '3px',
            border: `1px solid ${colors.gray6}`,
            'margin-left': '50%'
          },
          line: {
            ...general.timelineDefault.line,
            left: '-7px',
            top: '12px',
            'border-left': `1.5px solid ${colors.gray6}`,
            height: 'calc(100% - 5px)'
          },
          lineH: {
            ...general.timelineDefault.line,
            left: '0px',
            top: '-7px',
            'border-top': `1px solid ${colors.gray5}`,
            width: 'calc(100% + 6px)',
            'margin-left': 'calc(50% + 6px)'
          },
          lineDotted: {
            border: `0.75px dotted ${colors.gray6}`
          }
        },
        m: {
          frame: {
            ...general.timelineDefault.frame
          },
          timeInstance: {
            ...general.timelineDefault.timeInstance,
            'margin-left': '16px'
          },
          timeInstanceH: {
            ...general.timelineDefault.timeInstance,
            'margin-top': '16px'
          },
          dot: {
            ...general.timelineDefault.dot,
            left: '-10px',
            top: '7px',
            height: '7px',
            width: '7px',
            'border-radius': '4px',
            border: `1.5px solid ${colors.gray7}`
          },
          dotH: {
            ...general.timelineDefault.dot,
            left: '0px',
            top: '-10px',
            height: '7px',
            width: '7px',
            'border-radius': '4px',
            border: `1px solid ${colors.gray6}`,
            'margin-left': '50%'
          },
          line: {
            ...general.timelineDefault.line,
            left: '-7.5px',
            top: '14px',
            'border-left': `1.5px solid ${colors.gray6}`,
            height: 'calc(100% - 5px)'
          },
          lineH: {
            ...general.timelineDefault.line,
            left: '0px',
            top: '-8px',
            'border-top': `1px solid ${colors.gray5}`,
            width: 'calc(100% + 7px)',
            'margin-left': 'calc(50% + 7px)'
          },
          lineDotted: {
            border: `0.75px dotted ${colors.gray6}`
          }
        },
        l: {
          frame: {
            ...general.timelineDefault.frame
          },
          timeInstance: {
            ...general.timelineDefault.timeInstance,
            'margin-left': '18px'
          },
          timeInstanceH: {
            ...general.timelineDefault.timeInstance,
            'margin-top': '18px'
          },
          dot: {
            ...general.timelineDefault.dot,
            left: '-11px',
            top: '8px',
            height: '8px',
            width: '8px',
            'border-radius': '4px',
            border: `2px solid ${colors.gray8}`
          },
          dotH: {
            ...general.timelineDefault.dot,
            left: '0px',
            top: '-11px',
            height: '8px',
            width: '8px',
            'border-radius': '4px',
            border: `1px solid ${colors.gray6}`,
            'margin-left': '50%'
          },
          line: {
            ...general.timelineDefault.line,
            left: '-8px',
            top: '15px',
            'border-left': `2px solid ${colors.gray7}`,
            height: 'calc(100% - 6px)'
          },
          lineH: {
            ...general.timelineDefault.line,
            left: '0px',
            top: '-9px',
            'border-top': `1px solid ${colors.gray5}`,
            width: 'calc(100% + 8px)',
            'margin-left': 'calc(50% + 8px)'
          },
          lineDotted: {
            border: `1px dotted ${colors.gray7}`
          }
        },
        xl: {
          frame: {
            ...general.timelineDefault.frame
          },
          timeInstance: {
            ...general.timelineDefault.timeInstance,
            'margin-left': '20px'
          },
          timeInstanceH: {
            ...general.timelineDefault.timeInstance,
            'margin-top': '20px'
          },
          dot: {
            ...general.timelineDefault.dot,
            left: '-12px',
            top: '9px',
            height: '9px',
            width: '9px',
            'border-radius': '5px',
            border: `2px solid ${colors.gray8}`
          },
          dotH: {
            ...general.timelineDefault.dot,
            left: '0px',
            top: '-12px',
            height: '9px',
            width: '9px',
            'border-radius': '5px',
            border: `1px solid ${colors.gray6}`,
            'margin-left': '50%'
          },
          line: {
            ...general.timelineDefault.line,
            left: '-8px',
            top: '18px',
            'border-left': `2px solid ${colors.gray7}`,
            height: 'calc(100% - 7px)'
          },
          lineH: {
            ...general.timelineDefault.line,
            left: '0px',
            top: '-10px',
            'border-top': `1px solid ${colors.gray5}`,
            width: 'calc(100% + 9px)',
            'margin-left': 'calc(50% + 9px)'
          },
          lineDotted: {
            border: `1px dotted ${colors.gray7}`
          }
        },
        xxl: {
          frame: {
            ...general.timelineDefault.frame
          },
          timeInstance: {
            ...general.timelineDefault.timeInstance,
            'margin-left': '22px'
          },
          timeInstanceH: {
            ...general.timelineDefault.timeInstance,
            'margin-top': '22px'
          },
          dot: {
            ...general.timelineDefault.dot,
            left: '-13px',
            top: '10px',
            height: '10px',
            width: '10px',
            'border-radius': '5px',
            border: `2px solid ${colors.gray8}`
          },
          dotH: {
            ...general.timelineDefault.dot,
            left: '0px',
            top: '-13px',
            height: '10px',
            width: '10px',
            'border-radius': '5px',
            border: `1px solid ${colors.gray6}`,
            'margin-left': '50%'
          },
          line: {
            ...general.timelineDefault.line,
            left: '-9px',
            top: '20px',
            'border-left': `2px solid ${colors.gray7}`,
            height: 'calc(100% - 10px)'
          },
          lineH: {
            ...general.timelineDefault.line,
            left: '0px',
            top: '-10px',
            'border-top': `1px solid ${colors.gray5}`,
            width: 'calc(100% + 10px)',
            'margin-left': 'calc(50% + 10px)'
          },
          lineDotted: {
            border: `1px dotted ${colors.gray7}`
          }
        }
      },
      xs: {
        'letter-spacing': 'normal',
        'font-stretch': '100%',
        padding: '2.5 4px',
        'font-size': '12px',
        'border-radius': '4px'
      },
      xl: {
        'letter-spacing': 'normal',
        'font-stretch': '100%',
        padding: '15px 24px',
        'font-size': '18px',
        'border-radius': '4px'
      },
      xxs: {
        'letter-spacing': 'normal',
        'font-stretch': '100%',
        padding: '1px 2px',
        'font-size': '10px',
        'border-radius': '4px'
      },
      xxl: {
        'letter-spacing': 'normal',
        'font-stretch': '100%',
        padding: '20px 35px',
        'font-size': '20px',
        'border-radius': '4px'
      },
      'Bottom-Left': {
        top: '95%',
        left: 0
      },
      'Bottom-Center': {
        top: '95%'
      },
      'Bottom-Right': {
        top: '95%',
        right: 0
      },
      'Top-Left': {
        bottom: '95%',
        left: 0
      },
      'Top-Center': {
        bottom: '95%'
      },
      'Top-Right': {
        bottom: '95%',
        right: 0
      }
    }
  }
}
