import {
  FaChartArea,
  FaChartBar,
  FaChartLine,
  FaChartPie,
  FaTable,
  FaMoneyCheckAlt
} from '@fareye/utils'

export const config = {
  chartTypes: {
    PIE: 'pie',
    BAR: 'bar',
    LINE: 'line',
    AREA: 'area',
    TABLE: 'table',
    FaMoneyCheckAlt: 'score'
    // SCATTER: 'scatter'
  },
  chartViewProperty: {
    ROUTES: 'routes',
    MATERIALS: 'materials'
  }
}

export const chartMenu = [
  { value: config.chartTypes.BAR, label: FaChartBar },
  { value: config.chartTypes.AREA, label: FaChartArea },
  { value: config.chartTypes.LINE, label: FaChartLine },
  { value: config.chartTypes.FaMoneyCheckAlt, label: FaMoneyCheckAlt },
  { value: config.chartTypes.PIE, label: FaChartPie },
  { value: config.chartTypes.TABLE, label: FaTable }
]

export interface HeaderConfig {
  name: string
  style?: any
  child: HeaderChildConfig[]
  selected: number
  onClick: (value: any) => void
}
interface HeaderChildConfig {
  label: any
  value: any
}

export function randomIdGenerator() {
  return Math.random()
    .toString(36)
    .substr(2, 9)
}

enum WidthOption {
  FULL = 0.9,
  THREEFOURTH = 0.75,
  TWOTHIRD = 0.6,
  HALF = 0.5,
  ONETHIRD = 0.3,
  ONEFOURTH = 0.25,
  AUTO = 'auto'
}

interface GraphSizeProps {
  selector: string
  height: WidthOption
  width?: WidthOption
}
