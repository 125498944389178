import { Header, SearchFilterWrapper, setDateFromStorage } from '@fareye/common'
import {
  GraphRenderer,
  IApiChartConfig,
  IGraphRendererConfig
} from '@fareye/kpi'
import { IDateRange, IRouteConfig } from '@fareye/types'
import { DatePicker, Flex, RenderControl, usePrevious } from '@fareye/ui'
import {
  cancelToken,
  cloneDeep,
  currenthhmmA,
  get,
  getQueryParams,
  isEmpty,
  post,
  yyyyMmDd,
  isEqual
} from '@fareye/utils'
import queryString, { ParsedQuery } from 'query-string'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import environment from '../../../environment'
import FullScreenComponent, { pageId } from './GraphFullScreen'
import { GRAPH_DATA_MAPPING, KPI_COLORS } from '../../../config/constants'
//import KPI_CONFIG from '../OceanStatsKpi.json'
import KPI_CONFIG from '../OceanStatsKpi.json'

interface IKpi extends RouteComponentProps {
  routesConfig: Partial<IRouteConfig>[]
}

const Kpi = ({ match, location, history, routesConfig }: IKpi) => {
  const searchParams: ParsedQuery<string | Date> = queryString.parse(
    location.search
  )

  const [dateRange, setDateRange] = React.useState(() => {
    return setDateFromStorage({
      showDatePicker: true,
      carryDate: true,
      searchParams,
      daysDiff: 10
    })
  })

  const [selectedKey, setSelectedKey] = React.useState('all')
  const source = cancelToken().source()
  const [lastUpdate, setLastUpdate] = React.useState<string>('')
  const [loading, setLoading] = React.useState(false)
  let { baseURL } = environment
  baseURL = 'http://localhost:3001'
  const [selectedFilter, setSelectedFilter] = React.useState<any>([])
  const dataConfig = {
    getDataForChart: getDataForChartFromServer,
    graphDataMapping: GRAPH_DATA_MAPPING
  }

  const toggleContent = [
    {
      label: 'Active Trips',
      title: 'Active Trips',
      selectedKey: 'active'
    },
    {
      label: 'All Trips',
      title: 'All Trips',
      selectedKey: 'all'
    }
  ]
  const previousDateRange = usePrevious(dateRange)
  const [graphRenderListing, setGraphRenderListing] = React.useState<
    IGraphRendererConfig[]
  >([])
  const apiFav = {
    apiURL: `${baseURL}/api/v1/records`,
    queryParams: {
      type: 'JsonStore::Ui',
      subType: 'globalSearchFavourites',
      pageId: 'kpi'
    }
  }

  const apiFilter = {
    apiURL: `${baseURL}/api/v1/es/search_aggs`
  }

  React.useEffect(() => {
    setLoading(true)
    fetchConfig()
  }, [])

  React.useEffect(() => {
    if (!isEmpty(graphRenderListing)) {
      const clonedGraphRenderListing = cloneDeep(graphRenderListing)
      clonedGraphRenderListing.forEach(
        ele =>
          (ele.globalFilter = {
            dateRange: dateRange,
            selectedFilter: selectedFilter,
            selectedKey
          })
      )
      setGraphRenderListing(clonedGraphRenderListing)
      if (!isEqual(previousDateRange, dateRange)) {
        const changedQueryParam = getQueryParams({
          ...searchParams,
          ...dateRange
        })
        history.replace({
          pathname: match.url,
          search: changedQueryParam
        })
      }
    }
  }, [dateRange, selectedFilter, selectedKey])

  async function fetchConfig() {
    try {
      const queryParam = getQueryParams({
        type: 'JsonStore::Ui',
        subType: 'kpi'
      })

      const jsonUrl = `${baseURL}/api/v1/records?${queryParam}`
      //const jsonUrl = `http://localhost:3001/data`
      const { data } = await get(jsonUrl, source.token)
      const kpiRenderingConfig = KPI_CONFIG
      //   const kpiRenderingConfig = isEmpty(data.configs)
      //     ? data.samples
      //     : data.configs
      const kpiData = kpiRenderingConfig
        .sort((next: any, pre: any) => next.data.order - pre.data.order)
        .map((ele: any) => ele.data)
      kpiData.map((ele: IGraphRendererConfig) => {
        ele.dataConfig = dataConfig
        ele.graphConfig.colors = { ...ele.graphConfig.colors, ...KPI_COLORS }
        ele.globalFilter = {
          dateRange: dateRange,
          selectedFilter: selectedFilter
        }
        return false
      })
      setGraphRenderListing(kpiData)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
    }
  }
  async function getDataForChartFromServer(
    value: Partial<IApiChartConfig>,
    globalFilter: any,
    isReport?: boolean
  ) {
    const { graphDataMapping } = dataConfig
    const {
      dataSource,
      metric,
      metrics,
      hideDimension,
      metricUnit,
      dimension,
      breakdownDimension: breakdownDimensionFromKpi,
      benchmarkDimension: benchmarkDimensionFromKpi,
      filter: filterFromKpi,
      sortKey,
      sortType,
      size
    } = value
    const { dateRange, selectedFilter, selectedKey }: any = globalFilter || {}
    let breakDownDimension, benchmarkDimension, sort, metricCustom
    if (breakdownDimensionFromKpi) {
      breakDownDimension = {
        label: breakdownDimensionFromKpi[graphDataMapping['dimension'].value]
      }
    }
    metricCustom = {
      key: metric.aggKey[graphDataMapping['metricKey'].value],
      type: metric.aggType[graphDataMapping['metricType'].value],
      unit: metricUnit ? metricUnit[graphDataMapping['unit'].value] : ''
    }
    if (benchmarkDimensionFromKpi) {
      benchmarkDimension = {
        ...metricCustom,
        value: metric.value,
        breakDownDimension: metric.breakDownDimension
      }
    }

    sort = {
      ...(sortType
        ? { order: sortType[graphDataMapping['sortType'].value] }
        : {}),
      sortable: sortKey ? 'metric' : 'dimension',
      size: size || 10
    }
    let filter = filterFromKpi
      ? filterFromKpi.map(filterList => {
          return filterList.map(ele => {
            return {
              filterKey: ele.filterKey[graphDataMapping['filterKey'].value],
              filterValue:
                ele.filterValue[graphDataMapping['filterValue'].value],
              condition: ele.condition[graphDataMapping['condition'].value],
              filterType: ele.filterType[graphDataMapping['filterType'].value]
            }
          })
        })
      : []
    filter.push([
      {
        filterKey: 'start_date',
        filterValue: dateRange ? yyyyMmDd(dateRange.startDate) : new Date(),
        //filterValue: '2019-09-10',
        condition: 'include',
        filterType: 'gte'
      }
    ])
    filter.push([
      {
        filterKey: 'start_date',
        filterValue: dateRange ? yyyyMmDd(dateRange.endDate) : new Date(),
        //filterValue: '2019-09-10',
        condition: 'include',
        filterType: 'lte'
      }
    ])
    if (!isEmpty(selectedFilter)) {
      selectedFilter.forEach((ele: any) => {
        filter.push([
          {
            filterKey: ele.key,
            filterValue: ele.value,
            condition: 'include',
            filterType: 'gte'
          }
        ])
      })
    }
    if (selectedKey === 'active') {
      filter.push([
        {
          filterKey: 'is_active',
          filterValue: true,
          condition: 'include',
          filterType: 'eq'
        }
      ])
    }
    let query = {
      dataSource: dataSource[graphDataMapping['dataSource'].value],
      ...(!isEmpty(breakDownDimension)
        ? { breakDownDimension }
        : isEmpty(benchmarkDimension)
        ? { metrics: [metricCustom] }
        : {}),
      ...(hideDimension
        ? {}
        : {
            dimension: {
              label: dimension[graphDataMapping['dimension'].value]
            }
          }),
      //   ...(breakDownDimension && { breakDownDimension }),
      ...(benchmarkDimension && { benchmarkDimension }),
      sort,
      filter
    }

    try {
      const token = cancelToken().source().token
      const { data } = await post(
        `${baseURL}/api/v1/es/kpi_${isReport ? 'results' : 'aggs'}`,
        query,
        token
      )
      setLastUpdate(currenthhmmA())
      return isReport ? data.results : data.aggs
    } catch (ex) {
      setLastUpdate(currenthhmmA())
      return []
    }
  }

  return (
    <Flex column>
      <Header
        lastUpdate={lastUpdate}
        match={match}
        location={location}
        history={history}
        routesConfig={routesConfig}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        toggle={toggleContent}
        selectedKey={selectedKey}
        setSelectedKey={setSelectedKey}
        rightTopCorner={
          <DatePicker
            onDateRangeSelected={(dateRange: IDateRange<string | Date>) => {
              localStorage.setItem('date', JSON.stringify(dateRange))
              setDateRange(dateRange as IDateRange<Date>)
            }}
            formatter={yyyyMmDd}
            value={dateRange}
            name="dateFilter"
            variant="fareyeV2"
          />
        }
      >
        <Flex justifyContentSpaceBetween width={1}>
          <SearchFilterWrapper
            apiFav={apiFav}
            pageId={pageId}
            apiFilter={apiFilter}
            search={selectedFilter}
            setSearch={setSelectedFilter}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
          />
        </Flex>
      </Header>
      <RenderControl
        data={graphRenderListing}
        isSuccess={!loading && !isEmpty(graphRenderListing)}
        isLoading={loading}
        variant={'l'}
        message={
          !isEmpty(graphRenderListing)
            ? ''
            : 'Something went wrong please try after sometime ......'
        }
      >
        <Flex wrap style={{ background: '#F0F2F5', padding: '1vw' }}>
          {graphRenderListing.map((ele, index) => {
            return (
              <GraphRenderer
                key={`kpi${index}`}
                {...ele}
                style={{
                  ...ele.style,
                  wrapper: {
                    ...(ele.style && ele.style.wrapper),
                    width: '50%',
                    maxWidth: '50%'
                  }
                }}
                FullScreenComponent={<FullScreenComponent config={ele} />}
              />
            )
          })}
        </Flex>
      </RenderControl>
    </Flex>
  )
}
export default Kpi
