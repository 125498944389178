import React from 'react'
import { FaSync } from '@fareye/utils'
import { RefreshRotating, RefreshS } from '../styled'
import { IRefresh } from '../types'

function Refresh({ refreshing, refresh }: IRefresh) {
  if (!refresh) {
    return null
  }
  return (
    <RefreshS
      data-testid={`refresh`}
      refreshing={refreshing}
      onClick={() => !refreshing && refresh(true)}
    >
      {refreshing ? (
        <RefreshRotating className="icon" />
      ) : (
        <FaSync className="icon" />
      )}
      <span className="refresh-text">Refresh</span>
    </RefreshS>
  )
}

export default Refresh
