import { Flex, Modal, PopOver, Span, styled } from '@fareye/ui'
import React from 'react'
import { useTimeZone } from '../../context/TimezoneContext'
import { FaChevronDown, FaChevronRight, IoMdTime } from '@fareye/utils'
import TimeZoneList from './TimeZoneList'
import { TIMEZONE_KEY } from '../../config/constants'
const localData = [
  {
    abbr: 'local',
    value: '',
    text: 'Local Scan Time',
    offset: '',
    isDst: false
  },
  {
    abbr: 'system',
    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
    text: 'System Timezone',
    offset: '',
    isDst: false
  }
]
const CustomSpan = styled(Span)`
  padding: 8px 16px;
  cursor: pointer;
  font-size: 13px;
`
export default function Timezone() {
  const { state, setState } = useTimeZone()
  const [timeZoneModal, setTimeZoneModal] = React.useState(false)
  function handleClick(ele: any) {
    let data = {
      id: ele.abbr,
      text: ele.text,
      value: ele.value,
      displayText: ele.text
    }
    setState(data)
    localStorage.setItem(TIMEZONE_KEY, JSON.stringify(data))
  }
  return (
    <Flex>
      {/* <PopOver
        style={{ wrapper: { width: '150px' } }}
        variant="Bottom-Right arrow"
      >
        <Flex style={{ cursor: 'pointer' }} alignItemsCenter>
          <IoMdTime />
          <Span p={1}>{state.displayText}</Span>
          <FaChevronDown style={{ fontSize: '11px' }} />
        </Flex>
        <PopOver.Body>
          <Flex column>
            {localData.map((ele, index) => {
              let selected = ele.text === state.displayText
              return (
                <CustomSpan
                  key={`${ele.text}-${index}`}
                  onClick={() => handleClick(ele)}
                  color={selected ? '#0091EA' : 'inherit'}
                >
                  {ele.text}
                </CustomSpan>
              )
            })}
            <Flex
              key={'others-3'}
              onClick={() => {
                setTimeZoneModal(true)
              }}
              alignItemsCenter
              justifyContentSpaceBetween
            >
              <CustomSpan>Others</CustomSpan>
              <FaChevronRight
                style={{ fontSize: '11px', marginRight: '12px' }}
              />
            </Flex>
          </Flex>
        </PopOver.Body>
      </PopOver> */}
      {timeZoneModal && (
        <Modal
          variant="l"
          toggleModal={timeZoneModal}
          setToggleModal={setTimeZoneModal}
        >
          <TimeZoneList
            setToggleModal={setTimeZoneModal}
            state={state}
            setState={setState}
          />
        </Modal>
      )}
    </Flex>
  )
}
