import { ParsedQuery } from 'query-string'
import {
  yyyyMmDd,
  subDays,
  isEmpty,
  format as formatDate,
  dateFormatterWithTimezone,
  zonedTimeToUtc
} from '@fareye/utils'
import TimezoneAbbr from '../modules/Timezone/timezoneAbbr.json'
import { IDateRange } from '@fareye/types'
import { TIMEZONE_KEY } from '../config/constants'

export interface IDateInitializer {
  showDatePicker: boolean
  carryDate: boolean
  searchParams: ParsedQuery<Date | string>
  daysDiff?: number
}

export const setDateFromStorage = ({
  carryDate,
  searchParams,
  daysDiff = 1,
  showDatePicker
}: IDateInitializer): IDateRange<Date> => {
  if (showDatePicker) {
    // Need to convert return type of date functions from string to Date
    let prevStartDate: Date | string = yyyyMmDd(subDays(new Date(), daysDiff))
    let prevEndDate: Date | string = yyyyMmDd()
    if (carryDate && searchParams.startDate && searchParams.endDate) {
      prevStartDate = searchParams.startDate as Date
      prevEndDate = searchParams.endDate as Date
    } else if (
      carryDate &&
      (!searchParams.startDate || !searchParams.endDate)
    ) {
      const storeDate = (): IDateRange<Date> =>
        JSON.parse(localStorage.getItem('date') || '{}')
      if (!isEmpty(storeDate())) {
        prevStartDate = storeDate().startDate
        prevEndDate = storeDate().endDate
      }
    }
    return { startDate: prevStartDate as Date, endDate: prevEndDate as Date }
  } else {
    return { startDate: new Date(), endDate: new Date() }
  }
}

export const filterDuplicateEntriesFromSelection = (
  selectedFilter: any[],
  value: any[]
) => {
  let newValue = selectedFilter
  if (value) {
    value.forEach((item: any) => {
      if (
        !selectedFilter.find(
          (o: any) => o.label === item.label && o.key === item.key
        )
      ) {
        newValue.push(item)
      }
    })
  }
  return newValue
}

// simplify this unnecessary complex code only after increment
export const selectedfiltersAlreadySaved = (
  selectedFilter: any,
  favList: any
) => {
  // return true means allow saving selectedFilters
  if (selectedFilter && selectedFilter.length) {
    const sameFavListLen: any = favList.filter(
      (o: any) => o.data.filters.length === selectedFilter.length
    )
    if (sameFavListLen.length) {
      for (var i = 0; i < sameFavListLen.length; i++) {
        let dataisSameInFav = false
        const tempSavedFilter = sameFavListLen[i].data.filters
        const uniqFavLabels = Array.from(
          new Set(tempSavedFilter.map((o: any) => o.label))
        )
        const uniqSelectedLabels = Array.from(
          new Set(selectedFilter.map((o: any) => o.label))
        )
        if (uniqSelectedLabels.length === uniqFavLabels.length) {
          let dataIsSame = false
          for (var j = 0; j < uniqFavLabels.length; j++) {
            dataIsSame = uniqSelectedLabels.includes(uniqFavLabels[j])
            if (!dataIsSame) {
              // if selected filter item do not matches saved filter item allow saving filter
              break
            }
            if (j == uniqFavLabels.length - 1 && dataIsSame) {
              // if all filter item from selected filter matches saved filter do not allow save
              dataisSameInFav = true
            }
          }
        } else if (i == sameFavListLen.length - 1) {
          // if selected filters length do not matches saved filter length allow saving filter
          if (dataisSameInFav) {
            return false
          }
          return true
        }
      }
    } else {
      // if there is selected filter but not in saved filters allow saving filter
      return true
    }
  } else {
    // if there is no selected filter from search dont allow saving filter
    return false
  }
}

export const dateFormatter = (date: any, formatterType?: any) => {
  const pickTimezoneFromStorage = localStorage.getItem(TIMEZONE_KEY)
  const dateTypeIsObject = typeof date === 'object' ? true : false
  const time = dateTypeIsObject ? date.time : date
  let zone = dateTypeIsObject ? date.zone : 'GMT'
  if (!time) {
    return '-'
  }
  if (!pickTimezoneFromStorage) {
    return dateFormatterWithTimezone(time)
  } else if (pickTimezoneFromStorage) {
    let { value = '', id = '' } = JSON.parse(pickTimezoneFromStorage)
    zone = id === 'local' ? zone : value
    value = id === 'local' ? zone : value
    const newFormat = dateFormatterWithTimezone(
      time,
      zone,
      'enUS',
      { timeZone: value },
      formatterType
    )
    const indexOfGMT: any = newFormat?.indexOf('GMT')
    if (indexOfGMT > 0) {
      const timeAbbrFlag = TimezoneAbbr[value] && isNaN(TimezoneAbbr[value])
      return timeAbbrFlag
        ? newFormat?.replace(
            newFormat.substring(indexOfGMT),
            TimezoneAbbr[value]
          )
        : newFormat
    } else {
      return newFormat
    }
  }
}

export const getTimezone = () => {
  let pickTimezoneFromStorage: any = localStorage.getItem(TIMEZONE_KEY)
  pickTimezoneFromStorage = pickTimezoneFromStorage
    ? JSON.parse(pickTimezoneFromStorage).value
    : Intl.DateTimeFormat().resolvedOptions().timeZone
  return pickTimezoneFromStorage
}

export const zonedToUTC = (time: any) => {
  const pickTimezoneFromStorage = localStorage.getItem(TIMEZONE_KEY)
  if (pickTimezoneFromStorage) {
    let { value = '' } = JSON.parse(pickTimezoneFromStorage)
    if (value) {
      return zonedTimeToUtc(time, value)
    } else {
      return zonedTimeToUtc(time, 'GMT')
    }
  } else {
    return new Date(time)
  }
}

export const zonedToUTCAdd1Day = (time: any) => {
  let date = zonedToUTC(time)
  // add a day
  date.setDate(date.getDate() + 1)
  return date
}
