import React from 'react'
import {
  downloadToCSV,
  dMmm,
  getXlsx,
  cancelToken,
  get,
  post,
  postXlsx
} from '@fareye/utils'
import { Button } from '@fareye/ui'
import { HEADER_INFLECTION } from '../../../config/constants'
interface IDownloadExcelButton {
  downloadConfig: {
    downloadUrl: string
    isStream: boolean
    type: 'GET' | 'POST'
    query: any
  }
  fileName: string
  data: Array<any>
}
export default function DownloadExcelButton({
  downloadConfig,
  fileName,
  data
}: IDownloadExcelButton) {
  const [downloadLoading, setDownloadLoading] = React.useState(false)

  const source = cancelToken().source()
  async function downloadCSV() {
    try {
      const { isStream, downloadUrl, type, query } = downloadConfig
      setDownloadLoading(true)
      if (isStream) {
        const { data }: any = await (type === 'GET'
          ? getXlsx(downloadUrl, source.token)
          : postXlsx(downloadUrl, query, source.token))
        downloadToCSV({
          data,
          fileName: `${fileName}-${dMmm()}`,
          type: 'text/zip',
          extension: 'zip'
        })
      } else {
        const { data }: any = await (type === 'GET'
          ? get(downloadUrl, source.token, HEADER_INFLECTION)
          : post(downloadUrl, query, source.token, HEADER_INFLECTION))
        downloadToCSV({
          data: (data as any)['content'],
          fileName: `${fileName}-${dMmm()}`,
          extension: 'csv'
        })
      }
      setDownloadLoading(false)
    } catch (ex) {
      setDownloadLoading(false)
    }
  }
  return (
    <Button
      variant="primary m"
      onClick={() => downloadCSV()}
      isLoading={downloadLoading}
      style={{ marginRight: '20px' }}
    >
      Export as Excel
    </Button>
  )
}
