import React from 'react'
import styled from 'styled-components'
import { layout, splitVariantsToTypes } from '../../Theme/styledSystem'
import { ISwitchProps } from '../../utility/types'

const DivBack: any = styled.div`
  ${layout} ${(props: ISwitchProps) => {
    const variants = splitVariantsToTypes(props)
    const { input } = props.theme
    const display = input.switch[variants.display] || input.switch['primary']
    return {
      ...display[`${props.value ? 'on' : 'off'}`]['container'][variants.size],
      ...props.styles
    }
  }};
`
const DivSlider: any = styled.div`
  ${layout} ${(props: ISwitchProps) => {
    const variants = splitVariantsToTypes(props)
    const { input } = props.theme
    const display = input.switch[variants.display] || input.switch['primary']
    return {
      ...display[`${props.value ? 'on' : 'off'}`]['slider'][variants.size],
      ...props.styles
    }
  }};
`

const Switch = (props: ISwitchProps) => {
  let { value, variant, style } = props
  return (
    <DivBack
      variant={variant}
      value={value}
      styles={style && style.container}
      onClick={() => {
        props.onChange(!value)
      }}
    >
      <DivSlider styles={style && style.dot} variant={variant} value={value} />
    </DivBack>
  )
}
export default Switch
