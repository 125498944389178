import { Flex } from '@fareye/ui'
import React from 'react'
import { OverviewCard, TextDisplay } from '../../style/styled'
import { IGraphCard } from '../../utility/types'

export function GraphCard({ heading, children, graphId, ...rest }: IGraphCard) {
  return (
    <OverviewCard
      header={<TextDisplay {...rest}>{heading}</TextDisplay>}
      variant="m"
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
      }}
    >
      <Flex
        id={graphId}
        column
        style={{ height: '100%', width: '100%', margin: 0 }}
        justifyContentCenter
        alignItemsCenter
      >
        {children}
      </Flex>
    </OverviewCard>
  )
}
