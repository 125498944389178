import React from 'react'
import styled from 'styled-components'
import { layout, Props } from '../../Theme/styledSystem'
import { ITypographyProps } from '../../utility/types'

const StyledLabel: any = styled.label`
  ${(props: ITypographyProps) => {
    const variant = props.variant || 'default'
    const types = variant.split(' ')
    const isEllipsis = variant.indexOf('ellipsis') > -1

    var displayT = 'default'
    var size = 'm'
    var typographySize = 'h6'

    types.forEach((type: string, i: number) => {
      if (props.theme.global.displayType.indexOf(type) > -1) {
        displayT = type
      } else if (props.theme.global.sizing.indexOf(type) > -1) {
        size = type
      } else if (props.theme.global.typographySizing.indexOf(type) > -1) {
        typographySize = type
      }
    })

    const returnStyle = {
      ...props.theme.label[displayT],
      ...props.theme.label[size],
      ...props.theme.label[typographySize],
      ...props.styles
    }
    return isEllipsis
      ? {
          ...returnStyle,
          ...props.theme.label.ellipsis
        }
      : returnStyle
  }} ${layout};
`

const Label = (props: ITypographyProps) => {
  let { children, variant, title } = Props(props)
  const OtherProps = { ...Props(props) }
  delete OtherProps.style

  return (
    <StyledLabel
      styles={props.style}
      {...OtherProps}
      title={variant?.includes('ellipsis') ? title || children : title}
    >
      {children}
    </StyledLabel>
  )
}
export default Label
