import { zonedTimeToUtc } from '@fareye/utils'
export function getColorOnSeverity(severity: string) {
  switch (severity) {
    case 'low':
      return 'rgb(105, 192, 255)'
    case 'medium':
      return 'rgba(250,140,22,1)'
    case 'high':
      return 'rgb(213, 0, 0)'
    default:
      return 'rgb(105, 192, 255)'
  }
}
const TIMEZONE_KEY = '@IDV:transportation-timezone'
export const zonedToUTC: any = (time: any) => {
  const pickTimezoneFromStorage = localStorage.getItem(TIMEZONE_KEY)
  if (pickTimezoneFromStorage) {
    let { value = '' } = JSON.parse(pickTimezoneFromStorage)
    if (value) {
      return zonedTimeToUtc(time, value)
    } else {
      return time
    }
  } else {
    return time
  }
}

export const zonedToUTCAdd1Day: any = (time: any) => {
  let date = zonedToUTC(time)
  // add a day
  date.setDate(date.getDate() + 1)
  return date
}
