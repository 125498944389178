import { Modal } from '@fareye/ui'
import { FaFileImage, FaFilePdf, FaFileWord } from '@fareye/utils'
import React from 'react'
import { fileIconStyle, Files, FileWrapDisplay } from './../../../style/styled'
import ReactDOM from 'react-dom'
interface IFilesDisplay {
  attachments: string[]
}

function FilesListingDisplay({ attachments }: IFilesDisplay) {
  const [openModal, setOpenModal] = React.useState<string | boolean>(false)
  const isFilePdf = String(openModal).includes('.pdf')
  const isFileDoc = String(openModal).includes('.doc')
  const iframeUrl = isFileDoc
    ? `https://docs.google.com/viewer?url=${openModal}&embedded=true`
    : `${openModal}`
  const modal = ReactDOM.createPortal(
    <Modal
      style={{
        content: {
          height: isFileDoc || isFilePdf ? '80vh' : 'auto',
          width: isFileDoc || isFilePdf ? '80vw' : 'auto',
          margin: 'auto'
        },
        ...(isFileDoc || isFilePdf
          ? {
              cross: {
                color: '#fff',
                right: 2,
                top: 22
              }
            }
          : {})
      }}
      setToggleModal={setOpenModal}
      toggleModal={Boolean(openModal)}
    >
      {isFileDoc || isFilePdf ? (
        <iframe
          style={{
            border: 'none',
            height: '100%',
            width: '100%'
          }}
          src={iframeUrl}
        />
      ) : (
        <img
          src={String(openModal)}
          style={{ width: '100%', maxWidth: '60vh', margin: '0 auto' }}
        />
      )}
    </Modal>,
    document.body
  )

  return (
    <>
      <Files data-testid="files">
        {attachments?.map((file: string, i: number) => {
          const isFilePdf = file.includes('.pdf')
          const isFileDoc = file.includes('.doc')
          const Icon = isFilePdf
            ? FaFilePdf
            : isFileDoc
            ? FaFileWord
            : FaFileImage
          return (
            <FileWrapDisplay
              key={i}
              styles={{ maxWidth: 40, padding: '3px 5px' }}
              data-testid={`file-${i}`}
              onClick={() => setOpenModal(file)}
            >
              <a href={file} onClick={(e: any) => e.preventDefault()}>
                <Icon style={fileIconStyle} />
              </a>
            </FileWrapDisplay>
          )
        })}
      </Files>
      {modal}
    </>
  )
}

export default FilesListingDisplay
