import React from 'react'
import Styled, { CSSObject } from 'styled-components'
import { layout, Props } from '../Theme/styledSystem'
import { IFlexProps } from '../utility/types'

const Div: any = Styled.div`
  display: flex;
  ${(props: IFlexProps) => {
    let {
      wraps,
      hidden,
      column,
      rowReverse,
      textCenter,
      columnReverse,
      alignItemsCenter,
      alignItemsFlexEnd,
      alignItemsFlexStart,
      justifyContentCenter,
      justifyContentFlexEnd,
      justifyContentFlexStart,
      justifyContentSpaceAround,
      justifyContentSpaceEvenly,
      justifyContentSpaceBetween
    } = props
    let classStyle: CSSObject = wraps ? { flexWrap: 'wrap' } : {}
    classStyle = hidden ? { ...classStyle, overflow: 'hidden' } : classStyle
    classStyle = columnReverse
      ? { ...classStyle, flexDirection: 'column-reverse' }
      : classStyle
    classStyle = column
      ? { ...classStyle, flexDirection: 'column' }
      : classStyle
    classStyle = rowReverse
      ? { ...classStyle, flexDirection: 'row-reverse' }
      : classStyle
    classStyle = textCenter
      ? { ...classStyle, textAlign: 'center' }
      : classStyle
    classStyle = alignItemsFlexStart
      ? { ...classStyle, alignItems: 'flex-start' }
      : classStyle
    classStyle = alignItemsCenter
      ? { ...classStyle, alignItems: 'center' }
      : classStyle
    classStyle = alignItemsFlexEnd
      ? { ...classStyle, alignItems: 'flex-end' }
      : classStyle
    classStyle = justifyContentCenter
      ? { ...classStyle, justifyContent: 'center' }
      : classStyle
    classStyle = justifyContentFlexEnd
      ? { ...classStyle, justifyContent: 'flex-end' }
      : classStyle
    classStyle = justifyContentFlexStart
      ? { ...classStyle, justifyContent: 'flex-start' }
      : classStyle
    classStyle = justifyContentSpaceAround
      ? { ...classStyle, justifyContent: 'space-around' }
      : classStyle
    classStyle = justifyContentSpaceEvenly
      ? { ...classStyle, justifyContent: 'space-evenly' }
      : classStyle
    classStyle = justifyContentSpaceBetween
      ? { ...classStyle, justifyContent: 'space-between' }
      : classStyle
    return classStyle
  }}
	${(props: IFlexProps) => {
    return props.childrenCount
      ? {
          'justify-content': 'flex-start',
          'flex-wrap': 'wrap',
          '> *': {
            width: `${Math.floor(100 / props.childrenCount) - 1}%`,
            margin: '0px .5%'
          }
        }
      : {}
  }}
  ${layout}
`

const Flex = React.forwardRef((props: IFlexProps, ref?: any) => {
  let { children, width, wrap } = Props(props)
  let NewProps = { ...Props(props) }
  delete NewProps.wrap
  if (!ref) {
    ref = React.createRef()
  }

  return (
    <Div ref={ref} width={width} wraps={wrap} {...NewProps}>
      {children}
    </Div>
  )
})

export default Flex
