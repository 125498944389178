import { IState, IRouteConfig } from '@fareye/types'
import { RenderControl } from '@fareye/ui'
import {
  axiosInstance,
  cancelToken,
  get,
  isJourneyModel,
  HEADERS,
  isEmpty,
  responseParser
} from '@fareye/utils'
import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { RoutesPath, IN_PLANT } from './config/constants'
import { mappingComponents } from './config/mapComponents'
import { mustHaveRoutes, routesConfig } from './config/routes.config'
import environment from './environment'
import ErrorPages from './modules/ErrorPages'
import Layout from './modules/Layout/'
import { useCookies } from 'react-cookie'
import { TOKEN_KEY } from 'config/app'

axiosInstance.defaults.withCredentials = true

function AppLayout(props: any) {
  const [menuOpen, setMenuOpen] = React.useState(false)
  let { baseURL, mapKey, defaultBaseLayer } = environment
  const { history } = props
  const [routeConfig, setRouteConfig] = React.useState<IState<IRouteConfig[]>>({
    data: [],
    message: '',
    isError: false,
    isSuccess: false,
    isLoading: true
  })
  const [cookies, setCookie] = useCookies()
  const isLoggedIn = localStorage.getItem(TOKEN_KEY) // || isAccessCookieExist(cookies)

  async function fetchRouteAndMenu() {
    const source = cancelToken().source()
    try {
      const subType = isJourneyModel() ? 'journeyRouteAndMenu' : 'routeAndMenu'
      const { data } = responseParser(
        await get(
          `${baseURL}/api/v1/records?sub_type=${subType || 'routeAndMenu'}`,
          source.token
        )
      )
      const { configs, samples } = data
      const routeMenuConfig = isEmpty(configs) ? samples : configs
      setRouteConfig({
        ...routeConfig,
        data: routesConfig as any,
        // data: isEmpty(routeMenuConfig)
        //   ? routesConfig
        //   : routeMenuConfig[0].data.config,
        isLoading: false,
        isSuccess: true
      })
    } catch (err) {
      const { data, error, message } = responseParser(err.response)
      setRouteConfig({
        ...routeConfig,
        data,
        isSuccess: false,
        isError: error,
        isLoading: false,
        message
      })
      console.error('App Error:', err)
    }
  }
  React.useLayoutEffect(() => {
    if (!isLoggedIn && history.location.pathname === '/') {
      history.replace(RoutesPath.LOGIN)
    }
  }, [])
  React.useEffect(() => {
    if (isLoggedIn) {
      fetchRouteAndMenu()
    }
  }, [])
  const allRoutes: any[] = [...mustHaveRoutes]
  if (!isEmpty(routeConfig.data)) {
    iterateRouteConfig(routeConfig.data)
  }

  function iterateRouteConfig(routes: any) {
    routes.map((route: any, n: number) => {
      if (route.children?.length) {
        if (route.key === IN_PLANT) {
          localStorage.setItem(IN_PLANT, 'true')
        }
        iterateRouteConfig(route.children)
      } else {
        if (mappingComponents[route.key]) {
          allRoutes.push({
            path: route.path,
            config: mappingComponents[route.key].config,
            icon: mappingComponents[route.key]
              ? mappingComponents[route.key].icon
              : '',
            component: mappingComponents[route.key].component,
            exact: route.exact,
            key: route.key,
            layout: route.layout,
            subType: route.subType,
            pageId: route.pageId,
            authenticated: route.authenticated
          })
        }
      }
      return false
    })
  }

  return (
    <RenderControl
      data={isLoggedIn ? routeConfig.data : allRoutes}
      isSuccess={!isEmpty(isLoggedIn ? routeConfig.data : allRoutes)}
      isLoading={isEmpty(isLoggedIn ? routeConfig.data : allRoutes)}
      message={routeConfig.message}
      variant="xl"
    >
      {!isEmpty(isLoggedIn ? routeConfig.data : allRoutes) ? (
        <>
          <Switch>
            {allRoutes.map((route: any, i: number) => (
              <Route
                key={`${route.path}-${i}`}
                render={props => (
                  <Layout
                    route={route}
                    menuOpen={menuOpen}
                    refreshInterval={null} // 10 | 15 | 20 | 30 | null
                    maxRefreshCount={0} // 10 | 20 | 40
                    setMenuOpen={() => setMenuOpen(!menuOpen)}
                    routesConfig={routeConfig.data}
                    mapKey={mapKey}
                    defaultBaseLayer={defaultBaseLayer}
                    pageConfigKey={route.pageId && route.pageId}
                    baseUrl={
                      environment[route.key] ? environment[route.key] : baseURL
                    }
                    subType={route.subType && route.subType}
                    {...props}
                  />
                )}
                path={route.path}
                exact={route.exact}
              />
            ))}
            <Route path="/" component={ErrorPages} />
          </Switch>
        </>
      ) : (
        <div />
      )}
    </RenderControl>
  )
}

export default withRouter(AppLayout)
