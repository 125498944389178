import React from 'react'
import { IGraphs, IKeyValueString } from '../utility/types'
import {
  getGraphColors,
  getSelectedGraphType,
  dataPointWidth
} from './utility/funcs'
import { yAxisWidthCalculator, xAxisHeightCalculator } from './utility/funcs'
import { Flex, Loader } from '../main'
import styled from 'styled-components'

export const XLabelWrapper = styled.div`
  text-align: center;
  margin-top: 0;
  line-height: 1;
`
export const YLabelWrapper = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  height: 100%;
  span {
    transform: rotate(-90deg) translateX(-50%);
    position: absolute;
    transform-origin: 0 0;
  }
`

export const graphWidth = (
  selector: string,
  width?: 0.9 | 0.75 | 0.6 | 0.5 | 0.3 | 0.25 | 'auto'
) => {
  const element = document.getElementById(selector)
  const elementWidth = element && element.offsetWidth
  if (elementWidth && width) {
    return width !== 'auto' ? elementWidth * width : elementWidth * 0.95
  }
}

function Graph(props: IGraphs) {
  let {
    data,
    type,
    config,
    events,
    margin,
    XLabel,
    YLabel,
    stacked,
    selector,
    XLabelKey,
    YLabelKey,
    showLegends,
    tickAngle = 45,
    ratio = 16 / 9,
    minAxisSizeForLabel,
    layout = 'vertical'
  } = props

  // Resizing window code
  const el = document.getElementById(selector)
  const IS_CLIENT = typeof window === 'object'
  const wSize = (el: any) => [el.offsetWidth, el.offsetHeight]
  const noSize = [undefined, undefined]
  const getSize: any = IS_CLIENT
    ? () => [el && el.offsetWidth, el && el.offsetHeight]
    : () => [undefined, undefined]
  const [windowSize, setWindowSize] = React.useState(getSize)
  let [width, height] = windowSize
  const resizer = React.useCallback(() => {
    const setSize = el ? wSize(el) : noSize
    if (setSize !== windowSize) {
      setWindowSize(setSize)
    }
  }, [el]) //eslint-disable-line
  React.useLayoutEffect(() => {
    resizer()
  }, [el]) //eslint-disable-line
  React.useEffect(() => {
    if (!IS_CLIENT) {
      return
    }
    window.addEventListener('resize', resizer)
    return () => window.removeEventListener('resize', resizer)
  }, [IS_CLIENT, el, noSize, resizer, windowSize])
  // Resizing window code

  height = width ? Math.round(width / ratio) : 0
  YLabelKey =
    stacked || type === 'candle-stick'
      ? YLabelKey
      : YLabelKey.filter((x, i: number) => i < 1)

  const GraphType = getSelectedGraphType(type, layout)

  const graphColors: IKeyValueString = getGraphColors(
    data,
    type,
    XLabelKey,
    YLabelKey,
    config.colors,
    stacked
  )
  const yAxisWidth = yAxisWidthCalculator(
    data,
    width,
    XLabelKey,
    layout,
    config.yAxisUnit,
    minAxisSizeForLabel
  )
  const xAxisHeight = xAxisHeightCalculator(
    data,
    height,
    XLabelKey,
    layout,
    config.xAxisUnit,
    minAxisSizeForLabel
  )

  return (
    <Flex column>
      <Flex alignItemsCenter style={{ position: 'relative' }}>
        {type !== 'pie' && (
          <YLabelWrapper>
            <span>{layout === 'vertical' ? YLabel : XLabel}</span>
          </YLabelWrapper>
        )}
        <React.Suspense fallback={<Loader />}>
          <GraphType
            data={data}
            height={height > 0 ? Math.max(height, 220) : 0}
            events={events}
            margin={margin}
            stacked={stacked}
            width={width ? width - 20 : 0}
            tickAngle={tickAngle}
            XLabelKey={XLabelKey}
            YLabelKey={YLabelKey}
            yAxisWidth={yAxisWidth}
            xAxisHeight={xAxisHeight}
            showLegends={showLegends}
            config={{ ...config, colors: graphColors }}
            dataPointWidth={dataPointWidth(width, data)}
          />
        </React.Suspense>
      </Flex>
      {type !== 'pie' && (
        <XLabelWrapper>{layout === 'vertical' ? XLabel : YLabel}</XLabelWrapper>
      )}
    </Flex>
  )
}

export default React.memo(Graph)
