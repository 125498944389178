import { FaSpinner, isEmpty } from '@fareye/utils'
import React from 'react'
import styled from 'styled-components'
import { Props } from '../Theme/styledSystem'
import { IRenderControlProps } from '../utility/types'

const SpacedCentralizedContainer = styled.div`
  ${(props: IRenderControlProps) => {
    return props.theme.renderControl[props.variant || 'default']
  }};
  overflow: hidden;
`

export const Loader = styled(SpacedCentralizedContainer)`
  animation: 1s spinner linear infinite;
  @keyframes spinner {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
`

const RenderControl = (props: IRenderControlProps) => {
  let { children, state, data, isError, isLoading, isSuccess, message } = props
  if (state !== undefined) {
    data = state.data
    isError = state.isError
    isLoading = state.isLoading
    isSuccess = state.isSuccess
    message = state.message
  }
  function render() {
    if (isLoading) {
      return (
        <div style={{ overflow: 'hidden' }}>
          <Loader {...Props(props)}>
            <FaSpinner />
          </Loader>
        </div>
      )
    } else if (isSuccess && !isEmpty(data)) {
      return <>{children}</>
    } else if (message && message !== '') {
      return (
        <SpacedCentralizedContainer {...Props(props)}>
          {message}
        </SpacedCentralizedContainer>
      )
    } else if (isError) {
      console.error(message)
    }
  }

  return <>{render()}</>
}

RenderControl.displayName = 'RenderControl'

export default RenderControl
