import { Card, Flex, RenderControl, Span } from '@fareye/ui'
import {
  cancelToken,
  get,
  isEmpty,
  responseParser,
  getQueryParams,
  post
} from '@fareye/utils'
import React from 'react'
import { HEADER_INFLECTION } from '../../../../config/constants'
import { StyledTable } from '../../../../style/styled'
import { ISubscriptionListing } from '../../../../utility/types'
import { useTimeZone } from '@fareye/common'
const recordsPerPage = 20

const SubscriptionListing = React.memo(
  ({
    subscriptionType,
    reportData,
    baseUrl,
    selectedColumns,
    selectedReportConfig,
    setReportData,
    pageNo,
    setPageNo
  }: ISubscriptionListing) => {
    const { state } = useTimeZone()
    const source = cancelToken().source()
    const { data, queryString, meta, postQuery } = React.useMemo(
      () => reportData,
      [reportData]
    )
    const {
      apiUrl: pathParam,
      apiUrlRequestType = 'GET',
      label: selectReportLabel,
      queryParams
    } = selectedReportConfig

    React.useEffect(() => {
      //FIXME: Key inflection not working need to remove this onc backend is done
      if (
        queryString &&
        data.length < (pageNo + 1) * recordsPerPage &&
        meta.recordsPerPage != meta.totalElements
      ) {
        fetchReportDataOnPaginationChange()
      }
      return () => {
        source.cancel('Unmount Report component')
      }
    }, [pageNo])

    React.useEffect(() => {
      setPageNo(0)
      return () => {
        source.cancel('Unmount Report component')
      }
    }, [subscriptionType, queryString])

    async function fetchReportDataOnPaginationChange() {
      try {
        setReportData({ ...reportData, isLoading: true })
        const configQueryParam = queryParams ? getQueryParams(queryParams) : ''
        let apiData: any = {}
        let apiMessage: any = ''
        if (apiUrlRequestType === 'GET') {
          const Url = `${baseUrl}${pathParam}?${queryString}&pageNo=${pageNo +
            1}&${configQueryParam}`
          const { data, message } = responseParser(
            await get(Url, source.token, HEADER_INFLECTION)
          )
          apiMessage = message
          apiData = data
        } else {
          const { data, message }: any = responseParser(
            await post(
              `${baseUrl}${pathParam}`,
              { filter: postQuery, page: pageNo + 1, perPage: recordsPerPage },
              source.token,
              HEADER_INFLECTION
            )
          )
          apiData = data
          apiMessage = message
        }
        let tempData = [...reportData.data]
        tempData.splice(
          pageNo * recordsPerPage,
          recordsPerPage,
          ...apiData.content
        )
        setReportData({
          ...reportData,
          data: tempData,
          meta: apiData.meta,
          isLoading: false,
          isSuccess: true,
          message: isEmpty(apiData.content)
            ? 'No Data found for selected filters...'
            : apiMessage
        })
      } catch (ex) {
        const { error, message } = responseParser(ex.response)
        setReportData({
          ...reportData,
          isLoading: false,
          isSuccess: !error,
          isError: error,
          message
        })
      }
    }

    function Header() {
      return (
        <Flex p={7} alignItemsCenter justifyContentSpaceBetween>
          <Span variant="h6" style={{ color: 'black', fontWeight: '600' }}>
            {`Result for ${selectReportLabel}`}
          </Span>
        </Flex>
      )
    }
    return (
      <Card bg="#fff" header={Header()}>
        <RenderControl
          data={reportData.data}
          isLoading={reportData.isLoading}
          isSuccess={reportData.isSuccess}
          isError={reportData.isError}
          message={reportData.message}
        >
          <StyledTable
            data={data}
            size="xs"
            sortable
            dataLoading={reportData.isLoading}
            columns={selectedColumns}
            className="responsive striped"
            totalElements={meta.totalElements}
            disablePageSizeChange
            defaultPageIndex={pageNo}
            defaultPageSizeOptions={[recordsPerPage]}
            onPageChange={(pageIndex: number) => setPageNo(pageIndex)}
          />
        </RenderControl>
      </Card>
    )
  }
)

export default SubscriptionListing
