import React from 'react'
import { Link as Anchor } from 'react-router-dom'
import styled from 'styled-components'
import { LoadingWrapper } from '../style/styled'
import { Loader } from './../main'
import {
  calculateSizesforLoader,
  layout,
  splitVariantsToTypes
} from './../Theme/styledSystem'
import { IButtonProps } from '../utility/types'

const StyledLink: any = styled(Anchor)`
  position: relative;
  text-decoration: none;
  ${(props: IButtonProps) => {
    const variant = splitVariantsToTypes(props)
    const style = {
      ...props.theme.buttons['link'],
      ...props.theme[variant.size],
      ...(props.ghost ? props.theme.buttons[variant.display].ghost : {}),
      ...(props.disabled
        ? {
            pointerEvents: 'none',
            color: ''
          }
        : {})
    }
    return style
  }}
  ${layout}
`

export interface ILink extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: string
  isLoading?: boolean
  disabled?: boolean
  to: string
  onClick?: (e: any) => void
}

function Link(props: ILink) {
  const { isLoading, variant, disabled, to } = props
  const loaderSize = calculateSizesforLoader(variant)
  const OtherProps = { ...props }
  delete OtherProps.to
  delete OtherProps.onClick
  return (
    <StyledLink {...props} to={disabled ? '' : to}>
      {props.children}
      {isLoading && (
        <LoadingWrapper>
          <Loader size={loaderSize} />
        </LoadingWrapper>
      )}
    </StyledLink>
  )
}

export default Link
