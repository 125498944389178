import React from 'react'
import styled from 'styled-components'
import { layout, Props } from '../../Theme/styledSystem'
import { ITypographyProps } from '../../utility/types'

const StyledParagraph: any = styled.p`
  ${layout} ${(props: ITypographyProps) =>
    props.theme.paragraph[props.variant || 'default']};
`

const Paragraph = (props: ITypographyProps) => {
  let { children, variant } = Props(props)
  return (
    <StyledParagraph
      {...props}
      title={variant === 'ellipsis' ? children : props.title}
    >
      {children}
    </StyledParagraph>
  )
}

export default Paragraph
