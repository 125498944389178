import React from 'react'
import { TIMEZONE_KEY } from '../config/constants'
interface ITimeZoneProvider {
  children: React.ReactNode
  value?: ITimeZone
}
interface ITimeZone {
  id: string
  value: string
  text: string
  displayText: string
  //   abbr:string
  //   offset: string
  //   isDst?: boolean
}

type Dispatch = (action: ITimeZone) => void
const TimeZoneContext = React.createContext<{
  state: ITimeZone
  setState: Dispatch
}>({ state: getDefaultTimeZone(), setState: (value: ITimeZone) => {} })

function getDefaultTimeZone() {
  //Implement Default Timezone as Browser Timezone
  let stringifyData = localStorage.getItem(TIMEZONE_KEY)
  let data
  if (stringifyData) {
    data = JSON.parse(stringifyData)
  } else {
    data = {
      id: 'system',
      value: Intl.DateTimeFormat().resolvedOptions().timeZone,
      displayText: 'System Timezone',
      text: ''
    }
    localStorage.setItem(TIMEZONE_KEY, JSON.stringify(data))
  }
  return data
}

function TimeZoneProvider({ children, value }: ITimeZoneProvider) {
  const [state, setState] = React.useState(value || getDefaultTimeZone())
  const timeZoneState = { state, setState }
  return (
    <TimeZoneContext.Provider value={timeZoneState}>
      {children}
    </TimeZoneContext.Provider>
  )
}

function useTimeZone() {
  const timeZone = React.useContext(TimeZoneContext)
  if (!timeZone) {
    throw new Error('UseTimezone Must be used within TimeZoneProvider')
  }
  return timeZone
}

export { useTimeZone, TimeZoneProvider }
