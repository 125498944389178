import { Card, Flex, Label, Link, Span } from '@fareye/ui'
import {
  cancelToken,
  FaBell,
  FaBellSlash,
  hhmmAdoMMMNoIST,
  pascalCase,
  put
} from '@fareye/utils'
import React from 'react'
import { CreatedAtStyle, FaBellContainer } from '../../../../style/styled'
import { getColorOnSeverity } from '../../../../utility/func'
import { IIssue, IIssueListingApiConfig } from '../../../../utility/types'
import CommentsContainer from '../comments/CommentListing'

interface IIssueCardProps {
  issue: IIssue
  baseUrl: string
  config: IIssueListingApiConfig
  resourceType?: string
}

const IssueCard = ({
  issue,
  config,
  baseUrl,
  resourceType = ''
}: IIssueCardProps) => {
  const source = cancelToken().source()
  const {
    config: { links },
    children: { subscribe }
  } = config
  const [status, setStatus] = React.useState(issue.is_subscribed)
  async function subscribeAndUnsubscribeIssue(
    isSubscribe: boolean,
    threadId: number
  ) {
    try {
      const { apiURL } = subscribe.config.apiConfig
      const url = `${baseUrl}${apiURL}/${threadId}/${
        isSubscribe ? 'subscribe' : 'unsubscribe'
      }`
      await put(url, {}, source.token)
      setStatus(isSubscribe)
    } catch (ex) {
      console.error(ex)
    }
  }
  const isMobi = location?.pathname.includes('/v2/view')
  const severity = issue.severity ? issue.severity : 'Low'
  return (
    <Card variant="s" m={1} bg={'white'}>
      <Flex justifyContentSpaceBetween alignItemsCenter>
        <Link
          style={{ padding: 0 }}
          to={`${isMobi ? '/v2/view' : ''}${
            links
              ? resourceType
                ? links[resourceType]
                : links.tripDetailsUrl
              : ''
          }/${issue.slug}?issueRaised=open`}
        >
          {issue.invoice_no}
        </Link>
        <div>
          <Span
            variant="h6"
            style={{
              padding: '5px 6px',
              background: getColorOnSeverity(severity.toLowerCase()),
              color: '#fff',
              borderRadius: 5,
              fontSize: '12px'
            }}
          >
            {pascalCase(severity)}
          </Span>
          <FaBellContainer
            onClick={() => {
              subscribeAndUnsubscribeIssue(!status, issue.thread_id)
            }}
          >
            {status ? (
              <FaBell title="Subscribed" />
            ) : (
              <FaBellSlash title="Unsubscribed" />
            )}
          </FaBellContainer>
        </div>
      </Flex>
      <Label style={{ fontSize: '15px' }} title={issue.title}>
        {issue.category}
        {' - '}
        {issue.title}
      </Label>
      <CreatedAtStyle>{hhmmAdoMMMNoIST(issue.updated_at)}</CreatedAtStyle>
      <CommentsContainer
        count={issue.comment_count}
        issueId={issue.id}
        threadId={issue.thread_id}
        baseUrl={baseUrl}
        config={config}
      />
    </Card>
  )
}
export default IssueCard
