import React from 'react'
import { ISelectedFilterListing } from '../types'
import { Flex, Button, Span } from '../../main'
import { FaTimes, isEmpty, startCase } from '@fareye/utils'
import { IKeyValue } from '@fareye/types'

const exceptions: any = {
  DisconnectionAlertSubscription: 'Disconnection Alert',
  IdleAlertSubscription: 'Idle Alert',
  OverspeedAlertSubscription: 'Overspeed Alert',
  TsDelayAlertSubscription: 'Ts Delay Alert',
  RolloverAlertSubscription: 'Roll Over Alert',
  POLDelayAlertSubscription: 'POL Delay Alert',
  VehicleBreakdownAlertSubscription: 'Vehicle Breakdown Alert',
  RouteDevAlertSubscription: 'Route Deviation Alert',
  PoiDurationAlertSubscription: 'POI Duration Alert'
}

function SelectedFilterListing({
  selectedFilters,
  setSelectedFilters
}: ISelectedFilterListing) {
  function removeSelected(item: IKeyValue<string | number>) {
    const tempSearch: any[] | undefined = selectedFilters?.filter(
      (filter: IKeyValue<string | number>) => {
        return !(filter.key === item.key && filter.value === item.value)
      }
    )

    setSelectedFilters ? setSelectedFilters(tempSearch) : null
  }
  const remove = () => {
    setSelectedFilters ? setSelectedFilters([]) : null
  }
  return (
    <Flex
      style={{ width: '100%' }}
      alignItemsFlexStart
      //   style={{ margin: `${selectedFilters.length > 0 ? '5px 0 0 0' : 0}` }}
    >
      {!isEmpty(selectedFilters) ? (
        <>
          <Flex style={{ flex: '0 0 auto' }}>
            <Span
              m={2}
              width={54}
              style={{ cursor: 'pointer' }}
              variant="primary"
              onClick={() => remove()}
            >
              Clear All
            </Span>
          </Flex>
          <Flex style={{ flex: '1 0 100px', overflow: 'auto' }}>
            {selectedFilters?.map((item, i) => (
              <Button
                m={1}
                key={i}
                variant="secondary xs"
                rightStyle={{ marginLeft: 5 }}
                rightChild={
                  <FaTimes color="#333" onClick={() => removeSelected(item)} />
                }
              >
                {item.key === 'alert_event_types'
                  ? exceptions[item.label]
                  : startCase(item.label)}
              </Button>
            ))}
          </Flex>
        </>
      ) : null}
    </Flex>
  )
}

export default SelectedFilterListing
