import React from 'react'
import { IGraphRendererConfig } from '../../utility/types'
import { Modal, Flex } from '@fareye/ui'
import GraphRenderer from '../GraphRenderer/GraphRenderer'
import { cloneDeep } from '@fareye/utils'

interface IGraphRendererFullScreen {
  config: IGraphRendererConfig
  FullScreenComponent?: any
  fullScreen: boolean | undefined
  setFullScreen: (value: any) => void
}
export default ({
  config,
  fullScreen,
  setFullScreen,
  FullScreenComponent
}: IGraphRendererFullScreen) => {
  const clonedConfig = cloneDeep(config)
  if (clonedConfig.style) {
    clonedConfig.style.fullScreen = fullScreen
    clonedConfig.style.graphScreenRatio = 16 / 5
  }

  function generateFullScreenGraph() {
    if (FullScreenComponent) {
      return FullScreenComponent
    }
    return (
      <GraphRenderer
        {...clonedConfig}
        style={{
          ...clonedConfig.style,
          wrapper: {
            ...(clonedConfig.style && clonedConfig.style.wrapper),
            width: '100%',
            height: '100%'
          }
        }}
      />
    )
  }

  return (
    <Modal
      toggleModal={Boolean(fullScreen)}
      variant="xxl drawer Right-Center"
      style={{
        content: {
          maxHeight: '95vh',
          height: '95vh'
        }
      }}
      setToggleModal={setFullScreen}
    >
      <Flex column style={{ marginTop: 30 }}>
        {generateFullScreenGraph()}
      </Flex>
    </Modal>
  )
}
