import { dateFormatter } from '@fareye/common'
import { Flex, Grid, RenderControl, Span, styled } from '@fareye/ui'
import {
  cancelToken,
  get,
  getQueryParams,
  isEmpty,
  isToday
} from '@fareye/utils'
import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { getDate } from 'utility/funcs'

const Description = styled.span`
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`
const CustomLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #212121;
`
const styles: any = {
  enable: {
    cursor: 'pointer',
    opacity: 1,
    pointerEvents: 'all'
  },
  disable: {
    pointerEvents: 'none',
    opacity: 0.4
  },
  span: {
    fontSize: '15px'
  }
}
export default function NewsFeeds() {
  const pageSize = 3
  const newsData = JSON.parse(localStorage.getItem('newsData') || '{}')
  const [currentPage, setCurrentPage] = React.useState(1)
  const source = cancelToken().source()
  const [state, setState] = React.useState({
    data: newsData.data || [],
    isLoading: false,
    isError: false,
    isSuccess: true,
    message: ''
  })
  let fixedNews = [
    {
      source: {
        id: null,
        name: 'Castanet.net'
      },
      author: null,
      title:
        'China’s Omicron Outbreak Is Even Worse News for Global Supply Chains',
      description:
        'An omicron outbreak in China is sending disruptions rippling through supply chains as manufacturers and shippers brace for shutdowns inside the world’s-biggest trading nation if it can’t contain the fast-spreading Covid-19 variant.',
      url:
        'https://www.caixinglobal.com/2022-01-13/chinas-omicron-outbreak-is-even-worse-news-for-global-supply-chains-101829375.html',
      urlToImage: 'https://img.caixin.com/2022-01-13/164202098378868.jpg',
      publishedAt: '2022-01-13T04:58:00Z',
      content:
        "The federal government's last-minute decision to backtrack on a vaccine mandate for truckers will do little to relieve pressure on strained supply chains or prevent price hikes for food and other goo… [+3341 chars]"
    },
    {
      source: {
        id: null,
        name: 'Castanet.net'
      },
      author: null,
      title:
        'Maersk provides a snapshot of growing port congestion around the world',
      description:
        'Unfortunately, 2022 has not started off as we had hoped,” Maersk warned, adding: “The pandemic is still going strong and unfortunately, we are seeing new outbreaks impacting our ability to move your cargo. General sickness remains high as key ports in key regions are seeing new COVID-19 peaks.',
      url:
        'https://splash247.com/maersk-provides-a-snapshot-of-growing-port-congestion-around-the-world/',
      urlToImage:
        'https://467222-1467340-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2015/10/PTP-Port-Malaysia-e1641968510967-780x470.jpg',
      publishedAt: '2022-01-12T19:56:00Z',
      content:
        "The federal government's last-minute decision to backtrack on a vaccine mandate for truckers will do little to relieve pressure on strained supply chains or prevent price hikes for food and other goo… [+3341 chars]"
    },
    {
      source: {
        id: null,
        name: 'Castanet.net'
      },
      author: null,
      title:
        'OSHA clarifies: Solo truck drivers don’t fall under Biden vaccine mandate',
      description:
        'The Biden administration’s proposed so-called vaccination mandate in the workplace does not apply to solo truck drivers, according to new guidance handed down by the Occupational Safety and Health Administration. ',
      url:
        'https://www.freightwaves.com/news/osha-clarifies-solo-truck-drivers-dont-fall-under-biden-vaccine-mandate',
      urlToImage:
        'https://s29755.pcdn.co/wp-content/uploads/2022/01/Smart_Signs_FreightWaves_Vaccination_1-2048x1152.jpg.webp',
      publishedAt: '2022-01-13T19:56:00Z',
      content:
        "The federal government's last-minute decision to backtrack on a vaccine mandate for truckers will do little to relieve pressure on strained supply chains or prevent price hikes for food and other goo… [+3341 chars]"
    }
  ]
  let initialquery =
    'Logistics  AND (cyclone OR bad weather OR thunderstorm OR heavy rain OR supply chain disruption OR war OR flood OR heavy traffic OR embargo OR pandemic OR earthquake OR tsunami OR shortages OR truck OR container OR shipping line OR traffic congestion OR curfew OR container shortage OR container stuck OR port OR port congestion OR ocean freight OR freight OR transportation)'
  async function fetchData(query = initialquery) {
    try {
      setState({ ...state, isLoading: true })
      let apiUrl = '/api/v1/news_feed/get_news_feed_Data?'
      let params = {
        q: query,
        from: getDate(1),
        to: getDate(),
        sortBy: 'relevance',
        pageSize: 15
      }
      apiUrl += getQueryParams(params)
      let { data } = await get(apiUrl, source.token, {
        headers: { 'Access-Control-Allow-Origin': '*' }
      })
      let articles = data?.articles
      console.log(articles)
      setState({
        ...state,
        data: articles,
        isLoading: false,
        isSuccess: true,
        isError: false
      })
      let localData = {
        query: query,
        data: articles,
        date: new Date(),
        prevQuery: query
      }
      localStorage.setItem('newsData', JSON.stringify(localData))
    } catch (ex) {
      setState({
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: true
      })
    }
  }

  React.useEffect(() => {
    if (isEmpty(newsData)) {
      fetchData()
    } else if (isToday(new Date(newsData.date))) {
      if (newsData.query !== newsData.prevQuery) {
        fetchData(newsData.query)
      } else {
        console.log('Data is not loaded')
      }
    } else {
      fetchData(newsData.query)
    }
  }, [])
  function previousPage() {
    if (isShowPrevious()) {
      setCurrentPage(currentPage - 1)
    }
  }
  function nextPage() {
    if (isShowNext()) {
      setCurrentPage(currentPage + 1)
    }
  }
  function isShowNext() {
    return currentPage + 1 <= totalPage
  }
  function isShowPrevious() {
    return currentPage - 1 >= 1
  }
  const currentData = state?.data.slice(
    (currentPage - 1) * pageSize,
    pageSize * currentPage
  )
  const totalPage = Math.ceil(state?.data.length / pageSize)
  return (
    <Grid
      style={{
        height: '100%'
      }}
      gridGap="2.5em"
    >
      <RenderControl state={state}>
        <Flex alignItemsFlexStart justifyContentSpaceBetween>
          <CustomLabel>News Feeds</CustomLabel>
          <Flex alignItemsCenter>
            <Span style={styles.span}>
              {currentPage} of {totalPage}
            </Span>
            <Span style={styles.span}>
              <FaChevronLeft
                style={
                  isShowPrevious()
                    ? { ...styles.enable }
                    : { ...styles.disable }
                }
                onClick={() => previousPage()}
              />
              <FaChevronRight
                style={
                  isShowNext() ? { ...styles.enable } : { ...styles.disable }
                }
                onClick={() => nextPage()}
              />
            </Span>
          </Flex>
        </Flex>
        {currentData.map((ele: any, index: number) => {
          return <NewsCard key={ele.url} newsData={ele} index={index} />
        })}
      </RenderControl>
    </Grid>
  )
}

function NewsCard({ newsData, index }: any) {
  function openNewsPage() {
    window.open(newsData.url, 'blank')
  }
  return (
    <Grid
      onClick={openNewsPage}
      key={newsData.url}
      gridGap="8px"
      gridTemplateColumns={'160px 200px'}
      style={{
        cursor: 'pointer',
        borderBottom: index === 2 ? 'none' : '1px solid #DADADA',
        paddingBottom: '2.5em'
      }}
    >
      <img
        style={{ width: '160px', height: '100%', borderRadius: '5px' }}
        src={newsData.urlToImage}
      ></img>
      <Flex column>
        <Description title={newsData.description}>
          {newsData.description}
        </Description>
        <Span style={{ marginTop: '8px' }}>
          {dateFormatter(newsData.publishedAt)}
        </Span>
      </Flex>
    </Grid>
  )
}
