import { Button, Card, Flex, Input, SearchSelect, FilePicker } from '@fareye/ui'
import {
  cloneDeep,
  cancelToken,
  get,
  post,
  isEmpty,
  FaPaperclip
} from '@fareye/utils'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { mixed, object, string } from 'yup'
import {
  IssueContainerFlex,
  IssueHeadingText,
  IssueLabelText,
  IssueValueFlex,
  GroupedButton
} from '../../../../style/styled'
import CommentForm from '../comments/CommentForm'
import CustomErrorMessage from '../ErrorMessage'
import { IIssueListingApiConfig } from '../../../../utility/types'

const source = cancelToken().source()

export interface IIssueForm {
  resourceId: string
  resourceType: string
  onSubmit: (value: any) => any
  setShowForm: (value: any) => any
  showSubmitLoader: boolean
  baseUrl: string
  config: IIssueListingApiConfig
  defaultFormValue?: any
  resetFormValues?: any
  setDefaultIssueForm?: any
}
export interface IDurationChange {
  value: string
  milliseconds: number
  error: string
}
export interface ICategory {
  issueSeverity: any[]
  issueStatus: any[]
  issueCategory: any[]
}
const IssueForm = ({
  resourceId,
  resourceType,
  onSubmit,
  showSubmitLoader,
  config,
  baseUrl,
  setShowForm,
  defaultFormValue,
  setDefaultIssueForm
}: IIssueForm) => {
  const issueFormSource = cancelToken().source()
  const [categoryData, setCategoryData] = React.useState<ICategory | null>(null)
  const [files, setFiles] = React.useState<any>([])
  const {
    children: { category }
  } = config
  console.log(defaultFormValue?.comment)
  const resetFormValues = {
    title: '',
    severity: null,
    category: null,
    resourceId: resourceId,
    resourceType: resourceType,
    comment: ''
  }
  const [comment, setComment] = React.useState(defaultFormValue?.comment || '')
  const initialState = defaultFormValue || resetFormValues

  const validationSchema = object().shape({
    title: string()
      .required('* Required')
      .max(40, '* Enter less than 40 characters'),
    severity: mixed().required('* Required'),
    category: mixed().required('* Required'),
    comment: string().required('* Required')
    //NOTE: Enable this if you want to add at least one user
    //   .matches(
    //     /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
    //     '* Tag at least one user '
    //   )
  })
  async function getCategory() {
    try {
      const { apiURL } = category.config.apiConfig
      const apiUrl = `${baseUrl}${apiURL}`
      const { data: categoryData } = await get(apiUrl, issueFormSource.token)
      setCategoryData(categoryData)
    } catch (ex) {
      console.error(ex)
    }
  }
  React.useEffect(() => {
    getCategory()
  }, [])
  console.log(defaultFormValue, '*******')
  return (
    <Card bodyStyle={{ padding: '5px 10px' }} variant="m" m={1} bg={'white'}>
      <Formik
        initialValues={initialState}
        onSubmit={fields => {
          const data: any = cloneDeep(fields)
          data.category = data.category.label
          data.severity = data.severity.label
          data.comment = { body: comment }
          const filePayload: any = new FormData()
          files.forEach((file: any, index: number) => {
            filePayload.append('attachment[]', files[index])
          })
          onSubmit({ value: data, filePayload })
        }}
        validationSchema={validationSchema}
      >
        {({
          handleReset,
          handleSubmit,
          handleChange,
          values,
          setFieldValue,
          setFieldTouched,
          isValid,
          errors,
          resetForm
        }) => (
          <Form onSubmit={handleSubmit}>
            <IssueHeadingText>Raise An Issue</IssueHeadingText>
            <IssueContainerFlex>
              <IssueLabelText>Title</IssueLabelText>
              <IssueValueFlex column>
                <Input
                  placeholder="Enter the title"
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('title', true)}
                  name="title"
                  value={values.title}
                />
                <ErrorMessage component={CustomErrorMessage} name="title" />
              </IssueValueFlex>
            </IssueContainerFlex>
            <IssueContainerFlex>
              <IssueLabelText>Severity</IssueLabelText>
              <IssueValueFlex column>
                <>
                  <GroupedButton>
                    {categoryData &&
                      categoryData.issueSeverity.map((item: any, i: number) => {
                        return (
                          <Button
                            key={i}
                            type="button"
                            style={{ flexGrow: 1, justifyContent: 'center' }}
                            variant={`m ${(values.severity &&
                              (values.severity as any).value) === item.value &&
                              'primary'}`}
                            onClick={() => setFieldValue(`severity`, item)}
                          >
                            {item.label}
                          </Button>
                        )
                      })}
                  </GroupedButton>
                  <ErrorMessage
                    component={CustomErrorMessage}
                    name="severity"
                  />
                </>
              </IssueValueFlex>
            </IssueContainerFlex>
            <IssueContainerFlex>
              <IssueLabelText>Category</IssueLabelText>
              <IssueValueFlex column>
                <SearchSelect
                  options={categoryData ? categoryData.issueCategory : []}
                  value={values.category}
                  onChange={(ele: any) => setFieldValue(`category`, ele)}
                  onBlur={() => setFieldTouched('category', true)}
                  placeholder={'Select Category'}
                />
                <ErrorMessage component={CustomErrorMessage} name="category" />
              </IssueValueFlex>
            </IssueContainerFlex>
            <IssueContainerFlex>
              <IssueLabelText>Comment </IssueLabelText>
              <IssueValueFlex column>
                <CommentForm
                  baseUrl={baseUrl}
                  config={config}
                  createIssue
                  files={files}
                  onChangeFiles={setFiles}
                  defaultComment={comment}
                  setIssueComment={comment => {
                    setFieldValue('comment', comment)
                    setComment(comment)
                  }}
                />
                <ErrorMessage component={CustomErrorMessage} name="comment" />
              </IssueValueFlex>
            </IssueContainerFlex>
            <Flex style={{ marginTop: '20px' }} justifyContentFlexEnd>
              <FilePicker
                icon={<FaPaperclip size={18} />}
                style={{
                  containerStyle: {
                    padding: '5px 15px'
                  }
                }}
                multiple
                accept="image/jpeg, image/png, .doc, .docx, .pdf"
                onError={(err: any) => {
                  if (err && !isEmpty(err)) {
                    console.error('Oops! Unable to upload')
                  }
                }}
                onFileDrop={(newFiles: any) =>
                  setFiles([...files, ...newFiles])
                }
              />
              <Button
                style={{ marginRight: '10px' }}
                type="Submit"
                disabled={!isValid && !showSubmitLoader}
                isLoading={showSubmitLoader}
                variant="success"
              >
                Submit
              </Button>
              <Button
                type="button"
                variant="danger"
                onClick={() => {
                  resetForm({ values: resetFormValues })
                  setFieldValue('comment', '')
                  setComment('')
                  setDefaultIssueForm && setDefaultIssueForm(resetFormValues)
                  setShowForm(false)
                }}
              >
                Cancel
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Card>
  )
}
export default IssueForm
