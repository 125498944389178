import React from 'react'
import styled from 'styled-components'
import { Button } from './index'
import { Props, layout } from '../../Theme/styledSystem'
import { IGroupedButtonProps } from '../../utility/types'

const GroupedButtonDiv = styled.div`
  ${layout}
  display: flex;
  margin: 0;
  padding: 0;
  background-color: white;
  color: black;
`
const GroupedButton = (props: IGroupedButtonProps) => {
  let { content, variant } = Props(props)
  return (
    <GroupedButtonDiv>
      {content.map((item: any, key: number) => (
        <Button
          variant={variant}
          style={{
            margin: 0,
            borderWidth: '1px',
            borderRadius:
              key === 0
                ? '5px 0px 0px 5px'
                : key === content.length - 1
                ? '0px 5px 5px 0px'
                : '0'
          }}
          onClick={() => item.onclick(item.data)}
          key={key}
        >
          {item.label}
        </Button>
      ))}
    </GroupedButtonDiv>
  )
}
export default GroupedButton
