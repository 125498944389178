import React from 'react'
import { RenderControl, IRenderControl } from '@fareye/ui'
import { GraphRenderer, IApiChartConfig } from '@fareye/kpi'
import { IDateRange } from '@fareye/types'
import { getQueryParams, get, cancelToken, post, isEmpty } from '@fareye/utils'
import { GRAPH_DATA_MAPPING, KPI_COLORS } from '../../config/constants'
interface IKpiGraph {
  dateRange: IDateRange<Date>
  baseUrl: string
  selectedGraph: string
}

const queryParams = {
  type: 'JsonStore::Ui',
  sub_type: 'multiModalKpi'
}

export default function KpiGraph({
  dateRange,
  baseUrl,
  selectedGraph
}: IKpiGraph) {
  const [graphConfig, setGraphConfig] = React.useState<IRenderControl>({
    data: null,
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
  })
  const dataConfig = {
    getDataForChart: getDataForChartFromServer,
    graphDataMapping: GRAPH_DATA_MAPPING
  }

  const source = cancelToken().source()

  React.useEffect(() => {
    fetchKpiConfig()
    return () => source.cancel('')
  }, [selectedGraph])

  const getPageId = (type: string) => {
    if (type === 'Daily Dispatch') return 'dailyDispatchJourney'
    if (type === 'Unloading Performance') return 'unloadingPerformance'
    if (type === 'Loading Performance') return 'loadingPerformance'
    if (type === 'Transit Performance') return 'transitPerformance'
    if (type === 'Vendor Performance') return 'vendorPerformance'
    if (type === 'Trip State(At loading, at unloading point etc)')
      return 'tripState'

    return 'routePerformance'
  }

  async function fetchKpiConfig() {
    try {
      setGraphConfig({ ...graphConfig, isLoading: true })
      const pageId = getPageId(selectedGraph)
      const apiUrl = `${baseUrl}/api/v1/records?${getQueryParams({
        ...queryParams,
        page_id: pageId
      })}`
      const { data } = await get(apiUrl, source.token)
      const graphApiConfig = isEmpty(data.configs)
        ? data.samples[0].data
        : data.configs[0].data
      graphApiConfig.graphConfig.showLegends = true
      graphApiConfig.graphConfig.colors = { ...KPI_COLORS }
      const graphRendererConfig = {
        graphConfig: graphApiConfig.graphConfig,
        dataConfig: dataConfig,
        globalFilter: { dateRange },
        style: { graphScreenRatio: 3 }
      }

      setGraphConfig({
        ...graphConfig,
        data: graphRendererConfig,
        isSuccess: true,
        isLoading: false
      })
    } catch (ex) {
      setGraphConfig({
        ...graphConfig,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: 'Graph is not Configured...'
      })
    }
  }

  async function getDataForChartFromServer(
    value: Partial<IApiChartConfig>,
    globalFilter: any,
    isReport?: boolean
  ) {
    const { graphDataMapping } = dataConfig
    const {
      dataSource,
      metric,
      metricUnit,
      dimension,
      breakdownDimension: breakdownDimensionFromKpi,
      benchmarkDimension: benchmarkDimensionFromKpi,
      filter: filterFromKpi,
      sortKey,
      sortType,
      size
    } = value
    const { dateRange, selectedFilter, selectedKey }: any = globalFilter || {}
    let breakDownDimension, benchmarkDimension, sort, metricCustom
    if (breakdownDimensionFromKpi) {
      breakDownDimension = {
        label: breakdownDimensionFromKpi[graphDataMapping['dimension'].value]
      }
    }
    metricCustom = {
      key: metric.aggKey[graphDataMapping['metricKey'].value],
      type: metric.aggType[graphDataMapping['metricType'].value],
      unit: metricUnit ? metricUnit[graphDataMapping['unit'].value] : ''
    }
    if (benchmarkDimensionFromKpi) {
      benchmarkDimension = {
        ...metricCustom,
        target: benchmarkDimensionFromKpi.value
      }
    }

    sort = {
      order: sortType ? sortType[graphDataMapping['sortType'].value] : 'asc',
      sortable: sortKey ? 'metric' : 'dimension',
      size: size || 10
    }
    let filter = filterFromKpi
      ? filterFromKpi.map(filterList => {
          return filterList.map(ele => {
            return {
              filterKey: ele.filterKey[graphDataMapping['filterKey'].value],
              filterValue:
                ele.filterValue[graphDataMapping['filterValue'].value],
              condition: ele.condition[graphDataMapping['condition'].value],
              filterType: ele.filterType[graphDataMapping['filterType'].value]
            }
          })
        })
      : []
    filter.push([
      {
        filterKey: 'start_date',
        filterValue: dateRange ? dateRange.startDate : new Date(),
        //filterValue: '2019-09-10',
        condition: 'include',
        filterType: 'gte'
      }
    ])

    if (!isEmpty(selectedFilter)) {
      selectedFilter.forEach((ele: any) => {
        filter.push([
          {
            filterKey: ele.key,
            filterValue: ele.value,
            condition: 'include',
            filterType: 'gte'
          }
        ])
      })
    }
    if (selectedKey === 'active') {
      filter.push([
        {
          filterKey: 'is_active',
          filterValue: true,
          condition: 'include',
          filterType: 'eq'
        }
      ])
    }
    let query = {
      dataSource: dataSource[graphDataMapping['dataSource'].value],
      ...(benchmarkDimensionFromKpi ? undefined : { metric: metricCustom }),
      dimension: {
        label: dimension[graphDataMapping['dimension'].value]
      },
      ...(breakDownDimension && { breakDownDimension }),
      ...(benchmarkDimension && { benchmarkDimension }),
      sort,
      filter
    }

    try {
      const token = cancelToken().source().token
      const { data } = await post(
        `${baseUrl}/api/v1/es/kpi_${isReport ? 'results' : 'aggs'}`,
        query,
        token
      )

      return isReport ? data.results : data.aggs
    } catch (ex) {
      return []
    }
  }
  return (
    <>
      {!isEmpty(graphConfig.data) ? (
        <RenderControl variant="xxs" state={graphConfig}>
          <GraphRenderer
            {...graphConfig.data}
            style={{
              ...graphConfig.data.style,
              wrapper: {
                ...(graphConfig.data.style && graphConfig.data.style)
              }
            }}
          />
        </RenderControl>
      ) : null}
    </>
  )
}
