import React from 'react'
import AsyncReactSelect from 'react-select/async'
import { IAsyncSearchSelect } from '../../utility/types'

export default (props: IAsyncSearchSelect) => {
  let {
    label,
    variant,
    style = {},
    className = '',
    noBorder,
    separator = false
  } = props
  let boxShadow: any = null
  if (noBorder) {
    boxShadow = {
      border: '0px solid transparent',
      '&:hover': {
        borderColor: 'transparent'
      },
      '&:focus-within': {
        'box-shadow': '0 0 0 0px rgba(0,0,0,0)'
      }
    }
  }
  const styleVariant =
    variant && variant === 'fareyeV2'
      ? {
          control: (styles: any) => ({
            ...styles,
            ...{ minHeight: '20px' }
          }),
          placeholder: (styles: any) => ({
            ...styles,
            ...{ padding: '0 4px' }
          }),
          indicatorContainer: (styles: any) => ({
            ...styles,
            ...{ padding: '0 8px' }
          })
        }
      : {}

  const OtherProps = { ...props }
  delete OtherProps.className
  return (
    <div className={`feui-search-select ${className}`} style={style.wrapper}>
      {label && <label style={style.label}>{label}</label>}
      <AsyncReactSelect
        classNamePrefix="fe-ui"
        styles={{
          ...{
            container: base => ({
              ...base,
              border: 0,
              width: '100%',
              backgroundColor: 'transparent',
              ...style.container
            }),
            control: styles => ({
              ...styles,
              width: '100%',
              display: 'flex',
              position: 'relative',
              cursor: 'pointer',
              gridTemplateColumns: '1fr 1fr',
              transition: 'all .3s cubic-bezier(.645, .045, .355, 1)',
              boxSizing: 'border-box',
              background: 'transparent',
              borderTopWidth: '1.02px',
              borderRadius: '4px',
              outline: 'none',
              userSelect: 'none',
              '&:focusWithin': {
                borderColor: 'transparent',
                borderWidth: '1px !important',
                outline: 0
              },
              ...boxShadow,
              ...style.control
            }),
            menu: styles => ({
              ...styles,
              width: 'auto',
              minWidth: '100%',
              zIndex: 99,
              ...style.menu
            }),
            indicatorSeparator: () => ({
              ...(separator ? {} : { display: 'none' }),
              ...style.indicatorSeparator
            }),
            option: (provided, state) => ({
              ...provided,
              color: 'rgba(151,151,151,1)',
              padding: '5px 10px',
              maxHeight: '250px',
              outline: 'none',
              ...style.option
            }),
            singleValue: (styles, { data }) => ({
              ...styles,
              color: 'rgba(151,151,151,1)',
              ...style.singleValue
            }),
            placeholder: styles => ({
              ...styles,
              display: 'flex',
              padding: '0 4px',
              color: 'rgba(151,151,151,1)',
              ...style.placeholder
            }),
            input: styles => ({
              ...styles,
              color: 'rgba(151,151,151,1)',
              ...style.input
            }),
            valueContainer: (styles, state) => ({
              ...styles,
              background: 'transparent',
              // height: '35px',
              overflow: 'auto',
              ...style.valueContainer
            }),
            dropdownIndicator: (styles, state) => ({
              ...styles,
              color: 'rgba(207,207,207,1)',
              transition: '0.3s',
              transform: 'rotate(180deg)',
              ...style.dropdownIndicator
            })
          },
          ...styleVariant
        }}
        {...OtherProps}
      />
    </div>
  )
}
