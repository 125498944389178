import { AUTH_TOKEN, ENVIRONMENT } from '../config/constants'

//Get Authentiacation Token
export const getToken = () => {
  let token = JSON.parse(
    window.localStorage.getItem(AUTH_TOKEN) || JSON.stringify({ token: '' })
  ).token
  if (token) {
    return `Bearer ${token}`
  }
  return null
}

export function getEnvironmentFromLocalStorage() {
  const environmentData = localStorage.getItem(ENVIRONMENT)
  let environment = null
  if (environmentData) {
    environment = JSON.parse(environmentData)
  }
  return environment
}

export function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function isAccessCookieExist(cookies = {}) {
  let keys = Object.keys(cookies)
  let isPresent = keys.some(ele => ele.includes('access_token'))
  return isPresent
}

export function changeEnv(apiUrl = 'https://transportation-test.fareye.co') {
  let url = new URL(apiUrl)
  let urls: any = localStorage.getItem('environment')
  if (urls) {
    urls = (urls && JSON.parse(urls)) || {}
    urls.postUrl = url.host
    let excludeKeys = ['mapKey', 'defaultBaseLayer', 'postUrl', 'production']
    Object.entries(urls).forEach(([key, _]) => {
      if (!excludeKeys.includes(key)) {
        urls[key] = apiUrl
      }
    })
    localStorage.setItem('environment', JSON.stringify(urls))
  } else {
    console.warn('Environment is not created in localstorage')
  }
}

export function getDate(day = 0) {
  let d = new Date()
  d.setDate(d.getDate() - day)
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
}

export function lastweek(days: number) {
  let today = new Date()
  let lastweek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - days
  ).toDateString()
  let arr = lastweek.split(' ')
  return `${arr[2]}  ${arr[1]}`
}
