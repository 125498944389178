import { BaseLayer } from '@fareye/ui'
import { ENVIRONMENT } from './config/constants'
import { getEnvironmentFromLocalStorage } from './utility/funcs'
import { TEnvironment } from './utility/types'

const production = {
  production: true,
  baseURL: 'https://transportation.fareye.co',
  mapKey: 'AIzaSyAfHNk2CTX44LJiSKEYy1jmYgSuMk8IABA',
  defaultBaseLayer: BaseLayer.GMROADS
}

const dev = {
  production: false,
  baseURL: 'https://tpt-qa2.fareye.co',
  mapKey: 'AIzaSyAfHNk2CTX44LJiSKEYy1jmYgSuMk8IABA',
  defaultBaseLayer: BaseLayer.OSMMAPNIK
}
// TODO delete after merged with TRN-91
const jsonStore = {
  production: false,
  baseURL: 'https://tpt-qa2.fareye.co',
  mapKey: 'AIzaSyAfHNk2CTX44LJiSKEYy1jmYgSuMk8IABA',
  defaultBaseLayer: BaseLayer.OSMMAPNIK
}
// TODO : TO BE CHANGED BEFORE MERGING INTO TRN-91
const staging = {
  production: false,
  baseURL: 'https://tpt-preprod.fareye.co',
  settings: 'https://tpt-preprod.fareye.co',
  alertSettings: 'https://tpt-preprod.fareye.co',
  poi: 'https://tpt-preprod.fareye.co',
  postUrl: 'tpt-preprod.fareye.co',
  addPoi: 'https://tpt-preprod.fareye.co',
  addPois: 'https://tpt-preprod.fareye.co',
  mapKey: 'AIzaSyAfHNk2CTX44LJiSKEYy1jmYgSuMk8IABA',
  defaultBaseLayer: BaseLayer.OSMMAPNIK
}

const demo = {
  production: false,
  baseURL: 'https://tpt-demo.fareye.co',
  mapKey: 'AIzaSyAfHNk2CTX44LJiSKEYy1jmYgSuMk8IABA',
  defaultBaseLayer: BaseLayer.OSMMAPNIK
}
const demo1 = {
  production: false,
  baseURL: 'https://track-demo-api.fareye.co',
  mapKey: 'AIzaSyAfHNk2CTX44LJiSKEYy1jmYgSuMk8IABA',
  defaultBaseLayer: BaseLayer.OSMMAPNIK
}
const demo2 = {
  production: false,
  baseURL: 'https://tpt-demo2.fareye.co',
  mapKey: 'AIzaSyAfHNk2CTX44LJiSKEYy1jmYgSuMk8IABA',
  defaultBaseLayer: BaseLayer.OSMMAPNIK
}
const timezone = {
  production: false,
  baseURL: 'https://tpt-staging1.fareye.co',
  mapKey: 'AIzaSyAfHNk2CTX44LJiSKEYy1jmYgSuMk8IABA',
  defaultBaseLayer: BaseLayer.OSMMAPNIK
}
const multiModal = {
  production: false,
  baseURL: 'https://tpt-staging1.fareye.co',
  mapKey: 'AIzaSyAfHNk2CTX44LJiSKEYy1jmYgSuMk8IABA',
  defaultBaseLayer: BaseLayer.OSMMAPNIK
}
const multiModalProd = {
  production: false,
  baseURL: 'https://tpt-staging2.fareye.co',
  mapKey: 'AIzaSyAfHNk2CTX44LJiSKEYy1jmYgSuMk8IABA',
  defaultBaseLayer: BaseLayer.OSMMAPNIK
}
let processEnv = process.env.REACT_APP_ENV as TEnvironment
let environment
switch (processEnv) {
  case 'staging':
    environment = staging
    break
  case 'production':
    environment = production
    break
  case 'jsonStore':
    environment = jsonStore
    break
  case 'dev':
    environment = dev
    break
  case 'demo':
    environment = demo
    break
  case 'demo1':
    environment = demo1
    break
  case 'demo2':
    environment = demo2
    break
  case 'multiModal':
    environment = multiModal
    break
  case 'timezone':
    environment = timezone
    break
  case 'multiModalProd':
    environment = multiModalProd
    break
  default:
    processEnv = 'dev'
    environment = demo1
}

// NOTE: Check Data exist in local storage or not only when we are in staging and dev mode.

if (!environment.production) {
  const environmentData = getEnvironmentFromLocalStorage()

  if (environmentData) {
    environment = environmentData
  } else {
    localStorage.setItem(ENVIRONMENT, JSON.stringify(environment))
  }
}
export default { ...environment, processEnv }
