import React from 'react'
import { Card, Flex, Button, usePrevious } from '@fareye/ui'
import { IFormInputConfig, IFormConfig, IFormBuilderState } from '..'
import GenerateRowElement from './GenerateRowElement'
import { FormikProps } from 'formik'
import Header from './Header'
import { isEqual } from '@fareye/utils'
import { differenceBetweenObjects } from '../utility/funcs'
import { Row } from '../styled/styled'

interface ICustomForm {
  formConfig: IFormBuilderState
  baseUrl: string
  config: IFormConfig
  formikProps: FormikProps<any>
  formGroupName: string
  isLoading: boolean | undefined
  onFieldChange?: (val: any) => void
  onSpecificKeyChange?: (val: any) => void
  buttonComponent?: React.ReactElement<any>
  disableSubmit: boolean
}
export default function CustomForm({
  formConfig,
  baseUrl,
  config,
  formikProps,
  formGroupName,
  isLoading,
  onFieldChange,
  onSpecificKeyChange,
  buttonComponent,
  disableSubmit
}: ICustomForm) {
  const { btnConfig } = config
  const previousState = usePrevious(formikProps.values)
  React.useEffect(() => {
    onFieldChange && onFieldChange(formikProps)
    if (!isEqual(previousState, formikProps.values)) {
      const diffState = differenceBetweenObjects(
        formikProps.values,
        previousState
      )
      onSpecificKeyChange && onSpecificKeyChange({ diffState, formikProps })
    }
  }, [formikProps.values])

  return (
    <Card variant="s">
      <Header {...config} />
      {Object.keys(formConfig.rowColumnConfig).map(
        (ele: string, index: number) => {
          return (
            <Row
              key={`row${index}`}
              style={formConfig.rowColumnConfig[ele].style}
            >
              {formConfig.rowColumnConfig[ele].data.map(
                (rowElement: IFormInputConfig, secondIndex: number) => (
                  <GenerateRowElement
                    key={`column${index}${secondIndex}`}
                    rowElement={rowElement}
                    formikProps={formikProps}
                    count={formConfig.rowColumnConfig[ele].data.length}
                    formGroup={formGroupName}
                    styles={rowElement.styles ? rowElement.styles : {}}
                    elePosition={secondIndex}
                    baseUrl={baseUrl}
                  />
                )
              )}
            </Row>
          )
        }
      )}
      {
        <Flex justifyContentFlexEnd>
          {buttonComponent}
          <Button
            disabled={!formikProps.isValid || disableSubmit}
            variant="m primary"
            onClick={formikProps.handleSubmit}
            type="submit"
            style={
              btnConfig
                ? {
                    ...btnConfig.submitButton.style,
                    marginRight: '20px',
                    display: btnConfig.submitButton.isDisplay ? '' : 'none'
                  }
                : { marginRight: '20px' }
            }
            isLoading={isLoading}
          >
            {btnConfig ? btnConfig.submitButton.label : 'Submit'}
          </Button>
          <Button
            variant="m danger"
            onClick={formikProps.handleReset}
            type="button"
            style={
              btnConfig
                ? {
                    ...btnConfig.resetButton.style,
                    marginRight: '20px',
                    display: btnConfig.resetButton.isDisplay ? '' : 'none'
                  }
                : { marginRight: '20px' }
            }
          >
            {btnConfig ? btnConfig.resetButton.label : 'Reset'}
          </Button>
        </Flex>
      }
    </Card>
  )
}
