import { Flex, Span } from '@fareye/ui'
import {
  FaCalendar,
  FaFileImage,
  FaFilePdf,
  hhmmAdoMMMNoIST
} from '@fareye/utils'
import React from 'react'
import Linkify from 'react-linkify'
import { CreatedAtStyle, CommentContainer } from '../../../../style/styled'
import FilesListingDisplay from '../FilesListingDisplay'

function CommentBody({ comment }: any) {
  if (!comment) return <div></div>
  let taggedEmails = comment.match(
    /(@)(\[)([\w-+]+(?:\.[\w-+]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})(\])(\()((?:[a-z][a-z]+))(:)(\d+)(\))/g
  )
  let newComment = comment
  if (taggedEmails) {
    taggedEmails.forEach((taggedEmail: any, index: number) => {
      const extractedEmail = taggedEmail.match(
        /([\w-+]+(?:\.[\w-+]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})/g
      )[0]
      newComment = newComment.replace(taggedEmail, extractedEmail)
    })
  }

  return (
    <Span mb={2}>
      <Linkify>{newComment}</Linkify>
    </Span>
  )
}

export default function Comment({ data }: any) {
  const { creator, created_at, body } = data
  return (
    <CommentContainer column>
      <Flex justifyContentSpaceBetween>
        <CreatedAtStyle style={{ textTransform: 'uppercase' }}>
          {creator.full_name || creator.first_name}
        </CreatedAtStyle>
        <CreatedAtStyle>
          <FaCalendar size={12} color="#d2d2d2" />{' '}
          {hhmmAdoMMMNoIST(new Date(created_at))}
        </CreatedAtStyle>
      </Flex>
      <CommentBody comment={body} />
      <FilesListingDisplay attachments={data?.attachments || []} />
    </CommentContainer>
  )
}
