import {
  IFormInputConfig,
  OptionalRecord,
  CSelect,
  CInput,
  CConditional,
  CDate,
  CMultiAdd
} from '../utility/types'
import { FormikProps, Field } from 'formik'
import React from 'react'
import Select from './Select'
import Input from './Input'
import DateRange from './Date'
import MultiRow from './MultiRow'

interface IGenerateRowElement {
  rowElement: IFormInputConfig
  formikProps: FormikProps<any>
  count: number
  formGroup: string
  styles: OptionalRecord
  elePosition: number
  baseUrl: string
  formKeyName?: string
}
function GenerateRowElement({
  rowElement: val,
  formikProps,
  count,
  formGroup,
  styles,
  elePosition,
  baseUrl,
  formKeyName
}: IGenerateRowElement) {
  let { formKey } = val
  if (formKeyName) {
    formKey = formKeyName
  }
  if (CSelect.includes(val.type)) {
    return (
      <Field
        name={formKey}
        onChange={formikProps.handleChange}
        as={Select}
        config={val}
        value={formikProps.values[formKey]}
        type={val.type}
        formKeyName={formKey}
        {...formikProps}
        count={count}
        position={elePosition}
        baseUrl={baseUrl}
        key={`${formKey}`}
      />
    )
  } else if (CInput.includes(val.type)) {
    return (
      <Field
        name={formKey}
        onChange={formikProps.handleChange}
        as={Input}
        config={val}
        value={formikProps.values[formKey]}
        type={val.type}
        count={count}
        position={elePosition}
        baseUrl={baseUrl}
        style={{
          flexBasis: `${Math.floor(100 / count)}%`,
          boxSizing: 'border-box',
          paddingRight: 35
          //   paddingTop: '2px',
          //   height: 'auto',
          //   boxShadow: 'none',
          //   border: '1px solid #b3b3b3'
        }}
        key={`${formKey}`}
      />
    )
  } else if (CConditional.includes(val.type)) {
    return (
      <Field
        name={formKey}
        onChange={formikProps.handleChange}
        as={Input}
        config={val}
        count={count}
        baseUrl={baseUrl}
        value={formikProps.values[formKey]}
        type={val.type}
        position={elePosition}
        style={{
          flexBasis: `${Math.floor(100 / count)}%`,
          boxSizing: 'border-box',
          margin: '12px'
        }}
        key={`${formKey}`}
      />
    )
  } else if (CDate.includes(val.type)) {
    return (
      <Field
        name={formKey}
        onChange={formikProps.handleChange}
        as={DateRange}
        config={val}
        count={count}
        baseUrl={baseUrl}
        value={formikProps.values[formKey]}
        type={val.type}
        {...formikProps}
        position={elePosition}
        formKeyName={formKey}
        style={{
          flexBasis: `${Math.floor(100 / count)}%`,
          boxSizing: 'border-box',
          paddingRight: 35
        }}
        key={`${formKey}`}
      />
    )
  } else if (CMultiAdd.includes(val.type)) {
    return (
      <MultiRow
        config={val}
        formikProps={formikProps}
        values={formikProps.values}
        position={elePosition}
        count={count}
        baseUrl={baseUrl}
        type={val.type}
        formGroup={formGroup}
        key={`${formKey}`}
      />
    )
  }
  return null
}
export default GenerateRowElement
