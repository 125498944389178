import styled from 'styled-components'
import { FaSync } from '@fareye/utils'
import { Flex } from '../Layout'

export const TableStyle: any = styled.div`
  width: 100%;
  table {
    table-layout: fixed;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #ececec;
    border-collapse: separate;
    border-spacing: 0;
    tr {
      background: #fff;
    }
    th,
    td {
      box-sizing: border-box;
      font-family: 'Roboto';
      margin: 0;
      font-size: ${(props: any) => {
        switch (props.fontSize) {
          case 'xxs':
            return '9px'
          case 'xs':
            return '10px'
          case 's':
            return '11px'
          case 'm':
            return '12px'
          case 'l':
            return '13px'
          case 'xl':
            return '14px'
          case 'xxl':
            return '15px'
        }
      }};
      padding: ${(props: any) => {
        switch (props.size) {
          case 'xxs':
            return '0'
          case 'xs':
            return '4px'
          case 's':
            return '6px'
          case 'm':
            return '8px'
          case 'l':
            return '10px'
          case 'xl':
            return '12px'
          case 'xxl':
            return '14px'
        }
      }};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #f0f0f0;
      border-right: 1px solid #ececec;
    }
    tr.sub-comp {
      td {
        padding: 0;
      }
    }
    th {
      position: sticky;
      top: 0;
      text-transform: uppercase;
      background: #eff6ff;
      color: #727272;
      font-weight: 300;
      letter-spacing: 0.4px;
      .resizer {
        width: 10px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
      }
    }
    tr:hover {
      background: #f8fbff;
      transition: background 0.3s ease;
    }
  }
  &.striped {
    tr:nth-child(even) {
      background: #eff6ff;
    }
  }
  &.responsive {
    .responsive-table {
      overflow: auto;
      table {
        width: calc(100% - 6px);
      }
    }
  }
`

export const EmptyTable = styled.tr`
  div {
    padding: 50px;
    color: #bfbfbf;
    font-size: 14px;
    font-weight: 300;
  }
  svg {
    height: 50px;
    width: 60px;
    color: #e7e7e7;
  }
`

/* =================== Pagination =================== */
export const PaginationActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Option = styled.span`
  font-size: 12px;
  border-bottom: 1px solid ${(props: any) => props.theme.colors.gray3};
  padding: 5px 10px;
  &:hover,
  &.active {
    background: ${(props: any) => props.theme.colors.blue4};
    transition: 0.6s ease;
  }
  &.active {
    font-weight: 600;
  }
  &:first-child {
    border-radius: 4px 4px 0 0;
  }
  &:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: 0px solid transparent;
  }
`

export const PageList = styled.ul`
  display: flex;
  user-select: none;
  list-style: none;
  li {
    margin: 0 4px;
    border-radius: 2px;
    border: 1px solid #e0e0e0;
    padding: 5px 9px;
    &.active {
      border-color: ${(props: any) => props.theme.primary['background-color']};
      color: ${(props: any) => props.theme.primary['background-color']};
    }
  }
  .double-angle {
    display: none;
    color: ${(props: any) => props.theme.primary['background-color']};
  }
  .bring-more:hover {
    .double-angle {
      display: block;
    }
    .ellipsis {
      display: none;
    }
  }
`

export const SelectPageSize = styled.div`
  font-size: 12px;
  display: block;
  border: 1px solid #ececec;
  padding: 7px;
  width: 70px;
  margin-left: 16px;
  border-radius: 2px;
  text-align: center;
`
/* =================== Pagination =================== */

/* =================== Refresh  =================== */
export const RefreshS: any = styled.span`
  cursor: pointer;
  color: ${(props: any) => (props.refreshing ? '#727272' : 'inherit')};
  margin-right: 10px;
  font-size: 12px;
  .icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
  @media (max-width: 768px) {
    font-size: 11px;
    margin-right: 6px;
    .icon {
      width: 8px;
      height: 8px;
      margin-right: 6px;
    }
    .refresh-text {
      display: none;
    }
  }
`
export const RefreshRotating = styled(FaSync)`
  margin-right: 5px;
  animation: 1s spinner linear infinite;
  transform-origin: 50% 50%;
  @keyframes spinner {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
`
/* =================== Refresh  =================== */
/* =================== Table Actions  =================== */
export const TableAction = styled(Flex)`
  padding-bottom: 6px;
  .hide-mobile {
    margin-left: 3px;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .issue-raised-text {
    @media (max-width: 768px) {
      display: none;
    }
  }
`
/* =================== Table Actions  =================== */
