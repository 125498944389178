import { IRenderControl } from '@fareye/ui'
import {
  axiosInstance,
  cancelToken,
  get,
  getuser,
  getUserType,
  getQueryParams,
  HEADERS,
  isEmpty,
  validURL
} from '@fareye/utils'
import React from 'react'
import { TIMEZONE_KEY, TIME_FMT_24_HRS_KEY } from '../config/constants'
import { getTimezone } from '../utility'
import { IMapper } from '../utility/types'

export default function useMapper(props: IMapper) {
  const {
    pageConfigKey = '',
    authToken,
    baseUrl,
    type,
    subType = '',
    timezone,
    version,
    versions,
    timeFmt24Hr,
    selectedLanguage
  } = props
  const source = cancelToken().source()
  const [apiData, setApiData] = React.useState<IRenderControl>({
    data: [],
    isLoading: false,
    isError: false,
    isSuccess: true,
    message: ''
  })

  function setStorage(timezone: any) {
    const { timeZoneValue = '', localScanTime = false } = timezone || {}
    const localData = JSON.parse(localStorage.getItem(TIMEZONE_KEY) || '{}')
    let data = {
      ...localData,
      id: localScanTime ? 'local' : 'others',
      value: localScanTime ? '' : timeZoneValue,
      text: localScanTime ? 'Local Scan Time' : timeZoneValue,
      displayText: localScanTime ? 'Local Scan Time' : timeZoneValue
    }
    if (timezone) {
      localStorage.setItem(TIMEZONE_KEY, JSON.stringify(data))
    }
  }

  function registerInterceptor() {
    return axiosInstance.interceptors.request.use(
      function(config) {
        config.headers['Accept'] = 'application/json'
        config.headers['Content-Type'] = 'application/json'
        const token = `Bearer ${authToken}`
        config.headers['Authorization'] = token
        if (timezone) {
          config.headers['X-Fe-Timezone'] = getTimezone()
        }
        if (!validURL(config.url)) {
          config.url = baseUrl + config.url
        }
        if (timeFmt24Hr) {
          config.headers['X-Fe-TimeFmt24Hrs'] = timeFmt24Hr
        }
        if (selectedLanguage) {
          config.headers['X-Fe-Language'] = selectedLanguage
        }
        return config
      },
      function(error) {
        // Do something with request error
        return Promise.reject(error)
      }
    )
  }

  async function fetchConfig() {
    try {
      setApiData({ ...apiData, isLoading: true })
      const JSON_STORE_PATH_PARAM = '/api/v1/records'
      const queryParams = {
        type: type ? type : 'JsonStore::Ui',
        sub_type: subType,
        // npm_version: version,
        page_id: pageConfigKey,
        user_role: getUserType(),
        user: getuser().value
      }
      const queryString = getQueryParams(queryParams)
      const apiUrl = `${baseUrl}${JSON_STORE_PATH_PARAM}?${queryString}`
      const { data } = await get(apiUrl, source.token)
      const config = !isEmpty(data.configs)
        ? data.configs[0].data.config
        : data.samples[0].data.config
      setApiData({
        ...apiData,
        data: config,
        isLoading: false,
        message: ''
      })
    } catch (ex) {
      setApiData({
        ...apiData,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: 'Please Configure this Account...'
      })
    }
  }

  React.useLayoutEffect(() => {
    const axiosInterceptor = registerInterceptor()
    return () => {
      axiosInstance.interceptors.request.eject(axiosInterceptor)
    }
  }, [])

  React.useEffect(() => {
    setStorage(timezone)
  }, [timezone])

  React.useEffect(() => {
    if (timeFmt24Hr) {
      localStorage.setItem(TIME_FMT_24_HRS_KEY, timeFmt24Hr?.toString())
    } else {
      localStorage.setItem(TIME_FMT_24_HRS_KEY, 'false')
    }
  }, [timeFmt24Hr])

  React.useEffect(() => {
    fetchConfig()
  }, [pageConfigKey])

  return [apiData, setApiData] as [IRenderControl, (val: any) => any]
}
