import { P, Flex, RenderControl, IRenderControl } from '@fareye/ui'
import { cancelToken, get, post, responseParser } from '@fareye/utils'
import React from 'react'
import { CommentText } from '../../../../style/styled'
import { IIssueListingApiConfig } from '../../../../utility/types'
import Comment from './Comment'
import CommentForm from './CommentForm'

const source = cancelToken().source()
export interface ICommentsContainer {
  issueId: number
  threadId: number
  count: number
  baseUrl: string
  config: IIssueListingApiConfig
}
const initialState = {
  data: [],
  isLoading: false,
  isSuccess: true,
  isError: false,
  isMessage: ''
}

export default function CommentsContainer({
  issueId,
  threadId,
  count,
  baseUrl,
  config
}: ICommentsContainer) {
  const initialState = {
    data: { count, commentData: [] },
    isLoading: false,
    isSuccess: true,
    isError: false,
    isMessage: ''
  }
  const [comments, setComments] = React.useState<IRenderControl>(initialState)
  const [showComments, setShowComments] = React.useState(false)
  const [files, setFiles] = React.useState<any>([])
  const { showComments: showCommentsApiUrl } = config.children

  async function getComments() {
    setComments({
      ...comments,
      isLoading: true
    })
    const { apiURL } = showCommentsApiUrl.config.apiConfig
    try {
      const commentsUrl = `${baseUrl}/${apiURL}?id=${issueId}`
      const {
        data: { data }
      } = await get(commentsUrl, cancelToken().source().token)
      if (data) {
        setComments({
          ...comments,
          isLoading: false,
          isSuccess: true,
          message: '',
          isError: false,
          data: {
            commentData: data.comments,
            count: data.comments.length
          }
        })
      }
    } catch (error) {
      setComments({
        ...comments,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: 'Failed to load messages...'
      })
      console.error(error)
    }
  }
  function updateComments(newComment: any) {
    setComments({
      ...comments,
      data: {
        commentData: [...comments.data.commentData, newComment],
        count: comments.data.count + 1
      }
    })
  }
  async function filesUpload(comment: any) {
    const filePayload: any = new FormData()
    filePayload.append('id', comment.id)
    if (files?.length) {
      files.forEach((file: any, index: number) => {
        filePayload.append('attachment[]', files[index])
      })
    }
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    try {
      const { status, data } = await post(
        '/api/v1/commontator/upload_attachment',
        filePayload,
        source.token,
        headers
      )
      setFiles([])
      updateComments({
        ...comment,
        attachments: data.attachments
      })
    } catch (e) {
      setFiles([])
      console.error('App Error uploading image::', e)
    }
  }

  React.useEffect(() => {
    if (showComments) {
      getComments()
    }
  }, [showComments])
  const commentCount = comments.data.count
  const textToshow = commentCount > 0 ? 'Show Comments' : 'Add Comment'
  const countText = commentCount ? `(${commentCount})` : ''

  return (
    <div>
      <P onClick={() => setShowComments(!showComments)}>
        {!showComments ? (
          <CommentText>{`${textToshow}${countText}`}</CommentText>
        ) : (
          <CommentText>Hide Comments</CommentText>
        )}
      </P>
      {showComments && (
        <Flex column>
          <RenderControl state={comments} style={{ marginTop: '1em' }}>
            {comments.data.commentData.map((comment: any) => (
              <Comment
                data={comment}
                key={`${issueId}_comment_${comment.id}`}
              />
            ))}
          </RenderControl>
          <CommentForm
            threadId={threadId}
            files={files}
            filesUpload={filesUpload}
            onChangeFiles={setFiles}
            baseUrl={baseUrl}
            config={config}
          />
        </Flex>
      )}
    </div>
  )
}
