import { isDate } from 'date-fns'
import queryString from 'query-string'
import { yyyyMmDd, formatMs, tz } from './dateTime'
import { toTileCase } from './strings'

import {
  SelectFilterOptions,
  ExceptionTypeAndCount,
  TripGroupByDurationWiseData,
  TripGroupByDurationWiseDataItem,
  Coords
} from '../types'
import { cancelToken, get } from '../Axios'
import { startCase } from './lodash'

export function getQueryParams(params: any, timezoneFlag?: boolean): string {
  if (params) {
    let q: string
    const qKeys = Object.keys(params)
    const qValues = Object.values(params)
    const dateKeys = qValues
      .map((val: any, i: number) => (isDate(val) ? qKeys[i] : null))
      .filter((x: any) => x)
    dateKeys.forEach((key: any) => {
      params[key] = timezoneFlag
        ? tz(params[key]).toISOString()
        : yyyyMmDd(new Date(params[key]))
    })
    if (typeof params.isActive === 'string') {
      params.isActive = params.isActive === 'true'
    }
    q = purifySearchParams(params)
    return q
  }
  return ''
}

export const purifySearchParams = (obj: any): string =>
  queryString.stringify(removeNullValueKeys(obj), { arrayFormat: 'bracket' })

export const removeNullValueKeys = (obj: any) => {
  Object.entries(obj).forEach((o: any) =>
    o[1] === null || o[1] === '' ? delete obj[o[0]] : 0
  )
  return obj
}

export const generateSelectOption = (
  option: string,
  capitalize?: boolean
): SelectFilterOptions => ({
  label: capitalize ? option.toUpperCase() : toTileCase(option),
  value: option
})

export const generateSelectOptionForObject = (
  option: any
): SelectFilterOptions => ({
  label: option.route,
  value: option.route,
  data: option
})

export function getColorAfterComparing(
  actualWidthNum: number,
  targetWidthNum: number
) {
  let tatActualColor = { block: '#F5A623', text: 'rgb(187, 117, 0)' }
  if (actualWidthNum > 1.1 * targetWidthNum) {
    tatActualColor = { block: '#F64B4B', text: '#ad1f1f' }
  } else if (actualWidthNum < 0.9 * targetWidthNum) {
    tatActualColor = { block: '#2FBA7E', text: '#326d54' }
  } else {
    tatActualColor = { block: '#F5A623', text: 'rgb(187, 117, 0)' }
  }
  return tatActualColor
}

export const removeEmpty = (obj: any) =>
  Object.entries(obj).forEach(([key, val]: any) => {
    if (val && typeof val === 'object') {
      removeEmpty(val)
    } else if (val == null) {
      delete obj[key]
    }
  })

export function modifyResponseForFilters(keyListForKeyType: any) {
  return Object.entries(keyListForKeyType).reduce(
    (masterData: any, [key, val]: any) => {
      masterData[key] = val.map((option: any) =>
        typeof option === 'string'
          ? generateSelectOption(option)
          : generateSelectOptionForObject(option)
      )
      return masterData
    },
    {}
  )
}

export function isBase64(str: string): boolean {
  return /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(
    str
  )
}

export const generateVehicleSelectOption = (
  option: string
): SelectFilterOptions => ({
  label: option.toUpperCase(),
  value: option.toUpperCase()
})

export const removeAlertSubText = (
  str: string,
  remove: string = 'AlertSubscription'
) => startCase(str.split(remove)[0])

export const removeAlertSubTextWithoutSpace = (
  str: string,
  remove: string = 'AlertSubscription'
) => str.split(remove)[0]

export function generateDataForBarChart(data: any) {
  const label = startCase(data.process)
    .split(' ')
    .reverse()
  label.splice(1, 0, 'TAT', '-')
  return {
    label: label.join(' '),
    actualTat: formatMs(Math.trunc(data.actualProcessTat / 60000) * 60000),
    targetTat: formatMs(Math.trunc(data.expectedProcessTat / 60000) * 60000),
    actualProcessTat: data.actualProcessTat,
    expectedProcessTat: data.expectedProcessTat,
    exceptionsCases: data.exceptions,
    chartData: Object.entries(data.allProcessStatus).map(
      // TODO: Remove this any
      ([label, value]: any) => ({
        label: startCase((value as any).subProcess),
        value: Number(
          ((value as any).actualProcessTat / (1000 * 60)).toFixed(2)
        ),
        targetValue: Number(
          ((value as any).expectedProcessTat / (1000 * 60)).toFixed(2)
        ),
        yLabel: formatMs(Math.trunc((value as any).actualProcessTat)),
        target: Math.floor(
          Math.trunc((value as any).expectedProcessTat) / (1000 * 60)
        )
      })
    )
  }
}

export function getEscalationColor(count: number) {
  if (count >= 3) {
    return '#D32B2B'
  } else if (count >= 1 && count < 3) {
    return '#FD6F4C'
  }
  return '#D7D7E4'
}

export function generateDataForExceptionAndOnTimeDelivery(
  data: TripGroupByDurationWiseData[]
) {
  let count = 0
  const exceptionsBarData: object[] = []
  const onTimeGraphData: object[] = []
  const exceptionsCount: any = {}
  const colors = ['#FF7326', '#C73030', '#F03B3B', '#7B353E']

  /*
    FIXME:
    Reduce n3 to possibly m + n2
    Final result should be {label: ABC, hover: [{label: BlahBlahAlert, count: 10}]}

  */
  data.forEach((element: TripGroupByDurationWiseData) => {
    element.data.forEach((deliveryData: TripGroupByDurationWiseDataItem) => {
      const onTimeObj: any = {}
      // FIXME: How will it handle day, week, month, year ? Use a function param
      onTimeObj.label = new Date(element.date).getDate()
      onTimeObj.value0 = deliveryData.avgOnTimeDeliveryScore
      onTimeGraphData.push(onTimeObj)
      const exceptionObj: any = {}
      exceptionObj.label = new Date(element.date).getDate()
      deliveryData.exceptions.forEach(
        (exception: ExceptionTypeAndCount, index: number) => {
          if (!exceptionsCount[exception.type]) {
            exceptionsCount[exception.type] = {}
            exceptionsCount[exception.type].index = count
            exceptionsCount[exception.type].color = colors[count]
            count++
          }
          exceptionObj[`value${exceptionsCount[exception.type].index}`] =
            exception.count
          exceptionObj[`type${exceptionsCount[exception.type].index}`] =
            exception.type
        }
      )
      exceptionsBarData.push(exceptionObj)
    })
  })
  return { exceptionsBarData, onTimeGraphData, exceptionsCount }
}
const makeRequestCreator = () => {
  let cancel: any

  return async (query: string) => {
    if (cancel) {
      // Cancel the previous request before making a new request
      cancel.cancel()
    }
    // Create a new CancelToken
    cancel = cancelToken().source()
    try {
      const res = await get(query, cancel.token)

      const result = res.data
      // Store response

      return result
    } catch (error) {
      console.error('Error is')
    }
  }
}

export const search = makeRequestCreator()

export const uniqueLatLngArray = (latLngArray?: Array<number[] | string[]>) => {
  // example Input = [[22.45, 45.77], [22.45, 45.77], [26.43, 43.787]]
  // expected output = [[22.45, 45.77], [26.43, 43.787]]
  if (latLngArray && latLngArray.length) {
    // const latLngType = typeof latLngArray[0][0] // uncomment for type string number based parsing
    const stringifiedLatLngs = latLngArray.join(',')
    const newPairs = []
    const allLatLng: string[] | number[] = stringifiedLatLngs.split(',')
    for (let i = 0; i < allLatLng.length / 2; i++) {
      newPairs.push(`${allLatLng[i * 2]},${allLatLng[i * 2 + 1]}`)
    }

    const uniqueLatLngs = Array.from(new Set(newPairs))
    const separatedLatLngs = uniqueLatLngs.map(item => item.split(','))
    // uncomment for type string number based parsing
    // const trimNCastType = separatedLatLngs.map(item =>
    //   item.map(latLng => {
    //     if (latLngType === 'number') {
    //       return parseFloat(latLng.trim())
    //     }
    //     return latLng.trim()
    //   })
    // )
    const trimNCastType = separatedLatLngs.map(item =>
      item.map(latLng => parseFloat(latLng.trim()))
    )
    return trimNCastType
  }
  return undefined
}

export function fillterObject(Obj: any, allowedList: string[]) {
  const filtered: any = {}
  allowedList.map((key: string) => {
    filtered[key] = Obj[key]
  })
  return filtered
}

export function parseJwt(token: string) {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}
