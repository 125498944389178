import ReactHtmlParser from 'react-html-parser'
import { Parser } from 'json2csv'
import FileSaver from 'file-saver'
import { IDownloadToCSV } from '../types'

interface RGBProps {
  r: number
  g: number
  b: number
}

export const HTMLentities = (substring: any) => {
  substring = substring.replace(/&quot;/g, '"')
  substring = substring.replace(/&amp;/g, '&') // 38
  substring = substring.replace(/&#39;/g, "'") // 39
  substring = substring.replace(/&lt;/g, '<') // 60
  substring = substring.replace(/&gt;/g, '>') // 62
  substring = substring.replace(/&tilde;/g, '~') // 126
  substring = substring.replace(/&circ;/g, '^') // 710
  substring = substring.replace(/&ndash;/g, '–') // 8211
  substring = substring.replace(/&mdash;/g, '—') // 8212
  substring = substring.replace(/&lsquo;/g, '‘') // 8216
  substring = substring.replace(/&rsquo;/g, '’') // 8217
  substring = substring.replace(/&ldquo;/g, '“') // 8220
  substring = substring.replace(/&rdquo;/g, '”') // 8221
  substring = substring.replace(/&bull;/g, '•') // 8226
  return substring
}

export const cssPropertyToJsx = (h: string) => {
  h = h.replace(/[.]\D/g, u => `"${u.split('.')[1]}`)
  h = h.replace(/ [{]/g, '":{"')
  h = h.replace(/:\s/g, '":"')
  h = h.replace(/;\s/g, '","')
  h = h.replace(/,"[}]/g, '},')
  h = h.replace(/-[a-z]/g, u =>
    u.indexOf('-') > -1 ? u.split('-')[1].toUpperCase() : u
  )
  h = `{${h}}`
  h = h.replace(/\s/g, '')
  h = h.replace(/,[}]/g, '}')
  return h
}

export const hexToRgba = (hex: string, alpha?: number) => {
  const hL = hex.length
  hex = hL === 4 || hL === 3 ? `${hex}${hex.split('#')[1].repeat(5 - hL)}` : hex
  var res = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return (
    res &&
    `rgba(${parseInt(res[1], 16)},${parseInt(res[2], 16)},${parseInt(
      res[3],
      16
    )}, ${alpha || 1})`
  )
}

export const checkColorLight = (color: string, returnValue?: 'lumens') => {
  function rgbSeparator(rgba: string) {
    const rgbSplit = rgba.split('(')[1].split(',')
    return {
      r: parseInt(rgbSplit[0]),
      g: parseInt(rgbSplit[1]),
      b: parseInt(rgbSplit[2])
    }
  }
  let rgb: RGBProps = { r: 0, g: 0, b: 0 }
  if (color.indexOf('#') > -1) {
    rgb = rgbSeparator(hexToRgba(color) || '')
  } else if (color.indexOf('rgb') > -1) {
    rgb = rgbSeparator(color)
  }
  if (rgb) {
    const luminosity = Math.sqrt(
      0.299 * (rgb.r * rgb.r) +
        0.587 * (rgb.g * rgb.g) +
        0.114 * (rgb.b * rgb.b)
    )
    return returnValue && returnValue === 'lumens'
      ? luminosity
      : luminosity > 127.5
  }
  return null
}

export const parseJson = (stringified: string) =>
  JSON.parse(HTMLentities(stringified))

export const parseHTML = (stringified: string) =>
  ReactHtmlParser(HTMLentities(stringified))

export const cssToJsx = (css: string) => ({ css })

// FIXME not worked on it
// export const valuesParser = (
//   arrObj: Array<any> | Object,
//   key: string,
//   parseFor: string
// ) => {
//   return false
// }

/**
Accepts array of Data and a file name  
*/
export const downloadToCSV = (info: IDownloadToCSV) => {
  const {
    data,
    options,
    fileName,
    type = 'text/csv;charset=utf-8',
    extension = 'csv'
  } = info
  const parser = new Parser(options)
  const csv: any = type === 'text/zip' ? data : parser.parse(data)
  const blob = new Blob([csv], { type })
  FileSaver.saveAs(blob, `${fileName}.${extension}`)
}
