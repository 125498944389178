import { Button, Card, Flex, Input, Label, Modal, Span } from '@fareye/ui'
import { cloneDeep } from '@fareye/utils'
import React from 'react'
export default function ConfigureKpi({ modal, setModal, data }: any) {
  const Data = [
    { id: 1, name: 'C02 Emission DayWise' },
    { id: 2, name: 'C02 Emission CarrierWise' },
    { id: 3, name: 'Loading Performance' },
    { id: 4, name: 'Unloading performance' },
    { id: 5, name: 'Trip State' },
    { id: 6, name: 'Vendor Performance' },
    { id: 7, name: 'Transit Performance' },
    { id: 8, name: 'Route Performance' },
    { id: 9, name: 'Daily Dispatch' }
  ]

  const [selectedGraph, setSelectedGraph] = React.useState(
    Data.map((ele: any) => ele.id)
  )
  function changeHandling(ele: any) {
    console.log(ele)
    let index = selectedGraph.findIndex((a: any) => a === ele.id)
    if (index > -1) {
      let temp = cloneDeep(selectedGraph)
      temp.splice(index, 1)
      setSelectedGraph(temp)
    } else {
      setSelectedGraph([...selectedGraph, ele.id])
    }
  }
  const footer = (
    <Flex justifyContentFlexEnd>
      <Button m={2} variant="primary s">
        Submit
      </Button>
      <Button variant="secondary s" onClick={() => setModal(!modal)} m={2}>
        Cancel
      </Button>
    </Flex>
  )

  return (
    <Modal
      toggleModal={modal}
      setToggleModal={() => setModal(!modal)}
      variant="m"
      style={{
        content: {
          maxHeight: '500px',
          maxWidth: 500,
          boxShadow: '0 0 20px 1px rgba(0,0,0,.2)'
        }
      }}
    >
      <Card header="Configure Graph for Dashboard" footer={footer}>
        <Flex wrap>
          {Data.map((ele: any) => {
            let isSelected = selectedGraph.includes(ele.id)
            return (
              <Flex style={{ minWidth: '150px', margin: '7px 10px' }}>
                <Input
                  type="checkbox"
                  checked={isSelected}
                  key={ele.id}
                  style={{ marginRight: '10px' }}
                  onChange={() => changeHandling(ele)}
                />
                <Span>{ele.name}</Span>
              </Flex>
            )
          })}
        </Flex>
      </Card>
    </Modal>
  )
}
