import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
// import "react-leaflet-geosearch/lib/react-leaflet-geosearch.css";
import 'leaflet-geosearch/dist/geosearch.css'

const SearchControl = (props: any) => {
  const map = useMap()

  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider: props.provider || new OpenStreetMapProvider(),
      ...props
    })

    map.addControl(searchControl)
    return () => map.removeControl(searchControl)
  }, [props])

  return null
}
export default SearchControl
