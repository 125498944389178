import AerisWeather from '@aerisweather/javascript-sdk'
import { CONTEXT_VERSION, LeafletProvider } from '@react-leaflet/core'
import {
  FitBoundsOptions,
  LatLngBoundsExpression,
  Map as LeafletMap,
  MapOptions
} from 'leaflet'
import React, {
  CSSProperties,
  MutableRefObject,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

export interface MapContainerProps extends MapOptions {
  bounds?: LatLngBoundsExpression
  boundsOptions?: FitBoundsOptions
  children?: ReactNode
  className?: string
  id?: string
  placeholder?: ReactNode
  style?: CSSProperties
  whenCreated?: (map: LeafletMap) => void
  whenReady?: () => void
  mapInstance?: any
}

export function useMapElement(
  mapRef: MutableRefObject<HTMLElement | null>,
  props: MapContainerProps
): LeafletMap | null {
  const [map, setMap] = useState<LeafletMap | null>(null)
  const aeris = new AerisWeather(
    'Zo1OUzOxuZfj4uSLirYhj',
    '1ZQN0VhcaCH9n6aMBpe4bV1QkEOuBHPLdx1l8EEr'
  )
  useEffect(() => {
    if (mapRef.current !== null && map === null) {
      let instance: any = null
      aeris.apps(apps => {
        const app = new apps.InteractiveMapApp('#fareye-leaflet', {
          map: {
            strategy: 'leaflet',
            zoom: 4,
            layers: 'satellite,radar'
          },
          panels: {
            layers: {
              buttons: [
                {
                  id: 'cyclones',
                  title: 'Cyclones',
                  segments: [
                    {
                      value: 'tropical-cyclones',
                      title: 'Tropical Cyclones'
                    },
                    {
                      value: 'tropical-cyclones-forecast-point-icons',
                      title: 'Tropical Cyclones - Forecast Icons'
                    },
                    {
                      value: 'tropical-cyclones-break-points',
                      title: 'Tropical Cyclones - Breakpoints'
                    },
                    {
                      value: 'tropical-cyclones-track-point-icons',
                      title: 'Tropical Cyclones - Track Icons'
                    }
                  ],
                  options: {
                    style: {
                      zIndex: 0
                    }
                  }
                },
                {
                  id: 'severe',
                  title: 'Severe',
                  segments: [
                    {
                      value: 'alerts',
                      title: 'Alerts'
                    },
                    {
                      value: 'stormreports',
                      title: 'Storm Reports'
                    }
                  ],
                  options: {
                    style: {
                      zIndex: 0
                    }
                  }
                },
                {
                  id: 'forecasts',
                  title: 'Forecasts',
                  segments: [
                    {
                      value: 'ftemperatures',
                      title: 'Forecast Temperatures'
                    },
                    {
                      value: 'fqpf-accum',
                      title: 'Forecast Precip Accum'
                    },
                    {
                      value: 'fqsf-accum',
                      title: 'Forecast Snow Accum'
                    },
                    {
                      value: 'fsnow-depth',
                      title: 'Forecast Snow Depth'
                    },
                    {
                      value: 'fwind-speeds',
                      title: 'Forecast Winds'
                    },
                    {
                      value: 'fwind-gusts',
                      title: 'Forecast Wind Gusts'
                    },
                    {
                      value: 'fvisibility',
                      title: 'Forecast Visibility'
                    }
                  ],
                  options: {
                    style: {
                      zIndex: 0
                    }
                  }
                },
                {
                  id: 'observations',
                  title: 'Observations',
                  segments: [
                    {
                      value: 'temperatures',
                      title: 'Temperatures'
                    },
                    {
                      value: 'wind-speeds',
                      title: 'Wind Speeds'
                    },
                    {
                      value: 'wind-gusts',
                      title: 'Wind Gust'
                    },
                    {
                      value: 'wave-heights',
                      title: 'Wave Heights'
                    },
                    {
                      value: 'visibility',
                      title: 'Visibility'
                    },
                    {
                      value: 'precip',
                      title: 'Precipitation'
                    },
                    {
                      value: 'snow-depth',
                      title: 'Estimated Snow Depth'
                    }
                  ],
                  options: {
                    style: {
                      zIndex: 0
                    }
                  }
                },
                {
                  id: 'satellite',
                  title: 'Radar and Satellite',
                  segments: [
                    {
                      value: 'fradar',
                      title: 'Forecast Radar'
                    },
                    {
                      value: 'fsatellite',
                      title: 'Forecast Satellite'
                    }
                  ],
                  options: {
                    style: {
                      zIndex: 0
                    }
                  }
                },
                {
                  id: 'roads',
                  title: 'Roads',
                  segments: [
                    {
                      value: 'road-conditions',
                      title: 'Road Conditions'
                    },
                    {
                      value: 'road-conditions-index',
                      title: 'Road Conditions Index'
                    },
                    {
                      value: 'froad-conditions-index-midterm',
                      title: 'Future Road Conditions Index (Midterm)'
                    }
                  ],
                  options: {
                    style: {
                      zIndex: 0
                    }
                  }
                }
              ]
            },
            timeline: {
              toggleable: true
            }
          }
        })
        app.on('ready', () => {
          // get the native map instance from the underlying InteractiveMap instance
          const mapInstance = app.map.map
          instance = mapInstance
          console.log(props, '******')
          if (props.center != null && props.zoom != null) {
            instance.setView(props.center, props.zoom)
          } else if (props.bounds != null) {
            instance.fitBounds(props.bounds, props.boundsOptions)
          }
          if (props.whenReady != null) {
            instance.whenReady(props.whenReady)
          }
          setMap(instance)
        })
      })
    }
  }, [mapRef, map, props])

  return map
}

export function MapContainer<
  Props extends MapContainerProps = MapContainerProps
>({
  children,
  className,
  id,
  placeholder,
  style,
  whenCreated,
  ...options
}: Props) {
  const mapRef = useRef<HTMLDivElement>(null)
  const map = useMapElement(mapRef, options)

  const createdRef = useRef<boolean>(false)
  useEffect(() => {
    if (map != null && createdRef.current === false && whenCreated != null) {
      createdRef.current = true
      whenCreated(map)
    }
  }, [map, whenCreated])

  const [props] = useState({ className, id, style })
  const context = useMemo(
    () => (map ? { __version: CONTEXT_VERSION, map } : null),
    [map]
  )

  const contents = context ? (
    <LeafletProvider value={context}>{children}</LeafletProvider>
  ) : (
    placeholder ?? null
  )
  return (
    <div {...props} ref={mapRef}>
      {contents}
    </div>
  )
}
