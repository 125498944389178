import React from 'react'
import Styled, { CSSObject } from 'styled-components'
import { layout, Props } from '../Theme/styledSystem'
import { IGridProps } from '../utility/types'

const Div = Styled.div`
  display: grid;
  height: max-content;
  width: auto;
  ${(props: IGridProps) => {
    let {
      hidden,
      gridGap,
      responsive,
      textCenter,
      alignItemsEnd,
      alignItemsStart,
      gridTemplateRows,
      alignItemsCenter,
      alignItemsStretch,
      gridTemplateColumns,
      justifyContentEnd,
      justifyContentStart,
      justifyContentCenter,
      justifyContentSpaceAround,
      justifyContentSpaceEvenly,
      justifyContentSpaceBetween
    } = Props(props)
    let classStyle: CSSObject = justifyContentCenter
      ? { 'justify-content': 'center' }
      : {}
    classStyle = hidden ? { ...classStyle, overflow: 'hidden' } : classStyle
    classStyle = responsive ? { ...classStyle, overflow: 'auto' } : classStyle
    classStyle = textCenter
      ? { ...classStyle, textAlign: 'center' }
      : classStyle
    classStyle = alignItemsEnd
      ? { ...classStyle, 'align-items': 'end' }
      : classStyle
    classStyle = alignItemsStart
      ? { ...classStyle, 'align-items': 'start' }
      : classStyle
    classStyle = gridTemplateRows
      ? { ...classStyle, 'grid-template-rows': gridTemplateRows }
      : classStyle
    classStyle = alignItemsCenter
      ? { ...classStyle, 'align-items': 'center' }
      : classStyle
    classStyle = alignItemsStretch
      ? { ...classStyle, 'align-items': 'stretch' }
      : classStyle
    classStyle = justifyContentEnd
      ? { ...classStyle, 'justify-content': 'end' }
      : classStyle
    classStyle = justifyContentStart
      ? { ...classStyle, 'justify-content': 'start' }
      : classStyle
    classStyle = justifyContentSpaceAround
      ? { ...classStyle, justifyContent: 'space-around' }
      : classStyle
    classStyle = justifyContentSpaceEvenly
      ? { ...classStyle, justifyContent: 'space-evenly' }
      : classStyle
    classStyle = justifyContentSpaceBetween
      ? { ...classStyle, justifyContent: 'space-between' }
      : classStyle
    classStyle = gridGap ? { ...classStyle, 'grid-gap': gridGap } : classStyle
    classStyle = gridTemplateColumns
      ? { ...classStyle, 'grid-template-columns': gridTemplateColumns }
      : classStyle
    return classStyle
  }}
  ${layout}
`

export default function Grid(props: IGridProps) {
  let { p, px, py, children } = Props(props)
  return (
    <Div p={p} px={px} py={py} {...props}>
      {children}
    </Div>
  )
}
