import { Button, Flex, Input, Label, Modal, NewCard, Span } from '@fareye/ui'
import React from 'react'
import { SMdStar } from '../../../style/styled'
import { IAddFavourite } from '../../../utility/types'

function AddFav({
  favName,
  flagged,
  setFavName,
  setFlagged,
  saveFavorite,
  selectedFilter,
  showAddFavModal,
  setShowAddFavModal
}: IAddFavourite) {
  const filterType: any[] = selectedFilter.map((i: any) => i.key)
  const uniqueFilterType = Array.from(new Set(filterType))
  const isFlaggingAllowed =
    uniqueFilterType.filter(
      (item: any) => !['vehicle_no', 'driver_name'].includes(item)
    ).length == 0

  return (
    <Modal
      variant="xs"
      toggleModal={showAddFavModal}
      setToggleModal={setShowAddFavModal}
    >
      <NewCard variant="xs">
        <NewCard.Body>
          <Flex alignItemsCenter p={4}>
            <SMdStar style={{ marginRight: 8 }} />
            <Label>Save as Favourite</Label>
          </Flex>
          <Flex column p={8}>
            <Flex wrap>
              {selectedFilter.map((item, index) => (
                <Button
                  key={`${item.label}${index}`}
                  disabled
                  variant="xs"
                  style={{ margin: '2px' }}
                >
                  {item.label}
                </Button>
              ))}
            </Flex>
            <Input
              value={favName}
              placeholder="Add Title"
              style={{ marginTop: 10 }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFavName(e.target.value)
              }
            />
            {isFlaggingAllowed && (
              <Flex style={{ marginTop: 7 }} alignItemsCenter>
                <Input
                  type="checkbox"
                  checked={flagged}
                  onChange={() => setFlagged(!flagged)}
                />
                <Span style={{ marginLeft: 5 }}>
                  Mark as Flagged Vehicle/Driver
                </Span>
              </Flex>
            )}
          </Flex>
          <Flex justifyContentFlexEnd p={4}>
            <Button
              variant="s secondary"
              onClick={() => setShowAddFavModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="s primary"
              style={{ marginLeft: 10 }}
              onClick={saveFavorite}
            >
              Save
            </Button>
          </Flex>
        </NewCard.Body>
      </NewCard>
    </Modal>
  )
}

export default AddFav
