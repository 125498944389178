import React from 'react'
import { styled, Flex } from '../main'
import { Loader } from '../RenderControl/RenderControl'
import { FaSpinner } from '@fareye/utils'

const TranslucentLoader: any = styled(Flex)`
  position: absolute;
  background: rgba(
    ${(props: any) => (props.dark ? '0, 0, 0,0.1' : '255,255,255,.3')}
  );
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
export interface IFullLoader {
  dark?: boolean
}

function FullHeightWidthTranslucentLoader({ dark }: IFullLoader) {
  return (
    <TranslucentLoader dark={dark} alignItemsCenter justifyContentCenter>
      <Loader>
        <FaSpinner />
      </Loader>
    </TranslucentLoader>
  )
}

export default FullHeightWidthTranslucentLoader
