import { dateFormatter as dateFormatterTimezone } from '@fareye/common'
import { ddMMyyyyHHmm, formatMs } from '@fareye/utils'
import { IColumn } from '../utility/types'
//NOTE:For sorting object keys => (obj).toPairs().sortBy(0).fromPairs().value()
export const NO_FORMATTED_REPORTS = [
  'backwardReport1',
  'backwardReport2',
  'dumpDeviationReport'
]

export const Columns: IColumn[] = [
  {
    label: 'Vehicle No',
    key: 'vehicleNo'
  },
  {
    label: 'Tracking',
    key: 'tracking'
  },
  {
    key: 'link',
    label: 'Link'
  },
  {
    label: 'Consigner',
    key: 'consignerName'
  },
  {
    label: 'Consignee',
    key: 'consigneeName'
  },
  {
    key: 'brandName',
    label: 'Brand Name'
  },
  {
    label: 'Origin',
    key: 'origin'
  },
  {
    label: 'Destination',
    key: 'destination'
  },
  {
    label: 'Invoice No',
    key: 'invoiceNo'
  },
  {
    label: 'Invoice Qty',
    key: 'invoiceQty'
  },
  {
    label: 'Region',
    key: 'region'
  },
  {
    label: 'Driver Name',
    key: 'driverName'
  },
  {
    label: 'Driver Contact',
    key: 'phoneNo'
  },
  {
    label: 'IMEI No',
    key: 'imei'
  },
  {
    label: 'Last Location',
    key: 'lastLocation'
  },
  {
    label: 'Transporter',
    key: 'transporter'
  },
  {
    label: 'Start Time',
    key: 'startDate',
    formatter: dateFormatter
  },
  {
    label: 'S.T.A',
    key: 'eta',
    formatter: dateFormatter
  },
  {
    label: 'State',
    key: 'state'
  },
  {
    label: 'Status',
    key: 'status'
  },
  {
    label: 'Loading In',
    key: 'loadingInTime',
    formatter: dateFormatter
  },
  {
    label: 'Loading Out',
    key: 'loadingOutTime',
    formatter: dateFormatter
  },
  {
    label: 'UnLoading In',
    key: 'unloadingInTime',
    formatter: dateFormatter
  },
  {
    label: 'Unloading Out',
    key: 'unloadingOutTime',
    formatter: dateFormatter
  },
  {
    label: 'Delay Time (hh:mm)',
    key: 'delayTimeHhMm'
  },
  {
    label: 'Loading TAT (hh:mm)',
    key: 'loadingTat',
    formatter: timeFormatter
  },
  {
    label: 'Unloading TAT(hh:mm)',
    key: 'unloadingTat',
    formatter: timeFormatter
  },
  {
    label: 'Sap loading in time',
    key: 'vehicleEntryTime',
    formatter: dateFormatter
  },
  {
    label: 'Sap loading out time',
    key: 'vehicleExitTime',
    formatter: dateFormatter
  },
  {
    label: 'Sap unloading in time',
    key: 'vehicleUnloadingEntryTime',
    formatter: dateFormatter
  },
  {
    label: 'Sap unloading out time',
    key: 'vehicleUnloadingExitTime',
    formatter: dateFormatter
  },
  {
    label: 'Percentage Covered(%)',
    key: 'percCovered'
  },
  {
    label: 'Transit Time(hh:mm)',
    key: 'transitTime',
    formatter: timeFormatter
  },
  {
    label: 'Last IST Timestamp',
    key: 'istTimestamp',
    formatter: dateFormatter
  },
  {
    label: 'Disconnected Since',
    key: 'disconnectedSince',
    formatter: dateFormatter
  },
  {
    label: 'Creation Time',
    key: 'creationTime',
    formatter: dateFormatter
  },
  {
    label: 'Closure Time',
    key: 'closedDate',
    formatter: dateFormatter
  },
  {
    label: 'Closure Reason',
    key: 'closureReason'
  },

  {
    label: 'Ship To Code',
    key: 'shipToCode'
  },
  {
    key: 'tripDistance',
    label: 'Distance(Km)',
    formatter: distanceMeterToKm
  },
  {
    key: 'stoppageDuration',
    label: 'Stoppage Duration',
    formatter: timeFormatterFromSeconds
  },
  {
    key: 'movingDuration',
    label: 'Moving Duration',
    formatter: timeFormatterFromSeconds
  },
  {
    key: 'idleDuration',
    label: 'Idle Duration',
    formatter: timeFormatterFromSeconds
  },
  {
    key: 'gpsUptime',
    label: 'Gps Uptime',
    formatter: timeFormatterFromSeconds
  },
  {
    key: 'gpsDowntime',
    label: 'Gps Downtime',
    formatter: timeFormatterFromSeconds
  },
  {
    key: 'stoppageCount',
    label: 'Stoppage Count'
  },

  {
    label: 'Distance(Km)',
    key: 'distanceTravelled'
  },
  {
    label: 'Avg Speed',
    key: 'avgSpeed'
  },
  {
    label: 'Start Time',
    key: 'startTimestamp',
    formatter: dateFormatter
  },
  {
    label: 'End Time',
    key: 'endTimestamp',
    formatter: dateFormatter
  },
  {
    label: 'Start location',
    key: 'startLandmark'
  },
  {
    label: 'End location',
    key: 'endLandmark'
  },
  {
    label: 'Old State',
    key: 'oldState'
  },
  {
    label: 'New State',
    key: 'newState'
  },
  {
    label: 'Duration',
    key: 'duration',
    formatter: timeFormatterFromSeconds
  },
  {
    label: 'Distance Km',
    key: 'distanceKm'
  },
  {
    label: 'Latitude',
    key: 'latitude'
  },
  {
    label: 'Longitude',
    key: 'longitude'
  },
  {
    label: 'Speed',
    key: 'speed'
  },
  {
    label: 'landmark',
    key: 'landmark'
  },
  {
    label: 'Raw Data',
    key: 'rawData'
  },
  {
    label: 'Odometer(Km)',
    key: 'odometerKm'
  },
  {
    label: 'Power Status High',
    key: 'powerStatusHigh'
  },
  {
    label: 'Alert Data',
    key: 'alertData'
  },
  {
    label: 'Signal Strength',
    key: 'signalStrength'
  },
  {
    label: 'Battery Voltage',
    key: 'batteryVoltage'
  },
  {
    label: 'Idle Time',
    key: 'idleTime',
    formatter: timeFormatterFromSeconds
  },
  {
    label: 'Moving Time',
    key: 'movingTime'
  },
  {
    label: 'Gps Timestamp',
    key: 'gpsTimestamp',
    formatter: dateFormatter
  },
  {
    label: 'Stop Time',
    key: 'stopTime',
    formatter: timeFormatterFromSeconds
  },
  {
    label: 'Ist Timestamp',
    key: 'istTimestamp',
    formatter: dateFormatter
  },
  {
    label: 'Is Scraped',
    key: 'isScraped'
  },
  {
    label: 'Is Disconnected',
    key: 'isDisconnected'
  },
  {
    label: 'Is Sim',
    key: 'isSim'
  },
  {
    label: 'Is Live',
    key: 'isLive'
  },
  {
    label: 'Is Processed',
    key: 'isProcessed'
  },
  {
    label: 'Distance Prev Mtrs',
    key: 'distancePrevMtrs'
  },
  {
    label: 'Date',
    key: 'date',
    formatter: dateFormatter
  },
  {
    label: 'Running Time',
    key: 'runningTime',
    formatter: timeFormatterFromSeconds
  },
  {
    label: 'Start State',
    key: 'startState'
  },
  {
    label: 'End State',
    key: 'endState'
  },
  {
    label: 'End Date',
    key: 'endDate',
    formatter: dateFormatter
  },
  {
    label: 'Overspeed Instances',
    key: 'overspeedInstances'
  },
  {
    label: 'Max Speed(Km/hr)',
    key: 'maxSpeed'
  },
  {
    label: 'N Running Time',
    key: 'nRunning',
    formatter: timeFormatterFromSeconds
  },
  {
    label: 'N Stop Time',
    key: 'nStopTime',
    formatter: timeFormatterFromSeconds
  },
  {
    label: 'N Idle Time',
    key: 'nIdleTime',
    formatter: timeFormatterFromSeconds
  },
  {
    label: 'N Distance Travelled(Km)',
    key: 'nDistanceTravelled'
  },
  {
    label: 'N Odometer(Km)',
    key: 'nOdometerKm'
  },
  {
    label: 'Max Timestamp',
    key: 'maxTimestamp',
    formatter: dateFormatter
  },
  {
    label: 'Created At',
    key: 'createdAt',
    formatter: dateFormatter
  },
  {
    label: 'Updated At',
    key: 'updatedAt',
    formatter: dateFormatter
  },
  {
    label: 'Instances',
    key: 'instances'
  },

  {
    label: 'Enter Time',
    key: 'enterTime',
    formatter: dateFormatter
  },
  {
    label: 'Exit Time',
    key: 'exitTime',
    formatter: dateFormatter
  },
  {
    label: 'Poi Id',
    key: 'poiId'
  },
  {
    label: 'Trip Id',
    key: 'tripId'
  },
  {
    label: 'Entry Long',
    key: 'entryLong'
  },
  {
    label: 'Entry Lat',
    key: 'entryLat'
  },
  {
    label: 'Exit Lat',
    key: 'exitLat'
  },
  {
    label: 'Exit Long',
    key: 'exitLong'
  },
  {
    label: 'Poi Type',
    key: 'poiType'
  },
  {
    label: 'Next Outside Timestamp',
    key: 'nextOutsideTimestamp',
    formatter: dateFormatter
  },
  {
    label: 'Previous Outside Timestamp',
    key: 'previousOutsideTimestamp',
    formatter: dateFormatter
  },
  {
    label: 'Poi Nick Name',
    key: 'poiNickName'
  },
  {
    label: 'Entry Distance(Km)',
    key: 'entryDistanceInKm'
  },
  {
    label: 'Exit Distance(Km)',
    key: 'exitDistanceInKm'
  },
  {
    key: 'plantCode',
    label: 'Plant Code'
  },
  {
    key: 'reason',
    label: 'Reason'
  },
  {
    key: 'sta',
    label: 'Sta'
  },
  {
    key: 'driverPhoneNo',
    label: 'Driver Phone No'
  },
  {
    key: 'stockTransfer',
    label: 'Stock Transfer'
  },
  {
    key: 'primaryUnloadingIn',
    label: 'Primary Unloading In'
  },
  {
    key: 'primaryUnloadingOut',
    label: 'Primary Unloading Out'
  },
  {
    key: 'secondaryUnloadingIn',
    label: 'Secondary Unloading In'
  },
  {
    key: 'secondaryUnloadingOut',
    label: 'Secondary Unloading Out'
  },
  {
    key: 'tertiaryUnloadingIn',
    label: 'Tertiary Unloading In'
  },
  {
    key: 'tertiaryUnloadingOut',
    label: 'Tertiary Unloading Out'
  },
  {
    key: 'sapEntryTime',
    label: 'Sap Entry Time'
  },
  {
    key: 'sapExitTime',
    label: 'Sap Exit Time'
  },
  {
    key: 'tertiaryLoadingIn',
    label: 'Tertiary Loading In'
  },
  {
    key: 'tertiaryLoadingOut',
    label: 'Tertiary Loading Out'
  },
  {
    key: 'primaryUnloadingCord',
    label: 'Primary Unloading Cord'
  },
  {
    key: 'secondaryUnloadingCord',
    label: 'Secondary Unloading Cord'
  },
  {
    key: 'tertiaryUnloadingCord',
    label: 'Tertiary Unloading Cord'
  },
  {
    key: 'primaryLoadingCord',
    label: 'Primary Loading Cord'
  },
  {
    key: 'tertiaryLoadingCord',
    label: 'Tertiary Loading Cord'
  }
]
export const formatters = {
  dateFormatter,
  dateFormatterTz,
  timeFormatter,
  timeFormatterFromSeconds,
  distanceMeterToKm,
  timeFormatterFromMinutes,
  precision2,
  precision4
}

function dateFormatter(val: any) {
  return val ? ddMMyyyyHHmm(val) : ''
}
function dateFormatterTz(val: any): any {
  return val ? dateFormatterTimezone(val, 'YYYYMMDDHHmmz') : ''
}
function precision(val: any, place = 2) {
  if (!val) {
    return ''
  } else {
    return Number(val).toFixed(place)
  }
}

function precision2(val: any) {
  return precision(val, 2)
}
function precision4(val: any) {
  return precision(val, 4)
}

function timeFormatter(val: any) {
  return val ? formatMs(val) : ''
}

function timeFormatterFromSeconds(val: any) {
  return val ? formatMs(val * 1000) : ''
}
function timeFormatterFromMinutes(val: any) {
  return val ? formatMs(val * 1000 * 60) : ''
}
function distanceMeterToKm(val: any) {
  return val ? (val / 1000).toFixed(2) : ''
}

export const HEADER_INFLECTION = {
  headers: { 'Key-Inflection': 'camel', 'X-Change-Case': true }
}
