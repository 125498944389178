import React from 'react'
import { FaInfoCircle } from '@fareye/utils'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

export const InfoIconContainer = styled(FaInfoCircle)`
  margin-bottom: -2px;
  height: 14px;
  width: 14px;
  ${(props: any) => `
    background: transparent;
    color: ${props.theme.colors.gray6};
  `}
`
export const ReactTooltipContainer = styled(ReactTooltip)`
  max-width: 150px;}
`
export function InfoIcon(props: any) {
  const { title, ...rest } = props
  return (
    <>
      <InfoIconContainer {...rest} data-tip={props.title} />
      <ReactTooltipContainer />
    </>
  )
}
