import { ITripDetailConfig } from '@fareye/trip-details'
import environment from '../../environment'

const { baseURL } = environment

const v2ConsignerTrips = `/api/v2/consigner_trips`
const v2ShareTrips = `/api/v2/share_trips`

const TripDetailsRenderingConfig: any = {
  packageHistory: {
    styling: {
      style: { background: '#F0F2F5' },
      layoutStyle: {},
      className: '',
      variant: {}
    },
    config: {
      type: 'package',
      shareURL: `${baseURL}/api/v2/share_trips/share_link`,
      linkURL: '/share/packages',
      shareQueryParams: {
        resource_type: 'Shipment',
        resource_id: ''
      },
      showTimezone: true,
      subscribeURL: `${baseURL}/api/v3/trip/subscribe/`,
      summaryURL: `${baseURL}/api/v3/packages/`,
      otherDetailsURL: {
        url: `${baseURL}/api/v3/packages/`,
        pathParam: '/packages_data'
      },
      links: { shipmentShare: '/shipment' },
      tabsComponent: {
        summary: {
          label: 'Summary',
          showMap: true,
          component: 'summary'
        },
        exceptions: {
          label: 'Exceptions',
          showMap: true,
          component: 'exceptions'
        },
        stoppages: {
          label: 'Stoppages',
          showMap: true,
          component: 'stoppages'
        },
        history: {
          label: 'History',
          showMap: true,
          component: 'history'
        },
        transporterDetails: {
          label: 'Transporter Details',
          showMap: false,
          component: 'transporterDetails'
        },
        itemDetails: {
          label: 'Item Details',
          showMap: false,
          component: 'itemDetails'
        },
        // delays: {
        //   label: 'Delays',
        //   showMap: false,
        //   component: 'delays'
        // },
        oceanPortLogs: {
          label: 'Ocean Port Logs',
          showMap: false,
          component: 'oceanPortLogs'
        }
      },
      transporterColumns: {
        road: [
          { key: 'shipmentId', label: 'Shipment Id' },
          { key: 'transporterName', label: 'Transporter Name' },
          { key: 'transporterCode', label: 'Carrier Code' },
          { key: 'vehicleNo', label: 'Vehicle No.' },
          { key: 'route', label: 'Route' },
          { key: 'departureTime', label: 'STD/ATD' },
          { key: 'arrivalTime', label: 'STA/ATA' },
          {
            key: 'loadingInTime',
            label: 'Loading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'loadingOutTime',
            label: 'Loading Out',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingInTime',
            label: 'Unloading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingOutTime',
            label: 'Unloading Out',
            formatter: 'dateFormatter'
          },
          { key: 'driverName', label: 'Driver Name' },
          { key: 'routeState', label: 'Route State' },
          { key: 'driverNo', label: 'Driver No.' }
        ],
        ocean: [
          { key: 'shipmentId', label: 'Shipment Id' },
          { key: 'transporterName', label: 'Transporter Name' },
          { key: 'vehicleNo', label: 'IMO Code' },
          { key: 'vesselNo', label: 'Voyage No/Vessel No' },
          { key: 'route', label: 'Route' },
          { key: 'vesselName', label: 'Vessel Name' },
          { key: 'polPrettyName', label: 'POL' },
          { key: 'podPrettyName', label: 'POD' },
          { key: 'transshipment', label: 'Transshipment' },
          { key: 'containerNo', label: 'Container No' },
          { key: 'oceanArrivalTime', label: 'STA/ATA' },
          { key: 'oceanDepartureTime', label: 'STD/ATD' },
          { key: 'cll', label: 'CLL', formatter: 'dateFormatter' },
          { key: 'cdd', label: 'CDD', formatter: 'dateFormatter' },
          { key: 'routeState', label: 'Route State' }
        ],
        air: [
          { key: 'shipmentId', label: 'Shipment Id' },
          { key: 'transporterName', label: 'Transporter Name' },
          { key: 'transporterCode', label: 'Carrier Code' },
          { key: 'vehicleNo', label: 'Flight No.' },
          { key: 'route', label: 'Route' },
          { key: 'departureTime', label: 'ETD/ATD' },
          { key: 'arrivalTime', label: 'ETA/ATA' },
          { key: 'routeState', label: 'Route State' }
        ],
        rail: [
          { key: 'shipmentId', label: 'Shipment Id' },
          { key: 'transporterName', label: 'Transporter Name' },
          { key: 'transporterCode', label: 'Carrier Code' },
          { key: 'vehicleNo', label: 'Rake No.' },
          { key: 'fnrNo', label: 'FNR No.' },
          { key: 'route', label: 'Route' },
          { key: 'departureTime', label: 'ETD/ATD' },
          { key: 'arrivalTime', label: 'ETA/ATA' },
          {
            key: 'loadingInTime',
            label: 'Loading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'loadingOutTime',
            label: 'Loading Out',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingInTime',
            label: 'Unloading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingOutTime',
            label: 'Unloading Out',
            formatter: 'dateFormatter'
          },
          { key: 'routeState', label: 'Route State' }
        ]
      },
      itemColumns: [
        { key: 'id', label: 'Item Id' },
        { key: 'material', label: 'Product Name' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'origin', label: 'Origin' },
        { key: 'destination', label: 'Destination' },
        { key: 'consignerName', label: 'Consigner' },
        { key: 'consigneeName', label: 'Consignee' }
      ],
      portLogColumns: [
        { key: 'eventRaw', label: 'Events' },
        { key: 'locationRaw', label: 'Location' },
        {
          key: 'eventScheduledTime',
          label: 'Scheduled Time',
          formatter: 'dateFormatter'
        },
        {
          key: 'expectedEventTime',
          label: 'Predicted Time',
          formatter: 'dateFormatter'
        },
        { key: 'eventTime', label: 'Actual Time', formatter: 'dateFormatter' },
        { key: 'eventTimeDiff', label: 'Time Difference' }
      ],
      delayColumns: [
        { key: '', label: 'Landmark' },
        { key: '', label: 'Stage' },
        { key: '', label: 'Scheduled Time' },
        { key: '', label: 'Actual Time' },
        { key: '', label: 'Reason' },
        { key: '', label: 'Transporter' },
        { key: '', label: 'Vehicle No.' },
        { key: '', label: 'Time Difference' }
      ]
    }
  },
  shipmentHistory: {
    styling: {
      style: { background: '#F0F2F5' },
      layoutStyle: {},
      className: '',
      variant: {}
    },
    config: {
      type: 'shipment',
      shareURL: `${baseURL}/api/v2/share_trips/share_link`,
      linkURL: '/share/shipment',
      shareQueryParams: {
        resource_type: 'Journey',
        resource_id: ''
      },
      showTimezone: true,
      subscribeURL: `${baseURL}/api/v3/trip/subscribe/`,
      summaryURL: `${baseURL}/api/v3/journeys/`,
      otherDetailsURL: {
        url: `${baseURL}/api/v3/journeys/`,
        pathParam: '/journey_data'
      },
      links: { packageShare: '/packages' },
      tabsComponent: {
        summary: {
          label: 'Summary',
          showMap: true,
          component: 'shipmentSummary'
        },
        exceptions: {
          label: 'Exceptions',
          showMap: true,
          component: 'exceptions'
        },
        stoppages: {
          label: 'Stoppages',
          showMap: true,
          component: 'stoppages'
        },
        history: {
          label: 'History',
          showMap: true,
          component: 'history'
        },
        transporterDetails: {
          label: 'Transporter Details',
          showMap: false,
          component: 'transporterDetails'
        },
        packageDetails: {
          label: 'Package Details',
          showMap: false,
          component: 'packageDetails'
        },
        oceanPortLogs: {
          label: 'Ocean Port Logs',
          showMap: false,
          component: 'oceanPortLogs'
        }
        // delays: {
        //   label: 'Delays',
        //   showMap: false,
        //   component: 'delays'
        // }
      },
      transporterColumns: {
        road: [
          { key: 'transporterName', label: 'Transporter Name' },
          { key: 'transporterCode', label: 'Carrier Code' },
          { key: 'vehicleNo', label: 'Vehicle No.' },
          { key: 'shipmentRoute', label: 'Route' },
          { key: 'departureTime', label: 'ETD/ATD' },
          { key: 'arrivalTime', label: 'ETA/ATA' },
          {
            key: 'loadingInTime',
            label: 'Loading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'loadingOutTime',
            label: 'Loading Out',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingInTime',
            label: 'Unloading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingOutTime',
            label: 'Unloading Out',
            formatter: 'dateFormatter'
          },
          { key: 'driverName', label: 'Driver Name' },
          { key: 'routeState', label: 'Route State' },
          { key: 'driverNo', label: 'Driver No.' }
        ],
        ocean: [
          { key: 'transporterName', label: 'Transporter Name' },
          { key: 'vehicleNo', label: 'IMO Code' },
          { key: 'vesselNo', label: 'Voyage No/Vessel No' },
          { key: 'shipmentRoute', label: 'Route' },
          { key: 'vesselName', label: 'Vessel Name' },
          { key: 'polPrettyName', label: 'POL' },
          { key: 'podPrettyName', label: 'POD' },
          { key: 'transshipment', label: 'Transshipment' },
          { key: 'containerNo', label: 'Container No' },
          { key: 'oceanArrivalTime', label: 'STA/ATA' },
          { key: 'oceanDepartureTime', label: 'STD/ATD' },
          { key: 'cll', label: 'CLL', formatter: 'dateFormatter' },
          { key: 'cdd', label: 'CDD', formatter: 'dateFormatter' },
          { key: 'routeState', label: 'Route State' }
        ],
        air: [
          { key: 'transporterName', label: 'Transporter Name' },
          { key: 'transporterCode', label: 'Carrier Code' },
          { key: 'vehicleNo', label: 'Flight No.' },
          { key: 'shipmentRoute', label: 'Route' },
          { key: 'departureTime', label: 'ETD/ATD' },
          { key: 'arrivalTime', label: 'ETA/ATA' },
          { key: 'routeState', label: 'Route State' }
        ],
        rail: [
          { key: 'transporterName', label: 'Transporter Name' },
          { key: 'transporterCode', label: 'Carrier Code' },
          { key: 'vehicleNo', label: 'Rake No.' },
          { key: 'fnrNo', label: 'FNR No.' },
          { key: 'shipmentRoute', label: 'Route' },
          { key: 'departureTime', label: 'ETD/ATD' },
          { key: 'arrivalTime', label: 'ETA/ATA' },
          {
            key: 'loadingInTime',
            label: 'Loading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'loadingOutTime',
            label: 'Loading Out',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingInTime',
            label: 'Unloading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingOutTime',
            label: 'Unloading Out',
            formatter: 'dateFormatter'
          },
          { key: 'routeState', label: 'Route State' }
        ]
      },
      packageColumns: [
        { key: 'packageId', label: 'Package Id' },
        { key: 'material', label: 'Package Type' },
        { key: 'item', label: 'Item' },
        { key: 'origin', label: 'Origin' },
        { key: 'destination', label: 'Destination' },
        { key: 'consignerName', label: 'Consigner' },
        { key: 'consigneeName', label: 'Consignee' },
        { key: 'packageRouteState', label: 'Route State' }
      ],
      portLogColumns: [
        { key: 'eventRaw', label: 'Events' },
        { key: 'locationRaw', label: 'Location' },
        {
          key: 'eventScheduledTime',
          label: 'Scheduled Time',
          formatter: 'dateFormatter'
        },
        {
          key: 'expectedEventTime',
          label: 'Predicted Time',
          formatter: 'dateFormatter'
        },
        { key: 'eventTime', label: 'Actual Time', formatter: 'dateFormatter' },
        { key: 'eventTimeDiff', label: 'Time Difference' }
      ],

      delayColumns: [
        { key: '', label: 'Landmark' },
        { key: '', label: 'Stage' },
        { key: '', label: 'Scheduled Time' },
        { key: '', label: 'Actual Time' },
        { key: '', label: 'Reason' },
        { key: '', label: 'Transporter' },
        { key: '', label: 'Vehicle No.' },
        { key: '', label: 'Time Difference' }
      ]
    }
  },
  inTransit: {
    styling: {
      style: {},
      layoutStyle: { height: 'calc(100vh - 50px)' },
      className: '',
      variant: {}
    },
    shareURL: `${baseURL}/api/v2/share_trips/share_link`,
    shareQueryParams: {
      resource_type: 'consigner_trip',
      resource_id: ''
    },
    subscribeURL: `${baseURL}/api/v3/trip/subscribe/`,
    tripHistoryURl: `${baseURL}${v2ConsignerTrips}/transit_history`,
    tripExceptionURL: `${baseURL}${v2ConsignerTrips}/transit_exceptions`,
    tripPoisURL: `${baseURL}${v2ConsignerTrips}/__slug__/trip_pois`,
    mapOverInfoToggle: true,
    tripTabs: [
      {
        label: 'Trip Details',
        apiKey: '',
        conditionalField: []
      },
      {
        label: 'Exception',
        apiKey: '',
        conditionalField: []
      },
      {
        label: 'Stoppage',
        apiKey: '',
        conditionalField: []
      },
      {
        label: 'History',
        apiKey: '',
        conditionalField: []
      }
    ]
  },
  inPlant: {
    styling: {
      style: {},
      layoutStyle: { height: 'calc(100vh - 50px)' },
      className: '',
      variant: {}
    },
    shareURL: `${baseURL}/api/v2/share_trips/share_link`,
    shareQueryParams: {
      resource_type: 'consigner_trip',
      resource_id: ''
    },
    subscribeURL: `${baseURL}/api/v3/trip/subscribe/`,
    tripHistoryURl: `${baseURL}${v2ConsignerTrips}/inplant_history`,
    tripExceptionURL: `${baseURL}${v2ConsignerTrips}/inplant_exceptions`,
    tripPoisURL: `${baseURL}${v2ConsignerTrips}/`,
    mapOverInfoToggle: false,
    tripTabs: [
      {
        label: 'Trip Details',
        apiKey: '',
        conditionalField: []
      },
      {
        label: 'Exception',
        apiKey: '',
        conditionalField: []
      },
      {
        label: 'Stoppage',
        apiKey: '',
        conditionalField: []
      },
      {
        label: 'History',
        apiKey: '',
        conditionalField: []
      }
    ]
  },
  shipmentHistoryShare: {
    styling: {
      style: {},
      layoutStyle: { height: 'calc(100vh - 50px)' },
      className: '',
      variant: {}
    },
    config: {
      type: 'shipment',
      shareURL: '',
      summaryURL: `${baseURL}/api/v3/share_trips/journeys/`,
      showTimezone: true,
      otherDetailsURL: {
        url: `${baseURL}/api/v3/share_trips/journeys/`,
        pathParam: '/journey_data'
      },
      links: { packageShare: '/packages' },
      tabsComponent: {
        summary: {
          label: 'Summary',
          showMap: true,
          component: 'shipmentSummary'
        },
        exceptions: {
          label: 'Exceptions',
          showMap: true,
          component: 'exceptions'
        },
        stoppages: {
          label: 'Stoppages',
          showMap: true,
          component: 'stoppages'
        },
        history: {
          label: 'History',
          showMap: true,
          component: 'history'
        },
        transporterDetails: {
          label: 'Transporter Details',
          showMap: false,
          component: 'transporterDetails'
        },
        packageDetails: {
          label: 'Package Details',
          showMap: false,
          component: 'packageDetails'
        },
        oceanPortLogs: {
          label: 'Ocean Port Logs',
          showMap: false,
          component: 'oceanPortLogs'
        },
        ePOD: {
          label: 'EPOD Details',
          showMap: false,
          component: 'ePOD'
        }
      },
      transporterColumns: {
        road: [
          {
            key: 'lrNo',
            label: 'Shipment Id'
          },
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'transporterCode',
            label: 'Carrier Code'
          },
          {
            key: 'vehicleNo',
            label: 'Vehicle No.'
          },
          {
            key: 'shipmentRoute',
            label: 'Route'
          },
          {
            key: 'departureTime',
            label: 'ETD/ATD'
          },
          {
            key: 'arrivalTime',
            label: 'ETA/ATA'
          },
          {
            key: 'loadingInTime',
            label: 'Loading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'loadingOutTime',
            label: 'Loading Out',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingInTime',
            label: 'Unloading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingOutTime',
            label: 'Unloading Out',
            formatter: 'dateFormatter'
          },
          {
            key: 'driverName',
            label: 'Driver Name'
          },
          {
            key: 'routeState',
            label: 'Route State'
          },
          {
            key: 'driverNo',
            label: 'Driver No.'
          }
        ],
        ocean: [
          {
            key: 'lrNo',
            label: 'Shipment Id'
          },
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'vehicleNo',
            label: 'IMO Code'
          },
          {
            key: 'vesselNo',
            label: 'Voyage No/Vessel No'
          },
          {
            key: 'shipmentRoute',
            label: 'Route'
          },
          {
            key: 'vesselName',
            label: 'Vessel Name'
          },
          {
            key: 'polPrettyName',
            label: 'POL'
          },
          {
            key: 'podPrettyName',
            label: 'POD'
          },
          {
            key: 'transshipment',
            label: 'Transshipment'
          },
          {
            key: 'containerNo',
            label: 'Container No'
          },
          {
            key: 'oceanArrivalTime',
            label: 'STA/ATA'
          },
          {
            key: 'oceanDepartureTime',
            label: 'STD/ATD'
          },
          {
            key: 'cll',
            label: 'CLL',
            formatter: 'dateFormatter'
          },
          {
            key: 'cdd',
            label: 'CDD',
            formatter: 'dateFormatter'
          },
          {
            key: 'routeState',
            label: 'Route State'
          }
        ],
        air: [
          {
            key: 'lrNo',
            label: 'Shipment Id'
          },
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'transporterCode',
            label: 'Carrier Code'
          },
          {
            key: 'vehicleNo',
            label: 'Flight No.'
          },
          {
            key: 'shipmentRoute',
            label: 'Route'
          },
          {
            key: 'departureTime',
            label: 'ETD/ATD'
          },
          {
            key: 'arrivalTime',
            label: 'ETA/ATA'
          },
          {
            key: 'routeState',
            label: 'Route State'
          }
        ],
        rail: [
          {
            key: 'lrNo',
            label: 'Shipment Id'
          },
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'transporterCode',
            label: 'Carrier Code'
          },
          {
            key: 'vehicleNo',
            label: 'Rake No.'
          },
          {
            key: 'fnrNo',
            label: 'FNR No.'
          },
          {
            key: 'shipmentRoute',
            label: 'Route'
          },
          {
            key: 'departureTime',
            label: 'ETD/ATD'
          },
          {
            key: 'arrivalTime',
            label: 'ETA/ATA'
          },
          {
            key: 'loadingInTime',
            label: 'Loading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'loadingOutTime',
            label: 'Loading Out',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingInTime',
            label: 'Unloading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingOutTime',
            label: 'Unloading Out',
            formatter: 'dateFormatter'
          },
          {
            key: 'routeState',
            label: 'Route State'
          }
        ]
      },
      currentTransporterColumns: {
        road: [
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'transporterCode',
            label: 'Carrier Code'
          },
          {
            key: 'vehicleNo',
            label: 'Vehicle No.'
          },
          {
            key: 'route',
            label: 'Route'
          },
          {
            key: 'departureTime',
            label: 'ETD/ATD'
          },
          {
            key: 'arrivalTime',
            label: 'ETA/ATA'
          },
          {
            key: 'driverName',
            label: 'Driver Name'
          },
          {
            key: 'driverNo',
            label: 'Phone Number'
          }
        ],
        ocean: [
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'vehicleNo',
            label: 'IMO Code'
          },
          {
            key: 'vesselNo',
            label: 'Voyage No/Vessel No'
          },
          {
            key: 'route',
            label: 'Route'
          },
          {
            key: 'vesselName',
            label: 'Vessel Name'
          },
          {
            key: 'billOfLading',
            label: 'B/L No'
          },
          {
            key: 'polPrettyName',
            label: 'POL'
          },
          {
            key: 'podPrettyName',
            label: 'POD'
          },
          {
            key: 'transshipment',
            label: 'Transshipment'
          },
          {
            key: 'containerNo',
            label: 'Container No'
          }
        ],
        air: [
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'transporterCode',
            label: 'Carrier Code'
          },
          {
            key: 'vehicleNo',
            label: 'Flight No.'
          },
          {
            key: 'route',
            label: 'Route'
          },
          {
            key: 'departureTime',
            label: 'ETD/ATD'
          },
          {
            key: 'arrivalTime',
            label: 'ETA/ATA'
          }
        ],
        rail: [
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'transporterCode',
            label: 'Carrier Code'
          },
          {
            key: 'vehicleNo',
            label: 'Rake No.'
          },
          {
            key: 'fnrNo',
            label: 'FNR No.'
          },
          {
            key: 'route',
            label: 'Route'
          },
          {
            key: 'departureTime',
            label: 'ETD/ATD'
          },
          {
            key: 'arrivalTime',
            label: 'ETA/ATA'
          }
        ]
      },
      packageColumns: [
        {
          key: 'packageId',
          label: 'Package Id'
        },
        {
          key: 'material',
          label: 'Package Type'
        },
        {
          key: 'item',
          label: 'Item'
        },
        {
          key: 'origin',
          label: 'Origin'
        },
        {
          key: 'destination',
          label: 'Destination'
        },
        {
          key: 'consignerName',
          label: 'Consigner'
        },
        {
          key: 'consigneeName',
          label: 'Consignee'
        },
        {
          key: 'packageRouteState',
          label: 'Route State'
        }
      ],
      portLogColumns: [
        {
          key: 'eventRaw',
          label: 'Events'
        },
        {
          key: 'locationRaw',
          label: 'Location'
        },
        {
          key: 'eventScheduledTime',
          label: 'Scheduled Time',
          formatter: 'dateFormatter'
        },
        {
          key: 'expectedEventTime',
          label: 'Predicted Time',
          formatter: 'dateFormatter'
        },
        {
          key: 'eventTime',
          label: 'Actual Time',
          formatter: 'dateFormatter'
        },
        {
          key: 'eventTimeDiff',
          label: 'Time Difference'
        }
      ],
      delayColumns: [
        {
          key: '',
          label: 'Landmark'
        },
        {
          key: '',
          label: 'Stage'
        },
        {
          key: '',
          label: 'Scheduled Time'
        },
        {
          key: '',
          label: 'Actual Time'
        },
        {
          key: '',
          label: 'Reason'
        },
        {
          key: '',
          label: 'Transporter'
        },
        {
          key: '',
          label: 'Vehicle No.'
        },
        {
          key: '',
          label: 'Time Difference'
        }
      ],
      ePODColumns: [
        {
          key: 'epodName',
          label: 'Location Name'
        },
        {
          key: 'epodTime',
          label: 'Time',
          formatter: 'dateFormatter'
        },
        {
          key: 'epodRemarks',
          label: 'Delivery Remarks'
        },
        {
          key: 'epodFeedback',
          label: 'Feedback'
        },
        {
          key: 'images',
          label: 'Images'
        },
        {
          key: 'signature',
          label: 'Signature'
        }
      ]
    }
  },
  packageHistoryShare: {
    styling: {
      style: { background: '#F0F2F5' },
      layoutStyle: {},
      className: '',
      variant: {}
    },

    config: {
      type: 'package',
      shareURL: '',
      linkURL: '/share/packages',
      shareQueryParams: {
        resource_type: 'Shipment',
        resource_id: ''
      },
      showTimezone: true,
      subscribeURL: `${baseURL}/api/v3/trip/subscribe/`,
      summaryURL: `${baseURL}/api/v3/share_trips/packages/`,
      otherDetailsURL: {
        url: `${baseURL}/api/v3/share_trips/packages/`,
        pathParam: '/package_data'
      },
      links: { shipmentShare: '/shipment' },
      tabsComponent: {
        summary: {
          label: 'Summary',
          showMap: true,
          component: 'summary'
        },
        exceptions: {
          label: 'Exceptions',
          showMap: true,
          component: 'exceptions'
        },
        stoppages: {
          label: 'Stoppages',
          showMap: true,
          component: 'stoppages'
        },
        history: {
          label: 'History',
          showMap: true,
          component: 'history'
        },
        transporterDetails: {
          label: 'Transporter Details',
          showMap: false,
          component: 'transporterDetails'
        },
        itemDetails: {
          label: 'Item Details',
          showMap: false,
          component: 'itemDetails'
        },
        ePOD: {
          label: 'EPOD Details',
          showMap: false,
          component: 'ePOD'
        }
      },
      transporterColumns: {
        road: [
          {
            key: 'shipmentId',
            label: 'Shipment Id'
          },
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'transporterCode',
            label: 'Carrier Code'
          },
          {
            key: 'vehicleNo',
            label: 'Vehicle No.'
          },
          {
            key: 'route',
            label: 'Route'
          },
          {
            key: 'departureTime',
            label: 'STD/ATD'
          },
          {
            key: 'arrivalTime',
            label: 'STA/ATA'
          },
          {
            key: 'loadingInTime',
            label: 'Loading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'loadingOutTime',
            label: 'Loading Out',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingInTime',
            label: 'Unloading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingOutTime',
            label: 'Unloading Out',
            formatter: 'dateFormatter'
          },
          {
            key: 'driverName',
            label: 'Driver Name'
          },
          {
            key: 'routeState',
            label: 'Route State'
          },
          {
            key: 'driverNo',
            label: 'Driver No.'
          }
        ],
        ocean: [
          {
            key: 'shipmentId',
            label: 'Shipment Id'
          },
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'vehicleNo',
            label: 'IMO Code'
          },
          {
            key: 'vesselNo',
            label: 'Voyage No/Vessel No'
          },
          {
            key: 'route',
            label: 'Route'
          },
          {
            key: 'vesselName',
            label: 'Vessel Name'
          },
          {
            key: 'polPrettyName',
            label: 'POL'
          },
          {
            key: 'podPrettyName',
            label: 'POD'
          },
          {
            key: 'transshipment',
            label: 'Transshipment'
          },
          {
            key: 'containerNo',
            label: 'Container No'
          },
          {
            key: 'oceanArrivalTime',
            label: 'STA/ATA'
          },
          {
            key: 'oceanDepartureTime',
            label: 'STD/ATD'
          },
          {
            key: 'cll',
            label: 'CLL',
            formatter: 'dateFormatter'
          },
          {
            key: 'cdd',
            label: 'CDD',
            formatter: 'dateFormatter'
          },
          {
            key: 'routeState',
            label: 'Route State'
          }
        ],
        air: [
          {
            key: 'shipmentId',
            label: 'Shipment Id'
          },
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'transporterCode',
            label: 'Carrier Code'
          },
          {
            key: 'vehicleNo',
            label: 'Flight No.'
          },
          {
            key: 'route',
            label: 'Route'
          },
          {
            key: 'departureTime',
            label: 'ETD/ATD'
          },
          {
            key: 'arrivalTime',
            label: 'ETA/ATA'
          },
          {
            key: 'routeState',
            label: 'Route State'
          }
        ],
        rail: [
          {
            key: 'shipmentId',
            label: 'Shipment Id'
          },
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'transporterCode',
            label: 'Carrier Code'
          },
          {
            key: 'vehicleNo',
            label: 'Rake No.'
          },
          {
            key: 'fnrNo',
            label: 'FNR No.'
          },
          {
            key: 'route',
            label: 'Route'
          },
          {
            key: 'departureTime',
            label: 'ETD/ATD'
          },
          {
            key: 'arrivalTime',
            label: 'ETA/ATA'
          },
          {
            key: 'loadingInTime',
            label: 'Loading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'loadingOutTime',
            label: 'Loading Out',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingInTime',
            label: 'Unloading In',
            formatter: 'dateFormatter'
          },
          {
            key: 'unloadingOutTime',
            label: 'Unloading Out',
            formatter: 'dateFormatter'
          },
          {
            key: 'routeState',
            label: 'Route State'
          }
        ]
      },
      currentTransporterColumns: {
        road: [
          {
            key: 'shipmentId',
            label: 'Shipment Id'
          },
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'transporterCode',
            label: 'Carrier Code'
          },
          {
            key: 'vehicleNo',
            label: 'Vehicle No.'
          },
          {
            key: 'route',
            label: 'Route'
          },
          {
            key: 'departureTime',
            label: 'ETD/ATD'
          },
          {
            key: 'arrivalTime',
            label: 'ETA/ATA'
          },
          {
            key: 'driverName',
            label: 'Driver Name'
          },
          {
            key: 'driverPhoneNo',
            label: 'Phone Number'
          }
        ],
        ocean: [
          {
            key: 'shipmentId',
            label: 'Shipment Id'
          },
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'vehicleNo',
            label: 'IMO Code'
          },
          {
            key: 'vesselNo',
            label: 'Voyage No/Vessel No'
          },
          {
            key: 'route',
            label: 'Route'
          },
          {
            key: 'vesselName',
            label: 'Vessel Name'
          },
          {
            key: 'billOfLading',
            label: 'B/L No'
          },
          {
            key: 'polPrettyName',
            label: 'POL',
            main: true
          },
          {
            key: 'podPrettyName',
            label: 'POD',
            main: true
          },
          {
            key: 'transshipment',
            label: 'Transshipment',
            main: true
          },
          {
            key: 'containerNo',
            label: 'Container No',
            main: true
          }
        ],
        air: [
          {
            key: 'shipmentId',
            label: 'Shipment Id'
          },
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'transporterCode',
            label: 'Carrier Code'
          },
          {
            key: 'vehicleNo',
            label: 'Flight No.'
          },
          {
            key: 'route',
            label: 'Route'
          },
          {
            key: 'departureTime',
            label: 'ETD/ATD'
          },
          {
            key: 'arrivalTime',
            label: 'ETA/ATA'
          }
        ],
        rail: [
          {
            key: 'shipmentId',
            label: 'Shipment Id'
          },
          {
            key: 'transporterName',
            label: 'Transporter Name'
          },
          {
            key: 'transporterCode',
            label: 'Carrier Code'
          },
          {
            key: 'vehicleNo',
            label: 'Rake No.'
          },
          {
            key: 'fnrNo',
            label: 'FNR No.'
          },
          {
            key: 'route',
            label: 'Route'
          },
          {
            key: 'departureTime',
            label: 'ETD/ATD'
          },
          {
            key: 'arrivalTime',
            label: 'ETA/ATA'
          }
        ]
      },
      itemColumns: [
        {
          key: 'id',
          label: 'Item Id'
        },
        {
          key: 'material',
          label: 'Product Name'
        },
        {
          key: 'quantity',
          label: 'Quantity'
        },
        {
          key: 'origin',
          label: 'Origin'
        },
        {
          key: 'destination',
          label: 'Destination'
        },
        {
          key: 'consignerName',
          label: 'Consigner'
        },
        {
          key: 'consigneeName',
          label: 'Consignee'
        }
      ],
      portLogColumns: [
        {
          key: 'eventRaw',
          label: 'Events'
        },
        {
          key: 'locationRaw',
          label: 'Location'
        },
        {
          key: 'eventScheduledTime',
          label: 'Scheduled Time',
          formatter: 'dateFormatter'
        },
        {
          key: 'expectedEventTime',
          label: 'Predicted Time',
          formatter: 'dateFormatter'
        },
        {
          key: 'eventTime',
          label: 'Actual Time',
          formatter: 'dateFormatter'
        },
        {
          key: 'eventTimeDiff',
          label: 'Time Difference'
        }
      ],
      delayColumns: [
        {
          key: '',
          label: 'Landmark'
        },
        {
          key: '',
          label: 'Stage'
        },
        {
          key: '',
          label: 'Scheduled Time'
        },
        {
          key: '',
          label: 'Actual Time'
        },
        {
          key: '',
          label: 'Reason'
        },
        {
          key: '',
          label: 'Transporter'
        },
        {
          key: '',
          label: 'Vehicle No.'
        },
        {
          key: '',
          label: 'Time Difference'
        }
      ],
      ePODColumns: [
        {
          key: 'epodName',
          label: 'Location Name'
        },
        {
          key: 'epodTime',
          label: 'Time',
          formatter: 'dateFormatter'
        },
        {
          key: 'epodRemarks',
          label: 'Delivery Remarks'
        },
        {
          key: 'epodFeedback',
          label: 'Feedback'
        },
        {
          key: 'images',
          label: 'Images'
        },
        {
          key: 'signature',
          label: 'Signature'
        }
      ]
    }
  },
  inTransitShare: {
    styling: {
      style: {},
      layoutStyle: { height: 'calc(100vh - 50px)' },
      className: '',
      variant: {}
    },
    shareURL: undefined,
    subscribeURL: undefined,
    tripHistoryURl: `${baseURL}${v2ShareTrips}/transit_history`,
    tripExceptionURL: `${baseURL}${v2ShareTrips}/transit_exceptions`,
    tripPoisURL: `${baseURL}${v2ConsignerTrips}/__slug__/trip_pois`,
    tripPoisUnAuthURL: `${baseURL}/api/v2/share_trips/trip_pois`,
    mapOverInfoToggle: true,
    tripTabs: [
      {
        label: 'Trip Details',
        apiKey: '',
        conditionalField: []
      },
      {
        label: 'Exception',
        apiKey: '',
        conditionalField: []
      },
      {
        label: 'Stoppage',
        apiKey: '',
        conditionalField: []
      },
      {
        label: 'History',
        apiKey: '',
        conditionalField: []
      }
    ]
  },
  inPlantShare: {
    styling: {
      style: {},
      layoutStyle: { height: 'calc(100vh - 50px)' },
      className: '',
      variant: {}
    },
    shareURL: undefined,
    subscribeURL: undefined,
    tripHistoryURl: `${baseURL}${v2ShareTrips}/transit_history`,
    tripExceptionURL: `${baseURL}${v2ShareTrips}/transit_exceptions`,
    tripPoisURL: `${baseURL}${v2ConsignerTrips}/__slug__/trip_pois`,
    mapOverInfoToggle: true,
    tripTabs: [
      {
        label: 'Trip Details',
        apiKey: '',
        conditionalField: []
      },
      {
        label: 'Exception',
        apiKey: '',
        conditionalField: []
      },
      {
        label: 'Stoppage',
        apiKey: '',
        conditionalField: []
      },
      {
        label: 'History',
        apiKey: '',
        conditionalField: []
      }
    ]
  }
}

export default TripDetailsRenderingConfig
