import {
  Button,
  Card,
  components,
  Flex,
  Label,
  SearchSelect,
  Span
} from '@fareye/ui'
import React from 'react'
import { TIMEZONE_KEY } from '../../config/constants'
import data from './timezone.json'

const Option = (props: any) => (
  <div>
    <components.Option {...props}>
      <Flex justifyContentSpaceBetween style={{ width: '100%' }}>
        <Label>{props?.data?.abbr.toUpperCase() || props?.data?.key}</Label>
        <Label>{props?.data?.text}</Label>
        <Label>{`${props?.data?.offset} hrs`}</Label>
      </Flex>
    </components.Option>
  </div>
)
const SingleValue = ({ children, ...props }: any) => (
  <components.SingleValue {...props}>
    <Span
      style={{
        padding: 5,
        borderRadius: 5,
        color: 'white',
        backgroundColor: '#2684FF',
        display: 'flex',
        fontWeight: 600,
        fontSize: '14px'
      }}
    >
      {props?.data?.text}
    </Span>
  </components.SingleValue>
)
export default function TimeZoneList({ state, setState, setToggleModal }: any) {
  const recordsToShow = 8
  let flag = 0
  const [selectedTimezone, setSelectedTimezone] = React.useState(state)
  function handleSubmit(selectedTimezone: any) {
    let data = {
      id: 'others',
      text: selectedTimezone.value,
      value: selectedTimezone.utc[0],
      displayText: selectedTimezone.utc[0]
    }
    setState(data)
    setToggleModal(false)
    localStorage.setItem(TIMEZONE_KEY, JSON.stringify(data))
  }
  const header = () => {
    return <Label p={3}>Select Timezone</Label>
  }
  const footer = () => {
    return (
      <Flex p={2} justifyContentFlexEnd>
        <Button
          variant="primary"
          onClick={() => handleSubmit(selectedTimezone)}
        >
          Submit
        </Button>
      </Flex>
    )
  }
  return (
    <Card
      bodyStyle={{
        minHeight: '300px',
        backgroundColor: '#FBFBFB'
      }}
      variant="m"
      header={header()}
      footer={footer()}
    >
      <SearchSelect
        value={selectedTimezone}
        components={{
          Option,
          SingleValue,
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null
        }}
        filterOption={({ label }, query) => {
          return label
            ? label.toLowerCase().indexOf(query.toLowerCase()) >= 0 &&
                flag++ < recordsToShow
            : false
        }}
        getOptionLabel={ele => ele.value}
        getOptionValue={ele => ele.value}
        onInputChange={() => {
          flag = 0
        }}
        menuIsOpen={true}
        options={data}
        onChange={data => setSelectedTimezone(data)}
        placeholder="Please Select a Timezone"
        isMulti={false}
      ></SearchSelect>
    </Card>
  )
}
