import React from 'react'
import styled from 'styled-components'
import { layout, Props } from '../Theme/styledSystem'
import { INewCardProps } from '../utility/types'

const StyledCard: any = styled.div`
  ${(props: INewCardProps) =>
    props.theme.card[props.variant || props.parentVariant || 'm'].frame}
  ${layout}
`
const Divider = styled.div`
  ${(props: INewCardProps) =>
    props.theme.card[props.variant || props.parentVariant || 'm'].divider}
  ${layout}
`
const StyledHeader: any = styled.div`
  ${(props: INewCardProps) =>
    props.theme.card[props.variant || props.parentVariant || 'm'].header}
  ${layout}
`
const StyledBody: any = styled.div`
  ${(props: INewCardProps) =>
    props.theme.card[props.variant || props.parentVariant || 'm'].body}
  ${layout}
`
const StyledFooter: any = styled.div`
  ${(props: INewCardProps) =>
    props.theme.card[props.variant || props.parentVariant || 'm'].footer}
  ${layout}
`

const Header = (props: INewCardProps) => (
  <>
    {typeof props.children === 'string' ? (
      <StyledHeader {...props}>{props.children}</StyledHeader>
    ) : (
      props.children
    )}
    <Divider className="feui-divider-header" />
  </>
)

const Body = (props: INewCardProps) =>
  typeof props.children === 'string' ? (
    <StyledBody {...props}>{props.children}</StyledBody>
  ) : (
    props.children
  )

const Footer = (props: INewCardProps) => (
  <>
    <Divider className="feui-divider-footer" />
    {typeof props.children === 'string' ? (
      <StyledFooter {...props}>{props.children}</StyledFooter>
    ) : (
      props.children
    )}
  </>
)

const Card = (props: INewCardProps) => {
  let { children, variant } = Props(props)
  const childrenWithProps = React.Children.map(children, (child: any) =>
    React.cloneElement(child, { parentVariant: variant })
  )
  return <StyledCard {...Props(props)}>{childrenWithProps}</StyledCard>
}

const NewCard: any = Card
NewCard.Header = Header
NewCard.Footer = Footer
NewCard.Body = Body

export default NewCard
