import environment from '../../environment'
const { baseURL } = environment

export const POIRenderingConfig: any = {
  poi: {
    globalSearchPageId: 'poi',
    filters: {
      apiFilter: {
        apiURL: undefined,
        queryParams: {},
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: undefined,
        queryParams: {},
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      apiConfig: {
        apiURL: `${baseURL}/api/v2/pois`,
        queryParams: {}
      }
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      style: {
        flexGrow: 1,
        background: '#F0F2F5'
      },
      className: '',
      variant: {}
    },
    children: {
      poiMap: {
        component: 'poiMap',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v2/pois`,
            queryParams: {}
          }
        },
        styling: {
          style: {
            width: 'calc(100% - 20px)',
            height: 'calc(100% - 20px)',
            position: 'relative',
            boxShadow: '0 0 10px 2px #cecece'
          },
          className: ''
        },
        children: {
          addPoi: {
            component: 'addPoi',
            config: {
              apiConfig: {
                apiURL: `${baseURL}/api/v2/pois`
              },
              variablesFromParent: [
                'mapMode',
                'setMapMode',
                'setCenter',
                'setSearchPoi',
                'allPoisCategory'
              ]
            },
            styling: { style: {}, className: '' },
            children: {}
          },
          poiList: {
            component: 'listPoi',
            config: {
              variablesFromParent: [
                'allPois',
                'mapMode',
                'setZoom',
                'setCenter',
                'onEditPoi',
                'searchPoi',
                'setMapMode',
                'selectedPoi',
                'setSearchPoi',
                'setSelectedPoi',
                'onResetToReadMode'
              ]
            },
            styling: { style: {}, className: '' },
            children: {}
          }
        }
      }
    }
  }
}

export default POIRenderingConfig
