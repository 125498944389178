import React from 'react'
import styled from 'styled-components'
import { Props, layout, splitVariantsToTypes } from '../../Theme/styledSystem'
import { IButtonProps } from '../../utility/types'

const StyledRoundButton: any = styled.button`
  ${(props: IButtonProps) => {
    const variant = splitVariantsToTypes(props)
    const style = {
      ...props.theme.buttons[variant.display],
      ...props.theme.buttons[variant.display][`round-${variant.size}`],
      ...(props.disabled
        ? {
            backgroundColor: props.theme.colors.gray5,
            pointerEvents: 'none',
            color: ''
          }
        : {})
    }
    return style
  }}
  ${layout}
`

const RoundButton = (props: IButtonProps) => {
  let { children, style, className, disabledStyle, onClick } = Props(props)
  style = onclick ? { ...style, ...disabledStyle } : { ...style }

  return (
    <StyledRoundButton
      onClick={onClick}
      className={className || ''}
      style={style}
      {...props}
    >
      {children}
    </StyledRoundButton>
  )
}
export default RoundButton
