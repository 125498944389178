import React from 'react'
import { InputLabel } from '../styled/styled'
import { InfoIcon } from '@fareye/ui'
interface ICustomLabel {
  style?: React.CSSProperties
  label: string
  desc?: string
}
export default function CustomLabel({ style, label, desc }: ICustomLabel) {
  return (
    <div style={{ marginBottom: '4px' }}>
      <InputLabel variant="h6" style={style}>
        {label}
      </InputLabel>
      {desc && (
        <InfoIcon
          style={{ marginLeft: 5, marginRight: 5, cursor: 'pointer' }}
          title={desc}
        />
      )}
    </div>
  )
}
