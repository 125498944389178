import { IChildFilterRenderingConfig } from '@fareye/settings'
import environment from '../../environment'

let { baseURL: baseUrl } = environment
//baseUrl = 'http://localhost:3001'

export const ChildFilterRenderingConfig: IChildFilterRenderingConfig = {
  config: {},
  styling: {},
  children: {
    listChildUser: {
      label: 'Child User Listing',
      config: {
        children: {
          listChildUser: {
            apiConfig: {
              listChildUser: {
                apiUrl: `${baseUrl}/api/v1/childs`
              }
            },
            selectedColumns: [
              'name',
              'email',
              'primaryPhoneNo',
              'state',
              'action'
            ]
          },
          addChildUser: {
            apiConfig: {
              addChildUser: {
                apiUrl: `${baseUrl}/api/v1/childs`
              }
            },
            formRenderingConfig: {
              label: 'Child User',
              formKey: 'addChildUser',
              options: {},
              desc: 'Use this form to create Child User with filters',

              styles: {},
              formConfig: {
                '1': {
                  data: [
                    {
                      formKey: 'firstName',
                      validation: 'required',
                      label: 'First Name',
                      type: 'text',
                      placeholder: 'Enter First name',
                      row: 1,
                      styling: { variant: { width: [1, 1 / 2, 1 / 4] } },
                      dependency: null,
                      options: null
                    },
                    {
                      formKey: 'lastName',
                      validation: 'required',
                      label: 'Last Name',
                      type: 'text',
                      placeholder: 'Enter Last Name',
                      row: 1,
                      styling: { variant: { width: [1, 1 / 2, 1 / 4] } },
                      dependency: null,
                      options: null
                    },
                    {
                      formKey: 'username',
                      validation: null,
                      label: 'Username',
                      type: 'text',
                      placeholder: 'Enter UserName',
                      row: 1,
                      styling: { variant: { width: [1, 1 / 2, 1 / 4] } },
                      dependency: null,
                      options: null
                    },
                    {
                      formKey: 'email',
                      validation: 'email',
                      label: 'Email',
                      type: 'text',
                      placeholder: 'Enter email',
                      row: 1,
                      styling: { variant: { width: [1, 1 / 2, 1 / 4] } },
                      dependency: null,
                      options: null
                    }
                  ],
                  style: {}
                },
                '2': {
                  data: [
                    {
                      formKey: 'primaryPhoneNo',
                      validation: 'phone',
                      label: 'Phone No',
                      type: 'number',
                      placeholder: 'Enter Phone No.',
                      row: 1,
                      styling: { variant: { width: [1, 1 / 2, 1 / 4] } },
                      dependency: null,
                      options: null
                    },
                    {
                      formKey: 'password',
                      validation: 'required',
                      label: 'Password',
                      type: 'password',
                      placeholder: 'Enter Password',
                      row: 1,
                      styling: { variant: { width: [1, 1 / 2, 1 / 4] } },
                      dependency: null,
                      options: null
                    },
                    {
                      formKey: 'passwordConfirmation',
                      validation: 'required',
                      label: 'Confirmed Password',
                      type: 'password',
                      placeholder: 'Enter Confirmed Password',
                      row: 1,
                      styling: { variant: { width: [1, 1 / 2, 1 / 4] } },
                      dependency: null,
                      options: null
                    },
                    {
                      formKey: 'state',
                      validation: 'required',
                      label: 'State',
                      type: 'select',
                      placeholder: 'Select',
                      options: [
                        {
                          value: 'active',
                          label: 'Active'
                        },
                        {
                          value: 'inactive',
                          label: 'InActive'
                        }
                      ],
                      styling: { variant: { width: [1, 1 / 2, 1 / 4] } },
                      isMulti: false,
                      row: 1,
                      styles: {},
                      dependency: null
                    }
                  ],
                  style: {}
                },

                '3': {
                  data: [
                    {
                      formKey: 'roleIds',
                      validation: null,
                      label: 'Role',
                      type: 'local-select',
                      placeholder: 'Select',
                      isMulti: true,
                      labelForDisplay: 'name',
                      valueForDisplay: 'id',
                      apiUrl: '/api/v1/childs/get_roles',
                      options: null,
                      dependency: null,
                      styling: { variant: { width: [1, 1 / 2, 1 / 3] } },
                      searchQueryKey: 'roles'
                    },
                    {
                      formKey: 'configFilterIds',
                      validation: null,
                      label: 'Filters',
                      type: 'local-select',
                      placeholder: 'Select',
                      isMulti: false,
                      labelForDisplay: 'name',
                      valueForDisplay: 'id',
                      apiUrl: '/api/v1/config_filters',
                      options: null,
                      dependency: null,
                      styling: { variant: { width: [1, 1 / 2, 1 / 3] } },
                      searchQueryKey: 'filters'
                    },
                    {
                      formKey: 'userTimezone',
                      validation: 'required',
                      label: 'User Timezone',
                      type: 'local-select',
                      placeholder: 'Select',
                      isMulti: false,
                      labelForDisplay: 'label',
                      valueForDisplay: 'value',
                      apiUrl: '/api/v1/timezones',
                      options: null,
                      dependency: null,
                      styling: { variant: { width: [1, 1 / 2, 1 / 3] } },
                      searchQueryKey: 'timezones'
                    }
                  ],
                  style: {}
                }
              },
              btnConfig: {
                submitButton: {
                  label: 'Submit',
                  style: {},
                  isDisplay: true
                },
                resetButton: {
                  isDisplay: true,
                  label: 'Reset',
                  style: {}
                }
              }
            }
          },
          editChildUser: {
            apiConfig: {
              editChildUser: {
                apiUrl: `${baseUrl}/api/v1/childs`
              }
            },
            formRenderingConfig: {
              label: 'Child User',
              formKey: 'editChildUser',
              options: {},
              desc: 'Used this form to Edit Child User with filters',

              styles: {},
              formConfig: {
                '1': {
                  data: [
                    {
                      formKey: 'firstName',
                      validation: 'required',
                      label: 'First Name',
                      type: 'text',
                      placeholder: 'Enter First name',
                      row: 1,
                      styling: { variant: { width: [1, 1 / 2, 1 / 4] } },
                      dependency: null,
                      options: null
                    },
                    {
                      formKey: 'lastName',
                      validation: 'required',
                      label: 'Last Name',
                      type: 'text',
                      placeholder: 'Enter Last Name',
                      row: 1,
                      styling: { variant: { width: [1, 1 / 2, 1 / 4] } },
                      dependency: null,
                      options: null
                    },
                    {
                      formKey: 'username',
                      validation: null,
                      label: 'Username',
                      type: 'text',
                      placeholder: 'Enter UserName',
                      row: 1,
                      styling: { variant: { width: [1, 1 / 2, 1 / 4] } },
                      dependency: null,
                      options: null
                    },
                    {
                      formKey: 'email',
                      validation: 'email',
                      label: 'Email',
                      type: 'text',
                      placeholder: 'Enter email',
                      styling: { variant: { width: [1, 1 / 2, 1 / 4] } },
                      row: 1,
                      dependency: null,
                      options: null
                    }
                  ],
                  style: {}
                },
                '2': {
                  data: [
                    {
                      formKey: 'state',
                      validation: 'required',
                      label: 'State',
                      type: 'select',
                      placeholder: 'Select',
                      options: [
                        {
                          value: 'active',
                          label: 'Active'
                        },
                        {
                          value: 'inactive',
                          label: 'InActive'
                        }
                      ],
                      isMulti: false,
                      row: 1,
                      styling: { variant: { width: [1, 1 / 2, 1 / 3] } },
                      dependency: null
                    },
                    {
                      formKey: 'roleIds',
                      validation: null,
                      label: 'Role',
                      type: 'local-select',
                      placeholder: 'Select',
                      isMulti: true,
                      labelForDisplay: 'name',
                      valueForDisplay: 'id',
                      apiUrl: '/api/v1/childs/get_roles',
                      options: null,
                      dependency: null,
                      styling: { variant: { width: [1, 1 / 2, 1 / 3] } },
                      searchQueryKey: 'roles'
                    },
                    {
                      formKey: 'configFilterIds',
                      validation: null,
                      label: 'Filters',
                      type: 'local-select',
                      placeholder: 'Select',
                      isMulti: false,
                      labelForDisplay: 'name',
                      valueForDisplay: 'id',
                      apiUrl: '/api/v1/config_filters',
                      options: null,
                      dependency: null,
                      styling: { variant: { width: [1, 1 / 2, 1 / 3] } },

                      searchQueryKey: 'filters'
                    }
                  ],
                  style: {}
                },

                '3': {
                  data: [
                    {
                      formKey: 'userTimezone',
                      validation: 'required',
                      label: 'User Timezone',
                      type: 'local-select',
                      placeholder: 'Select',
                      isMulti: false,
                      labelForDisplay: 'label',
                      valueForDisplay: 'value',
                      apiUrl: '/api/v1/timezones',
                      options: null,
                      dependency: null,
                      styling: { variant: { width: [1, 1 / 2, 1 / 3] } },
                      searchQueryKey: 'timezones'
                    }
                  ],
                  style: {}
                }
              },
              btnConfig: {
                submitButton: {
                  label: 'Update',
                  style: {},
                  isDisplay: true
                },
                resetButton: {
                  isDisplay: true,
                  label: 'Reset',
                  style: {}
                }
              }
            }
          }
        }
      },
      id: 'listChildUser',
      styling: {
        bodyBgColor: '#F0F2F5',
        style: {
          padding: '0px',
          width: '100%',
          overflow: 'hidden',
          boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
        },
        className: '',
        variant: {
          gridGap: '5px'
        }
      }
    },

    listChildFilter: {
      label: 'Filter Listing',
      config: {
        children: {
          listFilter: {
            apiConfig: {
              listFilter: {
                apiUrl: `${baseUrl}/api/v1/config_filters`
              }
            },
            selectedColumns: ['filterName', 'type', 'action']
          },
          editFilter: {
            apiConfig: {
              editFilter: {
                apiUrl: `${baseUrl}/api/v1/config_filters`
              },
              getModels: {
                apiUrl: `${baseUrl}/api/v1/config_filters/get_models_and_attributes`
              },

              getValues: {
                apiUrl: `${baseUrl}/api/v1/config_filters/get_distinct_values`
              }
            }
          },
          addFilter: {
            apiConfig: {
              addFilter: {
                apiUrl: `${baseUrl}/api/v1/config_filters`
              },
              getModels: {
                apiUrl: `${baseUrl}/api/v1/config_filters/get_models_and_attributes`
              },
              getValues: {
                apiUrl: `${baseUrl}/api/v1/config_filters/get_distinct_values`
              }
            }
          }
        }
      },
      id: 'listChildFilter',
      styling: {
        bodyBgColor: '#F0F2F5',
        style: {
          padding: '0px',
          width: '100%',
          overflow: 'hidden',
          boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
        },
        className: '',
        variant: {
          gridGap: '5px'
        }
      }
    }
  },
  baseUrl: baseUrl
}
