export const getSearchFilterFromSession = (pageId: string) => {
  const filterSessionData = JSON.parse(
    sessionStorage.getItem('globalSearch') || '{"selectedFilter": []}'
  )
  if (filterSessionData.pageId === pageId) {
    return filterSessionData.selectedFilter
  } else {
    return []
  }
}

type THEADERKeys = 'HEADER_INFLECTION' | 'AUTHORIZATION'

export interface IHEADER {
  [key: string]: any
}

export const HEADERS = () => {
  return {
    HEADER_INFLECTION: {
      'Key-Inflection': 'camel',
      'X-Change-Case': true
    },
    AUTHORIZATION: `Bearer ${getLocalStorage().token || ''}`
  }
}

export const getLocalStorage = () => {
  return JSON.parse(localStorage.getItem('@Fareye:AuthToken') || '{}')
}

export function getUserType() {
  return getLocalStorage().userRole || 'child'
}

export const getuser = () => ({
  label: getLocalStorage().name,
  value: getLocalStorage().email
})

export const getOverrideUser = () => localStorage.getItem('jsonstore_user')

export const isJourneyModel = () => getLocalStorage().isJourneyModel as boolean
