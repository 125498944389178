import { Button, Span } from '@fareye/ui'
import { MdStarBorder } from '@fareye/utils'
import React from 'react'
import { saveFavouriteButtonStyle } from '../../../style/styled'
import { ISaveFavourite } from '../../../utility/types'

function SaveFavourites({ setShowAddFavModal }: ISaveFavourite) {
  return (
    <Button
      leftChild={<MdStarBorder style={{ marginRight: 8 }} size={14} />}
      onClick={() => setShowAddFavModal(true)}
      style={saveFavouriteButtonStyle}
    >
      Save this Search
    </Button>
  )
}

export default SaveFavourites
