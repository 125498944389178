import React from 'react'
import { RenderControl, IRenderControl } from '@fareye/ui'
import ReportSettings from '.'
import { IMapper, useMapper, TimeZoneProvider } from '@fareye/common'
import version from '../../../config/npmVersions'

export default function ReportMapper(props: IMapper) {
  const [apiData, _] = useMapper({ ...props, version })
  return (
    <TimeZoneProvider>
      <RenderControl variant="m" state={apiData as IRenderControl}>
        <ReportSettings {...props} config={(apiData as IRenderControl).data} />
      </RenderControl>
    </TimeZoneProvider>
  )
}
