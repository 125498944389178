import { useWindowDimensions } from '@fareye/common'
import { FaChevronDown } from '@fareye/utils'
import React from 'react'
import { matchPath, withRouter } from 'react-router'
import { mappingComponents } from '../config/mapComponents'
import { Anchor, MenuLevel, SideBarDiv } from '../style/styled'
import { ISidebar } from '../utility/types'

const matchPaths = (link: string, path: string) => {
  //   if (link.includes('kpi') && path === '/') {
  //     return true
  //   }
  return matchPath(path, {
    path: link,
    exact: true,
    strict: true
  })
}

function findActiveRouteusingLoc(routeGroup: any, path: string) {
  var x: any = undefined
  routeGroup.forEach((route: any) => {
    if (matchPaths(route.link, path)) {
      x = route.bLabel
    } else if (route.children?.length && route.children.length) {
      const y = findActiveRouteusingLoc(route.children, path)
      x = y || x
    }
  })
  if (x) {
    return x
  }
}

function SideBar(props: ISidebar) {
  let { routes, menuOpen, history, location, setMenuOpen } = props
  const { width } = useWindowDimensions()
  const [activeMenuItem, setActiveMenuItem] = React.useState(() => {
    return findActiveRouteusingLoc(routes, location.pathname) || ''
  })

  function links(link: string) {
    if (link && !matchPaths(link, location.pathname)) {
      history.push(link)
    }
  }

  const menuLevel = (group: any, level: number) =>
    group.map((item: any, i: number) => {
      const Icon = mappingComponents[item.key]
        ? mappingComponents[item.key].icon
        : ''
      const linkClass = matchPaths(item.link, location.pathname)
        ? 'active-child'
        : ''
      const activeParentLink = location.pathname.includes(item.link)
        ? 'active'
        : ''
      const activeSubMenu =
        activeMenuItem === item.bLabel || location.pathname.includes(item.link)
          ? 'active-sub-menu'
          : ''
      const isAnyChildActive = {
        marginRight: '-140px',
        paddingLeft: 25
      }
      const settings = item.label === 'Settings' ? { marginTop: 'auto' } : {}
      return (
        item.label !== '' && (
          <MenuLevel
            key={i}
            style={settings}
            className={activeMenuItem === item.bLabel ? 'hover' : ''}
          >
            <Anchor
              className={item.children?.length ? activeParentLink : linkClass}
              href={item.children?.length ? '' : item.link}
              menuOpen={menuOpen}
              onClick={(e: any) => {
                e.preventDefault()
                e.stopPropagation()
                links(!item.children?.length && item.link)
                setActiveMenuItem(
                  activeMenuItem === item.bLabel ? '' : item.bLabel
                )
                if (!item.children?.length && width < 769) {
                  setMenuOpen()
                }
              }}
            >
              {Icon && <Icon size={'15px'} className="icon" />}
              <span style={{ color: '#fff' }} key={i}>
                {item.label}
              </span>
              {item.children?.length ? (
                <FaChevronDown className="chevron" />
              ) : null}
            </Anchor>
            {item.children?.length ? (
              <div className={activeSubMenu} style={isAnyChildActive}>
                {menuLevel(item.children, level + 1)}
              </div>
            ) : null}
          </MenuLevel>
        )
      )
    })

  const hideTopNavigation = new URL(document.location.href).searchParams.get(
    'disableNav'
  )
  if (hideTopNavigation) {
    return null
  }
  return (
    <SideBarDiv column menuOpen={menuOpen} data-testid="app-sidebar">
      {menuLevel(routes, 1)}
    </SideBarDiv>
  )
}

export default withRouter(SideBar)
