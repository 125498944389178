import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #727272;
  transition: border 0.24s ease-in-out;
`

type FilePickerProps = {
  accept: string
  onError: (error: any) => void
  onFileDrop: (res: any) => void
  icon?: JSX.Element
  title?: string
  label?: string
  multiple?: boolean
  style?: {
    containerStyle?: React.CSSProperties
  }
  maxSize?: number
  maxFiles?: number
  validator?: (item?: any) => void
  onFileUpload?: (input: any) => void
}

export function FilePicker({
  icon,
  title,
  label,
  style,
  accept,
  maxSize = 5 * 1024 * 1024, // 5MB
  maxFiles,
  multiple = false,
  onError,
  validator,
  onFileDrop,
  onFileUpload
}: FilePickerProps) {
  const maxFilesCount = multiple ? maxFiles || 5 : 1
  const dropZoneOptions: any = {
    maxSize,
    maxFiles: maxFilesCount,
    validator,
    multiple,
    onDrop: onFileDrop
  }
  if (accept) {
    dropZoneOptions.accept = accept
  }

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone(dropZoneOptions)

  React.useEffect(() => {
    if (onFileUpload) {
      onFileUpload(acceptedFiles)
    }
  }, [acceptedFiles])

  React.useEffect(() => {
    onError(fileRejections)
  }, [fileRejections])

  return (
    <Container
      style={style?.containerStyle || {}}
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
    >
      <input {...getInputProps()} />
      <div title={title}>
        {icon || ''}
        {`${label || ''}`}
      </div>
    </Container>
  )
}
