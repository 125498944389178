import lazy from '../utility/lazy'
import { IRouteConfig } from '@fareye/types'
import TripDetailsRenderingConfig from './LayoutRenderingConfig/TripDetailsRenderingConfig'
import BlankImage from 'components/BlankImage'

const ConfigManagement = lazy(
  () => import('@fareye/settings'),
  'ConfigManagement'
)
const ReportsConfiguration = lazy(
  () => import('@fareye/jsonstore-ui'),
  'ReportsConfiguration'
)

const TripDetails = lazy(() => import('@fareye/trip-details'), 'TripDetails')
const MultiModelHistory = lazy(
  () => import('@fareye/trip-details'),
  'MultiModelHistory'
)

// Leave Label Empty If you don't want it in SideBar Item
// layout empty array if you don't want sidebar and header
// Do Not put sub routes inside children key if parent doesnot need submenu, keep subpaths on same level inside json

// export const routesConfig: IRouteConfig[] = [
//   //   {
//   //     authenticated: false,
//   //     bLabel: '',
//   //     children: undefined,
//   //     exact: true,
//   //     key: 'login',
//   //     label: '',
//   //     layout: [],
//   //     link: '/login',
//   //     path: '/login'
//   //   },
//   {
//     authenticated: true,
//     bLabel: '',
//     children: undefined,
//     exact: true,
//     key: 'multiModelListing',
//     label: '',
//     layout: ['header', 'sidebar'],
//     link: '/',
//     path: '/'
//   },
//   {
//     authenticated: true,
//     bLabel: 'Multi Modal',
//     children: [
//       {
//         authenticated: true,
//         bLabel: 'E.T.A',
//         children: undefined,
//         exact: true,
//         key: 'multiModelListingEta',
//         label: 'E.T.A',
//         layout: ['header', 'sidebar'],
//         link: '/',
//         path: '/',
//         subType: 'intransit'
//         // pageId: 'multiModelListing'
//       },
//       {
//         authenticated: true,
//         bLabel: 'InTransit Performance',
//         children: undefined,
//         exact: true,
//         key: 'routePerformance',
//         label: 'InTransit Performance',
//         layout: ['header', 'sidebar'],
//         link: '/in-transit',
//         path: '/in-transit',
//         subType: 'intransit',
//         pageId: 'intransitPerformance'
//       },
//       {
//         authenticated: true,
//         bLabel: 'InTransit Performance',
//         children: undefined,
//         exact: true,
//         key: 'containerListing',
//         label: 'Container Listing',
//         layout: ['header', 'sidebar'],
//         link: '/container',
//         path: '/container',
//         subType: 'intransit',
//         pageId: 'containerListing'
//       },
//       {
//         authenticated: true,
//         bLabel: '',
//         children: undefined,
//         exact: true,
//         key: 'routeDetail',
//         label: '',
//         layout: ['header', 'sidebar'],
//         link: '/in-transit/package',
//         path: '/in-transit/package/:route',
//         subType: 'intransit',
//         pageId: 'routeDetail'
//       },
//       {
//         authenticated: true,
//         bLabel: '',
//         children: undefined,
//         exact: true,
//         key: 'routeDetailShipment',
//         label: '',
//         layout: ['header', 'sidebar'],
//         link: '/in-transit/shipment',
//         path: '/in-transit/shipment/:route',
//         subType: 'intransit',
//         pageId: 'routeDetailShipment'
//       },
//       {
//         authenticated: true,
//         bLabel: 'Transporters',
//         children: undefined,
//         exact: true,
//         key: 'transporterPerformance',
//         label: '',
//         layout: ['header', 'sidebar'],
//         link: '/in-transit/transporters',
//         path: '/in-transit/transporters',
//         subType: 'intransit',
//         pageId: 'intransitTransporterPerformance'
//       },
//       {
//         authenticated: true,
//         bLabel: '',
//         children: undefined,
//         exact: true,
//         key: 'multiModalTransporterDetail',
//         label: '',
//         layout: ['header', 'sidebar'],
//         link: '/in-transit/transporters/shipment',
//         path: '/in-transit/transporters/shipment/:transporter',
//         subType: 'intransit',
//         pageId: 'multiModalTransporterDetail'
//       },
//       {
//         authenticated: true,
//         bLabel: 'InTransit Map',
//         children: undefined,
//         exact: true,
//         key: 'intransitMapOcean',
//         label: 'InTransit Map',
//         layout: ['header', 'sidebar'],
//         link: '/in-transit-map',
//         path: '/in-transit-map',
//         subType: 'intransit',
//         pageId: 'intransitMap'
//       }
//     ],
//     exact: true,
//     key: 'multiModelListing',
//     label: 'Intransit Visibility',
//     layout: ['header', 'sidebar'],
//     link: '/',
//     path: '/'
//   },
//   {
//     authenticated: true,
//     bLabel: 'Multi Modal',
//     children: undefined,
//     exact: true,
//     key: 'packageHistory',
//     label: '',
//     layout: ['header', 'sidebar'],
//     link: '/packages/:slug',
//     path: '/packages/:slug'
//   },
//   {
//     authenticated: true,
//     bLabel: 'Multi Model',
//     children: undefined,
//     exact: true,
//     key: 'shipmentHistory',
//     label: '',
//     layout: ['header', 'sidebar'],
//     link: '/shipment/:slug',
//     path: '/shipment/:slug'
//   },
//   //   {
//   //     authenticated: true,
//   //     bLabel: 'Home',
//   //     children: undefined,
//   //     exact: true,
//   //     key: 'overview',
//   //     label: 'Home',
//   //     layout: ['header', 'sidebar'],
//   //     link: '/',
//   //     path: '/'
//   //   },
//   //   {
//   //     authenticated: true,
//   //     bLabel: 'KPI Playground',
//   //     children: undefined,
//   //     exact: true,
//   //     key: 'kpiConfigure',
//   //     label: '',
//   //     layout: ['header', 'sidebar'],
//   //     link: '/kpi/editor',
//   //     path: '/kpi/editor'
//   //   },
//   {
//     authenticated: true,
//     bLabel: 'KPI',
//     children: undefined,
//     exact: true,
//     key: 'multiModalKpi',
//     label: 'KPI',
//     layout: ['header', 'sidebar'],
//     link: '/kpi',
//     path: '/kpi'
//   },
//   {
//     authenticated: true,
//     bLabel: 'Ocean Stats',
//     children: undefined,
//     exact: true,
//     key: 'oceanStats',
//     label: 'Ocean Stats',
//     layout: ['header', 'sidebar'],
//     link: '/ocean-stats',
//     path: '/ocean-stats'
//   },
//   //   {
//   //     authenticated: true,
//   //     bLabel: 'In Plant Performance',
//   //     children: [
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'In Plant path',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'inplantPerformance',
//   //         label: 'In Plant Performance',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/in-plant',
//   //         path: '/in-plant',
//   //         subType: 'inplant',
//   //         pageId: 'inplantPerformance'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'In Plant Map',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'inplantMap',
//   //         label: 'In Plant Map',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/in-plant/map',
//   //         path: '/in-plant/map',
//   //         subType: 'inplant',
//   //         pageId: 'inplantMap'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: '',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'inPlantTripDetails',
//   //         label: '',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/in-plant/trips',
//   //         path: '/in-plant/trips/:id',
//   //         subType: 'inplant',
//   //         pageId: 'inPlantTripDetails'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: '',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'inplantDetails',
//   //         label: '',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/in-plant',
//   //         path: '/in-plant/:plant',
//   //         subType: 'inplant',
//   //         pageId: 'inplantDetails'
//   //       },
//   //       {
//   //         authenticated: false,
//   //         bLabel: '',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'inplantTripDetailsShare',
//   //         label: '',
//   //         layout: [],
//   //         link: '/share/in-plant/trips',
//   //         path: '/share/in-plant/trips/:id',
//   //         subType: 'inplant',
//   //         pageId: 'inplantTripDetailsShare'
//   //       }
//   //     ],
//   //     exact: true,
//   //     key: 'inplant',
//   //     label: 'In Plant',
//   //     layout: ['header', 'sidebar'],
//   //     link: '/in-plant',
//   //     path: '/in-plant'
//   //   },
//   //   {
//   //     authenticated: true,
//   //     bLabel: 'InTransit',
//   //     children: [
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'InTransit Performance',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'intransitPerformance',
//   //         label: 'InTransit Performance',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/in-transit',
//   //         path: '/in-transit',
//   //         subType: 'intransit',
//   //         pageId: 'intransitPerformance'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'Transporters',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'intransitTransporterPerformance',
//   //         label: '',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/in-transit/transporters',
//   //         path: '/in-transit/transporters',
//   //         subType: 'intransit',
//   //         pageId: 'intransitTransporterPerformance'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'Transporters Performance',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'transporterDetails',
//   //         label: '',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/in-transit/transporters',
//   //         path: '/in-transit/transporters/:transporter',
//   //         subType: 'intransit',
//   //         pageId: 'transporterDetails'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'E. T. A',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'eta',
//   //         label: 'E. T. A',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/in-transit/eta',
//   //         path: '/in-transit/eta',
//   //         subType: 'intransit',
//   //         pageId: 'eta'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'InTransit Map',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'intransitMap',
//   //         label: 'InTransit Map',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/in-transit/map',
//   //         path: '/in-transit/map',
//   //         subType: 'intransit',
//   //         pageId: 'intransitMap'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'InTransit Trips',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'allTrips',
//   //         label: '',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/in-transit/trips',
//   //         path: '/in-transit/trips',
//   //         subType: 'intransit',
//   //         pageId: 'allTrips'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: '',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'intransitTripDetails',
//   //         label: '',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/in-transit/trips',
//   //         path: '/in-transit/trips/:id',
//   //         subType: 'intransit',
//   //         pageId: 'intransitTripDetails'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'InTransit',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'intransitRouteDetails',
//   //         label: '',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/in-transit',
//   //         path: '/in-transit/:id',
//   //         subType: 'intransit',
//   //         pageId: 'intransitRouteDetails'
//   //       },
//   //       {
//   //         authenticated: false,
//   //         bLabel: '',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'intransitTripDetailsShare',
//   //         label: '',
//   //         layout: [],
//   //         link: '/share/in-transit/trips',
//   //         path: '/share/in-transit/trips/:id',
//   //         subType: 'intransit',
//   //         pageId: 'intransitTripDetailsShare'
//   //       }
//   //     ],
//   //     exact: false,
//   //     key: 'intransit',
//   //     label: 'InTransit',
//   //     layout: ['header', 'sidebar'],
//   //     link: '/in-transit',
//   //     path: '/in-transit'
//   //   },
//   //   {
//   //     authenticated: true,
//   //     bLabel: 'Indent',
//   //     children: [
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'Dashboard',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'indentDashboard',
//   //         label: 'Dashboard',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/indent/dashboard',
//   //         path: '/indent/dashboard',
//   //         subType: 'indentKpi'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'Indent List',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'indentList',
//   //         label: 'Indent List',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/indent',
//   //         path: '/indent',
//   //         subType: 'indent',
//   //         pageId: 'indentList'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'Vehicle List',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'indentVehicleList',
//   //         label: 'Vehicle List',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/indent/vehicle',
//   //         path: '/indent/vehicle',
//   //         subType: 'indent',
//   //         pageId: 'indentVehicleList'
//   //       }
//   //     ],
//   //     exact: false,
//   //     key: 'indent',
//   //     label: 'Indent',
//   //     layout: ['header', 'sidebar'],
//   //     link: '/indent',
//   //     path: '/indent'
//   //   },
//   //   {
//   //     authenticated: true,
//   //     bLabel: 'Pending Orders',
//   //     children: [
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'Pending Orders',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'allocationList',
//   //         label: 'Pending Orders',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/pending',
//   //         path: '/pending',
//   //         subType: 'allocation',
//   //         pageId: 'allocationList'
//   //       },
//   //       {
//   //         authenticated: true,
//   //         bLabel: 'Transporters Allocated',
//   //         children: undefined,
//   //         exact: true,
//   //         key: 'transportersAllocated',
//   //         label: '',
//   //         layout: ['header', 'sidebar'],
//   //         link: '/pending/allocate',
//   //         path: '/pending/allocate',
//   //         subType: 'allocation',
//   //         pageId: 'transportersAllocated'
//   //       }
//   //     ],
//   //     exact: false,
//   //     key: 'allocation',
//   //     label: 'Allocation',
//   //     layout: ['header', 'sidebar'],
//   //     link: '/pending',
//   //     path: '/pending'
//   //   },
//   //   {
//   //     authenticated: true,
//   //     bLabel: 'Poi',
//   //     children: undefined,
//   //     exact: true,
//   //     key: 'poi',
//   //     label: 'Poi',
//   //     layout: ['header', 'sidebar'],
//   //     link: '/poi',
//   //     path: '/poi',
//   //     subType: 'poi',
//   //     pageId: 'poi'
//   //   },
//   //   {
//   //     authenticated: true,
//   //     bLabel: 'Nearby',
//   //     children: undefined,
//   //     exact: true,
//   //     key: 'nearby',
//   //     label: 'Nearby',
//   //     layout: ['header', 'sidebar'],
//   //     link: '/nearby',
//   //     path: '/nearby',
//   //     subType: 'nearBy'
//   //   },
//   //   {
//   //     authenticated: true,
//   //     bLabel: 'Reports',
//   //     children: undefined,
//   //     exact: true,
//   //     key: 'reportSettings',
//   //     label: 'Reports',
//   //     layout: ['header', 'sidebar'],
//   //     link: '/report',
//   //     path: '/report',
//   //     subType: 'reports'
//   //   },
//   //   {
//   //     authenticated: true,
//   //     bLabel: 'Ideal Routes',
//   //     children: undefined,
//   //     exact: true,
//   //     key: 'idealRoutes',
//   //     label: 'Ideal Routes',
//   //     layout: ['header', 'sidebar'],
//   //     link: '/ideal-routes',
//   //     path: '/ideal-routes',
//   //     subType: 'idealRoutes'
//   //   },
//   //   {
//   //     authenticated: true,
//   //     bLabel: 'Stoppage Analysis',
//   //     children: undefined,
//   //     exact: true,
//   //     key: 'stoppageAnalysis',
//   //     label: 'Stoppage Analysis',
//   //     layout: ['header', 'sidebar'],
//   //     link: '/stoppage-analysis',
//   //     path: '/stoppage-analysis',
//   //     subType: 'stoppageAnalysis'
//   //   },
//   {
//     authenticated: true,
//     bLabel: 'Alert',
//     children: [
//       {
//         authenticated: true,
//         bLabel: 'Configure Alert',
//         children: undefined,
//         exact: true,
//         key: 'alertSettings',
//         layout: ['header', 'sidebar'],
//         label: 'Configure Alert',
//         link: '/alert',
//         path: '/alert',
//         subType: 'alerts',
//         pageId: 'alertSetting'
//       },
//       {
//         authenticated: true,
//         bLabel: 'Alert Report',
//         children: undefined,
//         exact: true,
//         key: 'alertReport',
//         layout: ['header', 'sidebar'],
//         label: 'Alert Report',
//         link: '/alert/report',
//         path: '/alert/report',
//         subType: 'alerts',
//         pageId: 'alertSetting'
//       }
//     ],
//     exact: false,
//     key: 'alert',
//     label: 'Alert',
//     layout: ['header', 'sidebar'],
//     link: '/alert',
//     path: '/alert'
//   },
//   {
//     authenticated: false,
//     bLabel: '',
//     children: undefined,
//     exact: true,
//     key: 'packageHistoryShare',
//     label: '',
//     layout: [],
//     link: '/share/packages/',
//     path: '/share/packages/:slug',
//     subType: 'intransit',
//     pageId: 'pacakageHistoryShare'
//   },
//   {
//     authenticated: false,
//     bLabel: '',
//     children: undefined,
//     exact: true,
//     key: 'shipmentHistoryShare',
//     label: '',
//     layout: [],
//     link: '/share/shipment/',
//     path: '/share/shipment/:slug',
//     subType: 'intransit',
//     pageId: 'shipmentHistoryShare'
//   }

//   //   {
//   //     authenticated: true,
//   //     bLabel: 'Settings',
//   //     children: undefined,
//   //     exact: true,
//   //     key: 'settings',
//   //     label: 'Settings',
//   //     layout: ['header', 'sidebar'],
//   //     link: '/settings',
//   //     path: '/settings'
//   //   },
//   //   {
//   //     authenticated: true,
//   //     bLabel: 'User Management',
//   //     children: undefined,
//   //     exact: true,
//   //     key: 'childUserFilter',
//   //     layout: ['header', 'sidebar'],
//   //     label: '',
//   //     link: '/settings/user-management',
//   //     path: '/settings/user-management'
//   //   }
// ]

export const routesConfig = [
  {
    authenticated: true,
    bLabel: 'Dashboard',
    children: null,
    exact: true,
    key: 'overview',
    label: 'Dashboard',
    layout: ['header', 'sidebar'],
    link: '/',
    path: '/',
    subType: 'intransit',
    pageId: 'multiModelListing'
  },
  {
    authenticated: true,
    bLabel: 'Multi Modal',
    children: [
      {
        authenticated: true,
        bLabel: 'E.T.A',
        children: null,
        exact: true,
        key: 'multiModelListing',
        label: 'E.T.A',
        layout: ['header', 'sidebar'],
        link: '/in-transit',
        path: '/in-transit',
        subType: 'intransit',
        pageId: 'multiModelListing'
      },
      {
        authenticated: true,
        bLabel: 'All Trips',
        children: null,
        exact: true,
        key: 'journeyAllTrips',
        label: '',
        layout: ['header', 'sidebar'],
        link: '/in-transit/all-trips',
        path: '/in-transit/all-trips',
        subType: 'intransit',
        pageId: 'journeyAllTrips'
      },
      {
        authenticated: true,
        bLabel: 'InTransit Performance',
        children: null,
        exact: true,
        key: 'routePerformance',
        label: 'Performance Card',
        layout: ['header', 'sidebar'],
        link: '/in-transit/route-performance',
        path: '/in-transit/route-performance',
        subType: 'intransit',
        pageId: 'routePerformance'
      },
      {
        authenticated: true,
        bLabel: '',
        children: null,
        exact: true,
        key: 'routeDetail',
        label: '',
        layout: ['header', 'sidebar'],
        link: '/in-transit/route-performance/package',
        path: '/in-transit/route-performance/package/:route',
        subType: 'intransit',
        pageId: 'routeDetail'
      },
      {
        authenticated: true,
        bLabel: '',
        children: null,
        exact: true,
        key: 'routeDetailShipment',
        label: '',
        layout: ['header', 'sidebar'],
        link: '/in-transit/route-performacne/shipment',
        path: '/in-transit/route-performance/shipment/:route',
        subType: 'intransit',
        pageId: 'routeDetailShipment'
      },
      {
        authenticated: true,
        bLabel: 'Transporters',
        children: null,
        exact: true,
        key: 'transporterPerformance',
        label: '',
        layout: ['header', 'sidebar'],
        link: '/in-transit/transporter-performance',
        path: '/in-transit/transporter-performance',
        subType: 'intransit',
        pageId: 'transporterPerformance'
      },
      {
        authenticated: true,
        bLabel: '',
        children: null,
        exact: true,
        key: 'multiModalTransporterDetail',
        label: '',
        layout: ['header', 'sidebar'],
        link: '/in-transit/transporter-performance/shipment',
        path: '/in-transit/transporter-performance/shipment/:transporter',
        subType: 'intransit',
        pageId: 'multiModalTransporterDetail'
      },
      {
        authenticated: true,
        bLabel: 'InTransit Map',
        children: null,
        exact: true,
        key: 'intransitMapOcean',
        label: 'Map',
        layout: ['header', 'sidebar'],
        link: '/in-transit-map',
        path: '/in-transit-map',
        subType: 'intransit',
        pageId: 'intransitMapOcean'
      }
    ],
    exact: true,
    key: 'multiModelParent',
    label: 'Intransit Visibility',
    layout: ['header', 'sidebar'],
    link: '/',
    path: '/'
  },
  {
    authenticated: true,
    bLabel: 'Yard Visibility',
    children: [
      {
        authenticated: true,
        bLabel: 'Yard visibility',
        children: undefined,
        exact: true,
        key: 'inplantPerformance',
        label: 'Yard Performance',
        layout: ['header', 'sidebar'],
        link: '/in-plant',
        path: '/in-plant',
        subType: 'inplant',
        pageId: 'inplantPerformance'
      },
      {
        authenticated: true,
        bLabel: 'Yard Visibility Map',
        children: undefined,
        exact: true,
        key: 'inplantMap',
        label: 'yard Map',
        layout: ['header', 'sidebar'],
        link: '/in-plant/map',
        path: '/in-plant/map',
        subType: 'inplant',
        pageId: 'inplantMap'
      },
      {
        authenticated: true,
        bLabel: '',
        children: undefined,
        exact: true,
        key: 'inPlantTripDetails',
        label: '',
        layout: ['header', 'sidebar'],
        link: '/in-plant/trips',
        path: '/in-plant/trips/:id',
        subType: 'inplant',
        pageId: 'inPlantTripDetails'
      },
      {
        authenticated: true,
        bLabel: '',
        children: undefined,
        exact: true,
        key: 'inplantDetails',
        label: '',
        layout: ['header', 'sidebar'],
        link: '/in-plant',
        path: '/in-plant/:plant',
        subType: 'inplant',
        pageId: 'inplantDetails'
      },
      {
        authenticated: false,
        bLabel: '',
        children: undefined,
        exact: true,
        key: 'inplantTripDetailsShare',
        label: '',
        layout: [],
        link: '/share/in-plant/trips',
        path: '/share/in-plant/trips/:id',
        subType: 'inplant',
        pageId: 'inplantTripDetailsShare'
      }
    ],
    exact: true,
    key: 'inplant',
    label: 'Yard Visibility',
    layout: ['header', 'sidebar'],
    link: '/in-plant',
    path: '/in-plant'
  },
  {
    authenticated: true,
    bLabel: 'Multi Modal',
    children: null,
    exact: true,
    key: 'packageHistory',
    label: '',
    layout: ['header', 'sidebar'],
    link: '/packages/:slug',
    path: '/packages/:slug',
    subType: 'intransit',
    pageId: 'packageHistory'
  },
  {
    authenticated: true,
    bLabel: 'Multi Model',
    children: null,
    exact: true,
    key: 'shipmentHistory',
    label: '',
    layout: ['header', 'sidebar'],
    link: '/shipment/:slug',
    path: '/shipment/:slug',
    subType: 'intransit',
    pageId: 'shipmentHistory'
  },
  {
    authenticated: true,
    bLabel: 'KPI',
    children: [
      {
        authenticated: true,
        bLabel: 'Sustainability ',
        children: null,
        exact: true,
        key: 'sustainabilityKpi',
        layout: ['header', 'sidebar'],
        label: 'Sustainability',
        link: '/kpi/sustainability',
        path: '/kpi/sustainability',
        subType: 'sustainabilityKpi'
      },
      {
        authenticated: true,
        bLabel: 'Yard Performance',
        children: null,
        exact: true,
        key: 'yardKpi',
        layout: ['header', 'sidebar'],
        label: 'Yard Performance',
        link: '/kpi/yard',
        path: '/kpi/yard',
        subType: 'yardKpi'
      },
      {
        authenticated: true,
        bLabel: 'In Transit Performance',
        children: null,
        exact: true,
        key: 'intransitKpi',
        layout: ['header', 'sidebar'],
        label: 'In Transit Performance',
        link: '/kpi/intransit',
        path: '/kpi/intransit',
        subType: 'intransitKpi'
      }
    ],
    exact: true,
    key: 'kpiDashboard',
    label: 'KPI',
    layout: ['header', 'sidebar'],
    link: '/kpi',
    path: '/kpi',
    subType: 'multiModalKpiConfig'
  },
  {
    authenticated: true,
    bLabel: 'Data Quality KPI',
    children: null,
    exact: true,
    key: 'dataQualityKpi',
    layout: ['header', 'sidebar'],
    label: 'Data Quality KPI',
    link: '/data-quality',
    path: '/data-quality',
    subType: 'dataQualityKpi'
  },
  {
    authenticated: true,
    bLabel: 'Reports',
    children: null,
    exact: true,
    key: 'reportSettings',
    label: 'Reports',
    layout: ['header', 'sidebar'],
    link: '/report',
    path: '/report',
    subType: 'journeyReports'
  },
  {
    authenticated: true,
    bLabel: 'Alert',
    children: [
      {
        authenticated: true,
        bLabel: 'Configure Alert',
        children: null,
        exact: true,
        key: 'alertSettings',
        layout: ['header', 'sidebar'],
        label: 'Configure Alert',
        link: '/alert',
        path: '/alert',
        subType: 'journeyAlerts',
        pageId: 'alertSetting'
      },
      {
        authenticated: true,
        bLabel: 'Alert Report',
        children: null,
        exact: true,
        key: 'alertReport',
        layout: ['header', 'sidebar'],
        label: 'Alert Report',
        link: '/alert/report',
        path: '/alert/report',
        subType: 'journeyAlerts',
        pageId: 'alertSetting'
      }
    ],
    exact: false,
    key: 'alert',
    label: 'Alert',
    layout: ['header', 'sidebar'],
    link: '/alert',
    path: '/alert'
  },
  {
    authenticated: true,
    bLabel: 'Poi',
    children: undefined,
    exact: true,
    key: 'poi',
    label: 'Poi',
    layout: ['header', 'sidebar'],
    link: '/poi',
    path: '/poi',
    subType: 'poi',
    pageId: 'poi'
  },
  {
    authenticated: true,
    bLabel: 'Settings',
    children: undefined,
    exact: true,
    key: 'settings',
    label: 'Settings',
    layout: ['header', 'sidebar'],
    link: '/settings',
    path: '/settings'
  },
  {
    authenticated: true,
    bLabel: 'User Management',
    children: undefined,
    exact: true,
    key: 'childUserFilter',
    layout: ['header', 'sidebar'],
    label: '',
    link: '/settings/user-management',
    path: '/settings/user-management'
  },
  {
    authenticated: true,
    bLabel: 'User Utility',
    children: undefined,
    exact: true,
    key: 'utility',
    layout: ['header', 'sidebar'],
    label: '',
    link: '/settings/utility',
    path: '/settings/utility'
  },
  {
    authenticated: true,
    bLabel: 'Profile',
    exact: true,
    key: 'profile',
    label: '',
    layout: ['header', 'sidebar'],
    link: '/profile',
    path: '/profile'
  }
]
export const shareTripRoutes = [
  {
    authenticated: false,
    bLabel: '',
    children: undefined,
    config: TripDetailsRenderingConfig.inTransitShare,
    component: TripDetails,
    exact: true,
    key: 'intransitTripDetailsShare',
    label: '',
    layout: [],
    link: '/share/in-transit/trips',
    path: '/share/in-transit/trips/:id',
    subType: 'intransit',
    pageId: 'intransitTripDetailsShare'
  },
  {
    authenticated: false,
    bLabel: '',
    children: undefined,
    component: MultiModelHistory,
    config: {},
    exact: true,
    key: 'shipmentHistoryShare',
    label: '',
    layout: [],
    link: '/share/shipment',
    path: '/share/shipment/:slug',
    subType: 'intransit',
    pageId: 'shipmentHistoryShare'
  },
  {
    authenticated: false,
    bLabel: '',
    children: undefined,
    component: MultiModelHistory,
    config: {},
    exact: true,
    key: 'packageHistoryShare',
    label: '',
    layout: [],
    link: '/share/packages',
    path: '/share/packages/:slug',
    subType: 'intransit',
    pageId: 'packageHistoryShare'
  },
  {
    authenticated: false,
    bLabel: '',
    children: undefined,
    exact: true,
    config: TripDetailsRenderingConfig.inPlantShare,
    component: TripDetails,
    key: 'inplantTripDetailsShare',
    label: '',
    layout: [],
    link: '/share/in-plant/trips',
    path: '/share/in-plant/trips/:id',
    subType: 'inplant',
    pageId: 'inplantTripDetailsShare'
  },
  {
    authenticated: false,
    bLabel: '',
    children: undefined,
    exact: true,
    config: {},
    component: BlankImage,
    key: 'blankPage',
    label: '',
    layout: [],
    link: '/share/intransit-status/:id',
    path: '/share/intransit-status/:id',
    subType: 'intransit-status',
    pageId: 'intransit-status'
  }
]
export const mustHaveRoutes = [
  {
    authenticated: true,
    bLabel: 'Config Management',
    children: undefined,
    exact: true,
    config: {},
    component: ConfigManagement,
    key: 'configManagement',
    label: '',
    layout: ['header', 'sidebar'],
    link: '/settings/config-management',
    path: '/settings/config-management',
    subType: '',
    pageId: ''
  },
  {
    authenticated: true,
    bLabel: 'Reports Config',
    children: undefined,
    exact: true,
    config: {},
    component: ReportsConfiguration,
    key: 'reportsConfigManagement',
    label: '',
    layout: ['header', 'sidebar'],
    link: '/settings/reports-config',
    path: '/settings/reports-config',
    subType: '',
    pageId: ''
  }
]
