import { useTimeZone } from '@fareye/common'
import { Flex, Loader } from '@fareye/ui'
import React, { Suspense } from 'react'
import ErrorBoundary from '../../components/ErrorBoundary'
import Header from '../../components/Header'
import SideBar from '../../components/Sidebar'
import { mappingComponents } from '../../config/mapComponents'
import { PortalDiv } from '../../style/styled'
import { IAppLayout } from '../../utility/types'

const Layout: any = (props: IAppLayout) => {
  const { route, routesConfig, menuOpen, setMenuOpen } = props
  const { state } = useTimeZone()
  const RouteComponent: any = route.component
  const embed = new URL(document.location.href).searchParams.get('disableNav')
  return (
    <Flex column style={{ height: '100vh', overflow: 'hidden' }}>
      {route.layout && route.layout.includes('header') && <Header {...props} />}
      <Flex style={{ flexGrow: 1, overflow: 'hidden', position: 'relative' }}>
        {route.layout && route.layout.includes('sidebar') && (
          <SideBar
            routes={routesConfig}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            paramsData={[]}
          />
        )}
        <PortalDiv
          embed={embed}
          hasSidebar={route.layout?.includes('sidebar')}
          menuOpen={menuOpen}
          sidebar={route.layout?.includes('sidebar')}
        >
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <RouteComponent
                config={mappingComponents[route.key].config}
                routePath={route.path}
                timezone={{
                  timeZoneValue: state.value,
                  localScanTime: state.id === 'local'
                }}
                {...props}
              />
            </Suspense>
          </ErrorBoundary>
        </PortalDiv>
      </Flex>
    </Flex>
  )
}

export default Layout
