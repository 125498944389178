import { ConfigGraphData, IApiDataObjectFormat, IKeyValue } from './types'
import { cancelToken, get } from '@fareye/utils'

export function graphIsStacked({
  YAxisKeys,
  data,
  groupByDuration,
  groupByKey,
  xAxisKey
}: ConfigGraphData) {
  if (groupByKey.length === 0) {
    return null
  } else if (groupByDuration === '' && groupByKey.length === 1) {
    return null
  } else if (groupByDuration !== '' && groupByKey.length > 0) {
    return {}
  }
}

export function generateDataForChart(
  data: Partial<IApiDataObjectFormat>[],
  stacked: boolean,
  isBenchmark?: boolean,
  graphType?: string
): { data: IKeyValue<any>[]; yLabelKey: string[]; xLabelKey: string } {
  let yLabelKey: IKeyValue<string> = {}
  let stackedGraphData: IKeyValue<any>[] = []
  if (!stacked) {
    return {
      data,
      xLabelKey: 'label',
      yLabelKey:
        graphType === 'candle-stick' ? ['min', 'max', 'median'] : ['value']
    }
  } else {
    data.forEach(ele => {
      let tempObj: IKeyValue<any> = {}
      tempObj.label = ele.label
      tempObj.value = ele.label
      tempObj.count = {}
      ele.breakDownDimension &&
        ele.breakDownDimension.forEach(dimension => {
          if (isBenchmark) {
            tempObj.count[dimension.label ? dimension.label : ''] =
              dimension.count
          }
          yLabelKey[dimension.label ? dimension.label : ''] = ''
          tempObj[dimension.label ? dimension.label : ''] = dimension.value
        })
      stackedGraphData.push(tempObj)
    })
    return {
      data: stackedGraphData,
      xLabelKey: 'label',
      yLabelKey:
        graphType === 'candle-stick'
          ? ['min', 'max', 'median']
          : Object.keys(yLabelKey)
    }
  }
}

const makeRequestCreator = () => {
  let cancel: any

  return async (query: string) => {
    if (cancel) {
      // Cancel the previous request before making a new request
      cancel.cancel()
    }
    // Create a new CancelToken
    cancel = cancelToken().source()
    try {
      const res = await get(query, cancel.token)

      const result = res.data
      // Store response

      return result
    } catch (error) {
      console.error('Error is')
    }
  }
}

export const search = makeRequestCreator()
