import { Link, styled, Flex, Span, Modal, AutoRefresh } from '@fareye/ui'
import { AiOutlineClockCircle, FaChevronRight, FaCheck } from '@fareye/utils'
import React from 'react'
import { useTimeZone, TimeZoneList, TIMEZONE_KEY } from '@fareye/common'

const localData = [
  {
    id: 'local',
    abbr: 'local',
    value: '',
    text: 'Local Scan Time',
    offset: '',
    isDst: false
  },
  {
    id: 'system',
    abbr: 'system',
    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
    text: 'System Timezone',
    offset: '',
    isDst: false
  }
]

const SubMenu = styled(Flex)`
  padding: 5px 0;
  user-select: none;
  opacity: 0;
  min-width: 160px;
  border: 2px solid transparent;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  right: calc(100% + 5px);
  top: -5px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 1px 5px rgb(204 204 204);
  &::after {
    position: absolute;
    content: ' ';
    left: 100%;
    top: 0;
    width: 10px;
    height: 100%;
  }
  &:hover {
    opacity: 1;
    transition: 0.6s ease;
  }
`

function TimeZone({ bg }: { bg: string }) {
  const { state, setState } = useTimeZone()
  const [timeZoneModal, setTimeZoneModal] = React.useState(false)
  function handleClick(ele: any) {
    let data = {
      id: ele.abbr,
      text: ele.text,
      value: ele.value,
      displayText: ele.text
    }
    setState(data)
    localStorage.setItem(TIMEZONE_KEY, JSON.stringify(data))
  }
  return (
    <>
      <Link to={'#'} onClick={(e: any) => e.preventDefault()}>
        <AiOutlineClockCircle size={20} />
        Timezone
        <SubMenu column className="sub-menu">
          {localData.map((ele: any, index) => {
            let selected = ele.id === state.id
            return (
              <Span
                key={`${ele.text}-${index}`}
                onClick={() => handleClick(ele)}
              >
                {ele.text}
                {selected ? (
                  <FaCheck
                    size={10}
                    style={{
                      margin: 'auto',
                      padding: '3px 0 0 10px',
                      height: 10
                    }}
                  />
                ) : null}
              </Span>
            )
          })}
          <Span
            onClick={() => setTimeZoneModal(true)}
            style={state.id === 'others' ? { color: bg } : {}}
          >
            {state.id === 'others' ? state.value : 'Others'}
            <FaChevronRight style={{ marginLeft: 10 }} />
          </Span>
        </SubMenu>
      </Link>
      {timeZoneModal && (
        <Modal
          variant="l"
          toggleModal={timeZoneModal}
          setToggleModal={setTimeZoneModal}
        >
          <TimeZoneList
            setToggleModal={setTimeZoneModal}
            state={state}
            setState={setState}
          />
        </Modal>
      )}
    </>
  )
}
export default TimeZone
