import React from 'react'
import InputRange, { InputRangeProps } from 'react-input-range'
import styled from 'styled-components'
import 'react-input-range/lib/css/index.css'

const StyledInputRange = styled.div`
  padding: 30px 60px 26px;
  margin: 0px 24px;
  .input-range .input-range__label-container {
    top: 4px;
  }
  .input-range__slider-container:nth-child(2) .input-range__label-container {
    top: -8px;
  }
`

type StyledInputRangeProps = InputRangeProps & { containerStyle?: any }

function Range({ containerStyle, ...rest }: StyledInputRangeProps) {
  return (
    <StyledInputRange style={containerStyle}>
      <InputRange {...rest} />
    </StyledInputRange>
  )
}

export default Range
