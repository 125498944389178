import React from 'react'
import { Card, Flex, Label, Link, InfoIcon, List, ListItem } from '@fareye/ui'
import { FaCaretDown, FaChevronRight, FaCaretUp } from 'react-icons/fa'
import { DASHBOARD_DATA } from '../../config/constants'

interface IOverviewPerformanceCards {
  label: string
  url?: string
  count?: string
  style?: React.CSSProperties
  urlLabel?: string
  showCount?: boolean
  description?: string
  showShipment?: boolean
  showMode?: boolean
  showOthers?: boolean
  showYardVis?: boolean
  data?: string
}
export default function OverviewPerformanceCards({
  label,
  url,
  count,
  style,
  urlLabel,
  showCount = true,
  description,
  showShipment = false,
  showMode = false,
  showOthers = false,
  showYardVis = false,
  data
}: IOverviewPerformanceCards) {
  const dataToday = data === 'today' ? true : false

  const getColorForType = (key: string, element: string) => {
    if (key === 's') {
      if (element === 'On Time') return '#279B4E'
      if (element === 'Early') return '#00539A'
      if (element === 'Delay') return '#C84031'
    }
    if (key == 'o') {
      if (element === 'Exceptions') return '#C84031'
      if (element === 'PO Fulfilment') return '#279B4E'
    }
    return '#212121'
  }

  const getColor = (key: string, element: string, type: string) => {
    switch (type) {
      case 'count':
        return getColorForType(key, element)
      case 'text':
        if (key !== 's') return '#727272'
        else if (element === 'Shipments') return '#727272'
        return getColorForType(key, element)
      case 'up_arrow':
        if (
          element === 'Delay' ||
          element === 'Exceptions' ||
          element === 'CO2 Emission'
        )
          return '#C84031'
        else return '#279B4E'
      case 'down_arrow':
        if (
          element === 'Delay' ||
          element === 'Exceptions' ||
          element === 'CO2 Emission'
        )
          return '#279B4E'
        else return '#C84031'
    }
  }

  const renderElements = (elements: any[], key: string) => {
    return (
      <ul style={{ padding: 0 }}>
        {elements.map((element: any, idx: number) => (
          <Flex
            alignItemsCenter
            style={{
              borderBottom:
                element.url && idx !== elements.length - 1
                  ? '1px solid #DADADA'
                  : 'none'
            }}
            key={element.text}
          >
            <Flex
              justifyContentSpaceBetween
              alignItemsCenter
              style={{
                listStyleType: 'none',
                width: element.url ? '90%' : '100%'
              }}
            >
              <Flex
                style={{
                  flexDirection: element.url ? 'column' : 'row',
                  alignItems: element.url ? 'flex-start' : 'center'
                }}
              >
                <span
                  style={{
                    color: getColor(key, element.text, 'count'),
                    fontSize: '20px',
                    fontWeight: 500
                  }}
                >
                  {element.count}
                </span>
                <span
                  style={{
                    paddingLeft: element.url ? '0' : '5px',
                    color: getColor(key, element.text, 'text')
                  }}
                >
                  {element.text}
                </span>
              </Flex>
              <Flex alignItemsCenter style={{ float: 'right' }}>
                <span>
                  {element.percent > 0 ? '+' : ''}
                  {element.percent}%
                </span>
                {element.percent > 0 ? (
                  <FaCaretUp
                    style={{
                      color: getColor(key, element.text, 'up_arrow'),
                      padding: '0 1em',
                      height: '1.5em'
                    }}
                  ></FaCaretUp>
                ) : (
                  <FaCaretDown
                    style={{
                      color: getColor(key, element.text, 'down_arrow'),
                      padding: '0 1em',
                      height: '1.5em'
                    }}
                  ></FaCaretDown>
                )}
              </Flex>
            </Flex>
            <p style={{ width: element.url ? '10%' : '0%', padding: '5px 0' }}>
              {element.url && (
                <Link style={{ padding: '0' }} to={element.url}>
                  <FaChevronRight style={{ color: '#BFBFBF' }} />
                </Link>
              )}
            </p>
          </Flex>
        ))}
      </ul>
    )
  }

  return (
    <Card style={{ ...style }}>
      <Flex column>
        <Flex alignItemsCenter justifyContentSpaceBetween>
          <Label style={{ padding: '9px 0' }}>
            {label}
            {description && (
              <InfoIcon
                style={{ marginLeft: 5, marginRight: 5, cursor: 'pointer' }}
                title={description}
              />
            )}
          </Label>
          {url && (
            <Link to={url}>
              <FaChevronRight style={{ color: '#BFBFBF' }}></FaChevronRight>
            </Link>
          )}
        </Flex>
        {showCount && <Label variant="h3">{count}</Label>}
        {showShipment && (
          <Flex column>
            {renderElements(
              DASHBOARD_DATA[dataToday ? 'today' : 'lastWeek']['shipment'],
              's'
            )}
          </Flex>
        )}
        {showMode && (
          <Flex column>
            {renderElements(
              DASHBOARD_DATA[dataToday ? 'today' : 'lastWeek']['transport'],
              't'
            )}
          </Flex>
        )}
        {showYardVis && (
          <Flex column>
            {renderElements(
              DASHBOARD_DATA[dataToday ? 'today' : 'lastWeek']['yardVis'],
              'y'
            )}
          </Flex>
        )}
        {showOthers && (
          <Flex column>
            {renderElements(
              DASHBOARD_DATA[dataToday ? 'today' : 'lastWeek']['others'],
              'o'
            )}
          </Flex>
        )}
      </Flex>
    </Card>
  )
}
