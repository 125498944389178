import React from 'react'
import styled from 'styled-components'
import {
  Props,
  layout,
  refinePropsFromParentEvents,
  splitVariantsToTypes,
  calculateSizesforLoader
} from '../../Theme/styledSystem'
import { IButtonProps } from '../../utility/types'
import { Loader } from '../../main'
import { LoadingWrapper } from '../../style/styled'

const StyledButton: any = styled.button`
  position: relative;
  display: inline-flex;
  ${(props: IButtonProps) => {
    const variant = splitVariantsToTypes(props)
    const style = {
      ...props.theme.buttons[variant.display],
      ...props.theme[variant.size],
      ...(props.ghost ? props.theme.buttons[variant.display].ghost : {}),
      ...(props.disabled ? { pointerEvents: 'none' } : {})
    }
    return style
  }}
  ${layout}
`

const LeftChild = styled.div`
  text-align: left;
  ${(props: any) => props.leftStyle}
  ${layout}
`
const Child = styled.div`
  ${(props: any) => props.childrenStyle}
  ${layout}
`
const RightChild = styled.div`
  ${(props: any) => props.rightStyle}
  ${layout}
`

const Button = (props: IButtonProps) => {
  let {
    type = 'button',
    style,
    children,
    isLoading,
    className,
    leftChild,
    leftStyle,
    rightChild,
    rightStyle,
    childrenStyle,
    disabledStyle,
    onClick
  } = Props(props)

  style = onclick ? { ...style, ...disabledStyle } : { ...style }
  const tempStyle =
    !leftChild && !rightChild ? { justifyContent: 'space-around' } : {}
  const OtherProps = refinePropsFromParentEvents(Props(props))
  delete OtherProps.type
  const loaderSize = calculateSizesforLoader(props.variant)
  return (
    <StyledButton
      type={type}
      onClick={!isLoading && onClick}
      className={`${className ? ' ' + className : ''}`}
      style={{
        ...style,
        ...tempStyle
      }}
      {...props}
    >
      <>
        {leftChild && (
          <LeftChild leftStyle={{ ...leftStyle }} {...OtherProps}>
            {leftChild}
          </LeftChild>
        )}
        <Child childrenStyle={childrenStyle} {...OtherProps}>
          {children && children}
        </Child>
        {rightChild && (
          <RightChild rightStyle={{ ...rightStyle }} {...OtherProps}>
            {rightChild}
          </RightChild>
        )}
        {isLoading && (
          <LoadingWrapper>
            <Loader size={loaderSize} />
          </LoadingWrapper>
        )}
      </>
    </StyledButton>
  )
}
export default Button
