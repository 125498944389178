import { IIssueListingApiConfig } from '../utility/types'

export const IssueRaisedConfig: IIssueListingApiConfig = {
  children: {
    subscribe: {
      config: {
        apiConfig: {
          apiURL: `/api/v1/commontator/subscriptions`
        }
      }
    },
    showComments: {
      config: {
        apiConfig: {
          apiURL: '/api/v1/show_comments'
        }
      }
    },
    addComments: {
      config: {
        apiConfig: {
          apiURL: '/api/v1/commontator/threads'
        }
      }
    },
    issueListing: {
      config: {
        apiConfig: {
          apiURL: '/api/v1/issues'
        }
      }
    },
    category: {
      config: {
        apiConfig: {
          apiURL: `/api/v1/get_issue_category`
        }
      }
    },
    getUser: {
      config: {
        apiConfig: {
          apiURL: '/api/v1/alert_user_list'
        }
      }
    },
    addUser: {
      config: {
        apiConfig: {
          apiURL: `/api/v1/create_alert_user`
        }
      }
    },
    getAllIssue: {
      config: {
        apiConfig: {
          apiURL: `/api/v1/get_all_issues`
        }
      }
    },
    searchIssue: {
      config: {
        apiConfig: {
          apiURL: `/api/v1/search_issues`
        }
      }
    }
  },
  config: {
    links: {
      tripDetailsUrl: '/in-transit/trips',
      Journey: '/shipment',
      Shipment: '/packages',
      ConsignerTrip: '/in-transit/trips'
    }
  }
}
