export const GRAPH_DATA_MAPPING = {
  condition: {
    value: 'value',

    label: 'label'
  },
  dataSource: {
    label: 'label',

    value: 'value'
  },
  dimension: {
    value: 'value',

    label: 'label'
  },
  filterType: {
    value: 'value',

    label: 'label'
  },
  filterKey: {
    value: 'value',

    label: 'label'
  },
  filterValue: {
    label: 'label',

    value: 'value'
  },
  metricKey: {
    value: 'value',

    label: 'label'
  },
  metricType: {
    value: 'value',

    label: 'label'
  },
  sortKey: {
    value: 'value',

    label: 'label'
  },
  sortType: {
    value: 'value',

    label: 'label'
  },
  pageIdList: {
    value: 'key',

    label: 'value'
  },
  unit: {
    value: 'key',

    label: 'value'
  }
}

export const SHIPMENT_TYPES = [
  { label: 'Package', value: 'Shipment', dataSource: 'Shipment' },
  { label: 'Shipment', value: 'Journey', dataSource: 'Journey' }
  // { label: 'Item', value: 'item', dataSource: 'Item' }
]

export const KPI_COLORS = {
  Ontime: ' rgb(102, 187, 106)',
  default: ' rgb(102, 187, 106)',
  Early: ' rgb(66, 165, 245) ',
  Delayed: ' rgb(239, 83, 80)',
  pending: ' rgb(66, 165, 245)',
  pending_vehicle_qty: ' rgb(66, 165, 245)',
  pending_tonnage_qty: ' rgb(66, 165, 245)',
  completed: ' rgb(76, 175, 80)',
  successfully_reported_vehicle_qty: ' rgb(255, 87, 34)',
  successfully_reported_tonnage_qty: ' rgb(255, 87, 34)',
  short_closed: 'rgb(149, 117, 205)',
  cancelled: ' rgb(240, 98, 146)',
  cancelled_vehicle_qty: 'rgb(240, 98, 146)',
  cancelled_tonnage_qty: 'rgb(240, 98, 146)',
  placed: ' rgb(156, 204, 101)',
  placed_vehicle_qty: ' rgb(102, 187, 106)',
  placed_tonnage_qty: ' rgb(102, 187, 106)',
  confirmed: 'rgb(38, 166, 154)',
  confirmed_vehicle_qty: 'rgb(38, 166, 154)',
  confirmed_tonnage_qty: ' rgb(38, 166, 154)',
  delayed: ' rgb(194, 24, 91)',
  elapsed: 'rgb(255, 183, 77)',
  elapsed_vehicle_qty: 'rgb(255, 183, 77)',
  elapsed_tonnage_qty: 'rgb(255, 183, 77)',
  exited_vehicle_qty: 'rgb(149, 117, 205)',
  exited_tonnage_qty: 'rgb(149, 117, 205)',
  reported_vehicle_qty: 'rgb(239, 83, 80)',
  reported_tonnage_qty: 'rgb(239, 83, 80)',
  Unknown: 'rgb(216, 27, 96)',
  untracked: 'rgb(216, 27, 96)',
  // MultiModal Colours
  untracked_state: 'rgb(216, 27, 96)',
  untracked_status: 'rgb(216, 27, 96)',
  early: 'rgb(66, 165, 245) ',
  on_time: 'rgb(102, 187, 106)',
  OnTime: 'rgb(102, 187, 106)',
  On_time: 'rgb(102, 187, 106)',
  at_transit_jn: '',
  at_end_jn: '',
  enroute: '',
  at_start_jn: '',
  at_pol: 'rgb(63, 81, 181)',
  at_pod: 'rgb(175, 180, 43)',
  at_transshipment_port: 'rgb(171, 71, 188)',
  at_stop: 'rgb(255, 87, 34)',
  pick_up_executive_assigned: '',
  out_for_pickup: '',
  in_transit: '#FFA900',
  delivery_executive_assigned: '',
  out_for_delivery: '',
  delivered: ''
}
