import { axisFormatter, Span } from '@fareye/ui'
import { MdUnfoldMore, orderBy, toTileCase } from '@fareye/utils'
import React from 'react'
import { ScoreCardTable } from '../../../style/styled'
import { GraphDataMapping, IGraphStateData } from '../../../utility/types'

interface IScoreCard {
  selectedGraph: IGraphStateData
  graphDataMapping: GraphDataMapping
  index: number
  pageSize?: number
}
interface IHeader {
  align?: 'left' | 'right' | 'center'
}

export default function ScoreCard({
  selectedGraph,
  graphDataMapping,
  index,
  pageSize
}: IScoreCard) {
  const [scoreCardData, setScoreCardData] = React.useState<any>({
    columns: [],
    apiData: []
  })
  const fixedColumns = ['label', 'value', 'count']
  function generateScoreCardState() {
    let columns = []
    if (selectedGraph.stacked) {
      const headerKeysObject: any = selectedGraph.data.reduce(
        (dynamicHeaderKeys = new Set(), graphObj) => {
          Object.keys(graphObj).forEach(ele => {
            if (!fixedColumns.includes(ele)) dynamicHeaderKeys.add(ele)
          })
          return dynamicHeaderKeys
        },
        new Set()
      )
      const headerKeys: Array<string> = Array.from(headerKeysObject)
      columns = generateScoreCardHeader(headerKeys)
    } else {
      columns = generateScoreCardHeader()
    }
    setScoreCardData({ apiData: selectedGraph.data, columns })
  }

  function onFilterData(data: any[], ev: any) {
    const orderType = ev[0].desc ? 'desc' : 'asc'

    return orderBy(data, [ev[0].id], [orderType])
  }

  function generateScoreCardHeader(stackedKeys?: Array<string>) {
    const { dimension, metric, breakdownDimension } = selectedGraph.query
    let columns = [
      {
        Header: () => (
          <div style={{ textAlign: 'left' }}>
            {dimension ? dimension[graphDataMapping.dimension.label] : 'N/A'}
            <MdUnfoldMore />
          </div>
        ),
        id: 'label',
        minWidth: 180,
        Cell: ({ row }: any) => (
          <Span fontSize={12}>
            {axisFormatter(
              row?.original.label || '',
              selectedGraph.query.dimensionUnit[graphDataMapping.unit.value],
              null
            )}
          </Span>
        ),
        accessor: 'label'
      }
    ]
    if (selectedGraph.stacked) {
      const stackedColumn: any = []
      stackedKeys?.map(ele => {
        ele &&
          stackedColumn.push({
            Header: () => (
              <div style={{ textAlign: 'left' }}>
                {toTileCase(ele)}
                <MdUnfoldMore />
              </div>
            ),
            accessor: ele,
            id: ele,
            minWidth: 110,
            Cell: ({ row }: any) => (
              <Span fontSize={12}>
                {' '}
                {axisFormatter(
                  row?.original[ele],
                  selectedGraph.query.metricUnit[graphDataMapping.unit.value],
                  null
                )}
              </Span>
            )
          })
      })
      columns = [...columns, ...stackedColumn]
    } else {
      const valueColumn = {
        Header: () => (
          <div style={{ textAlign: 'left' }}>
            {metric
              ? metric.aggKey[graphDataMapping.metricKey['label']]
              : 'N/A'}{' '}
            <MdUnfoldMore />
          </div>
        ),
        accessor: 'value',
        id: 'value',
        minWidth: 110,
        Cell: ({ row }: any) => (
          <Span fontSize={12}>
            {axisFormatter(
              row?.original.value,
              selectedGraph.query.metricUnit[graphDataMapping.unit.value],
              null
            )}
          </Span>
        )
      }
      columns = [...columns, valueColumn]
    }
    return columns
  }

  React.useEffect(() => {
    generateScoreCardState()
  }, [index, selectedGraph])
  return (
    <ScoreCardTable
      columns={scoreCardData.columns}
      data={scoreCardData.apiData}
      defaultPageSize={pageSize || 10}
      className="responsive striped"
      fontSize="xs"
      onSortedChange={(ev: any) => {
        setScoreCardData({
          ...scoreCardData,
          apiData: onFilterData(scoreCardData.apiData, ev)
        })
      }}
    />
  )
}
