import { IInPlantMapConfig } from '@fareye/inplant-map'
import { MdList, MdMap } from '@fareye/utils'
import environment from '../../environment'
const { baseURL } = environment

const InPlantMapRenderingConfig: IInPlantMapConfig = {
  config: {
    links: {
      tripShare: '/in-plant/trips'
    },
    downloadApiConfig: {
      apiURL: '/streams/consigner_trips/trip_data',
      queryParams: {
        gpsInfo: true,
        isActive: true,
        mapView: true,
        inPlant: true
      }
    },
    columnsList: [
      {
        key: 'tripID',
        label: 'Reference No.'
      },
      {
        key: 'startDate',
        label: 'Start Date',
        formatter: 'dateFormatter'
      },
      {
        key: 'landmark',
        label: 'LANDMARK'
      },
      {
        key: 'material',
        label: 'PRODUCT'
      },
      {
        key: 'istTimestamp',
        label: 'Last Updated At'
      },
      {
        key: 'vehicleNo',
        label: 'VEHICLE NO'
      },
      {
        key: 'origin',
        label: 'Origin'
      },
      {
        key: 'destination',
        label: 'Destination'
      },
      {
        key: 'loadingInTime',
        label: 'LOADING IN',
        formatter: 'dateFormatter'
      },
      {
        key: 'loadingOutTime',
        label: 'LOADING OUT',
        formatter: 'dateFormatter'
      },
      {
        key: 'unloadingInTime',
        label: 'UNLOADING IN',
        formatter: 'dateFormatter'
      },
      {
        key: 'unloadingOutTime',
        label: 'UNLOADING OUT',
        formatter: 'dateFormatter'
      },
      {
        key: 'exceptionsCount',
        label: 'EXCEPTIONS'
      },
      {
        key: 'transporter',
        label: 'TRANSPORTER'
      },
      {
        key: 'consigneeName',
        label: 'CONSIGNEE'
      },
      {
        key: 'consignerName',
        label: 'CONSIGNER'
      },
      {
        key: 'driverName',
        label: 'DRIVER NAME'
      },
      {
        key: 'driverPhoneNo',
        label: 'DRIVER PHONE NO.'
      },
      {
        key: 'battery',
        label: 'Battery'
      },
      {
        key: 'signalStrength',
        label: 'Signal Strength'
      },
      {
        key: 'vehicleState',
        label: 'Vehicle State'
      }
    ]
  },
  styling: {
    style: {},
    className: '',
    variant: ''
  },
  children: {},
  vehiclePopUp: {
    apiURL: '/api/v2/consigner_trips'
  },
  masterData: {
    apiURL: '/api/v2/consigner_trips/master_data'
  },
  inPlantMap: {
    apiURL: '/api/v2/inplant/map_vehicles',
    plantApiURL: '/api/v2/inplant/plant_data',
    mapKey: 'AIzaSyAfHNk2CTX44LJiSKEYy1jmYgSuMk8IABA',
    toggleContent: [
      {
        icon: 'MdMap',
        title: 'Map View',
        selectedKey: 'normal'
      },
      {
        icon: 'MdList',
        title: 'List View',
        selectedKey: 'tabular'
      }
    ],
    env: 'dev',
    queryParams: {
      inPlant: true,
      isActive: true,
      mapView: true,
      gpsInfo: true,
      plant: 'kayad'
    }
  }
}
export default InPlantMapRenderingConfig
