import { IKeyValueObj } from '@fareye/types'
import React from 'react'
import {
  AlertType,
  AlertTypeListing,
  AlertTypeSelect
} from '../../style/styled'

interface IVerticalListing {
  listingOptions: Array<IKeyValueObj<string>>
  selected: string
  setSelected: (val: string) => void
}

export function VerticalListing(props: IVerticalListing) {
  const { selected, listingOptions, setSelected } = props
  const selectedOption = listingOptions.find(
    (item: any) => item.value === selected
  )
  return (
    <>
      <AlertTypeSelect
        options={listingOptions}
        value={selectedOption}
        onChange={(val: any) => setSelected(val.value)}
      />
      <AlertTypeListing column>
        {listingOptions.map((item: any, i: number) => {
          return (
            <AlertType
              onClick={() => setSelected(item.value)}
              key={`alert${i}`}
              variant={`m ${selected === item.value ? 'primary' : ''} `}
            >
              {item.label}
            </AlertType>
          )
        })}
      </AlertTypeListing>
    </>
  )
}
