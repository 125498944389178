import { KPI_COLORS, TimeZoneProvider } from '@fareye/common'
import {
  createGlobalStyle,
  Loader,
  ThemeProvider,
  Themes,
  ToastContainer
} from '@fareye/ui'
import { axiosInstance, parseJwt, validURL } from '@fareye/utils'
import { shareTripRoutes } from 'config/routes.config'
import React, { Suspense } from 'react'
import { CookiesProvider } from 'react-cookie'
import { Route, Router, Switch } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import './app.css'
import AppLayout from './AppLayout'
import logo from './assets/fareye-logo-white.png'
import {
  AUTHORIZATION_HEADER,
  RoutesPath,
  SkipPostURL
} from './config/constants'
import environment from './environment'
import history from './GoogleAnalytics'
import Layout from './modules/Layout/'
import { AppStore } from './store'
import { getToken } from './utility/funcs'
import lazy from './utility/lazy'

const Login = lazy(() => import('./modules/User/Login'))
const Logout = lazy(() => import('./modules/User/Logout'))

const GlobalCSS = createGlobalStyle`
  .input-range__slider {
    background: ${(props: any) => props.theme.colors.primary} !important;
    border-color: ${(props: any) => props.theme.colors.primary} !important;
  }
`

const initialStore = {
  header: {
    backgroundColor: '#006490',
    img: logo
  }
}
const App: React.FC = () => {
  const [store, setStore] = React.useState<any>(initialStore)
  const { baseURL, processEnv, postUrl, mapKey, defaultBaseLayer } = environment
  // For Enabling JIRA_URL
  // useScript(JIRA_URL, production)
  React.useLayoutEffect(() => {
    try {
      registerInterceptor()
    } catch (ex) {
      console.warn('Issue in Registering interceptor...', ex)
    }
  }, [])

  function registerInterceptor() {
    axiosInstance.interceptors.request.use(
      function(config) {
        config.headers['Accept'] = 'application/json'
        config.headers['Content-Type'] = 'application/json'
        config.headers['X-Fe-Platform'] = 'web'
        if (!config.headers['unAuthenticated']) {
          const token = getToken()
          if (token) {
            let userId = parseJwt(token) && parseJwt(token)['user_id']
            config.headers[AUTHORIZATION_HEADER] = token
            config.headers['X-Fe-RequestId'] = uuidv4()
            config.headers['X-Fe-UserId'] = userId
          }
        }
        if (!validURL(config.url)) {
          config.url = baseURL + config.url
        }
        //TODO: Added hack to run kpi in production Mode
        if (
          processEnv === 'staging' &&
          config.method === 'post' &&
          !SkipPostURL.some(ele => config?.url?.includes(ele))
        ) {
          try {
            let url = new URL(config.url || '')
            url.hostname = config?.url?.includes('streams')
              ? postUrl
              : postUrl || baseURL
            config.url = url.href
          } catch (e) {
            console.error(e, 'error')
          }
        }
        return config
      },
      function(error) {
        console.error('error', '*********')
        // Do something with request error
        return Promise.reject(error)
      }
    )
    axiosInstance.interceptors.response.use(
      function(config) {
        return config
      },
      function(error) {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 406
        ) {
          window.location.href = '/login'
        }
      }
    )
  }

  const theme = Themes.ant
  return (
    <ThemeProvider
      theme={{ ...theme, colors: { ...theme.colors, ...KPI_COLORS } }}
    >
      <CookiesProvider>
        <TimeZoneProvider>
          <AppStore.Provider value={{ store, updateStore: setStore }}>
            <>
              <Suspense fallback={<Loader />}>
                <ToastContainer
                  position="top-right"
                  style={{ zIndex: 1000000 }}
                />
                <Router history={history}>
                  <Switch>
                    <Route exact path={RoutesPath.LOGIN} component={Login} />
                    <Route exact path={RoutesPath.LOGOUT} component={Logout} />

                    {shareTripRoutes.map((route: any, i: number) => (
                      <Route
                        key={route.path}
                        render={props => (
                          <Layout
                            route={route}
                            menuOpen={false}
                            routesConfig={shareTripRoutes}
                            mapKey={mapKey}
                            defaultBaseLayer={defaultBaseLayer}
                            pageConfigKey={route.pageId && route.pageId}
                            baseUrl={
                              environment[route.key]
                                ? environment[route.key]
                                : baseURL
                            }
                            subType={route.subType && route.subType}
                            {...props}
                          />
                        )}
                        path={route.path}
                        exact={route.exact}
                      />
                    ))}
                    <AppLayout />
                  </Switch>
                </Router>
              </Suspense>
              <GlobalCSS />
            </>
          </AppStore.Provider>
        </TimeZoneProvider>
      </CookiesProvider>
    </ThemeProvider>
  )
}

export default App
