import { IInPlantConfig } from '@fareye/inplant'
import { MdFeaturedPlayList, MdList } from '@fareye/utils'
import environment from '../../environment'
import { AtLeastOne } from '@fareye/types'

const { baseURL } = environment

const InPlantRenderingConfig: AtLeastOne<IInPlantConfig> = {
  inPlantPerformance: {
    globalSearchPageId: 'inplant',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: {},
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'inplant'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      carryDate: true,
      showDatePicker: true,
      links: {}
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      layoutStyle: {
        height: 'calc(100vh - 50px)'
      },
      style: {
        padding: '0px',
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridGap: '2vw'
      }
    },
    children: {
      materialWiseTat: {
        component: 'ProductTAT',
        config: {
          carryDate: true,
          links: {},
          apiConfig: {
            apiURL: `${baseURL}/api/v2/inplant/loading_performance`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          }
        },
        styling: {
          style: { padding: '2vw' },
          className: '',
          variant: {
            gridGap: '2vw'
          }
        },
        children: {
          materialWiseTATCard: {
            component: 'ProductTatCard',
            config: {
              links: {}
            },
            styling: {
              style: { background: '#fff' },
              className: '',
              variant: 's'
            },
            children: {}
          }
        }
      }
    }
  },
  productWiseTatPage: {
    globalSearchPageId: 'inplant',
    filters: {
      apiFilter: {
        apiURL: '/api/v1/es/search_aggs',
        queryParams: {},
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: '/json_store/records.json',
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'inplant'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      carryDate: true,
      showDatePicker: true,
      toggleContent: [
        {
          label: 'Card View',
          title: 'Card View',
          selectedKey: 'normal'
        },
        {
          label: 'List View',
          title: 'List View',
          selectedKey: 'tabular'
        }
      ],
      links: {}
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      style: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridTemplateColumns: '1fr'
      }
    },
    children: {
      plantDetails: {
        component: 'PlantDetails',
        config: {
          apiConfig: {
            apiURL: '/api/v2/consigner_trips',
            queryParams: {
              groupBy: 'routes',
              groupByDuration: 'day',
              statusPopulated: false
            },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          apiPlantVivo: {
            apiURL: '/api/v2/inplant/vivo_distribution',
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          apiVehicleDistribution: {
            apiURL: '/api/v2/inplant/vehicle_distribution',
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          metricsCount: [
            {
              label: 'Bad',
              matchParam: 'greaterThan150',
              color: '#D84343'
            },
            {
              label: 'Good',
              matchParam: 'lessThan50',
              color: '#1261A0'
            },
            {
              label: 'Average',
              matchParam: 'between50and150',
              color: '#76BA1B'
            }
          ],
          links: {}
        },
        styling: {
          style: {},
          className: '',
          variant: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: '10px'
          }
        },
        children: {}
      },
      tripTable: {
        component: 'TripTable',
        styling: {
          style: {
            width: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: '16px 16px 50px'
          },
          className: '',
          variant: {}
        },
        children: {},
        config: {
          apiConfig: {
            apiURL: '/api/v2/consigner_trips',
            queryParams: {
              statusPopulated: 'false'
            },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          downloadApiConfig: {
            apiURL: '/streams/consigner_trips/trip_data',
            queryParams: {}
          },
          columns: [
            {
              key: 'tripIdAndProduct',
              label: 'Ref. No. & Product'
            },
            {
              key: 'plantInOut',
              label: 'Plant In And Out Time'
            },
            {
              key: 'vivo',
              label: 'VIVO'
            },
            {
              key: 'exceptions',
              label: 'Exceptions'
            },
            {
              key: 'driverDetails',
              label: 'Driver Details'
            }
          ],
          columnsList: [
            {
              key: 'tripID',
              label: 'Reference No.'
            },
            {
              key: 'landmark',
              label: 'LANDMARK'
            },
            {
              key: 'material',
              label: 'PRODUCT'
            },
            {
              key: 'istTimestamp',
              label: 'Last Updated At'
            },
            {
              key: 'vehicleNo',
              label: 'VEHICLE NO'
            },
            {
              key: 'origin',
              label: 'Origin'
            },
            {
              key: 'destination',
              label: 'Destination'
            },
            {
              key: 'loadingInTime',
              label: 'LOADING IN',
              formatter: 'dateFormatter'
            },
            {
              key: 'loadingOutTime',
              label: 'LOADING OUT',
              formatter: 'dateFormatter'
            },
            {
              key: 'exceptionsCount',
              label: 'EXCEPTIONS'
            },
            {
              key: 'transporter',
              label: 'TRANSPORTER'
            },
            {
              key: 'consigneeName',
              label: 'CONSIGNEE'
            },
            {
              key: 'consignerName',
              label: 'CONSIGNER'
            },
            {
              key: 'driverName',
              label: 'DRIVER NAME'
            },
            {
              key: 'driverPhoneNo',
              label: 'DRIVER PHONE NO.'
            }
          ],
          links: {
            tripShare: '/in-plant/trips'
          },
          variablesFromParent: ['plant']
        }
      }
    }
  }
}

export default InPlantRenderingConfig
