import { Flex, SearchSelect, Span } from '@fareye/ui'
import { FaTrash } from '@fareye/utils'
import React from 'react'
import { Colors, ValueJson } from '../../../utility/constant'
const SearchSelectFilterCss = {
  wrapper: {
    width: '25%',
    maxWidth: '150px'
  }
}
const DeleteIconCss: React.CSSProperties = {
  cursor: 'pointer',
  opacity: 0.5
}
const DeleteIconContainerCss: React.CSSProperties = {
  width: '24px',
  marginRight: '20px',
  marginLeft: '15px',
  marginTop: '7px'
}
const ORIconEnabledCss: React.CSSProperties = {
  cursor: 'pointer',
  color: Colors.orButtonEnableColor,
  pointerEvents: 'auto'
}
const ORIconDisableCss: React.CSSProperties = {
  pointerEvents: 'none'
}
const OrIconDefaultCss: React.CSSProperties = {
  fontSize: '16px',
  fontWeight: 500,
  color: Colors.orButtonColor,
  marginTop: '12px',
  display: 'inline-block',
  cursor: 'pointer'
}
interface IFilterForm {
  index: number
  secondIndex: number
  filterData: any
  setFieldValue: any
  arrayHelper: any
  arrayHelperInternal: any
  formOptionData: any
  getOptionsFromKpiConfig: any
}

export default ({
  index,
  secondIndex,
  filterData,
  setFieldValue,
  arrayHelper,
  arrayHelperInternal,
  formOptionData,
  getOptionsFromKpiConfig
}: IFilterForm) => {
  const [conditions, setConditions] = React.useState([])
  const [filterValues, setFilterValues] = React.useState([])
  const setFilterKey = (
    value: any,
    firstArrayIndex: number,
    secondArrayIndex: number
  ) => {
    setFieldValue(
      `filter.filterData.${firstArrayIndex}.${secondArrayIndex}.filterKey`,
      value
    )
    setConditions(getOptionsFromKpiConfig('condition', value))
  }
  const renderFilterValue = (value: any) => {
    switch (value.conditionType) {
      case 'string':
        break
      case 'number':
        break
      case 'date':
        break
      default:
        break
    }
  }
  return (
    <Flex key={`subFilter${secondIndex}`} justifyContentSpaceBetween>
      <Flex justifyContentSpaceBetween style={{ width: '60%' }}>
        <SearchSelect
          value={filterData[index][secondIndex].filterType}
          options={formOptionData.filterType}
          onChange={value => {
            setFieldValue(
              `filter.filterData.${index}.${secondIndex}.filterType`,
              value
            )
          }}
          placeholder="Filer Type"
          style={SearchSelectFilterCss}
        />
        <SearchSelect
          value={filterData[index][secondIndex].filterKey}
          options={formOptionData.dimension}
          onChange={value => {
            setFilterKey(value, index, secondIndex)
          }}
          placeholder={'Process Type'}
          style={SearchSelectFilterCss}
        />
        {filterData[index][secondIndex].filterKey ? (
          <SearchSelect
            value={filterData[index][secondIndex].condition}
            getOptionLabel={(ele: any) => ele.key}
            options={conditions}
            onChange={value => {
              setFieldValue(
                `filter.filterData.${index}.${secondIndex}.condition`,
                value
              )
            }}
            placeholder="Enter Condition"
            style={SearchSelectFilterCss}
          />
        ) : (
          <div style={SearchSelectFilterCss.wrapper}></div>
        )}
        {filterData[index][secondIndex].condition ? (
          <SearchSelect
            value={filterData[index][secondIndex].filterValue}
            getOptionLabel={(ele: any) => ele.key}
            options={ValueJson}
            onChange={value => {
              setFieldValue(
                `filter.filterData.${index}.${secondIndex}.filterValue`,
                value
              )
            }}
            placeholder="Select Value"
            style={SearchSelectFilterCss}
          />
        ) : (
          <div style={SearchSelectFilterCss.wrapper}></div>
        )}
      </Flex>
      <div style={{ padding: '10px 15px' }}>
        <Span
          style={{
            ...OrIconDefaultCss,
            ...(filterData[index].length - 1 === secondIndex
              ? ORIconEnabledCss
              : ORIconDisableCss)
          }}
          variant="s"
          onClick={() => arrayHelperInternal.push({})}
        >
          OR
        </Span>
        <span
          onClick={() => {
            secondIndex === 0
              ? arrayHelper.remove(index)
              : arrayHelperInternal.remove(secondIndex)
          }}
          style={DeleteIconContainerCss}
        >
          <FaTrash style={DeleteIconCss} />
        </span>
      </div>
    </Flex>
  )
}
