import { FormBuilder } from '@fareye/form-builder'
import { Flex } from '@fareye/ui'
import { zonedToUTC, zonedToUTCAdd1Day } from '@fareye/common'
import {
  groupBy,
  isEmpty,
  cancelToken,
  get,
  yyyyMmDd,
  getQueryParams,
  post
} from '@fareye/utils'
import React from 'react'
import {
  IPageConfig,
  IReportData,
  IReportPageRenderingConfig
} from '../../../utility/types'
import { HEADER_INFLECTION } from '../../../config/constants'
import DownloadExcelButton from './DownloadExcelButton'
import { AtLeastOne } from '@fareye/types'

interface IFormRenderer {
  selectedReportType: string
  reportConfig: IReportPageRenderingConfig
  propsConfig: AtLeastOne<IPageConfig>
  setReportData: (val: IReportData) => void
  baseUrl: string
  reportData: IReportData
  showTimezone?: boolean
  setPageNo: (val: number) => void
}
export default function FormRenderer({
  selectedReportType,
  reportConfig,
  setReportData,
  baseUrl,
  reportData,
  showTimezone,
  setPageNo
}: IFormRenderer) {
  const [formValue, setFormValue] = React.useState<any>({})
  const config = reportConfig.formRendering[selectedReportType]
  const [formDefaultValue, setFormDefaultValue] = React.useState({})
  const { reportConfig: reportApiConfig, formRendering } = reportConfig
  const source = cancelToken().source()
  const [fieldChange, onFieldChange] = React.useState<any>({})
  const { data, queryString, meta, postQuery } = React.useMemo(
    () => reportData,
    [reportData]
  )
  const {
    streamUrl,
    streamUrlRequestType = 'GET',
    label: fileName,
    queryParams,
    apiUrl,
    apiUrlRequestType = 'GET'
  } = reportConfig.reportConfig[selectedReportType]
  const configQueryParam = queryParams ? getQueryParams(queryParams) : ''

  function generateFilterDataFromGlobalSearch(globalSearch: any[]) {
    if (isEmpty(globalSearch)) {
      return []
    } else {
      const groupedSearch = groupBy(globalSearch, ele => ele.key)
      return Object.entries(groupedSearch).map(([key, value]) => {
        return [
          {
            filterKey: key,
            filterValue: value.reduce((res = [], obj) => {
              res.push(obj.value)
              return res
            }, []),
            condition: 'include',
            filterType: 'eq'
          }
        ]
      })
    }
  }
  const downloadConfig = React.useMemo(
    () =>
      streamUrl
        ? {
            downloadUrl: `${baseUrl}${streamUrl}${
              streamUrlRequestType === 'GET'
                ? `?${queryString}&${configQueryParam}`
                : ''
            }`,
            query: { filter: postQuery },
            isStream: true,
            type: streamUrlRequestType
          }
        : {
            downloadUrl: `${baseUrl}${apiUrl}${
              streamUrlRequestType === 'GET'
                ? `?${queryString}&${configQueryParam}`
                : ''
            }`,
            query: { filter: postQuery },
            isStream: false,
            type: streamUrlRequestType
          },
    [streamUrl, queryString, baseUrl, postQuery]
  )

  React.useEffect(() => {
    if (!isEmpty(formValue.values)) {
      const query = generateQueryForReport(formValue.values)
      const queryString = getQueryParams(query) || ''
      submitForm(queryString)
    }
    return () => {
      source.cancel('Unmount Report component')
    }
  }, [formValue])

  React.useEffect(() => {
    if (!isEmpty(fieldChange.values)) {
      const query: any = generateQueryForReport(fieldChange.values)
      if (streamUrlRequestType === 'GET') {
        const queryString = getQueryParams(query) || ''
        setReportData({
          ...reportData,
          queryString: queryString
        })
      } else {
        const filterQuery: any = generateFilterDataFromGlobalSearch(
          fieldChange?.values?.filter
        )
        const startFilter = [
          {
            filterKey: 'start_date',
            filterValue: query.startDate
              ? showTimezone
                ? zonedToUTC(yyyyMmDd(query.startDate))
                : yyyyMmDd(query.startDate)
              : new Date(),
            condition: 'include',
            filterType: 'gte'
          }
        ]
        const endFilter = [
          {
            filterKey: 'start_date',
            filterValue: query.endDate
              ? showTimezone
                ? zonedToUTCAdd1Day(yyyyMmDd(query.endDate))
                : yyyyMmDd(query.endDate)
              : new Date(),
            condition: 'include',
            filterType: 'lte'
          }
        ]
        const postQuery = [startFilter, endFilter, ...filterQuery]
        setReportData({
          ...reportData,
          postQuery
        })
      }
    }
    return () => {
      source.cancel('Unmount Report component')
    }
  }, [fieldChange])

  function pickStringArrayFromLabelValueData(
    val: { label: string; value: string; id: number }[]
  ) {
    return val.map(ele => {
      //NOTE:For POI
      if (ele.id) {
        return ele.id
      }
      //NOTE: For other params
      else {
        return ele.value
      }
    })
  }

  function generateFilterData(filterValue: any) {
    let groupeData: any = groupBy(filterValue, ele => ele.key)
    let filterData = {}
    Object.keys(groupeData).map(ele => {
      filterData[ele] = groupeData[ele].map(
        (filterData: any) => filterData.value
      )
    })
    return filterData
  }

  function generateQueryForReport(val: any) {
    let query = {}
    Object.entries(val).map(([key, value]) => {
      if (key === 'dateRange') {
        query = { ...query, ...(value as any) }
      } else if (key === 'filter') {
        query = { ...query, ...generateFilterData(value) }
      } else if (Array.isArray(value)) {
        query = {
          ...query,
          [key]: pickStringArrayFromLabelValueData(value)
        }
      } else if (value && typeof value === 'object') {
        query = { ...query, [key]: (value as any).label }
      } else {
        query = { ...query, [key]: value }
      }
    })
    return query
  }

  async function submitForm(val: any) {
    try {
      setPageNo(0)
      setReportData({ ...reportData, isLoading: true, queryString: val })
      const { apiUrl: pathParam } = reportApiConfig[selectedReportType]
      const apiUrl = `${baseUrl}${pathParam}`
      let apiData: any = {}
      if (apiUrlRequestType === 'GET') {
        const { data } = await get(
          `${apiUrl}?${val}&pageNo=1&${configQueryParam}`,
          source.token,
          HEADER_INFLECTION
        )
        apiData = data
      } else {
        const { data }: any = await post(
          apiUrl,
          { filter: postQuery, page: 1, perPage: 20 },
          source.token,
          HEADER_INFLECTION
        )
        apiData = data
      }

      if (config.hideSubmitButtonTimer) {
        setReportData({
          ...reportData,
          data: [],
          isLoading: false,
          isSuccess: true,
          queryString: val,
          message: 'Report send in your email successfully...'
        })
      } else {
        setReportData({
          ...reportData,
          data: apiData.content,
          meta: apiData.meta,
          isLoading: false,
          isSuccess: true,
          queryString: val,
          message: isEmpty(data.content)
            ? 'No Data found for selected filters...'
            : ''
        })
      }
    } catch (ex) {
      setReportData({
        ...reportData,
        isLoading: false,
        isSuccess: true,
        queryString: val,
        message: 'Something went wrong...'
      })
    }
  }
  const btnProps: any = React.useMemo(() => {
    return {
      downloadConfig,
      fileName,
      data
    }
  }, [downloadConfig, fileName, reportData.postQuery])
  return (
    <Flex column bg="#fff">
      <FormBuilder
        setSubmitValues={setFormValue}
        formDefaultValue={formDefaultValue}
        config={config}
        formGroupName={selectedReportType}
        isLoading={reportData.isLoading}
        baseUrl={baseUrl}
        onFieldChange={onFieldChange}
        buttonComponent={
          config.hideSubmitButtonTimer ? (
            <></>
          ) : (
            <DownloadExcelButton {...btnProps} />
          )
        }
      />
    </Flex>
  )
}
