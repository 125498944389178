import {
  axisFormatter,
  Button,
  Flex,
  Input,
  PopOver,
  SearchSelect,
  Span
} from '@fareye/ui'
import {
  cloneDeep,
  dMmm,
  downloadToCSV,
  FaArrowDown,
  FaArrowLeft,
  FaArrowUp,
  FaChartBar,
  FaEllipsisV,
  FaExpand,
  FaFileDownload,
  FaRedo,
  FaTable,
  isEqual
} from '@fareye/utils'
import React from 'react'
import {
  DimensionResetArrow,
  DimensionTravellerHeader,
  DimensionTravellerMenu,
  GroupedButton,
  InfoContainer,
  MoreActions,
  ResetContainer,
  TravellerSpan
} from '../../../style/styled'
import { Colors } from '../../../utility/constant'
import {
  IFilterData,
  IGraphDimensionConfig,
  IGraphRendererConfig,
  IGraphStateData,
  IKeyValue
} from '../../../utility/types'
import GraphRendererFullScreen from '../../GraphRendererFullScreen'

const DimensionTraveller = ({
  graphStateData,
  selectedDimension,
  selectedDrillDownDimensionIndex,
  setDataForChart,
  setStateForChart,
  setSelectedDrillDownDimensionIndex,
  graphDataMapping,
  graphStyle,
  dataConfig,
  graphConfig,
  globalFilter,
  setShowGraph,
  showGraph,
  FullScreenComponent,
  benchmarkInput,
  setBenchMarkInput
}: IGraphDimensionConfig) => {
  const drillDownDimensions = graphStateData[selectedDimension]
  const [fullScreen, setFullScreen] = React.useState(
    graphStyle && graphStyle.fullScreen
  )
  const [firstTime, setFirstTime] = React.useState(true)
  const config: IGraphRendererConfig = {
    graphConfig,
    dataConfig,
    style: graphStyle
  }
  const {
    hideSort = false,
    hideFullScreen = false,
    hideTableLayout = false
  } = graphConfig

  let { hideDownloadCsv = false } = graphConfig

  const [displayDataCount, setDisplayDataCount] = React.useState(() =>
    getDisplayDataCount()
  )
  const drillDownDimensionLength =
    drillDownDimensions &&
    drillDownDimensions[selectedDrillDownDimensionIndex.index] &&
    drillDownDimensions[selectedDrillDownDimensionIndex.index].data.length

  const drillDownDimensionLabel =
    drillDownDimensions &&
    drillDownDimensions[selectedDrillDownDimensionIndex.index] &&
    drillDownDimensions[selectedDrillDownDimensionIndex.index].query.dimension
      .label
  const { metric, sortType, benchmarkDimension, chartType } =
    drillDownDimensions &&
    drillDownDimensions[selectedDrillDownDimensionIndex.index] &&
    drillDownDimensions[selectedDrillDownDimensionIndex.index].query
  React.useState(() => {
    if (fullScreen) {
      setFirstTime(false)
    }
  })

  React.useEffect(() => {
    const { displayDataLength } =
      drillDownDimensions &&
      drillDownDimensions[selectedDrillDownDimensionIndex.index]
    if (displayDataLength !== displayDataCount) {
      setDisplayDataCount(displayDataLength ? displayDataLength : 0)
    }
  }, [graphStateData, selectedDrillDownDimensionIndex])

  function getDisplayDataCount() {
    const displayDataLength =
      drillDownDimensions &&
      drillDownDimensions[selectedDrillDownDimensionIndex.index] &&
      drillDownDimensions[selectedDrillDownDimensionIndex.index]
        .displayDataLength
    return displayDataLength ? displayDataLength : 0
  }
  function generateTextForDrillDown() {
    if (
      selectedDrillDownDimensionIndex.index + 1 <
      drillDownDimensions.length
    ) {
      const fromDimension =
        drillDownDimensions[selectedDrillDownDimensionIndex.index].query
          .dimension[dataConfig.graphDataMapping.dimension.label]
      const toDimension =
        drillDownDimensions[selectedDrillDownDimensionIndex.index + 1].query
          .dimension[dataConfig.graphDataMapping.dimension.label]
      return (
        <Span style={{ color: '#727272' }}>
          {`Click on ${fromDimension} bar to drill-down to ${toDimension}`}
        </Span>
      )
    }
    return ''
  }

  function onChangeOfDimension(
    index: number,
    graphStateData: IKeyValue<IGraphStateData[]>,
    value: any
  ) {
    const cloneGraphState = cloneDeep(graphStateData)
    cloneGraphState[selectedDimension][index].filterBy = value
    setDataForChart(
      selectedDimension,
      { isReload: true, index: selectedDrillDownDimensionIndex.index },
      cloneGraphState
    )
  }

  function formatLabel(value: any) {
    const { dimensionUnit } = drillDownDimensions[
      selectedDrillDownDimensionIndex.index - 1
    ].query
    const { label } = value
    return axisFormatter(label, dimensionUnit.key, null)
  }

  function updateSortingOrder(value: any) {
    const clonedGraphState = cloneDeep(graphStateData)
    clonedGraphState[selectedDimension][
      selectedDrillDownDimensionIndex.index
    ].query.sortType = value
    setDataForChart(
      selectedDimension,
      { isReload: true, index: selectedDrillDownDimensionIndex.index },
      clonedGraphState
    )
  }

  async function downloadCsv() {
    try {
      const selectedDimensionData = graphStateData[selectedDimension]
      let { query } = cloneDeep(
        selectedDimensionData[selectedDrillDownDimensionIndex.index]
      )
      query.filter = query.filter ? query.filter : []
      for (let i = 0; i <= selectedDrillDownDimensionIndex.index; i++) {
        if (selectedDimensionData[i].filterBy) {
          const { query: prevQuery } = selectedDimensionData[i - 1]
          const filterObj: IFilterData = {
            filterValue: {
              value: (selectedDimensionData[i].filterBy as any).label
            },
            filterType: { value: 'eq' },
            filterKey: {
              value: prevQuery.dimension[graphDataMapping.dimension.value]
            },
            condition: { value: 'include' }
          }
          query.filter.push([filterObj])
        }
      }
      const data = await dataConfig.getDataForChart(query, globalFilter, true)
      downloadToCSV({
        data: data,
        fileName: `${graphConfig.chartTitle}-${dMmm()}`
      })
    } catch (err) {
      console.error(err)
    }
  }

  function changeNoOfData() {
    let { data } = graphStateData[selectedDimension][
      selectedDrillDownDimensionIndex.index
    ]

    let noOfDataPoint =
      data.length > displayDataCount ? displayDataCount : data.length

    const config = {
      displayData: data.slice(0, noOfDataPoint),
      displayDataLength: noOfDataPoint
    }

    setStateForChart(
      graphStateData,
      selectedDimension,
      selectedDrillDownDimensionIndex.index,
      config
    )
  }

  function generateDropDown() {
    return (
      drillDownDimensions &&
      drillDownDimensions[selectedDrillDownDimensionIndex.index] &&
      drillDownDimensions.map((ele, index) => {
        if (index < selectedDrillDownDimensionIndex.index) {
          return (
            <Flex alignItemsFlexEnd key={`dimensionTraveller${index}`}>
              <Flex column>
                <Span style={{ color: '#217CE7', fontWeight: 600 }}>
                  {drillDownDimensions[index].query.dimension.label}
                </Span>
                <SearchSelect
                  variant="fareyeV2"
                  style={{ container: { width: '150px' } }}
                  options={drillDownDimensions[index].data}
                  value={drillDownDimensions[index + 1].filterBy}
                  getOptionValue={ele => ele.label}
                  placeholder={drillDownDimensions[index].query.dimension.label}
                  onChange={value => {
                    if (
                      !isEqual(drillDownDimensions[index + 1].filterBy, value)
                    ) {
                      onChangeOfDimension(index + 1, graphStateData, value)
                    }
                  }}
                  formatOptionLabel={value => formatLabel(value)}
                />
              </Flex>
              <FaArrowDown
                style={{
                  margin: '8px 12px',
                  transform: 'rotate(-90deg)'
                }}
                fontSize={14}
                color={Colors.orButtonColor}
              />
            </Flex>
          )
        }
        return ''
      })
    )
  }

  function setBenchMarkData() {
    const clonedGraphState = cloneDeep(graphStateData)
    if (benchmarkInput) {
      clonedGraphState[selectedDimension][
        selectedDrillDownDimensionIndex.index
      ].query.benchmarkDimension = { value: benchmarkInput }
    }
    setDataForChart(
      selectedDimension,
      selectedDrillDownDimensionIndex,
      clonedGraphState
    )
  }

  function generateBenchmarkInput() {
    if (benchmarkDimension) {
      return (
        <>
          <Span p={2}>Target(min)</Span>
          <Input
            type="number"
            onChange={(eve: React.ChangeEvent<HTMLInputElement>) => {
              setBenchMarkInput(eve.target.value)
            }}
            value={String(benchmarkInput)}
            onBlur={setBenchMarkData}
            style={{
              height: 14,
              paddingRight: 0,
              maxWidth: 40,
              fontSize: 12,
              marginLeft: 10,
              border: '1px solid rgba(120,120,120,.1)',
              boxShadow: '0 0 1px 0 rgba(120,120,120,.1)'
            }}
          />
        </>
      )
    }
    return <></>
  }

  const hideTopNavigation = new URL(document.location.href).searchParams.get(
    'disableNav'
  )
  if (hideTopNavigation) {
    hideDownloadCsv = true
  }
  return (
    <>
      <Flex column>
        <>
          {metric && (
            <>
              <DimensionTravellerHeader>
                <Flex alignItemsFlexEnd>
                  {generateDropDown()}
                  <Flex column>
                    <TravellerSpan>{`${drillDownDimensionLabel}`}</TravellerSpan>
                  </Flex>
                </Flex>
                <DimensionTravellerMenu>
                  {!hideSort &&
                    sortType &&
                    (sortType[graphDataMapping.sortType.value] === 'desc' ? (
                      <Button
                        style={{ padding: '5px 10px' }}
                        variant="primary xs"
                        onClick={() =>
                          updateSortingOrder({
                            value: 'asc',
                            label: 'Ascending'
                          })
                        }
                      >
                        {'Top '}
                        <FaArrowUp fontSize="12px" />
                      </Button>
                    ) : (
                      <Button
                        style={{ padding: '5px 10px' }}
                        variant="primary xs"
                        onClick={() =>
                          updateSortingOrder({
                            value: 'desc',
                            label: 'Descending'
                          })
                        }
                      >
                        {'Bottom '}
                        <FaArrowDown fontSize="12px" />
                      </Button>
                    ))}
                  {chartType !== 'pie' && (
                    <Input
                      style={{
                        height: 14,
                        paddingRight: 0,
                        fontSize: 12,
                        marginLeft: 10,
                        border: '1px solid rgba(120,120,120,.1)',
                        boxShadow: '0 0 1px 0 rgba(120,120,120,.1)'
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setDisplayDataCount(parseInt(event.target.value))
                      }}
                      onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                        changeNoOfData()
                      }}
                      value={String(displayDataCount)}
                      variant="s"
                      type="number"
                      min={String(1)}
                      max={String(drillDownDimensionLength)}
                      step={String(1)}
                    />
                  )}
                  {!fullScreen && !hideFullScreen && (
                    <InfoContainer onClick={() => setFullScreen(true)}>
                      <FaExpand />
                    </InfoContainer>
                  )}
                  <>
                    {!hideDownloadCsv ? (
                      <PopOver
                        style={{ margin: '0 10px' }}
                        variant="Bottom-Right"
                      >
                        <MoreActions>
                          <FaEllipsisV />
                        </MoreActions>
                        <PopOver.Body>
                          <>
                            <Span
                              style={{
                                boxShadow: '0 0 2px 0 rgba(0,0,0,0.4)',
                                transform: 'rotate(45deg)',
                                padding: 5,
                                position: 'absolute',
                                zIndex: 98,
                                right: '15%',
                                top: '-15%',
                                background: '#fff',
                                clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%)'
                              }}
                            ></Span>
                            <Flex
                              style={{
                                width: 'max-content',
                                zIndex: 99,
                                position: 'relative',
                                background: '#fff'
                              }}
                              column
                            >
                              <Flex
                                alignItemsCenter
                                p={4}
                                style={{
                                  borderBottom: '1px solid rgba(232,232,232)',
                                  cursor: 'pointer'
                                }}
                                onClick={() => downloadCsv()}
                              >
                                <FaFileDownload
                                  size={16}
                                  style={{ marginRight: 5, color: '#2690ff' }}
                                />
                                <Span variant="secondary">Export as Csv</Span>
                              </Flex>
                            </Flex>
                          </>
                        </PopOver.Body>
                      </PopOver>
                    ) : null}
                  </>
                </DimensionTravellerMenu>
              </DimensionTravellerHeader>
              <>{generateTextForDrillDown()}</>
              <Flex justifyContentFlexEnd m={4}>
                {/* {generateBenchmarkInput()} */}
                {selectedDrillDownDimensionIndex.index !== 0 && (
                  <ResetContainer
                    onClick={() =>
                      setSelectedDrillDownDimensionIndex({
                        index: selectedDrillDownDimensionIndex.index - 1,
                        isReload: false
                      })
                    }
                  >
                    <DimensionResetArrow>
                      <FaArrowLeft />
                    </DimensionResetArrow>
                    Back
                  </ResetContainer>
                )}
                {drillDownDimensions.length > 1 &&
                  selectedDrillDownDimensionIndex.index !== 0 && (
                    <ResetContainer
                      onClick={() =>
                        setSelectedDrillDownDimensionIndex({
                          index: 0,
                          isReload: false
                        })
                      }
                    >
                      <DimensionResetArrow>
                        <FaRedo />
                      </DimensionResetArrow>
                      Reset
                    </ResetContainer>
                  )}
                {!hideTableLayout && (
                  <GroupedButton>
                    <Button
                      onClick={() => setShowGraph(true)}
                      variant={`s ${showGraph ? 'primary' : ''}`}
                      title="Chart"
                    >
                      <FaChartBar size={14} style={{ margin: '-2px 0 -4px' }} />
                    </Button>
                    <Button
                      onClick={() => setShowGraph(false)}
                      variant={`s ${showGraph ? '' : 'primary'}`}
                      title="Table"
                    >
                      <FaTable size={14} style={{ margin: '-2px 0 -4px' }} />
                    </Button>
                  </GroupedButton>
                )}
              </Flex>
            </>
          )}
        </>
      </Flex>
      {fullScreen && firstTime && (
        <GraphRendererFullScreen
          fullScreen={fullScreen || false}
          setFullScreen={setFullScreen}
          config={{ ...config, globalFilter }}
          FullScreenComponent={FullScreenComponent}
        />
      )}
    </>
  )
}
export default DimensionTraveller
