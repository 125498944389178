import {
  Header,
  SearchFilterWrapper,
  setDateFromStorage,
  HeaderFilterContainer,
  TypeSelectorAndSearch,
  SearchSelectResponsive,
  zonedToUTC,
  zonedToUTCAdd1Day
} from '@fareye/common'
import {
  GraphRenderer,
  IApiChartConfig,
  IGraphRendererConfig
} from '@fareye/kpi'
import { IDateRange, IRouteConfig } from '@fareye/types'
import {
  Button,
  DatePicker,
  Flex,
  Label,
  RenderControl,
  SearchSelect,
  usePrevious
} from '@fareye/ui'
import {
  cancelToken,
  cloneDeep,
  currenthhmmA,
  FaCog,
  get,
  getQueryParams,
  groupBy,
  isEmpty,
  isEqual,
  post,
  yyyyMmDd
} from '@fareye/utils'
import queryString, { ParsedQuery } from 'query-string'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { GraphGrid } from '../../styled/styled'
import {
  GRAPH_DATA_MAPPING,
  KPI_COLORS,
  SHIPMENT_TYPES
} from '../../utility/constants'
//import KPI_CONFIG from '../OceanStatsKpi.json'
// import KPI_CONFIG from './sustainableDashboard.json'
import FullScreenComponent, { pageId } from './GraphFullScreen'
import ConfigureKpi from './ConfigureKpi'
import { getDates } from '../../utility/func'
import { FaRegChartBar } from 'react-icons/fa'

interface IKpi extends RouteComponentProps {
  routesConfig?: Partial<IRouteConfig>[]
  config: any
}

const MultiModalDashboard = ({
  match,
  location,
  history,
  routesConfig,
  config: dashboardConfig
}: IKpi) => {
  const searchParams: ParsedQuery<string | Date> = queryString.parse(
    location.search
  )
  const { filters, config, KPI_CONFIG = [] } = dashboardConfig
  const [dateRange, setDateRange] = React.useState(() => {
    return setDateFromStorage({
      showDatePicker: true,
      carryDate: true,
      searchParams,
      daysDiff: 10
    })
  })
  const [selectedShipmentType, setSelectedShipmentType] = React.useState(
    SHIPMENT_TYPES[1]
  )
  const [modal, setModal] = React.useState(false)
  const [selectedKey, setSelectedKey] = React.useState('all')
  const source = cancelToken().source()
  const [lastUpdate, setLastUpdate] = React.useState<string>('')
  const [loading, setLoading] = React.useState(false)
  //baseURL = 'http://localhost:3001'
  const [selectedFilter, setSelectedFilter] = React.useState<any>([])
  const dataConfig = {
    getDataForChart: getDataForChartFromServer,
    graphDataMapping: GRAPH_DATA_MAPPING
  }

  const { toggleContent, showTimezone = true } = config
  const previousDateRange = usePrevious(dateRange)
  const [graphRenderListing, setGraphRenderListing] = React.useState<
    IGraphRendererConfig[]
  >([])
  const apiFav = filters.apiFav

  const apiFilter = React.useMemo(() => {
    let filterConfig = {
      ...filters.apiFilter,
      queryParams: { data_source: selectedShipmentType.dataSource }
    }
    return filterConfig
  }, [selectedShipmentType])

  let endDate = new Date(dateRange.endDate)
  let startDate = new Date(dateRange.endDate)
  startDate.setDate(startDate.getDate() - 7)
  let dates = getDates(startDate, endDate)
  React.useEffect(() => {
    setLoading(true)
    fetchConfig()
  }, [selectedShipmentType])

  React.useEffect(() => {
    if (!isEmpty(graphRenderListing)) {
      const clonedGraphRenderListing = cloneDeep(graphRenderListing)
      clonedGraphRenderListing.forEach(
        ele =>
          (ele.globalFilter = {
            dateRange: dateRange,
            selectedFilter: selectedFilter,
            selectedKey
          })
      )
      setGraphRenderListing(clonedGraphRenderListing)
      if (!isEqual(previousDateRange, dateRange)) {
        const changedQueryParam = getQueryParams({
          ...searchParams,
          ...dateRange
        })
        history.replace({
          pathname: match.url,
          search: changedQueryParam
        })
      }
    }
  }, [dateRange, selectedFilter, selectedKey])
  const globalFilter = React.useMemo(() => {
    return {
      dateRange: dateRange,
      selectedFilter: selectedFilter,
      selectedKey,
      dataSource: selectedShipmentType
    }
  }, [dateRange, selectedFilter, selectedKey, selectedShipmentType])
  async function fetchConfig() {
    let { graphData } = config
    try {
      // const queryParam = getQueryParams(graphData.queryParams)
      // const jsonUrl = `${graphData.apiUrl}?${queryParam}`
      //const jsonUrl = `http://localhost:3001/data`
      // const { data } = await get(jsonUrl, source.token)
      //   const kpiRenderingConfig = KPI_CONFIG
      // const kpiRenderingConfig = isEmpty(data.configs)
      //   ? data.samples
      //   : data.configs
      const kpiData = [...KPI_CONFIG] as any[]
      // = kpiRenderingConfig
      //   .sort((next: any, pre: any) => next.data.order - pre.data.order)
      //   .map((ele: any) => ele.data)
      kpiData.map((ele: any) => {
        ele.dataConfig = dataConfig
        ele.graphConfig.colors = { ...KPI_COLORS, ...ele.graphConfig.colors }
        ele.graphConfig.dataSource = selectedShipmentType
        ele.globalFilter = {
          dateRange: dateRange,
          selectedFilter: selectedFilter
        }
        return false
      })
      console.log(kpiData)
      setGraphRenderListing(kpiData)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
    }
  }
  console.log(graphRenderListing)
  async function getDataForChartFromServer(
    value: Partial<IApiChartConfig>,
    globalFilter: any,
    isReport?: boolean
  ) {
    const { graphDataMapping } = dataConfig
    const {
      dataSource,
      metric,
      metrics,
      hideDimension,
      metricUnit,
      dimension,
      breakdownDimension: breakdownDimensionFromKpi,
      benchmarkDimension: benchmarkDimensionFromKpi,
      filter: filterFromKpi,
      sortKey,
      sortType,
      size
    } = value
    const { dateRange, selectedFilter, selectedKey }: any = globalFilter || {}
    let dataSourceValue = dimension[graphDataMapping['dimension'].value]
    if (dataSourceValue === 'co2') {
      return generateCo2Data()
    } else if (dataSourceValue === 'carrierEmission') {
      console.log(dataSourceValue, '***')
      return generateEmissionData()
    } else if (dataSourceValue === 'carrierEmission1') {
      return generateData1()
    } else if (dataSourceValue === 'carrierEmission2') {
      return generateData2()
    } else if (dataSourceValue === 'dailyDispatch') {
      return generateDailyDispatch()
    } else if (dataSourceValue === 'loadingTat') {
      return loadingTat()
    } else if (dataSourceValue === 'unloadingTat') {
      return unloadingTat()
    } else if (dataSourceValue === 'carrierEmission4') {
      return carrierCo2Emission()
    } else if (dataSourceValue === 'delayStatus') {
      return delayStatus()
    } else if (dataSourceValue === 'detention') {
      return detention()
    } else if (dataSourceValue === 'demurrage') {
      return demurrage()
    } else if (dataSourceValue === 'dwellPol') {
      return dwellPol()
    } else if (dataSourceValue === 'dwellPod') {
      return dwellPod()
    } else if (dataSourceValue === 'onTimeDeparture') {
      return onTimeDeparture()
    } else if (dataSourceValue === 'onTimeDepartureAtPorts') {
      return onTimeDepartureAtPorts()
    } else if (dataSourceValue === 'stateWiseTatYard') {
      return stateWiseTatYard()
    }
    let breakDownDimension, benchmarkDimension, sort, metricCustom
    if (breakdownDimensionFromKpi) {
      breakDownDimension = {
        label: breakdownDimensionFromKpi['value']
      }
    }
    metricCustom = {
      key: metric.aggKey['value'],
      type: metric.aggType['value'],
      unit: metricUnit ? metricUnit['value'] : ''
    }
    if (benchmarkDimensionFromKpi) {
      benchmarkDimension = {
        ...metricCustom,
        value: metric.value,
        breakDownDimension: metric.breakDownDimension
      }
    }

    sort = {
      ...(sortType ? { order: sortType['value'] } : {}),
      sortable: sortKey ? 'metric' : 'dimension',
      size: size || 10
    }
    let filter = filterFromKpi
      ? filterFromKpi.map(filterList => {
          return filterList.map(ele => {
            return {
              filterKey: ele.filterKey['value'],
              filterValue: ele.filterValue['value'],
              condition: ele.condition['value'],
              filterType: ele.filterType['value']
            }
          })
        })
      : []
    filter.push([
      {
        filterKey: 'start_date',
        filterValue: dateRange ? zonedToUTC(dateRange.startDate) : new Date(),
        //filterValue: '2019-09-10',
        condition: 'include',
        filterType: 'gte'
      }
    ])
    filter.push([
      {
        filterKey: 'start_date',
        filterValue: dateRange
          ? zonedToUTCAdd1Day(dateRange.endDate)
          : new Date(),
        //filterValue: '2019-09-10',
        condition: 'include',
        filterType: 'lte'
      }
    ])
    if (!isEmpty(selectedFilter)) {
      const groupedSearch = groupBy(selectedFilter, ele => ele.key)
      const data = Object.entries(groupedSearch).map(([key, value]) => {
        return [
          {
            filterKey: key,
            filterValue: value.reduce((res = [], obj) => {
              res.push(obj.value)
              return res
            }, []),
            condition: 'include',
            filterType: 'eq'
          }
        ]
      })
      filter = [...filter, ...data]
    }
    if (selectedKey === 'active') {
      filter.push([
        {
          filterKey: 'is_active',
          filterValue: true,
          condition: 'include',
          filterType: 'eq'
        }
      ])
    }
    let query = {
      dataSource: dataSource[graphDataMapping['dataSource'].value],
      ...(!isEmpty(breakDownDimension)
        ? {
            breakDownDimension,
            ...{
              ...(metricCustom.key === 'value_count'
                ? {}
                : { metrics: [metricCustom] })
            }
          }
        : isEmpty(benchmarkDimension)
        ? { metrics: [metricCustom] }
        : {}),
      ...(hideDimension
        ? {}
        : {
            dimension: {
              label: dimension[graphDataMapping['dimension'].value]
            }
          }),
      //   ...(breakDownDimension && { breakDownDimension }),
      ...(benchmarkDimension && { benchmarkDimension }),
      sort,
      filter
    }

    try {
      const token = cancelToken().source().token
      const { data } = await post(
        `/api/v1/es/kpi_${isReport ? 'results' : 'aggs'}`,
        query,
        token
      )
      setLastUpdate(currenthhmmA())
      return isReport ? data.results : data.aggs
    } catch (ex) {
      setLastUpdate(currenthhmmA())
      return []
    }
  }
  function generateCo2Data() {
    let data = [
      {
        label: dates[0],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Ryder Trucking',
            value: 7
          },
          {
            label: 'Coyote Trucking',
            value: 9
          },
          {
            label: 'Saia Trucking Co',
            value: 0
          },
          {
            label: 'Pacella Trucking Co',
            value: 6
          },
          {
            label: 'Esco Trans',
            value: 1
          }
        ]
      },
      {
        label: dates[1],
        count: 18,
        value: 18,
        breakDownDimension: [
          {
            label: 'Ryder Trucking',
            value: 3
          },
          {
            label: 'Coyote Trucking',
            value: 5
          },
          {
            label: 'Saia Trucking Co',
            value: 1
          },
          {
            label: 'Pacella Trucking Co',
            value: 3
          },
          {
            label: 'Esco Trans',
            value: 6
          }
        ]
      },
      {
        label: dates[2],
        count: 4,
        value: 28,
        breakDownDimension: [
          {
            label: 'Ryder Trucking',
            value: 2
          },
          {
            label: 'Coyote Trucking',
            value: 2
          },
          {
            label: 'Saia Trucking Co',
            value: 8
          },
          {
            label: 'Pacella Trucking Co',
            value: 12
          },
          {
            label: 'Esco Trans',
            value: 4
          }
        ]
      },
      {
        label: dates[3],
        count: 28,
        value: 28,
        breakDownDimension: [
          {
            label: 'Ryder Trucking',
            value: 3
          },
          {
            label: 'Coyote Trucking',
            value: 5
          },
          {
            label: 'Saia Trucking Co',
            value: 6
          },
          {
            label: 'Pacella Trucking Co',
            value: 9
          },
          {
            label: 'Esco Trans',
            value: 6
          }
        ]
      },
      {
        label: dates[4],
        count: 33,
        value: 33,
        breakDownDimension: [
          {
            label: 'Ryder Trucking',
            value: 1
          },
          {
            label: 'Coyote Trucking',
            value: 10
          },
          {
            label: 'Saia Trucking Co',
            value: 4
          },
          {
            label: 'Pacella Trucking Co',
            value: 8
          },
          {
            label: 'Esco Trans',
            value: 9
          }
        ]
      },
      {
        label: dates[5],
        count: 21,
        value: 21,
        breakDownDimension: [
          {
            label: 'Ryder Trucking',
            value: 6
          },
          {
            label: 'Coyote Trucking',
            value: 0
          },
          {
            label: 'Saia Trucking Co',
            value: 1
          },
          {
            label: 'Pacella Trucking Co',
            value: 7
          },
          {
            label: 'Esco Trans',
            value: 7
          }
        ]
      },
      {
        label: dates[6],
        count: 21,
        value: 21,
        breakDownDimension: [
          {
            label: 'Ryder Trucking',
            value: 3
          },
          {
            label: 'Coyote Trucking',
            value: 2
          },
          {
            label: 'Saia Trucking Co',
            value: 3
          },
          {
            label: 'Pacella Trucking Co',
            value: 10
          },
          {
            label: 'Esco Trans',
            value: 2
          }
        ]
      }
    ]
    return data
  }
  function generateEmissionData() {
    let data = [
      {
        label: 'Lyons-Princeton',
        value: 27,
        count: 27,
        breakDownDimension: [
          {
            label: 'Predicted Co2 emission',
            value: 12
          },
          // {
          //   label: 'Actual Co2 emission',
          //   value: 13.5
          // },
          {
            label: 'Co2 exceeded by',
            value: 1.5
          }
        ]
      },
      {
        label: 'Puerto-Yokohama',
        value: 54,
        count: 54,
        breakDownDimension: [
          {
            label: 'Predicted Co2 emission',
            value: 20
          },
          // {
          //   label: 'Actual Co2 emission',
          //   value: 27
          // },
          {
            label: 'Co2 exceeded by',
            value: 7
          }
        ]
      },
      {
        label: 'Shanghai-Chicago',
        value: 58,
        count: 58,
        breakDownDimension: [
          {
            label: 'Predicted Co2 emission',
            value: 27
          },
          // {
          //   label: 'Actual Co2 emission',
          //   value: 29
          // },
          {
            label: 'Co2 exceeded by',
            value: 2
          }
        ]
      },
      {
        label: 'Guaymas-Puerto',
        value: 32,
        count: 32,
        breakDownDimension: [
          {
            label: 'Predicted Co2 emission',
            value: 14
          },
          // {
          //   label: 'Actual Co2 emission',
          //   value: 16
          // },
          {
            label: 'Co2 exceeded by',
            value: 2
          }
        ]
      },
      {
        label: 'Fort Worth-San Antonio',
        value: 27,
        count: 27,
        breakDownDimension: [
          {
            label: 'Predicted Co2 emission',
            value: 12
          },
          // {
          //   label: 'Actual Co2 emission',
          //   value: 13
          // },
          {
            label: 'Co2 exceeded by',
            value: 1
          }
        ]
      },
      {
        label: 'Catrina-Puerto',
        value: 27,
        count: 27,
        breakDownDimension: [
          {
            label: 'Predicted Co2 emission',
            value: 7
          },
          // {
          //   label: 'Actual Co2 emission',
          //   value: 7.5
          // },
          {
            label: 'Co2 exceeded by',
            value: 0.5
          }
        ]
      }
    ]
    return data
  }

  function generateData1() {
    let data = [
      {
        label: 'Road',
        value: 1500,
        count: 1500
      },
      {
        label: 'Ocean',
        value: 1000,
        count: 1000
      },
      {
        label: 'Air',
        value: 2500,
        count: 2500
      },
      {
        label: 'Rail',
        value: 1300,
        count: 1300
      }
    ]
    return data
  }

  function generateData2() {
    let data = [
      {
        label: 'Road',
        value: 1257,
        count: 1257
      },
      {
        label: 'Ocean',
        value: 1535,
        count: 1535
      },
      {
        label: 'Air',
        value: 1079,
        count: 1079
      },
      {
        label: 'Rail',
        value: 350,
        count: 350
      }
    ]
    return data
  }

  function generateDailyDispatch() {
    let data = [
      {
        label: dates[0],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 32
          },
          {
            label: 'Lyons',
            value: 22
          },
          {
            label: 'Wenerberg',
            value: 21
          },
          {
            label: 'O-Hare',
            value: 6
          },
          {
            label: 'Puerto Quetzal',
            value: 2
          }
        ]
      },
      {
        label: dates[1],
        count: 18,
        value: 18,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 29
          },
          {
            label: 'Lyons',
            value: 19
          },
          {
            label: 'Wenerberg',
            value: 25
          },
          {
            label: 'O-Hare',
            value: 6
          },
          {
            label: 'Puerto Quetzal',
            value: 2
          }
        ]
      },
      {
        label: dates[2],
        count: 4,
        value: 28,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 30
          },
          {
            label: 'Lyons',
            value: 20
          },
          {
            label: 'Wenerberg',
            value: 21
          },
          {
            label: 'O-Hare',
            value: 6
          },
          {
            label: 'Puerto Quetzal',
            value: 1
          }
        ]
      },
      {
        label: dates[3],
        count: 28,
        value: 28,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 26
          },
          {
            label: 'Lyons',
            value: 18
          },
          {
            label: 'Wenerberg',
            value: 24
          },
          {
            label: 'O-Hare',
            value: 8
          },
          {
            label: 'Puerto Quetzal',
            value: 3
          }
        ]
      },
      {
        label: dates[4],
        count: 33,
        value: 33,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 23
          },
          {
            label: 'Lyons',
            value: 20
          },
          {
            label: 'Wenerberg',
            value: 14
          },
          {
            label: 'O-Hare',
            value: 7
          },
          {
            label: 'Puerto Quetzal',
            value: 1
          }
        ]
      },
      {
        label: dates[5],
        count: 21,
        value: 21,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 31
          },
          {
            label: 'Lyons',
            value: 20
          },
          {
            label: 'Wenerberg',
            value: 14
          },
          {
            label: 'O-Hare',
            value: 15
          },
          {
            label: 'Puerto Quetzal',
            value: 1
          }
        ]
      },
      {
        label: dates[6],
        count: 21,
        value: 21,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 32
          },
          {
            label: 'Lyons',
            value: 23
          },
          {
            label: 'Wenerberg',
            value: 24
          },
          {
            label: 'O-Hare',
            value: 5
          },
          {
            label: 'Puerto Quetzal',
            value: 2
          }
        ]
      }
    ]
    return data
  }

  function loadingTat() {
    let data = [
      {
        label: dates[0],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 12.5
          },
          {
            label: 'Lyons',
            value: 11
          },
          {
            label: 'Wenerberg',
            value: 14
          },
          {
            label: 'O-Hare',
            value: 2
          },
          {
            label: 'Puerto Quetzal',
            value: 20
          }
        ]
      },
      {
        label: dates[1],
        count: 18,
        value: 18,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 11
          },
          {
            label: 'Lyons',
            value: 11
          },
          {
            label: 'Wenerberg',
            value: 16
          },
          {
            label: 'O-Hare',
            value: 2
          },
          {
            label: 'Puerto Quetzal',
            value: 22
          }
        ]
      },
      {
        label: dates[2],
        count: 4,
        value: 28,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 13
          },
          {
            label: 'Lyons',
            value: 11
          },
          {
            label: 'Wenerberg',
            value: 17
          },
          {
            label: 'O-Hare',
            value: 1
          },
          {
            label: 'Puerto Quetzal',
            value: 21
          }
        ]
      },
      {
        label: dates[3],
        count: 28,
        value: 28,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 13
          },
          {
            label: 'Lyons',
            value: 11
          },
          {
            label: 'Wenerberg',
            value: 14
          },
          {
            label: 'O-Hare',
            value: 3
          },
          {
            label: 'Puerto Quetzal',
            value: 26
          }
        ]
      },
      {
        label: dates[4],
        count: 33,
        value: 33,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 12
          },
          {
            label: 'Lyons',
            value: 9
          },
          {
            label: 'Wenerberg',
            value: 17
          },
          {
            label: 'O-Hare',
            value: 1
          },
          {
            label: 'Puerto Quetzal',
            value: 21
          }
        ]
      },
      {
        label: dates[5],
        count: 21,
        value: 21,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 13
          },
          {
            label: 'Lyons',
            value: 9
          },
          {
            label: 'Wenerberg',
            value: 17
          },
          {
            label: 'O-Hare',
            value: 1
          },
          {
            label: 'Puerto Quetzal',
            value: 20
          }
        ]
      },
      {
        label: dates[6],
        count: 21,
        value: 21,
        breakDownDimension: [
          {
            label: '1st Street Center',
            value: 11
          },
          {
            label: 'Lyons',
            value: 12
          },
          {
            label: 'Wenerberg',
            value: 15
          },
          {
            label: 'O-Hare',
            value: 1
          },
          {
            label: 'Puerto Quetzal',
            value: 23
          }
        ]
      }
    ]
    return data
  }

  function unloadingTat() {
    let data = [
      {
        label: dates[0],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Yokohama',
            value: 3
          },
          {
            label: 'Minooka',
            value: 8
          },
          {
            label: 'Shorewood',
            value: 5
          },
          {
            label: 'Edenburg',
            value: 2
          },
          {
            label: 'San Ontonia',
            value: 1.5
          }
        ]
      },
      {
        label: dates[1],
        count: 18,
        value: 18,
        breakDownDimension: [
          {
            label: 'Yokohama',
            value: 4
          },
          {
            label: 'Minooka',
            value: 8
          },
          {
            label: 'Shorewood',
            value: 6
          },
          {
            label: 'Edenburg',
            value: 1
          },
          {
            label: 'San Ontonia',
            value: 0.1
          }
        ]
      },
      {
        label: dates[2],
        count: 4,
        value: 28,
        breakDownDimension: [
          {
            label: 'Yokohama',
            value: 4
          },
          {
            label: 'Minooka',
            value: 7
          },
          {
            label: 'Shorewood',
            value: 6
          },
          {
            label: 'Edenburg',
            value: 2
          },
          {
            label: 'San Ontonia',
            value: 1.4
          }
        ]
      },
      {
        label: dates[3],
        count: 28,
        value: 28,
        breakDownDimension: [
          {
            label: 'Yokohama',
            value: 3
          },
          {
            label: 'Minooka',
            value: 8
          },
          {
            label: 'Shorewood',
            value: 5
          },
          {
            label: 'Edenburg',
            value: 2
          },
          {
            label: 'San Ontonia',
            value: 1.5
          }
        ]
      },
      {
        label: dates[4],
        count: 33,
        value: 33,
        breakDownDimension: [
          {
            label: 'Yokohama',
            value: 5
          },
          {
            label: 'Minooka',
            value: 6
          },
          {
            label: 'Shorewood',
            value: 5
          },
          {
            label: 'Edenburg',
            value: 2
          },
          {
            label: 'San Ontonia',
            value: 0.7
          }
        ]
      },
      {
        label: dates[5],
        count: 21,
        value: 21,
        breakDownDimension: [
          {
            label: 'Yokohama',
            value: 3
          },
          {
            label: 'Minooka',
            value: 7
          },
          {
            label: 'Shorewood',
            value: 4
          },
          {
            label: 'Edenburg',
            value: 3
          },
          {
            label: 'San Ontonia',
            value: 1.5
          }
        ]
      },
      {
        label: dates[6],
        count: 21,
        value: 21,
        breakDownDimension: [
          {
            label: 'Yokohama',
            value: 2
          },
          {
            label: 'Minooka',
            value: 7
          },
          {
            label: 'Shorewood',
            value: 4
          },
          {
            label: 'Edenburg',
            value: 2
          },
          {
            label: 'San Ontonia',
            value: 0.6
          }
        ]
      }
    ]
    return data
  }

  function carrierCo2Emission() {
    let data = [
      {
        label: 'Coyote Trucking',
        value: 61,
        count: 61
      },
      {
        label: 'Saia Trucking Co',
        value: 72,
        count: 72
      },
      {
        label: 'Pacella Trucking Co',
        value: 89,
        count: 89
      },
      {
        label: 'Esco Trans',
        value: 65,
        count: 65
      },
      {
        label: 'UPS',
        value: 89,
        count: 89
      },
      {
        label: 'Landstar',
        value: 70,
        count: 70
      },
      {
        label: 'JB hunt',
        value: 75,
        count: 75
      },

      {
        label: 'Maersk Line',
        value: 16.14,
        count: 16.14
      },
      {
        label: 'CMA-CGM',
        value: 18.21,
        count: 18.21
      },
      {
        label: 'Tortoise cart (in gms)',
        value: 1,
        count: 1
      },
      {
        label: 'Cma Cgm',
        value: 15,
        count: 15
      },
      {
        label: 'Ocean Flyer',
        value: 19,
        count: 19
      },
      {
        label: 'Maersk Line',
        value: 14,
        count: 14
      }
    ]
    return data
  }

  function delayStatus() {
    let data = [
      {
        label: '0-3 days',
        value: 24,
        count: 24
      },
      {
        label: '3-6 days',
        value: 13,
        count: 13
      },
      {
        label: '6-9 days',
        value: 9,
        count: 9
      },
      {
        label: '9 days +',
        value: 2,
        count: 2
      }
    ]
    return data
  }

  function demurrage() {
    let data = [
      {
        label: dates[0],
        count: 19,
        value: 19,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 5,
            count: 5
          },
          {
            label: 'Lazaro Cardenas',
            value: 3,
            count: 3
          },
          {
            label: 'Yokohama',
            value: 1,
            count: 1
          },
          {
            label: 'Hong Kong',
            value: 2,
            count: 2
          },
          {
            label: 'Puerto Quetzal',
            value: 4,
            count: 4
          },
          {
            label: 'Qingdao',
            value: 5,
            count: 5
          }
        ]
      },
      {
        label: dates[1],
        count: 18,
        value: 18,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 4,
            count: 4
          },
          {
            label: 'Lazaro Cardenas',
            value: 1,
            count: 1
          },
          {
            label: 'Yokohama',
            value: 2,
            count: 2
          },
          {
            label: 'Hong Kong',
            value: 5,
            count: 5
          },
          {
            label: 'Puerto Quetzal',
            value: 1,
            count: 1
          },
          {
            label: 'Qingdao',
            value: 3,
            count: 3
          }
        ]
      },
      {
        label: dates[2],
        count: 4,
        value: 28,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 1,
            count: 1
          },
          {
            label: 'Lazaro Cardenas',
            value: 4,
            count: 4
          },
          {
            label: 'Yokohama',
            value: 3,
            count: 3
          },
          {
            label: 'Hong Kong',
            value: 5,
            count: 5
          },
          {
            label: 'Puerto Quetzal',
            value: 2,
            count: 2
          },
          {
            label: 'Qingdao',
            value: 1,
            count: 1
          }
        ]
      },
      {
        label: dates[3],
        count: 28,
        value: 28,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 5,
            count: 5
          },
          {
            label: 'Lazaro Cardenas',
            value: 3,
            count: 3
          },
          {
            label: 'Yokohama',
            value: 5,
            count: 5
          },
          {
            label: 'Hong Kong',
            value: 4,
            count: 4
          },
          {
            label: 'Puerto Quetzal',
            value: 5,
            count: 5
          },
          {
            label: 'Qingdao',
            value: 3,
            count: 3
          }
        ]
      },
      {
        label: dates[4],
        count: 33,
        value: 33,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 5,
            count: 5
          },
          {
            label: 'Lazaro Cardenas',
            value: 3,
            count: 3
          },
          {
            label: 'Yokohama',
            value: 5,
            count: 5
          },
          {
            label: 'Hong Kong',
            value: 4,
            count: 4
          },
          {
            label: 'Puerto Quetzal',
            value: 5,
            count: 5
          },
          {
            label: 'Qingdao',
            value: 3,
            count: 3
          }
        ]
      },
      {
        label: dates[5],
        count: 21,
        value: 21,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 5,
            count: 5
          },
          {
            label: 'Lazaro Cardenas',
            value: 3,
            count: 3
          },
          {
            label: 'Yokohama',
            value: 5,
            count: 5
          },
          {
            label: 'Hong Kong',
            value: 4,
            count: 4
          },
          {
            label: 'Puerto Quetzal',
            value: 5,
            count: 5
          },
          {
            label: 'Qingdao',
            value: 3,
            count: 3
          }
        ]
      },
      {
        label: dates[6],
        count: 21,
        value: 21,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 5,
            count: 5
          },
          {
            label: 'Lazaro Cardenas',
            value: 3,
            count: 3
          },
          {
            label: 'Yokohama',
            value: 5,
            count: 5
          },
          {
            label: 'Hong Kong',
            value: 4,
            count: 4
          },
          {
            label: 'Puerto Quetzal',
            value: 5,
            count: 5
          },
          {
            label: 'Qingdao',
            value: 3,
            count: 3
          }
        ]
      }
    ]
    return data
  }
  function detention() {
    let data = [
      {
        label: dates[0],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 5,
            count: 5
          },
          {
            label: 'Lazaro Cardenas',
            value: 3,
            count: 3
          },
          {
            label: 'Yokohama',
            value: 5,
            count: 5
          },
          {
            label: 'Hong Kong',
            value: 4,
            count: 4
          },
          {
            label: 'Puerto Quetzal',
            value: 5,
            count: 5
          },
          {
            label: 'Qingdao',
            value: 3,
            count: 3
          }
        ]
      },
      {
        label: dates[1],
        count: 18,
        value: 18,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 3,
            count: 3
          },
          {
            label: 'Lazaro Cardenas',
            value: 1,
            count: 1
          },
          {
            label: 'Yokohama',
            value: 2,
            count: 2
          },
          {
            label: 'Hong Kong',
            value: 2,
            count: 2
          },
          {
            label: 'Puerto Quetzal',
            value: 2,
            count: 2
          },
          {
            label: 'Qingdao',
            value: 1,
            count: 1
          }
        ]
      },
      {
        label: dates[2],
        count: 4,
        value: 28,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 3,
            count: 3
          },
          {
            label: 'Lazaro Cardenas',
            value: 1,
            count: 1
          },
          {
            label: 'Yokohama',
            value: 3,
            count: 3
          },
          {
            label: 'Hong Kong',
            value: 4,
            count: 4
          },
          {
            label: 'Puerto Quetzal',
            value: 2,
            count: 2
          },
          {
            label: 'Qingdao',
            value: 2,
            count: 2
          }
        ]
      },
      {
        label: dates[3],
        count: 28,
        value: 28,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 1,
            count: 1
          },
          {
            label: 'Lazaro Cardenas',
            value: 3,
            count: 3
          },
          {
            label: 'Yokohama',
            value: 5,
            count: 5
          },
          {
            label: 'Hong Kong',
            value: 2,
            count: 2
          },
          {
            label: 'Puerto Quetzal',
            value: 1,
            count: 1
          },
          {
            label: 'Qingdao',
            value: 1,
            count: 1
          }
        ]
      },
      {
        label: dates[4],
        count: 33,
        value: 33,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 2,
            count: 2
          },
          {
            label: 'Lazaro Cardenas',
            value: 5,
            count: 5
          },
          {
            label: 'Yokohama',
            value: 3,
            count: 3
          },
          {
            label: 'Hong Kong',
            value: 1,
            count: 1
          },
          {
            label: 'Puerto Quetzal',
            value: 5,
            count: 5
          },
          {
            label: 'Qingdao',
            value: 4,
            count: 4
          }
        ]
      },
      {
        label: dates[5],
        count: 21,
        value: 21,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 1,
            count: 1
          },
          {
            label: 'Lazaro Cardenas',
            value: 1,
            count: 1
          },
          {
            label: 'Yokohama',
            value: 4,
            count: 4
          },
          {
            label: 'Hong Kong',
            value: 2,
            count: 2
          },
          {
            label: 'Puerto Quetzal',
            value: 2,
            count: 2
          },
          {
            label: 'Qingdao',
            value: 2,
            count: 2
          }
        ]
      },
      {
        label: dates[6],
        count: 21,
        value: 21,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 1,
            count: 1
          },
          {
            label: 'Lazaro Cardenas',
            value: 2,
            count: 2
          },
          {
            label: 'Yokohama',
            value: 4,
            count: 4
          },
          {
            label: 'Hong Kong',
            value: 4,
            count: 4
          },
          {
            label: 'Puerto Quetzal',
            value: 5,
            count: 5
          },
          {
            label: 'Qingdao',
            value: 2,
            count: 2
          }
        ]
      }
    ]
    return data
  }

  function dwellPol() {
    let data = [
      {
        label: dates[0],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 9.5
          },
          {
            label: 'Hong Kong',
            value: 5.9
          },
          {
            label: 'Guangzhou',
            value: 6.1
          },
          {
            label: 'Pusan',
            value: 10.5
          },
          {
            label: 'Lazaro Cardenas',
            value: 7
          },
          {
            label: 'Puerto Quetzal',
            value: 11.5
          },
          {
            label: 'Rotterdam',
            value: 10
          }
        ]
      },
      {
        label: dates[1],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 7.5
          },
          {
            label: 'Hong Kong',
            value: 6
          },
          {
            label: 'Guangzhou',
            value: 5.9
          },
          {
            label: 'Pusan',
            value: 10
          },
          {
            label: 'Lazaro Cardenas',
            value: 5.9
          },
          {
            label: 'Puerto Quetzal',
            value: 11
          },
          {
            label: 'Rotterdam',
            value: 9.5
          }
        ]
      },
      {
        label: dates[2],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 8.7
          },
          {
            label: 'Hong Kong',
            value: 7.9
          },
          {
            label: 'Guangzhou',
            value: 5.3
          },
          {
            label: 'Pusan',
            value: 10.3
          },
          {
            label: 'Lazaro Cardenas',
            value: 9.1
          },
          {
            label: 'Puerto Quetzal',
            value: 12
          },
          {
            label: 'Rotterdam',
            value: 11
          }
        ]
      },
      {
        label: dates[3],
        count: 28,
        value: 28,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 8.3
          },
          {
            label: 'Hong Kong',
            value: 7.1
          },
          {
            label: 'Guangzhou',
            value: 6
          },
          {
            label: 'Pusan',
            value: 9
          },
          {
            label: 'Lazaro Cardenas',
            value: 9.5
          },
          {
            label: 'Puerto Quetzal',
            value: 11.5
          },
          {
            label: 'Rotterdam',
            value: 10.5
          }
        ]
      },
      {
        label: dates[4],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 7.5
          },
          {
            label: 'Hong Kong',
            value: 7
          },
          {
            label: 'Guangzhou',
            value: 5.6
          },
          {
            label: 'Pusan',
            value: 8.5
          },
          {
            label: 'Lazaro Cardenas',
            value: 9.3
          },
          {
            label: 'Puerto Quetzal',
            value: 10.9
          },
          {
            label: 'Rotterdam',
            value: 9.8
          }
        ]
      },
      {
        label: dates[5],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 7.25
          },
          {
            label: 'Hong Kong',
            value: 6.6
          },
          {
            label: 'Guangzhou',
            value: 6
          },
          {
            label: 'Pusan',
            value: 8
          },
          {
            label: 'Lazaro Cardenas',
            value: 6.7
          },
          {
            label: 'Puerto Quetzal',
            value: 10
          },
          {
            label: 'Rotterdam',
            value: 9.2
          }
        ]
      },
      {
        label: dates[6],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 6.9
          },
          {
            label: 'Hong Kong',
            value: 5.2
          },
          {
            label: 'Guangzhou',
            value: 5.7
          },
          {
            label: 'Pusan',
            value: 8.5
          },
          {
            label: 'Lazaro Cardenas',
            value: 6.5
          },
          {
            label: 'Puerto Quetzal',
            value: 9.7
          },
          {
            label: 'Rotterdam',
            value: 8.5
          }
        ]
      }
    ]
    return data
  }

  function dwellPod() {
    let data = [
      {
        label: dates[0],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 15.5
          },
          {
            label: 'Lazaro Cardenas',
            value: 10.9
          },
          {
            label: 'Puerto Quetzal',
            value: 10.1
          },
          {
            label: 'New York',
            value: 20.5
          },
          {
            label: 'Hong Kong',
            value: 9
          },
          {
            label: 'Qingdao',
            value: 14.5
          }
        ]
      },
      {
        label: dates[1],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 13.5
          },
          {
            label: 'Lazaro Cardenas',
            value: 20
          },
          {
            label: 'Puerto Quetzal',
            value: 8.9
          },
          {
            label: 'New York',
            value: 20
          },
          {
            label: 'Hong Kong',
            value: 7.9
          },
          {
            label: 'Qingdao',
            value: 14
          }
        ]
      },
      {
        label: dates[2],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 14.7
          },
          {
            label: 'Lazaro Cardenas',
            value: 21.9
          },
          {
            label: 'Puerto Quetzal',
            value: 8.3
          },
          {
            label: 'New York',
            value: 20.3
          },
          {
            label: 'Hong Kong',
            value: 11.1
          },
          {
            label: 'Qingdao',
            value: 15
          }
        ]
      },
      {
        label: dates[3],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 14.3
          },
          {
            label: 'Lazaro Cardenas',
            value: 21.1
          },
          {
            label: 'Puerto Quetzal',
            value: 9
          },
          {
            label: 'New York',
            value: 19
          },
          {
            label: 'Hong Kong',
            value: 11.5
          },
          {
            label: 'Qingdao',
            value: 14.5
          }
        ]
      },
      {
        label: dates[4],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 13.5
          },
          {
            label: 'Lazaro Cardenas',
            value: 21
          },
          {
            label: 'Puerto Quetzal',
            value: 8.6
          },
          {
            label: 'New York',
            value: 18.5
          },
          {
            label: 'Hong Kong',
            value: 11.3
          },
          {
            label: 'Qingdao',
            value: 13.9
          }
        ]
      },
      {
        label: dates[5],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 13.25
          },
          {
            label: 'Lazaro Cardenas',
            value: 20.6
          },
          {
            label: 'Puerto Quetzal',
            value: 9
          },
          {
            label: 'New York',
            value: 18
          },
          {
            label: 'Hong Kong',
            value: 8.7
          },
          {
            label: 'Qingdao',
            value: 13
          }
        ]
      },
      {
        label: dates[6],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Vancouver',
            value: 12.9
          },
          {
            label: 'Lazaro Cardenas',
            value: 19.2
          },
          {
            label: 'Puerto Quetzal',
            value: 8.7
          },
          {
            label: 'New York',
            value: 18.5
          },
          {
            label: 'Hong Kong',
            value: 8.5
          },
          {
            label: 'Qingdao',
            value: 12.7
          }
        ]
      }
    ]
    return data
  }

  function onTimeDeparture() {
    let data = [
      {
        label: dates[0],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Sparks',
            value: 62,
            count: 62
          },
          {
            label: 'Toppenish',
            value: 47,
            count: 47
          },
          {
            label: 'Yakima',
            value: 41,
            count: 41
          },
          {
            label: 'Lyons',
            value: 56,
            count: 56
          },
          {
            label: 'Princeton',
            value: 40,
            count: 40
          }
        ]
      },
      {
        label: dates[1],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Sparks',
            value: 69,
            count: 69
          },
          {
            label: 'Toppenish',
            value: 41,
            count: 41
          },
          {
            label: 'Yakima',
            value: 40,
            count: 40
          },
          {
            label: 'Lyons',
            value: 40,
            count: 40
          },
          {
            label: 'Princeton',
            value: 36,
            count: 36
          }
        ]
      },
      {
        label: dates[2],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Sparks',
            value: 62,
            count: 62
          },
          {
            label: 'Toppenish',
            value: 50,
            count: 50
          },
          {
            label: 'Yakima',
            value: 43,
            count: 43
          },
          {
            label: 'Lyons',
            value: 51,
            count: 51
          },
          {
            label: 'Princeton',
            value: 44,
            count: 44
          }
        ]
      },
      {
        label: dates[3],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Sparks',
            value: 50,
            count: 50
          },
          {
            label: 'Toppenish',
            value: 45,
            count: 45
          },
          {
            label: 'Yakima',
            value: 49,
            count: 49
          },
          {
            label: 'Lyons',
            value: 55,
            count: 55
          },
          {
            label: 'Princeton',
            value: 40,
            count: 40
          }
        ]
      },
      {
        label: dates[4],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Sparks',
            value: 45,
            count: 45
          },
          {
            label: 'Toppenish',
            value: 63,
            count: 63
          },
          {
            label: 'Yakima',
            value: 37,
            count: 37
          },
          {
            label: 'Lyons',
            value: 49,
            count: 49
          },
          {
            label: 'Princeton',
            value: 36,
            count: 36
          }
        ]
      },
      {
        label: dates[5],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Sparks',
            value: 40,
            count: 40
          },
          {
            label: 'Toppenish',
            value: 40,
            count: 40
          },
          {
            label: 'Yakima',
            value: 60,
            count: 60
          },
          {
            label: 'Lyons',
            value: 52,
            count: 52
          },
          {
            label: 'Princeton',
            value: 66,
            count: 66
          }
        ]
      },
      {
        label: dates[6],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Sparks',
            value: 35,
            count: 35
          },
          {
            label: 'Toppenish',
            value: 55,
            count: 55
          },
          {
            label: 'Yakima',
            value: 66,
            count: 66
          },
          {
            label: 'Lyons',
            value: 47,
            count: 47
          },
          {
            label: 'Princeton',
            value: 70,
            count: 70
          }
        ]
      }
    ]
    return data
  }
  function onTimeDepartureAtPorts() {
    let data = [
      {
        label: dates[0],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 40,
            count: 40
          },
          {
            label: 'Pusan',
            value: 41,
            count: 41
          },
          {
            label: 'Lazaro Cardenas',
            value: 58,
            count: 58
          },
          {
            label: 'Puerto Quetzal',
            value: 43,
            count: 43
          },
          {
            label: 'Rotterdam',
            value: 40,
            count: 40
          },
          {
            label: 'Hong Kong',
            value: 56,
            count: 56
          },
          {
            label: 'Guangzhou',
            value: 48,
            count: 48
          }
        ]
      },
      {
        label: dates[1],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 58,
            count: 58
          },
          {
            label: 'Pusan',
            value: 34,
            count: 34
          },
          {
            label: 'Lazaro Cardenas',
            value: 42,
            count: 42
          },
          {
            label: 'Puerto Quetzal',
            value: 48,
            count: 48
          },
          {
            label: 'Rotterdam',
            value: 53,
            count: 53
          },
          {
            label: 'Hong Kong',
            value: 53,
            count: 53
          },
          {
            label: 'Guangzhou',
            value: 54,
            count: 54
          }
        ]
      },
      {
        label: dates[2],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 31,
            count: 31
          },
          {
            label: 'Pusan',
            value: 55,
            count: 55
          },
          {
            label: 'Lazaro Cardenas',
            value: 46,
            count: 46
          },
          {
            label: 'Puerto Quetzal',
            value: 56,
            count: 56
          },
          {
            label: 'Rotterdam',
            value: 53,
            count: 53
          },
          {
            label: 'Hong Kong',
            value: 57,
            count: 57
          },
          {
            label: 'Guangzhou',
            value: 42,
            count: 42
          }
        ]
      },
      {
        label: dates[3],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 53,
            count: 53
          },
          {
            label: 'Pusan',
            value: 56,
            count: 56
          },
          {
            label: 'Lazaro Cardenas',
            value: 54,
            count: 54
          },
          {
            label: 'Puerto Quetzal',
            value: 53,
            count: 53
          },
          {
            label: 'Rotterdam',
            value: 52,
            count: 52
          },
          {
            label: 'Hong Kong',
            value: 60,
            count: 60
          },
          {
            label: 'Guangzhou',
            value: 50,
            count: 50
          }
        ]
      },
      {
        label: dates[4],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 46,
            count: 46
          },
          {
            label: 'Pusan',
            value: 36,
            count: 36
          },
          {
            label: 'Lazaro Cardenas',
            value: 35,
            count: 35
          },
          {
            label: 'Puerto Quetzal',
            value: 32,
            count: 32
          },
          {
            label: 'Rotterdam',
            value: 40,
            count: 40
          },
          {
            label: 'Hong Kong',
            value: 34,
            count: 34
          },
          {
            label: 'Guangzhou',
            value: 31,
            count: 31
          }
        ]
      },
      {
        label: dates[5],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 46,
            count: 46
          },
          {
            label: 'Pusan',
            value: 45,
            count: 45
          },
          {
            label: 'Lazaro Cardenas',
            value: 40,
            count: 40
          },
          {
            label: 'Puerto Quetzal',
            value: 60,
            count: 60
          },
          {
            label: 'Rotterdam',
            value: 59,
            count: 59
          },
          {
            label: 'Hong Kong',
            value: 49,
            count: 49
          },
          {
            label: 'Guangzhou',
            value: 39,
            count: 39
          }
        ]
      },
      {
        label: dates[6],
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Shanghai',
            value: 52,
            count: 52
          },
          {
            label: 'Pusan',
            value: 45,
            count: 45
          },
          {
            label: 'Lazaro Cardenas',
            value: 60,
            count: 60
          },
          {
            label: 'Puerto Quetzal',
            value: 34,
            count: 34
          },
          {
            label: 'Rotterdam',
            value: 50,
            count: 50
          },
          {
            label: 'Hong Kong',
            value: 58,
            count: 58
          },
          {
            label: 'Guangzhou',
            value: 43,
            count: 43
          }
        ]
      }
    ]
    return data
  }

  function stateWiseTatYard() {
    let data = [
      {
        label: 'Gate in to Parking in',
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Sparks',
            value: 2,
            count: 2
          },
          {
            label: 'Toppenish',
            value: 1,
            count: 1
          },
          {
            label: 'Yakima',
            value: 4,
            count: 4
          },
          {
            label: 'Lyons',
            value: 2,
            count: 2
          },
          {
            label: 'Princeton',
            value: 2,
            count: 2
          }
        ]
      },
      {
        label: 'Parking in to  L/U in',
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Sparks',
            value: 2,
            count: 2
          },
          {
            label: 'Toppenish',
            value: 2,
            count: 2
          },
          {
            label: 'Yakima',
            value: 1,
            count: 1
          },
          {
            label: 'Lyons',
            value: 1,
            count: 1
          },
          {
            label: 'Princeton',
            value: 2,
            count: 2
          }
        ]
      },
      {
        label: 'L/U in to L/U out',
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Sparks',
            value: '1.22',
            count: '1.22'
          },
          {
            label: 'Toppenish',
            value: '1.27',
            count: '1.27'
          },
          {
            label: 'Yakima',
            value: '0.64',
            count: '0.64'
          },
          {
            label: 'Lyons',
            value: '1.76',
            count: '1.76'
          },
          {
            label: 'Princeton',
            value: '1.63',
            count: '1.63'
          }
        ]
      },
      {
        label: 'L/U out to Gate out',
        count: 23,
        value: 23,
        breakDownDimension: [
          {
            label: 'Sparks',
            value: '0.67',
            count: '0.67'
          },
          {
            label: 'Toppenish',
            value: '1.65',
            count: '1.65'
          },
          {
            label: 'Yakima',
            value: '0.76',
            count: '0.76'
          },
          {
            label: 'Lyons',
            value: '1.89',
            count: '1.89'
          },
          {
            label: 'Princeton',
            value: '0.63',
            count: '0.63'
          }
        ]
      }
    ]
    return data
  }

  return (
    <Flex column>
      <Header
        lastUpdate={lastUpdate}
        match={match}
        location={location}
        history={history}
        routesConfig={routesConfig}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        toggle={toggleContent}
        selectedKey={selectedKey}
        setSelectedKey={setSelectedKey}
        rightTopCorner={
          <DatePicker
            onDateRangeSelected={(dateRange: IDateRange<string | Date>) => {
              localStorage.setItem('date', JSON.stringify(dateRange))
              setDateRange(dateRange as IDateRange<Date>)
            }}
            formatter={yyyyMmDd}
            value={dateRange}
            name="dateFilter"
            variant="fareyeV2"
          />
        }
        showTimezone={showTimezone}
      >
        <HeaderFilterContainer>
          <TypeSelectorAndSearch>
            <SearchSelectResponsive>
              <SearchSelect
                options={SHIPMENT_TYPES || []}
                value={selectedShipmentType}
                placeholder={'Select Shipment Type'}
                onChange={(item: any) => {
                  setSelectedShipmentType(item)
                }}
                style={{
                  container: {
                    width: '100%',
                    borderRadius: '0px'
                  },
                  wrapper: {
                    width: '100%',
                    alignItems: 'flex-start'
                  },
                  control: {
                    height: '46px'
                  },
                  dropdownIndicator: {
                    display: 'block',
                    transform: 'rotate(0deg) !important'
                  }
                }}
              />
            </SearchSelectResponsive>
            <SearchFilterWrapper
              apiFav={apiFav}
              pageId={pageId}
              apiFilter={apiFilter}
              customKeyMapping={(config.filters as any)?.customKeyMapping}
              search={selectedFilter}
              setSearch={setSelectedFilter}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              showTimezone={showTimezone}
            />
            <Button variant="primary" onClick={() => setModal(true)}>
              <div style={{ display: 'flex', cursor: 'pointer' }}>
                <FaRegChartBar
                  size={10}
                  style={{
                    color: '#FFFFFF',
                    border: '1px solid #FFFFFF',
                    padding: '1px',
                    cursor: 'pointer'
                  }}
                ></FaRegChartBar>
                <Label style={{ paddingLeft: '5px', cursor: 'pointer' }}>
                  Configure
                </Label>
              </div>
            </Button>
          </TypeSelectorAndSearch>
        </HeaderFilterContainer>
      </Header>
      <RenderControl
        data={graphRenderListing}
        isSuccess={!loading && !isEmpty(graphRenderListing)}
        isLoading={loading}
        variant={'l'}
        message={
          !isEmpty(graphRenderListing)
            ? ''
            : 'Something went wrong please try after sometime ......'
        }
      >
        <GraphGrid>
          {graphRenderListing.map((ele, index) => {
            return (
              <GraphRenderer
                key={`kpi${index}`}
                {...ele}
                style={{
                  ...ele.style,
                  wrapper: {
                    ...(ele.style && ele.style.wrapper)
                  }
                }}
                FullScreenComponent={
                  <FullScreenComponent
                    dashboardConfig={dashboardConfig}
                    config={ele}
                    location={location}
                    history={history}
                    match={match}
                  />
                }
              />
            )
          })}
        </GraphGrid>
      </RenderControl>
      {modal && (
        <ConfigureKpi
          data={KPI_CONFIG}
          modal={modal}
          setModal={setModal}
        ></ConfigureKpi>
      )}
    </Flex>
  )
}
export default MultiModalDashboard
