import React from 'react'
import { MdMessage } from '@fareye/utils'
import { Flex } from '../../main'
import { IComment } from '../types'

function Commenting({ commentingModule }: IComment) {
  if (!commentingModule) {
    return null
  }
  return (
    <Flex
      data-testid={`comment`}
      alignItemsCenter
      style={{ marginLeft: 10, cursor: 'pointer' }}
      onClick={() => commentingModule(true)}
    >
      <MdMessage style={{ fontSize: '22px' }} color="rgb(3, 138, 241)" />
      <span
        className="issue-raised-text"
        style={{
          fontSize: '16px',
          margin: '5px',
          color: 'rgb(3, 138, 241)'
        }}
      >
        Issues Raised
      </span>
    </Flex>
  )
}

export default Commenting
