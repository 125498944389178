import '@aerisweather/javascript-sdk/dist/styles/styles.css'
import React from 'react'
import { LayersControl, TileLayer } from 'react-leaflet'
import 'react-leaflet-fullscreen/dist/styles.css'
import { GoogleLayer } from 'react-leaflet-google-v2'
import { MapContainer } from './MapContainer'
import { BaseLayer, MapViewProps } from './types'

const MapView = React.forwardRef(
  (
    {
      children,
      mapKey,
      defaultBaseLayer = BaseLayer.OSMMAPNIK,
      leafletMapConfig,
      fullScreenControlPosition = 'bottomright',
      ZoomControlPosition = 'bottomright',
      layersControlPosition = 'topleft',
      className,
      style
    }: MapViewProps,
    ref?: any
  ) => {
    if (!ref) {
      ref = React.createRef()
    }
    return (
      <MapContainer
        zoom={13}
        scrollWheelZoom={false}
        id="fareye-leaflet"
        style={{ width: '100%', height: '100%' }}
        {...leafletMapConfig}
        className={className}
      >
        {/* <FullscreenControl position={fullScreenControlPosition} /> */}
        <LayersControl position={layersControlPosition}>
          <LayersControl.BaseLayer
            checked={defaultBaseLayer === BaseLayer.OSMMAPNIK}
            name={BaseLayer.OSMMAPNIK}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer
            checked={defaultBaseLayer === 'Aeris Map'}
            name={'Aeris Map'}
          >
            <TileLayer
              attribution='&amp;copy <a href="https://www.aerisweather.com/">&copy;AerisWeather</a>'
              url="https://maps1.aerisapi.com/Zo1OUzOxuZfj4uSLirYhj_1ZQN0VhcaCH9n6aMBpe4bV1QkEOuBHPLdx1l8EEr/flat,radar,radar-global,fradar,satellite-visible,satellite,satellite-geocolor,satellite-infrared-color,satellite-water-vapor,fsatellite/{z}/{x}/{y}/current.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer
            checked={defaultBaseLayer === BaseLayer.GMROADS}
            name={BaseLayer.GMROADS}
          >
            <GoogleLayer googlekey={mapKey} maptype={'ROADMAP'} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer
            checked={defaultBaseLayer === BaseLayer.GMTERRAIN}
            name={BaseLayer.GMTERRAIN}
          >
            <GoogleLayer googlekey={mapKey} maptype={'TERRAIN'} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer
            checked={defaultBaseLayer === BaseLayer.GMSATELLITE}
            name={BaseLayer.GMSATELLITE}
          >
            <GoogleLayer googlekey={mapKey} maptype={'SATELLITE'} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer
            checked={defaultBaseLayer === BaseLayer.GMHYBRID}
            name={BaseLayer.GMHYBRID}
          >
            <GoogleLayer googlekey={mapKey} maptype={'HYBRID'} />
          </LayersControl.BaseLayer>
        </LayersControl>
        {children}
      </MapContainer>
    )
  }
)

export default MapView
