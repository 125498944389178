import {
  AsyncSearchSelect,
  components,
  CreatableSearchSelect,
  Flex,
  Label,
  SearchSelect
} from '@fareye/ui'
import {
  cancelToken,
  get,
  getQueryParams,
  isEqual,
  search,
  startCase
} from '@fareye/utils'
import debounce from 'debounce-promise'
import { ErrorMessage } from 'formik'
import React from 'react'
import { Column, ErrorSpan } from '../styled/styled'
import { getDescendantProp } from '../utility/funcs'
import { IComponentFormField } from '../utility/types'
import CustomErrorMessage from './CustomErrorMessage'
import CustomLabel from './Label'

function Select({
  config,
  setFieldValue,
  setFieldTouched,
  formKeyName,
  values,
  baseUrl
}: IComponentFormField) {
  const {
    styling,
    apiUrl,
    configQuery = '',
    searchQueryKey,
    unit,
    apiResultKey,
    creatableType
  } = config
  const [optionsLocal, setOptionsLocal] = React.useState<any[]>([])
  const source = cancelToken().source()
  //To display number of Records
  const recordsToShow = 30
  let flag = 0

  React.useEffect(() => {
    let isCancelled = false
    if (config.type === 'local-select') {
      fetchLocalData(isCancelled)
    }
    return () => {
      isCancelled = true
      source.cancel('Unmount api')
    }
  }, [])

  const loadOptions = async (value: string, isFirstTime?: boolean) => {
    try {
      const apiBaseUrl = baseUrl + apiUrl
      const query = getQueryParams({
        [searchQueryKey ? searchQueryKey : 'search']: value
      })
      const data = await search(`${apiBaseUrl}?${query}${configQuery}`)
      const { [apiResultKey as string]: apiData } = data
      return closest(apiData, value).filter((item: any) => item.label !== '')
    } catch (ex) {}
  }

  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <span>{props.data.label}</span>
            <Label>
              {startCase(props?.data?.pretty_name || props?.data?.key)}
            </Label>
          </div>
        </components.Option>
      </div>
    )
  }

  const closest = (arr: any, searchValue: string) => {
    return arr.sort((a: any, b: any) => {
      const diff1 = a.value.toLowerCase().includes(searchValue.toLowerCase())
        ? searchValue.length / a.value.length
        : 0
      const diff2 = b.value.toLowerCase().includes(searchValue.toLowerCase())
        ? searchValue.length / b.value.length
        : 0
      return diff1 < diff2 ? 1 : -1
    })
  }

  async function fetchLocalData(isCancelled: boolean) {
    const { apiUrl, searchQueryKey } = config
    const apiBaseUrl = baseUrl + apiUrl
    try {
      const { data } = await get(apiBaseUrl, source.token)
      let options = getDescendantProp(
        data,
        searchQueryKey ? searchQueryKey : 'results'
      )
      if (!Array.isArray(options)) {
        options = Object.keys(options).map(ele => {
          return {
            label: ele,
            options: options[ele]
          }
        })
      } else if (typeof options[0] === 'string') {
        options = options.map(ele => {
          return {
            label: ele,
            value: ele
          }
        })
      }
      if (!isCancelled) {
        setOptionsLocal(options)
      }
    } catch (ex) {
      console.warn('Error while fetching dropdown values')
    }
  }
  function onCreateOption(label: string) {
    if (creatableType && creatableType === 'number') {
      let isValidLabel = !isNaN(Number(label))
      if (isValidLabel) {
        const selected = {
          value: label,
          label: label + (unit ? ' ' + unit : '')
        }
        setFieldValue(formKeyName, selected)
      }
    } else {
      const selected = {
        value: label,
        label: label + (unit ? ' ' + unit : '')
      }
      setFieldValue(formKeyName, selected)
    }
  }
  const debounceLoadOption = debounce(loadOptions, 400)

  function getSearchSelectComponent() {
    const {
      options,
      isMulti,
      placeholder,
      labelForDisplay,
      valueForDisplay
    } = config
    switch (config.type) {
      case 'creatable':
        return (
          <CreatableSearchSelect
            options={options as any}
            isMulti={isMulti}
            onBlur={() => {
              setFieldTouched(formKeyName, true)
            }}
            value={getDescendantProp(values, formKeyName)}
            onChange={value => {
              setFieldValue(formKeyName, value)
            }}
            placeholder={placeholder}
            getOptionLabel={ele =>
              labelForDisplay ? ele[labelForDisplay] : ele.label
            }
            styles={{
              container: (base: any) => ({
                ...base,
                flexGrow: 1
              }),
              control: (base: any) => ({
                ...base,

                borderColor: '#b3b3b3',
                boxShadow: 'none'
              }),
              option: (base: any) => ({
                ...base,
                display: 'flex',
                justifyContent: 'space-between',
                zIndex: 10
              })
            }}
            onCreateOption={input => onCreateOption(input)}
            getOptionValue={ele =>
              valueForDisplay ? ele[valueForDisplay] : ele.value
            }
          />
        )
      case 'async':
        return (
          <AsyncSearchSelect
            placeholder={placeholder}
            value={getDescendantProp(values, formKeyName)}
            loadOptions={(value: string) => debounceLoadOption(value)}
            onBlur={() => {
              setFieldTouched(formKeyName, true)
            }}
            components={{
              Option,
              IndicatorSeparator: () => null
            }}
            onChange={value => {
              setFieldValue(formKeyName, value)
            }}
            styles={{
              container: (base: any) => ({
                ...base,
                flexGrow: 1
              }),
              control: (base: any) => ({
                ...base,
                borderColor: '#b3b3b3',
                boxShadow: 'none'
              }),
              option: (base: any) => ({
                ...base,
                display: 'flex',
                justifyContent: 'space-between',
                zIndex: 10
              })
            }}
            isMulti
            getOptionLabel={ele =>
              labelForDisplay ? ele[labelForDisplay] : ele.label
            }
            getOptionValue={ele =>
              valueForDisplay ? ele[valueForDisplay] : ele.value
            }
            noOptionsMessage={() => 'Type to Filter Data ..'}
          />
        )
      case 'local-select':
        return (
          <SearchSelect
            options={optionsLocal as any}
            value={getDescendantProp(values, formKeyName)}
            onChange={value => {
              setFieldValue(formKeyName, value)
            }}
            placeholder={placeholder}
            onBlur={() => {
              setFieldTouched(formKeyName, true)
            }}
            filterOption={({ label }, query) =>
              label
                ? label.toLowerCase().indexOf(query.toLowerCase()) >= 0 &&
                  flag++ < recordsToShow
                : false
            }
            //reset flag after each input
            onInputChange={() => {
              flag = 0
            }}
            styles={{
              container: (base: any) => ({
                ...base,
                flexGrow: 1
              }),
              control: (base: any) => ({
                ...base,
                borderColor: '#b3b3b3',
                boxShadow: 'none'
              }),
              option: (base: any) => ({
                ...base,
                display: 'flex',
                justifyContent: 'space-between',
                zIndex: 10
              })
            }}
            isMulti={isMulti}
            getOptionLabel={ele =>
              labelForDisplay ? ele[labelForDisplay] : ele.label
            }
            getOptionValue={ele =>
              valueForDisplay ? ele[valueForDisplay] : ele.value
            }
          ></SearchSelect>
        )
      default:
        return (
          <SearchSelect
            options={options as any}
            value={getDescendantProp(values, formKeyName)}
            onChange={value => {
              setFieldValue(formKeyName, value)
            }}
            placeholder={placeholder}
            onBlur={() => {
              setFieldTouched(formKeyName, true)
            }}
            styles={{
              container: (base: any) => ({
                ...base,
                flexGrow: 1
              }),
              control: (base: any) => ({
                ...base,
                borderColor: '#b3b3b3',
                boxShadow: 'none'
              }),
              option: (base: any) => ({
                ...base,
                display: 'flex',
                justifyContent: 'space-between',
                zIndex: 10
              })
            }}
            isMulti={isMulti}
            getOptionLabel={ele =>
              labelForDisplay ? ele[labelForDisplay] : ele.label
            }
            getOptionValue={ele =>
              valueForDisplay ? ele[valueForDisplay] : ele.value
            }
          ></SearchSelect>
        )
    }
  }
  const { label = {}, container = {}, inputContainer = {} } = styling
    ? styling.styles
      ? styling.styles
      : {}
    : {}
  return (
    <Column {...(styling ? styling.variant : {})} style={container} column>
      <CustomLabel
        label={config.label as string}
        desc={config.desc}
        style={label}
      />
      <Flex width={1} column style={inputContainer}>
        {getSearchSelectComponent()}
        <ErrorSpan variant="danger">
          <ErrorMessage
            component={(props: any) => <CustomErrorMessage {...props} />}
            name={formKeyName}
          />
        </ErrorSpan>
      </Flex>
    </Column>
  )
}
export default React.memo(
  Select,
  (oldProp: IComponentFormField, newProp: IComponentFormField) => {
    return isEqual(oldProp, newProp)
  }
)
