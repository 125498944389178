import { IRenderControl, Modal, P, RenderControl, Flex, Span } from '@fareye/ui'
import { cancelToken, get, post, responseParser, FaPlus } from '@fareye/utils'
import React from 'react'
import { IssueRaisedConfig } from '../../../config/constant'
import { IIssueListing } from '../../../utility/types'
import IssueForm from '../components/IssueListing/IssueForm'
import IssueList from '../components/IssueListing/IssueListing'
import { IssueRaisedButton, ListingContainer } from '../../../style/styled'

const source = cancelToken().source()

export const IssueListing = (props: IIssueListing) => {
  const {
    resourceId,
    showDrawer,
    resourceType,
    setShowDrawer,
    //NOTE: Default Config for this Module if config is not passed.
    config = IssueRaisedConfig,
    baseUrl = '',
    updateIssueCount,
    defaultFormValue,
    setDefaultIssueForm,
    isShowForm = false
  } = props
  const {
    children: { issueListing }
  } = config
  const [showForm, setShowForm] = React.useState(isShowForm)
  const [reloadCount, setReloadCount] = React.useState(0)
  const source1 = cancelToken().source()
  const [showSubmitLoader, setShowSubmitLoader] = React.useState(false)

  const [apiData, setApiData] = React.useState<IRenderControl>({
    data: {},
    isLoading: false,
    isSuccess: true,
    isError: false,
    message: ''
  })
  const source2 = cancelToken().source()
  React.useEffect(() => {
    fetchData()
  }, [reloadCount])

  async function fetchData() {
    try {
      const { apiURL } = issueListing.config.apiConfig
      setApiData({ ...apiData, isLoading: true })
      const url = `${baseUrl}${apiURL}?resourceId=${resourceId}&resourceType=${resourceType}`
      const { data } = await get(url, source1.token)

      setApiData({
        ...apiData,
        data,
        isLoading: false,
        isSuccess: true,
        isError: false
      })
    } catch (ex) {
      setApiData({
        ...apiData,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: 'Something went Wrong...'
      })
    }
  }
  async function submitIssue({ value, filePayload }: any) {
    try {
      const {
        config: {
          apiConfig: { apiURL }
        }
      } = issueListing
      setShowSubmitLoader(true)
      const url = `${baseUrl}${apiURL}`
      const { error, data, message } = responseParser(
        await post(url, { issue: value }, source2.token)
      )
      updateIssueCount()
      filePayload.append('id', data?.comment_id)
      filesUpload(filePayload)
      if (!error) {
        setReloadCount(reloadCount + 1)
      }
      setShowSubmitLoader(false)
      setShowForm(false)
    } catch (ex) {
      setShowSubmitLoader(false)
    }
  }
  async function filesUpload(filePayload: any) {
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    try {
      const response = await post(
        '/api/v1/commontator/upload_attachment?',
        filePayload,
        source.token,
        headers
      )
    } catch (e) {
      console.error('App Error uploading image::', e)
    }
  }
  const { data } = apiData
  return (
    <ListingContainer
      setToggleModal={setShowDrawer}
      toggleModal={showDrawer}
      variant="xs drawer Bottom-Right"
      style={{
        content: {
          background: '#f9fafb',
          maxHeight: 'calc(100vh - 50px)',
          height: 'calc(100vh - 50px)'
        }
      }}
    >
      <P variant="h6" m={0} p={3} bg="#fff">
        Issues Raised
      </P>
      <RenderControl state={apiData} variant={'l'}>
        {data.issues && data.issues.length && !showForm ? (
          <Flex column>
            <IssueList
              issues={apiData.data.issues}
              baseUrl={baseUrl}
              config={config}
              resourceType={resourceType}
            />
            <IssueRaisedButton
              variant="dashed s Top-Right"
              onClick={() => setShowForm(true)}
            >
              <Span mr={20}>
                <FaPlus />
              </Span>
              New Issue
            </IssueRaisedButton>
          </Flex>
        ) : (
          <IssueForm
            resourceId={resourceId}
            resourceType={resourceType}
            onSubmit={submitIssue}
            setShowForm={setShowForm}
            showSubmitLoader={showSubmitLoader}
            baseUrl={baseUrl}
            config={config}
            defaultFormValue={defaultFormValue}
            setDefaultIssueForm={setDefaultIssueForm}
          />
        )}
      </RenderControl>
    </ListingContainer>
  )
}
