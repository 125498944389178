import React from 'react'
import styled from 'styled-components'
import {
  IPropsInterface,
  layout,
  splitVariantsToTypes
} from '../Theme/styledSystem'
import { TSize } from '../utility/variantTypes'
// import { IListProps } from '../utility/types'

export interface IListItemProps extends IPropsInterface {
  variant?: TSize
}
export interface IListDividerProps extends IPropsInterface {}
export interface IListProps extends IPropsInterface {}

const Divider: any = styled.div`
  ${(props: IListDividerProps) => props.theme.card.m.divider}
`
const StyledListItem = styled.div`
  ${(props: IListItemProps) => {
    const variant = splitVariantsToTypes(props)
    return props.theme.list[variant.size].item
  }}
  ${layout}
`

const StyledList = styled.div`
  ${(props: IListItemProps) => {
    const variant = splitVariantsToTypes(props)
    return props.theme.list[variant.size].frame
  }}
  ${layout}
`

export const ListItem = ({ children }: IListProps) => {
  return <StyledListItem className="fe-ui-list-item">{children}</StyledListItem>
}

export const List = ({ children, variant, ...restProps }: IListItemProps) => {
  const childrenWithProps = React.Children.map(children, (child: any) => {
    if (typeof child === 'string') {
      return child
    } else {
      return React.cloneElement(child, {
        parentVariant: variant,
        restProps
      })
    }
  })

  return (
    <StyledList className="fe-ui-list-frame">{childrenWithProps}</StyledList>
  )
}
