import { Card, Flex, Input, Label, Span } from '@fareye/ui'
import { cancelToken, post } from '@fareye/utils'
import React from 'react'
import { ImgWrapper, LabelField, UploadImage } from '../styled'
import { AppStore } from './../../../store'

function Profile() {
  const { store, updateStore } = React.useContext(AppStore)
  const [profile, setProfile] = React.useState<any>({})
  const [img, setImg] = React.useState<any>(null)
  const [error, setError] = React.useState<any>(null)
  const source = cancelToken().source()

  async function uploadImg() {
    let payload = new FormData()
    payload.append('brand_image', img)
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    try {
      const { status } = await post(
        '/api/v1/upload_brand_image',
        payload,
        source.token,
        headers
      )
      if (status === 200) {
        store.header?.getUserProfile()
      }
    } catch (e) {
      console.error('App Error uploading image::', e)
    }
  }

  function onChangeUpload(e: any) {
    const files = e.target.files
    if (files[0].size < 1024 * 1024) {
      var reader = new FileReader()
      reader.onloadend = () => {
        setImg(files[0])
        updateStore({
          ...store,
          header: {
            ...store.header,
            img: reader.result || store.header.img
          }
        })
        setProfile({ ...profile, logo: reader.result })
      }
      reader.readAsDataURL(files[0])
      setError(null)
    } else {
      setError('Max image size is 1MB.')
    }
  }

  const {
    email = '-',
    mName = '-',
    lastName = '-',
    username = '-',
    firstName = '-',
    primaryPhoneNo = '-'
  } = store?.header?.userProfile || {}
  return (
    <Flex justifyContentSpaceEvenly style={{ padding: '4vmax' }}>
      <Flex column width={[1, 0.45]}>
        <Card header="Profile">
          <Flex column>
            <form
              onSubmit={(e: any) => e.preventDefault()}
              encType="multipart/form-data"
            >
              <ImgWrapper bg={store.header.backgroundColor}>
                <img src={store.header.img} />
              </ImgWrapper>
              {img ? (
                <UploadImage variant="s primary" onClick={uploadImg}>
                  Submit
                </UploadImage>
              ) : (
                <UploadImage variant="s">
                  Choose File
                  <Input type="file" onChange={onChangeUpload} />
                </UploadImage>
              )}
            </form>
            {error ? (
              <Span variant="danger" p={3}>
                * {error}
              </Span>
            ) : null}
            <LabelField>{`${firstName} ${lastName}`}</LabelField>
            <LabelField>{email}</LabelField>
            {username !== email ? <LabelField>{username}</LabelField> : null}
            <LabelField>{primaryPhoneNo}</LabelField>
            <LabelField>{mName}</LabelField>
          </Flex>
        </Card>
      </Flex>
      <Flex column width={[1, 0.45]}>
        <Card header="Theme">
          <Flex alignItemsCenter>
            <Label>Header Color</Label>
            <Input
              type="color"
              value={store.header.backgroundColor}
              style={{ marginLeft: 10, padding: 2, height: 20 }}
              onChange={(e: any) => {
                updateStore({
                  ...store,
                  header: {
                    ...store.header,
                    backgroundColor: e.target.value
                  }
                })
              }}
            />
          </Flex>
        </Card>
      </Flex>
    </Flex>
  )
}

export default Profile
