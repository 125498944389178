import {
  Card,
  Flex,
  Graph,
  RenderControl,
  Span,
  TGraphType,
  useWindowDimensions
} from '@fareye/ui'
import {
  cloneDeep,
  isEmpty,
  MdUnfoldMore,
  startCase,
  toTileCase
} from '@fareye/utils'
import React from 'react'
import { GraphRendererContainer, ScoreCardTable } from '../../style/styled'
import { generateDataForChart } from '../../utility'
import {
  IApiChartConfig,
  IChartConfig,
  IDimension,
  IDrillDownDimension,
  IFilterData,
  IGraphRendererConfig,
  IGraphStateData,
  IKeyValue,
  TGraphStateConfig,
  TGraphStateDataKeys
} from '../../utility/types'
import DimensionTraveller from './components/DimensionTraveller'
import GraphHeader from './components/Header'
import ScoreCard from './components/ScoreCard'

const GraphRenderer = (props: IGraphRendererConfig) => {
  let {
    dataConfig,
    graphConfig,
    style: graphStyle,
    globalFilter,
    FullScreenComponent
  } = props
  const { graphDataMapping } = dataConfig
  const { showLegends, pageSize, chartTitle } = graphConfig
  const [selectedDimension, setSelectedDimension] = React.useState(
    graphConfig.dimensions[0].dimension[graphDataMapping.dimension.value]
  )
  const [
    selectedDrillDownDimensionIndex,
    setSelectedDrillDownDimensionIndex
  ] = React.useState({ isReload: true, index: 0 })
  const [selectedGraphPoint, setSelectedGraphPoint] = React.useState({})
  const [graphStateData, setGraphStateData] = React.useState<
    IKeyValue<IGraphStateData[]>
  >({})
  const [loading, setLoading] = React.useState(false)
  const [isFirstTime, setIsFirstTime] = React.useState(true)
  const [showGraph, setShowGraph] = React.useState(true)
  const [benchmarkInput, setBenchMarkInput] = React.useState(1000)
  const events = {
    onClick: (value: any) => {
      if (
        graphStateData[selectedDimension].length - 1 >
        selectedDrillDownDimensionIndex.index
      ) {
        setStateForChart(
          graphStateData,
          selectedDimension,
          selectedDrillDownDimensionIndex.index + 1,
          { filterBy: value.payload }
        )
        setSelectedGraphPoint(value)
      }
    }
  }
  let graphId = `graphWrap-${chartTitle}${
    graphStyle && graphStyle.fullScreen ? '-full' : ''
  }`

  // NOTE: Used for setting Chart State on Component load.
  React.useEffect(() => {
    setChartDataOnComponentLoad()
    //FIXME: Hack used for Calling only this useEffect
    setIsFirstTime(false)
    // eslint-disable-next-line
  }, [globalFilter])

  //NOTE: call when user click on a chart for drillDown
  React.useEffect(() => {
    if (!isEmpty(selectedGraphPoint)) {
      setSelectedDrillDownDimensionIndex({
        isReload: true,
        index: selectedDrillDownDimensionIndex.index + 1
      })
    }
    // eslint-disable-next-line
  }, [selectedGraphPoint])

  //NOTE: set data for chart on drilldown dimension index change
  React.useEffect(() => {
    if (!isFirstTime) {
      setDataForChart(
        selectedDimension,
        selectedDrillDownDimensionIndex,
        graphStateData
      )
    }
    // eslint-disable-next-line
  }, [selectedDrillDownDimensionIndex])

  //NOTE: call when dimension changes or pivot changes
  React.useEffect(() => {
    if (!isFirstTime) {
      if (selectedDrillDownDimensionIndex.index === 0) {
        setDataForChart(
          selectedDimension,
          selectedDrillDownDimensionIndex,
          graphStateData
        )
      } else {
        setSelectedDrillDownDimensionIndex({ isReload: false, index: 0 })
      }
    }
    // eslint-disable-next-line
  }, [selectedDimension])

  //NOTE: First Time called when page load and initialize state and data to chart
  async function setChartDataOnComponentLoad() {
    const { getDataForChart } = dataConfig
    const stateObj = generateStateForChart(graphConfig)
    let { query, stacked } = stateObj[selectedDimension][
      selectedDrillDownDimensionIndex.index
    ]
    setLoading(true)
    const apiData = await getDataForChart(query, globalFilter)
    const { data: graphData, yLabelKey } = generateDataForChart(
      apiData,
      stacked,
      query.benchmarkDimension,
      graphConfig.chartType
    )
    stateObj[selectedDimension][
      selectedDrillDownDimensionIndex.index
    ].data = graphData
    stateObj[selectedDimension][
      selectedDrillDownDimensionIndex.index
    ].displayData = graphData.slice(0, 10)
    stateObj[selectedDimension][
      selectedDrillDownDimensionIndex.index
    ].yLabelKey = yLabelKey
    let noOfDataPoint = graphData.length > 10 ? 10 : graphData.length
    stateObj[selectedDimension][
      selectedDrillDownDimensionIndex.index
    ].displayDataLength = noOfDataPoint
    setLoading(false)
    setGraphStateData(stateObj)
  }

  async function setDataForChart(
    selectedDimension: string,
    selectedDrillDownDimensionIndex: { isReload: boolean; index: number },
    stateObj: IKeyValue<IGraphStateData[]>
  ) {
    const { getDataForChart } = dataConfig
    const selectedDimensionData = stateObj[selectedDimension]
    let { query, stacked, filterBy, data } = cloneDeep(
      selectedDimensionData[selectedDrillDownDimensionIndex.index]
    )
    query.filter = query.filter ? query.filter : []
    for (let i = 0; i <= selectedDrillDownDimensionIndex.index; i++) {
      if (selectedDimensionData[i].filterBy) {
        const { query: prevQuery } = selectedDimensionData[i - 1]
        const filterObj: IFilterData = {
          filterValue: {
            value: (selectedDimensionData[i].filterBy as any).label
          },
          filterType: { value: 'eq' },
          filterKey: {
            value: prevQuery.dimension[graphDataMapping.dimension.value]
          },
          condition: { value: 'include' }
        }
        query.filter.push([filterObj])
      }
    }
    if (isEmpty(data) || selectedDrillDownDimensionIndex.isReload) {
      setLoading(true)

      const apiData = await getDataForChart(query, globalFilter)
      const { data: graphData, yLabelKey } = generateDataForChart(
        apiData,
        stacked,
        query.benchmarkDimension,
        query.chartType
      )
      let noOfDataPoint = graphData.length > 10 ? 10 : graphData.length

      const displayData = graphData.slice(0, noOfDataPoint)
      const config = {
        data: graphData,
        yLabelKey: yLabelKey,
        displayData,
        displayDataLength: noOfDataPoint
      }
      setStateForChart(
        stateObj,
        selectedDimension,
        selectedDrillDownDimensionIndex.index,
        config
      )
      // setNoOfData(noOfDataPoint)
    }
  }

  //NOTE: Set State for Chart on config basis
  function setStateForChart(
    stateObj: IKeyValue<IGraphStateData[]>,
    selectedDimension: string,
    selectedDrillDownDimensionIndex: number,
    config: Partial<TGraphStateConfig>
  ) {
    const tempData = cloneDeep(stateObj)
    Object.keys(config).forEach(ele => {
      let tempKey: TGraphStateDataKeys = ele as TGraphStateDataKeys
      ;(tempData[selectedDimension][selectedDrillDownDimensionIndex] as any)[
        tempKey
      ] = config[tempKey]
    })
    setGraphStateData(tempData)
    setLoading(false)
  }

  function generateQueryForChart(
    graphConfig: IChartConfig,
    dimension: IDimension,
    dimensionData: any,
    drillDownData?: Partial<IDrillDownDimension>
  ) {
    const {
      metricList,
      dataSource,
      sortType,
      sortKey,
      filter,
      chartType
    } = graphConfig
    const query: Partial<IApiChartConfig> = {}
    query.dimension = dimensionData
    query.metric = metricList[0]
    query.metrics = metricList
    query.dataSource = dataSource
    query.hideDimension = dimension.hideDimension
    if (dimension.breakdownDimension) {
      query.breakdownDimension = dimension.breakdownDimension
    }
    if (dimension.benchmarkDimension) {
      query.benchmarkDimension = dimension.benchmarkDimension
    }
    query.metricUnit = metricList[0].metricUnit
    query.dimensionUnit = dimension.dimensionUnit
    query.sortKey = sortKey
    query.chartType = dimension.chartType || chartType
    query.sortType = sortType
    query.filter = filter && filter.filterData
    query.dimensionDescription = dimension.dimensionDescription
    query.metricDescription = dimension.metricDescription

    const clonedDrillDownData = cloneDeep(drillDownData)
    if (clonedDrillDownData) {
      delete clonedDrillDownData.drillDownDimension
    }

    return { ...query, ...clonedDrillDownData }
  }

  function generateStateForChart(graphConfig: IChartConfig) {
    const { dimensions } = graphConfig
    const { graphDataMapping } = dataConfig
    const stateObj: IKeyValue<IGraphStateData[]> = {}
    dimensions.forEach(dimension => {
      stateObj[dimension.dimension[graphDataMapping.dimension.value]] = [
        {
          query: generateQueryForChart(
            graphConfig,
            dimension,
            dimension.dimension
          ),
          data: [],
          displayData: [],
          yLabelKey: [],
          xLabelKey: 'label',
          stacked:
            dimension.breakdownDimension || dimension.benchmarkDimension
              ? true
              : false
        }
      ]
      if (dimension.isDrillDown && dimension.drillDownDimensions) {
        dimension.drillDownDimensions.forEach(drillDownDimension => {
          stateObj[dimension.dimension[graphDataMapping.dimension.value]].push({
            query: generateQueryForChart(
              graphConfig,
              dimension,
              drillDownDimension.drillDownDimension,
              drillDownDimension
            ),
            displayData: [],
            data: [],
            yLabelKey: [],
            xLabelKey: 'label',
            stacked:
              drillDownDimension.breakdownDimension ||
              dimension.benchmarkDimension
                ? true
                : false
          })
        })
      }
    })
    return stateObj
  }

  //NOTE: Render Graph Header
  function renderGraphHeader() {
    return (
      <GraphHeader
        selectedDimension={selectedDimension}
        setSelectedDimension={setSelectedDimension}
        config={{ dataConfig, graphConfig }}
        selectedDrillDownDimensionIndex={selectedDrillDownDimensionIndex}
        setSelectedDrillDownDimensionIndex={setSelectedDrillDownDimensionIndex}
        setStateForChart={setStateForChart}
        graphStateData={graphStateData}
        globalFilter={globalFilter}
      />
    )
  }
  //NOTE: Render n level  Dimension
  function renderDimensionTraveller() {
    return (
      <DimensionTraveller
        selectedDimension={selectedDimension}
        selectedDrillDownDimensionIndex={selectedDrillDownDimensionIndex}
        setStateForChart={setStateForChart}
        graphStateData={graphStateData}
        setSelectedDrillDownDimensionIndex={setSelectedDrillDownDimensionIndex}
        setSelectedGraphPoint={setSelectedGraphPoint}
        setDataForChart={setDataForChart}
        graphDataMapping={graphDataMapping}
        graphStyle={graphStyle}
        dataConfig={dataConfig}
        graphConfig={graphConfig}
        globalFilter={globalFilter}
        benchmarkInput={benchmarkInput}
        setBenchMarkInput={setBenchMarkInput}
        showGraph={showGraph}
        setShowGraph={setShowGraph}
        FullScreenComponent={FullScreenComponent}
      />
    )
  }
  //NOTE:Render Graph
  function renderGraph() {
    try {
      const { displayData: data, yLabelKey, query, stacked } = graphStateData[
        selectedDimension
      ][selectedDrillDownDimensionIndex.index]
      return (
        <Graph
          type={
            (query.chartType
              ? query.chartType
              : graphConfig.chartType) as TGraphType
          }
          data={data}
          showLegends={showLegends}
          XLabelKey={'label'}
          YLabelKey={yLabelKey}
          ratio={graphStyle ? graphStyle.graphScreenRatio : 16 / 5}
          events={events}
          stacked={stacked ? 'tower' : undefined}
          config={{
            colors: graphConfig.colors || {},
            legendsList: [],
            xAxisUnit: query
              ? query.dimensionUnit[graphDataMapping['unit'].value]
              : '',
            yAxisUnit: query
              ? query.metricUnit[graphDataMapping['unit'].value]
              : ''
          }}
          labelColor="white"
          YLabel={
            query.dimensionDescription
              ? query.dimensionDescription
              : graphConfig.dimensionDescription
          }
          XLabel={`${
            query.metricDescription
              ? query.metricDescription
              : graphConfig.metricDescription
          }`}
          selector={graphId}
          layout={graphConfig.layout}
        />
      )
    } catch (ex) {
      console.warn('')
    }
  }

  function renderTable() {
    const selectedGraph =
      graphStateData[selectedDimension] &&
      graphStateData[selectedDimension][selectedDrillDownDimensionIndex.index]
    let columns = generateColumnForTable(selectedGraph.data[0])
    console.log(columns, selectedGraph.data)
    return (
      <ScoreCardTable
        columns={columns}
        data={selectedGraph.data}
        defaultPageSize={pageSize || 10}
        className="responsive striped"
        fontSize="xs"
      />
    )
  }

  function generateColumnForTable(val: any) {
    let removeKeys = ['label', 'value']
    let keys = Object.keys(val || {}).filter(ele => !removeKeys.includes(ele))
    return keys.map((ele: any) => {
      return {
        Header: () => (
          <div style={{ textAlign: 'left' }}>
            {startCase(ele)}
            <MdUnfoldMore />
          </div>
        ),
        accessor: ele,
        id: ele,
        minWidth: 110,
        Cell: ({ row }: any) => {
          return <Span fontSize={12}>{row.original[ele]}</Span>
        }
      }
    })
  }

  function renderScoreCard() {
    const selectedGraph =
      graphStateData[selectedDimension] &&
      graphStateData[selectedDimension][selectedDrillDownDimensionIndex.index]
    return (
      <ScoreCard
        selectedGraph={selectedGraph}
        graphDataMapping={dataConfig.graphDataMapping}
        index={selectedDrillDownDimensionIndex.index}
        pageSize={pageSize}
      />
    )
  }

  const { width } = useWindowDimensions()
  let chartType =
    graphStateData[selectedDimension]?.[selectedDrillDownDimensionIndex.index]
      ?.query?.chartType

  //FIXME: Add Destructuring in case of nested objects.
  return (
    <GraphRendererContainer
      width={width}
      containerStyle={{
        ...(graphStyle ? graphStyle.wrapper : {})
        // order: isEmpty(graphData) ? 1 : 0
      }}
    >
      {renderGraphHeader()}
      <Card bg="white" variant="s" style={{ height: '100%' }}>
        <RenderControl
          data={
            graphStateData[selectedDimension] &&
            graphStateData[selectedDimension][
              selectedDrillDownDimensionIndex.index
            ]?.data
          }
          isSuccess={
            !loading &&
            !isEmpty(
              graphStateData[selectedDimension]?.[
                selectedDrillDownDimensionIndex.index
              ]?.data
            )
          }
          isLoading={loading}
          variant={'m'}
          message={
            !isEmpty(
              graphStateData[selectedDimension]?.[
                selectedDrillDownDimensionIndex.index
              ]?.data
            )
              ? ''
              : 'No Data Found'
          }
        >
          {graphStateData[selectedDimension]?.[
            selectedDrillDownDimensionIndex.index
          ] && (
            <div id={graphId}>
              {renderDimensionTraveller()}
              {showGraph && chartType !== 'table'
                ? renderGraph()
                : chartType === 'table'
                ? renderTable()
                : renderScoreCard()}
            </div>
          )}
        </RenderControl>
      </Card>
    </GraphRendererContainer>
  )
}
export default GraphRenderer
// export default React.memo(GraphRenderer, (prevProp, newProp) => {
//   return isEqual(prevProp, newProp)
// })
