import axios from 'axios'
import { ILocationPayload } from '../utility/types'

async function fetchIPJSON(callback: (d: ILocationPayload) => void) {
  try {
    const { data } = await axios.get('https://ipapi.co/json/')
    if (data) {
      callback({
        success: true,
        data: {
          coords: {
            latitude: data.latitude,
            longitude: data.longitude
          },
          timestamp: new Date().getTime()
        }
      })
    } else {
      callback({
        success: false,
        data: {
          coords: {
            latitude: 0,
            longitude: 0
          },
          timestamp: new Date().getTime()
        }
      })
    }
  } catch (e) {
    callback({
      success: false,
      data: e.response
    })
  }
}

function useLocation(callback: (d: ILocationPayload) => void) {
  if (
    navigator &&
    'geolocation' in navigator &&
    typeof navigator.geolocation.getCurrentPosition == 'function'
  ) {
    navigator.geolocation.getCurrentPosition(
      data => {
        callback({
          success: true,
          data
        })
      },
      () => {
        fetchIPJSON(callback)
      }
    )
  } else {
    fetchIPJSON(callback)
  }
}

export default useLocation
