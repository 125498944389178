import {
  Button,
  FilePicker,
  Flex,
  Modal,
  Span,
  styled,
  toast
} from '@fareye/ui'
import {
  cancelToken,
  FaFileImage,
  FaFileWord,
  FaFilePdf,
  FaPaperclip,
  FaTimes,
  get,
  isEmpty,
  post
} from '@fareye/utils'
import React from 'react'
import { IIssueListingApiConfig } from '../../../../utility/types'
import MentionInput from '../MentionInput'
import UserForm from '../user/UserForm'
import { fileIconStyle, Files, FileWrap } from '../../../../style/styled'

export interface ICommentForm {
  threadId?: number
  createIssue?: boolean
  files?: any[]
  filesUpload?: (id: number) => void
  onChangeFiles: (files: any[]) => void
  setIssueComment?: (comment: string) => void
  baseUrl: string
  config: IIssueListingApiConfig
  defaultComment?: string
}
interface IMentionItem {
  id: number
  display: string
}

export const CommentAndFiles: any = styled(Flex)`
  width: 100%;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  &:hover {
    border: 1px solid #40a9ff;
  }
  .mention-input__control {
    border: none;
  }
  .mention-input__control:hover {
    border: none;
  }
`

export default function CommentForm(props: ICommentForm) {
  let {
    defaultComment,
    threadId,
    createIssue,
    setIssueComment,
    config,
    files,
    onChangeFiles,
    filesUpload,
    baseUrl
  } = props
  const [comment, setComment] = React.useState(defaultComment || '')
  const [showLoading, setShowLoading] = React.useState(false)
  const [users, setUsers] = React.useState<any>(null)
  const [showUser, setShowUser] = React.useState(false)
  const { addComments, getUser } = config.children
  React.useEffect(() => {
    console.log(defaultComment, '*******')
    if (defaultComment === '') {
      setComment('')
    }
  }, [defaultComment])
  function populateForm(
    event: any,
    newValue: any,
    newPlainTextValue: any,
    mentions: any
  ) {
    if (newPlainTextValue.split('').pop() === '@') {
      getUsers()
    }
    setIssueComment && setIssueComment(newValue)
    setComment(newValue)
  }

  async function addComment() {
    try {
      setShowLoading(true)
      const { apiURL } = addComments.config.apiConfig
      if (threadId) {
        const getCommentsForThread = `${baseUrl}${apiURL}/${threadId}/comments`
        const { data } = await post(
          getCommentsForThread,
          { comment: { body: comment } },
          cancelToken().source().token
        )
        if (data) {
          setComment('')
          filesUpload?.(data.comment)
          setShowLoading(false)
        }
      }
    } catch (error) {
      toast.error('Something went wrong')
      setShowLoading(false)
      console.error(error)
    }
  }

  async function getUsers() {
    try {
      const { apiURL } = getUser.config.apiConfig
      const url = `${baseUrl}${apiURL}`
      const { data } = await get(url, cancelToken().source().token)
      if (data) {
        setUsers(data.users)
      }
    } catch (error) {
      console.error(error)
    }
  }

  function getUserLocally(query: string, callback: any) {
    if (!query) return
    new Promise((resolve, reject) => {
      let searchUsers: IMentionItem[] = users
        ? users.map((x: any, index: number) => {
            return {
              display: x.email,
              id: x.id
            }
          })
        : []
      if (query) {
        searchUsers = searchUsers.filter(ele => ele.display.includes(query))
      }
      let mentionUsers = [{ id: -1, display: 'Add User' }, ...searchUsers]
      resolve(mentionUsers)
    })
      // Transform the users to what react-mentions expects
      .then(callback)
  }
  function filterFileToRemove(selectedFile: any, allFiles?: any[]) {
    return allFiles?.filter(
      (tempFile: any) => tempFile.name !== selectedFile.name
    )
  }
  return (
    <Flex width={1} alignItemsFlexEnd>
      <div id="mention-input" style={{ flexBasis: '100%', width: '100%' }}>
        <CommentAndFiles>
          <Files data-testid="files">
            {files?.map((file: any, i: number) => {
              const isFilePdf = file.name.includes('.pdf')
              const isFileDoc = file.name.includes('.doc')
              const Icon = isFilePdf
                ? FaFilePdf
                : isFileDoc
                ? FaFileWord
                : FaFileImage
              return (
                <FileWrap key={i} data-testid={`upload-file-${i}`}>
                  <FaTimes
                    id="remove"
                    data-testid="remove"
                    onClick={() =>
                      onChangeFiles(filterFileToRemove(file, files) || [])
                    }
                  />
                  <Flex alignItemsCenter>
                    <Icon style={fileIconStyle} />
                    <Span variant="ellipsis">{file.name || ''}</Span>
                  </Flex>
                </FileWrap>
              )
            })}
          </Files>
          <MentionInput
            comment={comment}
            setComment={setComment}
            setShowUser={setShowUser}
            populateForm={populateForm}
            getUserLocally={getUserLocally}
          />
        </CommentAndFiles>
      </div>
      {!createIssue ? (
        <FilePicker
          icon={<FaPaperclip size={18} />}
          style={{
            containerStyle: {
              padding: '5px 15px'
            }
          }}
          multiple
          accept="image/jpeg, image/png, .doc, .docx, .pdf"
          onError={(err: any) => {
            if (err && !isEmpty(err)) {
              console.error('Oops! Unable to upload')
            }
          }}
          onFileDrop={(newFiles: any) => {
            onChangeFiles([...(files || []), ...newFiles])
          }}
        />
      ) : null}
      {!createIssue ? (
        <Button
          type="button"
          disabled={comment.length === 0}
          isLoading={showLoading}
          variant="primary"
          onClick={addComment}
        >
          Post
        </Button>
      ) : null}
      {showUser && (
        <Modal
          style={{
            backdrop: {
              position: 'absolute',
              width: 'auto',
              background: 'rgba(0,0,0,.8)'
            },
            content: {}
          }}
          variant="m Bottom-Center"
          toggleModal={showUser}
          setToggleModal={setShowUser}
        >
          <UserForm
            setShowUser={setShowUser}
            comment={comment}
            setComment={setComment}
            baseUrl={baseUrl}
            config={config}
          />
        </Modal>
      )}
    </Flex>
  )
}
