import { SearchFilterWrapper, Header } from '@fareye/common'
import { GraphRenderer, IGraphRendererConfig } from '@fareye/kpi'
import { DatePicker, Flex } from '@fareye/ui'
import { IDateRange } from '@fareye/types'
import { cloneDeep, isEmpty, yyyyMmDd } from '@fareye/utils'
import React from 'react'
import { RouteComponentProps } from 'react-router'

interface IGraphRendererFullScreen extends RouteComponentProps {
  config: IGraphRendererConfig
  dashboardConfig: any
}

export const pageId: string = 'kpi'

const FullScreenComponent = ({
  config,
  history,
  location,
  match,
  dashboardConfig
}: IGraphRendererFullScreen) => {
  const [
    fullScreenConfig,
    setFullScreenConfig
  ] = React.useState<IGraphRendererConfig | null>(null)
  const [selectedKey, setSelectedKey] = React.useState(
    config.globalFilter.selectedKey
  )
  const { filters, config: apiConfigs } = dashboardConfig

  const { toggleContent, showTimezone = true } = apiConfigs
  const [dateRange, setDateRange] = React.useState(
    config.globalFilter
      ? config.globalFilter.dateRange
      : {
          startDate: new Date(),
          endDate: new Date()
        }
  )
  const [selectedFilter, setSelectedFilter] = React.useState(
    config.globalFilter ? config.globalFilter.selectedFilter : []
  )
  const apiFav = filters.apiFav

  const apiFilter = {
    ...filters.apiFilter,
    queryParams: { data_source: config?.graphConfig?.dataSource?.value }
  }

  React.useEffect(() => {
    const clonedConfig = cloneDeep(config)
    clonedConfig.globalFilter = {
      dateRange,
      selectedFilter,
      selectedKey
    }
    clonedConfig.graphConfig.showLegends = true
    if (clonedConfig.style) {
      clonedConfig.style.fullScreen = true
      clonedConfig.style.graphScreenRatio = 16 / 5
    }
    setFullScreenConfig(clonedConfig)
  }, [selectedFilter, dateRange, selectedKey])
  return (
    <Flex column>
      <Header
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        toggle={toggleContent}
        match={match}
        location={location}
        history={history}
        selectedKey={selectedKey}
        setSelectedKey={setSelectedKey}
        rightTopCorner={
          <DatePicker
            onDateRangeSelected={(dateRange: IDateRange<string | Date>) => {
              localStorage.setItem('date', JSON.stringify(dateRange))
              setDateRange(dateRange as IDateRange<Date>)
            }}
            formatter={yyyyMmDd}
            value={dateRange}
            name="dateFilter"
            variant="fareyeV2"
          />
        }
        showTimezone={showTimezone}
      >
        <Flex justifyContentSpaceBetween width={1}>
          <SearchFilterWrapper
            apiFav={apiFav}
            pageId={pageId}
            apiFilter={apiFilter}
            search={selectedFilter}
            setSearch={setSelectedFilter}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            showTimezone={showTimezone}
          />
        </Flex>
      </Header>
      {!isEmpty(fullScreenConfig) && (
        <GraphRenderer
          key={`${
            isEmpty(fullScreenConfig?.globalFilter?.selectedFilter)
              ? 'fullScreen'
              : fullScreenConfig?.globalFilter?.selectedFilter[0].label
          }_fullScreen`}
          {...(fullScreenConfig as IGraphRendererConfig)}
          style={{
            ...fullScreenConfig?.style,
            wrapper: {
              ...fullScreenConfig?.style?.wrapper,
              width: '100%',
              height: '100%'
            }
          }}
        />
      )}
    </Flex>
  )
}
export default FullScreenComponent
