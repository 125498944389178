import { Button, Card, Flex, Input, toast } from '@fareye/ui'
import { cancelToken, post } from '@fareye/utils'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { number, object, string } from 'yup'
import * as Yup from 'yup'
import {
  IssueContainerFlex,
  IssueHeadingText,
  IssueLabelText,
  IssueValueFlex
} from '../../../../style/styled'
import { IIssueListingApiConfig } from '../../../../utility/types'
import CustomErrorMessage from '../ErrorMessage'

export interface IUserForm {
  setShowUser: (val: any) => void
  setComment: (val: any) => void
  comment: any
  baseUrl: string
  config: IIssueListingApiConfig
}
export interface IUser {
  full_name: string
  email: string
  phone_no: string
  designation: string
}
Yup.addMethod(Yup.string, 'emailValidator', function() {
  return this.test({
    name: 'emailValidator',
    message: '*Emails outside fareye domain can only be tagged',
    test: (email = '') => {
      if (email.includes('fareye.com')) {
        return false
      }
      return true
    }
  })
})

const UserForm = ({
  setShowUser,
  setComment,
  comment,
  baseUrl,
  config
}: IUserForm) => {
  const [showLoading, setShowLoading] = React.useState(false)
  const initialState = {
    full_name: '',
    email: '',
    phone_no: '',
    designation: ''
  }
  const source = cancelToken().source()
  const validationSchema = object().shape({
    full_name: string().required('* Required'),
    email: (string()
      .email('*Enter Valid Email')
      .required('* Required') as any).emailValidator(),
    phone_no: number()
      .required('* Required')
      .max(9999999999, '* Invalid Number')
      .min(1111111111, '* Invalid Number'),
    designation: string().required('* Required')
  })
  const addUser = async (fields: IUser) => {
    try {
      setShowLoading(true)
      const { apiURL } = config.children.addUser.config.apiConfig
      const url = `${baseUrl}${apiURL}`
      const { data } = await post(url, { alert_user: fields }, source.token)
      const { user } = data
      const cloneComment = `@[${user.email}](email:${user.id})`
      setShowLoading(false)
      setComment(cloneComment)
      setShowUser(false)
      toast.success('User Created Successfully..')
    } catch (ex) {
      setShowLoading(false)
      toast.error('Something went wrong...')
      console.error(ex)
    }
  }
  return (
    <Card bodyStyle={{ padding: '5px 10px' }} variant="m" m={1} bg={'white'}>
      <Formik
        initialValues={initialState}
        onSubmit={fields => {
          addUser(fields)
        }}
        validationSchema={validationSchema}
        render={({
          handleReset,
          handleSubmit,
          handleChange,
          setFieldTouched,
          isValid,
          errors
        }) => (
          <Form>
            <IssueHeadingText>Add User</IssueHeadingText>
            <IssueContainerFlex justifyContentSpaceBetween>
              <IssueLabelText>Full Name</IssueLabelText>
              <IssueValueFlex column>
                <Input
                  placeholder="Enter the title"
                  onChange={handleChange}
                  onBlur={() => {
                    setFieldTouched('full_name', true)
                  }}
                  type="text"
                  name="full_name"
                />
                <ErrorMessage component={CustomErrorMessage} name="full_name" />
              </IssueValueFlex>
            </IssueContainerFlex>
            <IssueContainerFlex justifyContentSpaceBetween>
              <IssueLabelText>Email</IssueLabelText>
              <IssueValueFlex column>
                <Input
                  placeholder="Enter the email"
                  onChange={handleChange}
                  onBlur={() => {
                    setFieldTouched('email', true)
                  }}
                  type="text"
                  name="email"
                />
                <ErrorMessage component={CustomErrorMessage} name="email" />
              </IssueValueFlex>
            </IssueContainerFlex>
            <IssueContainerFlex justifyContentSpaceBetween>
              <IssueLabelText>Phone No</IssueLabelText>
              <IssueValueFlex column>
                <Input
                  placeholder="Enter the phone_no."
                  onChange={handleChange}
                  onBlur={() => {
                    setFieldTouched('phone_no', true)
                  }}
                  type="number"
                  name="phone_no"
                />
                <ErrorMessage component={CustomErrorMessage} name="phone_no" />
              </IssueValueFlex>
            </IssueContainerFlex>
            <IssueContainerFlex justifyContentSpaceBetween>
              <IssueLabelText>Designation </IssueLabelText>
              <IssueValueFlex column>
                <Input
                  placeholder="Enter the Designation"
                  onChange={handleChange}
                  onBlur={() => {
                    setFieldTouched('designation', true)
                  }}
                  name="designation"
                />
                <ErrorMessage
                  component={CustomErrorMessage}
                  name="designation"
                />
              </IssueValueFlex>
            </IssueContainerFlex>
            <Flex style={{ marginTop: '20px' }} justifyContentFlexEnd>
              <Button
                style={{ marginRight: '10px' }}
                type="Submit"
                disabled={!isValid}
                variant="success"
                isLoading={showLoading}
                onClick={handleSubmit}
              >
                Submit
              </Button>
              <Button
                type="button"
                variant="danger"
                onClick={() => {
                  handleReset()
                  setShowUser(false)
                }}
              >
                Cancel
              </Button>
            </Flex>
          </Form>
        )}
      />
    </Card>
  )
}
export default UserForm
