import React from 'react'
import { IAutoRefresh } from '@fareye/types'

export function useTimer(props?: IAutoRefresh) {
  if (Object.keys(props || {}).length == 0) {
    return [0, null]
  }
  const { maxRefreshCount, minAutoRefreshInterval } = props || {}
  var refreshInterval = props?.refreshInterval
  if (
    refreshInterval &&
    minAutoRefreshInterval &&
    refreshInterval < minAutoRefreshInterval
  ) {
    refreshInterval = minAutoRefreshInterval
  }

  const [timer, setTimer] = React.useState(0)
  const intervalId = React.useRef<any>(null)
  function interval() {
    if (refreshInterval && refreshInterval > 0) {
      const intervalInSecs = refreshInterval * 60 * 1000
      setTimeout(() => {
        setTimer((maxRefreshCount || 1) - 1)
        intervalId.current = setInterval(() => {
          setTimer((prevTimer: number) => {
            if (prevTimer === 1) {
              clearInterval(intervalId.current)
            }
            return prevTimer - 1
          })
        }, intervalInSecs)
      }, intervalInSecs)
    }
  }

  React.useEffect(() => {
    // component mounts with no setInterval running
    if (refreshInterval && refreshInterval > 0 && !intervalId.current) {
      interval()
      // component mounts with setInterval already running
    } else if (refreshInterval && refreshInterval > 0 && intervalId.current) {
      clearInterval(intervalId.current)
      interval()
      // clear any setInterval assigned
    } else if (!refreshInterval) {
      clearInterval(intervalId.current)
      intervalId.current = null
    }
  }, [refreshInterval])

  return [timer, setTimer]
}
