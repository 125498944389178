import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaChevronLeft,
  FaChevronRight,
  FaEllipsisH
} from '@fareye/utils'
import React from 'react'
import { Flex, PopOver } from '../../main'
import { PageList, PaginationActions, Option, SelectPageSize } from '../styled'
import { IPagination } from '../types'

const Pagination = (props: IPagination) => {
  const {
    pageSize,
    gotoPage,
    pageIndex,
    canNextPage,
    setPageSize,
    dataLoading,
    disablePageSizeChange,
    pageOptions,
    totalElements,
    showPagination,
    canPreviousPage
  } = props
  if (!showPagination || !totalElements) {
    return null
  }
  const totalPages = Math.ceil(totalElements / pageSize)
  const totalPageIndexToShow = totalPages > 3 ? 4 : totalPages

  var lowest =
    totalPages - pageIndex > 3 ? pageIndex : totalPages - totalPageIndexToShow
  var highest = lowest + (totalPageIndexToShow - 1)
  var isPrev = totalPages > totalPageIndexToShow && pageIndex > 1
  var isNext = totalPages > totalPageIndexToShow && highest < totalPages - 1

  const canNext = totalElements > (pageIndex + 1) * pageSize && !dataLoading
  const canPrev = pageIndex > 0 && !dataLoading
  var renderPagination

  if (totalPages < 5) {
    renderPagination = (
      <PageList>
        <li
          style={{ cursor: canPrev ? 'pointer' : 'not-allowed' }}
          onClick={() => canPrev && gotoPage(pageIndex - 1)}
        >
          <FaChevronLeft size={10} color={canPrev ? 'inherit' : '#cfcfcf'} />
        </li>
        {new Array(totalPageIndexToShow).fill('').map(
          (i, j) =>
            lowest + j < pageIndex + 2 && (
              <li
                className={j === pageIndex ? 'active' : ''}
                key={j}
                onClick={() => !dataLoading && gotoPage(j)}
              >
                {j + 1}
              </li>
            )
        )}
        <li
          style={{ cursor: canNext ? 'pointer' : 'not-allowed' }}
          onClick={() => canNext && gotoPage(pageIndex + 1)}
        >
          <FaChevronRight size={10} color={canNext ? 'inherit' : '#cfcfcf'} />
        </li>
      </PageList>
    )
  } else {
    renderPagination = (
      <PageList>
        <li
          style={{ cursor: canPrev ? 'pointer' : 'not-allowed' }}
          onClick={() => canPrev && gotoPage(pageIndex - 1)}
        >
          <FaChevronLeft size={10} color={canPrev ? 'inherit' : '#cfcfcf'} />
        </li>
        {lowest !== 0 && (
          <li onClick={() => !dataLoading && gotoPage(0)}>{1}</li>
        )}
        {isPrev && (
          <li
            className="bring-more"
            onClick={() =>
              !dataLoading && gotoPage(pageIndex > 4 ? pageIndex - 4 : 0)
            }
          >
            <FaEllipsisH className="ellipsis" size={10} color="#797979" />
            <FaAngleDoubleLeft className="double-angle" size={10} />
          </li>
        )}

        {new Array(totalPageIndexToShow).fill('').map(
          (i, j) =>
            lowest + j < pageIndex + 2 && (
              <li
                key={j}
                className={lowest + j === pageIndex ? 'active' : ''}
                onClick={() => !dataLoading && gotoPage(lowest + j)}
              >
                {lowest + j + 1}
              </li>
            )
        )}
        {/* {isNext && (
          <li
            className="bring-more"
            onClick={() =>
              gotoPage(pageIndex + 4 < totalPages ? pageIndex + 4 : totalPages)
            }
          >
            <FaEllipsisH className="ellipsis" size={10} color="#797979" />
            <FaAngleDoubleRight className="double-angle" size={10} />
          </li>
        )} */}
        {/*highest !== totalPages && (
          <li onClick={() => gotoPage(totalPages)}>{totalPages + 1}</li>
        )*/}
        <li
          style={{ cursor: canNext ? 'pointer' : 'not-allowed' }}
          onClick={() => canNext && gotoPage(pageIndex + 1)}
        >
          <FaChevronRight size={10} color={canNext ? 'inherit' : '#cfcfcf'} />
        </li>
      </PageList>
    )
  }
  return (
    <PaginationActions data-testid="pagination-actions">
      {renderPagination}
      <PopOver data-testid="page-sizes" variant="Top-Right">
        <SelectPageSize data-testid="page-size-selected">
          {pageSize} / page
        </SelectPageSize>
        <PopOver.Body>
          {disablePageSizeChange ? null : (
            <Flex
              column
              data-testid="page-size-options"
              style={{ background: '#fff', borderRadius: 4 }}
            >
              {pageOptions.map((size: number, optionIndex: number) => (
                <Option
                  data-testid={`page-size-option-${optionIndex}`}
                  key={`pagination${optionIndex}`}
                  className={size === pageSize ? 'active' : ''}
                  onClick={() => setPageSize(size)}
                >
                  {size} / page
                </Option>
              ))}
            </Flex>
          )}
        </PopOver.Body>
      </PopOver>
    </PaginationActions>
  )
}

export default Pagination
