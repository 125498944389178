import React from 'react'
import { isEmpty } from '@fareye/utils'
import { Flex, Span, Label } from '@fareye/ui'
import { SummaryFilterContainer } from '../../style/styled'

// Will move to config once color and text is decided
const colorOption = {
  'Total Routes': '#2a5491',
  'Trips Count': '#007f7f',
  'Total Trips': '#007f7f',
  'Ontime Trips': '#C1C1C1',
  'On Time': '#C1C1C1',
  'Delayed Trips': '#FF7B00',
  Delayed: '#FF7B00',
  'Untracked Status': '#c2706f',
  'Untracked Trips': '#c2706f',
  'Total Transporters': '#c2706f',
  'Trips At Loading': 'rgb(240, 98, 146)',
  'Trips At Unloading': 'rgb(100, 181, 246)',
  'Enroute Trips': 'rgb(174, 213, 129)'
}

export const SummaryFilter = ({ summaryList }: any) => {
  return !isEmpty(summaryList) ? (
    <SummaryFilterContainer>
      {summaryList.map((item: any) => (
        <Flex column m={2} key={item.label}>
          <Label
            color={colorOption[item.label]}
            style={{ textAlign: 'center', fontSize: '18px' }}
          >
            {item.value}
          </Label>
          <Span variant="secondary" style={{ textAlign: 'center' }}>
            {item.label}
          </Span>
        </Flex>
      ))}
    </SummaryFilterContainer>
  ) : null
}
