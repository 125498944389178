import { Flex, Label, PopOver, RoundButton, Span, Themes } from '@fareye/ui'
import { FaChevronDown, FaTrash } from '@fareye/utils'
import React from 'react'
import {
  FavStar,
  popoverWrapperStyle,
  popoverBodyStyle
} from '../../../style/styled'
import { ISavedFavourites } from '../../../utility/types'

function SavedFavourites({
  favList,
  selectFav,
  deleteFavourite
}: ISavedFavourites) {
  const [toggleFavState, setToggleFavList] = React.useState<boolean>(false)
  return (
    <PopOver disable={!Boolean(favList.length)} style={popoverWrapperStyle}>
      <Flex
        alignItemsCenter
        onClick={() => setToggleFavList(!toggleFavState)}
        color={Themes.ant.colors.blue6}
      >
        <FavStar
          style={{ strokewidth: favList.length < 5 ? favList.length || 1 : 5 }}
          size={14}
        />
        <Span variant="primary" style={{ whiteSpace: 'nowrap' }}>
          Favourites ({favList.length})
        </Span>
        <FaChevronDown color="#b8b8b8" size={14} />
      </Flex>
      <PopOver.Body variant="arrow" style={{ padding: '5px 0' }}>
        {favList.map((item, n) => (
          <Flex
            p={2}
            key={n}
            style={popoverBodyStyle}
            justifyContentSpaceBetween
          >
            <Label variant="ellipsis" onClick={() => selectFav(item)}>
              {item.name}
            </Label>
            <RoundButton
              variant="default xs"
              onClick={() => deleteFavourite(item.id as number)}
            >
              <FaTrash size="10" />
            </RoundButton>
          </Flex>
        ))}
      </PopOver.Body>
    </PopOver>
  )
}

export default SavedFavourites
