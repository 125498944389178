import { Flex, styled } from '@fareye/ui'
import React from 'react'
import img1 from '../assets/01_In-Transit-ontime.jpg'
import img2 from '../assets/02_In-Transit_Delayed.jpg'
import img3 from '../assets/03_In-Transit_needHelp.jpg'
import img4 from '../assets/04_Update DeliverySlot.jpg'
import img5 from '../assets/05_PickupPointUpdated.jpg'
export const Button = styled.button`
  position: absolute;
  top: 1%;
  right: 19%;
  background: #f0f8fe;
  padding: 2vh 2vw;
  opacity: 0;
  cursor: pointer;
`
export const RefreshButton = styled.button`
  position: absolute;
  top: 1%;
  right: calc(24% + 6vw);
  background: #f0f8fe;
  padding: 2vh 2vw;
  opacity: 0;
  cursor: pointer;
`
export const Submit = styled.button`
  position: absolute;
  top: 52vh;
  right: calc(13% + 6vw);
  background: #f0f8fe;
  padding: 20vh 29vw;
  opacity: 0;
  cursor: pointer;
`
export const Reschedule = styled.button`
  position: absolute;
  top: 20%;
  right: calc(13% + 6vw);
  background: #f0f8fe;
  padding: 30px 50px;
  opacity: 0;
  cursor: pointer;
`

export default function BlankImage({ location, match }: any) {
  let images = [img1, img2, img3, img4, img5]
  const [index, setIndex] = React.useState(0)
  let image = React.useMemo(() => images[index] || images[0], [index])
  const handleClick = (ev: any) => {
    setIndex(index + 1)
    console.log(ev, 'Button is clicked')
  }
  return (
    <Flex>
      <img width={'100%'} src={image}></img>
      <Button
        onClick={() => {
          setIndex(2)
        }}
      >
        Check For Update
      </Button>
      <RefreshButton onClick={handleClick}>Refresh</RefreshButton>
      <Reschedule onClick={handleClick}>Reschedule Delivery Slot</Reschedule>
      <Submit onClick={handleClick}>Submit</Submit>
    </Flex>
  )
}
