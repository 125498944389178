import React, { ReactNode } from 'react'
import { Colors } from '../../../utility/constant'
const defaultStyle: React.CSSProperties = {
  color: Colors.errorColor,
  fontSize: '12px',
  display: 'block'
}
export default function CustomErrorMessage({ children }: any) {
  return <span style={{ ...defaultStyle }}>{children}</span>
}
