import React from 'react'
import { BoxPlot } from '@visx/stats'
import { Group } from '@visx/group'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { scaleBand, scaleLinear } from '@visx/scale'
import {
  useTooltip,
  Tooltip,
  useTooltipInPortal,
  defaultStyles
} from '@visx/tooltip'
import { IBarGraph } from '../utility/types'
import { axisFormatter, setColor } from './utility/funcs'
import { startCase } from '@fareye/utils'
import styled from 'styled-components'
import { Flex } from '../Layout'
import { LegendOrdinal } from '@visx/legend'

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  padding: '10px 20px',
  background: 'rgba(240, 240, 240, 0.95)',
  color: '#1a1a1a',
  boxShadow: '0 0 1px 1px rgba(80, 80, 80, 0.4)',
  borderRadius: 2
}
const Dot = styled.div`
  height: 12px;
  width: 12px;
  margin-right: 5px;
  border-radius: 50%;
  background: ${(props: any) => props.color};
`

function CandleStick(props: IBarGraph): JSX.Element {
  const {
    data,
    width,
    config,
    height,
    stacked,
    tickAngle,
    XLabelKey,
    YLabelKey,
    labelColor,
    yAxisWidth,
    xAxisHeight,
    showLegends,
    brushColor = '#54caa8',
    XLabelColor = '#101010',
    YLabelColor = '#101010',
    brushHeight = 15,
    margin = [20, 0, 0, 40],
    events
  } = props
  const { colors } = config
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useTooltip()
  const x = (d: any) => d && d[XLabelKey]
  const min = (d: any) => d.min
  const max = (d: any) => d.max
  const median = (d: any) => d.median
  const firstQuartile = (d: any) => d.firstQuartile
  const thirdQuartile = (d: any) => d.thirdQuartile

  // scales
  const customScale = scaleBand({
    domain: data.map(x),
    padding: 0
  })
  const { containerRef } = useTooltipInPortal()

  if (width < 100) {
    return <></>
  }
  // bounds
  const xMax = width - margin[3]
  const yMax = height - margin[0] - 100

  const xScale: any = scaleBand({
    range: [0, xMax],
    round: true,
    domain: data.map(x),
    padding: 0.4
  })

  const temperatureScale = scaleLinear({
    domain: [
      Math.min(...data.map((d: any) => Math.min(min(d)))),
      Math.max(...data.map((d: any) => Math.max(max(d))))
    ],
    nice: true,
    padding: 0.3
  })

  const boxWidth = xScale.bandwidth()
  const constrainedWidth = Math.min(40, boxWidth)

  const values = data.reduce((allValues: any, { min, max }: any) => {
    allValues.push(min, max)
    return allValues
  }, [] as number[])
  const minYValue = Math.min(...values)
  const maxYValue = Math.max(...values)

  const yScale: any = scaleLinear({
    range: [yMax, 0],
    round: true,
    domain: [minYValue, maxYValue]
  })

  customScale.rangeRound([0, xMax])
  temperatureScale.range([yMax, 0])

  // relative position is needed for correct tooltip positioning
  return (
    <div style={{ position: 'relative' }}>
      <svg ref={containerRef} width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill="#fff" />
        <Group top={margin[0]} left={margin[3]}>
          {data.map((d: any, i: number) => (
            <g key={i}>
              <BoxPlot
                min={min(d)}
                max={max(d)}
                left={xScale(x(d))! + 0.3 * constrainedWidth}
                firstQuartile={firstQuartile(d)}
                thirdQuartile={thirdQuartile(d)}
                median={median(d)}
                boxWidth={constrainedWidth * 0.4}
                fill={setColor(data[i][XLabelKey], colors)}
                fillOpacity={0.3}
                stroke={setColor(data[i][XLabelKey], colors)}
                strokeWidth={1}
                valueScale={yScale}
                // outliers={outliers(d)} // bubbles above and below candle
                minProps={{
                  onMouseOver: () => {
                    showTooltip({
                      tooltipTop: yScale(min(d)) ?? margin[0],
                      tooltipLeft: xScale(x(d))! + constrainedWidth + margin[3],
                      tooltipData: {
                        min: min(d),
                        name: x(d)
                      }
                    })
                  },
                  onMouseLeave: () => {
                    hideTooltip()
                  }
                }}
                maxProps={{
                  onMouseOver: () => {
                    showTooltip({
                      tooltipTop: yScale(max(d)) ?? margin[0],
                      tooltipLeft: xScale(x(d))! + constrainedWidth + margin[3],
                      tooltipData: {
                        max: max(d),
                        name: x(d)
                      }
                    })
                  },
                  onMouseLeave: () => {
                    hideTooltip()
                  }
                }}
                boxProps={{
                  onMouseOver: () => {
                    showTooltip({
                      tooltipTop: yScale(median(d)) ?? margin[0],
                      tooltipLeft: xScale(x(d))! + constrainedWidth + margin[3],
                      tooltipData: {
                        ...d,
                        name: x(d)
                      }
                    })
                  },
                  onMouseLeave: () => {
                    hideTooltip()
                  }
                }}
                medianProps={{
                  style: {
                    stroke: setColor(data[i][XLabelKey], colors)
                  },
                  onMouseOver: () => {
                    showTooltip({
                      tooltipTop: yScale(median(d)) ?? margin[0],
                      tooltipLeft: xScale(x(d))! + constrainedWidth + margin[3],
                      tooltipData: {
                        median: median(d),
                        name: x(d)
                      }
                    })
                  },
                  onMouseLeave: () => {
                    hideTooltip()
                  }
                }}
              />
            </g>
          ))}
          <AxisBottom
            top={yMax}
            scale={customScale}
            stroke={XLabelColor}
            tickStroke={XLabelColor}
            tickLabelProps={() => ({
              fill: XLabelColor,
              fontSize: 11,
              textAnchor: 'middle'
            })}
          />
          <AxisLeft scale={temperatureScale} />
        </Group>
      </svg>
      {/* <div
        style={{
          position: 'absolute',
          top: margin[0] / 2 - 10,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '14px'
        }}
      >
        <LegendOrdinal
          scale={customScale}
          direction="row"
          labelMargin="0 15px 15px 0"
        />
      </div> */}
      {tooltipOpen && tooltipData && (
        <Tooltip top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <strong>
            {axisFormatter(
              tooltipData[XLabelKey],
              config.xAxisUnit || 'string',
              config.xAxisFormatter
            )}
          </strong>
          {YLabelKey.map((key: string, i: number) => {
            return (
              <Flex>
                <Dot color={setColor(key, colors)} />
                <small>{startCase(key)}</small>&nbsp;&nbsp;
                <span>
                  {axisFormatter(
                    tooltipData[key],
                    config.yAxisUnit || 'string',
                    config.yAxisFormatter
                  )}
                </span>
              </Flex>
            )
          })}
        </Tooltip>
      )}
    </div>
  )
}

export default React.memo(CandleStick)
