import React from 'react'
import Logo from '../../assets/fareye-logo.json'
import { Modal, styled } from '@fareye/ui'
import { TimeZoneList, useTimeZone } from '../..'

const LogoWithDropDown = styled.div`
  position: relative;
  margin: 0 auto;
  &:before {
    content: '';
    position: absolute;
    bottom: 15px;
    right: -15px;
    padding: 3px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-right: 2px solid #324554;
    border-bottom: 2px solid #324554;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
  }
  img {
    height: auto;
    width: 15vmax;
  }
`

export function PublicHeader() {
  const [timeZoneModal, setTimeZoneModal] = React.useState(false)
  const { state, setState } = useTimeZone()
  return (
    <LogoWithDropDown>
      <img
        src={Logo.logo}
        alt="fareye-logo"
        onClick={() => setTimeZoneModal(!timeZoneModal)}
      />
      {timeZoneModal && (
        <Modal
          variant="l"
          toggleModal={timeZoneModal}
          setToggleModal={setTimeZoneModal}
        >
          <TimeZoneList
            setToggleModal={setTimeZoneModal}
            state={state}
            setState={setState}
          />
        </Modal>
      )}
    </LogoWithDropDown>
  )
}
