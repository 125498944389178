import { IMapper, setDateFromStorage } from '@fareye/common'
import {
  Card,
  Flex,
  IRenderControl,
  Label,
  RenderControl,
  Button,
  SearchSelect,
  styled
} from '@fareye/ui'
import queryString, { ParsedQuery } from 'query-string'
import React from 'react'
import { TOKEN_KEY } from '../../config/app'
import KpiGraph from './KpiGraph'
import OverviewPerformanceCards from './OverviewPerformanceCards'
import { cancelToken, get, isEmpty } from '@fareye/utils'
import { HEADER_INFLECTION, IN_PLANT } from '../../config/constants'
import {
  PerformanceCardsContainer,
  DashboardContainer
} from '../../style/styled'
import NewsFeeds from './NewsFeed'
import { dropdownValues } from '../../config/constants'
import ConfigureKpiDashboard from './ConfigureKpiDashboard'
import { FaRegFlag, post } from '@fareye/utils'
import { Link } from 'react-router-dom'
import { FaAngleLeft, FaAngleRight, FaRegChartBar } from 'react-icons/fa'

const initialState = {
  data: null,
  isLoading: false,
  isSuccess: true,
  message: '',
  isError: false
}
export default function Dashboard(props: IMapper) {
  const { location, baseUrl } = props
  const localStorageToken = localStorage.getItem(TOKEN_KEY) || '{}'
  const isInplantRoutes = Boolean(localStorage.getItem(IN_PLANT) || '')
  const user = localStorageToken && JSON.parse(localStorageToken)
  const searchParams: ParsedQuery<string | Date> = queryString.parse(
    location.search
  )
  const source = cancelToken().source()
  const [dateRange, setDateRange] = React.useState(() => {
    return setDateFromStorage({
      showDatePicker: true,
      carryDate: true,
      searchParams,
      daysDiff: 7
    })
  })
  const [performanceData, setPerformanceData] = React.useState<IRenderControl>(
    initialState
  )
  React.useEffect(() => {
    fetchPerformanceData()
  }, [])

  const [modal, setModal] = React.useState(false)
  const [selectedGraph, setSelectedGraph] = React.useState('Route Performance')
  const handleGraphChange = (graph: string) => {
    setSelectedGraph(graph)
  }

  async function fetchPerformanceData() {
    try {
      setPerformanceData({ ...performanceData, isLoading: true })
      const {
        data: { content }
      } = await get(
        `${baseUrl}/api/v2/dashboard`,
        source.token,
        HEADER_INFLECTION
      )
      setPerformanceData({
        ...performanceData,
        isLoading: false,
        data: content,
        isSuccess: true,
        isError: false
      })
      const apiURL = `${baseUrl}/api/v3/journeys/list`
      const riskFilter = [
        [
          {
            condition: 'include',
            filterKey: 'risk_status',
            filterType: 'eq',
            filterValue: ['Shipment at Risk']
          }
        ]
      ]

      const getDates = () => {
        const startFilter = [
          {
            filterKey: 'start_date',
            filterValue: new Date(Date.now() - 60 * 24 * 60 * 60 * 1000),
            condition: 'include',
            filterType: 'gte'
          }
        ]
        const endFilter = [
          {
            filterKey: 'start_date',
            filterValue: new Date(),
            condition: 'include',
            filterType: 'lte'
          }
        ]
        return [startFilter, endFilter]
      }

      const dateFiler = getDates()

      const filter = [...riskFilter, ...dateFiler]

      const sarData = await post(
        apiURL,
        {
          filter
        },
        source.token,
        HEADER_INFLECTION
      )
      setShipmentsAtRisk(
        (sessionStorage.getItem('shipmentsAtRiskCount')
          ? Number(sessionStorage.getItem('shipmentsAtRiskCount'))
          : sarData.data.meta['count']) || 0
      )
    } catch (ex) {
      setPerformanceData({
        ...performanceData,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: 'Something went wrong...'
      })
      console.error(ex)
    }
  }
  const { data } = performanceData

  const [dropdownValue, setDropdownValue] = React.useState(dropdownValues[0])

  const [shipmentsAtRisk, setShipmentsAtRisk] = React.useState(0)

  const setSARFilter = () => {
    sessionStorage.setItem('sar', 'true')
  }

  const container_ref = React.useRef()

  const scroll = (element: any, scrollDir: string) => {
    const scrollOffset = element.scrollWidth - element.offsetWidth
    element.scrollLeft += scrollDir === 'left' ? -scrollOffset : scrollOffset
  }

  return (
    <DashboardContainer>
      <Flex column>
        <Flex justifyContentSpaceBetween>
          <Label variant="h3">Hello {user.name}</Label>
          <Button variant="primary" onClick={() => setModal(true)}>
            <div style={{ display: 'flex', cursor: 'pointer' }}>
              <FaRegChartBar
                size={10}
                style={{
                  color: '#FFFFFF',
                  border: '1px solid #FFFFFF',
                  padding: '1px',
                  cursor: 'pointer'
                }}
              ></FaRegChartBar>
              <Label style={{ paddingLeft: '5px', cursor: 'pointer' }}>
                Configure
              </Label>
            </div>
          </Button>
        </Flex>
        <Flex alignItemsCenter style={{ paddingTop: '5px' }}>
          <Label
            style={{ paddingRight: '8px', color: '#727272', fontSize: '14px' }}
          >
            Here are your updates for
          </Label>
          <SearchSelect
            options={dropdownValues}
            value={dropdownValue}
            onChange={(item: any) => {
              setDropdownValue(item)
            }}
            style={{
              SearchSelect: {
                width: '50%'
              },
              container: {
                width: '250px',
                backgroundColor: '#E5F6FF',
                height: '27px'
              },
              dropdownIndicator: {
                display: 'block',
                transform: 'rotate(0deg) !important',
                height: '20px',
                color: '#727272'
              },
              control: {
                minHeight: '25px',
                height: '27px',
                border: 'none'
              },
              singleValue: {
                color: '#212121',
                fontSize: '14px !important'
              },
              valueContainer: {
                height: '28px !important'
              },
              wrapper: {
                width: 'auto'
              }
            }}
          />
        </Flex>
      </Flex>
      {modal && (
        <ConfigureKpiDashboard
          modal={modal}
          setModal={setModal}
          onSelectGraph={handleGraphChange}
          selectedValue={selectedGraph}
        ></ConfigureKpiDashboard>
      )}
      <RenderControl state={performanceData}>
        {data && (
          <PerformanceCardsContainer>
            <Flex
              justifyContentSpaceBetween
              alignItemsCenter
              style={{
                gridArea: 'card-0',
                height: '70px',
                width: '100%',
                backgroundColor: '#FFE5E7',
                borderRadius: '4px'
              }}
            >
              <Flex alignItemsCenter style={{ paddingLeft: '25px' }}>
                <FaRegFlag style={{ color: '#C84031' }} />
                <Label
                  style={{
                    color: '#C84031',
                    fontSize: '20px',
                    fontWeight: '500',
                    paddingLeft: '15px'
                  }}
                >
                  {shipmentsAtRisk} Shipments are at Risk
                </Label>
              </Flex>
              <Link
                onClick={() => setSARFilter()}
                to={'/in-transit'}
                style={{
                  color: '#1276D3',
                  paddingRight: '10px',
                  textDecoration: 'none'
                }}
              >
                View Shipments
              </Link>
            </Flex>
            <Flex
              alignItemsCenter
              justifyContentSpaceBetween
              style={{ gridArea: 'card-1' }}
            >
              <FaAngleLeft
                size={30}
                onClick={() => scroll(container_ref.current, 'left')}
                style={{
                  color: '#323232',
                  paddingRight: '5px',
                  cursor: 'pointer'
                }}
              />
              <ScrollbarContainer ref={container_ref}>
                <OverviewPerformanceCards
                  style={{ minWidth: '32%' }}
                  showShipment={true}
                  url="/in-transit/eta"
                  showCount={false}
                  label={'Shipments'}
                  data={dropdownValue.value}
                />
                <OverviewPerformanceCards
                  style={{ minWidth: '32%' }}
                  url="/in-transit/eta?status=delayed"
                  showCount={false}
                  showMode={true}
                  label={'Transport Modes'}
                  data={dropdownValue.value}
                />
                <OverviewPerformanceCards
                  style={{ minWidth: '32%' }}
                  label="Yard Visibility"
                  showYardVis={true}
                  data={dropdownValue.value}
                />
                <OverviewPerformanceCards
                  style={{ minWidth: '32%' }}
                  label="Others"
                  showOthers={true}
                  data={dropdownValue.value}
                />
              </ScrollbarContainer>
              <FaAngleRight
                size={30}
                onClick={() => scroll(container_ref.current, 'right')}
                style={{
                  color: '#323232',
                  paddingLeft: '5px',
                  cursor: 'pointer'
                }}
              />
            </Flex>

            <Card style={{ gridArea: 'card-2' }}>
              <NewsFeeds />
            </Card>
            <Card style={{ gridArea: 'graph' }}>
              <KpiGraph
                baseUrl={baseUrl}
                dateRange={dateRange}
                selectedGraph={selectedGraph}
              />
            </Card>
            <OverviewPerformanceCards
              label="Vendor Performance"
              urlLabel="View Report"
              url="/in-transit/transporter-performance"
              showCount={false}
              style={{
                gridArea: 'card-3',
                width: 'auto',
                justifyContent: 'center',
                color: '#1276D3',
                minHeight: '80px'
              }}
            />
            <OverviewPerformanceCards
              label="Route Performance"
              urlLabel="View Report"
              url="/in-transit/route-performance"
              showCount={false}
              style={{
                gridArea: 'card-4',
                width: 'auto',
                justifyContent: 'center',
                color: '#1276D3',
                minHeight: '80px'
              }}
            />
          </PerformanceCardsContainer>
        )}
      </RenderControl>
    </DashboardContainer>
  )
}

const ScrollbarContainer: any = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  gap: 1em;
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`
