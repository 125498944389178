import React from 'react'
import styled from 'styled-components'
import { layout, Props, splitVariantsToTypes } from '../../Theme/styledSystem'
import { ITypographyProps } from '../../utility/types'

const StyledSpan: any = styled.span`
  ${(props: any) => {
    const variant = splitVariantsToTypes(props)
    const isEllipsis = props.variant && props.variant.includes('ellipsis')
    return {
      ...props.theme.span.default,
      ...props.theme.span[variant.display],
      ...props.theme.span[variant.size],
      ...props.theme.span[variant.typography],
      ...(isEllipsis ? props.theme.span.ellipsis : {})
    }
  }} ${layout};
`

const Span = (props: ITypographyProps) => {
  let { variant, children, title } = Props(props)
  return (
    <StyledSpan
      {...props}
      title={variant?.includes('ellipsis') ? title || children : title}
    >
      {children}
    </StyledSpan>
  )
}
export default Span
