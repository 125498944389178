import { IKeyValue } from '@fareye/types'
import { AsyncSearchSelect, components, Flex, Label } from '@fareye/ui'
import { getQueryParams, search, startCase } from '@fareye/utils'
import debounce from 'debounce-promise'
import React from 'react'
import {
  SaveFavouritesBtn,
  SearchFavouriteContainer,
  searchFavouritesWrapperStyle,
  searchStyle
} from '../../../style/styled'
import { ISearchFavourite } from '../../../utility/types'
import SavedFavourites from '../components/SavedFavourites'
import {
  filterDuplicateEntriesFromSelection,
  zonedToUTC,
  zonedToUTCAdd1Day
} from '../../../utility'

// Async compon
const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <Flex justifyContentSpaceBetween style={{ width: '100%' }}>
          <span>{props?.data?.label}</span>
          <Label>
            {startCase(props?.data?.pretty_name || props?.data?.key)}
          </Label>
        </Flex>
      </components.Option>
    </div>
  )
}

function SearchFavourite({
  pageId,
  closest,
  endDate,
  favList,
  selectFav,
  apiFilter,
  setSearch,
  startDate,
  selectedFilter,
  deleteFavourite,
  setSelectedFilter,
  setShowAddFavModal,
  customKeyMapping,
  showTimezone
}: ISearchFavourite) {
  const { apiURL, queryParams } = apiFilter

  const loadOptions = async (value: string) => {
    let ckm = customKeyMapping || {
      key1: 'Key 1',
      key2: 'Key 2',
      key3: 'Key 3'
    }
    if (value.length < 3) {
      return
    }
    try {
      const query = getQueryParams(
        {
          _search: value,
          startDate: showTimezone
            ? zonedToUTC(new Date(startDate as Date))
            : startDate,
          endDate: showTimezone
            ? zonedToUTCAdd1Day(new Date(endDate as Date))
            : endDate,
          ...queryParams
        },
        showTimezone
      )
      const data = await search(`${apiURL}${query ? `?${query}` : ''}`)
      const { search_aggs: apiData } = data
      let result = closest(apiData, value)
        .filter((item: IKeyValue<string>) => item.label !== '')
        .map(item =>
          ckm[item.pretty_name]
            ? { ...item, pretty_name: ckm[item.pretty_name] }
            : item
        )
      return result
    } catch (error) {
      console.error(error)
    }
  }

  let showSetFav: boolean = !!(
    favList.length < 10 &&
    selectedFilter &&
    selectedFilter.length
  )
  //selectedfiltersAlreadySaved(selectedFilter, favList)
  const debounceLoadOption = debounce(loadOptions, 400)

  return (
    <SearchFavouriteContainer>
      <Flex style={searchFavouritesWrapperStyle}>
        <SavedFavourites
          favList={favList}
          selectFav={selectFav}
          deleteFavourite={deleteFavourite}
        />
        <AsyncSearchSelect
          components={{
            Option,
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null
          }}
          noOptionsMessage={() => '-'}
          style={{ wrapper: searchStyle.wrapper }}
          styles={searchStyle}
          placeholder="Type Atleast 3 Chars"
          loadOptions={(value: string) => debounceLoadOption(value)}
          onChange={(value: any) => {
            // component interface
            if (value !== null) {
              let newValue = filterDuplicateEntriesFromSelection(
                selectedFilter,
                value
              )
              setSelectedFilter(newValue)
              sessionStorage.setItem(
                'globalSearch',
                JSON.stringify({
                  selectedFilter: newValue,
                  pageId
                })
              )
              setSearch([...newValue])
            }
          }}
          isMulti
          value={[]}
        />
      </Flex>
      {showSetFav && (
        <SaveFavouritesBtn setShowAddFavModal={setShowAddFavModal} />
      )}
    </SearchFavouriteContainer>
  )
}

export default SearchFavourite
