import React from 'react'
import { FaCaretUp, FaCaretDown } from '@fareye/utils'
import styled from 'styled-components'
import { ISorting } from '../types'

const SortIconsStyled: any = styled.div`
  position: relative;
  display: inline-block;
  width: ${(props: any) => props.width}px;
  height: 16px;
  > svg {
    font-weight: 700;
    position: absolute;
    left: 1px;
    top: 1px;
    :last-child {
      top: 7px;
    }
  }
`

interface ISortIcons extends ISorting {
  column?: any
  size: number
}

function SortIcons({ sorting, column, size }: ISortIcons) {
  if (!column.canSort && !column.sortable) {
    return <span />
  }
  let isAsc = false
  let isDesc = false
  if (sorting) {
    // sortByDirection false means sort direction desc
    isAsc = column.id === sorting.sortBy && !sorting.sortByDirection
    // sortByDirection true means sort direction asc
    isDesc = column.id === sorting.sortBy && !!sorting.sortByDirection
  } else if (column.isSorted) {
    isAsc = !column.isSortedDesc
    isDesc = column.isSortedDesc
  }
  return (
    <SortIconsStyled width={size}>
      <FaCaretUp size={size} color={isDesc ? '#1890ff' : '#bfbfbf'} />
      <FaCaretDown size={size} color={isAsc ? '#1890ff' : '#bfbfbf'} />
    </SortIconsStyled>
  )
}

export default SortIcons
