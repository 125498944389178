import React from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import mentionStyle from './comments/mentionStyle'
import { Button } from '@fareye/ui'

function MentionInput(props: any) {
  let {
    comment,
    populateForm,
    getUserLocally,
    setShowUser,
    submittedUser,
    setComment
  } = props

  function displayTransform(id: string, display: string) {
    if (id === String(-1)) {
      if (submittedUser) {
        return submittedUser.email
      }
      return ''
    }
    return display
  }

  function setValidUser(id: any) {
    if (id == -1) {
      const cloneComment = comment.replace('@[Add User](email:-1)', '')
      setComment(cloneComment)
    }
  }

  return (
    <MentionsInput
      value={comment}
      onChange={populateForm}
      singleLine={false}
      className="mention-input"
      style={mentionStyle}
      placeholder="Comment and use @ to mention"
    >
      <Mention
        markup="@[__display__](email:__id__)"
        trigger="@"
        data={getUserLocally}
        style={{ backgroundColor: 'transparent' }}
        renderSuggestion={(suggestion, search, highlightedDisplay) =>
          suggestion && suggestion.id === -1 ? (
            <Button
              width="100%"
              style={{ margin: '-5px -15px', width: '-webkit-fill-available' }}
              childrenStyle={{ textAlign: 'center' }}
              onClick={() => setShowUser(true)}
              variant="primary"
            >
              Add User
            </Button>
          ) : (
            highlightedDisplay
          )
        }
        onAdd={(id, display) => {
          setValidUser(id)
        }}
        appendSpaceOnAdd={true}
        displayTransform={displayTransform}
      />
    </MentionsInput>
  )
}

export default MentionInput
