import { Button, Flex } from '@fareye/ui'
import { FaPlus, FaTrash } from '@fareye/utils'
import { ErrorMessage, FieldArray } from 'formik'
import React from 'react'
import { IFormInputConfig } from '../utility/types'
import CustomErrorMessage from './CustomErrorMessage'
import GenerateRowElement from './GenerateRowElement'

export default function MultiRow({
  config,
  values,
  formikProps,
  formGroup,
  baseUrl
}: any) {
  const { formKey, options, label } = config

  return (
    <>
      <FieldArray
        name={formKey}
        render={(arrayHelper: any) => (
          <Flex column alignItemsFlexStart width={1}>
            <Flex column width={1}>
              {values[formKey] &&
                values[formKey].map((formGroup: any, index: number) => {
                  return (
                    <Flex
                      style={{ marginBottom: '15px' }}
                      key={`${index}`}
                      justifyContentSpaceBetween
                      wrap
                      width={1}
                    >
                      {(options as any[]).map(
                        (ele: IFormInputConfig, secondIndex: number) => {
                          return (
                            <GenerateRowElement
                              rowElement={ele}
                              key={`${index}${secondIndex}`}
                              formikProps={formikProps}
                              count={(options as any[]).length}
                              formGroup={formGroup}
                              styles={ele.styles ? ele.styles : {}}
                              elePosition={secondIndex}
                              baseUrl={baseUrl}
                              formKeyName={`${formKey}.${index}.${ele.formKey}`}
                            />
                          )
                        }
                      )}
                      <span
                        style={{ padding: '25px 15px 0' }}
                        onClick={() => arrayHelper.remove(index)}
                      >
                        <FaTrash />
                      </span>
                    </Flex>
                  )
                })}
            </Flex>
            <Button
              onClick={() => arrayHelper.push({})}
              type="button"
              variant="m link"
            >
              <FaPlus size={10} />
              {label}
            </Button>
          </Flex>
        )}
      />
      <ErrorMessage component={CustomErrorMessage} name={formKey} />
    </>
  )
}
