import React from 'react'
import { FaFlag, isEmpty } from '@fareye/utils'
import { Flex } from '../../main'
import Commenting from '../components/Commenting'
import Download from '../components/Download'
import Refresh from '../components/Refresh'
import SelectedFilterListing from '../components/SelectedFilterListing'
import { TableAction } from '../styled'
import { ITableActions } from '../types'

function TableActions(props: ITableActions) {
  const {
    refresh,
    refreshing,
    downloadCSV,
    totalElements,
    toggleFlagging,
    showTotalCount,
    downloadLoading,
    commentingModule,
    selectedFilters,
    setSelectedFilters
  } = props

  const Flagging = () => {
    return toggleFlagging ? (
      <FaFlag
        style={{ color: '#00a4ff', margin: '0 15px' }}
        size={18}
        onClick={toggleFlagging}
      />
    ) : null
  }
  return (
    <TableAction data-testid={`table-actions`} alignItemsCenter>
      <Flex
        data-testid={`total-elements`}
        alignItemsCenter
        style={{ marginLeft: 5 }}
      >
        {showTotalCount ? `Total ${totalElements || '-'}` : null}
      </Flex>
      <Flex style={{ fontSize: '10px', flex: '1 0 100px', overflow: 'hidden' }}>
        <Flex
          alignItemsFlexStart
          style={{ flex: '1 0 100px', overflow: 'hidden' }}
        >
          {!isEmpty(selectedFilters) ? (
            <SelectedFilterListing
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          ) : null}
        </Flex>
        <Flex style={{ flex: '0 0 auto' }}>
          <Refresh refreshing={refreshing} refresh={refresh} />
          <Commenting commentingModule={commentingModule} />
          <Flagging />
          <Download
            downloadCSV={downloadCSV}
            downloadLoading={downloadLoading}
          />
        </Flex>
      </Flex>
    </TableAction>
  )
}

export default TableActions
