import React from 'react'
import styled from 'styled-components'
import { layout, Props } from '../Theme/styledSystem'
import { IDropdownProps, IDropdownOptionsProps } from '../utility/types'

const DropDown: any = styled.div`
  ${(props: IDropdownProps) =>
    props.position
      ? {
          ...props.theme.dropdown.dropdownFrame,
          ...props.theme.dropdown[props.position]
        }
      : props.theme.dropdown.dropdownFrame};
`

const StyledDropdown: any = styled.div`
  ${(props: IDropdownProps) => props.theme.dropdown.dropdownOptionsFrame}
  ${(props: IDropdownProps) => props.theme[props.position || 'Bottom-Left']}}
  ${(props: IDropdownProps) =>
    !props.Click && { '&:hover': { display: 'block' } }}
  ${(props: IDropdownProps) =>
    props.Click && props.toggle && { display: 'block' }}
  ${layout}
`

const StyledItem: any = styled.div`
  ${(props: IDropdownProps) => props.theme.dropdown.dropdownItem};
`
const Label: any = styled.label`
  ${(props: IDropdownProps) => props.theme.dropdown.dropdownLabel} ${(
    props: IDropdownProps
  ) => !props.Click && { '&:hover + div': { display: 'block' } }};
`

const Dropdown = (props: IDropdownProps) => {
  const wrapperRef = React.useRef(null)
  const [toggle, setToggle] = React.useState(false)
  function useOutsideAlerter(ref: any) {
    function handleClickOutside(event: any) {
      event.stopPropagation()
      if (toggle && ref.current && !ref.current.contains(event.target)) {
        setToggle(false)
      }
    }
    React.useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    })
  }
  useOutsideAlerter(wrapperRef)
  let { options, onChange, label, value, Click } = Props(props)
  return (
    <DropDown ref={wrapperRef} position={props.position}>
      {typeof label === 'string' || typeof label === 'number' ? (
        <Label Click={Click} onClick={() => setToggle(!toggle)}>
          {value.label || label}
        </Label>
      ) : (
        label
      )}
      <StyledDropdown Click={Click} toggle={toggle} position={props.position}>
        {options.map((option: IDropdownOptionsProps, i: number) => (
          <StyledItem
            key={i}
            onClick={() => {
              setToggle(false)
              onChange(option)
            }}
          >
            {option.label}
          </StyledItem>
        ))}
      </StyledDropdown>
    </DropDown>
  )
}

export default Dropdown
