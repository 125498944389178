import {
  FaCalendarAlt,
  FaPencilAlt,
  isEqual,
  isTodayAndEqual,
  format,
  tz
} from '@fareye/utils'
import React from 'react'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import styled from 'styled-components'
import { Button, Flex, layout } from '../main'
import { IDateRangeSelectorProps } from '../utility/types'

type TShowHide = 'initial' | 'show' | 'hide'

const DatePicker = styled(DateRangePicker)`
  .rdrDefinedRangesWrapper {
    @media (max-width: 540px) {
      display: none;
    }
  }
`

const DatePickerFrame: any = styled.div`
  ${(props: any) => {
    const { variant } = props
    const isFareyeV2 = variant && variant.includes('fareyeV2')
    return props.theme.datePicker[isFareyeV2 ? 'fareyeV2' : 'default'].frame
  }} ${layout};
`
const datePickerLabelChange = (date: Date): any => {
  return typeof date === 'object'
    ? `${format(date, 'DD MMM YY')}`
    : `${format(tz(new Date(date)), 'DD MMM YY')}`
}
const DateRangeWrapper: any = styled.div`
  background: #fff;
  ${(props: any) => {
    const bl = props.variant && props.variant.includes('bottom-left')
    return bl
      ? `
      left: 0;
      transform-origin: top left;
    `
      : `
      right: 0;
      transform-origin: top right;
    `
  }}
  ${props => props.theme.datePicker['default'].dateRangeWrapper};
`

export default function DateRangeSelector({
  value,
  style,
  variant,
  minDate,
  maxDate = new Date(),
  formatter,
  className = '',
  buttonClass = '',
  onDateRangeSelected
}: IDateRangeSelectorProps) {
  const [selectionRange, setSelectionRange] = React.useState({
    startDate: value.startDate,
    endDate: value.endDate
  })

  const [showDateRangePicker, setShowDateRangePicker] = React.useState<
    TShowHide
  >('initial')
  React.useEffect(() => {
    setSelectionRange({
      startDate: value.startDate,
      endDate: value.endDate
    })
  }, [value.startDate, value.endDate])

  function handleSelect(ranges: any) {
    const formatDateforTimeRemoval = (date: Date): Date => {
      const t = date
      return new Date(
        (t.getMonth() + 1 + '-' + t.getDate() + '-' + t.getFullYear()).replace(
          /-/g,
          '/'
        )
      )
    }
    let sd = formatDateforTimeRemoval(ranges.range1.startDate)
    let ed = formatDateforTimeRemoval(ranges.range1.endDate)
    if (maxDate) {
      ed = maxDate < ed ? new Date(maxDate) : ed
    }
    if (minDate) {
      sd = minDate > sd ? new Date(minDate) : sd
    }
    setSelectionRange({
      startDate: sd,
      endDate: ed
    })
  }

  const { startDate, endDate } = selectionRange
  let buttonClosedLabel = 'Select Date'
  if (isTodayAndEqual(startDate, endDate) || isEqual(startDate, endDate)) {
    buttonClosedLabel = datePickerLabelChange(startDate)
  } else if (!isEqual(startDate, endDate)) {
    buttonClosedLabel =
      datePickerLabelChange(startDate) + ' - ' + datePickerLabelChange(endDate)
  }
  const FareyeStyle = {
    right: {
      border: '1px solid #a9d7fd',
      borderRadius: '8px',
      height: '16px',
      width: '16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    child: {
      color: '#687e90',
      margin: '0 5px'
    },
    wrapper: {
      alignItems: 'center',
      background: '#eaf0f7',
      padding: '3px 8px'
    }
  }
  const dateIsChanged =
    value.startDate !== selectionRange.startDate ||
    value.endDate !== selectionRange.endDate
  return (
    <DatePickerFrame
      variant={variant}
      className={className}
      style={style && style.frame}
    >
      <Button
        variant={`default s`}
        className={buttonClass}
        style={{
          ...FareyeStyle.wrapper,
          ...(style && style.button)
        }}
        childrenStyle={FareyeStyle.child}
        leftChild={<FaCalendarAlt size={11} color="#a9d7fd" />}
        rightChild={<FaPencilAlt size={8} color="#a9d7fd" />}
        rightStyle={FareyeStyle.right}
        onClick={() =>
          setShowDateRangePicker(
            ['initial', 'hide'].includes(showDateRangePicker) ? 'show' : 'hide'
          )
        }
      >
        {buttonClosedLabel}
        {/* {['initial', 'hide'].includes(showDateRangePicker)
          ? buttonClosedLabel
          : dateIsChanged
          ? 'Apply'
          : 'Close'} */}
      </Button>
      {showDateRangePicker === 'show' && (
        <DateRangeWrapper
          variant={variant}
          style={style && style.dateRangeWrapper}
        >
          <Flex column>
            <DatePicker
              ranges={[
                {
                  startDate:
                    typeof selectionRange.startDate === 'object'
                      ? selectionRange.startDate
                      : tz(new Date(selectionRange.startDate)),
                  endDate:
                    typeof selectionRange.endDate === 'object'
                      ? selectionRange.endDate
                      : tz(new Date(selectionRange.endDate))
                }
              ]}
              onChange={handleSelect}
              maxDate={maxDate}
              minDate={minDate}
              direction="horizontal"
            />
            <Flex
              justifyContentFlexEnd
              style={{ padding: '0 10px 10px', marginTop: '-10px' }}
            >
              <Button
                variant="default m"
                onClick={() => {
                  setShowDateRangePicker('hide')
                  setSelectionRange({
                    startDate: value.startDate,
                    endDate: value.endDate
                  })
                }}
              >
                Cancel
              </Button>
              {dateIsChanged && (
                <Button
                  variant="primary m"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    setShowDateRangePicker('hide')
                    onDateRangeSelected({
                      startDate: formatter
                        ? formatter(selectionRange.startDate)
                        : selectionRange.startDate,
                      endDate: formatter
                        ? formatter(selectionRange.endDate)
                        : selectionRange.endDate
                    })
                  }}
                >
                  Apply
                </Button>
              )}
            </Flex>
          </Flex>
        </DateRangeWrapper>
      )}
    </DatePickerFrame>
  )
}
