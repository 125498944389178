import { dMmm, formatMs } from '@fareye/utils'
import numberAbbreviate from 'number-abbreviate'
import { DataKey } from 'recharts'
import { MASTER } from '../../Theme/styledSystem'
import { IKeyValueString } from '../../utility/types'
import { default as graphColorPalette } from './graphColors.json'
import { TGraphType } from '../../utility/variantTypes'
import { lazy } from 'react'
import Candlestick from '../candlestick'
import { coerceNumber } from '@visx/scale'

const CHARWIDTH = 9
let minHeightOrWidth = 60

export function barWidthCalc(width: number, data: any[]) {
  return Math.ceil(width / data.length) * 0.5
}
export function dataPointWidth(width: number, data: any[]) {
  return Math.ceil(width / data.length) * 0.5
}

export function axisFormatter(
  value: any,
  unit: string,
  customAxisFormatter: any
): any {
  try {
    if (customAxisFormatter) {
      return customAxisFormatter(value)
    } else {
      switch (unit) {
        case 'minute':
        case 'second':
        case 'hour':
        case 'millisecond':
          return formatMs(
            value,
            { largest: 2, round: true, maxDecimalPoints: 1 },
            unit
          )
        case 'date':
          return dMmm(new Date(value))
        case 'number':
          return isNaN(value) ? '-' : numberAbbreviate(value, 3)
        case 'decimal':
          return parseFloat(value).toFixed(2)
        default:
          return value
      }
    }
  } catch (ex) {
    return value
  }
}

export function getSelectedGraphType(graphView: string, layout?: string) {
  let Comp: any = lazy(() => import('../Bar'))
  switch (graphView) {
    case MASTER.GRAPH.BAR:
      Comp =
        layout === 'vertical'
          ? lazy(() => import('../Bar'))
          : lazy(() => import('../BarHorizontal'))
      break
    case MASTER.GRAPH.LINE:
      Comp = lazy(() => import('../Line'))
      break
    case MASTER.GRAPH.AREA:
      Comp = lazy(() => import('../Area'))
      break
    case MASTER.GRAPH.PIE:
      Comp = lazy(() => import('../Pie'))
      break
    case MASTER.GRAPH.TABLE:
      // return Table
      break
    case MASTER.GRAPH.CANDLESTICK:
      Comp = Candlestick
      break
    default:
      Comp = lazy(() => import('../Bar'))
  }
  return Comp
}

export function getGraphColors(
  data: any,
  graphType: TGraphType,
  XLabelKey: string,
  YLabelKey: string[],
  colors: any = {},
  stacked?: string
) {
  const defaultGraphColor = 'lightgreen12'
  const graphColors = { ...colors }
  graphColors['default'] = graphColors['default'] || '#2A44DE' //graphColors[defaultGraphColor]

  const groupedColors = groupedColorsByColorWeight()
  // If a color matrix is m x n then
  // towards m color changes and towards n color darkens
  // now colorgroupIndex changes m and colorIndex changes n
  function colorFinder(keysToBeColored: string[]) {
    keysToBeColored.map((key: string) => {
      if (!colors.hasOwnProperty(key)) {
        const colorKey = groupedColors[colorgroupIndex][colorIndex]
        graphColors[key] = (graphColorPalette as IKeyValueString)[colorKey]
        if (colorIndex === groupedColors[0].length - 1) {
          colorIndex = 0
          colorgroupIndex++
          if (colorgroupIndex === groupedColors.length - 1) {
            colorIndex = 0
            colorgroupIndex = 0
          }
        } else {
          colorIndex++
        }
      }
      return null
    })
  }
  let colorgroupIndex = 0
  let colorIndex = 0
  if (stacked) {
    let keysToBeColored: any = [...YLabelKey]
    colorFinder(keysToBeColored)
  } else if (!stacked && graphType === 'bar') {
    let keysToBeColored: any = []
    colorFinder(keysToBeColored)
  } else {
    let keysToBeColored: any = []
    data.map((item: any) => {
      YLabelKey.map((key: string) => keysToBeColored.push(item[key]))
      keysToBeColored.push(item[XLabelKey])
      return false
    })
    // color finder allocate colors to keys provided
    colorFinder(keysToBeColored)
  }
  return graphColors
}

export const groupedColorsByColorWeight = () => {
  let groupedColorsByColorWeight: any = []
  const colorsWeightsByPriority: string[] = [
    '3',
    '6',
    '4',
    '7',
    '2',
    '5',
    '1',
    '8',
    '9'
  ]
  colorsWeightsByPriority.map((i: string, index: number) => {
    groupedColorsByColorWeight.push([])
    return Object.keys(graphColorPalette).map((item: string, n: number) => {
      if (
        item.indexOf(i) > -1 &&
        !(
          item.indexOf(`0`) === item.length - 2 ||
          item.indexOf(`1`) === item.length - 2
        ) &&
        item.indexOf(i) === item.length - 1
      ) {
        groupedColorsByColorWeight[index].push(item)
      }
      return null
    })
  })
  return groupedColorsByColorWeight
}

export const setColor = (key: DataKey, colors: IKeyValueString) => {
  return colors[key as string] || colors['default']
}

export function yAxisWidthCalculator(
  data: any[],
  width: number,
  key: string,
  layout: string,
  yAxisUnit?: string,
  minAxisSizeForLabel?: number
): number {
  minHeightOrWidth = minAxisSizeForLabel || minHeightOrWidth
  if (layout === 'vertical') {
    return minHeightOrWidth
  }
  const percentAllowed = Math.floor(width * 0.2)
  const dataLengths: any = []
  data.map((item: any) => dataLengths.push(item[key].length))
  const maxLengthValue = Math.max(...dataLengths) * CHARWIDTH
  const calculatedWidth =
    maxLengthValue < percentAllowed ? maxLengthValue : percentAllowed
  return calculatedWidth > minHeightOrWidth ? calculatedWidth : minHeightOrWidth
}
export function xAxisHeightCalculator(
  data: any[],
  height: number,
  key: string,
  layout: string,
  xAxisUnit?: string,
  minAxisSizeForLabel?: number
): number {
  minHeightOrWidth = minAxisSizeForLabel || minHeightOrWidth
  if (layout === 'horizontal') {
    return minHeightOrWidth
  }
  const percentAllowed = Math.floor(height * 0.2)
  const dataLengths: any = []
  data.map((item: any) => dataLengths.push(item[key].length))
  const maxLengthValue = Math.max(...dataLengths) * CHARWIDTH
  const calculatedWidth =
    maxLengthValue < percentAllowed ? maxLengthValue : percentAllowed
  return calculatedWidth > minHeightOrWidth ? calculatedWidth : minHeightOrWidth
}

export const getMinMax = (vals: (number | { valueOf(): number })[]) => {
  const numericVals: any = vals.map(coerceNumber)
  return [Math.min(...numericVals), Math.max(...numericVals)]
}
