import { FaInbox } from '@fareye/utils'
import React from 'react'
import Loader from '../../Loader'
import { Flex } from '../../main'
import { EmptyTable } from '../styled'
import { INoData } from '../types'

function NoData({ colSpan, noDataLoading }: INoData) {
  var content = <Loader containerStyle={{ boxSizing: 'border-box' }} />
  if (!noDataLoading) {
    content = (
      <Flex style={{ padding: 55 }} column alignItemsCenter>
        <FaInbox />
        <span>No Data</span>
      </Flex>
    )
  }
  return (
    <EmptyTable data-testid="tbody-nodata">
      <td colSpan={colSpan}>{content}</td>
    </EmptyTable>
  )
}

export default NoData
