import React from 'react'
import styled from 'styled-components'

const Summary = styled('summary')`
  &::-webkit-details-marker {
    display: none;
  }
`

interface AccordionProps {
  title: React.ReactNode
  children: React.ReactNode
  isOpen?: boolean
  style?: React.CSSProperties
  titleStyle?: React.CSSProperties
}

export default function Accordion({
  title,
  children,
  isOpen = false,
  titleStyle = {},
  style = {}
}: AccordionProps) {
  const [open, setOpen] = React.useState(isOpen)
  return (
    <details open={open} style={style}>
      <Summary style={titleStyle} onClick={() => setOpen(!open)}>
        {title}
      </Summary>
      {children}
    </details>
  )
}
