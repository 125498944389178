import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
import { TOKEN_KEY } from './config/app'

const trackingId = 'UA-138238764-1'
// 'UA-138238764-1' // FarEye Tracker Id
// 'UA-158824750-1' // Temp abhishek account tracker id
const { name } = JSON.parse(localStorage.getItem(TOKEN_KEY) || '{}')
const history = createBrowserHistory()

// mapping to be removed as we start to get company in login response
// code for testing analytics
const userMap: any = {
  'Ajit kumar Verma': 'tatasteelbsl',
  'Nikhil Arora': 'honda',
  'Team Csd': 'tatafamd',
  'Team Jsl': 'jindal',
  'Hzl Consigner': 'hzl',
  'Tester Account': 'testing'
}

ReactGA.initialize(trackingId)
ReactGA.set({
  userId: userMap[name]
})

history.listen(location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(`${userMap[name]}${location.pathname}`)
})

export default history
