import { Button, Label, styled } from '@fareye/ui'

export const ImgWrapper: any = styled.div`
  background: ${(props: any) => props.bg};
  margin: -24px -24px 20px;
  padding: 24px;
  img {
    width: auto;
    height: 90px;
    margin: 0 auto;
    display: block;
  }
`
export const UploadImage = styled(Button)`
  position: relative;
  width: 100px;
  margin-left: auto;
  input {
    opacity: 0;
    width: 100%;
    border: none;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    position: absolute;
  }
`

export const LabelField = styled(Label)`
  padding: 5px 3vw;
  color: #727272;
`
