import { IKeyValue } from '@fareye/types'
import { isEmpty } from '@fareye/utils'
import { Form, Formik, FormikErrors, FormikProps } from 'formik'
import React from 'react'
import { object } from 'yup'
import CustomForm from '../components/CustomForm'
import {
  getInputInitialValue,
  getValidationValue,
  multiAddValidation,
  isTimeRemaining
} from '../utility/funcs'
import {
  IFormBuilderState,
  IFormConfig,
  IFormInputConfig
} from '../utility/types'

interface IFormBuilder {
  config: IFormConfig
  setSubmitValues: (val: any, reset?: any) => void
  formDefaultValue: any
  formGroupName?: string
  isLoading?: boolean
  baseUrl: string
  onFieldChange?: (val: any) => void
  onSpecificKeyChange?: (val: any) => void
  validate?: (values: any) => FormikErrors<any> | Promise<any>
  buttonComponent?: React.ReactElement<any>
}

export default function FormBuilder({
  config,
  setSubmitValues,
  formDefaultValue,
  formGroupName = 'formGroup',
  isLoading,
  baseUrl,
  onFieldChange,
  onSpecificKeyChange,
  validate,
  buttonComponent
}: IFormBuilder) {
  const [formConfig, setFormConfig] = React.useState<IFormBuilderState>({
    formState: formDefaultValue,
    validationSchema: {},
    rowColumnConfig: {}
  })
  const timer = config.hideSubmitButtonTimer
  const [disableSubmit, setDisableSubmit] = React.useState(false)
  const timerRef: any = React.useRef(0)
  //#region
  const generateFormConfig = React.useCallback(
    formConfig => {
      let initialState = {}
      let validationSchema = {}

      Object.keys(formConfig).forEach(ele => {
        formConfig[ele] &&
          formConfig[ele].data.forEach((inputForm: IFormInputConfig) => {
            initialState[inputForm['formKey'] as string] = getInputInitialValue(
              inputForm
            )
            validationSchema[inputForm['formKey'] as string] =
              inputForm.type == 'multi-add'
                ? multiAddValidation(inputForm)
                : getValidationValue(inputForm)
          })
      })
      setFormConfig({
        formState: { ...initialState, ...formDefaultValue },
        validationSchema: object().shape(validationSchema),
        rowColumnConfig: formConfig
      })
    },
    [config, formDefaultValue]
  )
  //#endregion

  React.useEffect(() => {
    generateFormConfig(config.formConfig)
    clearInterval(timerRef.current)
    if (timer && isTimeRemaining(timer)) {
      setDisableSubmit(true)
      timerRef.current = setTimeout(() => {
        setDisableSubmit(prop => !prop)
      }, timer)
    } else {
      setDisableSubmit(false)
    }
  }, [generateFormConfig])

  function disableSubmitForSomeTime() {
    localStorage.setItem('disableTime', String(new Date().getTime()))
    setDisableSubmit(true)
    timerRef.current = setTimeout(() => {
      setDisableSubmit(prop => !prop)
    }, timer)
  }

  return !isEmpty(formConfig.rowColumnConfig) ? (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.formState}
      onSubmit={(values, { resetForm }) => {
        if (timer) {
          disableSubmitForSomeTime()
        }
        setSubmitValues({ values, resetForm })
      }}
      validate={validate}
      validationSchema={formConfig.validationSchema}
      key={formGroupName}
    >
      {(props: FormikProps<any>) => {
        return (
          <Form style={{ width: '100%' }}>
            <CustomForm
              formConfig={formConfig}
              baseUrl={baseUrl}
              config={config}
              formikProps={props}
              isLoading={isLoading}
              formGroupName={formGroupName}
              onFieldChange={onFieldChange}
              onSpecificKeyChange={onSpecificKeyChange}
              buttonComponent={buttonComponent}
              disableSubmit={disableSubmit}
            />
          </Form>
        )
      }}
    </Formik>
  ) : (
    <div />
  )
}
