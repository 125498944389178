import { Flex, Input } from '@fareye/ui'
import { ErrorMessage, useField } from 'formik'
import React from 'react'
import { Column, ErrorSpan } from '../styled/styled'
import CustomErrorMessage from './CustomErrorMessage'
import CustomLabel from './Label'

function CustomInput(props: any) {
  const { label, placeholder, styling, desc } = props.config
  const { name } = props
  const [formValue, meta] = useField(name)
  const {
    label: labelStyle = {},
    container = {},
    inputType = {},
    inputContainer = {}
  } = styling ? (styling.styles ? styling.styles : {}) : {}
  return (
    <Column {...(styling ? styling.variant : {})} style={container} column>
      <CustomLabel label={label} desc={desc} style={labelStyle} />
      <Flex width={1} column style={inputContainer}>
        <Input
          styles={inputType}
          placeholder={placeholder}
          {...props}
          checked={formValue.value}
        />
        <ErrorSpan variant="danger">
          <ErrorMessage
            component={(props: any) => (
              <CustomErrorMessage {...props} formKey={name} />
            )}
            name={name}
          />
        </ErrorSpan>
      </Flex>
    </Column>
  )
}
export default React.memo(CustomInput)
