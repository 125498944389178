import React from 'react'
import { Flex } from '../../main'
import { IHead } from '../types'
import SortIcons from './Sorticons'

const THead = (props: IHead) => {
  const {
    headerGroups,
    sorting,
    sortable,
    onSortedChange,
    resizableColumns
  } = props

  return (
    <thead>
      {headerGroups?.map((headerGroup, i) => (
        <tr
          data-testid={`thead-tr-${i}`}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map((column: any, j: number) => {
            const thProps = column.getHeaderProps()
            const style = {
              ...thProps.style,
              maxWidth: column.maxWidth,
              minWidth: column.minWidth,
              width: column.width
            }
            // TO DO (column.canSort || column.sortable) is buggy needs research
            const isColumnSortable = sortable && column.sortable
            return (
              <th data-testid={`th-${i}-${j}`} {...thProps} style={style}>
                <Flex
                  alignItemsCenter
                  justifyContentSpaceBetween
                  style={isColumnSortable ? { cursor: 'pointer' } : {}}
                  onClick={(e: any) => {
                    if (isColumnSortable) {
                      if (onSortedChange) {
                        onSortedChange([
                          { id: column.id, desc: !!column.isSortedDesc }
                        ])
                      } else if (
                        column.getHeaderProps(column.getSortByToggleProps())
                          .onClick
                      ) {
                        column
                          .getHeaderProps(column.getSortByToggleProps())
                          .onClick(e)
                      }
                    }
                  }}
                >
                  {column.render('Header')}
                  {isColumnSortable ? (
                    <SortIcons sorting={sorting} column={column} size={12} />
                  ) : null}
                </Flex>
                {resizableColumns ? (
                  <div
                    data-testid={`resizer-${i}-${j}`}
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? 'isResizing' : ''
                    }`}
                  />
                ) : null}
              </th>
            )
          })}
        </tr>
      ))}
    </thead>
  )
}
export default THead
