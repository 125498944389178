import React from 'react'
import { RouteComponentProps, matchPath } from 'react-router'
import { IRouteConfig } from '@fareye/types'
import { Breadcrumbs, BreadcrumbItem } from '../../style/styled'
import { FaCaretRight, isEmpty } from '@fareye/utils'
import { Span } from '@fareye/ui'

interface IBreadcrumb extends RouteComponentProps {
  routesConfig?: Partial<IRouteConfig>[]
}

const getPaths = (pathname: string) => {
  const paths = ['/']
  if (pathname === '/') return paths
  pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`
    paths.push(currPath)
    return currPath
  })
  return paths
}

export function Breadcrumb({ routesConfig, location, match }: IBreadcrumb) {
  const paths = getPaths(location.pathname)
  const query = location.search
  const breadCrumbPaths: any = []
  let pathIndex = 1
  const { id, plant, transporter }: any = match && match.params

  function generateBreadCrumbItem(
    ele: IRouteConfig,
    depth: number,
    pathname: string
  ) {
    const matchData = matchPath(pathname, {
      path: ele.path,
      exact: true,
      strict: true
    })
    if (matchData) {
      breadCrumbPaths.push({
        path: `${paths[pathIndex]}${query}`,
        bLabel: ele.path.includes(':')
          ? plant
            ? plant
            : transporter
            ? transporter
            : id
          : ele.bLabel,
        exact: true
      })
      pathIndex++
      if (depth < paths.length) {
        ele.children &&
          ele.children.forEach(ele => {
            generateBreadCrumbItem(ele, depth, paths[pathIndex])
          })
      }
    }
  }
  if (paths.length > 1) {
    routesConfig &&
      routesConfig.forEach((ele: any) => {
        generateBreadCrumbItem(ele, pathIndex, paths[pathIndex])
      })
  }

  const title = routesConfig && routesConfig[0] ? routesConfig[0].label : ''

  return (
    <Breadcrumbs alignItemsCenter>
      {isEmpty(breadCrumbPaths) && title ? (
        <BreadcrumbItem
          to={(routesConfig && routesConfig[0]?.path) || '/'}
          variant={`s link`}
          disabled={true}
          title={title}
        >
          <Span variant={'secondary'}>{title}</Span>
        </BreadcrumbItem>
      ) : (
        <>
          {breadCrumbPaths.map((ele: any, index: any) => {
            const isLastItem = breadCrumbPaths.length - 1 === index
            return (
              <React.Fragment key={`link${index}`}>
                <BreadcrumbItem
                  to={ele.path}
                  variant={`s link`}
                  disabled={isLastItem}
                  title={decodeURIComponent(ele.bLabel)}
                >
                  <Span variant={isLastItem ? 'secondary' : 'primary'}>
                    {decodeURIComponent(ele.bLabel)}
                  </Span>
                </BreadcrumbItem>
                {!isLastItem ? <FaCaretRight style={{ width: '9px' }} /> : null}
              </React.Fragment>
            )
          })}
        </>
      )}
    </Breadcrumbs>
  )
}
