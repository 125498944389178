let StatusCode: { [key: number]: string } = {
  200: 'OK',
  201: 'Created',
  204: 'No Content',
  400: 'Bad Request',
  304: 'Not Modified',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  408: 'Request Timeout',
  412: 'Precondition Failed',
  415: 'Unsupported Media Type',
  422: 'Invalid payload requested.',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout'
}

export function responseParser(payload: any) {
  let data
  let message
  let error = false
  if (payload) {
    switch (payload.status) {
      case 200:
        data = payload.data
        break
      case 201:
        message = payload.message
        break
      case 304:
        data = payload.data
        break
      default:
        error = true
        message =
          payload &&
          payload.data &&
          payload.data.errors &&
          payload.data.errors[0] &&
          payload.data.errors[0].message
        break
    }
    message = message || StatusCode[payload.status]
  } else {
    error = true
  }
  return { data, error, message }
}

interface IVariable {
  [key: string]: string | number | IVariable
}

export const responseVariableSwapper = (
  response: string,
  variables: IVariable
) => {
  const startSymbol = '{{'
  const endingSymbol = '}}'
  const start: any = response.match(new RegExp(startSymbol))
  const end: any = response.match(new RegExp(endingSymbol))
  let filtered = response
  if (start && end) {
    var temp: string = filtered.slice(start.index + 2, end.index)
    filtered = filtered.replace(startSymbol, '')
    filtered = filtered.replace(endingSymbol, '')
    filtered = filtered.replace(temp, `${variables[temp]}`)
  }
  return filtered
}

export const idInUrlIntegrator = (url: string, id: number): string => {
  const lastPosition = url.lastIndexOf('.')
  return `${url.substring(0, lastPosition)}/${id}${url.substring(lastPosition)}`
}
