import { MapView, BaseLayer } from '@fareye/ui'
import { flattenDeep, isEmpty } from '@fareye/utils'
import useLocation from './../../hooks/UseLocation'
import { ILocationPayload } from '../../utility/types'
import L, { LatLngBounds } from 'leaflet'
import React from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster'

export interface MapContainerProps {
  coords?: {
    id: number
    latitude: number
    longitude: number
    poiBounds?: string
  }[]
  style?: React.CSSProperties
  config: any
  mapKey: string
  markers: any
  clustering?: boolean
  defaultBaseLayer?: BaseLayer
  children: React.ReactNode
}

export function MapContainer({
  coords,
  mapKey,
  markers,
  clustering = true,
  defaultBaseLayer,
  children,
  style = {}
}: MapContainerProps) {
  const [center, setCenter] = React.useState(L.latLng(0.0, 0.0))
  const [zoom, setZoom] = React.useState(5)
  const [bounds, setBounds] = React.useState<LatLngBounds>()
  const [boundsCalculated, setBoundsCalculated] = React.useState(false)

  React.useEffect(() => {
    coords = coords?.filter(coord => coord.latitude && coord.longitude)

    if (!isEmpty(coords) && !isEmpty(markers)) {
      const newBounds = L.latLngBounds(
        flattenDeep(
          markers.map(({ location }: any) =>
            L.latLng(Number(location.latitude), Number(location.longitude))
          )
        )
      )
      setBounds(newBounds)
    } else {
      if (coords && !isEmpty(coords)) {
        const p = coords.map(coord =>
          coord.poiBounds
            ? JSON.parse(coord.poiBounds).geometry.coordinates
            : [coord.latitude, coord.longitude]
        )
        const newBounds = L.latLngBounds(
          flattenDeep(
            p.map((item: any) => item.map((bound: any) => L.latLng(bound[0])))
          )
        )
        setBounds(newBounds)
      }
    }
    setBoundsCalculated(true)
  }, [])

  React.useEffect(() => {
    if (boundsCalculated) {
      if (!bounds) {
        useLocation(({ data, success }: ILocationPayload) => {
          if (success) {
            setCenter(L.latLng(data.coords.latitude, data.coords.longitude))
          }
        })
      } else {
        setCenter(bounds.getCenter())
        setZoom(13)
      }
    }
  }, [bounds, boundsCalculated])

  const leafletMapConfig = {
    center,
    zoom: !bounds ? zoom : undefined,
    bounds,
    maxZoom: 18
  }
  Object.keys(leafletMapConfig).forEach(
    key => leafletMapConfig[key] === undefined && delete leafletMapConfig[key]
  )

  return (
    <MapView
      leafletMapConfig={{ ...leafletMapConfig }}
      mapKey={mapKey}
      style={style}
      defaultBaseLayer={defaultBaseLayer}
    >
      <>
        {clustering ? (
          <MarkerClusterGroup>{children}</MarkerClusterGroup>
        ) : (
          <>{children}</>
        )}
      </>
    </MapView>
  )
}
