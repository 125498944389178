import { AllIssueListing } from '@fareye/issue-resolution'
import { Table, FullHeightWidthTranslucentLoader } from '@fareye/ui'
import {
  get,
  dMmm,
  getXlsx,
  isEmpty,
  cancelToken,
  currenthhmmA,
  downloadToCSV,
  getQueryParams,
  responseParser
} from '@fareye/utils'
import queryString from 'query-string'
import React from 'react'
import { columnMapping } from '../../config/constants'
import { ITripTableProps } from '../../utility/types'
import { getTableColumns } from './Column'

const initTableData = {
  data: [],
  isLoading: true,
  isError: false,
  isSuccess: false,
  message: ''
}

export const TripTable = React.memo((props: ITripTableProps) => {
  let {
    data,
    size = 'm',
    match,
    config,
    mapping,
    endDate,
    fileName,
    location,
    startDate,
    searchData,
    selectedKey,
    issueResourceId = 'ConsignerTrip',
    searchQuery,
    setLastUpdate,
    selectedColumn,
    setSummaryFilter,
    refreshableTable = true
  } = props
  const { style, className, variant } = config.styling
  const {
    links,
    columns,
    apiConfig,
    columnsList,
    downloadApiConfig,
    issueRaisedConfig
  } = config.config
  const subComponentConfig = config.children

  const [refresh, setRefresh] = React.useState<boolean>(false)
  const [showCommentModule, setShowCommentModule] = React.useState(false)
  const [totalElements, setTotalElements] = React.useState(data?.length || 0)
  const [pageIndex, setPageIndex] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(10)
  const [tableData, setTableData] = React.useState<any>({
    ...initTableData,
    data: data || [],
    isLoading: !data?.length
  })
  const [sorting, setSortBy] = React.useState({
    sortBy: '',
    sortByDirection: true
  })

  const sourceFetchTable = cancelToken().source()
  const sourceDownload = cancelToken().source()

  const query = queryString.parse(location.search)
  const { id, transporter }: any = match?.params
  const idParam = id ? id.split('-') : []
  const routerParams = id?.includes('-')
    ? { origin: idParam[0], destination: idParam[1] }
    : transporter
    ? { transporter: transporter }
    : {}

  const { sortBy, sortByDirection } = sorting
  const searchParams = {
    ...query,
    startDate,
    endDate,
    ...routerParams,
    pageNo: pageIndex + (pageIndex === 0 ? 1 : 2),
    // above logic is if pageIndex is 0 call current page data otherwise next page data
    plant: props.plant ? props.plant : '',
    recordsPerPage: pageSize * (pageIndex === 0 ? 2 : 1),
    // above logic is if pageIndex is 0 call next page data too by doubling pageSize
    sortBy: columnMapping[sortBy],
    sortByDirection: sortBy ? (sortByDirection ? 'desc' : 'asc') : '',
    ...searchQuery,
    ...apiConfig?.queryParams
  }
  const queryParam = getQueryParams(searchParams)

  const tableLinks: any = { ...links }
  const column: string[] = selectedKey === 'tabular' ? columnsList : columns

  const selectedColumns = !isEmpty(selectedColumn)
    ? selectedColumn
    : getTableColumns(column, {
        links: tableLinks,
        queryParams: {
          tripShare: props.plant ? `?plant=${props.plant}` : undefined
        }
      })

  let masterData = tableData.data

  React.useEffect(() => {
    setTableData({ ...initTableData, data: data, isLoading: false })
    setPageIndex(0)
    setTotalElements(data?.length || 0)
  }, [data])

  React.useEffect(() => {
    // Below logic is if pageIndex is 0 call current page data otherwise next page data
    // const dontHaveNextPageData =
    //   pageIndex + (pageIndex === 0 ? 1 : 2) > tableData.data.length / pageSize
    // const haveFetchedWholeData =
    //   pageIndex + 1 * pageSize > totalElements && totalElements !== 0
    const skipApiCall =
      (searchParams.pageNo - 1) * searchParams.recordsPerPage > totalElements
    if (!skipApiCall || refresh) {
      apiConfig?.apiURL && fetchData()
    }
    return () => {
      sourceFetchTable.cancel('Unsubscribe Before Unmount')
    }
  }, [pageIndex, pageSize, startDate, endDate, searchQuery])

  React.useEffect(() => {
    setPageIndex(0)
    return () => {
      sourceFetchTable.cancel('Unsubscribe Before Unmount')
    }
  }, [startDate, endDate, searchQuery])

  async function fetchData() {
    try {
      let tempTableData = tableData.data
      if (pageIndex === 0) {
        tempTableData = []
      }
      if (!refresh) {
        setTableData({
          ...tableData,
          data: tempTableData,
          isLoading: true,
          isSuccess: false,
          isError: false
        })
        setSummaryFilter?.([])
      }
      const res = await get(
        `${apiConfig?.apiURL}?${queryParam}`,
        sourceFetchTable.token
      )
      const {
        data: { content, meta, content_summary }
      } = res
      let data: any[] = [...tempTableData]
      if (refresh) {
        data = [...data.slice(0, pageIndex * pageSize), ...content]
      } else {
        data = [...data, ...content]
      }
      setSummaryFilter?.(content_summary ? content_summary : [])
      setTableData({
        ...tableData,
        isError: false,
        data,
        isSuccess: true,
        isLoading: false,
        message: isEmpty(data) ? apiConfig?.apiDataState?.noDataMessage : ''
      })
      setLastUpdate?.(currenthhmmA())
      setRefresh(false)
      setTotalElements(meta?.count ?? meta?.totalElements ?? data.length)
    } catch (err) {
      const { message, error } = responseParser(err.response)
      setTableData({
        ...tableData,
        isSuccess: false,
        isError: true,
        isLoading: false,
        message
      })
      console.error('App Error:', error)
    }
  }

  async function downloadCSV() {
    delete searchParams.pageNo
    delete searchParams.recordsPerPage
    const queryParam = getQueryParams({
      ...searchParams,
      ...downloadApiConfig.queryParams,
      plant: props.plant ?? ''
    })
    const { data } = await getXlsx(
      `${downloadApiConfig?.apiURL}?${queryParam}`,
      sourceDownload.token
    )
    downloadToCSV({
      data,
      fileName: fileName || `${dMmm()}`,
      type: 'text/zip',
      extension: 'zip'
    })
  }
  function onPageSizeChange(pageSize: number) {
    apiConfig?.apiURL && setTotalElements(0)
    apiConfig?.apiURL && setTableData({ ...tableData, data: [] })
    setPageSize(pageSize)
  }

  const expanded = {}

  new Array(pageSize).fill('').map((item: any, i: number) => {
    expanded[i] = true
  })

  if (searchData) {
    masterData = masterData.filter((obj: any) =>
      Object.values(obj).some((val: any) => {
        return typeof val === 'string'
          ? val.toLowerCase().includes(searchData.toLowerCase())
          : false
      })
    )
  }

  return (
    <>
      <div
        style={{
          position: 'relative',
          width: '100%',
          ...style
        }}
      >
        <Table
          className={`responsive ${
            selectedKey === 'tabular' ? 'striped' : ''
          } ${className}`}
          size={size}
          data={masterData}
          dataLoading={tableData.isLoading}
          defaultPageIndex={pageIndex}
          refreshing={tableData.isLoading}
          downloadCSV={downloadCSV}
          commentingModule={() => setShowCommentModule(true)}
          columns={selectedColumns}
          totalElements={totalElements}
          defaultPageSize={pageSize}
          // expanded={selectedKey !== 'tabular' ? expanded : false}
          onPageChange={(pI: number) => setPageIndex(pI)}
          onPageSizeChange={(pageSize: number) => {
            setPageIndex(0)
            onPageSizeChange(pageSize)
          }}
          getTrProps={({ original }: any) => ({})}
          {...(selectedKey !== 'tabular'
            ? {
                subComponent: ({ row }: any) => {
                  if (selectedKey !== 'tabular' && subComponentConfig) {
                    Object.keys(subComponentConfig).map(
                      (subComp: any, i: number) => {
                        const Comp: any =
                          mapping?.[subComponentConfig[subComp].component]
                        return (
                          <Comp key={`sub-component-${i}`} timelineData={row} />
                        )
                      }
                    )
                  }
                }
              }
            : {})}
          onSortedChange={(ev: any) => {
            setSortBy({
              sortBy: ev[0].id,
              sortByDirection:
                ev[0].id === sorting.sortBy
                  ? !sorting.sortByDirection
                  : ev[0].desc
            })
          }}
        />
        {refresh ? <FullHeightWidthTranslucentLoader /> : null}
      </div>
      {showCommentModule && (
        <AllIssueListing
          showDrawer={showCommentModule}
          setShowDrawer={setShowCommentModule}
          resourceId={''}
          config={issueRaisedConfig}
          resourceType={issueResourceId}
          baseUrl={''}
          updateIssueCount={() => {}}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  )
})
