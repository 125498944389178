import React from 'react'
import { Flex } from '@fareye/ui'
import { Description, SubDescription } from '../styled/styled'
import { IFormConfig } from '../utility/types'

export default function Header({ label, desc, styles }: Partial<IFormConfig>) {
  return (
    <Flex style={styles ? styles.container : {}} column>
      <Description variant="h6" style={styles ? styles.label : {}}>
        {label}
      </Description>
      <SubDescription variant="secondary" style={styles ? styles.desc : {}}>
        {desc}
      </SubDescription>
    </Flex>
  )
}
