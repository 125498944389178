import { IFormConfig } from '@fareye/form-builder'
import { IRenderControl } from '@fareye/ui'
import { IStyleConfig, IKeyValue, AtLeastOne } from '@fareye/types'

enum ValidPages {
  reportSettings = 'reportSettings'
}
enum ValidSubPages {
  reportConfig = 'reportConfig'
}

export interface IPageConfig {
  config: IReportConfigOptions
  styling: IStyleConfig<IKeyValue<any>>
  children: { [key in ValidSubPages]: AtLeastOne<IPageConfig> } | {}
  baseUrl: string
}

interface IReportConfigOptions {
  formRendering: IKeyValue<IFormConfig>
  reportConfig: IKeyValue<IReportDataConfig>
  defaultReportType: string
  showTimezone?: boolean
}

export interface IReportDataConfig {
  label: string
  apiUrl: string
  streamUrl: string
  streamUrlRequestType?: 'GET' | 'POST'
  selectedColumns: Array<IColumnConfig>
  apiUrlRequestType?: 'GET' | 'POST'
  queryParams?: IKeyValue<any>
}
export interface IApiFilter {
  apiURL: string
  queryParams?: IKeyValue<string>
  modifyResponse?: (content: any) => any
}
export type TReportConfig = {
  [key in ValidPages]: AtLeastOne<IPageConfig>
}

type TConstant =
  | 'updateStatusUrlPathName'
  | 'subscriptionListingHeader'
  | 'apiFailErrorMessage'

type TReportConstant = {
  [key in TConstant]: string
}

export interface IColumn {
  label: string
  key: string
  formatter?: (val: any) => string
}
export interface IColumnConfig extends Pick<IColumn, 'label' | 'key'> {
  formatter?: string
  link?: string
}

export type TReportPageRenderingConfig<T> = {
  [key in EReportName]: T
}

export interface IReportPageRenderingConfig {
  formRendering: Partial<IFormConfig>
  reportConfig: Partial<IReportApiAndLabelConfig>
}

export interface IReportApiAndLabelConfig {
  label: string
  apiUrl: string
  streamUrl: string
  selectedColumns: Array<string>
}

enum EReportName {
  FAMD_TRIP_REPORT = 'famdTripReport',
  DAILY_TRIP_REPORT = 'dailyTripReport',
  DAY_BASED_REPORT = 'dayBasedReport',
  STATE_BASED_REPORT = 'stateBasedReport',
  WAYPOINT_REPORT = 'waypointReport',
  OVERSPEED_INSTANCES_REPORT = 'overspeedInstancesReport',
  POI_HISTORY_REPORT = 'poiHistoryReport',
  TRIP_REPORT_SIMPLE = 'tripReportSimple',
  TRIP_SUMMARY_REPORT = 'tripSummaryReport',
  DAY_SUMMARY_REPORT = 'daySummaryReport', //Shubham
  TRIP_BASED_REPORT = 'tripBasedReport',
  UTILIZATION_REPORT = 'utilizationReport',
  MONTHLY_ANALYSIS_REPORT = 'monthlyAnalysisReport',
  VEHICLE_PERFORMANCE_REPORT = 'vehiclePerformanceReport',
  OVERSPEED_REPORT = 'overspeedReport', //MilkMantra
  DAILY_DASHBOARD_REPORT = 'dailyDashboardReport',
  MONTHLY_REPORT = 'monthlyReport',
  GPS_REPORT = 'gpsReport',
  ROUTE_WISE_REPORT = 'routeWiseReporT'
}

export interface IReportData extends IRenderControl {
  queryString: string
  meta: any
  postQuery: any
}
export interface ISubscriptionListing {
  subscriptionType: string
  reportData: IReportData
  baseUrl: string
  selectedColumns: any
  selectedReportConfig: IReportDataConfig
  setReportData: (val: IReportData) => void
  pageNo: number
  setPageNo: (val: number) => void
}
