import {
  Button,
  Card,
  Flex,
  Grid,
  Input,
  Label,
  SearchSelect,
  styled
} from '@fareye/ui'
import { FaEye, FaPlusCircle, FaTrash, toTileCase } from '@fareye/utils'
import { ErrorMessage, FieldArray, Form, Formik } from 'formik'
import React from 'react'
import Switch from 'react-switch'
import { array, boolean, mixed, object, string } from 'yup'
import { chartMenu, config } from '../../utility/config'
import {
  Colors,
  CONFIGURE_CHART_DESC,
  CreateKpiStyling,
  CREATE_KPI_TEXT,
  DATA_SOURCE_DESC,
  PAGE_SETTING
} from '../../utility/constant'
import {
  IChartConfig,
  ICreateKpiConfig,
  OptionDataType
} from '../../utility/types'
import Filter from './Filter/Filter'
import SideBarDescription from './Text/Description'
import CustomErrorMessage from './Text/ErrorMessage'
import CustomLabel from './Text/Label'
import SubLabel from './Text/SubLabel'

const BackGroundContainer = styled.div`
  ${props => {
    return {
      backgroundColor: props.color,
      padding: '10px 15px',
      width: '95%',
      border: '1px solid transparent',
      borderRadius: '10px'
    }
  }}
`
interface ICreateKpiProps {
  config: ICreateKpiConfig
}
const CreateKpi = ({
  config: { config: kpiConfig, onSubmit }
}: ICreateKpiProps) => {
  const [showFilter, setShowFilter] = React.useState(false)
  const initialState: IChartConfig = {
    dataSource: null,
    chartTitle: '',
    chartDescription: '',
    chartType: '',
    dimensionDescription: '',
    metricDescription: '',
    dimensions: [
      {
        dimension: null,
        breakdownDimension: null,
        isDrillDown: false,
        drillDownDimensions: []
      }
    ],
    metricList: [{ aggKey: null, aggType: null }],
    unit: null,
    sortKey: null,
    sortType: null,
    maxNoOfBars: '',
    compactNo: false,
    decimalPrecision: '',
    filter: {
      filterData: [
        [
          {
            filterKey: null,
            filterValue: null,
            condition: null,
            filterType: null
          }
        ]
      ],
      name: '',
      dataSource: null
    },
    pageIdList: null
  }
  const [fieldVisibility, setFieldVisibility] = React.useState({
    dataSource: true,
    chartTitle: true,
    chartDescription: true,
    chartType: true,
    dimension: false,
    breakdownDimension: false,
    drillDown: false,
    metricList: false,
    sort: false,
    maxNoOfValues: false,
    compactNo: false,
    decimalPrecision: false,
    showAddMetricButton: false,
    dimensionDescription: false,
    metricDescription: false,
    unit: false
  })
  const [formOptionData, setFormOptionData] = React.useState<any>({
    dataSource: [],
    dimension: [],
    metricKey: [],
    metricType: [],
    sortType: [],
    sortKey: [],
    filterKey: [],
    filterType: [],
    pageIdList: [],
    unit: []
  })
  const validationSchema = object().shape({
    dataSource: mixed().required('* Please Enter Data Source'),
    chartTitle: string().required('* Please Enter Chart Title'),
    chartDescription: string().required('* Please Enter the chart description'),
    chartType: string().required('* Please Enter the chart type'),
    dimensions: array(
      object().shape({
        dimension: mixed(),
        breakdownDimension: mixed(),
        isDrillDown: boolean(),
        drillDownDimensions: mixed()
      })
    ).when('chartType', {
      is: (val: any) =>
        config.chartTypes.BAR === val ||
        config.chartTypes.AREA === val ||
        config.chartTypes.LINE === val,
      then: array().min(1, '* Please Select a dimension'),
      otherwise: array().min(0)
    }),
    breakdownDimension: mixed(),
    metricList: array(
      object().shape({
        aggKey: mixed(),
        aggType: mixed()
      })
    ).min(1, '* Please select a Metric'),
    sortKey: mixed(),
    maxNoOfBars: string(),
    compactNo: mixed(),
    decimalPrecision: string(),
    filters: object().shape({
      name: string(),
      dataSource: string(),
      filterData: array(
        array(
          object().shape({
            filterKey: mixed(),
            filterValue: mixed(),
            condition: mixed(),
            filterType: mixed()
          })
        )
      )
    }),
    pageIdList: array(mixed()).nullable()
  })

  React.useEffect(() => {
    setFormOptionData({
      ...formOptionData,
      dataSource: getOptionsFromKpiConfig('dataSource')
    })
  }, [])
  function getOptionsFromKpiConfig(key: OptionDataType, value?: any) {
    return kpiConfig[key].getOptions(value)
  }
  function getKeyFromKpiConfig(key: OptionDataType, pickId: 'label' | 'value') {
    return kpiConfig[key][pickId]
  }
  function setFormOnDataSourceChange(value: any) {
    const metricKey = getOptionsFromKpiConfig('metricKey', value)
    const metricType = getOptionsFromKpiConfig('metricType', value)
    const dimension = getOptionsFromKpiConfig('dimension', value)
    const sortKey = getOptionsFromKpiConfig('sortKey', value)
    const sortType = getOptionsFromKpiConfig('sortType', value)
    const filterKey = getOptionsFromKpiConfig('filterKey', value)
    const filterType = getOptionsFromKpiConfig('filterType', value)
    const pageIdList = getOptionsFromKpiConfig('pageIdList', value)
    setFormOptionData({
      ...formOptionData,
      metricKey,
      metricType,
      dimension,
      sortKey,
      sortType,
      filterKey,
      filterType,
      pageIdList
    })
  }
  const disableOption = (key: string, value: any) => {
    if (key && value) {
      return value.key === key
    }
    return false
  }
  const disableMetricOption = (key: string, values: any[]) => {
    const index = values.findIndex(ele => ele.aggKey && ele.aggKey.key === key)
    if (index > -1) {
      return true
    }
    return false
  }
  const updateFieldVisibilityOnChartType = (chartType: string) => {
    switch (chartType) {
      case config.chartTypes.PIE:
        setFieldVisibility({
          ...fieldVisibility,
          dimension: true,
          metricList: true,
          sort: true,
          maxNoOfValues: true,
          showAddMetricButton: false,
          breakdownDimension: false,
          compactNo: false,
          decimalPrecision: false,
          drillDown: true,
          metricDescription: true,
          dimensionDescription: true,
          unit: true
        })
        break
      case config.chartTypes.FaMoneyCheckAlt:
        setFieldVisibility({
          ...fieldVisibility,
          dimension: false,
          sort: false,
          metricList: true,
          decimalPrecision: true,
          maxNoOfValues: false,
          compactNo: true,
          showAddMetricButton: false,
          breakdownDimension: false,
          drillDown: false,
          metricDescription: false,
          dimensionDescription: false,
          unit: true
        })
        break
      default:
        setFieldVisibility({
          ...fieldVisibility,
          dimension: true,
          sort: true,
          metricList: true,
          showAddMetricButton: true,
          breakdownDimension: true,
          compactNo: false,
          maxNoOfValues: true,
          decimalPrecision: false,
          drillDown: true,
          metricDescription: true,
          dimensionDescription: true,
          unit: true
        })
        break
    }
  }

  function generateDimension(
    values: IChartConfig,
    setFieldValue: (field: string, value: any) => void
  ) {
    return (
      <>
        <FieldArray
          name="dimensions"
          render={(arrayHelper: any) => (
            <>
              {values.dimensions.map((ele, index) => {
                return (
                  <Card key={`dimension${index}`}>
                    {fieldVisibility.dimension && (
                      <div>
                        <SubLabel text={CREATE_KPI_TEXT.dimension} />
                        <SearchSelect
                          options={formOptionData.dimension}
                          value={values.dimensions[index].dimension}
                          onChange={value => {
                            setFieldValue(
                              `dimensions.${index}.dimension`,
                              value
                            )
                          }}
                          getOptionValue={(el: any) =>
                            el[getKeyFromKpiConfig('dimension', 'value')]
                          }
                          placeholder={'Enter Dimension'}
                        ></SearchSelect>
                      </div>
                    )}
                    {fieldVisibility.breakdownDimension && (
                      <div>
                        <SubLabel text={CREATE_KPI_TEXT.bdwn_dimension} />
                        <SearchSelect
                          options={formOptionData.dimension}
                          value={values.dimensions[index].breakdownDimension}
                          onChange={value => {
                            setFieldValue(
                              `dimensions.${index}.breakdownDimension`,
                              value
                            )
                          }}
                          getOptionValue={(el: any) =>
                            el[getKeyFromKpiConfig('dimension', 'value')]
                          }
                          placeholder="Enter Breakdown Dimension"
                        ></SearchSelect>
                      </div>
                    )}

                    {fieldVisibility.drillDown && (
                      <>
                        <Flex
                          style={{
                            width: '40%',
                            marginTop: '10px'
                          }}
                          justifyContentSpaceBetween
                        >
                          <SubLabel
                            text={CREATE_KPI_TEXT.drillDown}
                            customStyle={{
                              display: 'inline-block'
                            }}
                          />
                          <Switch
                            onChange={() => {
                              setFieldValue(
                                `dimensions.${index}.isDrillDown`,
                                !values.dimensions[index].isDrillDown
                              )
                            }}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={25}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            id="material-switch"
                            checked={values.dimensions[index].isDrillDown}
                          ></Switch>
                        </Flex>
                        {values.dimensions[index].isDrillDown && (
                          <div>
                            <SearchSelect
                              options={formOptionData.dimension}
                              value={
                                values.dimensions[index].drillDownDimensions
                              }
                              onChange={value => {
                                setFieldValue(
                                  `dimensions.${index}.drillDownDimensions`,
                                  value
                                )
                              }}
                              getOptionValue={(el: any) =>
                                el[getKeyFromKpiConfig('dimension', 'value')]
                              }
                              isMulti
                              placeholder="Enter Drilldown Dimension"
                            ></SearchSelect>
                          </div>
                        )}
                      </>
                    )}
                    <Flex
                      justifyContentFlexEnd
                      style={{ padding: '10px 15px' }}
                    >
                      <FaTrash onClick={() => arrayHelper.remove(index)} />
                    </Flex>
                  </Card>
                )
              })}
              <Button
                onClick={() => {
                  arrayHelper.push({
                    dimension: null,
                    breakdownDimension: null,
                    isDrillDown: false,
                    drillDownDimensions: null
                  })
                }}
                leftChild={
                  <FaPlusCircle
                    style={{
                      color: Colors.anchorColor,
                      marginRight: '7px'
                    }}
                  />
                }
                variant="dashed"
                type="button"
                style={{ marginTop: '10px' }}
              >
                Add Dimension
              </Button>
            </>
          )}
        />
        <ErrorMessage component={CustomErrorMessage} name="dimensions" />
      </>
    )
  }
  function generateMetric(
    values: IChartConfig,
    setFieldValue: (field: string, value: any) => void
  ) {
    return (
      <>
        <SubLabel text={CREATE_KPI_TEXT.metric} />
        <FieldArray
          name="metricList"
          render={(arrayHelper: any) => (
            <>
              {values.metricList.map((ele, index) => {
                return (
                  <Flex key={`metric${index}`} justifyContentSpaceBetween>
                    <SearchSelect
                      style={{
                        wrapper: { width: '40%' }
                      }}
                      options={formOptionData.metricKey}
                      getOptionValue={(el: any) =>
                        el[getKeyFromKpiConfig('metricKey', 'value')]
                      }
                      value={values.metricList[index].aggKey}
                      onChange={ele => {
                        setFieldValue(`metricList.${index}.aggKey`, ele)
                      }}
                      isOptionDisabled={(ele: any) =>
                        disableMetricOption(ele.key, values.metricList)
                      }
                      placeholder={'select from the List'}
                    ></SearchSelect>
                    <SearchSelect
                      style={{
                        wrapper: { width: '40%' }
                      }}
                      options={formOptionData.metricType}
                      value={values.metricList[index].aggType}
                      onChange={ele => {
                        setFieldValue(`metricList.${index}.aggType`, ele)
                      }}
                      getOptionValue={(el: any) =>
                        el[getKeyFromKpiConfig('metricType', 'value')]
                      }
                      placeholder={'Select Aggregation Types'}
                    ></SearchSelect>
                    <span style={{ padding: '10px 15px' }}>
                      <FaTrash onClick={() => arrayHelper.remove(index)} />
                    </span>
                  </Flex>
                )
              })}

              {fieldVisibility.showAddMetricButton && (
                <Button
                  onClick={() => {
                    arrayHelper.push({})
                  }}
                  style={{ marginTop: '5px' }}
                  leftChild={
                    <FaPlusCircle
                      style={{
                        color: Colors.anchorColor,
                        marginRight: '7px'
                      }}
                    />
                  }
                  type="button"
                  variant="dashed"
                >
                  Add Metric
                </Button>
              )}
            </>
          )}
        />
        <ErrorMessage component={CustomErrorMessage} name="metricList" />
      </>
    )
  }
  return (
    <div
      style={{ backgroundColor: Colors.backgroundColor, minHeight: '100vh' }}
    >
      <Formik
        initialValues={initialState}
        onSubmit={fields => {
          onSubmit(fields)
        }}
        validationSchema={validationSchema}
        render={({
          handleReset,
          handleSubmit,
          handleChange,
          values,
          setFieldValue,
          setFieldTouched,
          isValid,
          errors
        }) => (
          <Form>
            <div style={CreateKpiStyling.mainContainer}>
              <Label variant="h3">Create KPI</Label>
              <hr style={CreateKpiStyling.horizontalLine} />
              <Flex>
                <FaEye style={{ marginRight: '5px' }} />
                <SubLabel
                  customStyle={{ margin: '0px' }}
                  text={CREATE_KPI_TEXT.preview}
                />
              </Flex>
              <Grid gridGap="10px">
                <Grid gridTemplateColumns="1fr 3fr" gridGap="10px">
                  <SideBarDescription
                    label={CREATE_KPI_TEXT.data_source}
                    description={DATA_SOURCE_DESC}
                  />
                  <BackGroundContainer
                    color={Colors.rightContainerBackgroundColor}
                  >
                    <CustomLabel text={CREATE_KPI_TEXT.data_source_setting} />
                    <div style={{ padding: '10px 15px' }}>
                      <SubLabel text={CREATE_KPI_TEXT.select_data_source} />
                      <SearchSelect
                        options={formOptionData.dataSource}
                        getOptionValue={(el: any) =>
                          el[getKeyFromKpiConfig('dataSource', 'value')]
                        }
                        value={values.dataSource}
                        onChange={value => {
                          setFormOnDataSourceChange(value)
                          setFieldValue('dataSource', value)
                        }}
                        placeholder={'Data Source'}
                        name="dataSource"
                        onBlur={() => {
                          setFieldTouched('dataSource', true)
                        }}
                      ></SearchSelect>
                      <ErrorMessage
                        component={CustomErrorMessage}
                        name="dataSource"
                      />
                    </div>
                  </BackGroundContainer>
                </Grid>
                {values.dataSource && (
                  <Grid gridTemplateColumns="1fr 3fr" gridGap="10px">
                    <SideBarDescription
                      label={CREATE_KPI_TEXT.configure_chart}
                      description={CONFIGURE_CHART_DESC}
                    />
                    <BackGroundContainer
                      color={Colors.rightContainerBackgroundColor}
                    >
                      <CustomLabel text={CREATE_KPI_TEXT.chart_settings} />
                      <div style={{ padding: '10px 15px' }}>
                        <>
                          <SubLabel text={CREATE_KPI_TEXT.chart_title} />
                          <Input
                            style={CreateKpiStyling.inputStyle}
                            placeholder="Input the title"
                            onChange={handleChange}
                            onBlur={() => {
                              setFieldTouched('chartTitle', true)
                            }}
                            name="chartTitle"
                          />
                          <ErrorMessage
                            component={CustomErrorMessage}
                            name="chartTitle"
                          />
                        </>
                        <>
                          <SubLabel text={CREATE_KPI_TEXT.chart_desc} />
                          <Input
                            style={CreateKpiStyling.inputStyle}
                            placeholder="Input the description"
                            onChange={handleChange}
                            onBlur={() => {
                              setFieldTouched('chartDescription', true)
                            }}
                            type="textarea"
                            name="chartDescription"
                          />
                          <ErrorMessage
                            component={CustomErrorMessage}
                            name="chartDescription"
                          />
                        </>
                        <Flex>
                          <SubLabel text={CREATE_KPI_TEXT.select_chart_type} />
                          {values.chartType && (
                            <span
                              style={CreateKpiStyling.selectedChartIconLabel}
                            >
                              {' '}
                              >{` ${toTileCase(values.chartType)}`}
                            </span>
                          )}
                          <ErrorMessage
                            component={CustomErrorMessage}
                            name="chartType"
                          />
                        </Flex>
                        <Flex justifyContentSpaceBetween>
                          {chartMenu.map(ele => {
                            const ChartIcon = ele.label
                            return (
                              <span
                                id={ele.value}
                                key={ele.value}
                                onClick={() => {
                                  setFieldValue('chartType', ele.value)
                                  updateFieldVisibilityOnChartType(ele.value)
                                }}
                                style={
                                  ele.value === values.chartType
                                    ? CreateKpiStyling.selectedChartIcon
                                    : {}
                                }
                              >
                                <ChartIcon style={CreateKpiStyling.chartIcon} />
                              </span>
                            )
                          })}
                        </Flex>
                        {values.chartType && (
                          <>
                            {generateDimension(values, setFieldValue)}
                            <hr style={CreateKpiStyling.horizontalLine} />
                          </>
                        )}
                        <div>
                          {fieldVisibility.metricList &&
                            generateMetric(values, setFieldValue)}
                        </div>
                        <hr style={CreateKpiStyling.horizontalLine} />
                        {fieldVisibility.unit && (
                          <div>
                            <SubLabel text={CREATE_KPI_TEXT.unit} />
                            <SearchSelect
                              options={formOptionData.unit}
                              value={values.unit}
                              onChange={value => {
                                setFieldValue('unit', value)
                              }}
                              getOptionValue={(el: any) =>
                                el[getKeyFromKpiConfig('unit', 'value')]
                              }
                              style={{ wrapper: { width: '50%' } }}
                              placeholder="Select Unit"
                            ></SearchSelect>
                          </div>
                        )}
                        {fieldVisibility.metricDescription && (
                          <>
                            <SubLabel text={CREATE_KPI_TEXT.metric_desc} />
                            <Input
                              style={CreateKpiStyling.inputStyle}
                              placeholder="Input the description"
                              onChange={handleChange}
                              onBlur={() => {
                                setFieldTouched('metricDescription', true)
                              }}
                              name="metricDescription"
                            />
                          </>
                        )}
                        {fieldVisibility.dimensionDescription && (
                          <>
                            <SubLabel text={CREATE_KPI_TEXT.dimension_desc} />
                            <Input
                              style={CreateKpiStyling.inputStyle}
                              placeholder="Input the description"
                              onChange={handleChange}
                              onBlur={() => {
                                setFieldTouched('dimensionDescription', true)
                              }}
                              name="dimensionDescription"
                            />
                          </>
                        )}
                        {fieldVisibility.sort && (
                          <div>
                            <SubLabel text={CREATE_KPI_TEXT.sort} />
                            <Flex justifyContentSpaceBetween>
                              <SearchSelect
                                options={formOptionData.sortKey}
                                value={values.sortKey}
                                onChange={value => {
                                  setFieldValue('sort', value)
                                }}
                                getOptionValue={(el: any) =>
                                  el[getKeyFromKpiConfig('sortKey', 'value')]
                                }
                                style={{ wrapper: { width: '50%' } }}
                                placeholder="Select Field"
                              ></SearchSelect>
                              <SearchSelect
                                options={formOptionData.sortType}
                                value={values.sortType}
                                onChange={value => {
                                  setFieldValue('sortType', value)
                                }}
                                getOptionValue={(el: any) =>
                                  el[getKeyFromKpiConfig('sortType', 'value')]
                                }
                                style={{ wrapper: { width: '50%' } }}
                                placeholder="Select Sorting Order"
                              ></SearchSelect>
                            </Flex>
                          </div>
                        )}
                        {fieldVisibility.maxNoOfValues && (
                          <div>
                            <SubLabel
                              text={CREATE_KPI_TEXT.maximumNoOfPoints}
                            />
                            <Input
                              style={CreateKpiStyling.inputStyle}
                              placeholder="Input the MaxNo of points"
                              onChange={handleChange}
                              type="number"
                              name="maxNoOfBars"
                            />
                          </div>
                        )}
                        {fieldVisibility.compactNo && (
                          <div>
                            <SubLabel text={CREATE_KPI_TEXT.compact_number} />
                            <Input
                              onChange={handleChange}
                              type="checkbox"
                              name="compactNo"
                              checked={values.compactNo}
                            />
                          </div>
                        )}
                        {fieldVisibility.decimalPrecision && (
                          <div>
                            <SubLabel
                              text={CREATE_KPI_TEXT.decimal_precision}
                            />
                            <Input
                              style={CreateKpiStyling.inputStyle}
                              placeholder="Input the Decimal Precision"
                              onChange={handleChange}
                              name="decimalPrecision"
                            />
                          </div>
                        )}
                        <div>
                          <SubLabel text={CREATE_KPI_TEXT.filter} />
                          <Button
                            onClick={() => {
                              setShowFilter(!showFilter)
                            }}
                            leftChild={
                              <FaPlusCircle
                                style={{
                                  color: Colors.anchorColor,
                                  marginRight: '7px'
                                }}
                              />
                            }
                            variant="dashed"
                            type="button"
                          >
                            Add Filter
                          </Button>
                        </div>
                      </div>
                    </BackGroundContainer>
                  </Grid>
                )}
                <Grid gridTemplateColumns="1fr 3fr" gridGap="10px">
                  <SideBarDescription
                    label={CREATE_KPI_TEXT.page_setting}
                    description={PAGE_SETTING}
                  />
                  <BackGroundContainer
                    color={Colors.rightContainerBackgroundColor}
                  >
                    <CustomLabel text={CREATE_KPI_TEXT.select_page} />
                    <div style={{ padding: '10px 15px' }}>
                      <SubLabel text={CREATE_KPI_TEXT.available_list} />
                      <SearchSelect
                        options={formOptionData.pageIdList}
                        value={values.pageIdList}
                        getOptionValue={(el: any) =>
                          el[getKeyFromKpiConfig('pageIdList', 'value')]
                        }
                        getOptionLabel={(el: any) =>
                          el[getKeyFromKpiConfig('pageIdList', 'label')]
                        }
                        onChange={value => {
                          setFormOnDataSourceChange(value)
                          setFieldValue('pageIdList', value)
                        }}
                        isMulti
                        placeholder={'Enter pages to add'}
                        name="pageIdList"
                        onBlur={() => {
                          setFieldTouched('pageIdList', true)
                        }}
                      ></SearchSelect>
                    </div>
                  </BackGroundContainer>
                </Grid>
              </Grid>
              <Grid
                gridTemplateColumns="1fr 3fr"
                gridGap="10px"
                style={{ marginTop: '20px' }}
              >
                <div></div>
                <Flex>
                  <Button
                    disabled={!isValid}
                    variant="xl"
                    onClick={handleSubmit}
                    type="submit"
                    style={{ marginRight: '20px' }}
                  >
                    Save
                  </Button>
                  <Button type="button" onClick={handleReset}>
                    Cancel
                  </Button>
                </Flex>
              </Grid>
            </div>
            {showFilter && (
              <Filter
                formOptionData={formOptionData}
                setFormOptionData={setFormOptionData}
                value={values}
                setFieldValue={setFieldValue}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                handleChange={handleChange}
                getOptionsFromKpiConfig={getOptionsFromKpiConfig}
              />
            )}
          </Form>
        )}
      />
    </div>
  )
}
export default CreateKpi
