export function toTileCase(phrase = ''): string {
  try {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  } catch (ex) {
    return ''
  }
}

export const pascalCase = (s: string = ''): string =>
  s
    .replace(/\w+/g, w => w[0].toUpperCase() + w.slice(1).toLowerCase())
    .replace(/\s/g, '')

// export const capitalize = (str: string): string => str.toUpperCase()
// Exported from lodash capitalize function

interface IRandomString {
  stringMode?: 'uppercase' | 'lowercase' | 'number' | 'mixed'
  suffix?: string
  prefix?: string
}

export const randomString = (
  length: number = 1,
  config: undefined | IRandomString = {}
) => {
  const { stringMode, suffix, prefix } = config
  function randomAlpha() {
    const alphabetIndex = () => Math.round(Math.random() * 100) % 26
    let start = 97
    if (stringMode === 'uppercase') {
      start = 65
    } else if (stringMode === 'mixed') {
      start = Math.round(Math.random()) ? 65 : 97
    }
    return String.fromCharCode(start + alphabetIndex())
  }
  if (stringMode === 'number') {
    const randNumb = Math.round(Math.random() * Math.pow(10, length))
    return `${prefix || ''}${randNumb}${suffix || ''}`
  }
  const randString = Array.from(new Array(length))
    .map(i => randomAlpha())
    .join('')
  return `${prefix || ''}${randString}${suffix || ''}`
}
