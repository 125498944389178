export const isSafari: boolean =
  navigator.userAgent.indexOf('Safari') !== -1 &&
  navigator.userAgent.indexOf('Chrome') === -1

export function validURL(str: string = '') {
  try {
    new URL(str)
  } catch (_) {
    return false
  }
  return true
}
