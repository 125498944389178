import { IKeyValue, IKeyValueObj } from '@fareye/types'
import { Flex, Label } from '@fareye/ui'
import {
  del,
  get,
  getQueryParams,
  idInUrlIntegrator,
  isEmpty,
  post,
  responseParser,
  search
} from '@fareye/utils'
import Axios from 'axios'
import React from 'react'
import { ISearchES } from '../../utility/types'
import ListSearchFIlters from './containers/ListSearchFIlters'
import SearchFavourite from './containers/SearchFavourite'
import AddFav from './components/AddFav'
import { GlobalSearchChildWrapper } from '../../style/styled'
import { headers } from '../../config/constants'
import { FaRegFlag } from 'react-icons/fa'

export const SearchFilterWrapper = ({
  apiFav,
  pageId,
  search: globalSearch,
  apiFilter,
  setSearch,
  startDate,
  endDate,
  children,
  style = {},
  customKeyMapping,
  showTimezone = false
}: ISearchES) => {
  const [showAddFavModal, setShowAddFavModal] = React.useState<boolean>(false)
  const [favName, setFavName] = React.useState<string>('')
  const [favList, setFavList] = React.useState<IKeyValue<string>[] | []>([])
  const [selectedFilter, setSelectedFilter] = React.useState(globalSearch)
  const [flagged, setFlagged] = React.useState(false)

  const closest = (arr: IKeyValue<string>[], searchValue: string) => {
    return arr.sort((a: IKeyValue<string>, b: IKeyValue<string>) => {
      const diff1 = a.value.toLowerCase().includes(searchValue.toLowerCase())
        ? searchValue.length / a.value.length
        : 0
      const diff2 = b.value.toLowerCase().includes(searchValue.toLowerCase())
        ? searchValue.length / b.value.length
        : 0
      return diff1 < diff2 ? 1 : -1
    })
  }
  const s1 = Axios.CancelToken.source()
  const s2 = Axios.CancelToken.source()
  const s3 = Axios.CancelToken.source()

  const getDefaultValue = () => {
    if (sessionStorage.getItem('sar')) {
      sessionStorage.removeItem('sar')
      return true
    }
    return false
  }

  const getValueFromStorage = () => {
    return sessionStorage.getItem('shipmentsAtRiskCount') || ''
  }
  const sarFilter: boolean = getDefaultValue()
  const shipmentsAtRisk: string = getValueFromStorage()

  const shipmentAtRiskFilter = {
    label: 'Shipment at Risk',
    value: 'Shipment at Risk',
    pretty_name: 'risk_status',
    key: 'risk_status'
  }

  React.useEffect(() => {
    if (apiFav) {
      fetchFavourites()
    }
    return () => {
      s1.cancel
      s2.cancel
      s3.cancel
    }
  }, [])

  async function fetchFavourites() {
    try {
      const query = getQueryParams(apiFav.queryParams)
      const { data } = await get(`${apiFav.apiURL}?${query}`, s1.token)
      setFavList(data.configs)
      if (sarFilter) {
        setTimeout(() => showSAR(), 500)
      }
    } catch (ex) {
      console.error('Error Fetching Favourites.')
    }
  }
  async function saveFavorite() {
    setShowAddFavModal(false)
    try {
      const query = {
        record: {
          ...apiFav.queryParams,
          name: favName,
          data: { filters: selectedFilter, flagged }
        }
      }
      const { data } = await post(apiFav.apiURL, query, s2.token)
      if (data) {
        setFlagged(false)
        fetchFavourites()
        setFavName('')
      }
    } catch (ex) {
      console.error('Error Fetching Favourites.')
    }
  }

  async function deleteFavourite(id: number) {
    try {
      if (window.confirm('Are You Sure you want to Delete?')) {
        const { data } = responseParser(
          await del(`${idInUrlIntegrator(apiFav.apiURL, id)}`, s3.token)
        )
        if (data.deleted) {
          fetchFavourites()
        }
      }
    } catch (err) {
      console.error('Error Deleting Favourite.')
    }
  }
  // fav data filters is any because of API response
  function selectFav(item: IKeyValueObj<string | number | any>) {
    item.data.filters && setSelectedFilter(item.data.filters)
    sessionStorage.setItem(
      'globalSearch',
      JSON.stringify({ selectedFilter, pageId })
    )
    setSearch(item.data.filters)
  }

  function showSAR() {
    const item = {}
    item['data'] = {}
    item['data']['filters'] = [shipmentAtRiskFilter]
    selectFav(item)
  }
  return (
    <Flex column style={{ width: '100%', ...style.listWrapper }}>
      <GlobalSearchChildWrapper style={{ width: '100%', ...style.wrapper }}>
        {children}
        <SearchFavourite
          pageId={pageId}
          closest={closest}
          endDate={endDate}
          favList={favList}
          apiFilter={apiFilter}
          selectFav={selectFav}
          setSearch={setSearch}
          startDate={startDate}
          selectedFilter={selectedFilter}
          deleteFavourite={deleteFavourite}
          customKeyMapping={customKeyMapping}
          setSelectedFilter={setSelectedFilter}
          setShowAddFavModal={setShowAddFavModal}
          showTimezone={showTimezone}
        />
        {window.location.pathname === '/in-transit' ? (
          <Flex
            alignItemsCenter
            justifyContentSpaceEvenly
            style={{
              backgroundColor: '#C84031',
              height: '46px',
              width: '21%',
              cursor: 'pointer',
              borderRadius: '4px',
              flexWrap: 'wrap'
            }}
            onClick={() => showSAR()}
          >
            <FaRegFlag style={{ color: '#FFFFFF' }} />
            <Label style={{ color: '#FFFFFF', cursor: 'pointer' }}>
              {shipmentsAtRisk} Shipments at Risk
            </Label>
          </Flex>
        ) : null}
      </GlobalSearchChildWrapper>
      {!isEmpty(selectedFilter) ? (
        <ListSearchFIlters
          setSearch={setSearch}
          pageId={pageId}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ) : null}
      {apiFav && (
        <AddFav
          favName={favName}
          flagged={flagged}
          setFlagged={setFlagged}
          setFavName={setFavName}
          saveFavorite={saveFavorite}
          selectedFilter={selectedFilter}
          showAddFavModal={showAddFavModal}
          setShowAddFavModal={setShowAddFavModal}
        />
      )}
    </Flex>
  )
}
