export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
    borderRadius: 0,
    border: '1px solid transparent'
  },
  highlighter: {
    overflow: 'hidden',
    background: '#fff',
    border: 'none'
  },
  input: {
    margin: 0
  },
  '&singleLine': {
    control: {
      display: 'inline-block',
      width: 130
    },
    highlighter: {
      padding: 1,
      border: '2px inset transparent'
    },
    input: {
      padding: 1,
      border: '2px inset #d9d9d9'
    }
  },
  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      border: '1px solid transparent',
      '&:hover': {
        border: '1px solid #40a9ff'
      }
    },
    highlighter: {
      padding: 4,
      background: '#fff'
    },
    input: {
      fontSize: 12,
      fontFamily: 'sans-serif',
      padding: 8,
      minHeight: 50,
      outline: 0,
      border: 0
    }
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      maxHeight: '200px',
      overflow: 'scroll'
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5'
      }
    }
  }
}
