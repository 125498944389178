import { IKeyValue, ICreateKpiConfig, IChartConfig } from './types'

export const DATA_SOURCE_DESC =
  'Data sources let you configure the fields you can use in your KPIs.'
export const CONFIGURE_CHART_DESC =
  'Configure the chart based on the data source selected'
export const PAGE_SETTING =
  'Select the pages from the list on which this KPI will be shown'

export const CREATE_KPI_TEXT = {
  data_source: 'Data Source',
  preview: 'Preview',
  data_source_setting: 'Data source settings',
  select_data_source: 'Select data source',
  configure_chart: 'Configure chart',
  chart_settings: 'Chart Settings',
  chart_title: 'Chart Title',
  chart_desc: 'Chart Description',
  select_chart_type: 'Select Chart Type',
  dimension: 'Dimension',
  bdwn_dimension: 'Breakdown Dimension',
  metric: 'Metric',
  sort: 'Sort',
  maximumNoOfPoints: 'Maximum No of Points',
  filter: 'Filter',
  page_setting: 'Page Settings',
  select_page: 'Select Pages',
  available_list: 'Select from the available list (multiple select)',
  decimal_precision: 'Decimal Precision',
  compact_number: 'Compact Number',
  drillDown: 'Drill Down ',
  metric_desc: 'Metric Description',
  dimension_desc: 'Dimension Description',
  create_new_chart: 'Create New Chart',
  unit: 'Unit'
}
export const GRAPH_RENDERER_TEXT = {
  defaultChartTitle: 'Performance Analytics',
  defaultChartDescription: 'Showing Performance Analytics for this graph'
}

export const DataSource: any = [
  {
    label: 'Consigner Data',
    value: 'consigner_data'
  }
]
export const ConditionJson = [
  {
    key: 'eq',
    value: 'equals'
  },
  {
    key: 'gt',
    value: '>'
  },
  {
    key: 'gte',
    value: '>='
  },
  {
    key: 'lt',
    value: '<'
  },
  {
    key: 'lte',
    value: '<='
  }
]
export const Process_Attribute: any = [
  { label: 'Plant', value: 'plant' },
  { label: 'Transporter', value: 'transporter' },
  { label: 'Vehicle', value: 'vehicle' },
  { label: 'Route', value: 'route' },
  { label: 'Material', value: 'material' }
]

export const AggTypeList = [
  { value: 'sum', label: 'Sum' },
  { value: 'avg', label: 'Average' },
  { value: 'count', label: 'Count' }
]
export const SortingOrder = [
  { value: 'asc', label: 'Ascending' },
  { value: 'desc', label: 'Descending' }
]

export const FilterData = [
  {
    label: 'Includes',
    value: 'includes'
  },
  {
    label: 'Excludes',
    value: 'excludes'
  }
]
export const ValueJson = [
  {
    key: 'abc',
    value: 'abc'
  },
  {
    key: 'xyz',
    value: 'xyz'
  },
  {
    key: 'qwe',
    value: 'qwe'
  },
  {
    key: 'tre',
    value: 'tre'
  }
]

export const graphData = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
]

export const Colors = {
  darkLabelColor: '#333333',
  inputLabelColor: '#666666',
  inputSubLabelColor: '#9fa1a2',
  backgroundColor: '#f4f6f8',
  rightContainerBackgroundColor: '#ffffff',
  anchorColor: '#038AF1',
  orButtonColor: '#9b9b9b',
  orButtonEnableColor: '#4285f4',
  errorColor: 'red',
  kpiBackgroundColor: '#ffffff',
  chartLabelColor: '#212121',
  dropDownBackGroundColor: '#F5F5F5',
  subTextLabelColor: '#727272',
  infoIconColor: '#BBBBBB',
  dimensionBackgroundColor: '#EFEFEF'
}
export const CreateKpiStyling: IKeyValue<React.CSSProperties> = {
  mainContainer: {
    color: Colors.darkLabelColor,
    width: '70%',
    margin: 'auto',
    fontFamily: 'sans-serif'
  },
  horizontalLine: {
    border: `.5px solid ${Colors.inputSubLabelColor}`,
    opacity: 0.2
  },
  inputStyle: {
    width: '70%',
    height: '20px',
    marginBottom: '5px'
  },
  chartIcon: { height: '30px', width: '30px', padding: '10px 15px' },
  selectedChartIcon: {
    border: '1px solid #038AF1'
  },
  selectedChartIconLabel: {
    marginTop: '7px',
    fontSize: '14px',
    color: '#9fa1a2'
  }
}

export const kpiConfig: ICreateKpiConfig = {
  config: {
    condition: {
      value: 'key',
      getOptions: getCondition,
      label: 'value'
    },
    dataSource: {
      label: 'label',
      getOptions: getDataSource,
      value: 'value'
    },
    dimension: {
      value: 'value',
      getOptions: getDimension,
      label: 'label'
    },
    filterType: {
      value: '',
      getOptions: getCondition,
      label: ''
    },
    filterKey: {
      value: '',
      getOptions: getCondition,
      label: ''
    },
    filterValue: {
      label: '',
      getOptions: getCondition,
      value: ''
    },
    metricKey: {
      value: '',
      getOptions: getMetric,
      label: ''
    },
    metricType: {
      value: 'value',
      getOptions: getMetricType,
      label: 'label'
    },
    sortKey: {
      value: '',
      getOptions: getCondition,
      label: ''
    },
    sortType: {
      value: '',
      getOptions: getCondition,
      label: ''
    },
    pageIdList: {
      value: 'key',
      getOptions: getCondition,
      label: 'value'
    },
    unit: {
      value: 'key',
      getOptions: getCondition,
      label: 'value'
    }
  },
  onSubmit: value => {}
}
export const graphRenderConfig = {
  graphConfig: {
    dataSource: {
      label: 'Consigner Data',
      value: 'consigner_data'
    },
    chartTitle: 'Loading Performance',
    chartDescription: 'Chart Listing Demo',
    chartType: 'bar',
    dimensionDescription: 'Temp',
    metricDescription: 'Temp',
    dimensions: [
      {
        dimension: {
          label: 'Plant',
          value: 'plant'
        },
        breakdownDimension: {
          label: 'Transporter',
          value: 'transporter'
        },
        isDrillDown: false,
        drillDownDimensions: null
      },
      {
        dimension: {
          label: 'Transporter',
          value: 'transporter'
        },
        breakdownDimension: null,
        isDrillDown: true,
        drillDownDimensions: [
          {
            label: 'Vehicle',
            value: 'vehicle'
          }
        ]
      }
    ],
    metricList: [
      {
        aggKey: {
          label: 'Loading Performance',
          value: 'loadingPerformance'
        },
        aggType: {
          value: 'count',
          label: 'Count'
        }
      }
    ],
    sortKey: null,
    sortType: null,
    maxNoOfBars: '',
    compactNo: false,
    decimalPrecision: '',
    filter: {
      filterData: [
        [
          {
            filterKey: null,
            filterValue: null,
            condition: null,
            filterType: null
          }
        ]
      ],
      name: '',
      dataSource: null
    },
    pageIdList: null
  },
  dataConfig: {
    getDataForChart: (value: any) => [],
    graphDataMapping: {
      condition: {
        value: 'value',

        label: 'label'
      },
      dataSource: {
        label: 'label',

        value: 'value'
      },
      dimension: {
        value: 'value',

        label: 'label'
      },
      filterType: {
        value: 'value',

        label: 'label'
      },
      filterKey: {
        value: 'value',

        label: 'label'
      },
      filterValue: {
        label: 'label',

        value: 'value'
      },
      metricKey: {
        value: 'value',

        label: 'label'
      },
      metricType: {
        value: 'value',

        label: 'label'
      },
      sortKey: {
        value: 'value',

        label: 'label'
      },
      sortType: {
        value: 'value',

        label: 'label'
      },
      pageIdList: {
        value: 'key',

        label: 'value'
      },
      unit: {
        value: 'key',

        label: 'value'
      }
    }
  }
}
function getCondition(value: any) {
  if (value.key === 'customer_name') {
    return []
  }

  return ConditionJson
}
function getDataSource() {
  return DataSource
}
function getDimension() {
  return Process_Attribute
}

function getMetricType() {
  return AggTypeList
}
function getMetric() {
  return [
    { label: 'Loading Performance', value: 'loadingPerformance' },
    { label: 'Unloading Performance', value: 'unloadingPerformance' },
    { label: 'In Transit', value: 'inTransit' },
    { label: 'Trip Count', value: 'tripCount' }
  ]
}

export const DailyDispatch = {
  id: 309,
  user_id: null,
  name: 'Daily Dispatch',
  data: {
    graphConfig: {
      dataSource: {
        label: 'Shipment',
        value: 'Shipment'
      },
      chartTitle: 'Daily Dispatch',
      chartDescription: 'Showing day wise dispatch across plants',
      chartType: 'line',
      dimensionDescription: 'Vehicle Count ',
      metricDescription: 'Date ',
      dimensions: [
        {
          dimension: {
            label: 'Day-Plant Wise',
            value: 'day'
          },
          breakdownDimension: {
            label: 'Plant',
            value: 'pol_name'
          },
          dimensionUnit: {
            key: 'date',
            value: 'date'
          }
        },
        {
          dimension: {
            label: 'Plant Month Wise',
            value: 'month'
          },
          breakdownDimension: {
            label: 'Plant',
            value: 'pol_name'
          },
          dimensionUnit: {
            key: 'date',
            value: 'date'
          }
        }
      ],
      metricList: [
        {
          aggKey: {
            label: 'Transit Performance',
            value: 'value_count'
          },
          aggType: {
            value: 'avg',
            label: 'Count'
          },
          metricUnit: {
            key: 'number',
            value: 'number'
          }
        }
      ],
      sortKey: {
        label: 'Loading Performance',
        value: 'value_count'
      },
      maxNoOfBars: 30,
      compactNo: false,
      decimalPrecision: '',
      filter: {
        filterData: [[]],
        name: '',
        dataSource: null
      },
      pageIdList: null,
      hideSort: true
    },
    style: {
      graphScreenRatio: 2
    },
    order: 7
  },
  deleted_at: null,
  created_at: '2020-02-12T08:29:49.000Z',
  updated_at: '2020-07-14T18:10:18.000Z',
  sub_type: 'kpi',
  page_id: null
}
