// changes { key: "a.b" } => { key: { a : { b : "" } } }
export function flattenObjectToChainedKeysString(
  obj: {
    [key: string]: any
  },
  pattern: string,
  flattenBy = '.'
) {
  var result: { [key: string]: any } = {}
  for (var i in obj) {
    if (typeof obj[i] == 'object')
      Object.assign(
        result,
        flattenObjectToChainedKeysString(obj[i], pattern + flattenBy + i)
      )
    else result[(pattern + flattenBy + i).replace(flattenBy, '')] = obj[i]
  }
  return result
}

// changes { key: { a : { b : "" } } } => { key: "a.b" }
export function unflattenChainedKeysStringToObject(
  data: {
    [key: string]: any
  },
  flattenBy = '.'
): { [key: string]: any } {
  var result = {}
  for (var i in data) {
    var keys = i.split(flattenBy)
    keys.reduce(function(r: any, e, j) {
      return (
        r[e] ||
        (r[e] = isNaN(Number(keys[j + 1]))
          ? keys.length - 1 == j
            ? data[i]
            : {}
          : [])
      )
    }, result)
  }
  return result
}

export function flattenObj(obj: object): object {
  let result: any = {}
  Object.entries(obj).forEach(([key, val]: any) => {
    if (typeof val === 'object' && val !== null) {
      result = { ...result, ...val }
      delete result[key]
    } else {
      result[key] = val
    }
  })
  return result
}
