import { IDateRange } from '@fareye/types'
import { DatePicker, Flex, TDateVariant } from '@fareye/ui'
import { ErrorMessage } from 'formik'
import React from 'react'
import { Column, ErrorSpan } from '../styled/styled'
import CustomErrorMessage from './CustomErrorMessage'
import CustomLabel from './Label'
import { ICustomFormField, IComponentFormField } from '../utility/types'
export default function DateRange(props: IComponentFormField) {
  const { label, desc, styling } = props.config
  const { values, formKeyName, setFieldValue, count, position } = props
  const defaultDateRange = {
    startDate: new Date(),
    endDate: new Date()
  }
  function getPosition(): TDateVariant {
    return count % 3 < position ? 'fareyeV2' : 'fareyeV2 bottom-left'
  }
  const {
    label: labelStyle = {},
    container = {},
    inputType = {},
    errorText = {},
    inputContainer = {}
  } = styling ? (styling.styles ? styling.styles : {}) : {}
  return (
    <Column {...(styling ? styling.variant : {})} style={container} column>
      <CustomLabel label={label} desc={desc} style={labelStyle} />
      <Flex width={1} column style={inputContainer}>
        <DatePicker
          onDateRangeSelected={(dateRange: IDateRange<string | Date>) =>
            setFieldValue(formKeyName, dateRange)
          }
          style={{ button: inputType }}
          variant={getPosition()}
          value={values[formKeyName] || defaultDateRange}
          name="dateFilter"
        />
        <ErrorSpan variant="danger">
          <ErrorMessage
            component={(props: any) => (
              <CustomErrorMessage {...props} style={errorText} />
            )}
            name={formKeyName}
          />
        </ErrorSpan>
      </Flex>
    </Column>
  )
}
