import React from 'react'
import styled from 'styled-components'
import { splitVariantsToTypes } from '../main'
import { ITimeLineProps } from '../utility/types'

const TimeLineFrame: any = styled.div`
  ${(props: any) => {
    const variant = splitVariantsToTypes(props)
    const isHorizontal = props.variant.indexOf('horizontal') > -1
    return {
      ...props.theme.timeline[variant.size].frame,
      ...(isHorizontal
        ? {
            'flex-direction': 'row',
            'overflow-x': 'auto',
            'overflow-y': 'hidden'
          }
        : { 'overflow-x': 'hidden', 'overflow-y': 'auto' }),
      ...props.styles
    }
  }};
`
const TimeInstance: any = styled.div`
  ${(props: any) => {
    const variant = splitVariantsToTypes(props)
    const isHorizontal = props.variant.indexOf('horizontal') > -1
    return {
      ...props.theme.timeline[variant.size].timeInstance,
      ...(isHorizontal ? props.theme.timeline[variant.size].timeInstanceH : {}),
      ...props.styles
    }
  }};
`

const Dot: any = styled.div`
  ${(props: any) => {
    const variant = splitVariantsToTypes(props)
    const isDotted = props.variant.indexOf('dotted') > -1
    const isHorizontal = props.variant.indexOf('horizontal') > -1
    return {
      ...props.theme.timeline[variant.size].dot,
      ...(isHorizontal ? props.theme.timeline[variant.size].dotH : {}),
      ...(isDotted ? props.theme.timeline[variant.size].dotDotted : {}),
      ...props.styles
    }
  }};
`

const Line: any = styled.span`
  ${(props: any) => {
    const variant = splitVariantsToTypes(props)
    const isDotted = props.variant.indexOf('dotted') > -1
    const isHorizontal = props.variant.indexOf('horizontal') > -1
    return {
      ...props.theme.timeline[variant.size][isHorizontal ? 'lineH' : 'line'],
      ...(isDotted ? props.theme.timeline[variant.size].lineDotted : {}),
      ...props.styles
    }
  }};
`

function TimeLine(props: ITimeLineProps) {
  const { children, style, variant } = props
  const OtherProps = { ...props }
  delete OtherProps.style
  const reactChildren = React.Children.map(
    children,
    (child: any, i: number) => (
      <TimeInstance variant={variant} styles={style && style.timeInstance}>
        <Dot variant={variant} styles={style && style.dot} className="dot" />
        {(children as any).length !== i + 1 && (
          <Line
            variant={variant}
            styles={style && style.line}
            className="line"
          />
        )}
        {child}
      </TimeInstance>
    )
  )
  return (
    <TimeLineFrame {...OtherProps} styles={style && style.frame}>
      {reactChildren}
    </TimeLineFrame>
  )
}

export default TimeLine
