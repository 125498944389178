import React from 'react'
import { getDescendantProp } from '../utility/funcs'

export default function CustomErrorMessage(props: any) {
  const { children, formKey } = props
  let message = ''
  if (formKey && typeof children != 'string') {
    message = getDescendantProp(children, formKey)
  } else if (typeof children == 'string') {
    message = children
  }
  return <>{message}</>
}
