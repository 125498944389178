import React from 'react'
import ReactLoading, { LoadingType } from 'react-loading'
import { ILoadingProps } from '../utility/types'
import { FaSpinner } from '@fareye/utils'
import { Props } from '../Theme/styledSystem'

// import styled from 'styled-components'

// const SpinDots = styled.div`
//   height: 100%;
//   width: 100%;
//   animation: 1s spinner linear infinite;
//   @keyframes spinner {
//     from {
//       transform: rotateZ(0deg);
//     }
//     to {
//       transform: rotateZ(360deg);
//     }
//   }
// `

const Loader = (props: ILoadingProps) => {
  let {
    containerStyle,
    variant = 'bubbles',
    size,
    color,
    width,
    height
  } = props
  if (size) {
    height = `${size}px`
    width = `${size}px`
  }
  let OtherProps = { ...props }
  delete OtherProps.containerStyle
  if (variant === 'spinner') {
    return (
      <Loader {...Props(props)}>
        <FaSpinner />
      </Loader>
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: height || '12.5em',
        width: width || '100%',
        ...containerStyle
      }}
    >
      {[
        'blank',
        'balls',
        'bars',
        'bubbles',
        'cubes',
        'cylon',
        'spin',
        'spinningBubbles',
        'spokes'
      ].includes(variant) && (
        <ReactLoading
          type={variant as LoadingType}
          color={color || '#1a2847'}
          height={height || '10%'}
          width={width || '10%'}
          {...OtherProps}
        />
      )}
    </div>
  )
}

export default Loader
