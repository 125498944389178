import React from 'react'
import {
  usePagination,
  useResizeColumns,
  useRowSelect,
  useRowState,
  useSortBy,
  useTable
} from 'react-table'
import { Flex, Span } from '../main'
import NoData from './components/NoData'
import Pagination from './components/Pagination'
import THead from './components/THead'
import TableActions from './containers/TableActions'
import { TableStyle } from './styled'
import { ITable } from './types'

const defaultPageOptions = [10, 25, 50, 100, 150, 200]

const stateReducer = (
  newState: any,
  action: any,
  prevState: any,
  options: any
) => {
  const {
    defaultPageSize,
    manualPagination,
    defaultPageIndex,
    defaultPageSizeOptions,
    defaultSelectedRowIds
  } = options
  if (action.type === 'init') {
    return {
      ...newState,
      pageSize: defaultPageSize || defaultPageSizeOptions[0],
      ...(defaultSelectedRowIds && Object.keys(defaultSelectedRowIds).length
        ? { selectedRowIds: defaultSelectedRowIds }
        : {}),
      ...(defaultPageIndex ? { pageIndex: defaultPageIndex } : {})
    }
  }
  if (action.type === 'resetPage') {
    return {
      ...newState,
      pageIndex: defaultPageIndex ?? newState.pageIndex
    }
  }
  return newState
}

const manipulateHooks = ({ hooks, showRowSelect, expandable }: any) => {
  if (showRowSelect || expandable) {
    hooks.visibleColumns.push((columns: any[]) => [
      {
        id: 'selection',
        width: 32,
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div style={{ textAlign: 'center' }}>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }: any) => (
          <div style={{ textAlign: 'center' }}>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        )
      },
      ...columns
    ])
  }
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef(null)
    const resolvedRef: any = ref || defaultRef
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])
    return (
      <input
        type="checkbox"
        ref={resolvedRef}
        {...rest}
        style={{ margin: 0, ...rest.style }}
      />
    )
  }
)

function computeTWidth(headerGroups: any) {
  let windowW = window.innerWidth
  let colWid = 0
  headerGroups.map((group: any) => {
    group.headers.map((o: any) => {
      colWid += o.maxWidth > 2000 ? o.width ?? o.minWidth : o.maxWidth
    })
  })
  return windowW < colWid ? colWid : '100%'
}

function Table(props: ITable) {
  const {
    data = [],
    dataLoading,
    columns,
    className,
    size = 'm',
    fontSize = 'm',
    refresh,
    refreshing,
    toggleFlagging,
    onPageChange,
    defaultPageSize,
    defaultPageIndex,
    onPageSizeChange,
    disablePageSizeChange = false,
    defaultSelectedRowIds,
    getTrProps,
    getTdProps,
    showRowSelect,
    setSelectedRows,
    downloadCSV,
    downloadLoading = false,
    selectedFilters,
    setSelectedFilters,
    expandable,
    subComponent,
    sorting,
    sortable,
    totalElements,
    showTotalCount = true,
    onSortedChange,
    resizableColumns = true,
    commentingModule,
    manualPagination = false,
    showPagination = true,
    defaultPageSizeOptions = defaultPageOptions
  } = props

  var ins: any = useTable(
    {
      data,
      columns,
      stateReducer: (ns, a, ps) =>
        stateReducer(ns, a, ps, {
          defaultPageSize,
          manualPagination,
          defaultPageIndex,
          defaultPageSizeOptions,
          defaultSelectedRowIds
        })
    },
    useSortBy,
    useRowState,
    usePagination,
    useRowSelect,
    useResizeColumns,
    hooks => manipulateHooks({ hooks, showRowSelect, expandable })
  )

  const {
    rows = [],
    page,
    gotoPage,
    nextPage,
    pageCount,
    allColumns,
    canNextPage,
    pageOptions,
    setPageSize,
    headerGroups,
    previousPage,
    getTableProps,
    canPreviousPage,
    selectedFlatRows,
    isAllRowsSelected,
    isAllPageRowsSelected,
    getTableBodyProps,
    totalColumnsWidth,
    totalColumnsMaxWidth,
    totalColumnsMinWidth,
    state: { pageIndex, pageSize, selectedRowIds },
    prepareRow
  } = ins

  React.useEffect(() => {
    if (!showPagination && pageSize !== data.length) {
      setPageSize(data.length)
    } else {
      setPageSize(defaultPageSize || defaultPageSizeOptions[0])
    }
  }, [showPagination])
  React.useEffect(() => {
    setSelectedRows?.({
      isAllPageRowsSelected,
      isAllRowsSelected,
      selectedRowIds: Object.keys(selectedRowIds),
      selectedFlatRows
    })
  }, [isAllRowsSelected, selectedRowIds])
  const renderData = showPagination ? page : rows
  const hasDataForCurrPageIndex = showPagination
    ? pageIndex * pageSize < data.length
    : !!renderData.length
  const tableMaxWidth = computeTWidth(headerGroups)
  const totalDataLength = totalElements || data.length
  return (
    <TableStyle
      data-testid="table"
      size={size}
      fontSize={fontSize}
      className={className}
    >
      <TableActions
        refresh={refresh}
        refreshing={refreshing}
        downloadCSV={downloadCSV}
        toggleFlagging={toggleFlagging}
        showTotalCount={showTotalCount}
        totalElements={totalDataLength}
        downloadLoading={downloadLoading}
        commentingModule={commentingModule}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      <div className="responsive-table">
        <table {...getTableProps()} style={{ ...getTableProps().style }}>
          <THead
            sorting={sorting}
            sortable={sortable}
            resizableColumns={resizableColumns}
            onSortedChange={onSortedChange}
            headerGroups={headerGroups}
          />
          <tbody {...getTableBodyProps()}>
            {hasDataForCurrPageIndex ? (
              renderData.map((row: any, trIndex: number) => {
                prepareRow(row)
                const trProps = {
                  ...row.getRowProps(),
                  ...(getTrProps ? getTrProps(row) : {})
                }
                var showSubComp = !!subComponent
                if (expandable && subComponent) {
                  showSubComp = Object.keys(selectedRowIds).includes(row.id)
                }
                return (
                  <React.Fragment key={trIndex}>
                    <tr data-testid={`tbody-tr-${trIndex}`} {...trProps}>
                      {row.cells.map((cell: any, j: number) => {
                        const tdProps = {
                          ['data-testid']: `tbody-td-${trIndex}-${j}`,
                          ...cell.getCellProps(),
                          ...(getTdProps ? getTdProps(cell) : {})
                        }
                        return (
                          <td key={j} {...tdProps}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                    {showSubComp && (
                      <tr
                        key={trIndex}
                        className="sub-comp"
                        data-testid={`tbody-tr-sub-${trIndex}`}
                      >
                        <td
                          data-testid={`tbody-subcomp-${trIndex}`}
                          colSpan={allColumns.length}
                          style={{ borderBottom: '8px solid #f0f2f5' }}
                        >
                          {subComponent?.({
                            index: row.index,
                            original: row.original,
                            row,
                            level: row.depth,
                            page: pageIndex,
                            pageSize
                          })}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                )
              })
            ) : (
              <NoData
                noDataLoading={
                  dataLoading || (!hasDataForCurrPageIndex && !!refreshing)
                }
                colSpan={allColumns.length}
              />
            )}
          </tbody>
        </table>
      </div>
      <Flex alignItemsCenter justifyContentFlexEnd>
        <Span>{dataLoading && totalDataLength ? 'Loading...' : null}</Span>
        <Pagination
          gotoPage={(pageNo: number) => {
            gotoPage(pageNo)
            onPageChange?.(pageNo)
          }}
          pageSize={pageSize}
          pageIndex={pageIndex}
          canNextPage={canNextPage}
          setPageSize={(changedPageSize: number) => {
            setPageSize(changedPageSize)
            onPageChange?.(0)
            onPageSizeChange?.(changedPageSize)
          }}
          dataLoading={dataLoading}
          disablePageSizeChange={disablePageSizeChange}
          totalElements={totalDataLength}
          pageOptions={defaultPageSizeOptions}
          showPagination={showPagination}
          canPreviousPage={canPreviousPage}
        />
      </Flex>
    </TableStyle>
  )
}

export default Table
