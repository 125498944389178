import {
  border,
  color,
  compose,
  display,
  flexbox,
  maxWidth,
  space,
  typography,
  width
} from 'styled-system'
import { EGraph } from '../utility/variantTypes'

export const layout = compose(
  color,
  space,
  width,
  border,
  flexbox,
  display,
  maxWidth,
  typography
)

// wrapper > frame >

export const MASTER = {
  DISPLAY: ['danger', 'dashed', 'default', 'link', 'primary', 'secondary'],
  GHOST: 'ghost',
  SIZE: ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'],
  TYPOGRAPHY: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
  PATTERN: ['double', 'dotted', 'solid'],
  POSITION: [
    'Bottom-Left',
    'Bottom-Center',
    'Bottom-Right',
    'Top-Left',
    'Top-Center',
    'Top-Right',
    'Left-Top',
    'Left-Center',
    'Left-Bottom',
    'Right-Top',
    'Right-Center',
    'Right-Bottom'
  ],
  GRAPH: EGraph,
  ZINDEX: {
    popover: 401,
    popoverArrow: 402,
    map: 1000,
    mapComponents: 1001,
    pageHeader: 1,
    sidebar: 1004,
    header: 1004,
    modalBackdrop: 99999,
    toastify: 1006
  }
}

export interface IPropsInterface {
  bg?: string
  className?: string
  color?: string
  children?: string | React.ReactNode | JSX.Element
  dangerouslySetInnerHTML?: any
  height?: number
  hidden?: boolean
  id?: string
  fontSize?: number | Array<number>
  onClick?: (x: any) => void
  onContextMenu?: (x: any) => void
  onDoubleClick?: (x: any) => void
  onDrag?: (x: any) => void
  onDragEnd?: (x: any) => void
  onDragEnter?: (x: any) => void
  onDragExit?: (x: any) => void
  onDragLeave?: (x: any) => void
  onDragOver?: (x: any) => void
  onDragStart?: (x: any) => void
  onDrop?: (x: any) => void
  onFocus?: (x: any) => void
  onBlur?: (x: any) => void
  onMouseDown?: (x: any) => void
  onMouseEnter?: (x: any) => void
  onMouseLeave?: (x: any) => void
  onMouseMove?: (x: any) => void
  onMouseOut?: (x: any) => void
  onMouseOver?: (x: any) => void
  onMouseUp?: (x: any) => void
  onKeyDown?: (x: any) => void
  onKeyPress?: (x: any) => void
  onKeyUp?: (x: any) => void
  m?: number | Array<number>
  mb?: number | Array<number>
  ml?: number | Array<number>
  mr?: number | Array<number>
  mt?: number | Array<number>
  mx?: number | Array<number>
  my?: number | Array<number>
  p?: number | Array<number>
  pb?: number | Array<number>
  pl?: number | Array<number>
  pr?: number | Array<number>
  pt?: number | Array<number>
  px?: number | Array<number>
  py?: number | Array<number>
  style?: any
  styles?: React.CSSProperties
  theme?: any
  title?: string
  variant?: string
  width?: any
  ref?: any
}

export function refinePropsFromParentEvents(props: IPropsInterface): any {
  const FilteredProps = { ...props }
  delete FilteredProps.style
  delete FilteredProps.className
  delete FilteredProps.width
  delete FilteredProps.onClick
  delete FilteredProps.onContextMenu
  delete FilteredProps.onDoubleClick
  delete FilteredProps.onDrag
  delete FilteredProps.onDragEnd
  delete FilteredProps.onDragEnter
  delete FilteredProps.onDragExit
  delete FilteredProps.onDragLeave
  delete FilteredProps.onDragOver
  delete FilteredProps.onDragStart
  delete FilteredProps.onDrop
  delete FilteredProps.onFocus
  delete FilteredProps.onBlur
  delete FilteredProps.onMouseDown
  delete FilteredProps.onMouseEnter
  delete FilteredProps.onMouseLeave
  delete FilteredProps.onMouseMove
  delete FilteredProps.onMouseOut
  delete FilteredProps.onMouseOver
  delete FilteredProps.onMouseUp
  delete FilteredProps.onKeyDown
  delete FilteredProps.onKeyPress
  delete FilteredProps.onKeyUp
  return FilteredProps
}

export function Props(props: any) {
  return {
    ...props,
    onClick: props.onClick,
    m: props.m,
    mb: props.mb,
    ml: props.ml,
    mr: props.mr,
    mx: props.mx,
    mt: props.mt,
    my: props.my,
    p: props.p,
    pb: props.pb,
    pl: props.pl,
    pr: props.pr,
    px: props.px,
    pt: props.pt,
    py: props.py
  }
}

export function splitVariantsToTypes(props: any) {
  const { variant, parentVariant, theme } = props
  const types: string[] = parentVariant ? parentVariant.split(' ') : []
  types.push(...(variant ? variant.split(' ') : []))

  var display = 'default'
  var size = 'm'
  var typography = theme.global.typographySizing[7]
  var pattern = ''
  var position = theme.global.position[0]

  Array.from(new Set(types)).forEach((type: string, i: number) => {
    if (theme.global.displayType.includes(type)) {
      display = type
    } else if (theme.global.sizing.includes(type)) {
      size = type
    } else if (theme.global.typographySizing.includes(type)) {
      typography = type
    } else if (theme.global.position.includes(type)) {
      position = type
    }
  })
  return {
    isEllipsis: variant ? variant.includes('ellipsis') : false,
    onHover: variant ? variant.includes('hover') : false,
    size,
    pattern,
    display,
    position,
    typography
  }
}
export function calculateSizesforLoader(variant?: string): number {
  if (variant && variant.includes('xxs')) {
    return 8
  } else if (variant && variant.includes('xs')) {
    return 12
  } else if (variant && variant.includes('s')) {
    return 16
  } else if (variant && variant.includes('m')) {
    return 20
  } else if (variant && variant.includes('l')) {
    return 24
  } else if (variant && variant.includes('xl')) {
    return 28
  } else if (variant && variant.includes('xxl')) {
    return 32
  } else {
    return 12
  }
}

// create typings using constants and below function
// function strEnum<T extends string>(o: Array<T>): { [K in T]: K } {
//   return o.reduce((res, key) => {
//     res[key] = key;
//     return res;
//   }, Object.create(null));
// }

// const Direction = strEnum(['danger', 'dashed', 'default', 'link', 'primary', 'secondary'])
// /** Create a Type */
// type Direction = keyof typeof Direction;
// let a: Direction = "danger"
