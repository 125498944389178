import environment from '../../environment'
import { TAlertConfig } from '@fareye/settings'
const { baseURL } = environment
//const baseURL = 'http://13.127.59.154:4000'
//const baseURL = 'http://localhost:3001'

const AlertsRenderingConfig: any = {
  alertSettings: {
    config: {
      alertListing: {
        RolloverAlertSubscription: {
          formBuilderConfig: {
            label: 'Rollover Alert',
            formKey: 'rolloverAlert',
            options: {},
            desc:
              'This means your container didn’t make the vessel. Not having your container loaded onto the ship may happen because of customs problems, overbooking, or vessel omissions.Your carrier will reschedule your shipment and place your container on the next departing ship.',
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },

                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            },
            styles: {}
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Shipment Id', key: '' },
              { label: 'Package Id', key: '' },
              {
                label: 'BL No.',
                key: ''
              },
              { label: 'Previous Vessel Name', key: '' },
              { label: 'Previous Vessel No.', key: '' },
              { label: 'New Vessel Name', key: '' },
              { label: 'New Vessel No.', key: '' },
              { label: 'Container No.', key: '' },
              { label: 'STD Shipping Line', key: '' },
              { label: 'New STD Shipping Line', key: '' },
              {
                label: 'Origin',
                key: ''
              },
              { label: 'Destination', key: '' },
              {
                label: 'POL',
                key: ''
              },
              { label: 'POD', key: '' },
              { label: 'Consignee', key: '' },
              { label: 'Consigner', key: '' },
              { label: 'Transporter', key: '' },
              { label: 'Timestamp', key: '' },
              { label: 'Recorded At', key: '' }
            ]
          },
          type: 'ocean'
        },
        DemurrageAlertSubscription: {
          formBuilderConfig: {
            label: 'Demurrage Alert',
            formKey: 'demurrageAlert',
            desc:
              'Demurrage is a fee that container lines charge when you haven’t picked up your imported containers in time. When your containers have been discharged, there is a free period for storing them in the port (provided by the container line). You have to pick up your containers before the free period expires. If not, you are charged for the number of days your containers were left in the port.You can also be charged for demurrage fees if you have containers that cannot be shipped out by the container line due to, for example, customs problems. You are then charged for the number of days your containers have to be stored in the port.',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },

                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'shippingLine',
                    validation: 'required',
                    label: 'Select Shipping Line/Transporter',
                    type: 'creatable',
                    placeholder: 'Select',
                    isMulti: false,
                    // labelForDisplay: 'name',
                    // valueForDisplay: 'kind',
                    // searchQueryKey: 'results',

                    // apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: [
                      { label: 'Shipping Line 1', value: 'line1' },
                      { label: 'Shipping Line 2', value: 'line2' }
                    ],
                    dependency: null
                  },

                  {
                    formKey: 'free-period',
                    validation: 'required',
                    label: 'Free Period',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'day',
                    options: [
                      {
                        value: 3,
                        label: '3 days'
                      },
                      {
                        value: 7,
                        label: '7 days'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  }
                ],
                style: {}
              },
              '3': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  }
                ]
              },
              '4': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Shipment Id', key: '' },
              { label: 'Package Id', key: '' },
              {
                label: 'BL No.',
                key: ''
              },
              { label: 'Container No.', key: '' },
              { label: 'Current Landmark', key: '' },
              {
                label: 'Origin',
                key: ''
              },
              { label: 'Destination', key: '' },
              {
                label: 'POL',
                key: ''
              },
              { label: 'POD', key: '' },
              { label: 'Consignee', key: '' },
              { label: 'Consigner', key: '' },
              { label: 'Transporter', key: '' },
              { label: 'Timestamp', key: '' },
              { label: 'Recorded At', key: '' }
            ]
          },
          type: 'ocean'
        },
        PolDelayAlertSubscription: {
          formBuilderConfig: {
            label: 'POL Delay Alert',
            formKey: 'polDelayAlert',
            desc:
              'Package/Shipment/Container stuck at POL for more than X days (usually 3 days or can be configured by the user)',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },

                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  },
                  {
                    formKey: 'time-period',
                    validation: 'required',
                    label: 'Time Period',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'day',
                    options: [
                      {
                        value: 1,
                        label: '1 Day'
                      },
                      {
                        value: 3,
                        label: '3 Day'
                      },
                      {
                        value: 4,
                        label: '4 Day'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Shipment Id', key: '' },
              { label: 'Package Id', key: '' },
              { label: 'Vessel No.', key: '' },
              { label: 'Vessel Name', key: '' },
              {
                label: 'BL No.',
                key: ''
              },
              { label: 'Container No.', key: '' },

              {
                label: 'Origin',
                key: ''
              },
              { label: 'Destination', key: '' },
              {
                label: 'POL',
                key: ''
              },
              { label: 'POD', key: '' },
              { label: 'Consignee', key: '' },
              { label: 'Consigner', key: '' },
              { label: 'Transporter', key: '' },
              { label: 'Loading In/Gate', key: '' },
              { label: 'Loading Out/VDL', key: '' },
              { label: 'STD Shipping Line', key: '' },
              { label: 'ATD Shipping Line', key: '' },
              { label: 'STD Geofence', key: '' },
              { label: 'ATD Geofence', key: '' },
              { label: 'Time Period', key: '' },
              { label: 'Timestamp', key: '' },
              { label: 'Recorded At', key: '' }
            ]
          },
          type: 'ocean'
        },
        PodDelayAlertSubscription: {
          formBuilderConfig: {
            label: 'POD Delay alert',
            formKey: 'podDelayAlert',
            desc:
              'Package/Shipment/Container stuck at the port of discharge for more than X days (Usually 3 days or can be configured by the user)',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },

                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  },
                  {
                    formKey: 'time-period',
                    validation: 'required',
                    label: 'Time Period',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'day',
                    options: [
                      {
                        value: 1,
                        label: '1 Day'
                      },
                      {
                        value: 3,
                        label: '3 Day'
                      },
                      {
                        value: 4,
                        label: '4 Day'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Shipment Id', key: '' },
              { label: 'Package Id', key: '' },
              { label: 'Vessel No.', key: '' },
              { label: 'Vessel Name', key: '' },
              {
                label: 'BL No.',
                key: ''
              },
              { label: 'Container No.', key: '' },

              {
                label: 'Origin',
                key: ''
              },
              { label: 'Destination', key: '' },
              {
                label: 'POL',
                key: ''
              },
              { label: 'POD', key: '' },
              { label: 'Consignee', key: '' },
              { label: 'Consigner', key: '' },
              { label: 'Transporter', key: '' },
              { label: 'STD Shipping Line', key: '' },
              { label: 'ATD Shipping Line', key: '' },
              { label: 'STD Geofence', key: '' },
              { label: 'ATD Geofence', key: '' },
              { label: 'Timestamp', key: '' },
              { label: 'Recorded At', key: '' }
            ]
          },
          type: 'ocean'
        },
        TsDelayAlertSubscription: {
          formBuilderConfig: {
            label: 'T/S Delay Alert',
            formKey: 'tsDelayAlert',
            desc:
              'Package/Shipment/Container stuck at transshipment port for more than X days (usually 3 days or can be configured by the user)',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },

                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  },
                  {
                    formKey: 'time-period',
                    validation: 'required',
                    label: 'Time Period',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'day',
                    options: [
                      {
                        value: 1,
                        label: '1 Day'
                      },
                      {
                        value: 3,
                        label: '3 Day'
                      },
                      {
                        value: 4,
                        label: '4 Day'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { key: '', label: 'Shipment Id' },
              { key: '', label: 'Package Id' },
              { key: '', label: 'Arrival Vessel no.' },
              { key: '', label: 'Arrival Vessel Name' },
              { key: '', label: 'Departure Vesser No.' },
              { key: '', label: 'Departure Vessel Name' },
              { key: '', label: 'BL No.' },
              { key: '', label: 'POL' },
              { key: '', label: 'T/S' },
              { key: '', label: 'POD' },
              { key: '', label: 'Origin' },
              { key: '', label: 'Destination' },
              { key: '', label: 'Transporter' },
              { key: '', label: 'VAT' }
            ]
          },
          type: 'ocean'
        },
        VesselArrivalAlertSubscription: {
          formBuilderConfig: {
            label: 'Vessel Arrival Alert',
            desc:
              'Alert raised when vessel is about to reach POD. Time period/distance can be configured by the user.',
            options: {},
            styles: {},
            formKey: 'vesselArrivalAlerts',
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },

                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  },
                  {
                    formKey: 'time',
                    validation: 'required',
                    label: 'Time',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'day',
                    options: [
                      {
                        value: 1,
                        label: '1 Day'
                      },
                      {
                        value: 2,
                        label: '2 Day'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { key: '', label: 'Shipment Id' },
              { key: '', label: 'Package Id' },
              { key: '', label: 'Vessel No.' },
              { key: '', label: 'Vessel Name' },
              { key: '', label: 'STA Shipping Line' },
              { key: '', label: 'ETA' },
              { key: '', label: 'Time Remaining' },
              { key: '', label: '% Completion' },
              { key: '', label: 'BL No.' },
              { key: '', label: 'Transporter' },
              { key: '', label: 'POL' },
              { key: '', label: 'POD' },
              { key: '', label: 'Origin' },
              { key: '', label: 'Destination' },
              { key: '', label: 'Consigner' },
              { key: '', label: 'COnsignee' },
              { key: '', label: 'Timestamp' },
              { key: '', label: 'Recorded At' }
            ]
          },
          type: 'ocean'
        },
        DetentionAlertSubscription: {
          formBuilderConfig: {
            label: 'Detention Alert',
            formKey: 'detentionAlert',
            desc:
              'Detention is a charge levied by the shipping line to the importer in cases where they have taken the full container for unpacking (let’s say within the free days) but have not returned the empty container to the nominated empty depot before the expiry of the allowed line free days.. ',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },

                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  },
                  {
                    formKey: 'shippingLine',
                    validation: 'required',
                    label: 'Select Shipping Line/Transporter',
                    type: 'creatable',
                    placeholder: 'Select',
                    isMulti: false,
                    // labelForDisplay: 'name',
                    // valueForDisplay: 'kind',
                    // searchQueryKey: 'results',

                    // apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: [
                      { label: 'Shipping Line 1', value: 'line1' },
                      { label: 'Shipping Line 2', value: 'line2' }
                    ],
                    dependency: null
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { key: '', label: 'Shipment Id' },
              { key: '', label: 'Package Id' },
              { key: '', label: 'BL No.' },
              { key: '', label: 'Container No.' },
              { key: '', label: 'Current Landmark' },
              { key: '', label: 'POL' },
              { key: '', label: 'POD' },
              { key: '', label: 'Origin' },
              { key: '', label: 'Destination' },
              { key: '', label: 'Transporter' },
              { key: '', label: 'Consigner' },
              { key: '', label: 'Consignee' },
              { key: '', label: 'Timestamp' },
              { key: '', label: 'Recorded At' }
            ]
          },
          type: 'ocean'
        },
        DisconnectionAlertSubscription: {
          formBuilderConfig: {
            label: 'Disconnection Alert',
            formKey: 'disconnectionAlert',
            options: {},
            desc:
              'If vehicle device is disconnected (based on hardware capability)',
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },

                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            },
            styles: {}
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: 'Material', key: 'material' },
              {
                label: 'Loading Date',
                key: 'tripStartDate',
                formatter: 'dateFormatter'
              },
              { label: 'Percentage Covered', key: 'percentageCovered' },
              { label: 'Truck Owner', key: 'truckOwner' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              {
                label: 'Last Signal',
                key: 'lastSignal',
                formatter: 'dateFormatter'
              },
              { label: 'Current Landmark', key: 'currentLandmark' },
              {
                label: 'Disconnection Time',
                key: 'istTimestamp',
                formatter: 'dateFormatter'
              },
              { label: 'Driver name', key: 'driver' },
              { label: 'Driver Contact', key: 'driverContact' }
            ]
          },
          type: 'road'
        },
        OverspeedAlertSubscription: {
          formBuilderConfig: {
            label: 'Overspeed Alert',
            formKey: 'overspeedAlert',
            desc:
              'If vehicle is speeding beyond a benchmarked speed limit (xx km/hr)',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-speed',
                    validation: 'required',
                    label: 'Overspeed limit',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: 'Select',
                    unit: 'km/hr',
                    options: [
                      {
                        value: 40,
                        label: '40 km/hr'
                      },
                      {
                        value: 45,
                        label: '45 km/hr'
                      },
                      {
                        value: 50,
                        label: '50 km/hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  },
                  {
                    formKey: 'settings-instances',
                    validation: 'required',
                    label: 'No of Instances',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: 'Select',
                    options: [
                      {
                        value: 1,
                        label: '1'
                      },
                      {
                        value: 25,
                        label: '25'
                      },
                      {
                        value: 50,
                        label: '50'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  },
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    searchQueryKey: 'results',
                    styling: { variant: { width: [1, 0.5, 0.5] } }
                  },
                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  }
                ],
                style: {}
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Driver name', key: 'driver' },
              { label: 'Driver Contact', key: 'driverContact' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Max Speed', key: 'speed' },
              { label: 'Overspeed Instances', key: 'overspeedInstances' },
              { label: 'Overspeeding Location', key: 'currentLandmark' }
            ]
          },
          type: 'road'
        },
        IdleAlertSubscription: {
          formBuilderConfig: {
            label: 'Excess Idling Alert',
            formKey: 'excessIdlingAlert',
            desc:
              'If the vehicle continues idling beyond the benchmarked (xx hrs) time duration',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    },
                    options: null,
                    dependency: null
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-time',
                    validation: 'required',
                    label: 'Time period',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: [
                      {
                        value: 12,
                        label: '12 hr'
                      },
                      {
                        value: 24,
                        label: '24 hr'
                      },
                      {
                        value: 48,
                        label: '48 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    dependency: null
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: 'Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.75] } },
                    options: null,
                    dependency: null,
                    searchQueryKey: 'results'
                  },
                  {
                    formKey: 'providers',
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    options: null,
                    dependency: null
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: 'Material', key: 'material' },
              {
                label: 'Loading Date',
                key: 'tripStartDate',
                formatter: 'dateFormatter'
              },
              { label: 'Idle Duration', key: 'haltDuration' },
              {
                label: 'Idle Start Time',
                key: 'idleStartTime',
                formatter: 'dateFormatter'
              },
              {
                label: 'Idle End Time',
                key: 'idleEndTime',
                formatter: 'dateFormatter'
              },
              { label: 'Percentage Trip Completed', key: 'percentageCovered' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Driver name', key: 'driver' },
              { label: 'Driver Contact', key: 'driverContact' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Stop Location', key: 'landmark' },
              {
                label: 'Last Signal',
                key: 'lastSignal',
                formatter: 'dateFormatter'
              },
              {
                label: 'IST Timestamp',
                key: 'istTimestamp',
                formatter: 'dateFormatter'
              },
              {
                label: 'Recorded At',
                key: 'recordedAt',
                formatter: 'dateFormatter'
              }
            ]
          },
          type: 'road'
        },
        DestinationAlertSubscription: {
          formBuilderConfig: {
            label: 'Destination pre-arrival alert',
            formKey: 'destinationPreArrivalAlert',
            desc:
              'Alert triggered to concerned stakeholder when the vehicle is about to arrive and is a preset xx km away from destination',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-distance',
                    validation: 'required',
                    label: 'Distance',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: 'Select',
                    unit: 'km',
                    options: [
                      {
                        value: 125,
                        label: '125 km'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 12,
                        label: '12 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    searchQueryKey: 'results',
                    styling: { variant: { width: [1, 0.5, 0.75] } }
                  },
                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Vehicle No', key: 'vehicleNo' },
              {
                label: 'Loading Out Time',
                key: 'loadingOutTime',
                formatter: 'dateFormatter'
              },
              { label: 'Distance Remaining', key: 'distanceRemaining' },
              { label: 'Percentage Completed', key: 'percentageCovered' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Driver name', key: 'driver' },
              { label: 'Driver Contact', key: 'driverContact' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Transporter', key: 'transporterName' },
              {
                label: 'Last Signal',
                key: 'lastSignal',
                formatter: 'dateFormatter'
              },
              { label: 'Current Landmark', key: 'currentLandmark' },
              { label: 'Poi Type', key: 'poiType' },
              {
                label: 'IST Timestamp',
                key: 'istTimestamp',
                formatter: 'dateFormatter'
              },
              {
                label: 'Recorded At',
                key: 'recordedAt',
                formatter: 'dateFormatter'
              }
            ]
          },
          type: 'road'
        },
        EtaAlertSubscription: {
          formBuilderConfig: {
            label: 'STA Exceeded Alert',
            formKey: 'staExceededAlert',
            desc:
              'If vehicle transit time (exceeds the benchmarked (xx hrs) transit time for that particular shipment',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-time',
                    validation: 'required',
                    label: 'Time period',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 1,
                        label: '1 hr'
                      },
                      {
                        value: 24,
                        label: '24 hr'
                      },
                      {
                        value: 48,
                        label: '48 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 12,
                        label: '12 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    searchQueryKey: 'results',
                    styling: { variant: { width: [1, 0.5, 0.75] } }
                  },
                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: 'Material', key: 'material' },
              {
                label: 'Loading Date',
                key: 'tripStartDate',
                formatter: 'dateFormatter'
              },
              {
                label: 'STA Elapsed By',
                key: 'etaElapsed',
                formatter: 'timeFormatterFromSeconds'
              },
              { label: 'STA', key: 'sta', formatter: 'dateFormatter' },
              {
                label: 'Loading Out Time',
                key: 'loadingOutTime',
                formatter: 'dateFormatter'
              },
              { label: 'Percentage Trip Completed', key: 'percentageCovered' },
              {
                label: 'Last Signal',
                key: 'istTimestamp',
                formatter: 'dateFormatter'
              },
              // {
              //   label: 'Recorded At',
              //   key: 'recordedAt',
              //   formatter: 'dateFormatter'
              // },
              { label: 'Landmark', key: 'landmark' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Driver name', key: 'driver' },
              { label: 'Driver Contact', key: 'driverContact' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Transporter', key: 'transporterName' }
            ]
          },
          type: 'road'
        },
        LoadingDelayAlertSubscription: {
          formBuilderConfig: {
            label: 'Loading Delay Alert',
            desc:
              'If Vehicle duration spent inside loading geofence exceeds benchmarked (XX hrs) value',
            options: {},
            styles: {},
            formKey: 'loadingDelayAlerts',
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-time',
                    validation: 'required',
                    label: 'Time period',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    row: 1,
                    options: [
                      {
                        value: 24,
                        label: '24 hr'
                      },
                      {
                        value: 48,
                        label: '48 hr'
                      },
                      {
                        value: 96,
                        label: '96 hr'
                      }
                    ],
                    isMulti: false,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 12,
                        label: '12 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.75] } },
                    searchQueryKey: 'results'
                  },
                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: 'Invoice No', key: 'referenceNo' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Driver name', key: 'driver' },
              { label: 'Driver Contact', key: 'driverContact' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Transporter', key: 'transporterName' },
              {
                label: 'Last Signal',
                key: 'lastSignal',
                formatter: 'dateFormatter'
              },
              { label: 'Landmark', key: 'currentLandmark' },
              {
                label: 'Loading Entry',
                key: 'poiEnterTime',
                formatter: 'dateFormatter'
              },
              {
                label: 'Loading Exit',
                key: 'poiExitTime',
                formatter: 'dateFormatter'
              },
              { label: 'Loading Time Taken', key: 'duration' },
              { label: 'Poi Type', key: 'poiType' },
              {
                label: 'IST Timestamp',
                key: 'istTimestamp',
                formatter: 'dateFormatter'
              },
              {
                label: 'Recorded At',
                key: 'recordedAt',
                formatter: 'dateFormatter'
              }
            ]
          },
          type: 'road'
        },
        UnloadingDelayAlertSubscription: {
          formBuilderConfig: {
            label: 'Unloading Delay Alert',
            formKey: 'unloadingDelayAlert',
            desc:
              'If Vehicle duration spent inside unloading geofence exceeds benchmarked (XX hrs) value',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    },
                    options: null,
                    dependency: null
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-time',
                    validation: 'required',
                    label: 'Time period',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    row: 1,
                    options: [
                      {
                        value: 24,
                        label: '24 hr'
                      },
                      {
                        value: 48,
                        label: '48 hr'
                      },
                      {
                        value: 96,
                        label: '96 hr'
                      }
                    ],
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    isMulti: false,
                    dependency: null
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 12,
                        label: '12 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    searchQueryKey: 'results',
                    styling: { variant: { width: [1, 0.5, 0.75] } }
                  },
                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: 'Invoice No', key: 'referenceNo' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Driver name', key: 'driver' },
              { label: 'Driver Contact', key: 'driverContact' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Transporter', key: 'transporterName' },
              {
                label: 'Last Signal',
                key: 'lastSignal',
                formatter: 'dateFormatter'
              },
              { label: 'Landmark', key: 'currentLandmark' },
              {
                label: 'Unloading Entry',
                key: 'poiEnterTime',
                formatter: 'dateFormatter'
              },
              {
                label: 'Unloading Exit',
                key: 'poiExitTime',
                formatter: 'dateFormatter'
              },
              { label: 'Unloading Time Taken', key: 'duration' },
              { label: 'Poi Type', key: 'poiType' },
              {
                label: 'IST Timestamp',
                key: 'istTimestamp',
                formatter: 'dateFormatter'
              },
              {
                label: 'Recorded At',
                key: 'recordedAt',
                formatter: 'dateFormatter'
              }
            ]
          },
          type: 'road'
        },
        PoiDurationAlertSubscription: {
          formBuilderConfig: {
            label: 'Poi Duration Alert',
            formKey: 'poiDurationAlert',
            desc:
              'If Vehicle spends more than benchmarked time (XX hrs) in preselected PoI areas (excluding Loading/Unloading Areas)',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 4,
                        label: '4 hr'
                      },
                      {
                        value: 6,
                        label: '6 hr'
                      },
                      {
                        value: 12,
                        label: '12 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  },
                  {
                    formKey: 'settings-time',
                    validation: 'required',
                    label: 'Time period',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 12,
                        label: '12 hr'
                      },
                      {
                        value: 24,
                        label: '24 hr'
                      },
                      {
                        value: 48,
                        label: '48 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'settings-poi_ids',
                    validation: 'required',
                    label: 'POI',
                    type: 'local-select',
                    isMulti: true,
                    labelForDisplay: 'poi_nick_name',
                    valueForDisplay: 'id',
                    searchQueryKey: 'results',
                    placeholder: ' POI',
                    apiUrl: '/api/v1/pois',
                    row: 1,
                    dependency: null,
                    options: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  },
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    searchQueryKey: 'results',
                    styling: { variant: { width: [1, 0.5, 0.5] } }
                  },
                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  }
                ],
                style: {}
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Place', key: 'poiNickName' },
              { label: 'Vehicle No', key: 'vehicleNo' },
              {
                label: 'Loading date',
                key: 'tripStartDate',
                formatter: 'dateFormatter'
              },
              { label: 'Percentage Covered', key: 'percentageCovered' },
              {
                label: 'Enter Time',
                key: 'poiEnterTime',
                formatter: 'dateFormatter'
              },
              {
                label: 'Exit Time',
                key: 'poiExitTime',
                formatter: 'dateFormatter'
              },
              { label: 'Duration', key: 'duration' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Driver Name', key: 'driverName' },
              { label: 'Driver Contact', key: 'driverContact' }
            ]
          },
          type: 'road'
        },
        RouteDevAlertSubscription: {
          formBuilderConfig: {
            label: 'Route Deviation Alert',
            formKey: 'routeDeviationAlert',
            desc: 'If the vehicle has deviated from Ideal route',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-dev_radius',
                    validation: 'required',
                    label: 'Radius',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: 'Select',
                    unit: 'km',
                    options: [
                      {
                        value: 0.3,
                        label: '0.3 km'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },
                  {
                    formKey: 'settings-moving_duration',
                    validation: 'required',
                    label: 'Moving Duration',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: 'Select',
                    options: [
                      {
                        value: 0.167,
                        label: '0.167 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'settings-halt_duration',
                    validation: 'required',
                    label: 'Halt Duration',
                    type: 'creatable',
                    creatableType: 'number',
                    unit: 'hr',
                    placeholder: 'Select',
                    options: [
                      {
                        value: 0.25,
                        label: '0.25 hr'
                      }
                    ],
                    isMulti: false,
                    row: 2,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },
                  {
                    formKey: 'settings-processing_count_threshold',
                    validation: 'required',
                    label: 'Deviation Points',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: 'Select',
                    options: [
                      {
                        value: 10,
                        label: '10'
                      }
                    ],
                    isMulti: false,
                    row: 2,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.084,
                        label: '5 hr'
                      }
                    ],
                    isMulti: false,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    row: 1,
                    dependency: null
                  },
                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '3': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    options: null,
                    dependency: null,
                    searchQueryKey: 'results'
                  }
                ]
              },
              '4': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: '% Trip Completed', key: 'percentageCovered' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Driver name', key: 'driver' },
              { label: 'Driver Contact', key: 'driverContact' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Stop Location', key: 'landmark' },
              { label: 'Deviation Distance', key: 'distanceInKm' },
              {
                label: 'Last Signal',
                key: 'lastSignal',
                formatter: 'dateFormatter'
              },
              {
                label: 'IST Timestamp',
                key: 'istTimestamp',
                formatter: 'dateFormatter'
              },
              {
                label: 'Recorded At',
                key: 'recordedAt',
                formatter: 'dateFormatter'
              }
            ]
          },
          type: 'road'
        },
        PoiEntryAlertSubscription: {
          formBuilderConfig: {
            label: 'Poi Entry and Exit Alert',
            formKey: 'poiEnterAndExitAlert',
            desc: 'If the vehicle has entered or exited from a POI',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    },
                    options: null,
                    dependency: null
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-poi_ids',
                    validation: 'required',
                    label: 'POI',
                    type: 'local-select',
                    isMulti: true,
                    labelForDisplay: 'poi_nick_name',
                    valueForDisplay: 'id',
                    searchQueryKey: 'results',
                    placeholder: 'Select',
                    apiUrl: '/api/v1/pois',
                    row: 1,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 1,
                        label: '1 hr'
                      },
                      {
                        value: 6,
                        label: '6 hr'
                      },
                      {
                        value: 12,
                        label: '12 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    options: null,
                    dependency: null,
                    searchQueryKey: 'results'
                  },
                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Place', key: 'poiNickName' },
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: 'Event', key: 'poiAlertType' },
              {
                label: 'Enter Time',
                key: 'poiEnterTime',
                formatter: 'dateFormatter'
              },
              {
                label: 'Exit Time',
                key: 'poiExitTime',
                formatter: 'dateFormatter'
              },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Driver name', key: 'driver' },
              { label: 'Driver Contact', key: 'driverContact' },
              {
                label: 'IST Timestamp',
                key: 'istTimestamp',
                formatter: 'dateFormatter'
              },
              {
                label: 'Recorded At',
                key: 'recordedAt',
                formatter: 'dateFormatter'
              }
            ]
          },
          type: 'road'
        },
        InplantOverspeedAlertSubscription: {
          formBuilderConfig: {
            label: 'Inplant Overspeeding Alert',
            formKey: 'inplantOverspeedingAlert',
            desc: 'Alert sent when vehicle speeds exceeds the set limit',
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    },
                    options: null,
                    dependency: null
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-speed',
                    validation: 'required',
                    label: 'Speed',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'km/hr',
                    options: [
                      {
                        value: 40,
                        label: '40 km/hr'
                      },
                      {
                        value: 45,
                        label: '45 km/hr'
                      },
                      {
                        value: 50,
                        label: '50 km/hr'
                      }
                    ],
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    isMulti: false,
                    row: 1,
                    styles: {},
                    dependency: null
                  },
                  {
                    formKey: 'settings-instances',
                    validation: 'required',
                    label: 'Instances',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    options: [
                      {
                        value: 1,
                        label: '1'
                      },
                      {
                        value: 25,
                        label: '25'
                      },
                      {
                        value: 50,
                        label: '50'
                      }
                    ],
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    isMulti: false,
                    row: 1,
                    styles: {},
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    row: 1,
                    styles: {},
                    dependency: null
                  },
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },

                    dependency: null,
                    searchQueryKey: 'results'
                  },
                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    styling: { variant: { width: [1, 0.5, 0.25] } },

                    row: 3,
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Reference Number', key: 'referenceNo' },
              {
                label: 'Trip Start Date',
                key: 'tripStartDate',
                formatter: 'dateFormatter'
              },
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Driver name', key: 'driver' },
              { label: 'Driver Contact', key: 'driverContact' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Landmark', key: 'landmark' },
              {
                label: 'Recorded At',
                key: 'recordedAt',
                formatter: 'dateFormatter'
              },
              { label: 'Speed', key: 'speed' },
              { label: 'Material', key: 'material' },
              { label: 'Current Landmark ', key: 'currentLandmark' },
              { label: 'Landmark ', key: 'landmark' },
              { label: 'Recorded At ', key: 'recordedAt' },
              { label: 'Location', key: 'locationLink' }
            ]
          },
          type: 'road'
        },
        RestrictedMovementAlertSubscription: {
          formBuilderConfig: {
            label: 'RestrictedMovement Alert',
            formKey: 'restrictedMovementAlertSubscription',
            options: {},
            desc:
              'If vehicle device is disconnected (based on hardware capability)',
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },
                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'settings-poi_ids',
                    validation: 'required',
                    label: 'POI',
                    type: 'local-select',
                    isMulti: true,
                    labelForDisplay: 'poi_nick_name',
                    valueForDisplay: 'id',
                    searchQueryKey: 'results',
                    placeholder: ' POI',
                    apiUrl: '/api/v1/pois',
                    row: 1,
                    dependency: null,
                    options: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  },
                  {
                    formKey: 'settings-duration',
                    validation: 'required',
                    label: 'Duration',
                    type: 'text',
                    placeholder: 'Enter Duration',
                    row: 1,
                    styles: {},
                    dependency: null,
                    options: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  },
                  {
                    formKey: 'settings-start_time',
                    validation: 'required',
                    label: 'Start Time',
                    type: 'text',
                    placeholder: 'Ex- hh:mm',
                    row: 1,
                    styles: {},
                    dependency: null,
                    options: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  },
                  {
                    formKey: 'settings-end_time',
                    validation: 'required',
                    label: 'End Time',
                    type: 'text',
                    placeholder: 'Ex- hh:mm',
                    row: 1,
                    styles: {},
                    dependency: null,
                    options: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  }
                ]
              },
              '3': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  }
                ]
              },
              '4': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            },
            styles: {}
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: 'Material', key: 'material' },
              {
                label: 'Loading Date',
                key: 'tripStartDate',
                formatter: 'dateFormatter'
              },
              { label: 'Movement Duration', key: 'movingDuration' },
              {
                label: 'Movement Start Time',
                key: 'movementStartTime',
                formatter: 'dateFormatter'
              },
              {
                label: 'Movement End Time',
                key: 'movementEndTime',
                formatter: 'dateFormatter'
              },
              { label: 'Percentage Completed', key: 'percentageCovered' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Driver name', key: 'driver' },
              { label: 'Driver Contact', key: 'driverContact' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Stop Location', key: 'landmark' },
              {
                label: 'Last Signal',
                key: 'lastSignal',
                formatter: 'dateFormatter'
              },
              {
                label: 'IST Timestamp',
                key: 'istTimestamp',
                formatter: 'dateFormatter'
              },
              {
                label: 'Recorded At',
                key: 'recordedAt',
                formatter: 'dateFormatter'
              }
            ]
          },
          type: 'road'
        },
        TemperatureAlertSubscription: {
          formBuilderConfig: {
            label: 'Temperature Alert',
            formKey: 'TemperatureAlert',
            desc:
              'if the temperature of the package or shipment is not in temperature range , then alert will  fire',
            options: {},
            styles: {},
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-instances',
                    validation: 'required',
                    label: 'No of Instances',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: 'Select',
                    options: [
                      {
                        value: 1,
                        label: '1'
                      },
                      {
                        value: 25,
                        label: '25'
                      },
                      {
                        value: 50,
                        label: '50'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'settings-min_temperature',
                    validation: 'required',
                    label: 'Min Temperature',
                    type: 'text',
                    placeholder: 'Enter Temperature',
                    row: 1,
                    styles: {},
                    dependency: null,
                    options: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  },
                  {
                    formKey: 'settings-max_temperature',
                    validation: 'required',
                    label: 'Max Temperature',
                    type: 'text',
                    placeholder: 'Enter Temperature',
                    row: 1,
                    styles: {},
                    dependency: null,
                    options: null,
                    styling: { variant: { width: [1, 0.5, 0.25] } }
                  },
                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } }
                  }
                ]
              },
              '3': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    searchQueryKey: 'results',
                    styling: { variant: { width: [1, 0.5, 0.5] } }
                  }
                ],
                style: {}
              },
              '4': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            }
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Driver name', key: 'driver' },
              { label: 'Driver Contact', key: 'driverContact' },
              { label: 'Consigner', key: 'consignerName' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Last Signal', key: 'lastSignal' },
              { label: 'Current Landmark', key: 'currentLandmark' },
              {
                label: 'IST Timestamp',
                key: 'istTimestamp',
                formatter: 'dateFormatter'
              },
              {
                label: 'Recorded At',
                key: 'recordedAt',
                formatter: 'dateFormatter'
              }
            ]
          },
          type: 'road'
        },
        ShortShipmentAlertSubscription: {
          formBuilderConfig: {
            label: 'Short Shipment',
            formKey: 'shortShipment',
            options: {},
            desc:
              'This alert is triggered when the airline reports that load has been shipped partially.',
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },

                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            },
            styles: {}
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Shipment Id', key: '' },
              { label: 'Package Id', key: '' },
              {
                label: 'BL No.',
                key: ''
              },
              { label: 'Previous Vessel Name', key: '' },
              { label: 'Previous Vessel No.', key: '' },
              { label: 'New Vessel Name', key: '' },
              { label: 'New Vessel No.', key: '' },
              { label: 'Container No.', key: '' },
              { label: 'STD Shipping Line', key: '' },
              { label: 'New STD Shipping Line', key: '' },
              {
                label: 'Origin',
                key: ''
              },
              { label: 'Destination', key: '' },
              {
                label: 'POL',
                key: ''
              },
              { label: 'POD', key: '' },
              { label: 'Consignee', key: '' },
              { label: 'Consigner', key: '' },
              { label: 'Transporter', key: '' },
              { label: 'Timestamp', key: '' },
              { label: 'Recorded At', key: '' }
            ]
          },
          type: 'air'
        },
        PackageNonComplianceAlertSubscription: {
          formBuilderConfig: {
            label: 'Packaging Non-Compliance',
            formKey: 'packageNonCompliance',
            options: {},
            desc:
              'This alert is triggered when the handling team identifies non-compliance to the guidelines based on the material type.',
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },

                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            },
            styles: {}
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Shipment Id', key: '' },
              { label: 'Package Id', key: '' },
              {
                label: 'BL No.',
                key: ''
              },
              { label: 'Previous Vessel Name', key: '' },
              { label: 'Previous Vessel No.', key: '' },
              { label: 'New Vessel Name', key: '' },
              { label: 'New Vessel No.', key: '' },
              { label: 'Container No.', key: '' },
              { label: 'STD Shipping Line', key: '' },
              { label: 'New STD Shipping Line', key: '' },
              {
                label: 'Origin',
                key: ''
              },
              { label: 'Destination', key: '' },
              {
                label: 'POL',
                key: ''
              },
              { label: 'POD', key: '' },
              { label: 'Consignee', key: '' },
              { label: 'Consigner', key: '' },
              { label: 'Transporter', key: '' },
              { label: 'Timestamp', key: '' },
              { label: 'Recorded At', key: '' }
            ]
          },
          type: 'air'
        },
        DamageIntransitAlertSubscription: {
          formBuilderConfig: {
            label: 'Damage Intransit',
            formKey: 'damageIntransit',
            options: {},
            desc:
              'This alert is triggered when the damage to the shipment is identified while it is InTransit.',
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },

                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            },
            styles: {}
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Shipment Id', key: '' },
              { label: 'Package Id', key: '' },
              {
                label: 'BL No.',
                key: ''
              },
              { label: 'Previous Vessel Name', key: '' },
              { label: 'Previous Vessel No.', key: '' },
              { label: 'New Vessel Name', key: '' },
              { label: 'New Vessel No.', key: '' },
              { label: 'Container No.', key: '' },
              { label: 'STD Shipping Line', key: '' },
              { label: 'New STD Shipping Line', key: '' },
              {
                label: 'Origin',
                key: ''
              },
              { label: 'Destination', key: '' },
              {
                label: 'POL',
                key: ''
              },
              { label: 'POD', key: '' },
              { label: 'Consignee', key: '' },
              { label: 'Consigner', key: '' },
              { label: 'Transporter', key: '' },
              { label: 'Timestamp', key: '' },
              { label: 'Recorded At', key: '' }
            ]
          },
          type: 'air'
        },
        DocumentationDelayedAlertSubscription: {
          formBuilderConfig: {
            label: 'Documentation Delayed',
            formKey: 'documentationDelayed',
            options: {},
            desc:
              'This exception is sent to the user notifiying the delay in the shipment arrival at the destination due to hold for documentation.',
            formConfig: {
              '1': {
                data: [
                  {
                    formKey: 'is_consolidated',
                    validation: null,
                    label: 'Consolidated Alerts',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'is_enabled',
                    validation: null,
                    label: 'Enable for all child users',
                    type: 'checkbox',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: {
                      variant: { width: [1, 0.5, 0.25] },
                      styles: {
                        container: {
                          flexDirection: 'row'
                        },
                        inputContainer: {
                          width: '20px',
                          marginLeft: '10px'
                        }
                      }
                    }
                  },
                  {
                    formKey: 'settings-frequency',
                    validation: 'required',
                    label: 'Frequency',
                    type: 'creatable',
                    creatableType: 'number',
                    placeholder: ' Select',
                    unit: 'hr',
                    options: [
                      {
                        value: 0.5,
                        label: '0.5 hr'
                      },
                      {
                        value: 2,
                        label: '2 hr'
                      },
                      {
                        value: 4,
                        label: '4 hr'
                      }
                    ],
                    isMulti: false,
                    row: 1,
                    styles: {},
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    dependency: null
                  },

                  {
                    formKey: 'providers',
                    validation: 'required',
                    label: 'Select Providers',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'name',
                    valueForDisplay: 'kind',
                    searchQueryKey: 'results',
                    apiUrl: '/api/v1/alert_subscriptions/notifier_providers',
                    row: 3,
                    styling: { variant: { width: [1, 0.5, 0.25] } },
                    options: null,
                    dependency: null
                  }
                ],
                style: {}
              },
              '2': {
                data: [
                  {
                    formKey: 'alert_users',
                    validation: null,
                    label: 'Select Users',
                    type: 'local-select',
                    placeholder: 'Select',
                    isMulti: true,
                    labelForDisplay: 'full_name',
                    valueForDisplay: 'email',
                    apiUrl: '/api/v1/alert_users',
                    row: 3,
                    options: null,
                    dependency: null,
                    styling: { variant: { width: [1, 0.5, 0.5] } },
                    searchQueryKey: 'results'
                  }
                ]
              },
              '3': {
                data: [
                  {
                    dependency: null,
                    formKey: 'new_alert_users',
                    type: 'multi-add',
                    label: 'Add User',
                    validation: null,
                    row: 5,
                    options: [
                      {
                        formKey: 'full_name',
                        validation: 'required',
                        label: 'Name',
                        type: 'text',
                        placeholder: 'Enter name',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'email',
                        validation: 'email',
                        label: 'Email',
                        type: 'text',
                        placeholder: 'Enter email',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'phone_no',
                        validation: 'required',
                        label: 'Phone number',
                        type: 'text',
                        placeholder: 'Enter phone number',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      },
                      {
                        formKey: 'designation',
                        validation: 'required',
                        label: 'Designation',
                        type: 'text',
                        placeholder: 'Enter designation',
                        row: 1,
                        styles: {},
                        dependency: null,
                        options: null,
                        styling: { variant: { width: [1, 0.5, 0.25] } }
                      }
                    ]
                  }
                ],
                style: {}
              }
            },
            styles: {}
          },
          reportConfig: {
            selectedColumns: [
              { label: 'Shipment Id', key: '' },
              { label: 'Package Id', key: '' },
              {
                label: 'BL No.',
                key: ''
              },
              { label: 'Previous Vessel Name', key: '' },
              { label: 'Previous Vessel No.', key: '' },
              { label: 'New Vessel Name', key: '' },
              { label: 'New Vessel No.', key: '' },
              { label: 'Container No.', key: '' },
              { label: 'STD Shipping Line', key: '' },
              { label: 'New STD Shipping Line', key: '' },
              {
                label: 'Origin',
                key: ''
              },
              { label: 'Destination', key: '' },
              {
                label: 'POL',
                key: ''
              },
              { label: 'POD', key: '' },
              { label: 'Consignee', key: '' },
              { label: 'Consigner', key: '' },
              { label: 'Transporter', key: '' },
              { label: 'Timestamp', key: '' },
              { label: 'Recorded At', key: '' }
            ]
          },
          type: 'air'
        }
      },

      selectedAlertType: 'RolloverAlertSubscription'
    },
    children: {
      addAlert: {
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v1/alert_subscriptions`
          }
        }
      },
      deleteAlert: {
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v1/alert_subscriptions/`
          },
          constant: {
            updateStatusUrlPathName: 'manage',
            apiFailErrorMessage: 'Something went wrong ...'
          }
        }
      },
      listAlert: {
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v1/alert_subscriptions`
          },
          constant: { subscriptionListingHeader: 'Subscribed Users' },
          columns: ['row', 'alert_users', 'is_consolidated']
        }
      },
      listAlertReport: {
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v1/alerts/alert_report`
          }
        }
      },
      downloadReport: {
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v1/alerts/alert_report_stream`
          }
        }
      }
    },
    styling: {
      bodyBgColor: '#F0F2F5'
    },
    baseUrl: baseURL
  }
}

export default AlertsRenderingConfig
