import React from 'react'
import { Button, Span } from '../../main'
import { IDownload } from '../types'

function Download({ downloadCSV, downloadLoading }: IDownload) {
  if (!downloadCSV) {
    return null
  }
  return (
    <Button
      data-testid={`download`}
      disabled={downloadLoading}
      isLoading={downloadLoading}
      style={{ marginLeft: 6 }}
      variant="primary"
      onClick={downloadCSV}
    >
      <Span color="#fff">Download</Span>
      <Span color="#fff" className="hide-mobile">
        CSV
      </Span>
    </Button>
  )
}

export default Download
