import {
  FaBell,
  FaChartLine,
  FaCog,
  FaDollyFlatbed,
  FaFilePdf,
  FaHome,
  FaList,
  FaRoute,
  FaSearchLocation,
  FaTachometerAlt,
  FaTimesCircle,
  FaTruckLoading,
  MdFlag,
  TiLocationArrowOutline
} from '@fareye/utils'
import React, { ReactNode } from 'react'
import lazy from '../utility/lazy'
import OceanAlertsRenderingConfig from './LayoutRenderingConfig/OceanAlertsRenderingConfig'
import { ChildFilterRenderingConfig } from './LayoutRenderingConfig/ChildFilterRenderingConfig'
import { Profile } from '../modules/User'
import TripDetailsRenderingConfig from './LayoutRenderingConfig/TripDetailsRenderingConfig'
import Dashboard from '../modules/Dashboard'
import OceanKpi from '../modules/Kpi/containers/OceanGraphRenderer'
import {
  MultiModalDashboard,
  DataQualityDashboard
} from '@fareye/kpi-dashboard'
import versions from './npmVersions'
import { formatters } from '@fareye/reports'
import InTransitRenderingConfig from './LayoutRenderingConfig/InTransitRenderingConfig'
import InPlantRenderingConfig from './LayoutRenderingConfig/InPlantRenderingConfig'
import InPlantMapRenderingConfig from './LayoutRenderingConfig/InPlantMapRenderingConfig'
import POIRenderingConfig from './LayoutRenderingConfig/POIRenderingConfig'
import { DashBoardRenderingConfig } from './LayoutRenderingConfig/DashboardRenderingConfig'
import SUSTAINABILITY_DATA from '../utility/sustainableDashboard.json'
import INTRANSIT_DATA from '../utility/intransitDashboard.json'
import YARD_DATA from '../utility/yardDashboard.json'
import DATA_QUALITY from '../utility/dataQuality.json'
import { AiFillDashboard } from 'react-icons/ai'
import BlankImage from 'components/BlankImage'

const TripDetails = lazy(() => import('@fareye/trip-details'), 'TripDetails')
const GraphConfigEditor = React.lazy(() =>
  import('../modules/Kpi/containers/GraphConfigEditor')
)
const Kpi = React.lazy(() => import('../modules/Kpi/containers/GraphRenderer'))
const IndentDashboard = React.lazy(() =>
  import('../modules/Kpi/containers/IndentGraphRenderor')
)

const TripDetailMapper = lazy(
  () => import('@fareye/trip-details'),
  'TripDetailMapper'
)
const AllocationMapper = lazy(
  () => import('@fareye/allocation'),
  'AllocationMapper'
)
const HoldOrdersMapper = lazy(
  () => import('@fareye/allocation'),
  'HoldOrdersMapper'
)
const GroupedOrdersMapper = lazy(
  () => import('@fareye/allocation'),
  'GroupedOrdersMapper'
)
const IdealRoutesMapper = lazy(
  () => import('@fareye/ideal-routes'),
  'IdealRoutesMapper'
)
const IdealRoutesMXMapper = lazy(
  () => import('@fareye/ideal-routes'),
  'IdealRoutesMXMapper'
)
const IndentMapper = lazy(() => import('@fareye/indent'), 'IndentMapper')
const VehicleListMapper = lazy(
  () => import('@fareye/indent'),
  'VehicleListMapper'
)
const InPlantMapper = lazy(
  () => import('@fareye/inplant'),
  'InPlantPerformanceRoutes'
)
const InPlantMapMapper = lazy(() => import('@fareye/inplant-map'), 'InPlantMap')
const InTransitMapOceanMapper = lazy(
  () => import('@fareye/intransit-map'),
  'InTransitMapOceanMapper'
)

const IntransitMapper = lazy(
  () => import('@fareye/intransit'),
  'IntransitMapper'
)
const MultiModalDetailLayoutMapper = lazy(
  () => import('@fareye/intransit'),
  'MultiModalDetailLayoutMapper'
)

const MultiModalListingMapper = lazy(
  () => import('@fareye/intransit'),
  'MultiModalListingMapper'
)
const MultiModalListing = lazy(
  () => import('@fareye/intransit'),
  'MultiModelListing'
)
const MultiModalLayoutMapper = lazy(
  () => import('@fareye/intransit'),
  'MultiModalLayoutMapper'
)
const LayoutWithoutTypeMapper = lazy(
  () => import('@fareye/intransit'),
  'LayoutWithoutTypeMapper'
)
const ETAMapper = lazy(() => import('@fareye/intransit'), 'ETAMapper')
const IntransitMapMapper = lazy(
  () => import('@fareye/intransit-map'),
  'IntransitMapMapper'
)
const NearByMapper = lazy(() => import('@fareye/nearby'), 'NearByMapper')

const POIMapper = lazy(() => import('@fareye/poi'), 'Poi')
const ReportSettings = lazy(() => import('@fareye/reports'), 'ReportMapper')

const StoppageAnalysisMapper = lazy(
  () => import('@fareye/stoppage-analysis'),
  'StoppageAnalysisMapper'
)
const MultiModelHistoryMapper = lazy(
  () => import('@fareye/trip-details'),
  'MultiModelHistoryMapper'
)
const MultiModelHistory = lazy(
  () => import('@fareye/trip-details'),
  'MultiModelHistory'
)
const AlertSettings = lazy(() => import('@fareye/settings'), 'AlertSettings')
const ChildUserFilter = lazy(
  () => import('@fareye/settings'),
  'ChildUserFilter'
)
const Settings = lazy(() => import('@fareye/settings'), 'Settings')
const Utility = lazy(() => import('@fareye/settings'), 'Utility')

const RouteAndMenu = lazy(() => import('@fareye/settings'), 'RouteAndMenu')

const AlertReportMapper = lazy(
  () => import('@fareye/settings'),
  'AlertReportMapper'
)
const AlertReport = lazy(() => import('@fareye/settings'), 'AlertReport')
const MultiModelListing = lazy(
  () => import('@fareye/intransit'),
  'MultiModelListing'
)
export interface IMapComponents {
  [key: string]: {
    component: ReactNode
    icon: any
    config: any
    packageName: string
  }
}

export const mappingComponents: IMapComponents = {
  kpi: {
    component: Kpi,
    config: {},
    icon: FaChartLine,
    packageName: '@fareye/kpi'
  },
  kpiConfigure: {
    component: GraphConfigEditor,
    config: {},
    icon: FaTachometerAlt,
    packageName: '@fareye/kpi'
  },
  inplantPerformance: {
    component: InPlantMapper,
    config: InPlantRenderingConfig.inPlantPerformance,
    icon: undefined,
    packageName: '@fareye/inplant'
  },
  inplantMap: {
    component: InPlantMapMapper,
    config: InPlantMapRenderingConfig,
    icon: undefined,
    packageName: '@fareye/inplant-map'
  },
  inPlantTripDetails: {
    component: TripDetailMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/trip-details'
  },
  inplantDetails: {
    component: InPlantMapper,
    config: InPlantRenderingConfig.productWiseTatPage,
    icon: undefined,
    packageName: '@fareye/inplant'
  },
  inplantTripDetailsShare: {
    component: TripDetails,
    config: TripDetailsRenderingConfig.inPlantShare,
    icon: undefined,
    packageName: '@fareye/trip-details'
  },

  inplant: {
    component: undefined,
    config: {},
    icon: FaTruckLoading,
    packageName: '@fareye/inplant'
  },
  intransitPerformance: {
    component: IntransitMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  routePerformance: {
    component: MultiModalLayoutMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  transporterPerformance: {
    component: LayoutWithoutTypeMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  intransitTransporterPerformance: {
    component: IntransitMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  transporterDetails: {
    component: IntransitMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  routeDetail: {
    component: MultiModalDetailLayoutMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  routeDetailShipment: {
    component: MultiModalDetailLayoutMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  multiModalTransporterDetail: {
    component: MultiModalDetailLayoutMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  eta: {
    component: ETAMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  intransitMap: {
    component: IntransitMapMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit-map'
  },
  intransitMapOcean: {
    component: InTransitMapOceanMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit-map'
  },
  allTrips: {
    component: IntransitMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  intransitTripDetails: {
    component: TripDetailMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/trip-details'
  },
  intransitRouteDetails: {
    component: IntransitMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  intransitTripDetailsShare: {
    component: TripDetails,
    config: TripDetailsRenderingConfig.inTransitShare,
    icon: undefined,
    packageName: '@fareye/trip-details'
  },
  shipmentHistoryShare: {
    component: MultiModelHistory,
    config: TripDetailsRenderingConfig.shipmentHistoryShare,
    icon: undefined,
    packageName: '@fareye/trip-details'
  },
  packageHistoryShare: {
    component: MultiModelHistory,
    config: TripDetailsRenderingConfig.packageHistoryShare,
    icon: undefined,
    packageName: '@fareye/trip-details'
  },
  intransit: {
    component: undefined,
    config: {},
    icon: TiLocationArrowOutline,
    packageName: '@fareye/intransit'
  },
  indentList: {
    component: IndentMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/indent'
  },
  indentVehicleList: {
    component: VehicleListMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/indent'
  },
  indent: {
    component: undefined,
    config: {},
    icon: FaDollyFlatbed,
    packageName: '@fareye/indent'
  },
  indentDashboard: {
    component: IndentDashboard,
    config: {},
    icon: undefined,
    packageName: '@fareye/kpi'
  },
  allocation: {
    component: undefined,
    config: {},
    icon: FaList,
    packageName: '@fareye/allocation'
  },
  allocationList: {
    component: AllocationMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/allocation'
  },
  transportersAllocated: {
    component: GroupedOrdersMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/allocation'
  },
  holdOrders: {
    component: HoldOrdersMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/allocation'
  },
  poi: {
    component: POIMapper,
    config: POIRenderingConfig.poi,
    icon: MdFlag,
    packageName: '@fareye/poi'
  },
  nearby: {
    component: NearByMapper,
    config: {},
    icon: FaSearchLocation,
    packageName: '@fareye/nearby'
  },
  reportSettings: {
    component: ReportSettings,
    config: {},
    icon: FaFilePdf,
    packageName: '@fareye/reports'
  },
  stoppageAnalysis: {
    component: StoppageAnalysisMapper,
    config: {},
    icon: FaTimesCircle,
    packageName: '@fareye/stoppage-analysis'
  },
  settings: {
    component: Settings,
    config: {},
    icon: FaCog,
    packageName: '@fareye/settings'
  },
  alertSettings: {
    component: AlertSettings,
    config: OceanAlertsRenderingConfig.alertSettings,
    icon: undefined,
    packageName: '@fareye/settings'
  },
  alertReport: {
    component: AlertReport,
    config: OceanAlertsRenderingConfig.alertSettings,
    icon: undefined,
    packageName: '@fareye/settings'
  },
  alert: {
    component: undefined,
    config: {},
    icon: FaBell,
    packageName: ''
  },
  childUserFilter: {
    component: ChildUserFilter,
    config: ChildFilterRenderingConfig,
    icon: undefined,
    packageName: '@fareye/settings'
  },
  idealRoutes: {
    component: IdealRoutesMapper,
    config: {},
    icon: FaRoute,
    packageName: '@fareye/ideal-routes'
  },
  mxIdealRoutes: {
    component: IdealRoutesMXMapper,
    config: {},
    icon: FaRoute,
    packageName: '@fareye/ideal-routes'
  },
  overview: {
    component: Dashboard,
    config: {},
    icon: FaHome,
    packageName: '@fareye/tpt'
  },

  profile: {
    component: Profile,
    config: {},
    icon: undefined,
    packageName: '@fareye/tpt'
  },
  multiModelListing: {
    component: MultiModalListing,
    config: InTransitRenderingConfig.multiModelListing,
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  multiModelParent: {
    component: null,
    config: undefined,
    icon: TiLocationArrowOutline,
    packageName: '@fareye/intransit'
  },
  journeyAllTrips: {
    component: MultiModalListingMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  containerListing: {
    component: LayoutWithoutTypeMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/intransit'
  },
  packageHistory: {
    component: MultiModelHistoryMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/trip-details'
  },
  shipmentHistory: {
    component: MultiModelHistoryMapper,
    config: {},
    icon: undefined,
    packageName: '@fareye/trip-details'
  },
  multiModalKpi: {
    component: MultiModalDashboard,
    config: {},
    icon: FaChartLine,
    packageName: '@fareye/kpi-dashboard'
  },
  oceanStats: {
    component: OceanKpi,
    config: {},
    icon: FaChartLine,
    packageName: '@fareye/tpt'
  },
  configManagement: {
    component: null,
    config: {},
    icon: undefined,
    packageName: '@fareye/config-manager'
  },
  configRouteAndMenu: {
    component: RouteAndMenu,
    config: {},
    icon: undefined,
    packageName: '@fareye/settings'
  },
  reportsConfigManagement: {
    component: null,
    config: {
      columnFormatters: Object.keys(formatters),
      npmVersions: versions
    },
    icon: undefined,
    packageName: '@fareye/jsonstore-ui'
  },
  utility: {
    component: Utility,
    config: {},
    icon: undefined,
    packageName: '@fareye/settings'
  },
  kpiDashboard: {
    component: undefined,
    config: {},
    icon: FaChartLine,
    packageName: '@fareye/kpi-dashboard'
  },
  sustainabilityKpi: {
    component: MultiModalDashboard,
    config: { ...DashBoardRenderingConfig, KPI_CONFIG: SUSTAINABILITY_DATA },
    icon: undefined,
    packageName: '@fareye/kpi-dashboard'
  },
  yardKpi: {
    component: MultiModalDashboard,
    config: { ...DashBoardRenderingConfig, KPI_CONFIG: YARD_DATA },
    icon: undefined,
    packageName: '@fareye/kpi-dashboard'
  },
  intransitKpi: {
    component: MultiModalDashboard,
    config: { ...DashBoardRenderingConfig, KPI_CONFIG: INTRANSIT_DATA },
    icon: undefined,
    packageName: '@fareye/kpi-dashboard'
  },
  dataQualityKpi: {
    component: DataQualityDashboard,
    config: { ...DashBoardRenderingConfig, KPI_CONFIG: DATA_QUALITY },
    icon: AiFillDashboard,
    packageName: '@fareye/kpi-dashboard'
  },
  blankPage: {
    component: BlankImage,
    config: {},
    icon: undefined,
    packageName: '@fareye/kpi-dashboard'
  }
}
