import { lastweek } from 'utility/funcs'

export const RoutesPath = {
  HOME: '/',
  INTRANSIT: '/in-transit',
  INTRANSIT_MAP: '/in-transit/map',
  INTRANSIT_TRANSPORTERS: '/in-transit/transporters',
  INTRANSIT_TRIPS: '/in-transit/trips',
  INTRANSIT_ETA: '/in-transit/eta',
  SHARE_TRIP_INTRANSIT: '/share/in-transit/trips',
  SHARE_TRIP_INPLANT: '/share/in-plant/trips',

  TRIP_DETAILS: '/trip-details',
  POI: '/poi',
  ADD_POI: '/poi/add',
  INPLANT: '/in-plant',
  INPLANT_MAP: '/in-plant/map',
  INPLANT_TRIPS: '/in-plant/trips',
  INDENT: '/indent',
  INDENT_VEHICLE: '/indent/vehicle',
  LOGIN: '/login',
  LOGOUT: '/logout',
  KPI: '/kpi',
  NEARBY: '/nearby',
  SETTINGS: '/settings',
  ALERT_SETTINGS: '/settings/alerts',
  KPI_EDITOR: '/kpi/editor',
  ALLOCATION: '/allocation',
  REPORT: '/report',
  PROFILE: '/profile'
}

export const RoutesLabel = {
  KPI: 'KPI',
  KPI_PLAYGROUND: 'KPI Playground',
  INPLANT: 'In Plant',
  INPLANT_PERF: 'In Plant Performance',
  INPLANT_MAP: 'In Plant Map',
  INTRANSIT: 'InTransit',
  INTRANSIT_PERF: 'InTransit Performance',
  INTRANSIT_TRANSPORTERS: 'Transporters',
  INTRANSIT_ETA: 'E. T. A',
  INTRANSIT_MAP: 'InTransit Map',
  INTRANSIT_TRIPS: 'InTransit Trips',
  INDENT: 'Indent',
  INDENT_LIST: 'Indent List',
  INDENT_VEHICLE: 'Vehicle List',
  TRIP_DETAILS: 'Trip Details',
  POI: 'Poi',
  ETA: 'Eta',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  NEARBY: 'Nearby',
  ALLOCATION: 'Allocation',
  ALLOCATION_LIST: 'Allocation List',
  ALERTS: 'Alerts',
  ALERT_SETTINGS: 'Alerts',
  Report: 'report'
}
export const KPI_COLORS = {
  Ontime: ' rgb(102, 187, 106)',
  Early: ' rgb(66, 165, 245) ',
  Delayed: ' rgb(239, 83, 80)',
  pending: ' rgb(66, 165, 245)',
  pending_vehicle_qty: ' rgb(66, 165, 245)',
  pending_tonnage_qty: ' rgb(66, 165, 245)',
  completed: ' rgb(76, 175, 80)',
  successfully_reported_vehicle_qty: ' rgb(255, 87, 34)',
  successfully_reported_tonnage_qty: ' rgb(255, 87, 34)',
  short_closed: 'rgb(149, 117, 205)',
  cancelled: ' rgb(240, 98, 146)',
  cancelled_vehicle_qty: 'rgb(240, 98, 146)',
  cancelled_tonnage_qty: 'rgb(240, 98, 146)',
  placed: ' rgb(156, 204, 101)',
  placed_vehicle_qty: ' rgb(102, 187, 106)',
  placed_tonnage_qty: ' rgb(102, 187, 106)',
  confirmed: 'rgb(38, 166, 154)',
  confirmed_vehicle_qty: 'rgb(38, 166, 154)',
  confirmed_tonnage_qty: ' rgb(38, 166, 154)',
  delayed: ' rgb(194, 24, 91)',
  elapsed: 'rgb(255, 183, 77)',
  elapsed_vehicle_qty: 'rgb(255, 183, 77)',
  elapsed_tonnage_qty: 'rgb(255, 183, 77)',
  exited_vehicle_qty: 'rgb(149, 117, 205)',
  exited_tonnage_qty: 'rgb(149, 117, 205)',
  reported_vehicle_qty: 'rgb(239, 83, 80)',
  reported_tonnage_qty: 'rgb(239, 83, 80)',
  Unknown: 'rgb(216, 27, 96)',
  untracked: 'rgb(216, 27, 96)',
  // MultiModal Colours
  untracked_state: 'rgb(216, 27, 96)',
  untracked_status: 'rgb(216, 27, 96)',
  early: 'rgb(66, 165, 245) ',
  on_time: 'rgb(102, 187, 106)',
  OnTime: 'rgb(102, 187, 106)',
  On_time: 'rgb(102, 187, 106)',
  at_transit_jn: '',
  at_end_jn: '',
  enroute: '',
  at_start_jn: '',
  at_pol: 'rgb(63, 81, 181)',
  at_pod: 'rgb(175, 180, 43)',
  at_transshipment_port: 'rgb(171, 71, 188)',
  at_stop: 'rgb(255, 87, 34)',
  pick_up_executive_assigned: '',
  out_for_pickup: '',
  in_transit: '#FFA900',
  delivery_executive_assigned: '',
  out_for_delivery: '',
  delivered: '',
  at_loading: '#C1C1C1'
}

export const AUTHORIZATION_HEADER = 'Authorization'
export const AUTH_TOKEN = '@Fareye:AuthToken'
export const HEADER_INFLECTION = {
  headers: { 'Key-Inflection': 'camel', 'X-Change-Case': true }
}
export const IN_PLANT = 'inplant'

export const JIRA_URL =
  'https://fareye.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-lxsann/b/23/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=b1f1ba67'

export const ENVIRONMENT = 'environment'

export const GRAPH_DATA_MAPPING = {
  condition: {
    value: 'value',

    label: 'label'
  },
  dataSource: {
    label: 'label',

    value: 'value'
  },
  dimension: {
    value: 'value',

    label: 'label'
  },
  filterType: {
    value: 'value',

    label: 'label'
  },
  filterKey: {
    value: 'value',

    label: 'label'
  },
  filterValue: {
    label: 'label',

    value: 'value'
  },
  metricKey: {
    value: 'value',

    label: 'label'
  },
  metricType: {
    value: 'value',

    label: 'label'
  },
  sortKey: {
    value: 'value',

    label: 'label'
  },
  sortType: {
    value: 'value',

    label: 'label'
  },
  pageIdList: {
    value: 'key',

    label: 'value'
  },
  unit: {
    value: 'key',

    label: 'value'
  }
}
export const KPI_CONFIG = [
  {
    id: 306,
    user_id: null,
    name: 'Loading Performance',
    data: {
      graphConfig: {
        dataSource: {
          label: 'Consigner Data',
          value: 'ConsignerTrip'
        },
        chartTitle: 'Loading Performance',
        chartDescription: 'Showing Loading performance for Plants',
        chartType: 'line',
        dimensionDescription: 'Loading TAT',
        metricDescription: 'Date',
        dimensions: [
          {
            dimension: {
              label: 'Day',
              value: 'day'
            },
            breakdownDimension: {
              label: 'Origin',
              value: 'origin'
            },
            dimensionUnit: {
              key: 'date',
              value: 'date'
            },
            isDrillDown: true
          }
        ],
        metricList: [
          {
            aggKey: {
              label: 'Loading Performance',
              value: 'loading_tat'
            },
            aggType: {
              value: 'avg',
              label: 'Count'
            },
            metricUnit: {
              key: 'minute',
              value: 'minute'
            }
          }
        ],
        sortKey: {
          label: 'Loading Performance',
          value: 'loading_tat'
        },
        sortType: {
          label: 'Ascending',
          value: 'asc'
        },
        maxNoOfBars: 30,
        compactNo: false,
        decimalPrecision: '',
        filter: {
          filterData: [[]],
          name: '',
          dataSource: null
        },
        pageIdList: null,
        hideSort: true
      },
      style: {
        graphScreenRatio: 1.7777777777777777
      },
      order: 1
    },
    deleted_at: null,
    created_at: '2020-02-12T08:08:46.000Z',
    updated_at: '2020-05-05T16:33:39.000Z',
    sub_type: 'kpi',
    page_id: null
  },
  {
    id: 307,
    user_id: null,
    name: 'UnLoading Performance',
    data: {
      graphConfig: {
        dataSource: {
          label: 'Consigner Data',
          value: 'ConsignerTrip'
        },
        chartTitle: 'UnLoading Performance',
        chartDescription: 'Showing UnLoading performance for Destination',
        chartType: 'line',
        dimensionDescription: 'Unloading TAT',
        metricDescription: 'Date',
        dimensions: [
          {
            dimension: {
              label: 'Day',
              value: 'day'
            },
            breakdownDimension: {
              label: 'Destination',
              value: 'destination'
            },
            dimensionUnit: {
              key: 'date',
              value: 'date'
            },
            isDrillDown: true
          }
        ],
        metricList: [
          {
            aggKey: {
              label: 'Loading Performance',
              value: 'unloading_tat'
            },
            aggType: {
              value: 'avg',
              label: 'Count'
            },
            metricUnit: {
              key: 'minute',
              value: 'minute'
            }
          }
        ],
        sortKey: {
          label: 'Loading Performance',
          value: 'unloading_tat'
        },
        sortType: {
          label: 'Ascending',
          value: 'asc'
        },
        maxNoOfBars: 30,
        compactNo: false,
        decimalPrecision: '',
        filter: {
          filterData: [[]],
          name: '',
          dataSource: null
        },
        pageIdList: null,
        hideSort: true
      },
      style: {
        graphScreenRatio: 1.7777777777777777
      },
      order: 2
    },
    deleted_at: null,
    created_at: '2020-02-12T08:27:31.000Z',
    updated_at: '2020-05-05T16:35:57.000Z',
    sub_type: 'kpi',
    page_id: null
  },
  {
    id: 313,
    user_id: null,
    name: 'Trip State',
    data: {
      graphConfig: {
        dataSource: {
          label: 'Consigner Data',
          value: 'ConsignerTrip'
        },
        chartTitle: 'Trip State ( At Primary loading , Enroute , etc) ',
        chartDescription: 'Showing status distribution for trips',
        chartType: 'pie',
        dimensionDescription: 'Vehicle Count ',
        metricDescription: 'State',
        dimensions: [
          {
            dimension: {
              label: 'State',
              value: 'state'
            },
            drillDownDimensions: [
              {
                drillDownDimension: {
                  label: 'Transporter',
                  value: 'transporter'
                },
                chartType: 'bar',
                metricDescription: 'Transporter'
              }
            ],
            dimensionUnit: {
              key: 'string',
              value: 'string'
            },
            isDrillDown: true
          },
          {
            dimension: {
              label: 'Route',
              value: 'route'
            },
            breakdownDimension: {
              label: 'State',
              value: 'state'
            },
            drillDownDimensions: [
              {
                drillDownDimension: {
                  label: 'Transporter',
                  value: 'transporter'
                },
                breakdownDimension: {
                  label: 'State',
                  value: 'state'
                },
                chartType: 'bar',
                metricDescription: 'Transporter'
              }
            ],
            dimensionUnit: {
              key: 'string',
              value: 'string'
            },
            isDrillDown: true,
            chartType: 'bar',
            metricDescription: 'Route'
          },
          {
            dimension: {
              label: 'Material',
              value: 'material'
            },
            breakdownDimension: {
              label: 'State',
              value: 'state'
            },
            drillDownDimensions: [
              {
                drillDownDimension: {
                  label: 'Transporter',
                  value: 'transporter'
                },
                breakdownDimension: {
                  label: 'State',
                  value: 'state'
                },
                chartType: 'bar',
                metricDescription: 'Transporter'
              }
            ],
            dimensionUnit: {
              key: 'string',
              value: 'string'
            },
            isDrillDown: true,
            chartType: 'bar',
            metricDescription: 'Material'
          }
        ],
        metricList: [
          {
            aggKey: {
              label: 'Transit Performance',
              value: 'value_count'
            },
            aggType: {
              value: 'avg',
              label: 'Count'
            },
            metricUnit: {
              key: 'number',
              value: 'number'
            }
          }
        ],
        sortKey: {
          label: 'Value Count',
          value: 'value_count'
        },
        maxNoOfBars: 30,
        compactNo: false,
        decimalPrecision: '',
        filter: {
          filterData: null,
          name: '',
          dataSource: null
        },
        pageIdList: null
      },
      style: {
        graphScreenRatio: 1.7777777777777777
      },
      order: 3
    },
    deleted_at: null,
    created_at: '2020-02-12T08:33:22.000Z',
    updated_at: '2020-05-28T12:09:42.000Z',
    sub_type: 'kpi',
    page_id: null
  },
  {
    id: 310,
    user_id: null,
    name: 'Vendor Performance',
    data: {
      graphConfig: {
        dataSource: {
          label: 'Consigner Data',
          value: 'ConsignerTrip'
        },
        chartTitle: 'Vendor Performance',
        chartDescription: 'Showing Trip Status across transporters',
        chartType: 'bar',
        layout: 'horizontal',
        dimensionDescription: 'Vehicle Count ',
        metricDescription: 'Transporters ',
        dimensions: [
          {
            dimension: {
              label: 'Transporter',
              value: 'transporter'
            },
            breakdownDimension: {
              label: 'Status',
              value: 'status'
            },
            drillDownDimensions: null,
            dimensionUnit: {
              key: 'string',
              value: 'string'
            },
            isDrillDown: true
          }
        ],
        metricList: [
          {
            aggKey: {
              label: 'Transit Performance',
              value: 'value_count'
            },
            aggType: {
              value: 'avg',
              label: 'Count'
            },
            metricUnit: {
              key: 'number',
              value: 'number'
            }
          }
        ],
        sortKey: {
          label: 'Loading Performance',
          value: 'value_count'
        },
        sortType: {
          label: 'Descending',
          value: 'desc'
        },
        maxNoOfBars: 30,
        compactNo: false,
        decimalPrecision: '',
        filter: {
          filterData: [[]],
          name: '',
          dataSource: null
        },
        pageIdList: null
      },
      style: {
        graphScreenRatio: 1.7777777777777777
      },
      order: 4
    },
    deleted_at: null,
    created_at: '2020-02-12T08:30:33.000Z',
    updated_at: '2020-05-28T12:01:14.000Z',
    sub_type: 'kpi',
    page_id: null
  },
  {
    id: 314,
    user_id: null,
    name: 'Transit Performance',
    data: {
      graphConfig: {
        dataSource: {
          label: 'Consigner Data',
          value: 'ConsignerTrip'
        },
        chartTitle: 'Transit Performance',
        chartDescription: 'Showing Transit performance for Consigners Trip',
        chartType: 'pie',
        dimensionDescription: 'Status Count ',
        metricDescription: 'Routes',
        dimensions: [
          {
            dimension: {
              label: 'Status',
              value: 'status'
            },
            dimensionUnit: {
              key: 'string',
              value: 'string'
            },
            drillDownDimensions: [
              {
                drillDownDimension: {
                  label: 'Transporter',
                  value: 'transporter'
                },
                breakdownDimension: {
                  label: 'Status',
                  value: 'status'
                },
                metricDescription: 'Transporter',
                chartType: 'bar',
                isDrillDown: true
              }
            ],
            isDrillDown: true
          },
          {
            dimension: {
              label: 'Transporter',
              value: 'transporter'
            },
            breakdownDimension: {
              label: 'Status',
              value: 'status'
            },
            drillDownDimensions: [
              {
                drillDownDimension: {
                  label: 'Material',
                  value: 'material'
                },
                breakdownDimension: {
                  label: 'Status',
                  value: 'status'
                },
                metricDescription: 'Material',
                chartType: 'bar',
                isDrillDown: true
              }
            ],
            dimensionUnit: {
              key: 'string',
              value: 'string'
            },
            chartType: 'bar',
            metricDescription: 'Transporter',
            isDrillDown: true
          },
          {
            dimension: {
              label: 'Material',
              value: 'material'
            },
            breakdownDimension: {
              label: 'Status',
              value: 'status'
            },
            dimensionUnit: {
              key: 'string',
              value: 'string'
            },
            chartType: 'bar',
            metricDescription: 'Materials'
          }
        ],
        metricList: [
          {
            aggKey: {
              label: 'Transit Performance',
              value: 'value_count'
            },
            aggType: {
              value: 'avg',
              label: 'Count'
            },
            metricUnit: {
              key: 'number',
              value: 'number'
            }
          }
        ],
        sortKey: {
          label: 'Values',
          value: 'value_count'
        },
        maxNoOfBars: 30,
        compactNo: false,
        decimalPrecision: '',
        filter: {
          filterData: [[]],
          name: '',
          dataSource: null
        },
        pageIdList: null
      },
      style: {
        graphScreenRatio: 1.7777777777777777
      },
      order: 5
    },
    deleted_at: null,
    created_at: '2020-02-12T08:34:16.000Z',
    updated_at: '2020-05-28T12:05:24.000Z',
    sub_type: 'kpi',
    page_id: null
  },
  {
    id: 311,
    user_id: null,
    name: 'Route Performance',
    data: {
      graphConfig: {
        dataSource: {
          label: 'Consigner Data',
          value: 'ConsignerTrip'
        },
        chartTitle: 'Route Performance',
        chartDescription: 'Showing Trip Status across Routes',
        chartType: 'bar',
        layout: 'horizontal',
        dimensionDescription: 'Vehicle Count ',
        metricDescription: 'Route ',
        dimensions: [
          {
            dimension: {
              label: 'Route',
              value: 'route'
            },
            breakdownDimension: {
              label: 'Status',
              value: 'status'
            },
            drillDownDimensions: [
              {
                drillDownDimension: {
                  label: 'transporter',
                  value: 'transporter'
                },
                metricDescription: 'Transporters',
                breakdownDimension: {
                  label: 'Status',
                  value: 'status'
                },
                chartType: 'bar',
                isDrillDown: true
              }
            ],
            dimensionUnit: {
              key: 'string',
              value: 'string'
            },
            isDrillDown: true
          }
        ],
        metricList: [
          {
            aggKey: {
              label: 'Transit Performance',
              value: 'value_count'
            },
            aggType: {
              value: 'avg',
              label: 'Count'
            },
            metricUnit: {
              key: 'number',
              value: 'number'
            }
          }
        ],
        sortKey: {
          label: 'Loading Performance',
          value: 'value_count'
        },
        sortType: {
          label: 'Descending',
          value: 'desc'
        },
        maxNoOfBars: 30,
        compactNo: false,
        decimalPrecision: '',
        filter: {
          filterData: [[]],
          name: '',
          dataSource: null
        },
        pageIdList: null
      },
      style: {
        graphScreenRatio: 1.7777777777777777
      },
      order: 6
    },
    deleted_at: null,
    created_at: '2020-02-12T08:31:50.000Z',
    updated_at: '2020-05-28T11:52:31.000Z',
    sub_type: 'kpi',
    page_id: null
  }
]

export const SkipPostURL = [
  '/api/v1/es/kpi_aggs',
  '/api/v1/es/kpi_results',
  '/api/v1/consigner/add_route_mapping',
  '/auth/users/sign_in',
  '/api/v3/journeys/list',
  '/api/v3/journeys/route_performance',
  '/api/v3/journeys/transporter_performance',
  '/api/v3/journeys/in_transit_map',
  '/api/v3/reports/journey',
  '/api/v3/journeys/route_performance_details',
  '/api/v3/journeys/transporter_performance_details'
]

export const SHIPMENT_TYPES = [
  { label: 'Package', value: 'Shipment', dataSource: 'Shipment' },
  { label: 'Shipment', value: 'Journey', dataSource: 'Journey' }
  // { label: 'Item', value: 'item', dataSource: 'Item' }
]

export const DailyDispatch = {
  id: 309,
  user_id: null,
  name: 'Daily Dispatch',
  data: {
    graphConfig: {
      dataSource: {
        label: 'Shipment',
        value: 'Shipment'
      },
      chartTitle: 'Daily Dispatch',
      chartDescription: 'Showing day wise dispatch across plants',
      chartType: 'line',
      dimensionDescription: 'Vehicle Count ',
      metricDescription: 'Date ',
      dimensions: [
        {
          dimension: {
            label: 'Day-Plant Wise',
            value: 'day'
          },
          breakdownDimension: {
            label: 'Plant',
            value: 'pol_name'
          },
          dimensionUnit: {
            key: 'date',
            value: 'date'
          }
        },
        {
          dimension: {
            label: 'Plant Month Wise',
            value: 'month'
          },
          breakdownDimension: {
            label: 'Plant',
            value: 'pol_name'
          },
          dimensionUnit: {
            key: 'date',
            value: 'date'
          }
        },
        {
          dimension: {
            label: 'Top Destination',
            value: 'pod_name'
          },
          chartType: 'bar',
          metricDescription: 'Destination',
          dimensionUnit: {
            key: 'string',
            value: 'string'
          }
        },
        {
          dimension: {
            label: 'Material',
            value: 'material'
          },
          chartType: 'bar',
          metricDescription: 'Material',
          dimensionUnit: {
            key: 'number',
            value: 'number'
          }
        }
      ],
      metricList: [
        {
          aggKey: {
            label: 'Transit Performance',
            value: 'value_count'
          },
          aggType: {
            value: 'avg',
            label: 'Count'
          },
          metricUnit: {
            key: 'number',
            value: 'number'
          }
        }
      ],
      sortKey: {
        label: 'Loading Performance',
        value: 'value_count'
      },
      maxNoOfBars: 30,
      compactNo: false,
      decimalPrecision: '',
      filter: {
        filterData: [[]],
        name: '',
        dataSource: null
      },
      pageIdList: null,
      hideSort: true
    },
    style: {
      graphScreenRatio: 3
    },
    order: 7
  },
  deleted_at: null,
  created_at: '2020-02-12T08:29:49.000Z',
  updated_at: '2020-07-14T18:10:18.000Z',
  sub_type: 'kpi',
  page_id: null
}

export const ScoreCardConfig = {
  shipping: {
    graphConfig: {
      dataSource: {
        label: 'Shipment',
        value: 'Shipment'
      },
      chartTitle: 'On Time Shipping',
      chartDescription: 'Showing Transporter Status distribution',
      chartType: 'scorecard',
      layout: 'horizontal',
      dimensionDescription: 'Status Count ',
      metricDescription: 'Transporter',
      hideDimension: true,
      benchmarkDimension: {},
      metric: {
        aggKey: {
          label: 'Transit Performance',
          value: 'shipping_tat'
        },
        aggType: {
          value: 'range',
          label: 'Range'
        },
        metricUnit: {
          key: 'number',
          value: 'number'
        },
        value: [
          {
            key: 'Early',
            to: -1
          },
          {
            key: 'Ontime',
            from: 0,
            to: 1
          },
          {
            key: 'Delay',
            from: 2
          }
        ]
      },
      sortKey: {
        label: 'Values',
        value: 'value_count'
      },
      maxNoOfBars: 30,
      compactNo: false,
      decimalPrecision: '',
      filter: [],
      pageIdList: null
    },
    style: {
      graphScreenRatio: 1.7777777777777777
    },
    order: 5
  },
  delivery: {
    graphConfig: {
      dataSource: {
        label: 'Shipment',
        value: 'Shipment'
      },
      chartTitle: 'On Time Delivery',
      chartDescription: 'Showing Transporter Status distribution',
      chartType: 'scorecard',
      layout: 'horizontal',
      hideDimension: true,
      benchmarkDimension: {},
      dimensionDescription: 'Status Count ',
      metricDescription: 'Transporter',
      metric: {
        aggKey: {
          label: 'Transit Performance',
          value: 'delivery_tat'
        },
        aggType: {
          value: 'range',
          label: 'Range'
        },
        metricUnit: {
          key: 'number',
          value: 'number'
        },
        value: [
          {
            key: 'Early',
            to: -1
          },
          {
            key: 'Ontime',
            from: 0,
            to: 1
          },
          {
            key: 'Delay',
            from: 2
          }
        ]
      },
      sortKey: {
        label: 'Values',
        value: 'value_count'
      },
      maxNoOfBars: 30,
      compactNo: false,
      decimalPrecision: '',
      filter: [],
      pageIdList: null
    },
    style: {
      graphScreenRatio: 1.7777777777777777
    },
    order: 5
  }
}

export const NEWS_DATA = [
  {
    source: {
      id: 'cnn',
      name: 'CNN'
    },
    author: 'Ellie Kaufman, CNN',
    title:
      'US troops around the globe will get Thanksgiving meal despite supply chain obstacles',
    description:
      "Almost 60,000 pounds of roasted turkeys, over 38,000 pounds of sweet potatoes and over 68,000 pies and cakes were shipped around the world by the Department of Defense's Logistics Agency to make sure American service members stationed in the US and across the…",
    url:
      'https://www.cnn.com/2021/11/25/politics/troops-thanksgiving-meals/index.html',
    urlToImage:
      'https://cdn.cnn.com/cnnnext/dam/assets/211124151547-soldiers-thanksgiving-fort-bragg-1122-super-tease.jpg',
    publishedAt: '2021-11-25T13:07:14Z',
    content:
      "(CNN)Almost 60,000 pounds of roasted turkeys, over 38,000 pounds of sweet potatoes and over 68,000 pies and cakes were shipped around the world by the Department of Defense's Logistics Agency to make… [+2662 chars]"
  },
  {
    source: {
      id: 'reuters',
      name: 'Reuters'
    },
    author: null,
    title:
      'As COVID-19 vaccine deliveries to continent increase, logistics hampers inoculation drives-Africa CDC - Reuters',
    description:
      "As deliveries of COVID-19 vaccines to Africa finally pick up, many nations are struggling with the logistics of accelerating their inoculation campaigns, the head of Africa's disease control body said on Thursday.",
    url:
      'https://www.reuters.com/world/africa/covid-19-vaccine-deliveries-continent-increase-logistics-hampers-inoculation-2021-11-25/',
    urlToImage:
      'https://www.reuters.com/resizer/ACwNwEL6VuLYdyBYd06NvEYomjY=/1200x628/smart/filters:quality(80)/cloudfront-us-east-2.images.arcpublishing.com/reuters/IYRFFIUYYVJ5PJY7FOWT2TKKLQ.jpg',
    publishedAt: '2021-11-25T10:39:00Z',
    content:
      'NAIROBI, Nov 25 (Reuters) - As deliveries of COVID-19 vaccines to Africa finally pick up, many nations are struggling with the logistics of accelerating their inoculation campaigns, the head of Afric… [+540 chars]'
  },
  {
    source: {
      id: 'reuters',
      name: 'Reuters'
    },
    author: null,
    title:
      'Burkina army says three soldiers killed in combat with militants - Reuters',
    description:
      'Three Burkina Faso soldiers died and 11 militants were killed during an attack on the troops in the north of the Sahel West African nation on Wednesday, the army said in a statement.',
    url:
      'https://www.reuters.com/world/africa/burkina-army-says-three-soldiers-killed-combat-with-militants-2021-11-25/',
    urlToImage:
      'https://www.reuters.com/pf/resources/images/reuters/reuters-default.png?d=60',
    publishedAt: '2021-11-25T09:36:00Z',
    content:
      'OUAGADOUGOU, Nov 25 (Reuters) - Three Burkina Faso soldiers died and 11 militants were killed during an attack on the troops in the north of the Sahel West African nation on Wednesday, the army said … [+1716 chars]'
  },
  {
    source: {
      id: 'reuters',
      name: 'Reuters'
    },
    author: null,
    title: 'Baltic index rises as smaller vessels lend support - Reuters',
    description:
      "The Baltic Exchange's dry bulk sea freight index rose on Thursday, buoyed by gains for panamax and supramax vessels that countered a dip in rates for the larger capesize segment.",
    url:
      'https://www.reuters.com/markets/asia/baltic-index-rises-smaller-vessels-lend-support-2021-11-25/',
    urlToImage:
      'https://www.reuters.com/pf/resources/images/reuters/reuters-default.png?d=60',
    publishedAt: '2021-11-25T14:18:00Z',
    content:
      "Nov 25 (Reuters) - The Baltic Exchange's dry bulk sea freight index rose on Thursday, buoyed by gains for panamax and supramax vessels that countered a dip in rates for the larger capesize segment.\r\n… [+1827 chars]"
  },
  {
    source: {
      id: 'reuters',
      name: 'Reuters'
    },
    author: null,
    title: 'Morikawa, McIlroy to start 2022 season in Abu Dhabi - Reuters',
    description:
      'World number two Collin Morikawa and four-time major winner Rory McIlroy will begin their 2022 DP World Tour season at the Abu Dhabi HSBC Championship in January.',
    url:
      'https://www.reuters.com/lifestyle/sports/morikawa-mcilroy-start-2022-season-abu-dhabi-2021-11-25/',
    urlToImage:
      'https://www.reuters.com/resizer/2Li0mDh-CFVgx-3w5HYyNGi8cDM=/1200x628/smart/filters:quality(80)/cloudfront-us-east-2.images.arcpublishing.com/reuters/24BLQRV4JNPL5K35Q3IXXWXQSU.jpg',
    publishedAt: '2021-11-25T08:06:00Z',
    content:
      'Nov 25 (Reuters) - World number two Collin Morikawa and four-time major winner Rory McIlroy will begin their 2022 DP World Tour season at the Abu Dhabi HSBC Championship in January.\r\nThe 24-year-old … [+1628 chars]'
  },
  {
    source: {
      id: 'reuters',
      name: 'Reuters'
    },
    author: null,
    title:
      'European countries seek more COVID booster shots, vaccinations for children - Reuters',
    description:
      'European countries such as France expanded COVID-19 booster vaccinations and started plans to get shots to children as young as five on Thursday as the continent battles a surge in coronavirus cases and worries grow about the economic fallout.',
    url:
      'https://www.reuters.com/business/healthcare-pharmaceuticals/european-countries-seek-more-covid-booster-shots-vaccinations-children-2021-11-25/',
    urlToImage:
      'https://www.reuters.com/resizer/7xvvMX8YY6v5X-433yljdn5emZo=/1200x628/smart/filters:quality(80)/cloudfront-us-east-2.images.arcpublishing.com/reuters/YI7B4UAVWVPAJBICYIUQYLYUMY.jpg',
    publishedAt: '2021-11-25T15:17:00Z',
    content:
      'Nov 25 (Reuters) - European countries such as France expanded COVID-19 booster vaccinations and started plans to get shots to children as young as five on Thursday as the continent battles a surge in… [+4571 chars]'
  },
  {
    source: {
      id: null,
      name: 'Zacks.com'
    },
    author: 'Anindya Barman',
    title: 'U.S. Steel Imports Slump in October As Prices Cool Off',
    description:
      'Despite the slowdown in the automotive space due to the chip crunch, strong demand elsewhere along with supply disruptions due to outages are likely to lend support to steel prices...',
    url:
      'http://www.zacks.com/stock/news/1831699/u-s-steel-imports-slump-in-october-as-prices-cool-off?cid=CS-ENTREPRENEUR-FT-analyst_blog|industry_focus-1831699',
    urlToImage:
      'https://assets.entrepreneur.com/providers/zacks/hero-image-zacks-399153.jpeg',
    publishedAt: '2021-11-25T11:00:00Z',
    content:
      'U.S. steel imports dropped in October on a monthly comparison basis, but are up year over year for the first ten months of the year according to the latest American Iron and Steel Institute ("AISI") … [+6962 chars]'
  },
  {
    source: {
      id: null,
      name: 'Zacks.com'
    },
    author: 'Zacks Equity Research',
    title: 'Brand Strength to Offset Monster Beverages (MNST) Cost Woes?',
    description:
      'Monster Beverages (MNST) is likely to keep gaining from strength in the energy drinks category and commitment toward product innovation. Supply & logistics woes are likely to persist in the...',
    url:
      'http://www.zacks.com/stock/news/1831829/brand-strength-to-offset-monster-beverages-mnst-cost-woes?cid=CS-ENTREPRENEUR-FT-analyst_blog|rank_focused-1831829',
    urlToImage:
      'https://assets.entrepreneur.com/providers/zacks/hero-image-zacks-399191.jpeg',
    publishedAt: '2021-11-25T14:48:00Z',
    content:
      'Monster Beverages CorporationMNST remains well positioned for long-term growth on the back of continued momentum in the energy drinks business and various product launches across domestic and interna… [+6847 chars]'
  },
  {
    source: {
      id: null,
      name: 'Zacks.com'
    },
    author: 'Zacks Equity Research',
    title: 'Improving Freight Market Aids Werner (WERN) Amid High Costs',
    description:
      'Rising freight volume and shareholder-friendly measures boost Werner (WERN). However, escalating expenses are worrisome.',
    url:
      'http://www.zacks.com/stock/news/1831812/improving-freight-market-aids-werner-wern-amid-high-costs?cid=CS-ENTREPRENEUR-FT-analyst_blog|zer_report_update-1831812',
    urlToImage:
      'https://assets.entrepreneur.com/providers/zacks/hero-image-zacks-399197.jpeg',
    publishedAt: '2021-11-25T14:17:00Z',
    content:
      'We have recently updated a report on Werner Enterprises, Inc.WERN.\r\nThe long-term expected earnings per share (three to five years) growth rate for Werner is pegged at 11.1%. The stock has gained 15.… [+4546 chars]'
  },
  {
    source: {
      id: null,
      name: 'Zacks.com'
    },
    author: 'Zacks Equity Research',
    title: 'Global Ship Lease (GSL) Cheers Investors With 50% Dividend Hike',
    description:
      "Global Ship Lease's (GSL) shareholder-friendly approach is reflected in its decision to raise the quarterly dividend.",
    url:
      'http://www.zacks.com/stock/news/1832051/global-ship-lease-gsl-cheers-investors-with-50-dividend-hike?cid=CS-ENTREPRENEUR-FT-analyst_blog|company_news_-_corporate_actions-1832051',
    urlToImage:
      'https://assets.entrepreneur.com/providers/zacks/hero-image-zacks-399255.jpeg',
    publishedAt: '2021-11-25T18:00:00Z',
    content:
      'Driven by the gradual uptick in economic activities, Global Ship LeaseGSL announced a 50% hike in its quarterly dividend, taking the total to 37.5 cents per share (annualized $1.50). The increased di… [+3997 chars]'
  },
  {
    source: {
      id: null,
      name: 'Zacks.com'
    },
    author: 'Zacks Equity Research',
    title: 'Plug Power (PLUG) Secures Electrolyzer Deal From Fertiglobe',
    description:
      'Plug Power (PLUG) enters into a deal with Fertiglobe and its partners to provide electrolysis technology for a 100 megawatts electrolyzer. Its Applied Cryo buyout will boost its green hydrogen...',
    url:
      'http://www.zacks.com/stock/news/1831798/plug-power-plug-secures-electrolyzer-deal-from-fertiglobe?cid=CS-ENTREPRENEUR-FT-analyst_blog|company_news_-_corporate_actions-1831798',
    urlToImage:
      'https://assets.entrepreneur.com/providers/zacks/hero-image-zacks-399199.jpeg',
    publishedAt: '2021-11-25T14:10:00Z',
    content:
      'Plug Power Inc.PLUG yesterday announced that it has clinched a contract from Fertiglobe and its partners, Sovereign Fund of Egypt, Scatec ASA and Orascom Construction to provide electrolysis technolo… [+4729 chars]'
  },
  {
    source: {
      id: null,
      name: 'Zacks.com'
    },
    author: 'Zacks Equity Research',
    title: '3 Reasons Growth Investors Will Love ArcBest (ARCB)',
    description:
      'ArcBest (ARCB) possesses solid growth attributes, which could help it handily outperform the market.',
    url:
      'http://www.zacks.com/stock/news/1832046/3-reasons-growth-investors-will-love-arcbest-arcb?cid=CS-ENTREPRENEUR-FT-tale_of_the_tape|yseop_template_11_growth-1832046',
    urlToImage:
      'https://assets.entrepreneur.com/providers/zacks/hero-image-zacks-399240.jpeg',
    publishedAt: '2021-11-25T17:45:07Z',
    content:
      "Growth stocks are attractive to many investors, as above-average financial growth helps these stocks easily grab the market's attention and produce exceptional returns. But finding a great growth sto… [+4150 chars]"
  },
  {
    source: {
      id: null,
      name: 'Zacks.com'
    },
    author: 'Zacks Equity Research',
    title: "Here's Why You Should Retain Avery Dennison (AVY) For Now",
    description:
      "Avery Dennison (AVY) is worth retaining in one's portfolio owing to high packaging demand for essential products, investment in high-value products and positive projections.",
    url:
      'http://www.zacks.com/stock/news/1831815/here-s-why-you-should-retain-avery-dennison-avy-for-now?cid=CS-ENTREPRENEUR-FT-analyst_blog|rank_focused-1831815',
    urlToImage:
      'https://assets.entrepreneur.com/providers/zacks/hero-image-zacks-399195.jpeg',
    publishedAt: '2021-11-25T14:28:00Z',
    content:
      'Avery Dennison CorporationAVY is benefiting from the pandemic-driven demand for essential categories and e-commerce growth. Strong growth in high-value products, anticipated benefits from cost-reduct… [+6924 chars]'
  }
]

export const dropdownValues = [
  {
    label: `Last one week (${lastweek(7)} - ${lastweek(1)})`,
    value: 'lastWeek'
  },
  {
    label: 'Today',
    value: 'today'
  }
]

export const shipments1 = [
  {
    no: '324',
    text: 'Shipments',
    percent: 0.5,
    color: '#212121'
  },
  {
    no: '104',
    text: 'On Time',
    percent: -2.5,
    color: '#279B4E'
  },
  {
    no: '110',
    text: 'Early',
    percent: 3.5,
    color: '#00539A'
  },
  {
    no: '110',
    text: 'Delay',
    percent: -1.5,
    color: '#C84031'
  }
]

export const shipments2 = [
  {
    no: '512',
    text: 'Shipments',
    percent: 1.5,
    color: '#212121'
  },
  {
    no: '123',
    text: 'On Time',
    percent: 4.5,
    color: '#279B4E'
  },
  {
    no: '321',
    text: 'Early',
    percent: -1.5,
    color: '#00539A'
  },
  {
    no: '193',
    text: 'Delay',
    percent: 5.5,
    color: '#C84031'
  }
]

export const transportModes1 = [
  {
    no: '60%',
    text: 'Road',
    percent: 1.5,
    color: '#212121'
  },
  {
    no: '10%',
    text: 'Rail',
    percent: -0.3,
    color: '#212121'
  },
  {
    no: '12%',
    text: 'Ocean',
    percent: 0.5,
    color: '#212121'
  },
  {
    no: '18%',
    text: 'Air',
    percent: 0.5,
    color: '#212121'
  }
]

export const transportModes2 = [
  {
    no: '50%',
    text: 'Road',
    percent: -1.5,
    color: '#212121'
  },
  {
    no: '20%',
    text: 'Rail',
    percent: 0.8,
    color: '#212121'
  },
  {
    no: '15%',
    text: 'Ocean',
    percent: 1.5,
    color: '#212121'
  },
  {
    no: '15%',
    text: 'Air',
    percent: 2.5,
    color: '#212121'
  }
]

export const others1 = [
  {
    no: '132',
    text: 'Exceptions',
    percent: 1.5,
    url: '/alert/report',
    color: '#C84031'
  },
  {
    no: '476.6 MT',
    text: 'CO2 Emission',
    percent: 1.5,
    url: '/alert/report',
    color: '#212121'
  },
  {
    no: '63%',
    text: 'PO Fulfilment',
    percent: 2.5,
    url: '/in-transit/transporter-performance',
    color: '#279B4E'
  }
]

export const others2 = [
  {
    no: '421',
    text: 'Exceptions',
    percent: 1.5,
    url: '/alert/report',
    color: '#C84031'
  },
  {
    no: '654.6 MT',
    text: 'CO2 Emission',
    percent: 5.5,
    url: '/alert/report',
    color: '#212121'
  },
  {
    no: '32%',
    text: 'PO Fulfilment',
    percent: 5.5,
    url: '/in-transit/transporter-performance',
    color: '#279B4E'
  }
]
export const DASHBOARD_DATA = {
  today: {
    shipment: [
      {
        count: '83',
        text: 'Shipments',
        percent: 0.5
      },
      {
        count: '12',
        text: 'On Time',
        percent: -2.5
      },
      {
        count: '19',
        text: 'Early',
        percent: 3.5
      },
      {
        count: '52',
        text: 'Delay',
        percent: -1.5
      }
    ],
    transport: [
      {
        count: '76%',
        text: 'Road',
        percent: 1.5
      },
      {
        count: '2%',
        text: 'Rail',
        percent: -0.3,
        color: '#212121'
      },
      {
        count: '12%',
        text: 'Ocean',
        percent: 0.5,
        color: '#212121'
      },
      {
        count: '2%',
        text: 'Air',
        percent: 0.5,
        color: '#212121'
      }
    ],
    yardVis: [
      {
        count: 31,
        text: 'Inbound',
        percent: 1.5,
        url: '/'
      },
      {
        count: 18,
        text: 'Outbound',
        percent: 0.3,
        url: '/'
      }
    ],
    others: [
      {
        count: '132',
        text: 'Exceptions',
        percent: 1.5,
        url: '/alert/report'
      },
      {
        count: '476.6 MT',
        text: 'CO2 Emission',
        percent: -1.5,
        url: '/alert/report'
      },
      {
        count: '63%',
        text: 'PO Fulfilment',
        percent: 2.5,
        url: '/in-transit/transporter-performance'
      }
    ]
  },
  lastWeek: {
    shipment: [
      {
        count: '178',
        text: 'Shipments',
        percent: 1.5
      },
      {
        count: '92',
        text: 'On Time',
        percent: 4.5
      },
      {
        count: '21',
        text: 'Early',
        percent: -1.5
      },
      {
        count: '65',
        text: 'Delay',
        percent: 5.5
      }
    ],
    transport: [
      {
        count: '70%',
        text: 'Road',
        percent: -1.5
      },
      {
        count: '1%',
        text: 'Rail',
        percent: 0.8
      },
      {
        count: '10%',
        text: 'Ocean',
        percent: 1.5
      },
      {
        count: '1%',
        text: 'Air',
        percent: 2.5
      }
    ],
    yardVis: [
      {
        count: 31,
        text: 'Inbound',
        percent: -1.5,
        url: '/'
      },
      {
        count: 18,
        text: 'Outbound',
        percent: 1.3,
        url: '/'
      }
    ],
    others: [
      {
        count: '110',
        text: 'Exceptions',
        percent: -3.5,
        url: '/alert/report'
      },
      {
        count: '654.6 MT',
        text: 'CO2 Emission',
        percent: 5.5,
        url: '/alert/report'
      },
      {
        count: '32%',
        text: 'PO Fulfilment',
        percent: 5.5,
        url: '/in-transit/transporter-performance'
      }
    ]
  }
}

export const CONFIGURE_DASHBOARD_OPTIONS = [
  {
    id: 'newsfeed',
    label: 'News Feed'
  },
  {
    id: 'shipatrisk',
    label: 'Shipments at Risk'
  },
  {
    id: 'shipstatus',
    label: 'Shipment Status',
    children: {
      type: 'checkbox',
      values: ['All Shipments', 'Early', 'Ontime', 'Delay', 'Untracked']
    }
  },
  {
    id: 'tptmode',
    label: 'Transportation Mode',
    children: {
      type: 'radio',
      values: ['Road', 'Rail', 'Air', 'Ocean', 'Parcel']
    }
  },
  {
    id: 'yardvis',
    label: 'Yard Visibility',
    children: {
      type: 'radio',
      values: ['Inbound', 'Outbound', 'Both']
    }
  },
  {
    id: 'exc',
    label: 'Exceptions',
    children: {
      type: 'checkbox',
      values: [
        'Disconnection',
        'Excess Idling',
        'STA Exceeded',
        'POL Delay',
        'POD Delay',
        'Demurrage',
        'Detention',
        'Vessel Rollover'
      ]
    }
  },
  {
    id: 'others',
    label: 'Others',
    children: {
      type: 'checkbox',
      values: [
        'Exceptions',
        'C02 Emission',
        'PO Fulfillment',
        'Tracking Complaince'
      ]
    }
  },
  {
    id: 'graph',
    label: 'Show Graphs on Dashboard',
    subLabel: 'Only one graph can be selected',
    children: {
      type: 'radio',
      values: [
        'Daily CO2 Emission',
        'Routewise CO2 Emission',
        'Daily Dispatch',
        'Unloading Performance',
        'Loading Performance',
        'Route Performance',
        'Transit Performance',
        'Vendor Performance',
        'Trip State (At loading , at unloading point etc)'
      ]
    }
  }
]
