import { MdFileUpload } from '@fareye/utils'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 32px;
`

type FilePickerProps = {
  title: string
  accept: string
  maxFiles?: number
  onFileUpload?: (input: any) => void
  onError: (error: any) => void
  onFileDrop: (res: any) => void
}

export function FilePicker({
  title,
  accept,
  maxFiles = 1,
  onFileUpload,
  onError,
  onFileDrop
}: FilePickerProps) {
  const dropZoneOptions: any = {
    maxFiles,
    onDrop: onFileDrop
  }
  if (accept) {
    dropZoneOptions.accept = accept
  }

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone(dropZoneOptions)

  React.useEffect(() => {
    if (onFileUpload) {
      onFileUpload(acceptedFiles)
    }
  }, [acceptedFiles])

  React.useEffect(() => {
    onError(fileRejections)
  }, [fileRejections])

  return (
    <div
      className="container"
      style={{
        display: 'flex',
        justifyContent: 'space-around'
      }}
    >
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <div>
          <MdFileUpload size={18} /> {title}
        </div>
      </Container>
    </div>
  )
}
