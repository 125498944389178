import { IconOptions } from 'leaflet'
import { MapContainerProps } from 'react-leaflet'
export interface CustomMarkerInterface {
  lat: number
  long: number
  icon: IconOptions
  key: string
  iconType: 'divIcon' | 'icon' | string
  markerRef?: (ele: any, selected: boolean | undefined) => void
  markerDetailType: 'tooltip' | 'popover' | string
  markerDetailsComponent: React.ReactNode
  selected?: boolean
}
export interface CustomPolyLineInterface extends CustomPolygonInterface {}

export interface CustomPolygonInterface {
  positions: any[]
  color: string
  key: string
  markerDetailType?: 'tooltip' | 'popover'
  markerDetailsComponent?: any
}
export interface MapRendererProps extends MapViewProps {
  markers?: CustomMarkerInterface[]
  config?: MapConfig
  clustering?: boolean
  polyLines?: CustomPolyLineInterface[]
  polygons?: CustomPolygonInterface[]
}

interface MapConfig {
  mapKey: string
  env: string
}

export type LeafletPositions =
  | 'topright'
  | 'topleft'
  | 'bottomleft'
  | 'bottomright'
  | undefined

export interface MapViewProps extends MapContainerProps {
  mapKey: string
  children: React.ReactNode
  leafletMapConfig: Partial<MapContainerProps>
  defaultBaseLayer?: string
  fullScreenControlPosition?: LeafletPositions
  layersControlPosition?: LeafletPositions
  ZoomControlPosition?: LeafletPositions
}

export enum BaseLayer {
  OSMMAPNIK = 'OpenStreetMap.Mapnik',
  GMROADS = 'Google Maps Roads',
  GMTERRAIN = 'Google Maps Terrain',
  GMSATELLITE = 'Google Maps Satellite',
  GMHYBRID = 'Google Maps Hybrid'
}
