// FIXME: sizes are not available
import { durationToMs } from '@fareye/utils'
import React from 'react'
import styled from 'styled-components'
import { layout, Props } from '../../Theme/styledSystem'
import { InputProps } from '../../utility/types'
import { CheckBox, responsiveValues } from './CheckBox'

const Radio: any = styled.input`
  position: relative;
  height: ${(props: any) =>
    responsiveValues(props.variant, [
      '8px',
      '10px',
      '12px',
      '14px',
      '16px',
      '18px',
      '20px',
      '12px'
    ])};
  width: ${(props: any) =>
    responsiveValues(props.variant, [
      '8px',
      '10px',
      '12px',
      '14px',
      '16px',
      '18px',
      '20px',
      '12px'
    ])};
  border-radius: 50%;
  :before,
  :after {
    position: absolute;
    content: '';
    border-radius: 50%;
  }
  :before {
    background: #fff;
    height: 100%;
    width: 100%;
    left: -1px;
    top: -1px;
    border: 1px solid rgba(128, 128, 128, 0.4);
  }
  :after {
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.9);
    left: 50%;
    top: 50%;
  }
  :checked:after {
    background: #444;
  }
  ${(props: any) => props.styles}
`

const StyledInput: any = styled.input`
  ${(props: any) => {
    return props.disabled
      ? props.theme.input['default']
      : props.type && props.theme.input[props.type]
      ? props.theme.input[props.type]
      : props.theme.input['text']
  }};
  ${(props: InputProps) => {
    return {
      padding: responsiveValues(props.variant, [
        '2px 6px',
        '4px 8px',
        '6px 10px',
        '8px 12px',
        '10px 14px',
        '12px 16px',
        '14px 18px',
        '9px 13px' // to be equal with search when no size given
      ]),
      fontSize: responsiveValues(props.variant, [
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16
      ]),
      ...props.styles
    }
  }};
  ${layout};
`

const StyledTextArea: any = styled.textarea`
  ${(props: any) => props.theme.input['textarea']};
  ${(props: any) => props.styles};
  ${layout};
`

const Input = (props: Partial<InputProps>) => {
  let {
    error,
    style,
    value,
    success,
    rows = 2,
    cols = 20,
    className,
    placeholder,
    type = 'text',
    disabledStyle,
    disabled = false
  } = Props(props)
  let [inputState, setError] = React.useState({ error, message: '', success })

  let errorSuccessState = ''
  if (inputState.error) {
    errorSuccessState = 'error'
  } else if (inputState.success) {
    errorSuccessState = 'success'
  }
  if (disabled) {
    style = { ...style, ...disabledStyle }
  }
  if (type === 'checkbox') {
    className = 'right-tick'
  } else if (type === 'radio') {
    className = 'filled'
  }

  let durProps = { ...props }
  delete durProps.style
  delete durProps.type
  delete durProps.onChange
  delete durProps.className

  let textareaProps = { ...props }
  delete textareaProps.style
  delete textareaProps.type
  delete textareaProps.rows
  delete textareaProps.cols
  delete textareaProps.className

  let inputProps = { ...props }
  delete inputProps.style
  delete inputProps.className

  let radioProps = { ...props }
  delete radioProps.style

  function updateDuration(val: string) {
    let isError = false
    let message = ''
    const ms: string | number = durationToMs(val)
    if (typeof ms === 'string') {
      isError = true
      message = ms
      props.onChange &&
        props.onChange({ value: val, milliseconds: 0, error: ms })
    } else if (typeof ms === 'number') {
      isError = false
      message = ''
      props.onChange &&
        props.onChange({ value: val, milliseconds: ms, error: '' })
    }
    setError({ ...inputState, error: isError, message })
  }

  return type === 'textarea' ? (
    <StyledTextArea
      styles={style}
      rows={rows}
      cols={cols}
      className={`feui-input ${className} ${errorSuccessState}`}
      {...textareaProps}
    >
      {value}
    </StyledTextArea>
  ) : type === 'duration' ? (
    <StyledInput
      type="text"
      styles={style}
      placeholder={placeholder || '1w 2d 3h 4m 5s'}
      title={inputState.message}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        updateDuration(e.target.value)
      }
      className={`feui-input ${className} ${errorSuccessState}`}
      {...durProps}
    />
  ) : type === 'radio' ? (
    <Radio styles={style} {...radioProps} />
  ) : type === 'checkbox' ? (
    <CheckBox styles={style} {...radioProps} />
  ) : (
    <StyledInput
      styles={style}
      className={`feui-input ${className} ${errorSuccessState}`}
      {...inputProps}
    />
  )
}
export default Input
