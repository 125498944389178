import { toTileCase } from '@fareye/utils'
import { antDarkTheme } from './Ant/darkTheme'
import { antTheme } from './Ant/theme'

const themeOptions: any = {
  colors: {
    white1: 'rgba(255,255,255,1)',
    black: 'rgba(13,26,38,1)',
    transparent: 'rgba(0,0,0,0)'
  },
  borderWidth: [1, 2, 3],
  boxShadow: ['', 1, 2, 3],
  breakpoints: ['40em', '52em', '64em', '80em']
}

const boxShadow = () => {
  const tempShadow: any = {}
  themeOptions.boxShadow.map((size: number | string) => {
    Object.keys(themeOptions.colors).map((color: string) => {
      tempShadow[`shadow${size}${toTileCase(color)}`] =
        typeof size === 'string'
          ? `0 0 1px 0px ${themeOptions.colors[color]}`
          : `0 0 ${parseInt(`${size / 2}`)}px ${size}px ${
              themeOptions.colors[color]
            }`
      return null
    })
    return null
  })
  return tempShadow
}

const Themes: any = {
  '': {
    ...antTheme(themeOptions, boxShadow()),
    themeName: 'Default'
  },
  ant: {
    ...antTheme(themeOptions, boxShadow()),
    themeName: 'Ant'
  },
  'ant-dark': {
    ...antDarkTheme(themeOptions, boxShadow()).ant,
    themeName: 'Ant-Dark'
  }
}

if (process.env.NODE_ENV !== 'test') {
  console.warn('Themes: ', Themes)
}

export default Themes
