import { IInTransitConfig } from '@fareye/intransit'
import environment from '../../environment'
import { AtLeastOne } from '@fareye/types'

let { baseURL } = environment
const InTransitRenderingConfig: AtLeastOne<IInTransitConfig> = {
  inTransitPerformance: {
    globalSearchPageId: 'intransit',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: {},
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      carryDate: true,
      links: {
        allTrips: '/in-transit/trips'
      },
      showDatePicker: true,
      showSummary: true,
      toggleContent: [
        {
          title: 'Route Performance',
          label: 'Route',
          selectedKey: 'route',
          link: '/in-transit'
        },
        {
          title: 'Transporter Performance',
          label: 'Transporter',
          selectedKey: 'transporter',
          link: '/in-transit/transporters'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      layoutStyle: { height: 'calc(100vh - 50px)' },
      style: {
        padding: '0px',
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridGap: '5px'
      }
    },
    children: {
      routeWisePerformance: {
        component: 'RouteWisePerformance',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v2/consigner_trips`,
            queryParams: {
              groupBy: 'routes',
              isActive: 'false'
            },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          carryDate: true,
          links: {},
          sortParam: [{ label: 'Average Difference', value: 'avgDiff' }]
        },
        styling: {
          style: { padding: '2vw' },
          className: '',
          variant: ''
        },
        children: {
          component: 'RouteWiseDetails',
          config: {
            carryDate: true,
            links: {
              routeDetailPage: '/in-transit'
            },
            metricsCount: [
              { label: 'Delayed', matchParam: 'delayed', color: '#FF7B00' },
              { label: 'On-Time', matchParam: 'onTime', color: '#C1C1C1' },
              { label: 'Exceptions', matchParam: 'exception', color: '#D84343' }
            ]
          },
          styling: {
            style: { background: '#fff' },
            className: '',
            variant: {
              cardSize: 's'
            }
          },
          children: {}
        }
      }
    }
  },
  routePerformance: {
    globalSearchPageId: 'intransit',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: { data_source: 'Shipment' },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      carryDate: true,
      links: {
        allTrips: '/in-transit/trips'
      },
      showDatePicker: true,
      showSummary: true,
      toggleContent: [
        {
          title: 'Route Performance',
          label: 'Route',
          selectedKey: 'route',
          link: '/in-transit'
        },
        {
          title: 'Transporter Performance',
          label: 'Transporter',
          selectedKey: 'transporter',
          link: '/in-transit/transporters'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      layoutStyle: { height: 'calc(100vh - 50px)' },
      style: {
        padding: '0px',
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridGap: '5px'
      }
    },
    children: {
      journey: {
        component: 'MultiModalRoute',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v3/journeys/route_performance`,
            queryParams: { aggs: ['status', 'transport_mode'] },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          carryDate: true,
          links: {},
          sortParam: [{ label: 'Average Difference', value: 'avgDiff' }]
        },
        styling: {
          style: { padding: '2vw' },
          className: '',
          variant: ''
        },
        children: {
          component: 'RouteWiseDetails',
          config: {
            carryDate: true,
            links: {
              routeDetailPage: '/in-transit/shipment'
            },
            metricsCount: [
              { label: 'Delayed', matchParam: 'delayed', color: '#FF7B00' },
              { label: 'On-Time', matchParam: 'on_time', color: '#C1C1C1' },
              {
                label: 'Exceptions',
                matchParam: 'exceptions',
                color: '#D84343'
              },
              {
                label: 'Stops',
                matchParam: 'stops',
                color: 'rgba(24,144,255,1)'
              }
            ]
          },
          styling: {
            style: { background: '#fff' },
            className: '',
            variant: {
              cardSize: 's'
            }
          },
          children: {}
        }
      },
      package: {
        component: 'MultiModalRoute',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v3/packages/route_performance`,
            queryParams: { aggs: ['status'] },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          carryDate: true,
          links: {},
          sortParam: [{ label: 'Average Difference', value: 'avgDiff' }]
        },
        styling: {
          style: { padding: '2vw' },
          className: '',
          variant: ''
        },
        children: {
          component: 'RouteWiseDetails',
          config: {
            carryDate: true,
            links: {
              routeDetailPage: '/in-transit/package'
            },
            metricsCount: [
              { label: 'Delayed', matchParam: 'delayed', color: '#FF7B00' },
              { label: 'On-Time', matchParam: 'on_time', color: '#C1C1C1' },
              {
                label: 'Exceptions',
                matchParam: 'exceptions',
                color: '#D84343'
              },
              {
                label: 'Stops',
                matchParam: 'stops',
                color: 'rgba(24,144,255,1)'
              }
            ]
          },
          styling: {
            style: { background: '#fff' },
            className: '',
            variant: {
              cardSize: 's'
            }
          },
          children: {}
        }
      }
    }
  },
  transporterPerformance: {
    globalSearchPageId: 'intransit',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: { data_source: 'Shipment' },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      carryDate: true,
      links: {},
      showDatePicker: true,
      showSummary: true,
      toggleContent: [
        {
          title: 'Route Performance',
          label: 'Route',
          selectedKey: 'route',
          link: '/in-transit'
        },
        {
          title: 'Transporter Performance',
          label: 'Transporter',
          selectedKey: 'transporter',
          link: '/in-transit/transporters'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      layoutStyle: { height: 'calc(100vh - 50px)' },
      style: {
        padding: '0px',
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: { gridGap: '5px' }
    },
    children: {
      multiModalTransporter: {
        component: 'MultiModalTransporter',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v3/journeys/transporter_performance`,
            queryParams: { aggs: ['status', 'transport_mode', 'route_string'] },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          callWithoutSubFilter: true,
          carryDate: true,
          links: {},
          sortParam: [
            { label: 'Low Performers', value: 'low' },
            { label: 'Top Performers', value: 'top' }
          ]
        },
        styling: {
          style: {
            width: '100%',
            padding: '0 2vw 2vw 2vw',
            boxSizing: 'border-box',
            background: 'rgba(249,250,250,1)',
            boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
          },
          className: '',
          variant: {}
        },
        children: {
          component: 'TransportersDetailsOcean',
          config: {
            carryDate: true,
            links: {
              transporterDetailLink: '/in-transit/transporters/shipment'
            },
            metricsCount: [
              {
                label: 'Exceptions',
                matchParam: 'exceptionCount',
                color: '#D84343'
              },
              { label: 'Delayed', matchParam: 'delayed', color: '#FF7B00' },
              { label: 'On-Time', matchParam: 'on_time', color: '#C1C1C1' }
            ]
          },
          styling: {
            style: {}
          },
          children: {}
        }
      }
    }
  },
  routeDetail: {
    globalSearchPageId: 'intransit',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: { data_source: 'Package' },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      carryDate: true,
      links: {},
      showDatePicker: true,
      toggleContent: [
        {
          label: 'Card View',
          title: 'Card View',
          selectedKey: 'normal'
        },
        {
          label: 'List View',
          title: 'List View',
          selectedKey: 'tabular'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      layoutStyle: { height: 'calc(100vh - 50px)' },
      style: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridGap: '5px'
      }
    },
    children: {
      routeDetails: {
        component: 'MultiModalRouteDetails',
        config: {
          carryDate: true,
          links: {},
          apiConfig: {
            apiURL: `${baseURL}/api/v3/packages/route_performance_details`,
            queryParams: {
              groupBy: 'routes',
              isActive: 'false',
              groupByDuration: 'day'
            },
            apiDataState: {
              noDataMessage: 'No Data Found.'
            }
          },
          apiTransporterPerformance: {
            apiURL: `${baseURL}/api/v2/consigner_trips`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          graphsToShow: {
            tripStatusGraph: {
              show: true
            },
            exceptionsGraph: {
              show: true
            },
            transporterPerformanceGraph: {
              show: true
            }
          },
          metricsCount: [
            {
              label: 'Delayed',
              matchParam: 'delayed',
              color: '#D84343'
            },

            { label: 'Early', matchParam: 'early', color: '#1261A0' },
            { label: 'On-Time', matchParam: 'on_time', color: '#76BA1B' }
          ]
        },
        styling: {
          style: {},
          className: '',
          variant: {}
        },
        children: {}
      },
      routeTrips: {
        component: 'MultiModalTableList',
        config: {
          links: {
            tripShare: '/packages'
          },
          apiConfig: {
            apiURL: `${baseURL}/api/v3/packages/list`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          downloadApiConfig: {
            apiURL: `${baseURL}/streams/consigner_trips/trip_data`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          columns: [
            { label: 'Package Id', key: 'packageId' },
            { label: 'Start And End Time', key: 'startAndEndTime' },
            { label: 'Transit Time', key: 'transitTime' },
            { label: 'Exceptions', key: 'exceptions' },
            { label: 'Transporter Details', key: 'packageTransporterDetails' }
          ],
          columnsList: [
            { label: 'Package Id', key: 'packageId' },
            { label: 'No. of Items', key: 'itemQty' },
            { label: 'Landmark', key: 'landmark' },
            { label: 'Last Updated At', key: 'updatedAt' },
            { label: 'Origin', key: 'origin' },
            { label: 'Destination', key: 'destination' },
            {
              label: 'Trip Start Time',
              key: 'actStartTime',
              formatter: 'dateFormatter'
            },
            {
              label: 'Trip End Time',
              key: 'actEndTime',
              formatter: 'dateFormatter'
            },
            { label: 'No. of Transporter', key: 'transporterNames' },
            { label: 'Modes', key: 'transportModes' },
            { label: 'Exceptions', key: 'exceptionCount' },
            { label: 'Consignee', key: 'consigneeName' },
            { label: 'Consigner', key: 'consignerName' }
          ]
        },
        styling: {
          style: {
            width: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: '16px 16px 50px'
          },
          className: '',
          variant: {}
        },
        children: {}
      }
    }
  },
  routeDetailShipment: {
    globalSearchPageId: 'intransit',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: { data_source: 'Shipment' },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      carryDate: true,
      links: {},
      showDatePicker: true,
      toggleContent: [
        {
          label: 'Card View',
          title: 'Card View',
          selectedKey: 'normal'
        },
        {
          label: 'List View',
          title: 'List View',
          selectedKey: 'tabular'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      layoutStyle: { height: 'calc(100vh - 50px)' },
      style: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridGap: '5px'
      }
    },
    children: {
      routeDetails: {
        component: 'MultiModalRouteDetails',
        config: {
          carryDate: true,
          links: {},
          apiConfig: {
            apiURL: `${baseURL}/api/v3/journeys/route_performance_details`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found.'
            }
          },
          apiTransporterPerformance: {
            apiURL: `${baseURL}/api/v2/consigner_trips`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          graphsToShow: {
            tripStatusGraph: {
              show: true
            },
            exceptionsGraph: {
              show: true
            },
            transporterPerformanceGraph: {
              show: true
            }
          },
          metricsCount: [
            {
              label: 'Delayed',
              matchParam: 'delayed',
              color: '#D84343'
            },

            { label: 'Early', matchParam: 'early', color: '#1261A0' },
            { label: 'On-Time', matchParam: 'on_time', color: '#76BA1B' }
          ]
        },
        styling: {
          style: {},
          className: '',
          variant: {}
        },
        children: {}
      },
      routeTrips: {
        component: 'MultiModalTableList',
        config: {
          links: {
            tripShare: '/shipment'
          },
          apiConfig: {
            apiURL: `${baseURL}/api/v3/journeys/list`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          downloadApiConfig: {
            apiURL: `${baseURL}/streams/consigner_trips/trip_data`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          columns: [
            { label: 'Shipment Id', key: 'shipmentId' },
            { label: 'Start And End Time', key: 'startAndEndTime' },
            { label: 'Transit Time', key: 'transitTime' },
            { label: 'Exceptions', key: 'exceptions' },
            { label: 'Transporter Details', key: 'transporterDetails' }
          ],
          columnsList: {
            ocean: [
              { label: 'Shipment Id', key: 'shipmentId' },
              { label: 'Landmark', key: 'shipmentLandmark' },
              { label: 'Last Updated At', key: 'shipmentUpdatedAt' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Vessel Name', key: 'vesselName' },
              { label: 'Vessel No.', key: 'vesselNo' },
              { label: 'Container No.', key: 'containerNo' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Transshipment', key: 'transshipment' },
              {
                label: 'Trip Start Time',
                key: 'actStartTime',
                formatter: 'dateFormatter'
              },
              {
                label: 'Trip End Time',
                key: 'actEndTime',
                formatter: 'dateFormatter'
              },
              { label: 'Status', key: 'status' },
              { label: 'Exceptions', key: 'exceptionCount' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Consigner', key: 'consignerName' }
            ],
            road: [
              { label: 'Shipment Id', key: 'shipmentId' },
              { label: 'Landmark', key: 'shipmentLandmark' },
              { label: 'Last Updated At', key: 'shipmentUpdatedAt' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Transshipment', key: 'transshipment' },
              {
                label: 'Trip Start Time',
                key: 'actStartTime',
                formatter: 'dateFormatter'
              },
              {
                label: 'Trip End Time',
                key: 'actEndTime',
                formatter: 'dateFormatter'
              },
              { label: 'Status', key: 'status' },
              { label: 'Exceptions', key: 'exceptionCount' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Consigner', key: 'consignerName' }
            ],
            rail: [
              { label: 'Shipment Id', key: 'shipmentId' },
              { label: 'Landmark', key: 'shipmentLandmark' },
              { label: 'Last Updated At', key: 'shipmentUpdatedAt' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Vehicle No', key: 'vehicleNo' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Transshipment', key: 'transshipment' },
              {
                label: 'Trip Start Time',
                key: 'actStartTime',
                formatter: 'dateFormatter'
              },
              {
                label: 'Trip End Time',
                key: 'actEndTime',
                formatter: 'dateFormatter'
              },
              { label: 'Status', key: 'status' },
              { label: 'Exceptions', key: 'exceptionCount' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Consigner', key: 'consignerName' }
            ]
          }
        },
        styling: {
          style: {
            width: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: '16px 16px 50px'
          },
          className: '',
          variant: {}
        },
        children: {}
      }
    }
  },
  routeDetailsPage: {
    globalSearchPageId: 'intransit',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: {},
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      carryDate: true,
      links: {},
      showDatePicker: true,
      toggleContent: [
        {
          label: 'Card View',
          title: 'Card View',
          selectedKey: 'normal'
        },
        {
          label: 'List View',
          title: 'List View',
          selectedKey: 'tabular'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      layoutStyle: { height: 'calc(100vh - 50px)' },
      style: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridGap: '5px'
      }
    },
    children: {
      routeDetails: {
        component: 'RouteDetails',
        config: {
          carryDate: true,
          links: {},
          apiConfig: {
            apiURL: `${baseURL}/api/v2/consigner_trips`,
            queryParams: {
              groupBy: 'routes',
              isActive: 'false',
              groupByDuration: 'day'
            },
            apiDataState: {
              noDataMessage: 'No Data Found.'
            }
          },
          apiTransporterPerformance: {
            apiURL: `${baseURL}/api/v2/consigner_trips`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          graphsToShow: {
            tripStatusGraph: {
              show: true
            },
            exceptionsGraph: {
              show: true
            },
            transporterPerformanceGraph: {
              show: true
            }
          },
          metricsCount: [
            {
              label: 'Delayed',
              matchParam: 'delayed',
              color: '#D84343'
            },

            { label: 'Early', matchParam: 'onlyEarly', color: '#1261A0' },
            { label: 'On-Time', matchParam: 'onlyOntime', color: '#76BA1B' }
          ]
        },
        styling: {
          style: {},
          className: '',
          variant: {}
        },
        children: {}
      },
      routeTrips: {
        component: 'TripTable',
        config: {
          links: {
            tripShare: '/in-transit/trips'
          },
          apiConfig: {
            apiURL: `${baseURL}/api/v2/consigner_trips`,
            queryParams: {
              isActive: 'false'
            },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          downloadApiConfig: {
            apiURL: `${baseURL}/streams/consigner_trips/trip_data`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          columns: [
            'tripIdAndProduct',
            'startAndEndTime',
            'transitTime',
            'exceptions',
            'driverDetails'
          ],
          columnsList: [
            'tripID',
            'origin',
            'landmark',
            'consignee',
            'consigner',
            'loadingIn',
            'vehicleNo',
            'driverName',
            'loadingOut',
            'destination',
            'materialTab',
            'transporter',
            'unloadingIn',
            'istTimestamp',
            'unloadingOut',
            'driverPhoneNo',
            'exceptionsCount'
          ]
        },
        styling: {
          style: {
            width: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: '16px 16px 50px'
          },
          className: '',
          variant: {}
        },
        children: {}
      }
    }
  },
  transporterDetailsPage: {
    globalSearchPageId: 'intransit',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: {},
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      carryDate: true,
      constantConfig: {
        showTripsButton: 'View All Trips'
      },
      links: {},
      showDatePicker: true,
      toggleContent: [
        {
          label: 'Card View',
          title: 'Card View',
          selectedKey: 'normal'
        },
        {
          label: 'List View',
          title: 'List View',
          selectedKey: 'tabular'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      layoutStyle: { height: 'calc(100vh - 50px)' },
      style: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridGap: '5px'
      }
    },
    children: {
      transporterDetails: {
        component: 'TransporterDetails',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v2/consigner_trips`,
            queryParams: {
              groupBy: 'transporters',
              isActive: 'false',
              groupByDuration: 'day'
            },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          barColor: {
            onTime: 'green',
            exception: 'red',
            returnMovement: 'grey'
          },
          metricsCount: [
            {
              label: 'Delayed',
              matchParam: 'delayed',
              color: '#D84343'
            },
            {
              label: 'Early',
              matchParam: 'onlyEarly',
              color: '#1261A0'
            },
            {
              label: 'On-Time',
              matchParam: 'onlyOntime',
              color: '#76BA1B'
            }
          ],
          graphsToShow: {
            onTimeDeliveryGraph: {
              show: true,
              color: 'rgb(42, 68, 222)'
            },
            returnMovementGraph: {
              show: true,
              color: 'grey'
            },
            exceptionsGraph: {
              show: true
            },
            vehicleMetricGraph: {
              show: true
            }
          },
          carryDate: true,
          links: {}
        },
        styling: {
          style: {},
          className: '',
          variant: {}
        },
        children: {}
      },
      routeTrips: {
        component: 'TripTable',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v2/consigner_trips`,
            queryParams: { isActive: 'false', tripTat: 'false' },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          carryDate: true,
          columns: [
            'tripIdAndProduct',
            'routeDetails',
            'transitTime',
            'startAndEndTime',
            'exceptions',
            'driverDetails'
          ],
          columnsList: [
            'tripID',
            'origin',
            'landmark',
            'consignee',
            'consigner',
            'loadingIn',
            'vehicleNo',
            'driverName',
            'loadingOut',
            'destination',
            'materialTab',
            'transporter',
            'unloadingIn',
            'istTimestamp',
            'unloadingOut',
            'driverPhoneNo',
            'exceptionsCount'
          ],
          downloadApiConfig: {
            apiURL: `${baseURL}/streams/consigner_trips/trip_data`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          links: {
            tripShare: '/in-transit/trips'
          }
        },
        styling: {
          style: {
            width: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: '16px 16px 50px'
          },
          className: '',
          variant: {}
        },
        children: {}
      }
    }
  },
  multiModalTransporterDetail: {
    globalSearchPageId: 'intransit',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: {},
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      carryDate: true,
      constantConfig: {
        showTripsButton: 'View All Trips'
      },
      links: {},
      showDatePicker: true,
      toggleContent: [
        {
          label: 'Card View',
          title: 'Card View',
          selectedKey: 'normal'
        },
        {
          label: 'List View',
          title: 'List View',
          selectedKey: 'tabular'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      layoutStyle: { height: 'calc(100vh - 50px)' },
      style: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridGap: '5px'
      }
    },
    children: {
      transporterDetails: {
        component: 'MultiModalTransporterDetails',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v3/journeys/transporter_performance_details`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          metricsCount: [
            {
              label: 'Delayed',
              matchParam: 'delayed',
              color: '#D84343'
            },
            {
              label: 'Early',
              matchParam: 'early',
              color: '#1261A0'
            },
            {
              label: 'On-Time',
              matchParam: 'on_time',
              color: '#76BA1B'
            }
          ],
          graphsToShow: {
            onTimeDeliveryGraph: {
              show: true,
              color: 'rgb(42, 68, 222)'
            },
            returnMovementGraph: {
              show: true,
              color: 'grey'
            },
            exceptionsGraph: {
              show: true
            },
            vehicleMetricGraph: {
              show: true
            }
          },
          carryDate: true,
          links: {}
        },
        styling: {
          style: {},
          className: '',
          variant: {}
        },
        children: {}
      },
      routeTrips: {
        component: 'MultiModalTableList',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v3/journeys/list`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          carryDate: true,
          columns: [
            { label: 'Shipment Id', key: 'shipmentId' },
            { label: 'Start And End Time', key: 'startAndEndTime' },
            { label: 'Transit Time', key: 'transitTime' },
            { label: 'Exceptions', key: 'exceptions' },
            { label: 'Transporter Details', key: 'transporterDetails' }
          ],
          columnsList: {
            ocean: [
              { label: 'Shipment Id', key: 'shipmentId' },
              { label: 'Landmark', key: 'shipmentLandmark' },
              { label: 'Last Updated At', key: 'shipmentUpdatedAt' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Vessel Name', key: 'vesselName' },
              { label: 'Vessel No.', key: 'vesselNo' },
              { label: 'Container No.', key: 'containerNo' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Transshipment', key: 'transshipment' },
              {
                label: 'Trip Start Time',
                key: 'actStartTime',
                formatter: 'dateFormatter'
              },
              {
                label: 'Trip End Time',
                key: 'actEndTime',
                formatter: 'dateFormatter'
              },
              { label: 'Status', key: 'status' },
              { label: 'Exceptions', key: 'exceptionCount' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Consigner', key: 'consignerName' }
            ],
            road: [
              { label: 'Shipment Id', key: 'shipmentId' },
              { label: 'Landmark', key: 'shipmentLandmark' },
              { label: 'Last Updated At', key: 'shipmentUpdatedAt' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Vessel Name', key: 'vesselName' },
              { label: 'Vessel No.', key: 'vesselNo' },
              { label: 'Container No.', key: 'containerNo' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Transshipment', key: 'transshipment' },
              {
                label: 'Trip Start Time',
                key: 'actStartTime',
                formatter: 'dateFormatter'
              },
              {
                label: 'Trip End Time',
                key: 'actEndTime',
                formatter: 'dateFormatter'
              },
              { label: 'Status', key: 'status' },
              { label: 'Exceptions', key: 'exceptionCount' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Consigner', key: 'consignerName' }
            ],
            rail: [
              { label: 'Shipment Id', key: 'shipmentId' },
              { label: 'Landmark', key: 'shipmentLandmark' },
              { label: 'Last Updated At', key: 'shipmentUpdatedAt' },
              { label: 'Transporter', key: 'transporterName' },
              { label: 'Vessel Name', key: 'vesselName' },
              { label: 'Vessel No.', key: 'vesselNo' },
              { label: 'Container No.', key: 'containerNo' },
              { label: 'Origin', key: 'origin' },
              { label: 'Destination', key: 'destination' },
              { label: 'Transshipment', key: 'transshipment' },
              {
                label: 'Trip Start Time',
                key: 'actStartTime',
                formatter: 'dateFormatter'
              },
              {
                label: 'Trip End Time',
                key: 'actEndTime',
                formatter: 'dateFormatter'
              },
              { label: 'Status', key: 'status' },
              { label: 'Exceptions', key: 'exceptionCount' },
              { label: 'Consignee', key: 'consigneeName' },
              { label: 'Consigner', key: 'consignerName' }
            ]
          },
          downloadApiConfig: {
            apiURL: `${baseURL}/streams/consigner_trips/trip_data`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          links: {
            tripShare: '/shipment'
          }
        },
        styling: {
          style: {
            width: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: '16px 16px 50px'
          },
          className: '',
          variant: {}
        },
        children: {}
      }
    }
  },
  allTripsPage: {
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: {},
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      carryDate: true,
      showSummary: true,
      constantConfig: {
        showTripsButton: 'View All Trips'
      },
      links: {},
      showDatePicker: true,
      showAllTrips: false,
      toggleContent: [
        {
          label: 'Card View',
          title: 'Card View',
          selectedKey: 'normal',
          link: '/in-transit/trips'
        },
        {
          label: 'List View',
          title: 'List View',
          selectedKey: 'tabular',
          link: '/in-transit/trips'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      layoutStyle: { height: 'calc(100vh - 50px)' },
      style: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridGap: '5px'
      }
    },
    children: {
      routeTrips: {
        component: 'TripTable',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v2/consigner_trips`,
            queryParams: { tripTat: 'false' },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          callWithoutSubFilter: true,
          carryDate: true,
          columns: [
            'tripIdAndProduct',
            'routeDetails',
            'transitTime',
            'startAndEndTime',
            'exceptions',
            'driverDetails'
          ],
          columnsList: [
            'tripID',
            'origin',
            'exitTime',
            'landmark',
            'consignee',
            'consigner',
            'entryTime',
            'loadingIn',
            'vehicleNo',
            'driverName',
            'loadingOut',
            'destination',
            'materialTab',
            'transporter',
            'unloadingIn',
            'istTimestamp',
            'unloadingOut',
            'driverPhoneNo',
            'exceptionsCount'
          ],
          downloadApiConfig: {
            apiURL: `${baseURL}/streams/consigner_trips/trip_data`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          links: {
            tripShare: '/in-transit/trips'
          }
        },
        styling: {
          style: {
            width: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: '16px 16px 50px'
          },
          className: '',
          variant: {}
        },
        children: {}
      }
    }
  },
  allTransportersPage: {
    globalSearchPageId: 'intransit',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: {},
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      }
    },
    config: {
      carryDate: true,
      links: {},
      showDatePicker: true,
      showSummary: true,
      toggleContent: [
        {
          title: 'Route Performance',
          label: 'Route',
          selectedKey: 'route',
          link: '/in-transit'
        },
        {
          title: 'Transporter Performance',
          label: 'Transporter',
          selectedKey: 'transporter',
          link: '/in-transit/transporters'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      layoutStyle: { height: 'calc(100vh - 50px)' },
      style: {
        padding: '0px',
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: { gridGap: '5px' }
    },
    children: {
      transporterPerformance: {
        component: 'TransportersPerformance',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v2/consigner_trips`,
            queryParams: {
              isActive: 'false',
              tripTat: 'false',
              groupBy: 'transporters'
            },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          callWithoutSubFilter: true,
          carryDate: true,
          links: {},
          sortParam: [
            { label: 'Low Performers', value: 'low' },
            { label: 'Top Performers', value: 'top' }
          ]
        },
        styling: {
          style: {
            width: '100%',
            padding: '0 2vw 2vw 2vw',
            boxSizing: 'border-box',
            background: 'rgba(249,250,250,1)',
            boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
          },
          className: '',
          variant: {}
        },
        children: {
          component: 'TransportersDetailsCard',
          config: {
            carryDate: true,
            links: {},
            metricsCount: [
              {
                label: 'Exceptions',
                matchParam: 'exception',
                color: '#D84343'
              },
              { label: 'Delayed', matchParam: 'delayed', color: '#FF7B00' },
              { label: 'On-Time', matchParam: 'onTime', color: '#C1C1C1' }
            ]
          },
          styling: {
            style: {}
          },
          children: {}
        }
      }
    }
  },
  eta: {
    globalSearchPageId: 'intransit',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: {},
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        }
      }
    },
    config: {
      carryDate: false,
      links: {},
      showDatePicker: false,
      showSummary: true,
      toggleContent: [
        {
          label: 'Card View',
          title: 'Card View',
          selectedKey: 'normal',
          link: '/in-transit/eta'
        },
        {
          label: 'List View',
          title: 'List View',
          selectedKey: 'tabular',
          link: '/in-transit/eta'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      style: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridGap: '5px'
      }
    },
    children: {
      routeTrips: {
        component: 'TripTable',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v2/consigner_trips`,
            queryParams: {
              isActive: 'true',
              tripTat: 'false',
              gpsInfo: 'true',
              etaInfo: 'true'
            },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          callWithoutSubFilter: true,
          carryDate: true,
          columns: [
            'tripIdAndProduct',
            'startTimeAndSTA',
            'transitTimeETA',
            'eta',
            'expand',
            'exceptions',
            'landmarkEta',
            'routeDetails',
            'tripCompletion',
            'driverDetailsETA'
          ],
          columnsList: [
            'sta',
            'region',
            'etaList',
            'expand',
            'tripID',
            'origin',
            'status',
            'battery',
            'landmark',
            'consignee',
            'consigner',
            'invoiceNo',
            'loadingIn',
            'vehicleNo',
            'driverName',
            'invoiceQty',
            'loadingOut',
            'destination',
            'materialTab',
            'transporter',
            'unloadingIn',
            'istTimestamp',
            'unloadingOut',
            'driverPhoneNo',
            'signalStrength',
            'tripCompletion',
            'exceptionsCount'
          ],
          downloadApiConfig: {
            apiURL: `${baseURL}/streams/consigner_trips/trip_data`,
            queryParams: {
              isActive: 'true',
              tripTat: 'false',
              gpsInfo: 'true',
              etaInfo: 'true'
            },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          keysToDisplay: {
            etaEndPoint: 'consigneeName'
          },
          links: {
            tripShare: '/in-transit/trips'
          }
        },
        styling: {
          style: {},
          className: '',
          variant: {}
        },
        children: {
          subComp: {
            component: 'Timeline',
            config: {},
            styling: {
              style: {},
              className: '',
              variant: {}
            },
            children: {}
          }
        }
      }
    }
  },
  multiModelListing: {
    globalSearchPageId: 'intransit',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: {
          data_source: 'Shipment'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },
      apiFav: {
        apiURL: `${baseURL}/json_store/records.json`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        }
      },
      customKeyMapping: {
        key1: 'SKU number'
      }
    },
    config: {
      carryDate: true,
      links: {},
      showDatePicker: true,
      showSummary: true,
      toggleContent: [
        {
          label: 'Card View',
          title: 'Card',
          selectedKey: 'normal'
        },
        {
          label: 'List View',
          title: 'List',
          selectedKey: 'tabular'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      style: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridGap: '5px'
      }
    },
    children: {
      journey: {
        component: 'TripTableMultiModel',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v3/journeys/list`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          callWithoutSubFilter: true,
          carryDate: true,
          columns: [
            {
              key: 'shipmentId',
              label: 'Shipment Id'
            },
            {
              key: 'shipmentItemCount',
              label: 'Packages'
            },
            {
              key: 'packageRoute',
              label: 'Origin & Destination'
            },
            {
              key: 'packageStatus',
              label: 'Shipment Status'
            },
            {
              key: 'shipmentCarrier',
              label: 'Carrier'
            },
            {
              key: 'shipmentConsignor',
              label: 'Consignor'
            },
            {
              key: 'shipmentConsignee',
              label: 'Consignee'
            },
            {
              key: 'shipmentNextStop',
              label: 'Next Stop'
            },
            {
              key: 'shipmentEquipment',
              label: 'Equipment'
            }
          ],
          columnsList: [
            {
              key: 'lrNo',
              label: 'Shipment Id'
            },
            {
              key: 'tag',
              label: 'Label'
            },
            {
              key: 'status',
              label: 'Trip Status'
            },
            {
              key: 'inState',
              label: 'Trip State'
            },
            {
              key: 'percTripCovered',
              label: 'Trip Completion',
              columnConfig: {
                sortable: false
              }
            },
            {
              key: 'startLocation.locationName',
              label: 'Origin'
            },
            {
              key: 'std',
              label: 'STD',
              formatter: 'dateFormatter'
            },
            {
              key: 'atd',
              label: 'ATD',
              formatter: 'dateFormatter'
            },
            {
              key: 'endLocation.locationName',
              label: 'Destination'
            },
            {
              key: 'sta',
              label: 'STA',
              formatter: 'dateFormatter'
            },
            {
              key: 'ata',
              label: 'ATA',
              formatter: 'dateFormatter'
            },
            {
              key: 'eta',
              label: 'ETA',
              formatter: 'dateFormatter'
            },
            {
              key: 'consignerName',
              label: 'Consigner'
            },
            {
              key: 'transportMode',
              label: 'Mode'
            },
            {
              key: 'transporterName',
              label: 'Transporter Name'
            },
            {
              key: 'vehicleNo',
              label: 'Vehicle No./Vessel No.'
            },
            {
              key: 'stopCount',
              label: 'No. of stops'
            },
            {
              key: 'exceptionCount',
              label: 'Exceptions'
            },
            // {
            //   key: 'delays',
            //   label: 'Delays'
            // },
            {
              key: 'nextStop.name',
              label: 'Next Stop Address'
            },
            {
              key: 'trackingProvider',
              label: 'GPS Device Name'
            },

            {
              key: 'packagesCount',
              label: 'Packages',
              columnConfig: {
                sortable: false
              }
            },
            {
              key: 'routeString',
              label: 'Route'
            }
          ],
          sortingMapping: {
            inState: 'in_state',
            tripCompletion: 'trip_completion',
            'startLocation.locationName': 'start_location.location_name',
            expStartTime: 'exp_start_time',
            actStartTime: 'act_start_time',
            'endLocation.locationName': 'end_location.location_name',
            actEndTime: 'act_end_time',
            expEndTime: 'exp_end_time',
            itemQty: 'item_qty',
            routeString: 'route_string'
          },
          downloadApiConfig: {
            apiURL: `${baseURL}/streams/journey/reports/export_journeys_report`,
            type: 'POST',
            queryParams: {
              isActive: 'true',
              tripTat: 'false',
              gpsInfo: 'true',
              etaInfo: 'true'
            },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          keysToDisplay: {
            etaEndPoint: 'consigneeName'
          },
          links: {
            tripShare: '/shipment'
          }
        },
        styling: {
          style: {},
          className: '',
          variant: {}
        },
        children: {
          subComp: {
            component: 'ShipmentTimeLine',
            config: {
              carryDate: false,
              links: {}
            },
            styling: {
              style: {},
              className: '',
              variant: {}
            },
            children: {}
          }
        }
      },
      package: {
        component: 'TripTableMultiModel',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v3/packages/list`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          callWithoutSubFilter: true,
          carryDate: true,
          columns: [
            {
              key: 'packageId',
              label: 'Package Id'
            },
            {
              key: 'itemQty',
              label: 'Items'
            },
            {
              key: 'packageRoute',
              label: 'Origin & Destination'
            },
            {
              key: 'packageStatus',
              label: 'Package Status'
            },
            {
              key: 'packageCarrier',
              label: 'Carrier'
            },
            {
              key: 'packageConsignor',
              label: 'Consignor'
            },
            {
              key: 'packageConsignee',
              label: 'Consignee'
            },
            {
              key: 'packageNextStop',
              label: 'Next Stop'
            },
            {
              key: 'packageEquipment',
              label: 'Equipment'
            }
          ],
          columnsList: [
            {
              key: 'shipmentNo',
              label: 'Package Id'
            },
            {
              key: 'status',
              label: 'Trip Status'
            },
            {
              key: 'inState',
              label: 'Trip State'
            },
            {
              key: 'percTripCovered',
              label: 'Trip Completion'
            },
            {
              key: 'startLocation.locationName',
              label: 'Origin'
            },
            {
              key: 'expStartTime',
              label: 'STD',
              formatter: 'dateFormatter'
            },
            {
              key: 'actStartTime',
              label: 'ATD',
              formatter: 'dateFormatter'
            },
            {
              key: 'endLocation.locationName',
              label: 'Destination'
            },
            {
              key: 'expEndTime',
              label: 'STA',
              formatter: 'dateFormatter'
            },

            {
              key: 'actEndTime',
              label: 'ATA',
              formatter: 'dateFormatter'
            },
            {
              key: 'eta',
              label: 'ETA',
              formatter: 'dateFormatter'
            },

            {
              key: 'consignerName',
              label: 'Consigner'
            },

            {
              key: 'itemQty',
              label: 'Items/Qty',
              columnConfig: {
                sortable: false
              }
            },
            {
              key: 'packageStopCount',
              label: 'No. of stops'
            },
            {
              key: 'packageTransporterCount',
              label: 'No. of transporters'
            },
            {
              key: 'modes',
              label: 'Modes'
            },

            {
              key: 'consigneeName',
              label: 'Consignee'
            },
            {
              key: 'consigneeName',
              label: 'Consignee Address'
            },
            {
              key: 'exceptionCount',
              label: 'Exceptions'
            },
            {
              key: 'nextNode.name',
              label: 'Next Stop Address'
            },
            {
              key: 'trackingProvider',
              label: 'GPS Device Name'
            },
            {
              key: 'latestJourney.latestLocation.gpsTimestamp',
              label: 'Last Updated At',
              formatter: 'dateFormatter'
            },
            {
              key: 'latestJourney.latestLocation.landmark',
              label: 'Landmark'
            }
          ],
          downloadApiConfig: {
            apiURL: `${baseURL}/streams/consigner_trips/trip_data`,
            queryParams: {
              isActive: 'true',
              tripTat: 'false',
              gpsInfo: 'true',
              etaInfo: 'true'
            },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          keysToDisplay: {
            etaEndPoint: 'consigneeName'
          },
          links: {
            tripShare: '/packages'
          }
        },
        styling: {
          style: {},
          className: '',
          variant: {}
        },
        children: {
          subComp: {
            component: 'MultiModelPackageTimeLine',
            config: {
              carryDate: false,
              links: {}
            },
            styling: {
              style: {},
              className: '',
              variant: {}
            },
            children: {}
          }
        }
      },
      poList: {
        component: 'MultiModalPoList',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v3/journeys/list`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          callWithoutSubFilter: true,
          carryDate: true,
          columns: [
            {
              key: 'shipmentId',
              label: 'Shipment Id'
            },
            {
              key: 'shipmentItemCount',
              label: 'Packages'
            },
            {
              key: 'packageRoute',
              label: 'Origin & Destination'
            },
            {
              key: 'packageStatus',
              label: 'Shipment Status'
            },
            {
              key: 'shipmentCarrier',
              label: 'Carrier'
            },
            {
              key: 'shipmentConsignor',
              label: 'Consignor'
            },
            {
              key: 'shipmentConsignee',
              label: 'Consignee'
            },
            {
              key: 'shipmentNextStop',
              label: 'Next Stop'
            },
            {
              key: 'shipmentEquipment',
              label: 'Equipment'
            }
          ],
          columnsList: [
            {
              key: 'lrNo',
              label: 'Shipment Id'
            },
            {
              key: 'status',
              label: 'Trip Status'
            },
            {
              key: 'inState',
              label: 'Trip State'
            },
            {
              key: 'percTripCovered',
              label: 'Trip Completion',
              columnConfig: {
                sortable: false
              }
            },
            {
              key: 'startLocation.locationName',
              label: 'Origin'
            },
            {
              key: 'expStartTime',
              label: 'STD',
              formatter: 'dateFormatter'
            },
            {
              key: 'actStartTime',
              label: 'ATD',
              formatter: 'dateFormatter'
            },
            {
              key: 'endLocation.locationName',
              label: 'Destination'
            },
            {
              key: 'expEndTime',
              label: 'STA',
              formatter: 'dateFormatter'
            },
            {
              key: 'actEndTime',
              label: 'ATA',
              formatter: 'dateFormatter'
            },
            {
              key: 'eta',
              label: 'ETA',
              formatter: 'dateFormatter'
            },
            {
              key: 'consignerName',
              label: 'Consigner'
            },
            {
              key: 'transportMode',
              label: 'Mode'
            },
            {
              key: 'transporterName',
              label: 'Transporter Name'
            },
            {
              key: 'vehicleNo',
              label: 'Vehicle No./Vessel No.'
            },
            {
              key: 'stopCount',
              label: 'No. of stops'
            },
            {
              key: 'exceptionCount',
              label: 'Exceptions'
            },
            // {
            //   key: 'delays',
            //   label: 'Delays'
            // },
            {
              key: 'nextStop.name',
              label: 'Next Stop Address'
            },
            {
              key: 'trackingProvider',
              label: 'GPS Device Name'
            },

            {
              key: 'packagesCount',
              label: 'Packages',
              columnConfig: {
                sortable: false
              }
            },
            {
              key: 'routeString',
              label: 'Route'
            }
          ],
          sortingMapping: {
            inState: 'in_state',
            tripCompletion: 'trip_completion',
            'startLocation.locationName': 'start_location.location_name',
            expStartTime: 'exp_start_time',
            actStartTime: 'act_start_time',
            'endLocation.locationName': 'end_location.location_name',
            actEndTime: 'act_end_time',
            expEndTime: 'exp_end_time',
            itemQty: 'item_qty',
            routeString: 'route_string'
          },
          downloadApiConfig: {
            apiURL: `${baseURL}/streams/consigner_trips/trip_data`,
            queryParams: {
              isActive: 'true',
              tripTat: 'false',
              gpsInfo: 'true',
              etaInfo: 'true'
            },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          keysToDisplay: {
            etaEndPoint: 'consigneeName'
          },
          links: {
            tripShare: '/shipment'
          }
        },
        styling: {
          style: {},
          className: '',
          variant: {}
        },
        children: {
          subComp: {
            component: 'ShipmentTimeLine',
            config: {
              carryDate: false,
              links: {}
            },
            styling: {
              style: {},
              className: '',
              variant: {}
            },
            children: {}
          }
        }
      }
    }
  },
  containerListing: {
    globalSearchPageId: 'intransit',
    filters: {
      apiFilter: {
        apiURL: `${baseURL}/api/v1/es/search_aggs`,
        queryParams: {
          data_source: 'Shipment'
        },
        apiDataState: {
          noDataMessage: 'No Data Found'
        }
      },

      apiFav: {
        apiURL: `${baseURL}/api/v1/records`,
        queryParams: {
          type: 'JsonStore::Ui',
          subType: 'globalSearchFavourites',
          pageId: 'intransit'
        }
      }
    },
    config: {
      carryDate: true,
      links: {},
      showDatePicker: true,
      showSummary: true,
      toggleContent: [
        {
          label: 'All',
          title: 'All',
          selectedKey: 'normal'
        },
        {
          label: 'Active',
          title: 'Active',
          selectedKey: 'tabular'
        }
      ]
    },
    styling: {
      bodyBgColor: '#F0F2F5',
      style: {
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
      },
      className: '',
      variant: {
        gridGap: '5px'
      }
    },
    children: {
      containerList: {
        component: 'ContainerListTable',
        config: {
          apiConfig: {
            apiURL: `${baseURL}/api/v3/containers/list`,
            queryParams: {},
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          callWithoutSubFilter: true,
          carryDate: true,
          columnsList: [
            {
              key: 'containerNo',
              label: 'Container No'
            },
            {
              key: 'packageIdKey',
              label: 'Package Id'
            },
            {
              key: 'transporterNames',
              label: 'Transporter'
            },
            {
              key: 'polName',
              label: 'POL'
            },
            {
              key: 'podName',
              label: 'POD'
            },
            {
              key: 'potName',
              label: 'T/S'
            },
            {
              key: 'std',
              label: 'STD',
              formatter: 'dateFormatter'
            },
            {
              key: 'atd',
              label: 'ATD',
              formatter: 'dateFormatter'
            },
            {
              key: 'sta',
              label: 'STA',
              formatter: 'dateFormatter'
            },
            {
              key: 'ata',
              label: 'ATA',
              formatter: 'dateFormatter'
            },
            {
              key: 'eta',
              label: 'eta',
              formatter: 'dateFormatter'
            },
            {
              key: 'exceptions',
              label: 'Exceptions  '
            },
            {
              key: 'event.eventRaw',
              label: 'Event'
            },
            {
              key: 'routeState',
              label: 'Route State'
            },
            {
              key: 'status',
              label: 'Status'
            }

            // {
            //   key: 'delays',
            //   label: 'Delays'
            // },
          ],
          downloadApiConfig: {
            apiURL: `${baseURL}/streams/consigner_trips/trip_data`,
            queryParams: {
              isActive: 'true',
              tripTat: 'false',
              gpsInfo: 'true',
              etaInfo: 'true'
            },
            apiDataState: {
              noDataMessage: 'No Data Found'
            }
          },
          keysToDisplay: {
            etaEndPoint: 'consigneeName'
          },
          links: {
            tripShare: '/packages'
          }
        },
        styling: {
          style: {},
          className: '',
          variant: {}
        },
        children: {}
      }
    }
  }
}

export default InTransitRenderingConfig
