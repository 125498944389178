import React from 'react'
import styled from 'styled-components'
import {
  IPropsInterface,
  splitVariantsToTypes,
  refinePropsFromParentEvents
} from '../Theme/styledSystem'
import { ReactNode } from 'react'
import { TPopoverVariant } from '../utility/variantTypes'

const PopoverWrapper: any = styled.div`
  position: relative;
  ${(props: any) => {
    const variant = splitVariantsToTypes(props)
    return (
      variant.onHover &&
      `
      div.popover-body {
        display: none;
      }
      &:hover {
        div.popover-body {
          display: block;
        }
      }
    `
    )
  }}
`
const PopoverBodyWrapper: any = styled.div`
  position: absolute;
  min-width: 100%;
  width: auto;
  box-shadow: 0 0 2px 0 ${(props: any) => props.theme.colors.gray7};
  background: ${(props: any) => props.theme.global.background};
  border-radius: 4px;
  ${(props: any) => {
    const variant = splitVariantsToTypes(props)
    return {
      zIndex: props.theme.global.zIndex.popover,
      display: variant.onHover || props.show ? 'block' : 'none',
      ...props.theme.popover.body[variant.position]
    }
  }}
`

const Arrow: any = styled.span`
  position: absolute;
  width: 8px;
  height: 8px;
  border: 1px solid ${(props: any) => props.theme.colors.gray5};
  display: block;
  background: ${(props: any) => props.theme.global.background};
  ${(props: any) => {
    const variant = splitVariantsToTypes(props)
    return {
      zIndex: props.theme.global.zIndex.popoverArrow,
      display: variant.onHover || props.show ? 'block' : 'none',
      ...props.theme.popover.arrow[variant.position]
    }
  }}
`

interface ITempPopover {
  Body: React.ReactDOM
}
interface IPopOver extends IPropsInterface {
  variant?: TPopoverVariant
  disable?: boolean
  children?: string | ReactNode
  Body?: string | ReactNode
}

const Popover: ReactNode = (props: IPopOver) => {
  const wrapperRef = React.useRef(null)
  const [show, setShow] = React.useState(false)

  const onHover = props.variant ? props.variant.includes('hover') : false
  function useOutsideAlerter(ref: any) {
    function handleClickOutside(event: any) {
      event.stopPropagation()
      if (show && ref.current && !ref.current.contains(event.target)) {
        !onHover && !props.disable && setShow(false)
      }
    }
    React.useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    })
  }

  useOutsideAlerter(wrapperRef)
  const childrenWithProps = React.Children.map(props.children, (child: any) => {
    // if (child.type.name === 'PopoverBody') {
    if (typeof child !== 'string') {
      return React.cloneElement(child, {
        parentVariant: props.variant,
        show
      })
    }
    return child
  })
  const OtherProps = refinePropsFromParentEvents(props)
  return (
    <PopoverWrapper
      style={props.style && props.style.wrapper}
      ref={wrapperRef}
      className="popover-body"
      onClick={() => !props.disable && setShow(!show)}
      {...OtherProps}
    >
      {childrenWithProps}
    </PopoverWrapper>
  )
}

const PopoverBody: any = (props: any) => {
  const showArrow =
    (props.variant && props.variant.includes('arrow')) ||
    (props.parentVariant && props.parentVariant.includes('arrow'))
  const OtherProps = refinePropsFromParentEvents(props)
  delete OtherProps.parentVariant
  return (
    <>
      {showArrow && (
        <Arrow
          style={props.style && props.style.arrow}
          className="popover-arrow"
          {...OtherProps}
        >
          &nbsp;
        </Arrow>
      )}
      <PopoverBodyWrapper
        style={props.style && props.style.body}
        name="PopoverBody"
        className="popover-body"
        {...props}
      >
        {props.children}
      </PopoverBodyWrapper>
    </>
  )
}

const PopOver: any = Popover
PopOver.Body = PopoverBody

export default PopOver
