import environment from '../../environment'

let { baseURL } = environment
export const DashBoardRenderingConfig = {
  globalSearchPageId: 'kpi',
  filters: {
    apiFilter: {
      apiURL: `${baseURL}/api/v1/es/search_aggs`,
      queryParams: {},
      apiDataState: {
        noDataMessage: 'No Data Found'
      }
    },
    apiFav: {
      apiURL: `${baseURL}/json_store/records.json`,
      queryParams: {
        type: 'JsonStore::Ui',
        subType: 'globalSearchFavourites',
        pageId: 'kpi'
      },
      apiDataState: {
        noDataMessage: 'No Data Found'
      }
    }
  },
  config: {
    carryDate: true,
    showDatePicker: true,
    showSummary: true,
    toggleContent: [
      {
        label: 'Active Trips',
        title: 'Active Trips',
        selectedKey: 'active'
      },
      {
        label: 'All Trips',
        title: 'All Trips',
        selectedKey: 'all'
      }
    ],
    graphData: {
      apiUrl: `${baseURL}/api/v1/records`,
      queryParams: {
        type: 'JsonStore::Ui',
        subType: 'multiModalKpi'
      }
    },
    kpiColors: {}
  },
  styling: {
    bodyBgColor: '#F0F2F5',
    layoutStyle: { height: 'calc(100vh - 50px)' },
    style: {
      padding: '0px',
      width: '100%',
      overflow: 'hidden',
      boxShadow: 'inset 0px 5px 10px -5px rgba(239,239,239,1)'
    },
    className: '',
    variant: {
      gridGap: '5px'
    }
  }
}
