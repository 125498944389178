import { Flex, layout, styled } from '@fareye/ui'
import {
  capitalize,
  FaBatteryEmpty,
  FaBatteryFull,
  FaBatteryQuarter,
  FaBatteryThreeQuarters,
  FaSignal,
  formatMs,
  hhmmADoMMMYYYY,
  MdPerson,
  pascalCase,
  getValueFromNestedKey,
  formatters
} from '@fareye/utils'
import React from 'react'
// import useWindowDimensions from '../../hooks/useWindowDimensions'
// import { useParams } from 'react-router'
import { HideSlug, RWDLabel, RWDLink, RWDSpan } from '../../style/styled'
import { StoryLine } from './components/StoryLine'
interface IHeader {
  align?: 'left' | 'right' | 'center'
}

const Fa = {
  FaBatteryEmpty,
  FaBatteryFull,
  FaBatteryQuarter,
  FaBatteryThreeQuarters
}

const stringtoReactIconName = (name: string) =>
  name
    .split('-')
    .map(item => capitalize(item))
    .join('')

const ColFullHeight = styled((props: any) => (
  <Flex
    p={2}
    justifyContentSpaceEvenly
    column
    {...props}
    style={{
      height: '100%',
      overflow: 'hidden',
      paddingLeft: 8,
      ...props.style
    }}
  >
    {props.children}
  </Flex>
))`
  ${layout}
`
const FullHeight = styled((props: any) => (
  <Flex
    p={2}
    alignItemsCenter
    {...props}
    style={{ height: '100%', overflow: 'hidden', ...props.style }}
  >
    {props.children}
  </Flex>
))`
  ${layout}
`

const Header = styled(RWDSpan)`
  width: 100%;
  display: block;
  text-transform: uppercase;
  padding: 6px 2px 6px 4px;
  text-align: ${(props: any) => (props.isNumeric ? 'right' : 'left')};
`

function generateColumnObject(ele: any, { links, queryParams }: any) {
  const { width } = window.screen
  const { label, key, sortable, formatter } = ele
  switch (key) {
    case 'expand':
      return {
        Header: () => (
          <div style={{ padding: 0, display: 'none', margin: '0 -5px' }} />
        ),
        sortable: false,
        expander: true,
        width: 0,
        style: { padding: 0, display: 'none' },
        Expander: () => null
      }
    case 'expandHide':
      return {
        Header: () => (
          <div style={{ padding: 0, display: 'none', margin: '0 -5px' }} />
        ),
        show: false,
        sortable: false,
        expander: true,
        width: 0,
        style: { padding: 0, display: 'none' },
        Expander: () => null
      }
    case 'tripIdAndProduct':
      return {
        Header: label,
        sortable: false,
        accessor: 'referenceNo',
        width: 100,
        Cell: ({ row: { original } }: any) => (
          <ColFullHeight>
            <HideSlug data-key-id="slug">{original.slug}</HideSlug>
            <RWDLink
              target={width < 769 ? '_self' : '_blank'}
              variant="xs"
              to={`${links.tripShare}/${original.slug}${queryParams.tripShare ||
                ''}`}
            >
              {original.referenceNo || '-'}
            </RWDLink>
            {!!original.material && original.material !== 'N.A' ? (
              <RWDLabel variant="secondary" style={{ fontWeight: 600 }}>
                {original.material}
              </RWDLabel>
            ) : null}
          </ColFullHeight>
        )
      }
    case 'routeDetails':
      return {
        Header: label,
        width: 70,
        sortable: false,
        Cell: ({ row: { original } }: any) =>
          original.origin && original.destination ? (
            <ColFullHeight justifyContentSpaceEvenly>
              <RWDSpan color="#006491">
                {original.origin ? original.origin.toUpperCase() : ''}
              </RWDSpan>
              <RWDSpan color="#006491">
                {original.destination ? original.destination.toUpperCase() : ''}
              </RWDSpan>
            </ColFullHeight>
          ) : null
      }
    case 'tripID':
      return {
        Header: label,
        sortable: true,
        id: 'referenceNo',
        accessor: 'referenceNo',
        width: 120,
        Cell: ({ row: { original } }: any) => (
          <FullHeight data-id="referenceNo">
            <HideSlug>{original.slug}</HideSlug>
            <RWDLink
              target={width < 769 ? '_self' : '_blank'}
              variant="xs"
              to={`${links.tripShare}/${original.slug}${queryParams.tripShare ||
                ''}`}
            >
              {original.referenceNo || '-'}
            </RWDLink>
          </FullHeight>
        )
      }
    case 'startTimeAndSTA':
      return {
        Header: label,
        sortable: true,
        accessor: 'startTimeAndSTA',
        width: 120,
        Cell: ({ row: { original } }: any) => {
          const datesArray = [
            original.startDate ? hhmmADoMMMYYYY(original.startDate) : '-',
            original.sta ? hhmmADoMMMYYYY(original.sta) : '-'
          ]
          return (
            <ColFullHeight data-id="startDate-sta" justifyContentCenter>
              <StoryLine dataSet={datesArray} />
            </ColFullHeight>
          )
        }
      }
    case 'transitTimeETA':
      return {
        Header: label,
        sortable: false,
        width: 110,
        Cell: ({ row: { original } }: any) => {
          const statusColor = {
            delayed: 'rgb(255, 120, 117)',
            early: 'rgb(105, 192, 255)',
            ontime: 'rgb(149, 222, 100)',
            unknown: 'rgb(196, 29, 127)'
          }
          return (
            <ColFullHeight alignItemsFlexStart style={{ padding: 4 }}>
              {original.status ? (
                <RWDSpan
                  variant="h6"
                  style={{
                    padding: '2px 5px',
                    marginBottom: 4,
                    background: original.status
                      ? statusColor[original.status.toLowerCase()]
                        ? statusColor[original.status.toLowerCase()]
                        : '#ffd740'
                      : '',
                    color: '#fff',
                    borderRadius: 2
                  }}
                >
                  {pascalCase(original.status)}
                  {original.statusTimeDiff
                    ? ` by ${formatMs(original.statusTimeDiff)}`
                    : null}
                </RWDSpan>
              ) : null}
              <RWDSpan mb={1} color="#555">
                {`Target - ${formatMs(Number(original.targetTransitTime))}`}
              </RWDSpan>
              {original.ata ? null : (
                <RWDSpan color="#555">
                  {`Running Since ${formatMs(
                    Number(original.actualTransitTime)
                  )}`}
                </RWDSpan>
              )}
            </ColFullHeight>
          )
        }
      }
    case 'eta':
      return {
        Header: label,
        sortable: true,
        accessor: 'eta',
        width: 80,
        Cell: ({ row: { original } }: any) => {
          let tempTime = original.ata || original.rtEta || original.eta
          let formattedTime = tempTime
            ? hhmmADoMMMYYYY(tempTime).split(' | ')
            : ['', '']
          return (
            <ColFullHeight data-id="ata|rtEta|eta">
              <RWDSpan
                variant="h6"
                style={{ fontWeight: 600, color: '#525252' }}
              >
                {formattedTime[1]}
              </RWDSpan>
              <RWDSpan
                variant="h6"
                style={{ fontWeight: 600, color: '#525252' }}
              >
                {formattedTime[0]}
              </RWDSpan>
            </ColFullHeight>
          )
        }
      }
    case 'exceptions':
      return {
        Header: label,
        sortable: true,
        accessor: 'exceptionsCount',
        width: 60,
        Cell: ({ row: { original } }: any) => (
          <FullHeight justifyContentCenter>
            <RWDLink
              target={width < 769 ? '_self' : '_blank'}
              to={`${links.tripShare}/${original.slug}?tab=${'exceptions'}`}
            >
              <RWDLabel
                variant={`${original.exceptionsCount ? 'danger' : 'secondary'}`}
                style={{ cursor: 'pointer' }}
              >
                {original.exceptionsCount || 0}
              </RWDLabel>
            </RWDLink>
          </FullHeight>
        )
      }
    case 'landmarkEta':
      return {
        Header: label,
        sortable: false,
        width: 150,
        Cell: ({ row: { original } }: any) => {
          return (
            <ColFullHeight>
              <RWDSpan
                style={{ height: 28, marginBottom: 5, whiteSpace: 'normal' }}
                variant="ellipsis"
              >
                {original.currLocation ? original.currLocation.landmark : '-'}
              </RWDSpan>
              {/* <RWDSpan>Last Updated At: </RWDSpan> */}
              <RWDSpan>
                {original.currLocation
                  ? hhmmADoMMMYYYY(original.currLocation.istTimestamp)
                  : '-'}
              </RWDSpan>
            </ColFullHeight>
          )
        }
      }
    case 'tripCompletion':
      return {
        Header: label,
        sortable: true,
        width: 75,
        accessor: 'tripCompletion',
        Cell: ({ row: { original } }: any) => (
          <FullHeight justifyContentCenter>
            <RWDSpan variant="h6">
              {original.tripCompletionPerc
                ? `${
                    original.tripCompletionPerc.toString().includes('.')
                      ? original.tripCompletionPerc.toFixed('2')
                      : original.tripCompletionPerc
                  }%`
                : '-'}
            </RWDSpan>
          </FullHeight>
        )
      }
    case 'driverDetailsETA':
      return {
        Header: label,
        sortable: false,
        accessor: 'driverDetailsETA',
        width: 190,
        Cell: ({ row: { original } }: any) => {
          const Icon =
            original.deviceInfo &&
            Fa[stringtoReactIconName(original.deviceInfo.batteryStrength.icon)]
          return original.origin && original.destination ? (
            <FullHeight
              justifyContentSpaceBetween
              style={{ background: 'rgba(230,233,236,0.5)' }}
            >
              <Flex style={{ width: 150 }}>
                <MdPerson
                  style={{
                    margin: 5,
                    background: '#d1d1d2',
                    borderRadius: '50%',
                    height: '20px',
                    width: '20px'
                  }}
                />
                <Flex column style={{ width: 120, textAlign: 'left' }}>
                  <RWDSpan mb={1} variant="primary">
                    {original.driverName}
                  </RWDSpan>
                  <RWDSpan
                    mb={1}
                    variant="secondary"
                    style={{ fontWeight: 'normal' }}
                  >
                    {original.vehicleNo}
                  </RWDSpan>
                  <RWDLabel
                    variant="secondary ellipsis"
                    style={{ fontWeight: 600, maxWidth: '150px' }}
                  >
                    {original.transporter}
                  </RWDLabel>
                </Flex>
              </Flex>
              {original.deviceInfo ? (
                <ColFullHeight style={{ width: 60, paddingLeft: 5 }}>
                  <Flex alignItemsCenter>
                    <RWDSpan
                      style={{
                        transform: 'rotate(-90deg)',
                        paddingLeft: '5px',
                        borderLeft: '1px solid #d8d8d8'
                      }}
                      title={
                        ['Good', 'Average'].includes(
                          original.deviceInfo.batteryStrength.status
                        )
                          ? 'Good'
                          : 'Poor'
                      }
                    >
                      <Icon
                        color={
                          ['Good', 'Average'].includes(
                            original.deviceInfo.batteryStrength.status
                          )
                            ? '#7dd321'
                            : 'e20a0a'
                        }
                      />
                    </RWDSpan>
                    <RWDSpan>
                      {['Good', 'Average'].includes(
                        original.deviceInfo.batteryStrength.status
                      )
                        ? 'Good'
                        : 'Poor'}
                    </RWDSpan>
                  </Flex>
                  <Flex style={{ paddingTop: 5 }} alignItemsCenter>
                    <RWDSpan
                      color={
                        original.deviceInfo.signalStrength.status === 'Good'
                          ? '#7dd321'
                          : '#e20a0a'
                      }
                      title={
                        original.deviceInfo.signalStrength.status === 'Good'
                          ? 'Good'
                          : 'Poor'
                      }
                    >
                      <FaSignal />
                    </RWDSpan>
                    <RWDSpan style={{ paddingLeft: 5 }}>
                      {original.deviceInfo.signalStrength.status === 'Good'
                        ? 'Good'
                        : 'Poor'}
                    </RWDSpan>
                  </Flex>
                </ColFullHeight>
              ) : null}
            </FullHeight>
          ) : null
        }
      }
    case 'landmark':
      return {
        Header: label,
        sortable: false,
        width: 400,
        Cell: ({ row: { original } }: any) => {
          return (
            <FullHeight>
              <RWDSpan>
                {original.currLocation ? original.currLocation.landmark : '-'}
              </RWDSpan>
            </FullHeight>
          )
        }
      }
    case 'istTimestamp':
      return {
        Header: label,
        sortable: true,
        width: 160,
        accessor: 'istTimestamp',
        Cell: ({ row: { original } }: any) => {
          return (
            <FullHeight>
              <RWDSpan>
                {original.curr_location
                  ? hhmmADoMMMYYYY(original.curr_location.ist_timestamp)
                  : '-'}
              </RWDSpan>
            </FullHeight>
          )
        }
      }
    case 'etaList':
      return {
        Header: label,
        sortable: true,
        accessor: 'eta',
        width: 160,
        Cell: ({ row: { original } }: any) => (
          <FullHeight>
            <RWDSpan>{hhmmADoMMMYYYY(original.rtEta || original.eta)}</RWDSpan>
          </FullHeight>
        )
      }

    case 'battery':
      return {
        Header: label,
        sortable: false,
        width: 70,
        Cell: ({ row: { original } }: any) => {
          const { status } = original?.deviceInfo?.batteryStrength || {}
          return (
            <FullHeight>
              <RWDSpan>{status || '-'}</RWDSpan>
            </FullHeight>
          )
        }
      }
    case 'signalStrength':
      return {
        Header: label,
        sortable: false,
        width: 135,
        Cell: ({ row: { original } }: any) => {
          const { status } = original?.deviceInfo?.signalStrength || {}
          return (
            <FullHeight>
              <RWDSpan>{status || '-'}</RWDSpan>
            </FullHeight>
          )
        }
      }
    case 'startAndEndTime':
      return {
        Header: label,
        sortable: true,
        accessor: 'startDate',
        width: 220,
        Cell: ({ row: { original } }: any) => {
          const datesArray = [
            original.loadingOutTime
              ? hhmmADoMMMYYYY(original.loadingOutTime)
              : '-',
            original.unloadingInTime
              ? hhmmADoMMMYYYY(original.unloadingInTime)
              : '-'
          ]
          return (
            <ColFullHeight justifyContentCenter>
              {original.loadingOutTime || original.unloadingInTime ? (
                <StoryLine dataSet={datesArray} />
              ) : null}
            </ColFullHeight>
          )
        }
      }
    case 'transitTime':
      return {
        Header: label,
        sortable: false,
        width: 120,
        Cell: ({ row: { original } }: any) => {
          const statusColor = {
            delayed: 'rgb(255, 120, 117);',
            early: 'rgb(105, 192, 255);',
            ontime: 'rgb(149, 222, 100);',
            unknown: 'rgb(196, 29, 127);'
          }
          return (
            <ColFullHeight>
              <RWDSpan
                variant="h6"
                color={`${
                  original.status
                    ? statusColor[original.status.toLowerCase()]
                      ? statusColor[original.status.toLowerCase()]
                      : '#ffd740'
                    : ''
                }`}
              >
                {original.status ? pascalCase(original.status) : ''}
              </RWDSpan>
              <RWDSpan mb={1} color="#727272">
                {`Target - ${formatMs(Number(original.targetTransitTime))}`}
              </RWDSpan>
              <RWDSpan mb={1} color="#727272">
                {!original.isActive
                  ? `Actual - ${formatMs(Number(original.actualTransitTime))}`
                  : original.tripCompletionPerc &&
                    `% Completed - ${original.tripCompletionPerc.toFixed(0)}%`}
              </RWDSpan>
            </ColFullHeight>
          )
        }
      }
    case 'driverDetails':
      return {
        Header: label,
        width: 200,
        sortable: false,
        Cell: ({ row: { original } }: any) =>
          original.transporter ? (
            <FullHeight style={{ background: 'rgba(230,233,236,0.5)' }}>
              <MdPerson
                style={{
                  margin: '5px 10px 5px 10px',
                  background: '#d1d1d2',
                  borderRadius: '50%',
                  height: '23px',
                  width: '23px'
                }}
              />
              <Flex column style={{ textAlign: 'left', flexGrow: 1 }}>
                <RWDSpan variant="primary">{original.driverName}</RWDSpan>
                <RWDSpan variant="secondary">{original.vehicleNo}</RWDSpan>
                <RWDLabel
                  variant="secondary ellipsis"
                  style={{ fontWeight: 600, maxWidth: '250px' }}
                >
                  {original.transporter}
                </RWDLabel>
              </Flex>
            </FullHeight>
          ) : null
      }
    case 'vivo':
      return {
        Header: label,
        sortable: false,
        width: 90,
        Cell: ({ row: { original } }: any) => (
          <FullHeight>
            {original.loadingOutTime && original.loadingInTime
              ? formatMs(
                  Number(
                    new Date(original.loadingOutTime).getTime() -
                      new Date(original.loadingInTime).getTime()
                  )
                )
              : '-'}
          </FullHeight>
        )
      }
    case 'plantInOut':
      return {
        Header: label,
        sortable: false,
        accessor: 'gateIn',
        width: 220,
        Cell: ({ row: { original } }: any) => {
          const datesArray = [
            original.loadingInTime
              ? hhmmADoMMMYYYY(original.loadingInTime)
              : '-',
            original.loadingOutTime
              ? hhmmADoMMMYYYY(original.loadingOutTime)
              : '-'
          ]
          return (
            <ColFullHeight justifyContentCenter>
              {original.loadingInTime || original.loadingOutTime ? (
                <StoryLine dataSet={datesArray} />
              ) : null}
            </ColFullHeight>
          )
        }
      }
    case 'vehicleState':
      return {
        Header: label,
        sortable: false,
        width: 150,
        Cell: ({ row: { original } }: any) => {
          const { state } =
            (original.deviceInfo && original.deviceInfo.vehicleState) || {}
          return (
            <FullHeight>
              <RWDSpan>{state === undefined ? '-' : state}</RWDSpan>
            </FullHeight>
          )
        }
      }
    default:
      return {
        Header: label,
        width: 140,
        sortable: sortable ? sortable : true,
        accessor: key,
        Cell: ({ row: { original } }: any) => {
          let value = getValueFromNestedKey(original, key)
          return (
            <ColFullHeight>
              <RWDSpan variant="ellipsis">
                {formatter && formatters[formatter]
                  ? formatters[formatter](value) || '-'
                  : value || (value === 0 ? 0 : '-')}
              </RWDSpan>
            </ColFullHeight>
          )
        }
      }
  }
}
export function getTableColumns(
  reqdColumns: any[],
  { links, queryParams }: any
) {
  const result: any = []
  reqdColumns.map((ele: any) => {
    if (ele.key && !ele.key.includes('expand')) {
      const processedColumns = generateColumnObject(ele, { links, queryParams })
      result.push({
        ...processedColumns,
        id: processedColumns.id || ele.key
      })
    }
  })
  return result
}
