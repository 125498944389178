// FIXME: sizes are not available
import React from 'react'
import styled from 'styled-components'
import { InputProps } from '../../utility/types'

export const responsiveValues: any = (
  size: any,
  value: string[] | number[]
) => {
  return size === 'xxs'
    ? value[0]
    : size === 'xs'
    ? value[1]
    : size === 's'
    ? value[2]
    : size === 'm'
    ? value[3]
    : size === 'l'
    ? value[4]
    : size === 'xl'
    ? value[5]
    : size === 'xxl'
    ? value[6]
    : value[7] || value[2]
}

const Check: any = styled.input`
  position: relative;
  margin: 1px;
  height: ${(props: any) => {
    return responsiveValues(props.variant, [
      '8px',
      '10px',
      '12px',
      '14px',
      '16px',
      '18px',
      '20px',
      '12px'
    ])
  }};
  width: ${(props: any) => {
    return responsiveValues(props.variant, [
      '8px',
      '10px',
      '12px',
      '14px',
      '16px',
      '18px',
      '20px',
      '12px'
    ])
  }};
  border-radius: ${(props: any) => {
    return responsiveValues(props.variant, [
      '1px',
      '1px',
      '2px',
      '3px',
      '4px',
      '5px',
      '5px',
      '2px'
    ])
  }};
  :before,
  :after {
    position: absolute;
    content: '';
  }
  :before {
    background: #fff;
    height: 100%;
    width: 100%;
    left: -1px;
    top: -1px;
    border: 1px solid rgba(128, 128, 128, 0.4);
    border-radius: ${(props: any) => {
      return responsiveValues(props.variant, [
        '2px',
        '2px',
        '2px',
        '3px',
        '3px',
        '4px',
        '4px',
        '2px'
      ])
    }};
  }
  :checked:before {
    background: ${(props: any) => (props.isBlue ? '#006491' : '#fff')};
  }
  :after {
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
  :checked:after {
    top: 15%;
    left: 55%;
    transform: rotateZ(-45deg) translate(-50%, -50%);
    border: ${(props: any) =>
      `${responsiveValues(props.variant, [
        '2px',
        '2px',
        '2px',
        '2px',
        '2px',
        '3px',
        '3px',
        '2px'
      ])} solid ${props.isBlue ? '#fff' : '#444'}`};
    border-top: 0px solid transparent;
    background: transparent;
    border-right: 0px solid transparent;
    height: 25%;
    width: 50%;
  }
  ${(props: any) => props.styles}
`

export const CheckBox = (props: Partial<InputProps>) => {
  const checkProps = { ...props }
  const className = checkProps.className?.split('primary').join('')
  const isBlue = props.className?.includes('primary')
  delete checkProps.className
  return <Check {...checkProps} isBlue={isBlue} className={className} />
}
