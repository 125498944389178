import { Flex, Span, Button } from '@fareye/ui'
import React from 'react'
import { FaTimes, isEmpty } from '@fareye/utils'
import { IListFilters } from '../../../utility/types'
import { IKeyValue } from '@fareye/types'

function ListSearchFIlters({
  setSearch,
  pageId,
  selectedFilter,
  setSelectedFilter
}: IListFilters) {
  function removeSelected(item: IKeyValue<string | number>) {
    const tempSearch = selectedFilter.filter(
      (filter: IKeyValue<string | number>) => {
        return !(filter.key === item.key && filter.value === item.value)
      }
    )
    sessionStorage.setItem(
      'globalSearch',
      JSON.stringify({
        selectedFilter: tempSearch,
        pageId
      })
    )
    setSelectedFilter(tempSearch)
    setSearch(tempSearch)
  }
  const remove = () => {
    setSearch([])
    setSelectedFilter([])
    sessionStorage.removeItem('globalSearch')
  }
  return (
    <Flex
      wrap
      alignItemsFlexStart
      style={{ margin: `${selectedFilter.length > 0 ? '5px 0 0 0' : 0}` }}
    >
      {!isEmpty(selectedFilter) ? (
        <>
          <Span
            m={2}
            width={54}
            style={{ cursor: 'pointer' }}
            variant="primary"
            onClick={() => remove()}
          >
            Clear All
          </Span>
          {selectedFilter.map((item, i) => (
            <Button
              m={1}
              key={i}
              variant="secondary xs"
              rightStyle={{ marginLeft: 5 }}
              rightChild={
                <FaTimes color="#333" onClick={() => removeSelected(item)} />
              }
              style={{
                background: item.label === 'Shipment at Risk' ? '#FFE5E7' : ''
              }}
            >
              {item.label}
            </Button>
          ))}
        </>
      ) : null}
    </Flex>
  )
}

export default ListSearchFIlters
