import {
  AlertsContainer,
  Header,
  IMapper,
  VerticalListing
} from '@fareye/common'
import { IKeyValueObj } from '@fareye/types'
import { Flex, IRenderControl, RenderControl } from '@fareye/ui'
import { currenthhmmA, isEmpty } from '@fareye/utils'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { IPageConfig, IReportData } from '../../../utility/types'
import FormRenderer from './FormRenderer'
import { getTableColumns } from './reportTable/Column'
import SubscriptionListing from './reportTable/SubscriptionListing'

const initReportData = {
  data: [],
  queryString: '',
  postQuery: '',
  meta: {},
  isLoading: false,
  isSuccess: false,
  message: 'Please select filter for Data...',
  isError: false
}

interface IReportSettingProps extends RouteComponentProps, IMapper {
  routesConfig: any
  config: IPageConfig
}

function ReportSettings({
  match,
  history,
  location,
  routesConfig,
  config: propsConfig,
  baseUrl
}: IReportSettingProps) {
  const { styling, config } = propsConfig
  const { defaultReportType, showTimezone = false } = config
  const { bodyBgColor } = styling ? styling : { bodyBgColor: '' }
  const [selectedReportType, setSelectedReportType] = React.useState<string>(
    defaultReportType ? defaultReportType : ('dailyTripReport' as string)
  )
  const [pageNo, setPageNo] = React.useState(0)
  const [reportData, setReportData] = React.useState<IReportData>(
    initReportData
  )
  const [lastUpdate, setLastUpdate] = React.useState(currenthhmmA())
  const [reportConfig, setReportConfig] = React.useState<IRenderControl>({
    data: config,
    isLoading: false,
    isSuccess: true,
    message: '',
    isError: false
  })

  React.useEffect(() => {
    if (!isEmpty(reportConfig.data.reportConfig)) {
      setReportData({
        queryString: '',
        postQuery: '',
        meta: {},
        data: [],
        isLoading: false,
        isSuccess: false,
        message: 'Please submit the form to see the Report Data...',
        isError: false
      })
    }
  }, [selectedReportType])
  let tempReportConfig = reportConfig.data.reportConfig
  let listingOptions: Array<IKeyValueObj<string>> = []
  Object.keys(tempReportConfig).map((ele: string) => {
    listingOptions.push({
      label: tempReportConfig[ele].label,
      value: ele
    })
  })
  return (
    <Flex column width={1} style={{ height: '100%' }}>
      <Header
        lastUpdate={lastUpdate}
        match={match}
        location={location}
        history={history}
        routesConfig={routesConfig}
        showTimezone={showTimezone}
      />
      <div style={{ flexGrow: 1, background: bodyBgColor, overflow: 'hidden' }}>
        <RenderControl state={reportConfig} variant="l">
          {!isEmpty(reportConfig.data) && (
            <AlertsContainer>
              <VerticalListing
                listingOptions={listingOptions}
                setSelected={(data: string) => {
                  setSelectedReportType(data)
                  setReportData(initReportData)
                }}
                selected={selectedReportType}
              />
              <Flex column style={{ overflow: 'auto' }}>
                <FormRenderer
                  selectedReportType={selectedReportType}
                  reportConfig={reportConfig.data}
                  propsConfig={propsConfig}
                  setReportData={setReportData}
                  reportData={reportData}
                  baseUrl={baseUrl}
                  showTimezone={showTimezone}
                  setPageNo={setPageNo}
                />
                <SubscriptionListing
                  subscriptionType={selectedReportType}
                  reportData={reportData}
                  selectedReportConfig={
                    reportConfig.data.reportConfig[selectedReportType]
                  }
                  setReportData={setReportData}
                  selectedColumns={getTableColumns(
                    reportConfig.data.reportConfig[selectedReportType]
                      .selectedColumns,
                    selectedReportType
                  )}
                  baseUrl={baseUrl}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                />
              </Flex>
            </AlertsContainer>
          )}
        </RenderControl>
      </div>
    </Flex>
  )
}

export default ReportSettings
