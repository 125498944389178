import { Flex, Grid, InfoIcon } from '@fareye/ui'
import { formatMs } from '@fareye/utils'
import React from 'react'
import {
  DimensionButton,
  GraphHeading,
  GroupedButton,
  InfoDuration
} from '../../../style/styled'
import { IGraphHeaderConfig } from '../../../utility/types'

const GraphHeader = ({
  config: {
    dataConfig: { graphDataMapping },
    graphConfig
  },
  selectedDimension,
  setSelectedDimension,
  globalFilter
}: IGraphHeaderConfig) => {
  const activeButton = (ele: any) =>
    ele.dimension[graphDataMapping.dimension.value] === selectedDimension
  const getDays = (globalFilter: any) => {
    let timeDiff: any
    try {
      if (globalFilter) {
        const {
          dateRange: { startDate, endDate }
        } = globalFilter
        timeDiff = new Date(endDate).getTime() - new Date(startDate).getTime()
        return timeDiff ? formatMs(timeDiff) : '1 Day'
      }
    } catch (ex) {
      return '1 Day'
    }
  }
  const {
    chartTitle,
    chartDescription,
    hideDateDiff = false,
    dimensions
  } = graphConfig

  return (
    <Flex className="mt122" column>
      <Flex alignItemsCenter>
        <GraphHeading variant="h6" title={chartTitle}>
          {chartTitle}
        </GraphHeading>
        {chartDescription && (
          <InfoIcon
            style={{ marginLeft: 5, marginRight: 5 }}
            title={chartDescription}
          />
        )}
        {/* {!hideDateDiff && (
          <InfoDuration>Last {getDays(globalFilter)}</InfoDuration>
        )} */}
      </Flex>
      {/* {dimensions.length > 1 ? (
        <Flex justifyContentFlexEnd>
          <GroupedButton>
            {dimensions.map((ele, index) => (
              <DimensionButton
                variant={`${activeButton(ele) ? 'h6' : ''}`}
                key={`dimensionButton${index}`}
                onClick={() => {
                  setSelectedDimension(
                    ele.dimension[graphDataMapping.dimension.value]
                  )
                }}
              >
                {ele.dimension[graphDataMapping.dimension.label]}
              </DimensionButton>
            ))}
          </GroupedButton>
        </Flex>
      ) : null} */}
      <Flex justifyContentFlexEnd>
        <GroupedButton>
          {dimensions.length > 1 ? (
            dimensions.map((ele, index) => (
              <DimensionButton
                variant={`${activeButton(ele) ? 'h6' : ''}`}
                key={`dimensionButton${index}`}
                onClick={() => {
                  setSelectedDimension(
                    ele.dimension[graphDataMapping.dimension.value]
                  )
                }}
              >
                {ele.dimension[graphDataMapping.dimension.label]}
              </DimensionButton>
            ))
          ) : (
            <div style={{ padding: '11px' }}></div>
          )}
        </GroupedButton>
      </Flex>
    </Flex>
  )
}
export default GraphHeader
