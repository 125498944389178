import React from 'react'
import styled from 'styled-components'
import { FaTimes } from '@fareye/utils'
import {
  layout,
  Props,
  splitVariantsToTypes,
  MASTER
} from '../Theme/styledSystem'
import { IModalProps } from '../utility/types'

const StyledModal: any = styled.div`
	${(props: IModalProps) => {
    const isDrawer = props.variant && props.variant.includes('drawer')
    const variant = splitVariantsToTypes(props)
    const position = variant.position === '' ? 'Right-Center' : variant.position
    return {
      ...props.theme.modal.modalFrame,
      ...(isDrawer ? props.theme.modal[position].drawerFrame : {})
    }
  }} ${layout} ${(props: IModalProps) => props.styles};
`

const StyledContent: any = styled.div`
  ${(props: IModalProps) => {
    const isDrawer = props.variant && props.variant.includes('drawer')
    const variant = splitVariantsToTypes(props)
    const position = variant.position === '' ? 'Right-Center' : variant.position
    return {
      ...props.theme.modal.content,
      ...(isDrawer ? props.theme.modal[position].drawerContent : {}),
      ...props.theme.modal[variant.size]
    }
  }} ${(props: IModalProps) => props.styles};
`

const StyledCross: any = styled.div.attrs(() => ({ className: 'close-modal' }))`
  ${(props: IModalProps) => ({ ...props.theme.modal.cross, ...props.styles })};
  @media (max-width: 425px) {
    left: 90%;
  }
`

const Modal = (props: IModalProps) => {
  let { children, toggleModal, setToggleModal, style = {} } = Props(props)

  React.useEffect(() => {
    document.addEventListener('keydown', onkeyDown, false)
    document.addEventListener('click', onDocumentClick, false)
    return () => {
      document.removeEventListener('keydown', onkeyDown, false)
    }
  }, [])

  function onDocumentClick(e: any) {
    if (
      typeof e.target.className === 'string' &&
      e.target.className.includes('backdrop')
    ) {
      setToggleModal(false)
    }
  }

  function onkeyDown(e: any) {
    if (e.keyCode === 27) setToggleModal(false)
  }

  const OtherProps = { ...Props(props) }
  delete OtherProps.style
  const isDrawer = props.variant && props.variant.includes('drawer')
  let position = undefined
  MASTER.POSITION.map((pos: string) => {
    if (props.variant && props.variant.indexOf(pos) > -1) {
      position = pos
    }
  })
  const positionClass = position || 'Right-Center'

  return toggleModal ? (
    <StyledModal className="backdrop" {...OtherProps} styles={style.backdrop}>
      <StyledContent
        className={`${isDrawer ? positionClass : ''} ${
          isDrawer ? 'drawer-content' : 'modal-content'
        }`}
        styles={style.content}
        {...OtherProps}
      >
        <StyledCross styles={style.cross} {...OtherProps}>
          <FaTimes onClick={() => setToggleModal(false)} />
        </StyledCross>
        {children}
      </StyledContent>
    </StyledModal>
  ) : null
}

export default Modal
