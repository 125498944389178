import { Flex, IRenderControl } from '@fareye/ui'
import React from 'react'
import { IIssue, IIssueListingApiConfig } from '../../../../utility/types'
import IssueCard from './IssueCard'

interface IIssueList {
  baseUrl: string
  config: IIssueListingApiConfig
  issues: any[]
  resourceType: string
}
const IssueList = ({
  baseUrl,
  config,
  issues,
  resourceType = ''
}: IIssueList) => {
  return (
    <Flex column>
      {issues &&
        issues.map((ele: IIssue, index: number) => {
          return (
            <IssueCard
              config={config}
              key={`issue${index}`}
              issue={ele}
              baseUrl={baseUrl}
              resourceType={resourceType}
            />
          )
        })}
    </Flex>
  )
}
export default IssueList
