import {
  format as fm,
  isEqual,
  isValid,
  startOfDay,
  subMinutes
} from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc, format as tzFormat } from 'date-fns-tz'
import { default as HumanizeDuration } from 'humanize-duration'
import { TTime } from '..'
export { subDays, addMinutes, differenceInSeconds } from 'date-fns'
import enUS from 'date-fns/locale/en-US'
export function format(date: any, formatType: string) {
  return fm(new Date(date), formatType.replace(/D/g, 'd').replace(/Y/g, 'y'))
}
export { zonedTimeToUtc }

const TIME_FMT_24_HRS_KEY = '@IDV:time-format-24-hrs'
// this needs to be implmented for y replacing Y anf d replacing D
// if above replace hack do not works
// currently typings throwing error for below implementation
// https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
// {
//   useAdditionalDayOfYearTokens: true,
//   useAdditionalWeekYearTokens: true
// }

function dateFormatter(val: any) {
  return val ? hhmmADoMMMYYYY(val) : ''
}
export function removedZuluTimeString(date: string) {
  return new Date(date.replace('Z', ''))
}
export function dateFormatterTZ(
  date: string,
  formatString = `YYYY-MM-DD ${timeFormat()}`
) {
  if (date) {
    const removedZuluTime: number | Date = removedZuluTimeString(date)
    return format(removedZuluTime, formatString)
  }
  return ''
}

export const formatters = {
  dateFormatter,
  dateFormatterTZ
}
export const tz = (date = new Date()) =>
  new Date(date.getTime() + date.getTimezoneOffset() * 60000)

export const isToday = (sampleDate: Date) =>
  isEqual(startOfDay(toIST(new Date())), startOfDay(toIST(sampleDate)))

export const isTodayAndEqual = (startDate: Date, endDate: Date): boolean =>
  isEqual(new Date(startDate), new Date(endDate)) &&
  isToday(new Date(startDate))

export const toIST = (inputDate: Date): Date =>
  subMinutes(
    new Date(inputDate),
    Math.abs(new Date(inputDate).getTimezoneOffset())
  )

// ==================== ONLY DATE ====================

export const dMmm = (date = new Date()): string => format(date, 'Do MMM')

export const yyyyMmDd = (date = new Date()) =>
  format(new Date(date), 'YYYY-MM-DD')

export const ddMMMMYYYY = (date = new Date()): string =>
  format(toIST(date), 'DD MMMM, YYYY')

export const ddMMMMYYYYNoIST = (date = new Date()): string =>
  format(date, 'DD MMMM, YYYY')
export const dMmmYY = (date = new Date()): string => format(date, 'D MMM YY')

export const DMYYY = (date = new Date()): string => format(date, 'D-M-YYYY')
// used in date picker onchange event

// ==================== ONLY TIME ====================

// for duration where am pm is not required. Ex - stoppage
export const HHmm = (date = new Date()): string => format(toIST(date), 'HH:mm')

export const hhmmA = (date = new Date()): string =>
  format(toIST(date), timeFormat())

export const hhmmANoIST = (date = new Date()): string =>
  format(date, timeFormat())

// used for last refreshed
export const currenthhmmA = (date = new Date()): string =>
  hhmmA(
    new Date(
      new Date().getTime() + Math.abs(new Date().getTimezoneOffset() * 60000)
    )
  )

// ================== DATE AND TIME ===================

const timeFormat = (includeAmPm = true) => {
  const timeFmt24hrs = localStorage.getItem(TIME_FMT_24_HRS_KEY) === 'true'
  return timeFmt24hrs ? 'HH:mm' : includeAmPm ? 'hh:mm a' : 'hh:mm'
}

// Date 02-03-2020 05:33 AM replace with hhmmADoMMMYYYY
export const ddMMyyyyhhmmA = (date = new Date()): string =>
  format(toIST(date), `DD-MM-YYYY ${timeFormat()}`)

export const ddMMyyyyhhmmANoIST = (date = new Date()): string =>
  format(date, `DD-MM-YYYY ${timeFormat()}`)

export const ddMMyyyyHHmm = (date = new Date()): string =>
  format(toIST(date), `DD-MM-YYYY ${timeFormat(false)}`)

export const ddMMyyyyHHmmNoIST = (date = new Date()): string =>
  format(date, `DD-MM-YYYY ${timeFormat(false)}`)

// Date 21st Jan, 05:33 AM
export const hhmmAdoMMM = (date = new Date()): string =>
  format(toIST(date), `${timeFormat()} | Do MMM`)

// Date 21st Jan, 05:33 AM
export const hhmmAdoMMMNoIST = (date = new Date()): string => {
  return format(date, `${timeFormat()} | Do MMM`)
}

// Date 05:33 AM | 12th Jan, 2020 recommended
export const hhmmADoMMMYYYY = (date = new Date()): string =>
  format(toIST(date), `${timeFormat()} | Do MMM, YYYY`)

export const hhmmADoMMMYYYYNoIST = (date = new Date()): string =>
  format(date, `${timeFormat()} | Do MMM, YYYY`)

// Date 05:33 AM | 12th January, 2020 replace with hhmmADoMMMYYYY
export const hhmmADDMMMMYYYY = (date = new Date()): string =>
  format(toIST(date), `${timeFormat()} | DD MMMM, YYYY`)

export const hhmmADDMMMMYYYYNoIST = (date = new Date()): string =>
  format(date, `${timeFormat()} | DD MMMM, YYYY`)

export const hhmmAddddDDMMYYYY = (date = new Date()) =>
  format(date, `${timeFormat()} a, dddd ,DD-MM-YYYY`)

export const dMHHmm = (date = new Date()): string =>
  format(toIST(date), `D-M ${timeFormat()}`)

export const formatMs = (
  value: number,
  option?: HumanizeDuration.Options,
  time?: TTime
) => {
  option = option || {
    largest: 2,
    round: true
  }
  time = time || 'millisecond'
  switch (time) {
    case 'second':
      value = value * 1000
      break
    case 'minute':
      value = value * 60 * 1000
      break
    case 'hour':
      value = value * 60 * 60 * 1000
      break
    default:
      break
  }

  return HumanizeDuration.humanizer({
    language: 'shortEn',
    round: true,
    units: ['w', 'd', 'h', 'm', 's'],
    languages: {
      shortEn: {
        y: () => 'yr',
        mo: () => 'month',
        w: () => 'w',
        d: () => 'd',
        h: () => 'h',
        m: () => 'm',
        s: () => 's'
      }
    }
  })(value, option)
}

export const durationToMs = (duration: string) => {
  let message: string = ''
  let isError = false
  let millisec: number = 0
  let checkDuplicate: string[] = []
  const convert: any = {
    w: 604800000,
    d: 86400000,
    h: 3600000,
    m: 60000,
    s: 1000
  }

  duration
    .trim()
    .split(' ')
    .map(item => {
      const regexCheck: null | string[] = item
        .trim()
        .match(/([0-9]{1,}[wdmhs]{1}\s?)*/gi)
      if (regexCheck === null || (regexCheck && !regexCheck.includes(item))) {
        isError = true
        message = `Duration pattern is 1w 2d 3h 4m 5s. Invalid ${item}`
      } else {
        return ['w', 'd', 'h', 'm', 's'].map(unit => {
          if (item.includes(unit) && !checkDuplicate.includes(unit)) {
            checkDuplicate.push(unit)
            const time: number = parseInt(item.substr(0, item.indexOf(unit)))
            millisec += time * convert[unit]
          } else if (item.includes(unit) && checkDuplicate.includes(unit)) {
            isError = true
            message = `${unit} is repeated`
          }
        })
      }
      return false
    })
  if (isError) {
    return message
  } else {
    return millisec
  }
}
export const durationBetweenDates = ({ startDate, endDate }: any) => {
  const dateA = new Date(startDate)
  const dateB = new Date(endDate)
  if (dateA && dateB) {
    const status = dateA.getTime() > dateB.getTime() ? '-' : '+'
    const diff = dateA.getTime() - dateB.getTime()
    return {
      duration: formatMs(diff),
      status
    }
  } else {
    throw new Error('Incorrect date format.')
  }
}

export const isValidDate = isValid

//=================== Date and Time Formatting with Timezones==============================//
export enum Formatters {
  //Date Time
  'ddMMyyyyhhmmA' = 'DD-MM-YYYY hh:mm a z', //Date 02-03-2020 05:33 AM
  'hhmmAdoMMM' = 'hh:mm a | do MMM z', // 21st Jan, 05:33 AM
  'hhmmADoMMMYYYY' = 'hh:mm a | Do MMM, yyyy z', // 05:33 AM | 12th Jan, 2020 **recommended**
  'hhmmADDMMMMYYYY' = 'hh:mm a | DD MMMM, yyyy z',

  'ddMMyyyyHHMM' = 'DD-MM-YYYY HH:mm z', //Date 02-03-2020 13:33
  'HHMMdoMMM' = 'HH:mm | do MMM z', // 21st Jan, 15:33
  'HHMMDoMMMYYYY' = 'HH:mm | Do MMM, yyyy z', // 15:33 | 12th Jan, 2020 **recommended**
  'HHMMDDMMMMYYYY' = 'HH:mm | DD MMMM, yyyy z',

  'YYYYMMDDHHmmz' = 'yyyy-MM-dd HH:mm z',
  'dMHHmm' = 'D-M HH:mm z',

  // Date
  'dMmm' = 'Do MMM z',
  'yyyyMmDd' = 'YYYY-MM-DD z',
  'ddMMMMYYYY' = 'do MMMM, yyyy z',
  'dMmmYYz' = 'D MMM YY z',
  'DMYYY' = 'D-M-YYYY z',
  'dMmmYY' = 'd MMM yy',
  //Time
  'HHmm' = 'HH:mm ',
  'hhmma' = 'hh:mm a'
}

type TimeFormat = keyof typeof Formatters

/**
 * @date 2021-05-10
 * @param {string|Date|number} inputDate:string|Date|number
 * @param {Formatters} formatter=Formatters.hhmmADoMMMYYYY
 * @param {string} zoneFormatter='GMT'=> Used for converting  a date to a  zone.
 * @param {Object} zone={locale:enIN ,timeZone:'Asia/Kolkata'}
 * @returns {string | null}
 */
export const dateFormatterWithTimezone = (
  inputDate: string | Date | number,
  zoneFormatter = 'GMT',
  locale = 'enUS',
  zone = { timeZone: 'Asia/Kolkata' },
  formatter: TimeFormat = 'hhmmAdoMMM'
  // zone = { locale: enIN, timeZone: 'Asia/Kolkata' }
) => {
  const timeFmt24hrs = localStorage.getItem(TIME_FMT_24_HRS_KEY) === 'true'
  if (timeFmt24hrs) {
    switch (formatter) {
      case 'ddMMyyyyhhmmA':
        formatter = 'ddMMyyyyHHMM'
        break
      case 'hhmmAdoMMM':
        formatter = 'HHMMdoMMM'
        break
      case 'hhmmADoMMMYYYY':
        formatter = 'HHMMDoMMMYYYY'
        break
      case 'hhmmADDMMMMYYYY':
        formatter = 'HHMMDDMMMMYYYY'
        break
      case 'hhmma':
        formatter = 'HHmm'
        break
      default:
        formatter = formatter
        break
    }
  }
  let format = Formatters[formatter]
    ? Formatters[formatter]
    : Formatters.hhmmAdoMMM

  let date = new Date(inputDate)
  if (isValidDate(date)) {
    date = utcToZonedTime(date, zoneFormatter)
    return tzFormat(date, format, {
      ...zone,
      locale: enUS
    })
  }
  return null
}
