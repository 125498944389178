import { AiFillStop, AiOutlineClockCircle } from 'react-icons/ai'
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaBacon,
  FaExclamationCircle,
  FaGasPump,
  FaIndustry
} from 'react-icons/fa'
import { GiStopSign } from 'react-icons/gi'
import { IoIosLocate, IoIosAirplane, IoMdTime } from 'react-icons/io'
import { MdLocationOn, MdRadioButtonChecked } from 'react-icons/md'
import BlackDotMarker from './../assets/mapMarkers/BlackDotMarker.png'
import GreyVehicleMarker from './../assets/mapMarkers/GreyVehicleMarker.png'
import * as IdleVehicleMarker from './../assets/mapMarkers/IdleVehicleMarker.png'
import * as MovingVehicleMarker from './../assets/mapMarkers/MovingVehicleMarker.png'
import * as OverspeedMarker from './../assets/mapMarkers/OverspeedMarker.png'
import * as StoppageMarker from './../assets/mapMarkers/StoppageMarker.png'
import * as StoppedVehicleMarker from './../assets/mapMarkers/StoppedVehicleMarker.png'
import * as RoadTollMarker from './../assets/mapMarkers/RoadTollMarker.png'
import * as TRAINICON from './../assets/mapMarkers/train.png'
import * as VehicleMarker from './../assets/mapMarkers/VehicleMarker.png'
import * as _10TYRETRUCK from './../assets/vehicleIcons/10TYRETRUCK.png'
import * as _2AXLETRAILER from './../assets/vehicleIcons/2AXLETRAILER.png'
import * as _3AXLETRAILER from './../assets/vehicleIcons/3AXLETRAILER.png'
import * as CANTER from './../assets/vehicleIcons/CANTER.png'
import * as CARCARRIER from './../assets/vehicleIcons/CARCARRIER.png'
import * as CEMENT from './../assets/vehicleIcons/CEMENT.png'
import * as CONTAINER from './../assets/vehicleIcons/CONTAINER.png'
import * as CRANE from './../assets/vehicleIcons/CRANE.png'
import * as HYDRA from './../assets/vehicleIcons/HYDRA.png'
import * as HYDRAULICTRAILER from './../assets/vehicleIcons/HYDRAULICTRAILER.png'
import * as JEEP from './../assets/vehicleIcons/JEEP.png'
import * as LOWBED from './../assets/vehicleIcons/LOWBED.png'
import * as LOWBEDTRAILER from './../assets/vehicleIcons/LOWBEDTRAILER.png'
import * as PICKUPVAN from './../assets/vehicleIcons/PICKUPVAN.png'
import * as REFRIGERATED from './../assets/vehicleIcons/REFRIGERATED.png'
import * as SCOOTER from './../assets/vehicleIcons/SCOOTER.png'
import * as TANKER from './../assets/vehicleIcons/TANKER.png'
import * as TATA407 from './../assets/vehicleIcons/TATA407.png'
import * as TATA709 from './../assets/vehicleIcons/TATA709.png'
import * as TEMPO from './../assets/vehicleIcons/TEMPO.png'
import * as TIPPER from './../assets/vehicleIcons/TIPPER.png'
import * as TRAILER from './../assets/vehicleIcons/TRAILER.png'
import * as TRAILOR from './../assets/vehicleIcons/TRAILOR.png'
import * as TRUCK from './../assets/vehicleIcons/TRUCK.png'

export {
  FaArrowDown,
  FaArrowLeft,
  FaArrowUp,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaBars,
  FaBatteryEmpty,
  FaBatteryFull,
  FaBatteryQuarter,
  FaBatteryThreeQuarters,
  FaBell,
  FaBellSlash,
  FaBolt,
  FaBox,
  FaBoxes,
  FaBoxOpen,
  FaCalendar,
  FaCalendarAlt,
  FaCaretDown,
  FaCaretLeft,
  FaCaretUp,
  FaCaretRight,
  FaChartArea,
  FaChartBar,
  FaChartLine,
  FaChartPie,
  FaCheck,
  FaCheckCircle,
  FaChevronUp,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaCircle,
  FaClock,
  FaCopy,
  FaCog,
  FaCommentDots,
  FaDollyFlatbed,
  FaDotCircle,
  FaDownload,
  FaDrawPolygon,
  FaEllipsisH,
  FaEllipsisV,
  FaExclamationTriangle,
  FaExpand,
  FaExternalLinkAlt,
  FaEye,
  FaEyeSlash,
  FaFile,
  FaFileDownload,
  FaFilePdf,
  FaFilter,
  FaFlag,
  FaHome,
  FaRegImage,
  FaFileImage,
  FaFileWord,
  FaImage,
  FaInbox,
  FaInfo,
  FaInfoCircle,
  FaList,
  FaMapMarked,
  FaMapMarkerAlt,
  FaMobile,
  FaMoneyCheckAlt,
  FaPaperclip,
  FaPencilAlt,
  FaPlane,
  FaPlay,
  FaPlus,
  FaPlusCircle,
  FaRedo,
  FaRegCircle,
  FaRegDotCircle,
  FaRegMinusSquare,
  FaRegPlusSquare,
  FaRoute,
  FaSearch,
  FaSearchLocation,
  FaShareAlt,
  FaShip,
  FaSignal,
  FaSortDown,
  FaSortUp,
  FaSpinner,
  FaStar,
  FaStopCircle,
  FaSubway,
  FaSync,
  FaTable,
  FaTachometerAlt,
  FaTimes,
  FaTimesCircle,
  FaTrash,
  FaTruck,
  FaTruckLoading,
  FaTruckMoving,
  FaUserCircle,
  FaWindowClose,
  FaAngleDown,
  FaAngleUp,
  FaEnvelope,
  FaRegFlag,
  FaAngleRight,
  FaLeaf,
  FaExclamationCircle
} from 'react-icons/fa'
export { FiInfo, FiTruck } from 'react-icons/fi'
export { RiShipLine } from 'react-icons/ri'
export {
  MdBookmark,
  MdBookmarkBorder,
  MdFeaturedPlayList,
  MdFlag,
  MdList,
  MdMap,
  MdMessage,
  MdNearMe,
  MdPerson,
  MdPowerSettingsNew,
  MdShare,
  MdShoppingCart,
  MdStar,
  MdStarBorder,
  MdTextsms,
  MdTrendingUp,
  MdUnfoldMore,
  MdDirectionsRailway,
  MdFlight,
  MdTrain,
  MdDirectionsBoat,
  MdLocalShipping,
  MdFileDownload,
  MdFileUpload,
  MdFilterList
} from 'react-icons/md'
export { TiLocationArrowOutline } from 'react-icons/ti'
export {
  FaBacon,
  FaGasPump,
  FaIndustry,
  GiStopSign,
  AiFillStop,
  AiOutlineClockCircle,
  IoIosLocate,
  IoIosAirplane,
  IoMdTime,
  MdLocationOn,
  FaArrowAltCircleUp,
  MdRadioButtonChecked,
  FaArrowAltCircleDown
}
export const defaultVehicle = VehicleMarker
export const blackDotMarker = BlackDotMarker
export const dipperTruckMarker = VehicleMarker
export const stoppageMarker = StoppageMarker
export const overspeedMarker = OverspeedMarker
export const roadTollMarker = RoadTollMarker

export const trainIcon = TRAINICON

export const iconByState = {
  idle: IdleVehicleMarker,
  moving: MovingVehicleMarker,
  stopped: StoppedVehicleMarker,
  none: GreyVehicleMarker
}

export const iconsByVehicleSubtype = {
  '10TYRE TRUCK': _10TYRETRUCK,
  '2AXLETRAILER': _2AXLETRAILER,
  '3AXLETRAILER': _3AXLETRAILER,
  BULKER: '',
  CANTER,
  CARCARRIER,
  CEMENT,
  CHAMPION: '',
  CONTAINER,
  CRANE,
  DUMMY: '',
  FLATBED: '',
  FULLBODY: '',
  HALFBODY: '',
  HYDRA,
  HYDRAULICTRAILER,
  JEEP,
  LONGBED: '',
  LOWBED,
  LOWBEDTRAILER,
  OPEN: '',
  OPENTRUCK: '',
  PICKUPVAN,
  PLATFORM: '',
  REFRIGERATED,
  SCOOTER,
  SHORTBED: '',
  SILO: '',
  TANKER,
  TATA407,
  TATA709: TATA709,
  TATAACE: '',
  TEMPO,
  TIPPER,
  TRAILER,
  TRAILOR,
  TRUCK,
  TURBO: ''
}
export const poiTypeIcon: any = {
  altloading: FaArrowAltCircleUp,
  altunloading: FaArrowAltCircleDown,
  authorized_stoppage: GiStopSign,
  border: FaBacon,
  calculated_unloading: FaArrowAltCircleDown,
  hub: MdRadioButtonChecked,
  'In Plant Poi': FaIndustry,
  iocl: MdLocationOn,
  loading: FaArrowAltCircleUp,
  Loading: FaArrowAltCircleUp,
  loading_city: FaArrowAltCircleUp,
  'Loading Point': FaArrowAltCircleUp,
  'Other Point of Interest': IoIosLocate,
  'Petrol Pump': FaGasPump,
  poi: IoIosLocate,
  primary: MdLocationOn,
  'State Border': FaBacon,
  toll_plaza: AiFillStop,
  unauthorized_stoppage: FaExclamationCircle,
  unloading: FaArrowAltCircleDown,
  unloading_city: FaArrowAltCircleDown,
  'Unloading Point': FaArrowAltCircleDown
}
