import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import React from 'react'
import { Marker, Polygon, Polyline, Popup, Tooltip } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'react-leaflet-markercluster/dist/styles.min.css'
import {
  CustomMarkerInterface,
  CustomPolygonInterface,
  CustomPolyLineInterface,
  MapRendererProps
} from './types'
import MapView from './MapView'

function MapRenderer({
  markers,
  clustering = true,
  config,
  polygons,
  polyLines,
  leafletMapConfig
}: MapRendererProps) {
  const ClusterContainer: any = clustering ? MarkerClusterGroup : 'div'
  function getMarkerDetailsType(
    marker: CustomMarkerInterface | CustomPolygonInterface
  ) {
    const MarkerDetailsType: any =
      marker.markerDetailType === 'tooltip' ? Tooltip : Popup
    return (
      <MarkerDetailsType>{marker.markerDetailsComponent}</MarkerDetailsType>
    )
  }
  function getMarkerIcon(marker: CustomMarkerInterface) {
    if (marker.iconType === 'icon') {
      return L.icon({ ...marker.icon })
    }
    return L.divIcon({ ...marker.icon })
  }
  return (
    <MapView
      mapKey={config ? config.mapKey : ''}
      defaultBaseLayer={config ? config.env : ''}
      leafletMapConfig={leafletMapConfig}
    >
      <>
        <ClusterContainer>
          <>
            {markers &&
              markers.map((ele: CustomMarkerInterface, index: number) => (
                <Marker
                  position={L.latLng(ele.lat, ele.long)}
                  icon={getMarkerIcon(ele)}
                  key={`${ele.key + index}`}
                  ref={e => ele.markerRef && ele.markerRef(e, ele.selected)}
                >
                  {getMarkerDetailsType(ele)}
                </Marker>
              ))}
            )
            {polygons &&
              polygons.map((ele: CustomPolygonInterface, index: number) => (
                <Polygon
                  positions={ele.positions}
                  color={ele.color}
                  key={`${ele.key + index}`}
                >
                  {getMarkerDetailsType(ele)}
                </Polygon>
              ))}
            {polyLines &&
              polyLines.map((ele: CustomPolyLineInterface, index: number) => (
                <Polyline
                  positions={ele.positions}
                  color={ele.color}
                  key={`${ele.key + index}`}
                >
                  {getMarkerDetailsType(ele)}
                </Polyline>
              ))}
          </>
        </ClusterContainer>
      </>
    </MapView>
  )
}

export default MapRenderer
