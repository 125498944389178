import {
  Header,
  KPI_COLORS,
  SearchFilterWrapper,
  setDateFromStorage
} from '@fareye/common'
import {
  GraphRenderer,
  IApiChartConfig,
  IGraphRendererConfig
} from '@fareye/kpi'
import { IDateRange, IRouteConfig } from '@fareye/types'
import { DatePicker, Flex, RenderControl, usePrevious } from '@fareye/ui'
import {
  cancelToken,
  cloneDeep,
  currenthhmmA,
  format,
  getQueryParams,
  groupBy,
  isEmpty,
  isEqual,
  post,
  yyyyMmDd
} from '@fareye/utils'
import queryString, { ParsedQuery } from 'query-string'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import ScoreCard from '../../components/Scorecard'
import { GraphGrid, HorizontalScrollGrid } from '../../styled/styled'
import { GRAPH_DATA_MAPPING, SHIPMENT_TYPES } from '../../utility/constants'
//import KPI_CONFIG from '../OceanStatsKpi.json'
import FullScreenComponent, { pageId } from './GraphFullScreen'
interface IKpi extends RouteComponentProps {
  routesConfig?: Partial<IRouteConfig>[]
  config: any
}

const DataQualityDashboard = ({
  match,
  location,
  history,
  routesConfig,
  config: dashboardConfig
}: IKpi) => {
  const searchParams: ParsedQuery<string | Date> = queryString.parse(
    location.search
  )
  const { filters, config, KPI_CONFIG } = dashboardConfig
  const [dateRange, setDateRange] = React.useState(() => {
    return setDateFromStorage({
      showDatePicker: true,
      carryDate: true,
      searchParams,
      daysDiff: 10
    })
  })
  const [selectedShipmentType, setSelectedShipmentType] = React.useState(
    SHIPMENT_TYPES[0]
  )
  const [selectedKey, setSelectedKey] = React.useState('all')
  const source = cancelToken().source()
  const [lastUpdate, setLastUpdate] = React.useState<string>('')
  const [loading, setLoading] = React.useState(false)
  //baseURL = 'http://localhost:3001'
  const [selectedFilter, setSelectedFilter] = React.useState<any>([])
  const dataConfig = {
    getDataForChart: getDataForChartFromServer,
    graphDataMapping: GRAPH_DATA_MAPPING
  }

  const { toggleContent } = config
  const previousDateRange = usePrevious(dateRange)
  const [graphRenderListing, setGraphRenderListing] = React.useState<
    IGraphRendererConfig[]
  >([])
  const [scoreCardRenderingList, setScoreCardRenderingList] = React.useState<
    IGraphRendererConfig[]
  >([])
  const apiFav = filters.apiFav

  const apiFilter = React.useMemo(() => {
    let filterConfig = {
      ...filters.apiFilter,
      queryParams: { data_source: selectedShipmentType.dataSource }
    }
    return filterConfig
  }, [selectedShipmentType])

  React.useEffect(() => {
    setLoading(true)
    fetchConfig()
  }, [selectedShipmentType])

  React.useEffect(() => {
    if (!isEmpty(graphRenderListing)) {
      const clonedGraphRenderListing = cloneDeep(graphRenderListing)
      clonedGraphRenderListing.forEach(
        ele =>
          (ele.globalFilter = {
            dateRange: dateRange,
            selectedFilter: selectedFilter,
            selectedKey
          })
      )
      setGraphRenderListing(clonedGraphRenderListing)
      const clonedScoreCardListing = cloneDeep(scoreCardRenderingList)
      clonedScoreCardListing.forEach(
        ele =>
          (ele.globalFilter = {
            dateRange: dateRange,
            selectedFilter: selectedFilter,
            selectedKey
          })
      )
      setScoreCardRenderingList(clonedScoreCardListing)

      if (!isEqual(previousDateRange, dateRange)) {
        const changedQueryParam = getQueryParams({
          ...dateRange
        })
        history.replace({
          pathname: match.url,
          search: changedQueryParam
        })
      }
    }
  }, [dateRange, selectedFilter, selectedKey])
  const globalFilter = React.useMemo(() => {
    return {
      dateRange: dateRange,
      selectedFilter: selectedFilter,
      selectedKey,
      dataSource: selectedShipmentType
    }
  }, [dateRange, selectedFilter, selectedKey, selectedShipmentType])
  async function fetchConfig() {
    let { graphData } = config
    try {
      //   const queryParam = getQueryParams(graphData.queryParams)
      //   const jsonUrl = `${graphData.apiUrl}?${queryParam}`
      //   //const jsonUrl = `http://localhost:3001/data`
      //   const { data } = await get(jsonUrl, source.token)
      //   //const kpiRenderingConfig = KPI_CONFIG
      //   const kpiRenderingConfig = isEmpty(data.configs)
      //     ? data.samples
      //     : data.configs
      console.log(KPI_CONFIG)
      let kpiData = KPI_CONFIG.sort(
        (next: any, pre: any) => next.data.order - pre.data.order
      ).map((ele: any) => ele.data)
      kpiData.map((ele: IGraphRendererConfig) => {
        ele.dataConfig = dataConfig
        ele.graphConfig.colors = { ...KPI_COLORS, ...ele.graphConfig.colors }
        ele.graphConfig.dataSource = selectedShipmentType
        ele.globalFilter = {
          dateRange: dateRange,
          selectedFilter: selectedFilter
        }
        return false
      })
      let scoreCardList = kpiData.filter(
        (ele: any) => ele.graphConfig.chartType === 'scorecard'
      )
      kpiData = kpiData.filter(
        (ele: any) => ele.graphConfig.chartType !== 'scorecard'
      )
      setScoreCardRenderingList(scoreCardList)
      setGraphRenderListing(kpiData)
      setLoading(false)
    } catch (ex) {
      setLoading(false)
    }
  }
  function laneWise() {
    let data = [
      { label: 'Shanghai - Vancouver', value: 91 },
      { label: 'Vancouver - sparks', value: 88.4 },
      { label: 'Shanghai - Busan', value: 87.1 },
      { label: 'PUERTO QUETZAL - Yokohama', value: 86 },
      { label: 'Rotterdam - Qingdao', value: 85.7 },
      { label: 'Sparks - Phoenix', value: 84.2 },
      { label: 'Topennish - Eugene', value: 82 },
      { label: 'Sparks - Sacramento', value: 81.6 },
      { label: 'Topennish - Portland', value: 79.3 },
      { label: 'Hong Kong - New York', value: 77.9 }
    ]
    return data
  }
  function tripTracking() {
    let data = [
      { label: 'Trips Tracked', value: 395 },
      { label: 'Trips Untracked', value: 117 }
    ]
    return data
  }
  function carrierTracking() {
    let data = [
      { label: 'Pacella trucking co', value: 91 },
      { label: 'Saia trucking', value: 88 },
      { label: 'UPS', value: 84 },
      { label: 'Esco trans', value: 82 },
      { label: 'Landstar', value: 80 },
      { label: 'Maersk Line', value: 79 },
      { label: 'Locomov', value: 78 },
      { label: 'Ryder trucking', value: 78 },
      { label: 'CGM- CDM', value: 76 },
      { label: 'JB hunt', value: 75 }
    ]
    return data
  }
  function activeData() {
    let data = [
      {
        label: 'Pacella trucking co',
        value: 0,
        breakDownDimension: [
          { label: 'Low consistency (<50%)', value: 15 },
          { label: 'Average consistency (50%-74%)', value: 20 },
          { label: 'Good Consistency (75%-89%)', value: 28 },
          { label: 'High consistency (>90%)', value: 35 }
        ]
      },
      {
        label: 'Saia trucking',
        value: 0,
        breakDownDimension: [
          { label: 'Low consistency (<50%)', value: 10 },
          { label: 'Average consistency (50%-74%)', value: 23 },
          { label: 'Good Consistency (75%-89%)', value: 23 },
          { label: 'High consistency (>90%)', value: 40 }
        ]
      },
      {
        label: 'UPS',
        value: 0,
        breakDownDimension: [
          { label: 'Low consistency (<50%)', value: 13 },
          { label: 'Average consistency (50%-74%)', value: 21 },
          { label: 'Good Consistency (75%-89%)', value: 23 },
          { label: 'High consistency (>90%)', value: 31 }
        ]
      },
      {
        label: 'Esco trans',
        value: 0,
        breakDownDimension: [
          { label: 'Low consistency (<50%)', value: 15 },
          { label: 'Average consistency (50%-74%)', value: 19 },
          { label: 'Good Consistency (75%-89%)', value: 28 },
          { label: 'High consistency (>90%)', value: 25 }
        ]
      },
      {
        label: 'Landstar',
        value: 0,
        breakDownDimension: [
          { label: 'Low consistency (<50%)', value: 10 },
          { label: 'Average consistency (50%-74%)', value: 37 },
          { label: 'Good Consistency (75%-89%)', value: 25 },
          { label: 'High consistency (>90%)', value: 13 }
        ]
      },
      {
        label: 'Maersk Line',
        value: 0,
        breakDownDimension: [
          { label: 'Low consistency (<50%)', value: 26 },
          { label: 'Average consistency (50%-74%)', value: 18 },
          { label: 'Good Consistency (75%-89%)', value: 23 },
          { label: 'High consistency (>90%)', value: 14 }
        ]
      },
      {
        label: 'Locomov',
        value: 0,
        breakDownDimension: [
          { label: 'Low consistency (<50%)', value: 28 },
          { label: 'Average consistency (50%-74%)', value: 22 },
          { label: 'Good Consistency (75%-89%)', value: 12 },
          { label: 'High consistency (>90%)', value: 16 }
        ]
      },
      {
        label: 'Ryder trucking',
        value: 0,
        breakDownDimension: [
          { label: 'Low consistency (<50%)', value: 19 },
          { label: 'Average consistency (50%-74%)', value: 18 },
          { label: 'Good Consistency (75%-89%)', value: 24 },
          { label: 'High consistency (>90%)', value: 15 }
        ]
      },
      {
        label: 'CGM- CDM',
        value: 0,
        breakDownDimension: [
          { label: 'Low consistency (<50%)', value: 24 },
          { label: 'Average consistency (50%-74%)', value: 19 },
          { label: 'Good Consistency (75%-89%)', value: 25 },
          { label: 'High consistency (>90%)', value: 6 }
        ]
      },
      {
        label: 'JB hunt',
        value: 0,
        breakDownDimension: [
          { label: 'Low consistency (<50%)', value: 36 },
          { label: 'Average consistency (50%-74%)', value: 14 },
          { label: 'Good Consistency (75%-89%)', value: 15 },
          { label: 'High consistency (>90%)', value: 8 }
        ]
      }
    ]
    return data
  }

  function healthCarriers() {
    let data = [
      {
        carrierName: 'A.D Transport express ',
        model: 'FTL',
        lastUpdatedAt: '7 days ago',
        health: 'Very Poor'
      },
      {
        carrierName: 'Seaboard',
        model: 'Ocean',
        lastUpdatedAt: '3 days ago',
        health: 'Very poor'
      },
      {
        carrierName: 'Pacella trucking co',
        model: 'FTL',
        lastUpdatedAt: '2 day ago',
        health: 'Very poor'
      },
      {
        carrierName: 'Saia trucking',
        model: 'FTL',
        lastUpdatedAt: '1 day ago',
        health: 'Poor'
      },
      {
        carrierName: 'Esco trans',
        model: 'FTL',
        lastUpdatedAt: '15 hours ago',
        health: 'Poor'
      },
      {
        carrierName: 'Locomov',
        model: 'CEP',
        lastUpdatedAt: '3 hours ago',
        health: 'Average'
      },
      {
        carrierName: 'Ryder trucking',
        model: 'FTL',
        lastUpdatedAt: '2 hours ago',
        health: 'Average'
      },
      {
        carrierName: 'CGM- CDM',
        model: 'Ocean',
        lastUpdatedAt: '1 hour ago',
        health: 'Good'
      }
    ]
    return data.map(ele => {
      return {
        label: '',
        value: '',
        ...ele
      }
    })
  }
  function apiConnectorQuality() {
    let data = [
      {
        apiName: 'Shipment',
        platform: '611370',
        external: '34570',
        success: '97.4%',
        responseTime: '500ms'
      },
      {
        apiName: 'Journey',
        platform: '529345',
        external: '529842',
        success: '95.6%',
        responseTime: '450ms'
      },
      {
        apiName: 'Fetch Tracking',
        platform: '529842',
        external: '439842',
        success: '92.2%',
        responseTime: '300ms'
      },
      {
        apiName: 'Shipment Event',
        platform: '534242',
        external: '529835',
        success: '89.8%',
        responseTime: '480ms'
      },
      {
        apiName: 'Alert Event',
        platform: '529842',
        external: '529842',
        success: '87.3%',
        responseTime: '600ms'
      }
    ]
    return data.map(ele => {
      return {
        label: '',
        value: '',
        ...ele
      }
    })
  }

  async function getDataForChartFromServer(
    value: Partial<IApiChartConfig>,
    globalFilter: any,
    isReport?: boolean
  ) {
    const { graphDataMapping } = dataConfig
    const {
      dataSource,
      metric,
      metrics,
      hideDimension,
      metricUnit,
      dimension,
      breakdownDimension: breakdownDimensionFromKpi,
      benchmarkDimension: benchmarkDimensionFromKpi,
      filter: filterFromKpi,
      sortKey,
      sortType,
      size,
      chartType
    } = value
    const { dateRange, selectedFilter, selectedKey }: any = globalFilter || {}
    let dataSourceValue =
      chartType === 'scorecard'
        ? dimension.dimension.value
        : dimension[graphDataMapping['dimension'].value]
    if (dataSourceValue === 'etaAccuracy') {
      return [{ label: 'Overall ETA Accuracy', value: 83.2 }]
    } else if (dataSourceValue === 'oceanETAAccuracy') {
      return [{ label: 'Overall ETA Accuracy', value: 78.1 }]
    } else if (dataSourceValue === 'otrETAAccuracy') {
      return [{ label: 'Overall ETA Accuracy', value: 84.4 }]
    } else if (dataSourceValue === 'cepETAAccuracy') {
      return [{ label: 'Overall ETA Accuracy', value: 89.3 }]
    } else if (dataSourceValue === 'modalETAAccuracy') {
      return [{ label: 'Overall ETA Accuracy', value: 79.7 }]
    } else if (dataSourceValue === 'platformUpTime') {
      return [{ label: 'Overall ETA Accuracy', value: 99.98 }]
    } else if (dataSourceValue === 'laneWise') {
      return laneWise()
    } else if (dataSourceValue === 'tripTracking') {
      return tripTracking()
    } else if (dataSourceValue === 'carrierTracking') {
      return carrierTracking()
    } else if (dataSourceValue === 'activeData') {
      return activeData()
    } else if (dataSourceValue === 'healthCarriers') {
      return healthCarriers()
    } else if (dataSourceValue === 'apiConnectorQuality') {
      return apiConnectorQuality()
    }
    let breakDownDimension, benchmarkDimension, sort, metricCustom
    if (breakdownDimensionFromKpi) {
      breakDownDimension = {
        label: breakdownDimensionFromKpi['value']
      }
    }
    metricCustom = {
      key: metric.aggKey['value'],
      type: metric.aggType['value'],
      unit: metricUnit ? metricUnit['value'] : ''
    }
    if (benchmarkDimensionFromKpi) {
      benchmarkDimension = {
        ...metricCustom,
        value: metric.value,
        breakDownDimension: metric.breakDownDimension
      }
    }

    sort = {
      ...(sortType ? { order: sortType['value'] } : {}),
      sortable: sortKey ? 'metric' : 'dimension',
      size: size || 10
    }
    let filter = filterFromKpi
      ? filterFromKpi.map(filterList => {
          return filterList.map(ele => {
            return {
              filterKey: ele.filterKey['value'],
              filterValue: ele.filterValue['value'],
              condition: ele.condition['value'],
              filterType: ele.filterType['value']
            }
          })
        })
      : []
    filter.push([
      {
        filterKey: 'start_date',
        filterValue: dateRange ? yyyyMmDd(dateRange.startDate) : new Date(),
        //filterValue: '2019-09-10',
        condition: 'include',
        filterType: 'gte'
      }
    ])
    filter.push([
      {
        filterKey: 'start_date',
        filterValue: dateRange ? yyyyMmDd(dateRange.endDate) : new Date(),
        //filterValue: '2019-09-10',
        condition: 'include',
        filterType: 'lte'
      }
    ])
    if (!isEmpty(selectedFilter)) {
      const groupedSearch = groupBy(selectedFilter, ele => ele.key)
      const data = Object.entries(groupedSearch).map(([key, value]) => {
        return [
          {
            filterKey: key,
            filterValue: value.reduce((res = [], obj) => {
              res.push(obj.value)
              return res
            }, []),
            condition: 'include',
            filterType: 'eq'
          }
        ]
      })
      filter = [...filter, ...data]
    }
    if (selectedKey === 'active') {
      filter.push([
        {
          filterKey: 'is_active',
          filterValue: true,
          condition: 'include',
          filterType: 'eq'
        }
      ])
    }
    let query = {
      dataSource: dataSource[graphDataMapping['dataSource'].value],
      ...(!isEmpty(breakDownDimension)
        ? {
            breakDownDimension,
            ...{
              ...(metricCustom.key === 'value_count'
                ? {}
                : { metrics: [metricCustom] })
            }
          }
        : isEmpty(benchmarkDimension)
        ? { metrics: [metricCustom] }
        : {}),
      ...(hideDimension
        ? {}
        : {
            dimension: {
              label: dimension[graphDataMapping['dimension'].value]
            }
          }),
      //   ...(breakDownDimension && { breakDownDimension }),
      ...(benchmarkDimension && { benchmarkDimension }),
      sort,
      filter
    }

    try {
      const token = cancelToken().source().token
      const { data } = await post(
        `/api/v1/es/kpi_${isReport ? 'results' : 'aggs'}`,
        query,
        token
      )
      setLastUpdate(currenthhmmA())
      return isReport ? data.results : data.aggs
    } catch (ex) {
      setLastUpdate(currenthhmmA())
      return []
    }
  }

  return (
    <Flex column>
      <Header
        lastUpdate={lastUpdate}
        match={match}
        location={location}
        history={history}
        routesConfig={routesConfig}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        toggle={toggleContent}
        selectedKey={selectedKey}
        setSelectedKey={setSelectedKey}
        rightTopCorner={
          <DatePicker
            onDateRangeSelected={(dateRange: IDateRange<string | Date>) => {
              localStorage.setItem('date', JSON.stringify(dateRange))
              setDateRange(dateRange as IDateRange<Date>)
            }}
            formatter={yyyyMmDd}
            value={dateRange}
            name="dateFilter"
            variant="fareyeV2"
          />
        }
      >
        <Flex width={1}>
          <SearchFilterWrapper
            apiFav={apiFav}
            pageId={pageId}
            apiFilter={apiFilter}
            search={selectedFilter}
            setSearch={setSelectedFilter}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
          />
        </Flex>
      </Header>
      <RenderControl
        data={graphRenderListing}
        isSuccess={!loading && !isEmpty(graphRenderListing)}
        isLoading={loading}
        variant={'l'}
        message={
          !isEmpty(graphRenderListing)
            ? ''
            : 'Something went wrong please try after sometime ......'
        }
      >
        <HorizontalScrollGrid>
          {scoreCardRenderingList.map((ele, index) => {
            return (
              <ScoreCard
                valueStyle={{ color: '#00539A' }}
                key={`scorecard-${index}`}
                {...ele}
              ></ScoreCard>
            )
          })}
        </HorizontalScrollGrid>
        <GraphGrid>
          {graphRenderListing.map((ele, index) => {
            return (
              <GraphRenderer
                key={`kpi${index}`}
                {...ele}
                style={{
                  ...ele.style,
                  wrapper: {
                    ...(ele.style && ele.style.wrapper)
                  }
                }}
                FullScreenComponent={
                  <FullScreenComponent
                    dashboardConfig={dashboardConfig}
                    config={ele}
                    location={location}
                    history={history}
                    match={match}
                  />
                }
              />
            )
          })}
        </GraphGrid>
      </RenderControl>
    </Flex>
  )
}
export default DataQualityDashboard
