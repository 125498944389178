import React from 'react'
import styled from 'styled-components'
import {
  layout,
  Props,
  refinePropsFromParentEvents
} from '../Theme/styledSystem'
import { ICardProps } from '../utility/types'

const StyledCard: any = styled.div`
  ${(props: ICardProps) => props.theme.card[props.variant || 'm'].frame}
  ${layout}
`
const Divider = styled.div`
  ${(props: ICardProps) => props.theme.card[props.variant || 'm'].divider}
  ${layout}
`
const StyledHeader: any = styled.div`
  ${(props: ICardProps) => props.theme.card[props.variant || 'm'].header}
  ${layout}
`
const StyledBody: any = styled.div`
  ${(props: ICardProps) => props.theme.card[props.variant || 'm'].body}
  ${layout}
`
const StyledFooter: any = styled.div`
  ${(props: ICardProps) => props.theme.card[props.variant || 'm'].footer}
  ${layout}
`
const Card = (props: ICardProps) => {
  let { children, header, footer, headerStyle, bodyStyle, footerStyle } = Props(
    props
  )
  const OtherProps = refinePropsFromParentEvents(props)

  return (
    <StyledCard {...Props(props)}>
      {header &&
        (typeof header === 'string' ? (
          <StyledHeader {...OtherProps} style={headerStyle}>
            {header}
          </StyledHeader>
        ) : (
          header
        ))}
      {header && <Divider />}
      <StyledBody style={bodyStyle} {...OtherProps}>
        {children}
      </StyledBody>
      {footer && <Divider />}
      {footer &&
        (typeof footer === 'string' ? (
          <StyledFooter style={footerStyle} {...OtherProps}>
            {footer}
          </StyledFooter>
        ) : (
          footer
        ))}
    </StyledCard>
  )
}

export default Card
