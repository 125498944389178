import { Flex } from '@fareye/ui'
import React from 'react'
import {
  EndCirle,
  RWDSpan,
  StartCirle,
  StoryTimeLine
} from '../../../style/styled'

export const StoryLine = ({ dataSet }: any) => (
  <Flex column>
    {dataSet.map((item: string, index: number) => {
      return (
        <Flex column key={`${item}${index}`}>
          <Flex>
            {index === dataSet.length - 1 ? <EndCirle /> : <StartCirle />}
            <RWDSpan ml={13}>{item}</RWDSpan>
          </Flex>
          {index !== dataSet.length - 1 && <StoryTimeLine />}
        </Flex>
      )
    })}
  </Flex>
)
