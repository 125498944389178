import { Span, styled, Table } from '@fareye/ui'

export const GraphHeading = styled(Span)`
  color: ${(props: any) => props.theme.colors.gray9};
  font-family: 'Roboto';
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    max-width: 28ch;
  }
`

export const InfoDuration = styled(Span)`
  padding: 4px 6px;
  font-family: Roboto;
  font-size: 10px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  background: ${(props: any) => props.theme.colors.yellow3};
`

export const GroupedButton = styled.div`
  display: flex;
  > * {
    margin: 0;
    border-radius: 0px;
    &:first-child {
      border-radius: 3px 0 0 3px;
    }
    &:last-child {
      border-radius: 0 3px 3px 0;
    }
  }
`

export const DimensionButton = styled(Span)`
  ${(props: any) => `
    padding: ${props.variant.includes('h6') ? 3 : 4}px 10px 3px;
    color: ${
      props.theme.colors[props.variant.includes('h6') ? 'black' : 'gray8']
    };
    border-bottom: 2px solid ${
      props.variant.includes('h6') ? props.theme.colors.blue6 : 'transparent'
    };
  `}
`
export const SortingArrow = styled.div`
  margin-top: 12px;
`

export const InfoContainer = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
  background-color: ${(props: any) => props.theme.colors.gray4};
  margin: 4px 12px;
  border-radius: 12px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MoreActions = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
  background-color: ${(props: any) => props.theme.colors.gray4};
  margin: 4px 12px;
  border-radius: 12px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:active {
    box-shadow: 0 0 5px -1px ${props => props.theme.colors.gray6};
  }
  &:hover {
    box-shadow: 0 0 5px -1px ${props => props.theme.colors.gray6};
  }
`

export const TravellerSpan = styled(Span)`
  padding: 6px 0px 8px;
  font-weight: 600;
  font-size: 13px;
`
export const ResetContainer = styled(Span)`
  ${(props: any) => `
    color: ${props.theme.colors.blue8};
    padding: 4px 8px;
    text-align: center;
    cursor: pointer;
    `}
`
export const DimensionResetArrow = styled.span`
  transform: translate3d(0px, 3px, 0px);
  margin-right: 3px;
  font-size: 9px;
`

export const GraphRendererContainer = styled.div<{
  containerStyle: any
  width: number
}>`
  display: flex;
  flex-direction: column;
  padding: 1vw;
  box-sizing: border-box;
  ${({ containerStyle }) => ({ ...containerStyle })}
  @media only screen and (min-width: 426px) and (max-width: 768px) {
    max-width: 325px;
  }
  @media only screen and (max-width: 425px) {
    max-width: ${({ width }) => width - 14 + 'px'};
  }
`

export const DimensionTravellerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 425px) {
    flex-direction: column;
    margin-right: unset;
  }
`

export const DimensionTravellerMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  @media only screen and (max-width: 425px) {
    align-self: flex-end;
    margin-right: unset;
  }
`

export const ScoreCardTable: any = styled(Table)`
  table {
    th {
      background: rgb(24, 144, 255);
      color: #fff;
    }
    th,
    td {
      padding: 10px 7px;
    }
  }
  &.striped {
    tr:nth-child(even) {
      background: rgb(240, 242, 245);
    }
  }
`
