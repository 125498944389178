import { Span } from '@fareye/ui'
import { FaMapMarked, getValueFromNestedKey } from '@fareye/utils'
import React from 'react'
import { formatters } from '../../../../config/constants'
import { ColFullHeight } from '../../../../style/styled'
import { IColumnConfig } from '../../../../utility/types'

function generateColumnObject(column: IColumnConfig) {
  //TODO: Add Tracking column
  let { label, key, formatter, link = '/in-transit/trips' } = column
  //   NOTE: Remove this code once subtype is resolved
  const isMobi = location?.pathname.includes('/v2/view')
  link = isMobi ? (link = `/v2/view${link}`) : link
  switch (key) {
    case 'tracking':
      return {
        Header: label,
        id: key,
        width: 60,
        filterable: false,
        sortable: false,
        Cell: ({ row: { original } }: any) => (
          <ColFullHeight data-testid="slug">
            <a target="_blank" href={`${link}/${original['slug']}`}>
              {<FaMapMarked color="rgba(24,144,255,1)" />}
            </a>
          </ColFullHeight>
        )
      }
    case 'link':
      return {
        Header: label,
        id: key,
        width: 60,
        filterable: false,
        sortable: false,
        Cell: ({ row: { original } }: any) => (
          <ColFullHeight data-testid="slug">
            <a target="_blank" href={`${link}/${original['slug']}`}>
              {<FaMapMarked color="rgba(24,144,255,1)" />}
            </a>
          </ColFullHeight>
        )
      }
    case 'hasMultiVehicle':
      return {
        Header: label,
        id: key,
        width: 100,
        filterable: false,
        sortable: false,
        Cell: ({ row: { original } }: any) => (
          <ColFullHeight data-testid="hasMultiVehicle">
            <Span variant="ellipsis">
              {original.hasMultiVehicle ? 'Yes' : 'No'}
            </Span>
          </ColFullHeight>
        )
      }

    default:
      return {
        Header: label,
        id: key,
        width: 100,
        accessor: key,
        Cell: ({ row: { original } }: any) => {
          let value = getValueFromNestedKey(original, key)
          return (
            <ColFullHeight data-testid={key}>
              <Span variant="ellipsis">
                {formatter && formatters[formatter]
                  ? formatters[formatter](value) || '-'
                  : value || (value === 0 ? 0 : '-')}
              </Span>
            </ColFullHeight>
          )
        }
      }
  }
}
export function getTableColumns(
  reqdColumns: IColumnConfig[],
  propsConfig?: any
) {
  const COLUMNS = reqdColumns.map(ele => generateColumnObject(ele))
  return COLUMNS
}
