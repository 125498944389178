import { isSafari, addMinutes, differenceInSeconds } from '@fareye/utils'
import formatTime from 'hh-mm-ss'
import React from 'react'
import styled from 'styled-components'
import { ICountdownTimerProps } from '../utility/types'

const Div: any = styled.div`
  ${(props: any) => {
    // const variant = splitVariantsToTypes(props)
    return {
      color: 'rgba(0, 0, 0, 0.85)',
      fontSize: '24px',
      fontFamily: 'sans-serif',
      ...props.styles
    }
  }}
`

export const CountDownTimer = (props: ICountdownTimerProps) => {
  const { reference, variant, style = {} } = props
  const [currentDate, setCurrentDate] = React.useState(Date.now())

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(Date.now())
    }, 0)
    return () => {
      clearInterval(interval)
    }
  }, [])

  let now = new Date(currentDate)
  if (isSafari) {
    now = addMinutes(now, 330)
  }
  return (
    <Div className="feui-countdown" variant={variant} styles={style}>
      {formatTime.fromS(differenceInSeconds(now, reference), 'hh:mm:ss')}
    </Div>
  )
}
