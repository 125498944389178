import { FaStar } from '@fareye/utils'
import React from 'react'
import styled from 'styled-components'
import { layout, Props } from '../Theme/styledSystem'
import { IRateProps } from '../utility/types'

const RateDiv = styled.div`
  ${layout}
`
const Rate = (props: IRateProps) => {
  let {
    max,
    size,
    theme,
    color = 'rgba(245,245,117,1)',
    rating,
    readOnly = true,
    fillColor = 'rgb(250, 219, 20)'
  } = Props(props)
  const [rate, setRate] = React.useState(rating)
  const [hover, setHover] = React.useState(rating)

  const handleHover = (e: any, value: any) => {
    e && e.stopPropagation()
    hover !== 0
      ? hover !== value && setHover(value)
      : hover === 0 && setHover(value)
  }

  const handleClick = (e: any, value: any) => {
    e && e.stopPropagation()
    rate !== 0 ? rate !== value && setRate(value) : rate === 0 && setRate(value)
  }

  const fillHelper = (num: number) =>
    hover > num ? color : rate > num ? fillColor : 'transparent'

  var numStars: number[] = Array(max)
    .fill(0)
    .map((e, i) => i + 1)
  const Prop = delete Props(props).size
  return (
    <RateDiv {...Prop} onMouseOver={e => !readOnly && handleHover(e, 0)}>
      {numStars.map((x: number, key: number) => (
        <FaStar
          style={{ marginRight: `${Math.ceil(0.1 * size)}` }}
          onMouseOver={(e: any) => !readOnly && handleHover(e, x)}
          onClick={(e: any) => !readOnly && handleClick(e, x)}
          size={size ? size : theme.rate.size}
          strokeWidth={theme && theme.rate && theme.rate['stroke-width']}
          stroke={color || (theme && theme.rate && theme.rate.stroke)}
          fill={`${fillHelper(x - 1)}`}
          key={key}
        />
      ))}
    </RateDiv>
  )
}
export default Rate
